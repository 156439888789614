import { Injectable } from '@angular/core';
import { BehaviorSubject, from, interval, Observable, Subscription, timer, EMPTY, Subject, of } from 'rxjs';
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  toArray,
  bufferCount,
  concatAll,
  finalize,
  takeWhile
} from 'rxjs/operators';
import { ActiveOperationsStatus, Operation, OperationStatus } from '../../models/operations';
import { LockOperationSyncStatus, SyncState, SyncStatusJSON, SyncTask, fromJSON } from '../../models/sync-status';
import { OperationsService } from '../operations/operations.service';
import { MAX_TASK_UUIDS_IN_QUERY_PARAMS, syncStateSort, SyncStatusService } from '../sync-status/sync-status.service';
import { ChangeNotificationService } from '../appstate/change-notification.service';
import { isDefined } from '@latch/latch-web';

/* quick complete time in milliseconds */
export const QUICK_COMPLETE_INTERVAL = 400;
/* operation linger time ion milliseconds */
export const LINGER_INTERVAL = 10000;

/** interface for functions that must be passed alon with operation ids that will return sync tasks
 * @param response - the result field of the operation returned from the operationsService
 * */
export type SyncTaskCallback = (response: any) => SyncStatusJSON[] | undefined;

export interface PendingOperationInput {
  operationId: string;
  callback?: SyncTaskCallback;
}

export interface OperationEvent {
  operationId: string;
  status: OperationStatus;
}

@Injectable({ providedIn: 'root' })
export class ActiveOperationsService {
  // polling interval in ms
  private pollingInterval = 3000;

  /* stored so that the polling can be unsubscribed if the polling interval changes */
  private subscription$!: Subscription;
  private activeOperationsStatus$ =
    new BehaviorSubject<ActiveOperationsStatus>(ActiveOperationsStatus.NO_ACTIVE_OPERATIONS);

  private inProgress: Set<string> = new Set();

  // When operation completes or fails, emit OperationEvent to monitor results
  private operationEvents = new Subject<OperationEvent>();

  private syncingOnline: Set<LockOperationSyncStatus> = new Set();
  private syncedLockUUIDs: Set<string> = new Set();
  private operationIdSyncCallbacks: Map<string, SyncTaskCallback> = new Map();

  private completedOperations: Set<string> = new Set();
  private failedOperations: Set<string> = new Set();

  constructor(
    private operationsService: OperationsService,
    private syncStatusService: SyncStatusService,
    private changeNotificationService: ChangeNotificationService
  ) {
    // on initial load, get outstanding operations for this loggedInUser
    this.operationsService.getInProgressOperations()
      .subscribe((operations) => {
        operations.forEach((operation) => this.inProgress.add(operation.operationId));
        this.updateActiveOperationStatus();
      });
    this.startPolling();
    this.subscribeToChanges();
  }

  private get hasActiveOperations(): boolean {
    return this.completedOperations.size > 0 ||
      this.failedOperations.size > 0 ||
      this.inProgress.size > 0 ||
      this.syncingOnline.size > 0 ||
      this.syncedLockUUIDs.size > 0;
  }

  private get hasPendingOperations(): boolean {
    return this.inProgress.size > 0 || this.syncingOnline.size > 0;
  }

  private get hasCompletedOperations(): boolean {
    return this.completedOperations.size > 0 || this.syncedLockUUIDs.size > 0;
  }

  private get hasFailedOperations(): boolean {
    return this.failedOperations.size > 0;
  }

  addPendingOperation(input: PendingOperationInput): Observable<OperationEvent> {
    // clear the lingering list if there are only lingering operations
    if (!this.hasPendingOperations && (this.failedOperations.size > 0 || this.completedOperations.size > 0)) {
      this.dismissLingeringOperations();
    }
    // store the callback so that we can parse the sync tasks later
    if (input.callback) {
      this.operationIdSyncCallbacks.set(input.operationId, input.callback);
    }
    return timer(QUICK_COMPLETE_INTERVAL).pipe(
      switchMap(() => this.operationsService.getOperationStatuses([input.operationId]).pipe(map(operations => operations[0]))),
      tap((operation) => {
        this.updateAfterQuickComplete(operation);
      }),
      map((operation) => ({ operationId: operation.operationId, status: operation.status }))
    );
  }

  /**
   * Add operations in bulk to the polling list of in progress operations to start tracking operation results
   * @param pendingOperations List of operations to store in the polling list
   * @note This method will not send http requests, it will store operations in the polling list
   */
  public addPendingOperations(pendingOperations: PendingOperationInput[]): void {
    // clear the lingering list if there are only lingering operations
    if (!this.hasPendingOperations && (this.failedOperations.size > 0 || this.completedOperations.size > 0)) {
      this.dismissLingeringOperations();
    }
    pendingOperations.forEach(pendingOperation => {
      // store the callback so that we can parse the sync tasks later
      if (pendingOperation.callback) {
        this.operationIdSyncCallbacks.set(pendingOperation.operationId, pendingOperation.callback);
      }
      this.inProgress.add(pendingOperation.operationId);
    });
    this.updateActiveOperationStatus();
  }

  /** retrieve the current activeOperations Status as an observable */
  getOperationStatus(): Observable<ActiveOperationsStatus> {
    // limits clients' access to BehaviorSubject
    return this.activeOperationsStatus$.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Operations are requested from the api with the help of polling and this method makes sure all of the pending
   * operations are completed.
   * @param operations array of operations to filter `IN_PROGRESS` operations and wait for their result
   * @returns Returns an observable which completes when none of the operations provided as input have status IN_PROGRESS.
   * @note this method only monitors `IN_PROGRESS` operations, others will be skipped
   */
  public waitPendingOperations(operations: OperationEvent[]): Observable<OperationEvent[]> {
    const pendingOperations = operations.filter(
      op => op.status === OperationStatus.IN_PROGRESS
    );
    if (pendingOperations.length === 0) {
      return of([]);
    }
    return this.operationEvents.pipe(
      takeWhile(operationEvent => {
        const pending = pendingOperations.find(
          op => op.operationId === operationEvent.operationId
        );
        if (pending && operationEvent.status !== OperationStatus.IN_PROGRESS) {
          const index = pendingOperations.indexOf(pending);
          pendingOperations.splice(index, 1);
        }
        return pendingOperations.length !== 0;
      }),
      toArray(),
    );
  }

  setPollingInterval(newInterval: number) {
    this.pollingInterval = newInterval;
    this.subscription$.unsubscribe();
    this.startPolling();
  }

  dismissLingeringOperations() {
    this.completedOperations.clear();
    this.failedOperations.clear();
    this.updateActiveOperationStatus();
  }

  private operationHasSyncTasks(operationId: string) {
    return this.operationIdSyncCallbacks.has(operationId);
  }

  private startPolling() {
    this.subscription$ = interval(this.pollingInterval).pipe(
      filter(() => this.hasPendingOperations),
      concatMap(() => this.updateInProgressList()),
      concatMap(() => this.updateSyncStatusList()),
    ).subscribe();
  }

  private updateActiveOperationStatus() {
    if (!this.hasActiveOperations) {
      this.activeOperationsStatus$.next(ActiveOperationsStatus.NO_ACTIVE_OPERATIONS);
    } else {
      if (this.hasPendingOperations) {
        this.activeOperationsStatus$.next(ActiveOperationsStatus.IN_PROGRESS);
      } else {
        if (this.hasCompletedOperations && !this.hasFailedOperations) {
          this.activeOperationsStatus$.next(ActiveOperationsStatus.COMPLETE);
        } else if (!this.hasCompletedOperations && this.hasFailedOperations) {
          this.activeOperationsStatus$.next(ActiveOperationsStatus.FAILED);
        } else if (this.hasCompletedOperations && this.hasFailedOperations) {
          this.activeOperationsStatus$.next(ActiveOperationsStatus.SOME_FAILED);
        }
      }
    }
  }

  private updateInProgressList() {
    return this.operationsService.getOperationStatuses(Array.from(this.inProgress))
      .pipe(
        tap((operations: Operation<unknown>[]) => {
          const completedAndFailedOperations = operations.filter(operation => operation.status !== OperationStatus.IN_PROGRESS);
          for (const operation of completedAndFailedOperations) {
            if (operation.status === OperationStatus.SUCCESS && this.operationHasSyncTasks(operation.operationId)) {
              this.addSyncStatusesForPolling(operation);
            } else if (operation.status === OperationStatus.SUCCESS) {
              this.moveOperationToCompleted(operation);
            } else {
              this.moveOperationToFailed(operation);
            }
          }
        })
      );
  }

  // set operation status to completed and trigger linger timeout
  private moveOperationToCompleted(operation: Operation<unknown>) {
    const operationId = operation.operationId;
    if (this.inProgress.has(operationId)) {
      this.inProgress.delete(operationId);
    }
    this.completedOperations.add(operationId);
    this.operationEvents.next({ operationId, status: operation.status });
    this.updateActiveOperationStatus();
    timer(LINGER_INTERVAL).subscribe(() => {
      this.completedOperations.delete(operationId);
      this.updateActiveOperationStatus();
    });
  }

  // set operation status to failed and trigger linger timeout
  private moveOperationToFailed(operation: Operation<unknown>) {
    const operationId = operation.operationId;
    if (this.inProgress.has(operationId)) {
      this.inProgress.delete(operationId);
    }
    this.failedOperations.add(operationId);
    this.operationEvents.next({ operationId, status: operation.status });
    this.updateActiveOperationStatus();
    timer(LINGER_INTERVAL).subscribe(() => {
      this.failedOperations.delete(operationId);
      this.updateActiveOperationStatus();
    });
  }

  private markLockSyncAsCompleted(lockSyncStatus: LockOperationSyncStatus) {
    this.syncedLockUUIDs.add(lockSyncStatus.lockUUID);
    this.syncingOnline.delete(lockSyncStatus);
    this.updateActiveOperationStatus();
    timer(LINGER_INTERVAL).subscribe(() => {
      this.syncedLockUUIDs.delete(lockSyncStatus.lockUUID);
      this.updateActiveOperationStatus();
    });
  }

  // retrieve and process the sync statuses for operations with pending sync tasks
  private updateSyncStatusList(): Observable<unknown> {
    if (this.syncingOnline.size === 0) {
      return EMPTY;
    }

    // reverse mapping from syncTask uuid to LockOperationSyncStatus
    const taskUUIDToSyncStatusMap = new Map<string, LockOperationSyncStatus>();
    // map lock to lock's SyncTask[]
    const syncStatusToTasksMap = new Map<LockOperationSyncStatus, SyncTask[]>();

    this.syncingOnline.forEach(syncStatus => {
      syncStatusToTasksMap.set(syncStatus, []);
      syncStatus.syncTasks.forEach(task => {
        taskUUIDToSyncStatusMap.set(task.uuid, syncStatus);
      });
    });

    const taskUUIDs = Array.from(taskUUIDToSyncStatusMap.keys());
    return from(taskUUIDs).pipe(
      bufferCount(MAX_TASK_UUIDS_IN_QUERY_PARAMS),
      concatMap(chunkTaskUUIDs => this.syncStatusService.getSyncTasks(chunkTaskUUIDs)),
      concatAll(),
      tap(syncTask => {
        const syncStatus = taskUUIDToSyncStatusMap.get(syncTask.uuid);
        if (isDefined(syncStatus)) {
          const tasks = syncStatusToTasksMap.get(syncStatus) ?? [];
          tasks.push(syncTask);
          syncStatusToTasksMap.set(syncStatus, tasks);
        }
      }),
      toArray(),
      finalize(() => {
        // first parameter for the forEach is the map's value and second is key
        syncStatusToTasksMap.forEach((tasks, syncStatus) => {
          const syncState = this.mapSyncTasksToSyncOutcomeState(tasks);
          if (syncState !== SyncState.SyncingOnline) {
            this.markLockSyncAsCompleted(syncStatus);
          }
        });
      }),
    );
  }

  // calculate a single syncOutcome.syncState from an array of SyncTasks
  private mapSyncTasksToSyncOutcomeState(syncTasks: SyncTask[]): SyncState {
    // some operations (like changing key names) won't produce sync tasks. for that case we'll construct and return a syncOutcome
    if (syncTasks.length === 0) {
      return SyncState.SyncedByDevice;
    }
    if (syncTasks.some(syncTask => syncTask.state === SyncState.SyncingOnline)) {
      return SyncState.SyncingOnline;
    }
    return syncTasks.map(({ state }) => state).sort(syncStateSort)[0];
  }

  /* update service's internal state when the operation's quick complete period is over*/
  private updateAfterQuickComplete(operation: Operation<unknown>) {
    switch (operation.status) {
      case OperationStatus.IN_PROGRESS:
        this.inProgress.add(operation.operationId);
        this.updateActiveOperationStatus();
        break;
      case OperationStatus.SUCCESS:
        if (this.operationHasSyncTasks(operation.operationId)) {
          this.addSyncStatusesForPolling(operation);
        } else {
          this.moveOperationToCompleted(operation);
        }
        break;
      case OperationStatus.FAILURE_UNACKNOWLEDGED:
        this.moveOperationToFailed(operation);
        break;
    }
  }

  /* fetches sync statuses for this operation id and stores them for polling */
  private addSyncStatusesForPolling(operation: Operation<unknown>) {
    if (this.inProgress.has(operation.operationId)) {
      this.inProgress.delete(operation.operationId);
    }
    const getSyncStatusesFromOperation = this.operationIdSyncCallbacks.get(operation.operationId);
    if (isDefined(getSyncStatusesFromOperation)) {
      const operationSyncStatuses = getSyncStatusesFromOperation(operation.result);
      const parsedSyncStatuses: LockOperationSyncStatus[] = operationSyncStatuses ? fromJSON(operationSyncStatuses) : [];
      for (const lockSyncStatus of parsedSyncStatuses) {
        if (this.mapSyncTasksToSyncOutcomeState(lockSyncStatus.syncTasks as SyncTask[]) === SyncState.SyncingOnline) {
          this.syncingOnline.add(lockSyncStatus);
        } else {
          this.moveOperationToCompleted(operation);
        }
      }
    }
    this.operationIdSyncCallbacks.delete(operation.operationId);
    this.updateActiveOperationStatus();
  }

  private subscribeToChanges() {
    this.changeNotificationService.authStateChange().subscribe(() => {
      this.inProgress.clear();
      this.syncingOnline.clear();
      this.syncedLockUUIDs.clear();
      this.operationIdSyncCallbacks.clear();
      this.completedOperations.clear();
      this.failedOperations.clear();

      this.setPollingInterval(this.pollingInterval);
      this.updateActiveOperationStatus();
    });
  }
}
