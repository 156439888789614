<div class="modal">
  <div class="content">
    <div class="inner-content" *ngIf="!progressStatus.done">
      <div *ngFor="let item of progressStatus.items" class="title">
        <span [ngClass]="{ done: !isInProgress(item.key) }">{{getProgressText(item)}}</span>
      </div>
      <div class="progress-bar">
        <div class="progress" [ngStyle]="{ 'width': totalPercentage + '%' }"></div>
      </div>
      <div class="footer">
        {{ footer }}
      </div>
    </div>
    <div class="inner-content" *ngIf="progressStatus.done">
      <div class="latch-icon-confirm"></div>
      <div class="success">Success</div>
      <div *ngFor="let text of doneTexts" class="done">
        <span>{{text}}</span>
      </div>
    </div>
    <button *ngIf="progressStatus.done" class="close latch-button latch-dark" (click)="handleClose.emit()">Close</button>
  </div>
</div>
