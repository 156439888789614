import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDefined } from '@latch/latch-web';
import {
  Assignment,
  AssignmentBase,
  MaintenanceSlot,
  MaintenanceSlotBase,
  Location,
  Media,
  ServiceOrder,
  ServiceOrderBase,
  TimeSlot
} from 'manager/models/service-order';
import { from, interval, merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { dateToEpochSeconds, epochSecondsToDate } from '../utility/utility';
import {
  AssignServiceOrderInput,
  ServiceOrderInput,
  GetServiceOrdersInput,
  GetServiceOrdersResponse,
  GetServiceOrdersResponseBase,
  ServiceOrderService,
  UpdateServiceOrderInput,
  CreateServiceOrderMediaInput,
  ActionServiceOrderInput,
  AssignServiceOrderInputAssignment,
} from './service-order.service';

type GetServiceOrdersResponseRaw = GetServiceOrdersResponseBase & {
  serviceOrders: ServiceOrderRaw[];
};

export type ServiceOrderRaw = ServiceOrderBase & {
  /** epoch seconds */
  createdAt: number;
  /** epoch seconds */
  completedAt: number | null;
  /** epoch seconds */
  lastUpdatedAt: number;
  assignment?: AssignmentRaw;
};

export type AssignmentRaw = AssignmentBase & {
  maintenanceSlot?: MaintenanceSlotRaw;
  /** epoch seconds */
  createdAt: number;
};

export type MaintenanceSlotRaw = MaintenanceSlotBase & {
  /** epoch seconds */
  date: number;
};

type AssignServiceOrderInputAssignmentRaw = Omit<AssignServiceOrderInputAssignment, 'date'> & {
  /** epoch seconds */
  date: number;
};

type CreateServiceOrderMediaResponse = Media & {
  putUrl: string;
};

// Polling interval of 60s
const POLLING_INTERVAL_MS = 60000;

@Injectable()
export class HTTPServiceOrderService extends ServiceOrderService {
  private unacknowledgedServiceOrdersMap: { [buildingUUID: string]: Observable<GetServiceOrdersResponse>; } = {};
  private refreshUnacknowledgedServiceOrders$ = new Subject<void>();
  private locations$: Observable<Location[]> | undefined;
  private timeSlots$: Observable<TimeSlot[]> | undefined;

  constructor(private authService: AuthService) {
    super();
  }

  public pollUnacknowledgedServiceOrders(buildingUUID: string): Observable<GetServiceOrdersResponse> {
    if (!this.unacknowledgedServiceOrdersMap[buildingUUID]) {
      this.unacknowledgedServiceOrdersMap[buildingUUID] = merge(
        this.refreshUnacknowledgedServiceOrders$,
        interval(POLLING_INTERVAL_MS)
      ).pipe(
        startWith(0),
        switchMap(() => this.getServiceOrders({ buildingUUID, isAcknowledged: false })),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
    return this.unacknowledgedServiceOrdersMap[buildingUUID];
  }

  public getServiceOrders(input: GetServiceOrdersInput): Observable<GetServiceOrdersResponse> {
    let search = new HttpParams();

    if (input.statuses) {
      input.statuses.forEach(status => search = search.append('status', status));
    }
    if (isDefined(input.isUrgent)) {
      search = search.append('isUrgent', String(input.isUrgent));
    }
    if (isDefined(input.isAcknowledged)) {
      search = search.append('isAcknowledged', String(input.isAcknowledged));
    }
    if (input.spaceUUIDs) {
      input.spaceUUIDs.forEach(spaceUUID => search = search.append('spaceUUID', spaceUUID));
    }
    if (input.assignedToUserUUIDs) {
      input.assignedToUserUUIDs.forEach(userUUID => search = search.append('assignedToUserUUID', userUUID));
    }
    if (input.startDate) {
      search = search.set('createdStartDate', `${dateToEpochSeconds(input.startDate)}`);
    }
    if (input.endDate) {
      search = search.set('createdEndDate', `${dateToEpochSeconds(input.endDate)}`);
    }
    if (input.search) {
      search = search.append('search', input.search);
    }
    if (input.pageToken) {
      search = search.append('pageToken', input.pageToken);
    }
    if (input.pageSize) {
      search = search.append('pageSize', `${input.pageSize}`);
    }
    if (input.orderBy) {
      search = search.append('orderBy', input.orderBy);
    }

    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders`,
      search
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(getServiceOrdersRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getServiceOrder(input: ServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public acknowledgeServiceOrder(input: ServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/acknowledge`,
    }).pipe(
      tap(() => this.refreshUnacknowledgedServiceOrders$.next()),
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public assignServiceOrder(input: AssignServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/assign`,
      data: assignServiceOrderInputAssignmentToRaw(input.assignment)
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public updateServiceOrder(input: UpdateServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}`,
      data: input.serviceOrder
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public cancelServiceOrder(input: ActionServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/cancel`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public completeServiceOrder(input: ActionServiceOrderInput): Observable<ServiceOrder> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/complete`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(serviceOrderRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getServiceOrderMedia(input: ServiceOrderInput): Observable<Media[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/media`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public createServiceOrderMedia(input: CreateServiceOrderMediaInput): Observable<Media> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/service-orders/buildings/${input.buildingUUID}/service-orders/${input.serviceOrderUUID}/media`,
      data: { contentType: input.file.type }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      switchMap((response: CreateServiceOrderMediaResponse) =>
        from(fetch(response.putUrl, { body: input.file, method: 'PUT' })).pipe(map(() => response))
      ),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getLocations(): Observable<Location[]> {
    if (!this.locations$) {
      this.locations$ = this.authService.request({
        method: 'get',
        endpoint: '/web/v1/service-orders/locations'
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.locations$;
  }

  public getTimeSlots(): Observable<TimeSlot[]> {
    if (!this.timeSlots$) {
      this.timeSlots$ = this.authService.request({
        method: 'get',
        endpoint: '/web/v1/service-orders/time-slots'
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    }
    return this.timeSlots$;
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}

function getServiceOrdersRawToJSON(raw: GetServiceOrdersResponseRaw): GetServiceOrdersResponse {
  return {
    ...raw,
    serviceOrders: raw.serviceOrders.map(item => serviceOrderRawToJSON(item))
  };
}

function serviceOrderRawToJSON(serviceOrder: ServiceOrderRaw): ServiceOrder {
  return {
    ...serviceOrder,
    createdAt: epochSecondsToDate(serviceOrder.createdAt),
    completedAt: serviceOrder.completedAt ? epochSecondsToDate(serviceOrder.completedAt) : null,
    lastUpdatedAt: epochSecondsToDate(serviceOrder.lastUpdatedAt),
    assignment: serviceOrder.assignment ? assignmentRawToJson(serviceOrder.assignment) : undefined
  };
}

function maintenanceSlotRawToJSON(maintenanceSlot: MaintenanceSlotRaw): MaintenanceSlot {
  return {
    ...maintenanceSlot,
    date: epochSecondsToDate(maintenanceSlot.date)
  };
}

function assignmentRawToJson(assignment: AssignmentRaw): Assignment {
  return {
    ...assignment,
    maintenanceSlot: assignment.maintenanceSlot ? maintenanceSlotRawToJSON(assignment.maintenanceSlot) : undefined,
    createdAt: epochSecondsToDate(assignment.createdAt)
  };
}

function assignServiceOrderInputAssignmentToRaw(assignment: AssignServiceOrderInputAssignment): AssignServiceOrderInputAssignmentRaw {
  return {
    ...assignment,
    date: dateToEpochSeconds(assignment.date)
  };
}
