<div class="preview-container" *ngIf="photos.length">
  <div
    class="preview"
    [style.background-image]="'url(' + selectedPhoto[urlProperty] + ')'">
  </div>
</div>


<div class="photos">
  <frame-avatar
    *ngFor="let photo of photos"
    (click)="selectedPhoto = photo"
    class="photo cliccable"
    size="lg"
    variant="image"
    shape="square"
    [imageUrl]="photo[urlProperty]">
  </frame-avatar>
</div>
