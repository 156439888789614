<latch-subnav *ngIf="!pageWithTabsService" headerTitle="Units" [subtitle]="countText" [actions]="actions">
</latch-subnav>

<latch-tab-group class="latch-tab-with-viewport"
  *ngIf="!pageWithTabsService && buildingSupportsSmartHome" [selected]="currentTab"
  (tabClicked)="handleToggleTabs($event)">
  <latch-tab [id]="Tab.VacantUnitSettings" *ngIf="buildingSupportsSmartHomeEnterpriseManagement">Vacant Unit Settings</latch-tab>
  <latch-tab [id]="Tab.NotificationSettings" *ngIf="buildingSupportsSmartHome">Notification Settings</latch-tab>
</latch-tab-group>

<latch-table class="latch-table-with-viewport" *ngIf="currentTab === Tab.Units"
  [data]="datasource" [selection]="false" (rowClick)="onRowClick($event)" [emptyMessage]="emptyListMessage">

  <latch-table-column field="name" columnTitle="Name" [sortable]="true">
    <ng-template latchTableCellTemplate let-dataItem>
      <div class="cell-name">
        <span class="name-primary">
          {{ dataItem.name }}
        </span>
      </div>
    </ng-template>
  </latch-table-column>

</latch-table>

<latch-table class="latch-table-with-viewport" *ngIf="currentTab === Tab.NotificationSettings" [selection]="false" [data]="notificationsDataSource"
  [emptyMessage]="emptyListMessage">

  <latch-table-column field="name" columnTitle="Notification Type" width="400px" [sortable]="false">
    <ng-template latchTableCellTemplate let-notification>
      <div class="cell-name">
        <span class="name-primary">
          {{getNotificationPrimaryTitle(notification)}}
        </span>
        <span class="name-secondary">
          {{getNotificationSecondaryTitle(notification)}}
        </span>
      </div>
    </ng-template>
  </latch-table-column>

  <latch-table-column field="sms" columnTitle="SMS" width="200px" [sortable]="false">
    <ng-template latchTableCellTemplate let-notification>
        <latch-checkbox
          [checked]="getNotificationSmsCheck(notification)" (checkedChange)="handleToggleNotificationSmsCheck(notification)" [disabled]="!editNotificationSettingsMode"></latch-checkbox>
    </ng-template>
  </latch-table-column>

  <latch-table-column field="sms" columnTitle="Push Notifications" width="200px" [sortable]="false">
    <ng-template latchTableCellTemplate let-notification>
        <latch-checkbox
          *ngIf="notification.values.PUSH_NOTIFICATION !== undefined"
          [checked]="getNotificationPushNotificationsCheck(notification)"
          (checkedChange)="handleToggleNotificationPushNotificationsCheck(notification)"
          [disabled]="!editNotificationSettingsMode"></latch-checkbox>
    </ng-template>
  </latch-table-column>

</latch-table>

<latch-card class="page" *ngIf="currentTab === Tab.VacantUnitSettings">
  <ng-container latchCardHeader>
    <p class="page-description">
      These default settings will be applied to devices in all vacant units and
      reset nightly at 12a local time to conserve energy.
    </p>
  </ng-container>

  <ng-container latchCardContent>
    <div class="cell-list">
      <ng-container *ngIf="buildingHasLights">
        <div class="cell cell-row latch-checkbox-wrapper"
          (click)="editSettingsMode && lightsOff = !lightsOff"
          [class.disabled]="!editSettingsMode">
          <latch-checkbox label="Turn lights off when unit becomes vacant"
            labelPosition="after" [disabled]="!editSettingsMode"
            [checked]="lightsOff"></latch-checkbox>
        </div>
      </ng-container>

      <ng-container *ngIf="buildingHasThermostats">
        <div class="cell cell-row latch-checkbox-wrapper"
          (click)="editSettingsMode && hvacOff = !hvacOff"
          [class.disabled]="!editSettingsMode">
          <latch-checkbox class="checkbox"
            label="Turn HVAC off when unit becomes vacant" labelPosition="after"
            [disabled]="!editSettingsMode" [checked]="hvacOff"></latch-checkbox>
        </div>
      </ng-container>

      <ng-container
        *ngIf="!buildingHasDevices && currentUserHasDeviceManagementPermissions">
        <div class="cell cell-row">
          <div class="cell-text-1">
            There are no smart devices eligible for this feature in this
            building.
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!currentUserHasDeviceManagementPermissions">
        <div class="cell cell-row">
          <div class="cell-text-1">
            Only Portfolio Managers and Property Managers with ‘Device
            Management’ permissions may view and edit vacant unit settings.
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</latch-card>
