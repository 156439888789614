import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ErrorHandlerService } from '../../services/appstate/error-handler.service';
import { pluralize } from 'manager/services/utility/utility';
import { Subject, EMPTY } from 'rxjs';
import { tap, takeUntil, catchError } from 'rxjs/operators';
import {
  UserMfaSettings,
  LatchMfaService,
  User,
  ActiveUserSession,
  LatchAnalyticsConstants,
  LatchAnalyticsService
} from '@latch/latch-web';

enum Step {
  InitiateChangePassword,
  ConfirmChangePassword,
  ConfirmEmailSent
}

@Component({
  selector: 'latch-view-account-page',
  templateUrl: './view-account-page.component.html',
  styleUrls: ['./view-account-page.component.scss']
})
export class ViewAccountPageComponent implements OnInit, OnDestroy {

  user: User | null = null;
  currentMFASettings!: UserMfaSettings;
  isLoading = false;
  step = Step;
  currentStep = Step.InitiateChangePassword;

  public otherActiveUserSessions: ActiveUserSession[] | undefined;

  private unsubscribe$ = new Subject<void>();

  get mfaEnabled() {
    return (this.currentMFASettings?.mfaEnabled);
  }

  constructor(
    private authService: AuthService,
    private analyticsService: LatchAnalyticsService,
    private errorHandlerService: ErrorHandlerService,
    private mfaService: LatchMfaService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.user = this.authService.currentUser;

    this.mfaService.getUserMFASettings().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: (settings) => {
        this.currentMFASettings = settings;
        this.isLoading = false;
        this.analyticsService.track(LatchAnalyticsConstants.ViewPage, { [LatchAnalyticsConstants.PageName]: 'My Profile' });
      },
      error: (error) => {
        this.errorHandlerService.handleException(error);
        this.isLoading = false;
      },
    });

    this.authService.getActiveUserSessions().pipe(
      tap(activeUserSessions => this.otherActiveUserSessions =
        activeUserSessions?.filter(item => item.currentSession === false)
      ),
      catchError(() => EMPTY),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  public getActiveSessionsText(activeSessions: ActiveUserSession[]): string {
    return activeSessions.length === 0 ? 'None' :
      `${activeSessions.length} Active ${pluralize(activeSessions.length, 'Session')}`;
  }

  public handleChangePassword() {
    this.isLoading = true;
    this.authService.changePassword(this.user?.email ?? '')
      .subscribe({
        next: () => {
          this.currentStep = Step.ConfirmEmailSent;
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.errorHandlerService.handleException(error);
        }
      });
  }
}
