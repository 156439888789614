import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { groupBy } from 'manager/services/utility/utility';

export interface InvalidItem {
  /**
   * Brief description of error that can be displayed to the user.
   * This description should not be customized per-item - items with the same type of
   * error should have the exact same error string.
   */
  error?: string;
  errors?: string[];
}

/**
 * Displays a summary of what was successfully and not successfully imported
 * as part of an import operation.
 */
@Component({
  selector: 'latch-import-csv-result',
  templateUrl: './import-csv-result.component.html',
  styleUrls: ['./import-csv-result.component.scss']
})
export class ImportCsvResultComponent implements OnInit, OnChanges {
  /** The type of item that is being imported. */
  @Input() importName!: string;
  /** Successfully imported items. */
  @Input() valid: unknown[] = [];
  /** Unsuccessfully imported items, including a description of why each failed. */
  @Input() invalid: InvalidItem[] = [];
  @Input() groupInvalidBy!: string;

  invalidGroups: InvalidItem[][] = [];
  errorCount!: number;

  ngOnInit(): void {
    this.invalidGroups = this.updateInvalidGroups();
    this.errorCount = this.updateUniqueErrors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invalid) {
      this.invalidGroups = this.updateInvalidGroups();
      this.errorCount = this.updateUniqueErrors();
    }
  }
  /**
   * Unsuccesfully imported items, grouped by why their import failed.
   *
   * invalidGroups[i] gives a group of items which all failed for the same reason.
   */
  private updateInvalidGroups(): InvalidItem[][] {
    // in case of multiple errors flatten for grouping similar errors
    let invalid = this.invalid;
    if (this.invalid.some(i => i.errors)) {
      invalid = [];
      this.invalid.forEach(i => {
        i.errors?.forEach(error => {
          invalid.push({ ...i, error, errors: undefined });
        });
      });
    }
    const groups = Object.values(groupBy(invalid, item => item.error ?? ''));
    return groups.sort((a, b) => b.length - a.length);
  }

  private updateUniqueErrors(): number {
    if (!this.groupInvalidBy) {
      return this.invalid.length;
    }

    const uniqueItems: Record<string, boolean> = {};
    const keys = this.groupInvalidBy.split('.');
    this.invalid.forEach(item => {
      const value = keys.reduce((final: any, key: string) => {
        if (!final[key]) {
          return item;
        }
        return final[key];
      }, { ...item });
      uniqueItems[value] = true;
    });

    return Object.keys(uniqueItems).length;
  }
}
