import { Component, HostBinding, Input } from '@angular/core';

export interface InsightComparisonData {
  difference?: number;
  differencePercent: number;
}

@Component({
  selector: 'latch-insight-comparison',
  templateUrl: './insight-comparison.component.html',
  styleUrls: ['./insight-comparison.component.scss']
})
export class InsightComparisonComponent {

  @Input() data!: InsightComparisonData;

  @HostBinding('class')
  @Input() color: 'dark' | 'light' = 'dark';

  public get text(): string {
    const percentageText = (this.data.differencePercent > 0 ?
      '+' + this.data.differencePercent.toString() : this.data.differencePercent.toString()) + '%';
    return this.data.difference !== undefined ?
      `${this.data.difference} (${percentageText})` : percentageText;
  }

}
