import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { EMAIL_RE } from '../services/utility/utility';

// We use our own email validation directive because Angular's assumes the field is always required
@Directive({
  selector: '[latchEmail]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidateEmailDirective, multi: true }]
})
export class ValidateEmailDirective implements Validator {

  @Input() email: string | undefined;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const email = control.value || '';
    const hasError = email ? !EMAIL_RE.test(email) : false;
    return hasError ? { email } : null;
  }

}
