import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceActivityLog, SmartDevice, SmartDeviceType } from 'manager/models/device';
import { NextPage, ServiceResponse } from '../interfaces';
import { DeviceVacancySettings } from 'manager/models/enterprise-settings';

export interface GetDevicesResponse {
  devices: SmartDevice[];
}

export interface PagedGetDeviceActivityResponse {
  activityLogs: DeviceActivityLog[];
  metadata: {
    nextPage?: NextPage
  };
}

export enum SmartHomeUnitErrors {
  UnitHasPendingDispute = 'UNIT_WITH_DISPUTES',
  UnitHasRecentlyCreatedDispute = 'UNIT_HAS_RECENTLY_CREATED_DISPUTE',
  UnitHasExtendedAccessBufferEndtime = 'UNIT_HAS_EXTENDED_NEST_ACCESS_BUFFER_ENDTIME'
}

export enum SmartHomeDeviceRenamingErrors {
  NameTooLong = 'Name is longer than 32 characters.',
  DuplicateName = 'Name already in use'
}

export enum SmartHomeUnitErrorMessages {
  // eslint-disable-next-line max-len
  ActiveDisputeErrorMessage = 'This unit has a thermostat that is disputed through Nest. Please resolve dispute on the unit screen to add new residents to this unit. If you have recently resolved a dispute, please wait up to 24 hours to add new residents.',
  // eslint-disable-next-line max-len
  ActiveDisputeErrorMessageCsv = 'One or more of the units in this CSV has a thermostat that is disputed through Nest. Please resolve dispute on the unit screen to add new residents to this unit. If you have recently resolved a dispute, please wait up to 24 hours to add new residents.',
  // eslint-disable-next-line max-len
  RecentMoveoutErrorMessage = 'Nest ensures residents can dispute their move-out date for 24 hours. A resident was recently moved out and you will have to wait 24 hours from move-out before adding a new one.',
  // eslint-disable-next-line max-len
  RecentMoveoutErrorMessageCsv = 'Nest ensures residents can dispute their move-out date for 24 hours. A resident was recently moved out from one or more of the units in this CSV and you will have to wait 24 hours from move-out before adding a new one.'
}

// spaceUUIDs and rootSpaceUUIDs represent spaces for which device activity will be returned
// spaceUUIDs param will only search for activity from devices directly belong to that space
// rootSpaceUUIDs param will search for device activity in child spaces as well as in the given space
export interface GetDeviceActivityInput {
  spaceUUIDs?: string[];
  rootSpaceUUIDs?: string[];
  deviceTypes?: SmartDeviceType[];
  startTime?: Date;
  endTime?: Date;
  sortAscending?: boolean;
  start?: number;
  limit?: number;
}

export interface GetDisputesResponse {
  hasActiveDispute: boolean;
  hasRecentlyCreatedDispute: boolean;
  hasExtendedNestAccessBufferEndTime: boolean;
}

export interface CreateOrUpdateDeviceVacancySettingsResponse {
  settings: {
    smartDeviceVacancySettings: DeviceVacancySettings[]
  };
  spaceUUID: string;
}

@Injectable()
export abstract class SmartHomeService {
  /**
   * Returns devices in a given space. Recursive parameter indicates whether to return only devices
   * in that space (recursive = false), or to also include all devices in child spaces of the
   * spaceUUID param (recursive = true). Include traits param indicates whether to include various
   * smart device metadata.
   */
  abstract getDevices(spaceUUID: string, recursive?: boolean, includeTraits?: boolean): Observable<SmartDevice[]>;

  abstract getAllDevices(recursive?: boolean, includeTraits?: boolean): Observable<SmartDevice[]>;

  abstract getDeviceDetails(deviceUUID: string): Observable<SmartDevice>;

  abstract getDevicesForHub(hubUUID: string): Observable<SmartDevice[]>;

  abstract getHubHotspotPassword(hubUUID: string): Observable<string>;

  abstract getDeviceActivityLogs(input: GetDeviceActivityInput): Observable<PagedGetDeviceActivityResponse>;

  abstract getDisputesForUnit(unitId: number): Observable<GetDisputesResponse>;

  abstract closeDisputeForUnit(unitId: number): Observable<ServiceResponse>;

  abstract renameDevice(deviceUUID: string, name: string): Observable<SmartDevice>;

  abstract getSmartHomeSettingsForSpace(spaceUUID: string): Observable<DeviceVacancySettings[]>;

  abstract createOrUpdateDeviceVacancySettingsForSpace(spaceUUID: string, settings: DeviceVacancySettings[]):
    Observable<DeviceVacancySettings[]>;
}
