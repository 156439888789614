import { Injectable } from '@angular/core';
import { AuthService } from 'manager/services/auth/auth.service';
import { sprig } from '@sprig-technologies/sprig-browser';
import { environment } from 'environments/interface';

export const Sprig = sprig.configure({
  environmentId: environment.sprigEnvironmentId,
});

@Injectable({ providedIn: 'root' })
export class SprigService {
  constructor(
    private authService: AuthService
  ) { }

  /**
   * initialize should be called once at app launch.
   *
   * It is responsible for registering app state changes when the user logs in/out.
   */
  initialize() {
    this.authService.user$
      .subscribe(user => {
        if (user) {
          // Ensures Sprig does not re-survey the same user and increases tracking accuracy
          Sprig.setUserId(user.uuid);
        } else {
          // If there's no currently logged in user, de-register the user in Sprig so that we
          // don't target the last-logged-in user on the login pages.
          Sprig.logoutUser();
        }
      });
  }

  public track(eventName: string, attributes?: { [key: string]: string | number | boolean; }): void {
    Sprig.track(eventName, attributes);
  }

  public setAttributes(attributes: { [key: string]: any}) {
    Sprig.setAttributes(attributes);
  }
}
