<ng-container>
  <input
    type="text"
    class="latch-form-control time"
    [(ngModel)]="startTime"
    placeholder="hh:mm"
    [disabled]="isReadOnly"
    (blur)="handleBlurStartTime()"
  />
  <select class="meridiem" [(ngModel)]="startMeridiem" [disabled]="isReadOnly" (ngModelChange)="handleMeridiemChange()">
    <option value="AM">am</option>
    <option value="PM">pm</option>
  </select>
  <span class="range-label">to</span>
  <input
    type="text"
    class="latch-form-control time"
    [(ngModel)]="endTime"
    placeholder="hh:mm"
    [disabled]="isReadOnly"
    (blur)="handleBlurEndTime()"
  />
  <select class="meridiem" [(ngModel)]="endMeridiem" [disabled]="isReadOnly" (ngModelChange)="handleMeridiemChange()">
    <option value="AM">am</option>
    <option value="PM">pm</option>
  </select>
</ng-container>
