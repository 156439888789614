import { Injectable } from '@angular/core';
import { SpaceService, GetSpacesResponse, SpaceShortInfo } from './space.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HTTPSpaceService extends SpaceService {

  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  getSpaces(rootUUID: string, spaceTypes = []): Observable<SpaceShortInfo[]> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/me/spaces',
      search: new HttpParams({
        fromObject: {
          root: rootUUID,
          spaceTypes
        }
      })
    }).pipe(
      map(res => {
        const response: GetSpacesResponse = AuthService.getPayload(res);
        return response.spaces;
      }),
      catchError((error: Error) => throwError(error))
    );
  }
}

