import { Component, Input } from '@angular/core';
import { BaseChartComponent } from './base-chart.component';
import { LineChartData } from './chart';

@Component({
  selector: 'latch-chart[line]',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends BaseChartComponent {
  @Input()
  public results: LineChartData[] = [];

  constructor() {
    super();
  }
}
