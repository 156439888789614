import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: Date | string | null | undefined, format: string): string {
    if (!value || !moment(value).isValid()) {
      return '';
    }
    return moment(value).format(format);
  }

}
