import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LatchMfaService, LatchToastAction, LatchToastType } from '@latch/latch-web';
import { LatchStorageService } from 'manager/services/storage/storage.service';
import moment from 'moment';
import { map, take } from 'rxjs/operators';
import { StorageKeys } from 'shared/common/constants';

@Component({
  selector: 'latch-mfa-enrollment-toast',
  templateUrl: './mfa-enrollment-toast.component.html',
  styleUrls: ['./mfa-enrollment-toast.component.scss'],
})
export class MfaEnrollmentToastComponent implements OnInit {
  public showToast = false;
  public ToastType = LatchToastType;
  public actions: LatchToastAction[] = [
    {
      label: 'Set up now',
      clickHandler: this.handleSetUp.bind(this),
    },
    {
      label: 'Later',
      clickHandler: this.handleDismiss.bind(this),
    },
  ];

  constructor(
    private mfaService: LatchMfaService,
    private router: Router,
    private storageService: LatchStorageService,
  ) {}

  public ngOnInit() {
    this.mfaService.getUserMFASettings().pipe(
      map(mfaSettings => mfaSettings.mfaEnabled),
      take(1),
    ).subscribe((mfaEnabled) =>
      this.showToast = !mfaEnabled && !this.storageService.getTimestamp(StorageKeys.DismissMfaEnrollment)
    );
  }

  public hideToast() {
    this.showToast = false;
  }

  private handleSetUp() {
    this.router.navigate(['/console/account/mfa']);
  }

  private handleDismiss() {
    this.storageService.setTimestamp(StorageKeys.DismissMfaEnrollment, moment().add(1, 'd').valueOf());
    this.hideToast();
  }
}
