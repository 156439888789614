<latch-package-modal
  *ngIf="packageModalType && viewDeliveryUnit"
  [packageModalType]="packageModalType"
  [deliveryUnit]="viewDeliveryUnit"
  (closeModal)="closePackageModal($event)">
</latch-package-modal>

<latch-message-modal
  *ngIf="!!messageModalType && !!message"
  [messageModalType]="messageModalType"
  [message]="message"
  (closeModal)="closeMessageModal()">
</latch-message-modal>

<latch-table
  class="latch-table-with-viewport"
  [data]="datasource"
  (scrolledToEnd)="nextPage && setPage(nextPage)"
  (rowClick)="openPackageModal('packageDetails', $event)"
  [showLoadMore]="!!nextPage"
  (loadMore)="nextPage && setPage(nextPage)">
  <latch-table-column field="title" columnTitle="Unit">
    <ng-template latchTableCellTemplate let-deliveryUnit>
      <div class="delivery-unit-details" [class.with-image]="deliveryUnit?.accessLog?.image?.url">
        <div *ngIf="deliveryUnit?.accessLog?.image?.url" class="delivery-image"
          [class.rotate]="deliveryUnit?.accessLog?.image?.imageSource === ImageSource.LOCK">
          <img [src]="deliveryUnit?.accessLog?.image?.url">
        </div>
        <div class="delivery-info">
          <div class="delivery-unit-title">Unit {{deliveryUnit.unit.displayName}}</div>
          <div>{{deliveryUnit.deliveryRequest.id}} ({{deliveryUnit.deliveryRequest.courier}})</div>
        </div>
      </div>
    </ng-template>
  </latch-table-column>
  <latch-table-column field="deliveryDateTime" columnTitle="Delivery Time" [sortable]="true">
    <ng-template latchTableCellTemplate let-deliveryUnit>
      {{deliveryUnit.deliveryRequest.deliveryDateTime | date: 'MM/dd/yyyy h:mm a'}}
    </ng-template>
  </latch-table-column>
  <latch-table-column field="packageType" columnTitle="Package Type">
    <ng-template latchTableCellTemplate let-deliveryUnit>
      {{deliveryUnit | deliveryUnitInfo : 'packages'}}
    </ng-template>
  </latch-table-column>
  <latch-table-column field="timeNotified" columnTitle="Time Notified">
    <ng-template latchTableCellTemplate let-deliveryUnit>
      <div class="packages">
        {{deliveryUnit | deliveryUnitInfo : 'timeNotified' | date: 'MM/dd/yyyy h:mm a'}}
      </div>
    </ng-template>
  </latch-table-column>
  <latch-table-column field="action" columnTitle="Action">
    <ng-template latchTableCellTemplate let-deliveryUnit>
      <button class="latch-button-link" (click)="notifyResidents($event, deliveryUnit)">Notify</button>
    </ng-template>
  </latch-table-column>
</latch-table>
