import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KeyMembership, KeyMembershipType } from '../../models/key-membership';
import { KeyOwnershipType } from '../../models/key';
import { OperationResultResponse } from '../../models/operations';

export interface GetKeyMembershipsInput {
  buildingUUID: string;

  // Optional search parameters
  userUUID?: string;
  hostUUID?: string;
  type?: KeyMembershipType;
  keyUUID?: string | string[];
  keyOwnershipType?: KeyOwnershipType;
}

export interface GetKeyMembershipInput {
  keyUUID: string;
  membershipUUID: string;
}

export interface InviteKeyMembershipInput {
  keyUUID: string;
  shareable: boolean;
  role: KeyMembershipRole;

  // Only specified for elevator key memberships
  floorGroups?: CreateFloorGroupInput[];
}

export interface CreateKeyMembershipInput {
  type: KeyMembershipType;
  buildingUUID: string;
  userUUID: string;
  keyUUID: string;
  shareable: boolean;
  role: KeyMembershipRole;
  nickname?: string;
  // Intentionally use date strings (no timezone) instead of Date objects
  // so that backend can manage localizing to effected locks.
  startTime: string;
  endTime?: string;

  // Only specified for elevator key memberships
  floorGroups?: CreateFloorGroupInput[];
}

export interface CreateKeyMembershipsInput {
  keyUUID: string;
  shareable: boolean;
  role: KeyMembershipRole;
  passcodeType: KeyMembershipType;
  users: { uuid: string, nickname?: string }[];
  // Intentionally use date strings (no timezone) instead of Date objects
  // so that backend can manage localizing to effected locks.
  startTime: string;
  endTime?: string;

  // Only specified for elevator key memberships
  floorGroups?: CreateFloorGroupInput[];
}

export interface CreateFloorGroupInput {
  keyDoorUUID: string;
  floorGroupUUID: string;
}

export interface UpdateFloorGroupInput {
  keyDoorMembershipUUID: string;
  floorGroupUUID: string;
}

export interface UpdateKeyMembershipInput {
  uuid: string;
  keyUUID: string;
  shareable: boolean;
  role: KeyMembershipRole;
  // See above about date strings
  startTime: string;
  endTime?: string;

  // Only specified for elevator key memberships
  floorGroups?: UpdateFloorGroupInput[];
}

export interface DeleteKeyMembershipInput {
  uuid: string;
  keyUUID: string;
}

export enum KeyMembershipRole {
  RESIDENT = 'RESIDENT',
  GUEST = 'GUEST',
}

export const OVERLAPPING_KEY_DOOR_CREDENTIALS = 'OVERLAPPING_KEY_DOOR_CREDENTIALS';
export const OVERLAPPING_FLOOR_GROUPS = 'OVERLAPPING_FLOOR_GROUPS';
export const OVERLAPPING_SCHEDULES = 'OVERLAPPING_SCHEDULES';

@Injectable()
export abstract class KeyMembershipService {
  /**
   * Returns the set of key memberships meeting all the criteria in input.
   *
   * If keyUUIDs is an array, returns key memberships matching any of those keys.
   *
   * For example, if buildingUUID is x, keyOwnershipType is y, and keyUUID is [1, 2],
   * this method will return all key memberships in building x AND with ownership type y
   * AND on either keyUUID 1 or keyUUID 2.
   *
   * If keyUUID is an empty array, this is interpreted as a request for all key memberships
   * in no keys (in other words, if keyUUID is an empty array, no key memberships will be
   * returned).
   */
  abstract getKeyMemberships(input: GetKeyMembershipsInput): Observable<KeyMembership[]>;

  abstract getKeyMembership(input: GetKeyMembershipInput): Observable<KeyMembership>;

  abstract createKeyMembership(input: CreateKeyMembershipInput): Observable<OperationResultResponse>;

  abstract createKeyMemberships(input: CreateKeyMembershipsInput): Observable<OperationResultResponse>;

  abstract updateKeyMembership(input: UpdateKeyMembershipInput): Observable<OperationResultResponse>;

  abstract deleteKeyMembership(input: DeleteKeyMembershipInput): Observable<OperationResultResponse>;
}
