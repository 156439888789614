<ng-container [ngSwitch]="currentStep">
  <latch-card class="page" *ngSwitchCase="Step.Confirm">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="goBack($event)">
          <span class="latch-icon-chevron-left"></span>
        </button>
      </div>
      <div class="latch-card-title">Require Two-Factor Authentication</div>
      <div class="page-description">Further strengthen your portfolio's security by requiring Two-Factor Authentication for all portfolio managers and property managers for {{accountName}}.
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <div class="field-list">
        <div class="cell cell-description">
          <div class="cell-text">Require authentication codes at login for property managers and portfolio managers
          </div>
        </div>
        <div class="cell">
          <button class="latch-button" (click)="confirmChange($event)">Confirm</button>
        </div>
      </div>
    </ng-container>
  </latch-card>

  <latch-card class="page" *ngSwitchCase="Step.Success">
    <ng-container latchCardHeader>
      <div class="latch-card-title">Success</div>
      <div class="page-description">You've required Two-Factor Authentication for all portfolio managers and property managers.
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <img src="shared/assets/images/mfa-required.svg" alt="enabled" class="logo-verify">
    </ng-container>
    <ng-container latchCardFooter>
      <button type="button" class="latch-button-outline" (click)="finished($event)">close</button>
    </ng-container>
  </latch-card>
</ng-container>
