import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter',
  pure: false
})
export class ListFilterPipe implements PipeTransform {
  transform(list: any[], query: any, filter: any) {
    if (query == null) {
      return list;
    }
    return filter(list, query);
  }
}
