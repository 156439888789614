import { Component, HostBinding, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { auditTime, fromEvent, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'latch-scroll-arrows',
  templateUrl: './scroll-arrows.component.html',
  styleUrls: ['./scroll-arrows.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScrollArrowsComponent implements OnInit, OnDestroy {

  @HostBinding('class.latch-scroll-arrows')
  public scrollArrowsClass = true;

  public showLeftScrollArrow = false;
  public showRightScrollArrow = false;

  @Input()
  public scrollContainer: HTMLElement | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
  ) { }

  public ngOnInit() {
    this.initializeScrollSubscription();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeScrollSubscription(): void {
    const element = this.scrollContainer;
    if (element) {
      this.ngZone.runOutsideAngular(() => {
        fromEvent(element, 'scroll').pipe(
          startWith({}),
          auditTime(100),
          takeUntil(this.unsubscribe$),
        ).subscribe(() => {
          this.ngZone.run(() => {
            if (element.scrollLeft > 100) {
              this.showLeftScrollArrow = true;
            } else {
              this.showLeftScrollArrow = false;
            }

            if ((element.scrollLeft + element.clientWidth + 100) < element.scrollWidth) {
              this.showRightScrollArrow = true;
            } else {
              this.showRightScrollArrow = false;
            }
          });
        });
      });
    }
  }

  public onLeftScrollClick(): void {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: -this.scrollContainer.scrollLeft, behavior: 'smooth' });
    }
  }

  public onRightScrollClick(): void {
    if (this.scrollContainer) {
      this.scrollContainer.scrollBy({ left: this.scrollContainer.scrollWidth, behavior: 'smooth' });
    }
  }

}
