import moment from 'moment';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DaysOfWeek, QualifiedDay, TimeInterval } from '../../../models/key';
import { cloneDeep, keyBy } from 'manager/services/utility/utility';

export const TWENTY_FOUR_SEVEN = DaysOfWeek.map((day) => ({
  day,
  timeIntervals: [{
    start: 0,
    end: 24 * 60 * 60
  }]
}));

const START_TIMES = [...Array(24).keys()].reduce(
  (hourTimes: number[], hours) => hourTimes.concat(
    [0, 15, 30, 45].reduce((minuteTimes: number[], minutes) => minuteTimes.concat((hours * 60 + minutes) * 60), [])
  ), []
);

const END_TIMES = [...Array(24).keys()].reduce(
  (hourTimes: number[], hours) => hourTimes.concat(
    [15, 30, 45, 60].reduce((minuteTimes: number[], minutes) => minuteTimes.concat((hours * 60 + minutes) * 60), [])
  ), []
);

interface SelectableQualifiedDay {
  dayLetter: string;
  qualifiedDay: QualifiedDay;
  selected?: boolean;
}

const TIME_INTERVAL: TimeInterval = { start: START_TIMES[0], end: END_TIMES[END_TIMES.length - 1] };
const DAYS_SELECTION: SelectableQualifiedDay[] = ['M', 'T', 'W', 'R', 'F', 'S', 'S'].map((letter, ind) => ({
  dayLetter: letter,
  qualifiedDay: {
    day: DaysOfWeek[ind],
    timeIntervals: [TIME_INTERVAL]
  },
  selected: true
}));

@Component({
  selector: 'latch-schedule-picker',
  templateUrl: './schedule-picker.component.html',
  styleUrls: ['./schedule-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SchedulePickerComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulePickerComponent implements ControlValueAccessor, OnInit {
  @Input() canEdit = false;
  @Output() valueChange = new EventEmitter<QualifiedDay[]>();

  // Selected days (indexes correspond to DaysOfWeek)
  days: SelectableQualifiedDay[] = cloneDeep(DAYS_SELECTION);

  StartTimes: number[] = START_TIMES;
  EndTimes: number[] = END_TIMES;

  readonly timeIntervalForm = new FormGroup({
    start: new FormControl<number>(START_TIMES[0], { nonNullable: true }),
    end: new FormControl<number>(END_TIMES[END_TIMES.length - 1], { nonNullable: true })
  });

  get noDaysSelected(): boolean {
    return !this.days.some((d) => d.selected);
  }

  get timeInterval(): TimeInterval {
    return this.timeIntervalForm.getRawValue();
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.timeIntervalForm.valueChanges.subscribe(() => this.emitChange());
  }

  getTimeText(minutes: number) {
    return moment.utc(minutes * 1000).format('h:mm A');
  }

  handleToggleDay(day: SelectableQualifiedDay) {
    day.selected = !day.selected;
    this.emitChange();
  }

  emitChange() {
    const intervalFormValue = this.timeInterval;
    if (intervalFormValue.start >= intervalFormValue.end) {
      this.timeIntervalForm.setValue({ ...intervalFormValue, end: this.EndTimes[this.EndTimes.length - 1] });
    }

    const qualifiedDays = this.days.filter(day => day.selected)
      .map((day) => ({
        ...day.qualifiedDay,
        timeIntervals: [this.timeInterval]
      }));

    this.valueChange.emit(qualifiedDays);
    this.changeDetectorRef.markForCheck();
  }

  writeValue(qualifiedDays: QualifiedDay[]) {
    if (!qualifiedDays || qualifiedDays.length === 0) {
      qualifiedDays = cloneDeep(TWENTY_FOUR_SEVEN);
    }

    const qualifiedDaysByDay = keyBy(qualifiedDays, 'day');
    this.days.forEach(day => {
      day.selected = !!qualifiedDaysByDay[day.qualifiedDay.day];
    });

    const timeInterval = qualifiedDays[0].timeIntervals[0];
    this.timeIntervalForm.setValue(timeInterval);

    this.changeDetectorRef.markForCheck();
  }

  isEndTimeDisabled(endTime: number): boolean {
    return endTime <= this.timeIntervalForm.getRawValue().start;
  }

  registerOnChange(fn: (val: QualifiedDay[]) => void) {
    this.valueChange.subscribe(fn);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(): void { }
}
