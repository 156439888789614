<ng-container>

  <div class="days">
    <button *ngFor="let day of days" class="day" [class.active]="day.selected" type="button" [disabled]="disabled"
      (click)="handleToggleDay(day)">
      {{day.dayLetter}}
    </button>
  </div>

  <form *ngIf="mode !== SlotDuration.OVERNIGHT" class="times" [formGroup]="timeIntervalForm">
    <div *ngFor="let time of daysSelectedTimeIntervals" class="days-selected-time-intervals">
      <span class="title" *ngIf="showDay(time)">{{getDayTitle(time)}}</span>
      <span class="title" *ngIf="!showDay(time)"></span>

      <latch-dropdown [data]="time.startTimes" [formControlName]="time.formKeys.start">
      </latch-dropdown>
      <div class="connector">to</div>
      <latch-dropdown [data]="time.endTimes" [formControlName]="time.formKeys.end">
      </latch-dropdown>

      <ng-container *ngIf="!disabled && !disableMultipleTimeSlotsPerDay">
        <button class="latch-button-icon add" type="button" *ngIf="showAddTime(time)" (click)="handleAddTime(time)">
          <span class="icon-circle-plus"></span>
        </button>
        <button class="latch-button-icon remove" type="button" *ngIf="showRemoveTime(time)"
          (click)="handleRemoveTime(time)">
          <span class="icon-circle-minus"></span>
        </button>
        <button class="latch-button-link copy" type="button" *ngIf="showCopyToAll(time)"
          (click)="handleCopyToAll(time)">
          Copy to all
        </button>
      </ng-container>
    </div>
  </form>

  <form *ngIf="mode === SlotDuration.OVERNIGHT" class="times" [formGroup]="overnightForm">
    <div class="days-selected-time-intervals">
      <span class="title">Selected day(s)</span>
      <latch-dropdown [data]="overnight.startTimes" formControlName="start" [disabled]="disabled"></latch-dropdown>
      <div class="connector">to</div>
      <latch-dropdown class="next-day" [data]="overnight.endTimes" formControlName="end" [disabled]="disabled">
      </latch-dropdown>
    </div>
  </form>
</ng-container>
