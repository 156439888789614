
import { Injectable } from '@angular/core';
import { SpaceType, Space } from 'manager/models/space';
import { Observable } from 'rxjs';

export type SpaceShortInfo = Omit<Space, 'unitId' | 'childrenUUIDs'>;

export interface GetSpacesResponse {
  spaces: SpaceShortInfo[];
}

@Injectable()
export abstract class SpaceService {

  abstract getSpaces(rootUUID: string, spaceTypes?: SpaceType[]): Observable<SpaceShortInfo[]>;

  /**
   * Given a room and a directory of spaces, return the unit the room belongs to.
   */
  getUnitForRoom(space: SpaceShortInfo, spacesByUUID: Record<string, SpaceShortInfo>): SpaceShortInfo | undefined {
    if (space.spaceType !== SpaceType.Room) {
      return undefined;
    }
    let pointer: SpaceShortInfo | undefined = space;
    while (pointer && pointer.spaceType !== SpaceType.ResidentialUnit) {
      pointer = pointer.parentUUID ? spacesByUUID[pointer.parentUUID] : undefined;
    }
    return pointer;
  }
}
