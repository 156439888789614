
import { FloorGroup } from '../../models/floor-group';
import { Lock } from '../../models/lock';

/**
 * Returns the details of the floor group specified by floorGroupUUID if it is a floor group on lock. Otherwise, returns null.
 */
export const getFloorGroupFromLock = (lock: Lock, floorGroupUUID: string): FloorGroup | undefined => {
  if (!lock.elevator) {
    return undefined;
  }
  // floorGroupUUID is unique acroos all elevators.
  // Even if two elevators have floor groups with the same name, containing the same list of floors,
  // they are conceptually different floor groups with unique ids
  const floorGroup = lock.elevator.floorGroups.find(item => item.uuid === floorGroupUUID);
  if (floorGroup) {
    return floorGroup;
  }
  if (lock.elevator.doorcodeFloorGroup && lock.elevator.doorcodeFloorGroup.uuid === floorGroupUUID) {
    return lock.elevator.doorcodeFloorGroup;
  }

  return undefined;
};
