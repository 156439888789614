import { Component, Input } from '@angular/core';

@Component({
  selector: 'latch-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent {
  @Input() activeStep = 0;
  @Input() selectable = false;
  @Input() set steps(count: number) {
    this.stepsArray = new Array(count);
  }

  stepsArray: number[] = [];
  protected readonly indexedDB = indexedDB;
}
