<div class="key-card" [class.editing]="selectedKey.hideSettings">
  <div
    [ngClass]="{
      'icon-door-key': keyIconType(selectedKey.key) === KeyIconType.Door,
      'icon-delivery-key': keyIconType(selectedKey.key) === KeyIconType.Delivery}"
  ></div>
  <div class="vertical">
    <div class="title">{{selectedKey.key.name}}</div>
    <div class="subtitle" *ngIf="!countSelectedKeyIntercoms">
      <strong>{{selectedKey.key.doors.length}}</strong>
      {{pluralize(selectedKey.key.doors, 'door')}}
    </div>
    <div class="subtitle" *ngIf="countSelectedKeyIntercoms">
      <strong>{{selectedKey.key.doors.length}}</strong>
      {{pluralize(selectedKey.key.doors, 'door')}}, <strong>{{countSelectedKeyIntercoms}}</strong> {{pluralize(countSelectedKeyIntercoms, 'intercom')}}
    </div>
  </div>
  <button *ngIf="!selectedKey.readOnly" type="button" class="latch-button-icon" (click)="handleRemove()">
    <span class="latch-icon-close"></span>
  </button>
</div>
<div class="card-details" *ngIf="!selectedKey.hideSettings">
  <div class="card-detail-row">
    <latch-checkbox [checked]="selectedKey.role === KeyMembershipRole.RESIDENT"
      label="Resident" labelPosition="after" class="text text-dark" data-id="resident"
      (checkedChange)="handleToggleRole()" [disabled]="selectedKey.readOnly === true">
    </latch-checkbox>
  </div>
  <div class="card-detail-row">
    <latch-checkbox [checked]="selectedKey.shareable"
      label="May share key" labelPosition="after" class="text text-dark" data-id="may-share-key"
      (checkedChange)="handleToggleGuests()" [disabled]="selectedKey.readOnly === true">
    </latch-checkbox>
  </div>
</div>
