import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'manager/services/auth/auth.service';
import { combineLatest, map, Observable, of, switchMap, throwError } from 'rxjs';
import { Space, SpaceType } from '../models/spaces';
import { CreateSpaceInput, GetSpacesInput, SpacesService } from './spaces.service';

@Injectable({
  providedIn: 'root'
})
export class HTTPSpacesService implements SpacesService {
  constructor(
    private authService: AuthService,
  ) { }

  public getSpaces(buildingUUID: string, input: GetSpacesInput): Observable<Space[]> {
    const search = new HttpParams()
      .append('depth', '3')
      .append('metadataMask', '*');
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v2/spaces/0/buildings/${buildingUUID}/communal/*`,
      search
    }).pipe(
      map(response => AuthService.getPayload(response) as Space[]),
      map(spaces => {
        spaces = spaces
          .filter(space => (space.metadata?.ui?.name ?? '').toLowerCase().includes(input.searchTerm?.toLowerCase() ?? ''))
          .filter(space => (input.spaceTypes && input.spaceTypes.length > 0) ? input.spaceTypes.some(st => st === space.spaceType) : true)
          .filter(space => space.spaceType !== SpaceType.ResidentialUnit);

        if (input.sort === 'name') {
          const sort = input.sort;
          spaces.sort((a, b) => (a.metadata?.ui?.name ?? '').toLowerCase().localeCompare((b.metadata?.ui?.name ?? '').toLowerCase()));
          if (input.sortDescending) {
            spaces.reverse();
          }
        }
        return spaces;
      })
    );
  }

  public getSpace(buildingUUID: string, uuid: string): Observable<Space> {
    const search = new HttpParams()
      .append('depth', '3')
      .append('metadataMask', '*');
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v2/spaces/${uuid}`,
      search
    }).pipe(
      switchMap((response: HttpResponse<Space>) => {
        const result = AuthService.getPayload(response) as Space[];
        if (result?.length > 0) {
          return of(result[0]);
        } else {
          return throwError(() => new Error('Could not find Space.'));
        }
      }),
    );
  }

  public createSpace(buildingUUID: string, space: CreateSpaceInput): Observable<Space> {
    const uuid = crypto.randomUUID();

    const search = new HttpParams()
      .append('depth', '1');
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v2/spaces/0/buildings/${buildingUUID}/floors/*`,
      search
    }).pipe(
      switchMap(floorsResponse => {
        const parent = AuthService.getPayload(floorsResponse)[0] as Space;
        if (parent) {
          space.parentPath = parent.path ?? '';
          return this.authService.request({
            method: 'post',
            endpoint: `/web/v2/spaces/${space.parentPath}`,
            data: {
              space: {
                ...space,
                uuid: uuid,
              }
            }
          }).pipe(
            map((response: HttpResponse<Space>) => AuthService.getPayload(response) as Space),
          );
        } else {
          return throwError(() => new Error('Could not find a floor Space.'));
        }
      }),
    );



  }

  public updateSpace(buildingUUID: string, space: Space): Observable<Space> {
    const search = new HttpParams()
      .append('deleteAndCreate', 'true');
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v2/spaces/${space.path ?? ''}`,
      search,
      data: {
        space
      }
    }).pipe(
      map((response: HttpResponse<Space>) => AuthService.getPayload(response).space as Space),
    );
  }

  public deleteSpace(buildingUUID: string, space: Space): Observable<null> {
    return combineLatest(
      (space.subspaces?.[SpaceType.Access] ?? []).map(s => this.authService.request({
        method: 'delete',
        endpoint: `/web/v2/spaces/${s.path ?? ''}`,
      }))
    ).pipe(
      switchMap(() => this.authService.request({
        method: 'delete',
        endpoint: `/web/v2/spaces/${space.path ?? ''}`,
      }).pipe(
        map((response: HttpResponse<null>) => AuthService.getPayload(response) as null)
      )),
    );
  }
}
