import { Component, OnInit } from '@angular/core';
import { LatchDatasource, LatchDialogService, LatchNavbarStateService, LatchSortDescriptor } from '@latch/latch-web';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, take, takeUntil } from 'rxjs/operators';
import { NO_SEARCH_RESULTS } from 'shared/common/constants';
import { ImageSource } from '../../../models/access-log';
import { Building } from '../../../models/building';
import { DeliveriesFilter, DeliveryList, DeliveryPriority } from '../../../models/delivery';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { DeliveryService } from '../../../services/delivery/delivery.service';
import { DeliveryDetailsComponent } from '../delivery-details/delivery-details.component';

const PAGE_START_DEFAULT = 1;
const PAGE_SIZE_DEFAULT = 20;

@Component({
  selector: 'latch-delivery-activity-list-page',
  templateUrl: './delivery-activity-list-page.component.html',
  styleUrls: ['./delivery-activity-list-page.component.scss']
})
export class DeliveryActivityListPageComponent implements OnInit {
  public filter: DeliveriesFilter = { page: PAGE_START_DEFAULT, pageSize: PAGE_SIZE_DEFAULT, sort: '', search: '' };
  public nextPage: number | null = null;
  public deliveries: DeliveryList[] = [];
  public datasource = new LatchDatasource<DeliveryList>({});
  public DeliveryPriority = DeliveryPriority;
  public ImageSource = ImageSource;
  public hasSmartHomeFeature = false;

  private deliveriesSubscription?: Subscription;
  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private latchNavbarStateService: LatchNavbarStateService,
    private deliveryService: DeliveryService,
    private pageWithTabsService: PageWithTabsService,
    private dialog: LatchDialogService
  ) { }

  public get emptyListMessage(): string {
    const emptySearch = !this.latchNavbarStateService.searchControl.value;

    return emptySearch ? NO_SEARCH_RESULTS : 'There are no logs to view.';
  }

  ngOnInit(): void {
    this.latchNavbarStateService.initializeSearch({ placeholder: 'Search Deliveries' });

    this.selectedBuildingsService.getSelectedBuildings().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildings => {
      this.selectedBuilding = buildings[0];
      this.checkSortChange();
      this.setPage(PAGE_START_DEFAULT);
    });
    this.latchNavbarStateService.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(search => this.setFilterSearch(search));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDelivery(delivery: DeliveryList): void {
    this.dialog.open(DeliveryDetailsComponent, {
      data: delivery,
      width: '100%',
      maxWidth: '600px'
    });
  }

  setPage(page: number) {
    this.filter.page = page;
    this.getDeliveries(this.selectedBuilding.uuid, this.filter);
  }

  private checkSortChange(): void {
    this.datasource.sortChange().pipe(
      distinctUntilChanged(),
      filter(sort => !!sort.active),
      takeUntil(this.unsubscribe$)
    ).subscribe(sort => {
      if (sort.direction) {
        this.filter.sort = this.getSortValue(sort);
      } else {
        this.filter.sort = '';
      }
      this.setPage(PAGE_START_DEFAULT);
    });
  }

  private getSortValue(sort: LatchSortDescriptor): string {
    return `${sort.active}:${sort.direction.toUpperCase()}`;
  }

  private setFilterSearch(search: string): void {
    this.filter.search = search;
    this.setPage(PAGE_START_DEFAULT);
  }

  private getDeliveries(buildingUuid: string, deliveriesFilter: DeliveriesFilter): void {
    this.datasource.startLoading();
    if (this.deliveriesSubscription) {
      this.deliveriesSubscription.unsubscribe();
    }
    this.deliveriesSubscription = this.deliveryService.getDeliveries(buildingUuid, deliveriesFilter).pipe(
      finalize(() => this.datasource.stopLoading()),
      takeUntil(this.unsubscribe$)
    ).subscribe(deliveriesListReponse => {
      this.nextPage = deliveriesListReponse.metadata.page < deliveriesListReponse.metadata.totalPages ?
        deliveriesListReponse.metadata.page + 1 : null;
      if (deliveriesFilter.page === 1) {
        this.deliveries = [];
      }
      if (deliveriesListReponse.elements && deliveriesListReponse.elements.length > 0) {
        deliveriesListReponse.elements.forEach(a => this.deliveries.push(a));
      }
      this.datasource.set(this.deliveries);
      this.updateSubnavSubtitle(deliveriesListReponse.metadata.totalElements);
    });
  }

  private updateSubnavSubtitle(totalDeliveries: number) {
    this.pageWithTabsService.setSubnavSubtitle(totalDeliveries ? `(${totalDeliveries})` : '');
  }
}
