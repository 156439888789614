<latch-import-csv
  *ngIf="currentStep === Step.Upload"
  [importName]="importName"
  [columnNames]="columnNames"
  [uploadTemplate]="uploadTemplate"
  [maxRows]="maxRows"
  [pageDescription]="pageDescription"
  entity="Units"
  (cancel)="cancel.emit()"
  (csvResults)="handleImport($event)"
></latch-import-csv>
<latch-import-csv-result-page
  *ngIf="currentStep === Step.Summary"
  [importName]="importName"
  [valid]="valid"
  [invalid]="invalid"
  (cancel)="currentStep = Step.Upload"
  (import)="import.emit(valid)"
></latch-import-csv-result-page>
