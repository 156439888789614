import moment from 'moment';

import { Accessibility, LockType } from '../../models/lock';
import { KeyMembership, KeyMembershipType } from '../../models/key-membership';
import { Key, KeyListView, KeyOwnershipType, KeyType } from 'manager/models/key';

export const AccessibilityText = {
  [Accessibility.PUBLIC]: 'Building Entrance',
  [Accessibility.COMMUNAL]: 'Communal',
  [Accessibility.PRIVATE]: 'Residence',
  [Accessibility.SERVICE]: 'Service',
  [Accessibility.ELEVATOR]: 'Elevator'
};

export const KeyTypeGroupText = {
  [KeyType.Elevator]: 'Elevator Keys',
  [KeyType.Door]: 'Door Keys'
};

export enum KeyIconType {
  Delivery,
  Door,
  Elevator
}

type KeyOrMembership = Key | KeyListView | KeyMembership;

function isKeyMembership(keyOrMembership: KeyOrMembership): keyOrMembership is KeyMembership {
  return !!(keyOrMembership as KeyMembership).keyUUID &&
    !!(keyOrMembership as KeyMembership).userUUID;
}

export const getKeyIconType = (keyOrMembership: KeyOrMembership): KeyIconType => {
  const ownershipType: KeyOwnershipType | undefined = isKeyMembership(keyOrMembership) ?
    keyOrMembership.keyType : keyOrMembership.ownershipType;

  const keyType: KeyType = isKeyMembership(keyOrMembership) ?
    (keyOrMembership.keyKeyType ?? KeyType.Door) : keyOrMembership.keyType;

  if (ownershipType === KeyOwnershipType.Delivery) {
    return KeyIconType.Delivery;
  }

  switch (keyType) {
    case KeyType.Elevator:
      return KeyIconType.Elevator;

    case KeyType.Door:
    default:
      return KeyIconType.Door;
  }
};

/**
 * The "lock type" surfaced to the user is actually a combination of the lock's actual type (elevator vs door)
 * and accessibility. Elevators are assumed to be "communal", so accessibility is not relevant when the lock
 * is an elevator.
 */
export const getUserFacingLockTypeText = (
  accessibilityType: Accessibility | undefined,
  lockType: LockType
): string => {
  if (lockType === LockType.ELEVATOR) {
    return 'Elevator';
  } else if (accessibilityType) {
    return AccessibilityText[accessibilityType];
  }
  return '';
};

// Join first and last names but omit either if it's missing
export function getObjFullName(obj:
  { firstName: string, lastName?: string } |
  { userFirstName: string, userLastName?: string }): string {
  if ('firstName' in obj) {
    return `${obj.firstName || ''} ${obj.lastName || ''}`;
  }
  return `${obj.userFirstName || ''} ${obj.userLastName || ''}`;
}

// Join first and last names but omit either if it's missing
export const getFullName = (firstName: string, lastName?: string) => `${firstName || ''} ${lastName || ''}`;

export const UnexpectedErrorText = 'Something unexpected happened. If this problem persists, please contact support@latch.com.';

export const getMembershipName = (membership: KeyMembership): string =>
  membership.userNickname || getFullName(membership.userFirstName ?? '', membership.userLastName);

export const getMembershipTypeHTML = (membership: KeyMembership) => {
  if (membership.type === KeyMembershipType.Permanent) {
    return '<strong>App</strong>';
  }

  const doorcodeDateText = getMembershipDateText(membership).toLowerCase();
  return `<strong>Doorcode</strong> (${doorcodeDateText})`;
};

export function getMembershipDateText(membership: KeyMembership): string {
  let result = '';
  const startTime = moment(membership.startTime);
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  if (membership.type === KeyMembershipType.Permanent) {
    const endText = membership.endTime ?
      ' - ' + moment(membership.endTime).format('MM/DD/YYYY') : '';
    result = today.isSame(startTime, 'day') ?
      'Today' : tomorrow.isSame(startTime, 'day') ?
        'Tomorrow' : (startTime.format('MM/DD/YYYY') + endText);
  } else {
    const text = today.isSame(startTime, 'day') ?
      'Today' : tomorrow.isSame(startTime, 'day') ?
        'Tomorrow' : startTime.format('MM/DD/YYYY');
    result = `${text} until midnight`;
  }
  return result;
}

export function getMembershipEndDateText(membership: KeyMembership): string {
  let result = '';
  const startTime = moment(membership.startTime);
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  if (membership.type === KeyMembershipType.Permanent) {
    const endText = membership.endTime ?
      moment(membership.endTime).format('MM/DD/YYYY') : 'N/A';
    result = endText;
  } else {
    const text = today.isSame(startTime, 'day') ?
      'Today' : tomorrow.isSame(startTime, 'day') ?
        'Tomorrow' : startTime.format('MM/DD/YYYY');
    result = `${text} until midnight`;
  }
  return result;
}

export function getMembershipStartDateText(membership: KeyMembership): string {
  const startTime = moment(membership.startTime);
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const text = today.isSame(startTime, 'day') ?
    'Today' : tomorrow.isSame(startTime, 'day') ?
      'Tomorrow' : startTime.format('MM/DD/YYYY');
  return text;
}
