<latch-card class="page" *ngIf="!viewingFloorGroup">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="cancel.emit()">
        <span class="latch-icon-chevron-left"></span>
      </button>
    </div>
    <div class="latch-card-title">{{ getPageHeader() }}</div>
    <div *ngIf="defaultFloorGroupSelectMode" class="text-1">
      {{getPageHeaderSubtext()}}
    </div>
    <br>
    <input type="text" placeholder="Search" class="latch-card-search" [(ngModel)]="search"/>
  </ng-container>

  <ng-container latchCardContent>
    <div class="cell-list" *ngIf="!!floorGroups && floorGroups.length !== 0">
      <div class="cell cell-row" *ngFor="let floorGroup of floorGroups | listFilter: search : filter">
        <div class="cell-body">
          <latch-checkbox [label]="floorGroup.name" labelPosition="after"
            [checked]="isItemSelected(floorGroup)"
            (checkedChange)="selectFloorGroup(floorGroup)">
          </latch-checkbox>
          <button
            class="icon-info-circle"
            (click)="viewFloorGroup(floorGroup)">
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container latchCardFooter>
    <button
      class="latch-button"
      [disabled]="!selectedFloorGroupUUID"
      (click)="confirmSave()"
    >Save
    </button>
    <div class="prompt" *ngIf="showConfirmPrompt">
      <div class="content">
        <div class="message">
          <p>
            {{warningMessage}}
          </p>
        </div>
        <div class="actions">
          <button class="latch-button-text" (click)="showConfirmPrompt = false">
            Cancel
          </button>
          <button class="latch-button-text" (click)="handleSave()">
            Done
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</latch-card>

<latch-floor-group-floor-list
    *ngIf="!!viewingFloorGroup"
    [floorGroup]="viewingFloorGroup"
    (cancel)="stopViewingFloorGroup()">
</latch-floor-group-floor-list>
