/**
 * Date Time Utilities
 *
 * Primarily used for start/end time logic.
 */

import { Moment } from 'moment';
import moment from 'moment-timezone';

export enum Meridiem {
  AM = 'AM',
  PM = 'PM'
}

export interface DateTimeRange {
  start: DateTime;
  end: DateTime;
}

export interface DateTime {
  date?: string;
  time?: string;
  meridiem?: Meridiem;
}

export const DATE_DISPLAY_FORMAT = 'M/D/YY';
export const DATE_PARSE_FORMATS = [
  'M/D/YY',
  'M/D/YYYY',
  'MM/DD/YY',
  'MM/DD/YYYY'
];
export const TIME_DISPLAY_FORMAT = 'h:mm';
export const TIME_PARSE_FORMATS = [
  'h:mm A',
  'hh:mm A',
  'h A',
  'hh A'
];
export const ISO_NO_TZ_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export const getValidTimeZone = (timeZone?: string) => (timeZone && moment.tz.names().includes(timeZone)) ?
  timeZone :
  moment.tz.guess();

export const getParsedTime = (dateTime: DateTime, validateTime = false): Moment => {
  const date = moment(dateTime.date, DATE_PARSE_FORMATS, true);
  const time = moment(`${dateTime.time ?? ''} ${dateTime.meridiem ?? ''}`, TIME_PARSE_FORMATS, true);
  if (time.isValid() || !validateTime) {
    return moment(date)
      .hours(time.hours())
      .minutes(time.minutes());
  } else {
    return moment.invalid();
  }
};

export const today = () => moment().startOf('day');

export const momentToDateTime = (momentArg: moment.Moment) => {
  const dateTime: DateTime = {
    date: momentArg.format(DATE_DISPLAY_FORMAT),
    time: momentArg.format(TIME_DISPLAY_FORMAT),
    meridiem: momentArg.format('A') === 'AM' ? Meridiem.AM : Meridiem.PM
  };
  return dateTime;
};

export function serializeDateTime(dateTime: DateTime): string {
  return `${dateTime.date ?? ''} ${dateTime.time ?? ''} ${dateTime.meridiem ?? ''}`;
}

export function deserializeDateTime(dateTimeString: string): DateTime {
  const [date, time, meridiem] = dateTimeString.split(' ');
  return {
    date,
    time,
    meridiem,
  } as DateTime;
}

interface ValidateTimesOptions {
  mountTime: Moment;
  canEditStartTime?: boolean;
}

interface TimeErrors {
  startTime?: string;
  endTime?: string;
}

export const validateTimes = (errors: TimeErrors, start: DateTime, end: DateTime, options: ValidateTimesOptions) => {
  let startTime;

  if (options.canEditStartTime) {
    if (!start.date || !start.time) {
      return errors.startTime = 'A start time is required.';
    }

    const startDate = moment(start.date, DATE_DISPLAY_FORMAT, true);
    if (!startDate.isValid()) {
      return errors.startTime = 'A valid date must be entered in MM/DD/YY format.';
    }

    const startHoursMinutes = moment(start.time, TIME_DISPLAY_FORMAT, true);
    if (!startHoursMinutes.isValid()) {
      return errors.startTime = 'A valid time must be entered in HH:MM format.';
    }

    startTime = getParsedTime(start);
    if (startTime.isBefore(options.mountTime)) {
      return errors.startTime = 'The start time must occur now or in the future.';
    }
  } else {
    startTime = getParsedTime(start);
  }

  // Use existence of meridiem (since it always has to be something) to check if an expiration is enabled
  if (!end.meridiem) {
    return;
  }

  if (!end.time) {
    return errors.endTime = 'An expiration time is required.';
  }

  const endDate = moment(end.date, DATE_DISPLAY_FORMAT, true);
  if (!endDate.isValid()) {
    return errors.endTime = 'A valid date must be entered in MM/DD/YY format.';
  }

  const endHoursMinutes = moment(end.time, TIME_DISPLAY_FORMAT, true);
  if (!endHoursMinutes.isValid()) {
    return errors.endTime = 'A valid time must be entered in HH:MM format.';
  }

  const endTime = getParsedTime(end);
  if (endTime.isSameOrBefore(startTime)) {
    return errors.endTime = 'The expiration must be later than the start time.';
  }

  if (endTime.isBefore(options.mountTime)) {
    return errors.endTime = 'The expiration must occur now or in the future.';
  }
};

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export const DaysOfWeek = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday
];
