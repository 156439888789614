import { Component, OnDestroy, OnInit } from '@angular/core';
import { LatchDatasource, LatchNavAction } from '@latch/latch-web';
import { Building, PmsSourceDisplay } from 'manager/models/building';
import { LeaseStatus, LeaseStatusDisplay } from 'manager/models/pms-access';
import { DuplicateEmail } from 'manager/models/user';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { DuplicatesService } from 'manager/services/duplicates/duplicates.service';
import { map, Subject, switchMap, take, takeUntil } from 'rxjs';
import moment from 'moment';

interface GroupedDuplicateEmail {
  email: string;
  duplicates: DuplicateEmail[];
}

@Component({
  selector: 'latch-duplicate-emails',
  templateUrl: './duplicate-emails.component.html',
  styleUrls: ['./duplicate-emails.component.scss']
})
export class DuplicateEmailsComponent implements OnInit, OnDestroy {
  isLoading = false;
  subTitle = '';
  actions: LatchNavAction[] = [
    {
      id: 'support',
      name: 'Contact Latch Support',
      path: '/console/support'
    }
  ];
  datasource = new LatchDatasource<GroupedDuplicateEmail>({});
  duplicates?: GroupedDuplicateEmail[];
  building?: Building;
  lastUpdated?: moment.Moment;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private duplicatesService: DuplicatesService,
  ) { }

  ngOnInit(): void {
    this.datasource.startLoading();
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      take(1),
      map(buildings => buildings[0]),
      switchMap(building => {
        this.building = building;
        return this.duplicatesService.getDuplicateEmails(building.uuid);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      duplicates => {
        if (this.building?.pmsUpdateTime) {
          this.lastUpdated = moment(this.building?.pmsUpdateTime);
          this.subTitle = `Last Updated ${this.lastUpdated.format('MM/DD/YY, h:mma')}`;
        }
        const groupedDuplicates = duplicates.reduce((prev, curr) => {
          const emailGroup = prev.find(eg => eg.email === curr.email);
          if (emailGroup) {
            emailGroup.duplicates.push(curr);
          } else {
            prev.push({
              email: curr.email,
              duplicates: [curr],
            });
          }
          return prev;
        }, [] as GroupedDuplicateEmail[]);
        this.duplicates = groupedDuplicates;
        this.datasource.set(this.duplicates);
        this.datasource.stopLoading();
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPmsSourceDisplay(): string {
    const pmsSource = this.building?.pmsSource;
    return pmsSource ? PmsSourceDisplay[pmsSource] : '';
  }
}
