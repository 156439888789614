<latch-loading [isActive]="isLoading">
  <latch-subnav headerTitle="My Profile"></latch-subnav>
  <div *ngIf="!!user" class="latch-viewport">

    <latch-labeled-list-item label="Name">
      {{user | fullName}}
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Email">
      {{user.email}}
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Password">
      <ng-container *ngIf="currentStep === step.InitiateChangePassword">
        <a (click)="currentStep = step.ConfirmChangePassword">Change Password</a>
      </ng-container>
      <ng-container  *ngIf="currentStep === step.ConfirmChangePassword">
        <div class="eyebrow-text">Are you sure you want to reset your password? We will send an email to {{user.email}}.</div>
        <a
          type="button"
          class="latch-button-outline latch-dark"
          (click)="handleChangePassword()">
          Confirm
        </a>
        <a
          type="button"
          class="latch-button-link"
          (click)="currentStep=step.InitiateChangePassword">
          Cancel
        </a>
      </ng-container>
      <ng-container *ngIf="currentStep === step.ConfirmEmailSent">
        <div class="eyebrow-text">
          An email with your password reset link has been sent. If you don't see an email, please check your spam folder.
        </div>
        <a
          type="button"
          class="latch-button-outline latch-dark"
          (click)="currentStep = step.InitiateChangePassword">
          Confirm
        </a>
      </ng-container>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Two-Factor Authentication">
      <div class="eyebrow-text">
        Add additional security to your user account.
        <span *ngIf="!mfaEnabled">You will be taken to the login screen to complete setup.</span>
      </div>
      <div>
        <a class="latch-button-outline latch-dark" type="button" routerLink="/console/account/mfa" queryParamsHandling="preserve">
          {{ mfaEnabled ? "Security Settings" : "Set up" }}
        </a>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Recent Logins" *ngIf="user.currentLoginDate">
      <a routerLink="/console/account/recent-logins" queryParamsHandling="preserve" latch-labeled-list-item-action>
        {{ user.currentLoginDate | date: "MMMM d y 'at' h:mm a" }}
      </a>
    </latch-labeled-list-item>

    <!--
    Other active sessions is temporarily disabled; LMC-2998.
    We need some time to re-implement this functionality on the backend after the auth0 transition.

    <div class="cell" *ngIf="otherActiveUserSessions">
      <div class="cell-heading">Other Active Sessions</div>
      <div class="cell-body">
        <ng-container [ngSwitch]="otherActiveUserSessions.length === 0">
          <span *ngSwitchCase="true">
            None
          </span>
          <a *ngSwitchCase="false" routerLink="/console/account/active-sessions"
            queryParamsHandling="preserve">
            <button class="cell-value">
              <div class="cell-text">
                {{ getActiveSessionsText(otherActiveUserSessions) }}
              </div>
              <div class="latch-icon-chevron-right"></div>
            </button>
          </a>
        </ng-container>
      </div>
    </div>
    -->
    <!-- end of list of cells -->
  </div>
</latch-loading>
