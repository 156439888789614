<div class="auth-header">
  <img src="shared/assets/images/latch-logo.svg" alt="logo" class="logo"/>
</div>

<div class="card-page">
  <div class="card">
    <h1>Trouble logging in?</h1>
    <p *ngIf="!statusMessage">Enter your email and we’ll send you a link to get back into your account.</p>
    <p *ngIf="statusMessage">{{statusMessage}}</p>
    <p *ngIf="hasSubmitted && form.invalid" class="has-error">An email is required</p>

    <form
      [formGroup]="form"
      (submit)="handleSubmitPreCaptcha($event)"
      class="form">
      <div class="form-group">
        <div class="input-group">
          <input
            type="email"
            formControlName="email"
            placeholder="Email"
            class="latch-form-control large-input"
            [class.has-error]="hasSubmitted && form.invalid"
            #email
            latchAutofocus
          >
        </div>
        <div class="captcha">
          <re-captcha
              #captchaRef="reCaptcha"
              badge="inline"
              size="invisible"
              (resolved)="$event && handleSubmitPostCaptcha($event)"
          ></re-captcha>
        </div>
      </div>
      <div class="card-footer">
        <button type="submit"
                (click)="captchaRef.execute()"
                class="latch-button latch-dark latch-button-lg">Send login link
        </button>
        <button type="button" class="latch-button-outline latch-dark latch-button-lg" (click)="handleBack()">Back</button>
      </div>
    </form>
  </div>
</div>
