import { groupBy } from 'manager/services/utility/utility';

// Collection of sync tasks generated because of some operation on a lock
export interface LockOperationSyncStatus {
  lockUUID: string;
  lockName?: string;
  accessCountEstimate: number;
  syncTasks: {
    uuid: string;
    state: SyncState;
  }[];
}

// Ultimate distilled outcome of some operation on some lock
export interface LockOperationSyncOutcome {
  lockUUID: string;
  lockName?: string;
  syncState: SyncState;
}

export enum SyncState {
  SyncScheduled = 'SYNC_SCHEDULED',
  SyncingOnline = 'SYNCING_ONLINE',
  SyncedByDevice = 'SYNCED_BY_DEVICE',
  SyncedByMobile = 'SYNCED_BY_MOBILE',
  PendingAdminSync = 'PENDING_ADMIN_SYNC',
  PendingInstall = 'PENDING_INSTALL',
  PendingUserSync = 'PENDING_USER_SYNC'
}

export interface SyncTask {
  uuid: string;
  lockUUID: string;
  deadline: Date;
  earliestDownlink: Date;
  state: SyncState;
}

export interface MockSyncTask extends SyncTask {
  buildingUUID: string;
}

export interface SyncStatusJSON {
  lockUUID: string;
  accessCountEstimate: number;
  syncStatus: {
    syncTaskUUID: string;
    status: SyncState;
  }[];
}

export const fromJSON = (syncStatuses: SyncStatusJSON[]): LockOperationSyncStatus[] => {
  const mapped = syncStatuses.map((syncStatus) => ({
    lockUUID: syncStatus.lockUUID,
    lockName: undefined,
    accessCountEstimate: syncStatus.accessCountEstimate,
    syncTasks: syncStatus.syncStatus.map(({ syncTaskUUID, status }) => ({
      uuid: syncTaskUUID,
      state: status
    }))
  }));
  const groupByResult = groupBy(mapped, item => item.lockUUID);
  return Object.values(groupByResult).map((lockSyncStatuses) => lockSyncStatuses.reduce((lockSyncStatus, next) => {
    lockSyncStatus.syncTasks = lockSyncStatus.syncTasks.concat(next.syncTasks);
    return lockSyncStatus;
  }));
};

export const toJSON = (syncStatuses: LockOperationSyncStatus[]): SyncStatusJSON[] => {
  const mapped = syncStatuses.map((syncStatus) => ({
    lockUUID: syncStatus.lockUUID,
    accessCountEstimate: syncStatus.accessCountEstimate,
    syncStatus: syncStatus.syncTasks.map(({ uuid, state }) => ({
      syncTaskUUID: uuid,
      status: state
    }))
  }));
  const groupByResult = groupBy(mapped, item => item.lockUUID);
  return Object.values(groupByResult).map((syncStatusJson) => syncStatusJson.reduce((acc, next) => {
    acc.syncStatus = acc.syncStatus.concat(next.syncStatus);
    return acc;
  }));
};
