import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Describes whether the currently selected building is an "elevator building" or not.
 *
 * An elevator building is one where we make elevator-related functionality available to the user - for example,
 * we split keys into "Door Keys" and "Elevator Keys", surface functionality to create elevator keys, etc.
 */
@Injectable()
export abstract class ElevatorBuildingService {
  /**
   * Whether or not the currently selected building is an elevator building.
   */
  abstract isCurrentElevatorBuilding(): Observable<boolean>;

  /**
   * Whether or not the specified building is an elevator building.
   */
  abstract isElevatorBuilding(buildingUUID: string | null): Observable<boolean>;
}
