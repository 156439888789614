import { Component, HostBinding, Input } from '@angular/core';

export type TagVariant = 'primary' | 'default' | 'error' | 'success' | 'info' | 'warning' | 'secondary';

@Component({
  selector: 'frame-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @HostBinding('class.frame-label-xs-default') fontStyle = true;
  @Input() label?: string;
  @HostBinding('class') @Input() variant: TagVariant = 'default';
  @Input() size = 'sm';
}
