import { cloneDeep } from 'manager/services/utility/utility';
import { Space } from './space';

interface IUnit {
  id: number;
  name: string;
  localTelecom?: string;
  space?: Space;
}

export class Unit {
  id: number;
  name: string;
  localTelecom?: string;

  space?: Space;

  constructor(iunit: IUnit) {
    this.id = iunit.id;
    this.name = iunit.name;
    this.localTelecom = iunit.localTelecom;
    this.space = iunit.space;
  }

  static fromJson(json: any): Unit {
    const unit = cloneDeep(json);
    return new Unit(unit);
  }
}

export interface UpdateUnitOperationResult {
  id: number;
  name: string;
  localTelecom?: string;
}
