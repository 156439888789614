<latch-loading [isActive]="isLoading">
  <latch-card class="page" headerTitle="Add Phone Number">
    <ng-container latchCardHeader>
      <ng-container latchCardActions>
        <a class="latch-button-icon" (click)="cancel.emit()">
          <span class="latch-icon-chevron-left"></span>
        </a>
      </ng-container>
      <div class="latch-card-title">Add Phone Number</div>
      <div class="page-description">
        In order to add {{candidate.firstName}} as management on the intercom, you must provide a phone number for calls.
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <latch-list>
        <latch-labeled-list-item label="Phone Number">
          <input class="latch-form-control"
            type="text"
            placeholder="Phone Number"
            [ngModel]="phoneNumber | phoneNumber"
            (ngModelChange)="phoneNumber=$event"/>
          <div class="help-block" *ngIf="showPhoneError">
            Enter a valid phone number
          </div>
        </latch-labeled-list-item>
      </latch-list>
    </ng-container>
    <ng-container latchCardFooter>
      <button class="latch-button" [disabled]="!phoneNumber" (click)="handleSubmitPhoneNumber()">
        Add phone
      </button>
    </ng-container>
  </latch-card>
</latch-loading>
