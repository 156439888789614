<latch-card class="page" headerTitle="CSV imported">
  <button class="latch-button-icon" latchCardActions (click)="cancel.emit()">
    <span class="latch-icon-chevron-left"></span>
  </button>

  <ng-container latchCardContent>
    <latch-import-csv-result
      [importName]="importName"
      [valid]="valid"
      [invalid]="invalid"
    >
    </latch-import-csv-result>
  </ng-container>

  <ng-container latchCardFooter>
    <button class="latch-button" (click)="import.emit()">
      Add {{importName}} ({{valid.length}})
    </button>
  </ng-container>
</latch-card>
