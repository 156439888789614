import { Component, Inject } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { ElevatorDetails } from 'manager/models/elevator-details';
import { FloorGroup } from 'manager/models/floor-group';

export interface FloorGroupListModalData {
  elevatorName: string;
  elevatorDetails?: ElevatorDetails;
  selectedFloorGroup?: FloorGroup;
  defaultFloorGroup?: FloorGroup;
}

@Component({
  selector: 'latch-floor-group-list-modal',
  templateUrl: './floor-group-list-modal.component.html',
})
export class FloorGroupListModalComponent {

  constructor(
    @Inject(LATCH_DIALOG_DATA) public data: FloorGroupListModalData,
    @Inject(LatchDialogRef) private dialogRef: LatchDialogRef<FloorGroup>,
  ) { }

  public onBackClick(): void {
    this.dialogRef.close();
  }
}
