<latch-loading [isActive]="isPageLoading || isUpdateLoading">
  <latch-subnav headerTitle="Intercom Settings"></latch-subnav>

  <div class="latch-viewport">

    <latch-labeled-list-item label="Name Format">
      <div>
        <latch-dropdown [data]="nameFormattingSelect" [(ngModel)]="defaultNameFormattingType"/>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Custom Names">
      <latch-checkbox
        label="Allow users to create custom resident names to  be displayed in the Intercom directory."
        labelPosition="before"
        [checked]="customNamesDisabled === false"
        (checkedChange)="toggleCustomNamesDisabled()"/>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Display View">
      <div>
        <latch-dropdown
          class="latch-dropdown-display-view"
          [data]="directoryListSelect"
          [(ngModel)]="directoryListType"
          (ngModelChange)="handleDisplayViewChange($event)"
          [disabled]="disableDirectorySettings()"/>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Display Order">
      <div class="radio-items">
        <latch-radio-item
          class="radio-alphanumeric"
          labelPosition="after"
          [checked]="directorySortType === directorySortSelect[0].value"
          [label]="directorySortSelect[0].name"
          [value]="directorySortSelect[0].value"
          [disabled]="disableDirectorySettings()"
          (check)="handleRadioChange(directorySortSelect[0].value)"/>
        <latch-radio-item
          labelPosition="after"
          [checked]="directorySortType === directorySortSelect[1].value"
          [label]="directorySortSelect[1].name"
          [value]="directorySortSelect[1].value"
          [disabled]="disableDirectorySettings() || disableCustomDisplayOrderSetting()"
          (check)="handleRadioChange(directorySortSelect[1].value)"/>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item label="Featured Entries" *ngIf="featuredEntries && supportedIntercomVersions">
      <div class="dropdown dropdown-button" slot="label">
        <button class="latch-button-link">
          Actions
          <fa-icon icon="caret-down"></fa-icon>
        </button>
        <div class="dropdown-menu">
          <div
            latchTooltip
            [tooltipEnabled]="featuredEntries.length >= maxFeaturedEntries"
            [tooltipHover]="true"
            [tooltipText]="'This property already has ' + maxFeaturedEntries + ' featured entries'">
            <button
              class="dropdown-item"
              [routerLink]="['', { outlets: { popup:  ['featured-entries', 'add'] } }]"
              queryParamsHandling="preserve"
              [disabled]="featuredEntries.length >= maxFeaturedEntries">
              Add new
            </button>
          </div>
          <div
            latchTooltip
            [tooltipEnabled]="featuredEntries.length <= 1"
            [tooltipHover]="true"
            tooltipText="Not enough featured entries to reorder">
            <button
              class="dropdown-item"
              [routerLink]="['', { outlets: { popup:  ['featured-entries', 'reorder'] } }]"
              queryParamsHandling="preserve"
              [disabled]="featuredEntries.length <= 1">
              Reorder
            </button>
          </div>
        </div>
      </div>
      <div class="cell-list" *ngIf="featuredEntries.length > 0">
        <div class="cell cell-row" *ngFor="let featuredEntry of featuredEntries">
          <div class="cell-text">
            <div class="text-1">{{featuredEntry.displayName}}</div>
          </div>
          <a
            class="latch-icon-chevron-right"
            [routerLink]="['', { outlets: { popup: ['featured-entries', featuredEntry.id] } }]"
            queryParamsHandling="preserve">
          </a>
        </div>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

    <latch-labeled-list-item>
      <div>
        <button class="latch-button" [disabled]="!canSubmit()" (click)="handleSubmit()">Save Changes</button>
      </div>
    </latch-labeled-list-item>
    <div class="latch-divider"></div>

  </div>
</latch-loading>
