import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CreateDoorScheduleResponse, DoorSchedule, DoorScheduleLockFailure, Exception, PrimarySchedule, PrimaryScheduleInterval
} from './../../models/door-schedules';

import { AuthService } from '../auth/auth.service';
import { DoorSchedulesService } from '../door-schedules/door-schedules.service';
import { SelectedAccountService } from '../appstate/selected-account.service';
import {
  DateTime,
  DateTimeRange,
  DATE_DISPLAY_FORMAT,
  DATE_PARSE_FORMATS,
  DayOfWeek,
  Meridiem,
  TIME_DISPLAY_FORMAT,
  TIME_PARSE_FORMATS
} from '../../services/utility/date-time';
import moment from 'moment';

export interface CreateDoorScheduleResponseRaw {
  schedule: DoorScheduleRaw,
  lockFailures: DoorScheduleLockFailure[];
}
export interface DoorScheduleRaw {
  earlyClosureEnabled: boolean;
  exceptions: ExceptionRaw[];
  firstUnlockedEnabled: boolean;
  name: string;
  primarySchedule: PrimaryScheduleRaw;
  uuid: string;
  lockUUIDs: string[];
}

export interface ExceptionRaw {
  date: number[];
  intervals: IntervalRaw[];
}

interface PrimaryScheduleRaw {
  intervals: PrimaryScheduleIntervalRaw[];
}

interface PrimaryScheduleIntervalRaw {
  dayOfWeek: DayOfWeek;
  intervals: IntervalRaw[];
}

export interface IntervalRaw {
  startTime: number[];
  endTime: number[];
}

@Injectable()
export class HTTPDoorSchedulesService extends DoorSchedulesService {

  constructor(
    private authService: AuthService,
    private selectedAccountService: SelectedAccountService,
  ) {
    super();
  }

  createDoorSchedule(doorSchedule: DoorSchedule, buildingUUID: string): Observable<CreateDoorScheduleResponse> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const data = {
      ... doorScheduleToRaw(doorSchedule),
      isFirstUnlockedEnabled: doorSchedule.firstUnlockedEnabled,
      isEarlyClosureEnabled: doorSchedule.earlyClosureEnabled
    };
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/doorSchedules`,
        data
      })
      .pipe(
        map((response: HttpResponse<any>): CreateDoorScheduleResponseRaw => AuthService.getPayload(response)),
        map((response: CreateDoorScheduleResponseRaw): CreateDoorScheduleResponse => createDoorScheduleRawToJson(response))
      );
  }

  editDoorSchedule(doorSchedule: DoorSchedule, buildingUUID: string, doorScheduleUUID: string): Observable<DoorSchedule> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const data = {
      ... doorScheduleToRaw(doorSchedule),
      isFirstUnlockedEnabled: doorSchedule.firstUnlockedEnabled,
      isEarlyClosureEnabled: doorSchedule.earlyClosureEnabled
    };
    return this.authService
      .request({
        method: 'put',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/doorSchedules/${doorScheduleUUID}`,
        data
      })
      .pipe(
        map((response: HttpResponse<any>): DoorScheduleRaw => AuthService.getPayload(response)),
        map((response: DoorScheduleRaw): DoorSchedule => doorSchedulesRawToJSON(response))
      );
  }

  // Get the List Door Schedules for the selected account and building
  getDoorSchedules(buildingUUID: string): Observable<DoorSchedule[]> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/doorSchedules`,
      })
      .pipe(
        map((response: HttpResponse<any>): DoorScheduleRaw[] =>
          AuthService.getPayload(response)
        ),
        map((response: DoorScheduleRaw[]): DoorSchedule[] =>
          response.map(item => doorSchedulesRawToJSON(item)
        )
      ),
    );
  }

  // Get a Door Schedule based on it's UUID for the selected account and building
  getDoorScheduleByUUID(buildingUUID: string, doorScheduleUUID: string): Observable<DoorSchedule> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/doorSchedules/${doorScheduleUUID}`,
      })
      .pipe(
        map((response: HttpResponse<any>): DoorScheduleRaw =>
          AuthService.getPayload(response)
        ),
        map((response: DoorScheduleRaw): DoorSchedule =>
          doorSchedulesRawToJSON(response)
      ),
    );
  }

  // Delete a Door Schedule based on it's UUID for the selected account and building
  deleteDoorScheduleByUUID(buildingUUID: string, doorScheduleUUID: string): Observable<null> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService
      .request({
        method: 'delete',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/doorSchedules/${doorScheduleUUID}`,
      })
      .pipe(
        map(() => null)
    );
  }
}

export function createDoorScheduleRawToJson(raw: CreateDoorScheduleResponseRaw): CreateDoorScheduleResponse {
  return {
    schedule: doorSchedulesRawToJSON(raw.schedule),
    lockFailures: raw.lockFailures
  };
}

export function doorSchedulesRawToJSON(doorSchedules: DoorScheduleRaw): DoorSchedule {
  return {
    ... doorSchedules,
    exceptions: convertExceptionsDate(doorSchedules.exceptions),
    primarySchedule: convertRawPrimarySchedule(doorSchedules.primarySchedule),
    lockUUIDs: doorSchedules.lockUUIDs
  };
}

export function doorScheduleToRaw(doorSchedule: DoorSchedule): DoorScheduleRaw {
  return {
    ... doorSchedule,
    exceptions: convertExceptionsToRaw(doorSchedule.exceptions),
    primarySchedule: convertPrimaryScheduleToRaw(doorSchedule.primarySchedule)
  };
}

function convertExceptionsDate(exceptionsRaw: ExceptionRaw[]): Exception[] {
  return exceptionsRaw.map(e => convertExceptionRawToException(e));
}

function convertExceptionsToRaw(exceptions: Exception[]): ExceptionRaw[] {
  return exceptions.map(e => convertExceptionToRaw(e));
}

function convertExceptionRawToException(raw: ExceptionRaw): Exception {
  const dateString = moment()
    .set('year', raw.date[0])
    /** Moment zero-indexes months */
    .set('month', raw.date[1] - 1)
    .set('date', raw.date[2])
    .format(DATE_DISPLAY_FORMAT);
  return {
    date:  {
      date: dateString
    },
    intervals: raw.intervals.map(i => convertIntervalRawToDateTimeRange(i))
  };
}

export function convertExceptionToRaw(exception: Exception): ExceptionRaw {
  const date = moment(exception.date.date, DATE_PARSE_FORMATS, true);
  return {
    date: [date.year(), date.month() + 1, date.date()],
    intervals: exception.intervals.map(i => convertDateTimeRangeToIntervalRaw(i))
  };
}

export function convertIntervalRawToDateTimeRange(raw: IntervalRaw): DateTimeRange {
  return {
    start: parseDateTimeFromArray(raw.startTime),
    end: parseDateTimeFromArray(raw.endTime)
  };
}

export function convertDateTimeRangeToIntervalRaw(dateTimeRange: DateTimeRange): IntervalRaw {
  return {
    startTime: dateTimeToArray(dateTimeRange.start),
    endTime: dateTimeToArray(dateTimeRange.end)
  };
}

function parseDateTimeFromArray(timeArray: number[]): DateTime {
  let meridiem = Meridiem.AM;
  const hours = timeArray[0];

  if (hours >= 12) {
    meridiem = Meridiem.PM;
  }

  const time = moment()
    .set('hour', timeArray[0])
    .set('minute', timeArray[1])
    .format(TIME_DISPLAY_FORMAT);

  return {
    time,
    meridiem
  };
}

export function dateTimeToArray(dateTime: DateTime): number[] {
  const time = moment(`${dateTime.time ?? ''} ${dateTime.meridiem ?? ''}`, TIME_PARSE_FORMATS, true);
  return [time.hours(), time.minutes()];
}

export function convertRawPrimarySchedule(raw: PrimaryScheduleRaw): PrimarySchedule {
  return {
    intervals: raw.intervals.map(i => convertRawPrimaryScheduleInterval(i))
  };
}

export function convertPrimaryScheduleToRaw(primarySchedule: PrimarySchedule): PrimaryScheduleRaw {
  return {
    intervals: primarySchedule.intervals.map(i => convertPrimaryScheduleIntervalToRaw(i))
  };
}

function convertRawPrimaryScheduleInterval(raw: PrimaryScheduleIntervalRaw): PrimaryScheduleInterval {
  return {
    dayOfWeek: raw.dayOfWeek,
    intervals: raw.intervals.map(i => convertIntervalRawToDateTimeRange(i))
  };
}

function convertPrimaryScheduleIntervalToRaw(interval: PrimaryScheduleInterval): PrimaryScheduleIntervalRaw {
  return {
    dayOfWeek: interval.dayOfWeek,
    intervals: interval.intervals.map(i => convertDateTimeRangeToIntervalRaw(i))
  };
}
