import { Component, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { FeaturedEntry } from 'manager/models/featured-entry';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { FeaturedEntryService } from 'manager/services/featured-entry/featured-entry.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'latch-reorder-featured-entries',
  templateUrl: './reorder-featured-entries.component.html',
  styleUrls: ['./reorder-featured-entries.component.scss']
})
export class ReorderFeaturedEntriesComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public featuredEntries: FeaturedEntry[] = [];
  private unsubscribe$ = new Subject<void>();
  private buildingUUID!: string;

  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private featuredEntryService: FeaturedEntryService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      switchMap((building) => {
        this.buildingUUID = building[0].uuid;
        return this.featuredEntryService.getBuildingFeaturedEntries(this.buildingUUID);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      featuredEntries => {
        this.featuredEntries = featuredEntries;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleDrop(event: CdkDragDrop<FeaturedEntry[]>): void {
    moveItemInArray(this.featuredEntries, event.previousIndex, event.currentIndex);
  }

  handleSubmit(): void {
    let i = 1;
    this.featuredEntries.forEach(fe => fe.order = i++);
    this.isLoading = true;
    this.featuredEntryService.updateFeaturedEntriesOrder(this.featuredEntries, this.buildingUUID).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      featuredEntries => {
        this.featuredEntries = featuredEntries;
        this.isLoading = false;
        this.router.navigate([{ outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
      },
      error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      }
    );
  }
}
