import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryInfo, DeliveryList, getPackagesDisplay, getUnlockMethodDisplayValue, statusNames } from '../models/delivery';
@Pipe({
  name: 'deliveryInfo'
})
export class DeliveryInfoPipe implements PipeTransform {

  transform(delivery: DeliveryList, info: DeliveryInfo): string {
    switch (info) {
      case 'title': return `${delivery.id} (${delivery.courier.trim()})`;
      case 'packages': return getPackagesDisplay(delivery.units.map(
        unit => ({
          packageType: unit.packageType,
          numberOfPackages: unit.numberOfPackages
        }))
      );
      case 'status': return statusNames[delivery.status];
      case 'unlockMethod': return getUnlockMethodDisplayValue(delivery.accessLog);
    }
  }

}
