import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessState } from '../../models/key-membership';
import { ServiceResponse } from '../interfaces';
import { LeaseState, Person, ContactOnlyPerson } from '../../models/contact-cards';

export enum HostType {
  User = 'USER',
  Building = 'BUILDING',
}

export interface Host {
  hostType: HostType;
  uuid: string;
}

export enum Sort {
  ALPHABETICAL = 'ALPHABETICAL',
  PMS_UPDATE = 'PMS_UPDATE',
  UNIT = 'UNIT',
}

export enum SortDirection {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending'
}

export enum EditContactCardOperation {
  ADD = 'ADD',
  MODIFY = 'MODIFY',
  DELETE = 'DELETE'
}

export enum EditContactCardKey {
  UNIT_ID = 'UNIT_ID',
  PHONE_NUMBER = 'PHONE_NUMBER',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME'
}

export interface GetPeopleInput {
  accessState: AccessState[];
  leaseState: LeaseState[];
  unitIds?: number[];
  start?: number;
  limit?: number;
  search?: string;
  sort?: Sort;
  sortDirection?: SortDirection;
  withUnitAssignedOnly?: boolean;
  withUnitNotAssignedOnly?: boolean;
  keysUUID?: string[];
  includeResidentsGuests?: boolean;
}

export interface PaginatedPeopleResponse {
  metadata: {
    nextPage?: {
      start: number;
      limit: number;
    };
  };
  people: Person[];
}

export interface CreateContactCardInput {
  buildingUUID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber?: string;
  unitId?: number;
}

export interface CreateContactCardResponse {
  uuid: string;
  buildingUUID: string;
  newUser: boolean;
  host: Host;
  userUUID: string;
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  creationTime: Date;
  decommissionTime?: Date;
  unitId?: number;
}

export interface EditContactCardInput {
  operation: EditContactCardOperation;
  key: EditContactCardKey;
  value?: string;
}

@Injectable()
export abstract class ContactCardService {
  abstract createContactCard(input: CreateContactCardInput): Observable<CreateContactCardResponse>;

  abstract deleteContactCard(personUUID: string, keepKeyCards?: boolean): Observable<void>;

  /**
   * Gets building specific metadata for the given user.
   *
   * This call gets a "full" person object, which includes some key membership and
   * credential information. If you don't need membership or credential information,
   * use the more performant getContactOnlyPersonForUser.
   */
  abstract getPersonForUser(buildingUUID: string, userUUID: string): Observable<Person>;

  /**
   * Gets building specific metadata for the given user.
   *
   * This returns a more limited set of information than getPersonForUser. This
   * excludes key membership and credential information and is generally faster.
   */
  abstract getContactOnlyPersonForUser(buildingUUID: string, userUUID: string): Observable<ContactOnlyPerson>;

  abstract getPeople(buildingId: string, input: GetPeopleInput): Observable<PaginatedPeopleResponse>;

  abstract editContactCard(personUUID: string, input: EditContactCardInput[]): Observable<ServiceResponse>;
}
