import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LatchNavAction } from '@latch/latch-web';
import { RouterEventsService } from 'manager/core/services/router-events.service';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class PageWithTabsBaseComponent<Tab> implements OnInit, OnDestroy {
  abstract baseUrl: string;
  abstract defaultTab: Tab;
  abstract tabData: { id: Tab, path: string; }[];

  activeTab: Tab = '' as unknown as Tab;
  tabPathMatchFull = false;
  subnavSubtitle = '';
  subnavActions: LatchNavAction[] = [];
  showPageContent = true;

  unsubscribe$ = new Subject<void>();

  private routerEventsService = inject(RouterEventsService);

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected pageWithTabsService: PageWithTabsService,
  ) { }

  ngOnInit() {
    this.activeTab = this.defaultTab;

    this.initializeSubscriptions();
    this.initializeRouteSubscription();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeSubscriptions() {
    this.pageWithTabsService.subnavSubtitleChange$.pipe(
      delay(0),
      takeUntil(this.unsubscribe$)
    ).subscribe((subtitle) => this.subnavSubtitle = subtitle);

    this.pageWithTabsService.subnavActionsChange$.pipe(
      delay(0),
      takeUntil(this.unsubscribe$)
    ).subscribe((actions) => this.subnavActions = actions);

    this.pageWithTabsService.showPageContentChange$.pipe(
      delay(0),
      takeUntil(this.unsubscribe$)
    ).subscribe((show) => this.showPageContent = show);
  }

  private initializeRouteSubscription() {
    this.routerEventsService.getNavigationEnd().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // active tab needs to update inside subscription, because user might use browser's back/forth button
      // so activeTab should always be in accordance with the component that is rendered by the router outlet.
      const newTab = this.getActiveTab();
      if (this.activeTab !== newTab) {
        this.pageWithTabsService.reset();
      }
      this.activeTab = newTab;
      this.tabPathMatchFull = !this.route.snapshot.firstChild?.firstChild?.routeConfig?.path;
    });
  }

  private getActiveTab(): Tab {
    const path = this.route.snapshot.firstChild?.routeConfig?.path ?? '';
    const tab = this.tabData.find((t) => t.path === path);
    return tab?.id ?? this.defaultTab;
  }

  onTabClick(tabId: string) {
    this.router.navigate(this.getTabUrlFragments(tabId as unknown as Tab), {
      queryParams: { building: this.route.snapshot.queryParams.building }
    });
  }

  private getTabUrlFragments(tabId: Tab): string[] {
    const urlFragments = [this.baseUrl];
    const tab = this.tabData.find((t) => t.id === tabId);
    if (tab?.path) {
      urlFragments.push(tab.path);
    }
    return urlFragments;
  }
}
