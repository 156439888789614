<latch-toast
  [ngClass]="{ 'active': hasActiveOperations }"
  [type]="toastType"
  [show]="hasActiveOperations"
  [title]="activeStatusMessage"
  [bodyText]="activeStatusSubText"
  [tooltipMessage]="operationsInProgress ? 'This might take a while. Closing or minimizing this window will not affect this action' : undefined"
  (toastClick)="onMessageClick()"
  (dismiss)="onDismiss()"
></latch-toast>
