<ng-container [ngSwitch]="currentStep">
  <latch-card class="page" *ngSwitchCase="Step.Code">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="goBack($event)">
          <span class="latch-icon-chevron-left"></span>
        </button>
      </div>
      <div class="latch-card-title">Make Two-Factor Authentication Optional</div>
      <div class="page-description">Are you sure you want to make Two-Factor Authentication optional for all portfolio managers  and property managers?</div>
    </ng-container>
    <ng-container latchCardContent>
      <div class="field-list">
        <div class="cell cell-description">
          <div class="cell-text">Disable required authentication codes at login for property managers and portfolio managers
          </div>
        </div>
        <div class="cell">
          <button class="latch-button" (click)="confirmChange($event)">Confirm</button>
        </div>
      </div>
    </ng-container>
  </latch-card>

  <latch-card class="page" *ngSwitchCase="Step.Success">
    <ng-container latchCardHeader>
      <div class="latch-card-title">Success</div>
      <div class="page-description">You have made Two-Factor Authentication optional for all portfolio managers and property managers.
      </div>
    </ng-container>
    <ng-container latchCardContent>
      <img src="shared/assets/images/mfa-not-required.svg" alt="enabled" class="logo-verify">
    </ng-container>
    <ng-container latchCardFooter>
      <button type="button" class="latch-button-outline" (click)="finished($event)">close</button>
    </ng-container>
  </latch-card>
</ng-container>
