<ng-container *ngIf="currentStep === FeaturedEntryStep.FeaturedEntryDetails">
  <latch-loading [isActive]="isLoading">
    <latch-card class="page" *ngIf="!!featuredEntry" [headerTitle]="featuredEntry.displayName">
      <ng-container latchCardActions>
        <a class="latch-button-icon" [routerLink]="['', { outlets: { popup: null } }]" queryParamsHandling="preserve">
          <span class="latch-icon-close"></span>
        </a>
        <button *ngIf="!isEditing" class="latch-button-link" (click)="handleEdit()">Edit</button>
        <button *ngIf="isEditing" class="latch-button-link" (click)="handleCancel()">Cancel</button>
      </ng-container>
      <ng-container latchCardContent>
        <latch-list>
          <latch-labeled-list-item label="Display Name">
            <div *ngIf="!isEditing">{{featuredEntry.displayName}}</div>
            <div *ngIf="isEditing">
              <div class="list-sub-title">
                Maximum 17 characters
              </div>
              <input class="latch-form-control" type="text" placeholder="Display Name" maxlength="17" [(ngModel)]="displayName"/>
            </div>
          </latch-labeled-list-item>
          <div class="latch-divider"></div>
          <latch-labeled-list-item label="Person">
            <div class="card" *ngIf="!isEditing || selectedCandidate">
              <div class="card-handle space-bt">
                <div class="card-title" *ngIf="!isEditing">
                  {{featuredEntry.firstName}} {{featuredEntry.lastName}}
                </div>
                <ng-container *ngIf="isEditing">
                  <ng-container *ngIf="selectedCandidate">
                    <div class="card-title">
                      {{selectedCandidate.firstName}} {{selectedCandidate.lastName}}
                    </div>
                    <div class="latch-icon-close" (click)="clearSelectedCandidate()"></div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div *ngIf="isEditing && !selectedCandidate">
              <button class="latch-button-dashed" (click)="setCurrentStep(FeaturedEntryStep.FeaturedEntryCandidate)">
                Add Person
              </button>
            </div>
          </latch-labeled-list-item>
          <div class="latch-divider"></div>
          <latch-labeled-list-item label="Contact Preference">
            <div>
              <latch-dropdown
                  [disabled]="!isEditing"
                  [data]="contactPreferenceSelect"
                  [(ngModel)]="contactPreference">
              </latch-dropdown>
            </div>
          </latch-labeled-list-item>
        </latch-list>
      </ng-container>
      <ng-container latchCardFooter>
        <button class="latch-button-outline latch-danger" *ngIf="!isEditing" (click)="deleteFeaturedEntry()">
          Delete featured entry
        </button>
        <button class="latch-button" [disabled]="!canSubmit()" *ngIf="isEditing" (click)="handleSubmit()">
          Save changes
        </button>
      </ng-container>
    </latch-card>
  </latch-loading>
</ng-container>
<ng-container *ngIf="currentStep===FeaturedEntryStep.FeaturedEntryCandidate">
  <latch-select-featured-entry-candidate
    [buildingUUID]="buildingUUID"
    (selectedCandidate)="setSelectedCandidate($event)"
    (cancel)="setCurrentStep(FeaturedEntryStep.FeaturedEntryDetails)"
  ></latch-select-featured-entry-candidate>
</ng-container>
