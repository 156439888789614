import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FeatureService } from '../../services/appstate/feature.service';
import { Router } from '@angular/router';

@Component({
  selector: 'latch-no-building',
  templateUrl: './no-building.component.html',
  styleUrls: ['./no-building.component.scss']
})
export class NoBuildingComponent {
  currentUser = this.authService.currentUser;
  hasSelfSetup = this.featureService.hasSelfSetupFeature$;

  constructor(
    private authService: AuthService,
    private featureService: FeatureService,
    private router: Router,
  ) {}

  openCreatePropertyFlow() {
    this.router.navigate(['', { outlets: { popup: ['new-property'] } }], { queryParamsHandling: 'preserve' });
  }

}
