<latch-loading [isActive]="isLoading">
  <latch-card class="page" *ngIf="!!device && !isLoading">

    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <a class="latch-button-icon" latchCardActions
        (click)="handleGoBack()">
          <span class="latch-icon-chevron-left"></span>
        </a>
      </div>
      <div class="latch-card-title">{{getFullDeviceDisplayName(device)}}</div>
    </ng-container>

    <ng-container latchCardContent>

      <div class="section">
        <latch-labeled-list-item label="Unit">
          {{getUnitNameForDevice(device)}}
          <div class="help-block" *ngIf="deviceSpace.spaceType === SpaceType.ResidentialUnit || deviceSpace.spaceType === SpaceType.Room">
            This unit is {{spaceIsOccupied ? 'occupied' : 'unoccupied'}}.
          </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Room" *ngIf="deviceSpace.spaceType === SpaceType.Room">
          {{deviceSpace.name}}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Name" [class.has-error]="nameError">
          <div class="button-container" slot="label" *ngIf="device.deviceType!==SmartDeviceType.Hub">
            <button
              type="button"
              class="latch-button-link"
              (click)="handleToggleEditName()">
              {{editNameMode ? 'Cancel' : 'Edit'}}
            </button>
            <button
              type="button"
              class="latch-button-link"
              *ngIf="editNameMode"
              (click)="handleSaveEditName()"
              [disabled]="!name.length">
              Save
            </button>
          </div>
          <ng-container *ngIf="!editNameMode">
            {{device.name}}
          </ng-container>
          <input
            *ngIf="editNameMode"
            type="text"
            placeholder=""
            class="latch-form-control"
            [(ngModel)]="name"
            maxlength="32"
            latchAutofocus/>
          <div *ngIf="nameError" class="help-block">{{nameError}}</div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Device Type">
          {{ SmartDeviceTypeForDisplayAndSearch[device.deviceType] }}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Model">
          {{device.deviceModel}}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Device {{device.deviceManufacturer === SmartDeviceManufacturer.Honeywell ? 'MAC ID' : 'Serial Number'}}">
          {{device.manufacturerDeviceId}}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <latch-labeled-list-item label="Internet Status" [class.danger]="device.connection !== SmartDeviceConnectionType.Connected">
          {{device.connection === SmartDeviceConnectionType.Connected ? 'Connected' : 'Disconnected'}}
        </latch-labeled-list-item>
        <div class="latch-divider"></div>

        <ng-container [ngSwitch]="device.deviceType">
          <ng-container *ngSwitchCase="SmartDeviceType.Thermostat">
            <ng-container *ngIf="spaceTemperature || spaceIsOccupied">
              <latch-labeled-list-item label="Temperature">
                {{spaceTemperature ? convertCelsiusToFahrenheit(spaceTemperature)+'&deg;F' : 'Restricted (unit occupied)'}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
            <ng-container *ngIf="hvacMode || spaceIsOccupied">
              <latch-labeled-list-item label="Mode">
                {{hvacMode ? (hvacMode | titlecase) : 'Restricted (unit occupied)'}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
            <ng-container *ngIf="heatSetpoint">
              <latch-labeled-list-item label="Min. Temperature Setting">
                {{convertCelsiusToFahrenheit(heatSetpoint)}}&deg;F
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
            <ng-container *ngIf="coolSetpoint">
              <latch-labeled-list-item label="Max. Temperature Setting">
                {{convertCelsiusToFahrenheit(coolSetpoint)}}&deg;F
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="SmartDeviceType.Light">
            <ng-container *ngIf="(lightDimmerLevel !== null || spaceIsOccupied) && deviceIsDimmerLight">
              <latch-labeled-list-item label="Brightness">
                {{spaceIsOccupied ? 'Restricted (unit occupied)' : lightDimmerLevel + '%'}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
            <latch-labeled-list-item label="Device Status">
              {{spaceIsOccupied ? 'Restricted (unit occupied)' : lightOnOffStatus}}
            </latch-labeled-list-item>
            <div class="latch-divider"></div>

            <ng-container *ngIf="!!controllingHub">
              <div class="page-toolbar">
                <div class="page-data-count">Hub it's controlled by</div>
              </div>
              <latch-labeled-list-item label="Hub">
                <button
                  latch-labeled-list-item-action
                  [routerLink]="['/console/devices', controllingHub.uuid]"
                  queryParamsHandling="preserve">
                  {{getFullDeviceDisplayName(controllingHub)}}
                </button>
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="SmartDeviceType.Hub">
            <ng-container *ngIf="hubPowerMode">
              <latch-labeled-list-item label="Power">
                {{hubPowerMode | titlecase}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>

            <ng-container *ngIf="hubAccessPointTrait && hubAccessPointTrait.parameters.apSsid">
              <latch-labeled-list-item label="Wifi Hotspot Name">
                {{hubAccessPointTrait.parameters.apSsid}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>

              <latch-labeled-list-item label="Wifi Hotspot Password">
                <a *ngIf="!showHubHotspotPassword" (click)="handleShowPassword()">Click to reveal</a>
                <span class="password" *ngIf="showHubHotspotPassword">
                  {{hubHotspotPassword}}
                </span>
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>


            <ng-container *ngIf="hubSpaceNames">
              <div class="page-toolbar">
                <div class="page-data-count">
                  Units it Controls
                </div>
              </div>

              <latch-labeled-list-item label="Units">
                {{hubSpaceNames}}
              </latch-labeled-list-item>
              <div class="latch-divider"></div>
            </ng-container>

            <ng-container *ngIf="controlledDevices.length">
              <div class="page-toolbar">
                <div class="page-data-count">
                  Devices it Controls
                </div>
              </div>

              <ng-container *ngFor="let controlledDevice of controlledDevices">
                <latch-labeled-list-item [label]="getUnitNameForDevice(controlledDevice)" >
                  <button
                    latch-labeled-list-item-action
                    [routerLink]="['/console/devices', controlledDevice.uuid]"
                    queryParamsHandling="preserve">
                    {{getFullDeviceDisplayName(controlledDevice)}}
                  </button>
                </latch-labeled-list-item>
                <div class="latch-divider"></div>
              </ng-container >

            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="SmartDeviceType.LeakDetector">
            <latch-labeled-list-item label="Power" *ngIf="!!devicePowerTrait">
              <ng-container *ngIf="!devicePowerTrait.parameters.isBatteryLow">
                Your leak detector has sufficient battery
              </ng-container>
              <ng-container *ngIf="devicePowerTrait.parameters.isBatteryLow">
                Your leak detector has low battery, detected on {{deviceLowPowerLastDetected | date: 'MMM dd, y'}}
              </ng-container>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>

            <latch-labeled-list-item label="Leak last detected">
              <ng-container *ngIf="!!leakLastDetected">
                <button
                  latch-labeled-list-item-action
                  [routerLink]="['/console/activity/device']"
                  [queryParams]="queryParamsForDeviceLog"
                  queryParamsHandling="merge">
                  <div [class.danger]="leakLastDetectedWithin24Hrs">
                    {{leakLastDetected | friendlyDate}}
                  </div>
                </button>
              </ng-container>
              <ng-container *ngIf="!leakLastDetected">
                There are no leaks.
              </ng-container>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>

            <ng-container *ngIf="!!controllingHub">
              <div class="page-toolbar">
                <div class="page-data-count">
                  Hub it's controlled by
                </div>
              </div>

              <latch-labeled-list-item label="Hub">
                <button
                  latch-labeled-list-item-action
                  [routerLink]="['/console/devices', controllingHub.uuid]"
                  queryParamsHandling="preserve">
                  {{getFullDeviceDisplayName(controllingHub)}}
                </button>
              </latch-labeled-list-item>
              <div class="latch-divider"></div>

            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>

      </div>

    </ng-container>
  </latch-card>
</latch-loading>
