import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { LatchDialogRef, LatchDialogService, LATCH_DIALOG_DATA } from '@latch/latch-web';

export interface UpgradePopupItem {
  title?: string;
  learnMoreTitle?: string;
  content?: string;
}

@Component({
  selector: 'latch-upgrade-popup',
  templateUrl: './upgrade-popup.component.html',
  styleUrls: ['./upgrade-popup.component.scss']
})
export class UpgradePopupComponent {

  @ViewChild('requestSubmittedDialog', { static: true })
  public requestSubmittedDialog!: TemplateRef<unknown>;

  private dialog = inject(LatchDialogService);
  private dialogRef = inject(LatchDialogRef);
  public data = inject<UpgradePopupItem>(LATCH_DIALOG_DATA);

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public onSubmitClick(): void {
    this.dialogRef.close(true);
    this.openSubmitConfirmation();
  }

  private openSubmitConfirmation(): void {
    this.dialog.openConfirmation({
      data: {
        messages: [this.requestSubmittedDialog],
        closeTimeout: 2000,
      },
    });
  }

}
