export enum SpaceType {
  Portfolio = 'PORTFOLIO',
  Building = 'BUILDING',
  Floor = 'FLOOR',
  ResidentialUnit = 'RESIDENTIAL_UNIT',
  Room = 'ROOM'
}

interface SpaceMetadata {
  occupied?: boolean;
}

export interface Space {
  uuid: string;
  name: string;
  spaceType: SpaceType;
  parentUUID: string;
  childrenUUIDs: string[];
  metadata: SpaceMetadata;
  unitId?: number;
}
