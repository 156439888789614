// Adapted from https://medium.com/@Sureshkumar_Ash/angular-2-simple-infinite-scroller-directive-with-rxjs-observables-a989b12d4fb1

import { Component, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, pairwise, map, takeUntil } from 'rxjs/operators';

interface ScrollPosition {
  scrollHeight: number;
  scrollTop: number;
  clientHeight: number;
}

@Component({
  selector: 'latch-infinite-scroll',
  template: '<ng-content></ng-content>',
  styleUrls: ['./infinite-scroll.component.scss']
})
export class InfiniteScrollComponent implements AfterViewInit, OnDestroy {

  @Input() isLoading = false;
  @Input() threshold = 80;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public load = new EventEmitter<void>();

  private unsubscribe$ = new Subject<void>();

  constructor(private el: ElementRef) { }

  public ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, 'scroll').pipe(
      filter(() => !this.isLoading),
      map((e: any): ScrollPosition => ({
        scrollHeight: e.target.scrollHeight,
        scrollTop: e.target.scrollTop,
        clientHeight: e.target.clientHeight
      })),
      pairwise(),
      filter((positions) => this.isScrollingDown(positions) && this.isPastThreshold(positions[1])),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.load.emit());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  private isScrollingDown(positions: [ScrollPosition, ScrollPosition]) {
    return positions[0].scrollTop < positions[1].scrollTop;
  }

  private isPastThreshold(position: ScrollPosition) {
    // Add clientHeight so offset measures from bottom of viewable content (instead of top)
    const scrollOffset = position.scrollTop + position.clientHeight;
    const scrollPct = scrollOffset / position.scrollHeight;
    const thresholdPct = this.threshold / 100;
    return scrollPct > thresholdPct;
  }

}
