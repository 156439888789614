import { Injectable } from '@angular/core';
import { asapScheduler, BehaviorSubject, Observable } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UIStateService {

  private focusMode = new BehaviorSubject<number>(0);

  public enableFocusMode() {
    this.focusMode.next(this.focusMode.value + 1);
  }

  public disableFocusMode() {
    this.focusMode.next(this.focusMode.value - 1);
  }

  public isFocusMode(): Observable<boolean> {
    return this.focusMode.asObservable().pipe(
      map(value => value > 0),
      // asapScheduler will perform task as soon as possible asynchronously.
      // focusMode value is getting updated from child to parent, so
      // this is needed to avoid ExpressionChangedAfterItHasBeenCheckedError.
      observeOn(asapScheduler),
    );
  }
}
