import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { ensure, isDefined, hasProperty } from '@latch/latch-web';

const DEFAULT_ERROR_MESSAGE = 'File could not be processed. Please check formatting and try again.';

enum Mode {
  Card = 'Card',
  Inline = 'Inline',
}
@Component({
  selector: 'latch-import-csv',
  templateUrl: './import-csv.component.html',
  styleUrls: ['./import-csv.component.scss']
})
export class ImportCsvComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() csvResults = new EventEmitter<string[][]>();
  @Input() mode: string = Mode.Card;
  @Input() uploadTemplate!: string;
  @Input() importName!: string;
  @Input() columnNames: string[] = [];
  @Input() maxRows!: number;
  @Input() pageDescription = '';
  @Input() entity = '';
  // disables validating that rows have numColumns elements
  @Input() disableNumColumnsCheck = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  hasHover = false;
  error: string | undefined;
  file: File | undefined;
  importTemplateUrl!: SafeUrl;

  Mode = Mode;

  private get numColumns(): number {
    return this.columnNames.length;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private analyticsService: LatchAnalyticsService
  ) { }

  ngOnInit() {
    this.importTemplateUrl = this.sanitizer.bypassSecurityTrustUrl(
      `data:text/plain;charset=utf-8,${encodeURIComponent(this.uploadTemplate)}`);
  }

  public handleImport(): void {
    if (!this.file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = this.handleLoad.bind(this);
    reader.onerror = this.handleReaderError.bind(this);
    reader.onabort = this.handleAbort.bind(this);
    reader.readAsText(this.file);
  }

  handleLoad(e: ProgressEvent<FileReader>) {
    this.error = undefined;
    const result: string[][] = [];

    const fileText = e.target?.result;
    if (!(isDefined(fileText) && typeof fileText === 'string')) {
      this.handleError(DEFAULT_ERROR_MESSAGE);
      return;
    }

    try {
      const file = ensure(this.file);
      const maxKb = 2500;
      if (file.size > maxKb * 1024) {
        const size = Math.round(file.size / 1024);
        this.handleError(`File size (${size}kb) is greater than maximum of ${maxKb}kb.`, {
          'File Size': file.size
        });
        return;
      }

      const parts = file.name.split('.');
      const extension = parts[parts.length - 1] || '';
      if (extension.toLowerCase() !== 'csv') {
        this.handleError('Only CSV files are permitted.', {
          'File Type': file.type,
          'File Name': file.name
        });
        return;
      }

      const [header, ...rows]: string[] = fileText.split('\n').filter((row) => !!row);
      if (rows.length > this.maxRows) {
        this.handleError(`May not import more than ${this.maxRows} ${this.importName.toLowerCase()} at once.`, {
          'Row Count': rows.length
        });
        return;
      }

      const headerValues = header.split(',').map(value => value.trim());

      if (headerValues.length !== this.numColumns) {
        this.handleError(`File must have ${this.numColumns} columns but the uploaded file had ${headerValues.length}.`);
        return;
      }

      for (let i = 0; i < this.numColumns; i++) {
        if (headerValues[i].toLowerCase() !== this.columnNames[i].toLowerCase()) {
          this.handleError(`Column ${i + 1} should be ${this.columnNames[i]} but was ${headerValues[i]}.`);
          return;
        }
      }

      for (let i = 0; i < rows.length; i++) {
        const values = rows[i].split(',');
        if (!this.disableNumColumnsCheck && values.length !== this.numColumns) {
          this.handleError(`Row ${i + 1} should have had ${this.numColumns} columns but had ${values.length}.`);
          return;
        }
        const row = values.map((value) => value.trim());
        result.push(row);
      }

      if (!result.length) {
        this.handleError('Nothing to import. Please check file and try again.');
        return;
      }

    } catch (error) {
      return this.handleError();
    }

    if (!this.error) {
      this.csvResults.emit(result);
    }
  }

  handleDragOver(e: DragEvent) {
    e.preventDefault();
    this.hasHover = true;
  }

  handleDragLeave(e: DragEvent) {
    e.preventDefault();
    this.hasHover = false;
  }

  handleDrop(e: DragEvent) {
    e.preventDefault();
    this.hasHover = false;
    this.handleFiles(e.dataTransfer?.files);
  }

  handleInput(e: any) {
    this.handleFiles((e.target as HTMLInputElement).files);
  }

  private handleFiles(files?: FileList | null) {
    this.error = undefined;
    this.file = undefined;

    if (!files || files?.length === 0) {
      return;
    }

    if (files.length === 1) {
      this.file = files[0];
    }

    if (files.length > 1) {
      this.handleError('Only one file may be uploaded.', {
        'File Count': files.length
      });
    }

    if (this.mode === Mode.Inline) {
      this.handleImport();
    }
  }

  handleClear(e: Event) {
    e.preventDefault();
    this.file = undefined;
    this.error = undefined;
    this.fileInput.nativeElement.value = '';
  }

  handleAbort() {
    this.error = undefined;
    this.file = undefined;
  }

  handleError(message = DEFAULT_ERROR_MESSAGE, params = {}) {
    this.error = message;
    params = {
      ...params,
      [LatchAnalyticsConstants.ErrorMessage]: message
    };
    this.analyticsService.track(`Import ${this.importName} Failure`, params);
  }

  // handling for file reader errors
  private handleReaderError(error: ProgressEvent) {
    if (error.target && hasProperty(error.target, 'error')) {
      if (error.target.error) {
        this.error = error.target.error as string;
      }
    } else {
      this.error = DEFAULT_ERROR_MESSAGE;
    }
    this.analyticsService.track(`Import ${this.importName} Failure`, {
      [LatchAnalyticsConstants.ErrorMessage]: this.error
    });
  }

}
