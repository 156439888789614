import { Component, OnInit, Input } from '@angular/core';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { FloorGroupListBaseComponent } from './floor-group-list-base/floor-group-list.base.component';
import { FloorGroup } from '../../../models/floor-group';
import { isDefined } from '@latch/latch-web';

@Component({
  selector: 'latch-floor-group-list',
  templateUrl: './floor-group-list.component.html',
  styleUrls: ['./floor-group-list.component.scss']
})
export class FloorGroupListComponent extends FloorGroupListBaseComponent implements OnInit {
  @Input() selectedFloorGroup: FloorGroup | undefined;
  @Input() defaultFloorGroup: FloorGroup | undefined;

  public get isDoorcodeFloorGroupSelected(): boolean {
    const doorcodeFloorGroup = this._elevatorDetails && this._elevatorDetails.doorcodeFloorGroup;
    return !!this.selectedFloorGroup && !!doorcodeFloorGroup &&
      this.selectedFloorGroup === doorcodeFloorGroup;
  }

  constructor(analyticsService: LatchAnalyticsService) {
    super(analyticsService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.isDoorcodeFloorGroupSelected && this._elevatorDetails.doorcodeFloorGroup) {
      this.viewFloorGroup(this._elevatorDetails.doorcodeFloorGroup);
    }
  }

  getPageTrackingInfo(): Record<string, unknown> {
    return { [LatchAnalyticsConstants.PageName]: 'Elevator Floor Group List' };
  }

  isFloorGroupSelected(floorGroup: FloorGroup): boolean {
    return isDefined(this.selectedFloorGroup)
      && this.selectedFloorGroup.uuid === floorGroup.uuid;
  }

  isFloorGroupDefault(floorGroup: FloorGroup): boolean {
    return isDefined(this.defaultFloorGroup) &&
      this.defaultFloorGroup.uuid === floorGroup.uuid;
  }

  public stopViewingFloorGroup() {
    if (this.isDoorcodeFloorGroupSelected) {
      this.cancel.emit();
    }
    super.stopViewingFloorGroup();
  }
}
