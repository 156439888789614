import { Injectable } from '@angular/core';
import {
  CreateLeaseInputV2,
  Lease,
  LeaseResidents,
  LeaseShort,
  RentDetailsResponse,
  UpdateLeaseInput,
  UpdateLeasesBatchInput
} from 'manager/models/lease';
import { Transaction } from 'manager/models/payment';
import { Observable } from 'rxjs';

export interface GetLeasesInput {
  buildingUUID: string,
  primaryResidentUserUUID?: string;
  destinationId?: string;
}

@Injectable()
export abstract class LeaseService {
  public abstract getLeasesV2(input: GetLeasesInput): Observable<Lease[]>;
  public abstract getLeaseV2(uuid: string): Observable<Lease>;
  public abstract getLease(uuid: string): Observable<Lease>;
  public abstract createLeaseV2(input: CreateLeaseInputV2): Observable<LeaseShort>;
  public abstract updateLease(leaseUUID: string, input: UpdateLeaseInput): Observable<LeaseShort>;
  public abstract updateLeasesBatch(input: UpdateLeasesBatchInput): Observable<void>;
  public abstract deleteLease(leaseUUID: string): Observable<void>;
  public abstract resendInviteEmail(leaseUUID: string): Observable<void>;
  public abstract getPaymentHistory(leaseUUID: string): Observable<Transaction[]>;
  public abstract getPaymentDetails(leaseUUID: string, paymentUUID: string): Observable<Transaction>;
  public abstract getRentDetails(leaseUUID: string): Observable<RentDetailsResponse>;
  public abstract getLeaseResidents(leaseUUID: string): Observable<LeaseResidents>;
}
