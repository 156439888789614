import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { asyncScheduler, filter, map, Observable, observeOn, ReplaySubject, tap } from 'rxjs';
import { urlToQueryParams } from './utility/report-utility';

interface QueryParamsEvent {
  consumed: boolean;
  hyperlink: string;
}

@Injectable({ providedIn: 'root' })
export class ReportPageService {

  private hyperlink$ = new ReplaySubject<QueryParamsEvent>(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public navigateToReport(hyperlink: string, options?: { replaceUrl: boolean; }): void {
    this.hyperlink$.next({ consumed: false, hyperlink });
    const url = this.router.parseUrl(hyperlink);
    if (!url.queryParams.building) {
      url.queryParams.building = this.route.snapshot.queryParams.building;
    }
    this.router.navigateByUrl(url, {
      replaceUrl: options?.replaceUrl ?? false,
    });
  }

  public getNavigateToReportHyperlink(): Observable<string> {
    return this.hyperlink$.pipe(
      observeOn(asyncScheduler),
      filter((event) => !event.consumed),
      tap((event) => {
        this.hyperlink$.next({ hyperlink: event.hyperlink, consumed: true });
      }),
      map((event) => event.hyperlink),
    );
  }

}
