import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { DATE_DISPLAY_FORMAT } from 'manager/services/utility/date-time';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  transform(date: Date): string {
    if (!date) {
      return '';
    }

    const momentDate = moment(date);

    if (momentDate.isSame(moment(), 'day')) {
      return momentDate.format('h:mma').toLowerCase();
    }

    if (momentDate.isSame(moment(), 'year')) {
      return momentDate.format('MMM D');
    }

    return momentDate.format(DATE_DISPLAY_FORMAT);
  }
}
