import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DefaultNameFormattingType } from '../models/intercom';
import { UserNameInfo } from '../models/user';
import { capitalize } from 'manager/services/utility/utility';
@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'intercomDisplayName'
})
export class IntercomDisplayNamePipe implements PipeTransform {

  transform(nameInfo: UserNameInfo, nameFormattingType: DefaultNameFormattingType): string {
    switch (nameFormattingType) {
      case DefaultNameFormattingType.FIRST_INITIAL_LAST_NAME: return this.firstInitialLastName(nameInfo);
      case DefaultNameFormattingType.FIRST_NAME_LAST_INITIAL: return this.firstNameLastInitial(nameInfo);
      case DefaultNameFormattingType.FIRST_NAME_LAST_NAME: return this.firstNameLastName(nameInfo);
    }
  }

  private firstInitialLastName(nameInfo: UserNameInfo): string {
    const firstNameInitial = nameInfo.firstName ? this.getInitial(nameInfo.firstName) : '';
    const lastName = nameInfo.lastName ? ` ${capitalize(nameInfo.lastName)}` : '';
    return `${firstNameInitial}${lastName}`;
  }

  private firstNameLastInitial(nameInfo: UserNameInfo): string {
    const firstName = nameInfo.firstName ? capitalize(nameInfo.firstName) : '';
    const lastNameInitial = nameInfo.lastName ? ` ${this.getInitial(nameInfo.lastName)}` : '';
    return `${firstName}${lastNameInitial}`;
  }

  private firstNameLastName(nameInfo: UserNameInfo): string {
    const firstName = nameInfo.firstName ? capitalize(nameInfo.firstName) : '';
    const lastName = nameInfo.lastName ? ` ${capitalize(nameInfo.lastName)}` : '';
    return `${firstName}${lastName}`;
  }

  private getInitial(value: string): string {
    return `${value.charAt(0).toUpperCase()}.`;
  }
}
