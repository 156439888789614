import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  UserIntercomSettings,
  IntercomUnit,
  ResidentContactType,
  IntercomConnectionStatus,
  IntercomManagementDisplaySettings,
  ResidentWarning,
  UpdateIntercomConnectionStatus,
  DirectoryListType,
  DirectorySortType
} from 'manager/models/intercom';
import {
  DisplayNamePreference,
  IntercomNetworkConnection,
  EthernetNetworkType,
  WifiSecurityType,
  BuildingIntercomSettings,
  DefaultNameFormattingType,
  VirtualIntercom
} from '../../models/intercom';
import { ServiceResponse, NextPage } from '../interfaces';
import { map } from 'rxjs/operators';

export interface CreateUserIntercomSettingsInput {
  isVisible: boolean;
  namePreference?: DisplayNamePreference;
  customName?: string;
}

interface ResidentContactTypeResponse {
  type: ResidentContactType;
}

export interface ResidentsForIntercomUnitsResponse {
  id: number;
  name: string;
  contact: ResidentContactTypeResponse;
  residentWarning: ResidentWarning;
}

export interface FullIntercomUnitInfo extends IntercomUnit {
  name: string;
  residents: ResidentsForIntercomUnitsResponse[];
}

export interface IntercomShortInfo {
  intercomId: number;
  lockUUID: string;
  parrotSerialNumber: string | null;
  releaseVersion?: string | null;
}

export interface UpdateIntercomUnitVisibilityInput {
  unitId: number;
  visible: boolean;
}

export interface IntercomManagementCandidate {
  contactCardUUID: string;
  userUUID: string;
  firstName: string;
  lastName: string | undefined;
  hasAccess: boolean;
  phoneNumber: string | undefined;
}

export interface IntercomManagementCandidateShortInfo {
  contactCardUUID: string;
  firstName: string;
  lastName: string | undefined;
}

export interface GetIntercomManagementCandidatesResponse {
  people: IntercomManagementCandidate[];
  metadata: {
    nextPage: NextPage | null;
  };
}

export interface GetIntercomManagementCandidatesInput {
  start?: number;
  limit?: number;
  search?: string;
}

export interface IntercomManagerResponse {
  id: number;
  intercomId: number;
  displayName: string;
  contactCardId: string;
  firstName: string;
  lastName: string | undefined;
  creationTimestamp: number;
  displaySettings: IntercomManagementDisplaySettings;
}

export interface AddIntercomManagerInput {
  displayName: string;
  contactCardId: string;
  displaySettings: IntercomManagementDisplaySettings;
}

export interface WifiConfigurationInput {
  securityType: WifiSecurityType;
  ssid: string;
	passkey?: string;
}

export interface EthernetConfigurationInput {
  networkType: EthernetNetworkType;
  gateway?: string;
  subnetMask?: string;
  ipAddress?: string;
}

export interface ChangeIntercomNetworkSettingsInput {
  enableConnections: IntercomNetworkConnection[];
  // Wifi configuration input will be included if wifi connection is enabled
  lockWifiConfigurationDetails?: WifiConfigurationInput;
  // Ethernet configuration input will be included if ethernet connection is enabled
  lockEthernetConfigurationDetails?: EthernetConfigurationInput;
}

export interface ChangeIntercomNetworkSettingsResponse {
  connectionStatusEventId: string;
}

export interface UpdateIntercomConnectionStatusResponse {
  intercomId: number;
  connectionStatus: UpdateIntercomConnectionStatus;
  lastUpdateTime: number;
}

export interface UpdateIntercomManagerInput {
  displayName: string;
  contactCardId: string;
  displaySettings: IntercomManagementDisplaySettings;
}

export interface UpdateBuildingIntercomSettingsRequest {
  customNamesDisabled: boolean;
  defaultNameFormattingType: DefaultNameFormattingType;
  directoryListType: DirectoryListType;
  directorySortType: DirectorySortType;
}

export const NO_INTERCOM_SETTINGS_FOR_CONTACT_CARD_RESIDENT =
  'NO_INTERCOM_SETTINGS_FOR_CONTACT_CARD_RESIDENT';
export const DUPLICATE_NAME = 'DUPLICATE_NAME';
export const NO_DEVICE_PAIRED = 'Intercom is not paired';
export const INTERCOM_NOT_FOUND_ERROR = 'INTERCOM_NOT_FOUND_ERROR';
export const MANAGEMENT_NOT_FOUND_ERROR = 'MANAGEMENT_NOT_FOUND';
export const CONTACT_CARD_NOT_FOUND_ERROR = 'CONTACT_CARD_NOT_FOUND';
export const BUILDING_INTERCOM_SETTINGS_NOT_FOUND_ERROR = 'BUILDING_INTERCOM_SETTINGS_NOT_FOUND_ERROR';
export const BUILDING_INTERCOM_SETTINGS_CHANGES_SAVED_MESSAGE = 'Changes saved successfully';
export const VIRTUAL_INTERCOM_NOT_FOUND_ERROR = 'VIRTUAL_INTERCOM_NOT_FOUND_ERROR';


@Injectable()
export abstract class IntercomService {

  abstract getUserIntercomSettings(personUUID: string): Observable<UserIntercomSettings>;

  /**
   * Creates or updates user intercom settings.
   * If updating, any fields marked null are ignored - not removed.
   */
  abstract createUserIntercomSettings(personUUID: string, input: CreateUserIntercomSettingsInput): Observable<UserIntercomSettings>;

  abstract getUnitsForIntercom(intercomId: number): Observable<FullIntercomUnitInfo[]>;

  abstract getBuildingIntercoms(buildingUUID: string): Observable<IntercomShortInfo[]>;

  abstract getBuildingVirtualIntercoms(buildingUUID: string): Observable<VirtualIntercom[]>;

  getIntercomForLock(lockUUID: string, buildingUUID: string): Observable<IntercomShortInfo> {
    const allIntercoms$ = this.getBuildingIntercoms(buildingUUID);

    return allIntercoms$.pipe(
      map(intercoms => intercoms.filter(intercom => intercom.lockUUID === lockUUID)[0])
    );
  }

  getVirtualIntercomForLock(lockUUID: string, buildingUUID: string): Observable<VirtualIntercom> {
    const allVirtualIntercoms$ = this.getBuildingVirtualIntercoms(buildingUUID);

    return allVirtualIntercoms$.pipe(
      map(virtualIntercoms => virtualIntercoms.filter(virtualIntercom => virtualIntercom.lockUUID === lockUUID)[0])
    );
  }

  getIntercomDetails(buildingUUID: string, intercomId: number): Observable<IntercomShortInfo> {
    const allIntercoms$ = this.getBuildingIntercoms(buildingUUID);

    return allIntercoms$.pipe(
      map(intercoms =>
        intercoms.filter(intercom => intercom.intercomId.toString() === intercomId.toString())[0]
      )
    );
  }

  getIntercomCustomNamesDisabled(buildingIntercomSettings: BuildingIntercomSettings): boolean {
    return buildingIntercomSettings.customNamesDisabled !== undefined ? buildingIntercomSettings.customNamesDisabled : false;
  }

  abstract updateUnitVisibility(intercomId: number, input: UpdateIntercomUnitVisibilityInput[]): Observable<ServiceResponse>;

  /**
   * Returns intercom settings for all users in the unit specified in the input param.
   */
  abstract getUnitIntercomSettings(unitId: number): Observable<UserIntercomSettings[]>;

  abstract getIntercomConnectionStatus(lockUUID: string): Observable<IntercomConnectionStatus>;

  abstract getIntercomManagementCandidates(
    intercomId: number,
    input: GetIntercomManagementCandidatesInput
  ): Observable<GetIntercomManagementCandidatesResponse>;

  abstract getIntercomManagement(intercomId: number): Observable<IntercomManagerResponse[]>;

  abstract addIntercomManagement(intercomId: number, input: AddIntercomManagerInput): Observable<IntercomManagerResponse>;

  abstract changeIntercomConnectionSettings(
    intercomId: number,
    input: ChangeIntercomNetworkSettingsInput
  ): Observable<ChangeIntercomNetworkSettingsResponse>;

  abstract checkIntercomConnectionStatus(intercomId: number, connectionEventId: string): Observable<UpdateIntercomConnectionStatusResponse>;

  abstract getIntercomManager(intercomId: number, managerId: number): Observable<IntercomManagerResponse>;

  abstract deleteIntercomManager(intercomId: number, managerId: number): Observable<null>;

  abstract updateIntercomManager(
    intercomId: number,
    managerId: number,
    input: UpdateIntercomManagerInput
  ): Observable<IntercomManagerResponse>;

  abstract getBuildingIntercomSettings(buildingUUID: string): Observable<BuildingIntercomSettings>;

  abstract updateBuildingIntercomSettings(
    buildingUUID: string,
    input: UpdateBuildingIntercomSettingsRequest
  ): Observable<BuildingIntercomSettings>;

  abstract rotateVirtualIntercom(buildingUUID: string, virtualIntercomUUID: string): Observable<VirtualIntercom>;
}
