import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageWithTabsBaseComponent } from 'manager/components/page-with-tabs-base/page-with-tabs-base.component';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { PermissionsService } from 'manager/services/permissions/permissions.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LatchNavAction } from '@latch/latch-web';
import { LockService } from '@managerweb/services/lock/lock.service';

enum Tab {
  Doors = 'Doors',
  SmartDevices = 'SmartDevices',
  ServiceOrders = 'ServiceOrders',
  Deliveries = 'Deliveries',
  Packages = 'Packages',
  Bookings = 'Bookings',
}

@Component({
  selector: 'latch-activity-list-page',
  templateUrl: './activity-list-page.component.html',
  styleUrls: ['./activity-list-page.component.scss'],
  providers: [PageWithTabsService]
})
export class ActivityListPageComponent extends PageWithTabsBaseComponent<Tab> implements OnInit {
  baseUrl = '/console/activity';
  defaultTab = Tab.Doors;
  tabData = [
    { id: Tab.Doors, path: '' },
    { id: Tab.SmartDevices, path: 'device' },
    { id: Tab.ServiceOrders, path: 'service-orders' },
    { id: Tab.Deliveries, path: 'delivery' },
    { id: Tab.Packages, path: 'packages' },
    { id: Tab.Bookings, path: 'bookings' },
  ];

  Tab = Tab;
  hasSmartHomeFeature = false;
  showServiceOrdersTab = false;
  hasDeliveryAssistantFeature = false;
  hasPackageManagementFeature = false;
  hasBookingsFeature = false;
  actions: LatchNavAction[] = [
    {
      id: 'export',
      name: 'Export',
      clickHandler: () => this.exportDoors()
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected pageWithTabsService: PageWithTabsService,
    private featureService: FeatureService,
    private permissionsService: PermissionsService,
    private lockService: LockService,
  ) {
    super(route, router, pageWithTabsService);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest([
      this.featureService.hasSmartHomeFeature$,
      this.featureService.hasServiceOrdersFeature$,
      this.featureService.hasDeliveryAssistantFeature$,
      this.featureService.hasPackageManagementFeature$,
      this.featureService.hasBookingsFeature$,
      this.permissionsService.currentUserIsOwner()
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([
      hasSmartHomeFeature,
      hasServiceOrdersFeature,
      hasDeliveryAssistantFeature,
      hasPackageManagementFeature,
      hasBookingsFeature,
      currentUserIsOwner
    ]) => {
      this.hasSmartHomeFeature = hasSmartHomeFeature;
      this.showServiceOrdersTab = currentUserIsOwner && hasServiceOrdersFeature;
      this.hasDeliveryAssistantFeature = hasDeliveryAssistantFeature;
      this.hasPackageManagementFeature = hasPackageManagementFeature;
      this.hasBookingsFeature = hasBookingsFeature;
    });
  }

  onTabClick(tabId: string) {
    this.actions[0].disabled = tabId !== Tab.Doors;
    super.onTabClick(tabId);
  }

  private exportDoors(): void {
    this.lockService.exportAccessLogs().subscribe(data => {
      const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
      window.open(csvContent);
    });
  }
}
