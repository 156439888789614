import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AccountPermissions } from '../../models/account';
import { Address, Building, BuildingInput } from './../../models/building';
import { SyncTask } from './../../models/sync-status';
import { AuthService } from './../auth/auth.service';

/**
 * Service which provides a list of buildings accessible to the current user.
 */
@Injectable()
export abstract class BuildingService {

  readonly buildingAdded$ = new BehaviorSubject<Building | undefined>(undefined);
  protected buildings$?: Observable<Building[]>;

  constructor(protected authService: AuthService) {
  }

  initialize() {
    this.authService.isLoggedIn$.subscribe((session) => {
      if (!session) {
        this.buildings$ = undefined;
      }
    });
  }

  // Perhaps this filter should be done by an appstate service, but no part of the app should
  // ever see buildings without ManageBuildings permission, so filter here for convenience.
  filterBuildingsWithPermission(buildings: Building[]): Building[] {
    return buildings.filter((building) => building.permissions && building.permissions.includes(AccountPermissions.ManageBuildings));
  }

  /**
   * Returns a list of buildings in the current account.
   *
   * Provides only a list including UUID and name.
   */
  abstract getBuildings(): Observable<Building[]>;

  abstract refreshBuildings(): void;

  abstract createBuilding(buildingDetails: BuildingInput, accountUUID?: string): Observable<Building>;

  abstract formatAddress(address: Address): Observable<{ address: Address, formattedAddress: string }>;

  abstract getBuildingTasks(buildingUUID: string): Observable<SyncTask[]>;
}
