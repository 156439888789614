import { Building } from './building';

export interface Account {
  name: string;
  uuid: string;
  buildings?: Building[];
  permissions: AccountPermissions[];
  mfa?: {
    smsRequired: boolean
  };
}

export enum AccountPermissions {
  ViewLocks = 'VIEW_LOCKS',
  ViewBuildings = 'VIEW_BUILDINGS',
  ManagementConsole = 'MANAGEMENT_CONSOLE',
  ManageBuildings = 'MANAGE_BUILDINGS',
  CreateAdministrator = 'CREATE_ADMINISTRATOR',
}
