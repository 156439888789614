import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CourierNamesResponse,
  DeliveriesFilter,
  DeliveryListResponse,
  DeliveryUnitListResponse,
  DeliveryUnitsFilter,
  NotifyResidentRequest,
  NotifyResidentResponse
} from '../../models/delivery';
@Injectable({
  providedIn: 'root'
})
export abstract class DeliveryService {

  abstract getDeliveries(buildingUuid: string, filter: DeliveriesFilter): Observable<DeliveryListResponse>;

  abstract getDeliveryUnits(buildingUuid: string, filter: DeliveryUnitsFilter): Observable<DeliveryUnitListResponse>;

  abstract getCourierNames(): Observable<CourierNamesResponse>;

  abstract notifyResidents(
    buildingUUID: string,
    deliveryRequestId: string,
    notifyResidents: NotifyResidentRequest
  ): Observable<NotifyResidentResponse>;
}
