<latch-base-modal>
  <div class="base-modal-card">
    <div class="base-modal-card-header">
      <div class="base-modal-card-actions">
        <button class="latch-button-icon" (click)="closeModal.emit()">
          <span class="latch-icon-close"></span>
        </button>
        <button class="latch-button-link" (click)="openSendNotification.emit()">Send Notification</button>
      </div>
      <div class="base-modal-card-title">Unit {{deliveryUnit.unit.displayName}}</div>
    </div>
    <latch-tab-group [selected]="activeTab" (tabClicked)="setActiveTab($event)">
      <latch-tab [id]="Tab.Notifications">Notifications</latch-tab>
    </latch-tab-group>
    <div class="base-modal-card-body">
      <ng-container *ngIf="activeTab === Tab.Notifications">
        <ng-container *ngFor="let notification of deliveryUnit.notifications; let i = index">
          <div class="latch-divider" *ngIf="i > 0"></div>
          <latch-labeled-list-item>
            <div class="notification-elements">
              <div class="notification-element info">
                <span class="name">{{notification.contactCardFullName}}</span>
                {{notificationTypeNames[notification.notificationType]}}
              </div>
              <div class="notification-element time">
                {{notification.notifiedOn | date: 'MM/dd/yyyy h:mm a'}}
              </div>
            </div>
          </latch-labeled-list-item>
        </ng-container>
      </ng-container>
    </div>
    <div class="base-modal-card-footer"></div>
  </div>
</latch-base-modal>
