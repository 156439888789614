<latch-card class="page" *ngIf="mode === Mode.Card">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="cancel.emit()">
        <span class="latch-icon-close"></span>
      </button>
      <a
        [href]="importTemplateUrl"
        download="Latch - Import {{entity}} Sample.csv"
        class="latch-button-link"
      >
        Download Template File
      </a>
    </div>
    <div class="latch-card-title">Import CSV</div>
    <p *ngIf="pageDescription" class="page-description">
      {{pageDescription}}
    </p>
  </ng-container>

  <ng-container latchCardContent [ngTemplateOutlet]="inlineComponent"></ng-container>

  <ng-container latchCardFooter>
    <button
      class="latch-button"
      [disabled]="!file"
      (click)="handleImport()"
    >Import CSV</button>
  </ng-container>
</latch-card>

<div class="inline-container" *ngIf="mode === Mode.Inline">
  <a
    [href]="importTemplateUrl"
    download="Latch - Import {{entity}} Sample.csv"
    class="latch-button-link"
  >
    Download Template File
  </a>
  <ng-container [ngTemplateOutlet]="inlineComponent"></ng-container>
</div>

<ng-template #inlineComponent>
  <div class="drop-container">
    <div
      class="drop-area"
      [class.hover]="hasHover"
      [class.has-file]="!!file"
      (dragover)="handleDragOver($event)"
      (dragleave)="handleDragLeave($event)"
      (drop)="handleDrop($event)"
    >
      <input
        id="file"
        type="file"
        class="file-input"
        accept=".csv"
        (change)="handleInput($event)"
        #fileInput
      />
      <label for="file">
        <div *ngIf="!file">
          <p>Drag and drop a file</p>
          <p>or</p>
          <div class="latch-button-dashed">Select a file</div>
        </div>
        <div class="file-name" *ngIf="file">
          {{file.name}}
          <button class="latch-icon-close" (click)="handleClear($event)"></button>
        </div>
      </label>
    </div>

    <div class="has-error" *ngIf="error">{{error}}</div>
  </div>
</ng-template>
