import moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Building } from '../../models/building';
import { getValidTimeZone } from '../utility/date-time';
import { Observable, of } from 'rxjs';
import { defaultIfEmpty, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { SelectedAccountService } from './selected-account.service';
import { Account } from '@managerweb/models/account';


@Injectable({ providedIn: 'root' })
export class SelectedBuildingsService {

  public selectedBuildings: Building[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private selectedAccountService: SelectedAccountService,
  ) {
  }

  /**
   * @deprecated there was never multiple selected buildings possible, to be replaced by getSelectedBuilding
   * @param options emitEmptyBuildings: boolean
   * @returns Array of selected buildings
   */
  getSelectedBuildings(options?: { emitEmptyBuildings: boolean }): Observable<Building[]> {
    return this.getSelectedBuilding().pipe(
      map(building => building ? [building] : []),
      filter((buildings: Building[]) => options?.emitEmptyBuildings ? true : buildings.length > 0),
    );
  }

  getSelectedBuilding() {
    return this.activatedRoute.queryParams.pipe(
      map(({ building }) => building as string | undefined),
      distinctUntilChanged((prevBuilding, newBuilding) => prevBuilding === newBuilding),
      switchMap(building => this.selectedAccountService.getSelectedAccount().pipe(
        defaultIfEmpty(undefined),
        map(account => [account, building] as [Account | undefined, string | undefined])
      )),
    ).pipe(
      switchMap(([account, selectedBuildingUUID]) => {
        const selectedBuilding = account?.buildings?.find(building => building.uuid === selectedBuildingUUID);
        return selectedBuilding ? of(selectedBuilding) : of(undefined);
      }),
      tap(building => {
        this.selectedBuildings = building ? [building] : [];
        if (building) {
          moment.tz.setDefault(getValidTimeZone(building.timezone));
        }
      })
    );
  }

}
