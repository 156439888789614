import moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SmartHomeDeviceRenamingErrors, SmartHomeService } from 'manager/services/smart-home/smart-home.service';
import { Subject, zip, of, Observable, combineLatest } from 'rxjs';
import {
  SmartDevice,
  SmartDeviceType,
  SmartDeviceConnectionType,
  SmartThermostatMode,
  isTemperatureSetpointTrait,
  isHVACModeTrait,
  isTemperatureTrait,
  isLightOnOffTrait,
  SmartDeviceTypeForDisplayAndSearch,
  isHubPairTrait,
  isHubPowerTrait,
  isHubSpacesTrait,
  HubAccessPointInfoTraitDetails,
  isHubAccessPointInfoTrait,
  isLeakLastDetectionTrait,
  isDevicePowerTrait,
  DevicePowerTraitDetails,
  SmartDeviceManufacturer,
  isLightDimmerLevelTrait,
  DimmerLightModel,
} from 'manager/models/device';
import { ActivatedRoute, Params } from '@angular/router';
import { map, distinctUntilChanged, takeUntil, switchMap, take } from 'rxjs/operators';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { SpaceService, SpaceShortInfo } from 'manager/services/space/space.service';
import { SpaceType } from 'manager/models/space';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { logError, LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';
import { Location } from '@angular/common';
import { keyBy } from 'manager/services/utility/utility';

@Component({
  selector: 'latch-device-detail-page',
  templateUrl: './device-detail-page.component.html',
  styleUrls: ['./device-detail-page.component.scss']
})
export class DeviceDetailPageComponent implements OnInit, OnDestroy {

  isLoading = false;
  device!: SmartDevice;
  private spacesByUUID: Record<string, SpaceShortInfo> = {};
  SmartDeviceType = SmartDeviceType;
  SmartDeviceConnectionType = SmartDeviceConnectionType;
  SmartDeviceManufacturer = SmartDeviceManufacturer;
  SpaceType = SpaceType;
  SmartDeviceTypeForDisplayAndSearch = SmartDeviceTypeForDisplayAndSearch;
  // Represents the hub that controls the smart device, if any
  // Currently only applicable for smart lights
  controllingHub: SmartDevice | undefined;

  // Represents devices controlled by a smart hub
  controlledDevices: SmartDevice[] = [];

  hubHotspotPassword: string | undefined;
  showHubHotspotPassword = false;

  editNameMode = false;
  name = '';
  nameError = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private smartHomeService: SmartHomeService,
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private spaceService: SpaceService,
    private selectedBuildingsService: SelectedBuildingsService,
    private analyticsService: LatchAnalyticsService,
    private location: Location
  ) { }

  get spaceTemperature(): number | null {
    const temperatureTrait = this.device.traits.find(isTemperatureTrait);

    // The device doesn't have the expected trait.
    if (!temperatureTrait) {
      return null;
    }

    return temperatureTrait.parameters.ambientCelsius;
  }

  get heatSetpoint(): number | null {
    const temperatureSetpointTrait = this.device.traits.find(isTemperatureSetpointTrait);
    const hvacModeTrait = this.device.traits.find(isHVACModeTrait);
    if (!temperatureSetpointTrait || !hvacModeTrait) {
      // The device doesn't have both the expected traits.
      return null;
    }

    if (hvacModeTrait.parameters.mode !== SmartThermostatMode.Auto &&
      hvacModeTrait.parameters.mode !== SmartThermostatMode.Heat) {
      // The device is not in a mode which has an applicable heat setpoint.
      return null;
    }

    return temperatureSetpointTrait.parameters.heatCelsius;
  }

  get coolSetpoint(): number | null {
    const temperatureSetpointTrait = this.device.traits.find(isTemperatureSetpointTrait);
    const hvacModeTrait = this.device.traits.find(isHVACModeTrait);
    if (!temperatureSetpointTrait || !hvacModeTrait) {
      // The device doesn't have both the expected traits.
      return null;
    }

    if (hvacModeTrait.parameters.mode !== SmartThermostatMode.Auto &&
      hvacModeTrait.parameters.mode !== SmartThermostatMode.Cool) {
      // The device is not in a mode which has an applicable cool setpoint.
      return null;
    }

    return temperatureSetpointTrait.parameters.coolCelsius;
  }

  get hvacMode(): string | null {
    const hvacModeTrait = this.device.traits.find(isHVACModeTrait);

    // The device doesn't have the expected trait.
    if (!hvacModeTrait) {
      return null;
    }

    return hvacModeTrait.parameters.mode;
  }

  get lightOnOffStatus(): string | null {
    const lightOnOffTrait = this.device.traits.find(isLightOnOffTrait);

    // The device doesn't have the expected trait.
    if (!lightOnOffTrait) {
      return null;
    }

    return lightOnOffTrait.parameters.isOn ? 'On' : 'Off';
  }

  get lightDimmerLevel(): number | null {
    const lightDimmerLevelTrait = this.device.traits.find(isLightDimmerLevelTrait);

    // The device doesn't have the expected trait.
    if (!lightDimmerLevelTrait) {
      return null;
    }

    return lightDimmerLevelTrait.parameters.level;
  }

  get deviceIsDimmerLight(): boolean {
    return this.device.deviceModel === DimmerLightModel;
  }

  get hubPowerMode(): string | null {
    const hubPowerTrait = this.device.traits.find(isHubPowerTrait);

    // The device doesn't have the expected trait.
    if (!hubPowerTrait) {
      return null;
    }

    return hubPowerTrait.parameters.powerType;
  }

  /**
   * Represents the names of the units a hub controls
   */
  get hubSpaceNames(): string | undefined {
    const hubSpacesTrait = this.device.traits.find(isHubSpacesTrait);
    // The device doesn't have the expected trait.
    if (!hubSpacesTrait) {
      return undefined;
    }

    const unitSpaceNames: Record<string, boolean> = {};
    let hubControlsCommunalSpace = false;
    hubSpacesTrait.parameters.spaces.forEach(spaceUUID => {
      const space = this.spacesByUUID[spaceUUID];
      if (!space) {
        return;
      }
      if (space.spaceType === SpaceType.ResidentialUnit) {
        unitSpaceNames[`Unit ${space.name}`] = true;
      }
      if (space.spaceType === SpaceType.Room) {
        const unit = this.spaceService.getUnitForRoom(space, this.spacesByUUID);
        if (unit) {
          unitSpaceNames[`Unit ${unit.name}`] = true;
        }
      }
      if (space.spaceType === SpaceType.Building || space.spaceType === SpaceType.Floor) {
        hubControlsCommunalSpace = true;
      }
    });
    const result = Object.keys(unitSpaceNames).sort((name1, name2) => name1.localeCompare(name2));
    // If the hub controls a communal space, we want to prepend 'Communal Space' to the list of names
    if (hubControlsCommunalSpace) {
      result.unshift('Communal Space');
    }

    return result.length ? result.join(', ') : 'This hub does not control any units';
  }

  get hubAccessPointTrait(): HubAccessPointInfoTraitDetails | null {
    const hubAccessPointTrait = this.device.traits.find(isHubAccessPointInfoTrait);

    // The device doesn't have the expected trait.
    if (!hubAccessPointTrait) {
      return null;
    }

    return hubAccessPointTrait;
  }

  get leakLastDetected(): Date | undefined {
    const leakLastDetectionTrait = this.device.traits.find(isLeakLastDetectionTrait);

    // The device doesn't have the expected trait OR there has been no leak detected.
    if (!leakLastDetectionTrait || !leakLastDetectionTrait.parameters.receivedTime) {
      return undefined;
    }

    return new Date(leakLastDetectionTrait.parameters.receivedTime * 1000);
  }

  get leakLastDetectedWithin24Hrs(): boolean {
    const leakTime = this.leakLastDetected;
    if (!leakTime) {
      return false;
    }
    return moment(leakTime).isSameOrAfter(moment().subtract(1, 'day'));
  }

  get devicePowerTrait(): DevicePowerTraitDetails | null {
    const devicePowerTrait = this.device.traits.find(isDevicePowerTrait);

    // The device doesn't have the expected trait
    if (!devicePowerTrait) {
      return null;
    }

    return devicePowerTrait;
  }

  get deviceLowPowerLastDetected(): Date | null {
    if (this.devicePowerTrait) {
      return new Date(this.devicePowerTrait.parameters.lastLowBatteryTime * 1000);
    }
    return null;
  }

  // Represents the space a device belongs to.
  get deviceSpace(): SpaceShortInfo {
    return this.spacesByUUID[this.device.spaceUUID];
  }

  // If the space a device is in is occupied, the BE will return an empty array for the traits field.
  // If the space is communal or unoccupied, the BE will return a fully populated traits field.
  get spaceIsOccupied(): boolean {
    // We only check for occupancy if the device is in a unit or a room.
    if (this.deviceSpace.spaceType === SpaceType.ResidentialUnit) {
      return this.deviceSpace.metadata.occupied ?? false;
    }
    if (this.deviceSpace.spaceType === SpaceType.Room) {
      const unit = this.spaceService.getUnitForRoom(this.deviceSpace, this.spacesByUUID);
      // If the unit isn't found or the metadata object doesn't exist, return true for occupancy
      return !!unit && unit.metadata ? !!unit.metadata.occupied : true;
    }

    return false;
  }

  // Returns params for pre-filtering device log when we navigate to the log from the device detail page
  // Pre-populates filter with the device type, appropriate spaceUUID, and appropriate time filter
  // Currently only used for seeing leak details
  get queryParamsForDeviceLog() {
    const params: {
      deviceType: string,
      space: string | undefined,
      startTime: Date | undefined;
    } = {
      deviceType: this.device.deviceType,
      space: undefined,
      startTime: this.leakLastDetected
    };
    if (this.deviceSpace.spaceType === SpaceType.Room) {
      const unit = this.spaceService.getUnitForRoom(this.deviceSpace, this.spacesByUUID);
      params.space = unit?.uuid;
    } else {
      params.space = this.deviceSpace.uuid;
    }
    return params;
  }

  ngOnInit() {
    this.isLoading = true;

    const deviceUUID$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.deviceUUID),
      distinctUntilChanged(),
    );

    combineLatest([this.selectedBuildingsService.getSelectedBuildings(), deviceUUID$]).pipe(
      switchMap(([buildings, deviceUUID]) => {
        const spaces$ = this.spaceService.getSpaces(buildings[0].uuid);
        const device$ = this.smartHomeService.getDeviceDetails(deviceUUID);
        return zip(spaces$, device$);
      }),
      switchMap(([spaces, device]) => {
        this.device = device;
        this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
          [LatchAnalyticsConstants.PageName]: 'Device Detail',
          [LatchAnalyticsConstants.DeviceName]: device.name,
          [LatchAnalyticsConstants.DeviceType]: device.deviceType,
          'Space UUID': device.spaceUUID
        });
        this.spacesByUUID = keyBy(spaces, 'uuid');
        this.validateDeviceDetails();
        return this.loadAdditionalDeviceDetails(device);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(([controllingHub, controlledDevices]) => {
      this.controllingHub = controllingHub;
      this.controlledDevices = controlledDevices;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  /**
   * Loads additional smart device details if necessary.
   * For lights and leaks, this includes fetching the device details of the hub that controls the device.
   * For hubs, this includes fetching a list of devices controlled by the hub.
   * For security reasons, we also reset the hub wifi hotspot password here in case the client navigates to
   * this page from another device's detail page (in which case the component does not reinitialize).
   */
  private loadAdditionalDeviceDetails(device: SmartDevice): Observable<[SmartDevice | undefined, SmartDevice[]]> {
    this.showHubHotspotPassword = false;
    this.hubHotspotPassword = undefined;
    const hubPairTrait = device.traits.find(isHubPairTrait);
    const controllingHub$ = hubPairTrait ? this.smartHomeService.getDeviceDetails(hubPairTrait.parameters.hubUUID) : of(undefined);
    const controlledDevices$ = device.deviceType === SmartDeviceType.Hub ?
      this.smartHomeService.getDevicesForHub(device.uuid) : of([]);
    return zip(controllingHub$, controlledDevices$);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  convertCelsiusToFahrenheit(temperature: number) {
    return Math.floor(temperature * 1.8 + 32);
  }

  /**
   * Given a device and a device's space, construct the full display name for the device
   * If the device is in a unit, the display name is Unit + unit name + device name
   * If the device is in a room, the display name is room name + device name
   * If the device is in a communal space (non-unit/room), the display name is only the device name
   */
  getFullDeviceDisplayName(device: SmartDevice) {
    // guards against empty device
    if (!device) {
      return 'N/A';
    }

    // guards against device space not found
    const space = this.spacesByUUID[device.spaceUUID];
    if (!space) {
      return device.name;
    }

    if (space.spaceType === SpaceType.ResidentialUnit) {
      return `Unit ${space.name} ${device.name}`;
    }
    if (space.spaceType === SpaceType.Room) {
      return `${space.name} ${device.name}`;
    }
    return device.name;
  }

  /**
   * Returns the unit name of the space the param device belongs to, if it is in a unit or room.
   * Returns 'communal space' otherwise.
   */
  getUnitNameForDevice(device: SmartDevice): string {
    const deviceSpace = this.spacesByUUID[device.spaceUUID];
    if (!deviceSpace) {
      return 'N/A';
    }
    if (deviceSpace.spaceType === SpaceType.ResidentialUnit) {
      return `Unit ${deviceSpace.name}`;
    }
    if (deviceSpace.spaceType === SpaceType.Room) {
      const unit = this.spaceService.getUnitForRoom(deviceSpace, this.spacesByUUID);
      return unit ? `Unit ${unit.name}` : 'N/A';
    }
    return 'Communal Space';
  }

  handleShowPassword() {
    this.isLoading = true;
    this.smartHomeService.getHubHotspotPassword(this.device.uuid).pipe(take(1)).subscribe(password => {
      this.hubHotspotPassword = password;
      this.showHubHotspotPassword = true;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
    return false;
  }

  handleToggleEditName() {
    if (!this.editNameMode) {
      this.track('Enter Edit Device Name Mode', {});
    }
    this.nameError = '';
    this.editNameMode = !this.editNameMode;
    this.name = this.device.name;
  }

  handleSaveEditName() {
    this.track('Edit Device Name Submit', { 'Device Name': this.name });
    this.isLoading = true;
    this.nameError = '';
    if (this.name === this.device.name) {
      this.editNameMode = false;
      this.isLoading = false;
      return;
    }
    this.smartHomeService.renameDevice(this.device.uuid, this.name)
      .subscribe(device => {
        this.editNameMode = false;
        this.device = device;
        this.isLoading = false;
        this.track('Edit Device Name Success');
      }, error => {
        this.isLoading = false;
        if (error.message === SmartHomeDeviceRenamingErrors.DuplicateName) {
          this.nameError = 'This name already exists. Please use a different device name.';
        } else if (error.message === SmartHomeDeviceRenamingErrors.NameTooLong) {
          this.nameError = 'Device name may not exceed 32 characters.';
        } else {
          this.errorHandlerService.handleException(error);
        }
        this.track('Edit Device Name Failure');
      });
  }

  handleGoBack() {
    this.location.back();
  }

  /**
   * Checks for and logs errors when component initializes
   */
  private validateDeviceDetails() {
    // Logs error if the device's spaceUUID is invalid
    if (!this.spacesByUUID[this.device.spaceUUID]) {
      logError(`Space UUID ${this.device.spaceUUID} is invalid`);
    }

    if (this.device.deviceType === SmartDeviceType.Hub) {
      const hubSpacesTrait = this.device.traits.find(isHubSpacesTrait);
      if (!hubSpacesTrait) {
        // Logs error if the device is a hub and is missing the hub spaces trait
        logError(`Hub ${this.device.uuid} is missing hub spaces trait`);
      } else {
        hubSpacesTrait.parameters.spaces.forEach(spaceUUID => {
          if (!this.spacesByUUID[spaceUUID]) {
            // Logs error if hub spaces trait contains invalid spaceUUID
            logError(`Space UUID ${spaceUUID} is invalid`);
          }
        });
      }
    }
  }

  track(eventName: string, properties = {}) {
    this.analyticsService.track(eventName, Object.assign({
      [LatchAnalyticsConstants.DeviceUUID]: this.device.uuid,
    }, properties));
  }
}
