import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BuildingFeature } from 'manager/models/building';
import { map, Observable, take } from 'rxjs';
import { BuildingService } from '../building/building.service';

export enum FeatureGuardMode {
  AllRequired,
  OneRequired,
}

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
  constructor(
    private buildingService: BuildingService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const features = route.data.requiredFeatures as BuildingFeature[];
    const mode = route.data.mode as FeatureGuardMode ?? FeatureGuardMode.AllRequired;

    if (!features || features.length === 0) {
      return true;
    }
    return this.buildingService.getBuildings().pipe(
      take(1),
      map(buildings => {
        // the SelectedBuildingsService fails to execute at this point in time due to a router limitation, so here it mimicks the behavior
        const building = buildings.find(b => b.uuid === route.queryParams.building);
        if (building) {
          let hasRequiredFeatures = false;
          switch (mode) {
            case FeatureGuardMode.AllRequired:
              hasRequiredFeatures = features.every(feature => building.features?.find(f => f === feature));
              break;
            case FeatureGuardMode.OneRequired:
              hasRequiredFeatures = features.some(feature => building.features?.find(f => f === feature));
              break;
          }
          if (hasRequiredFeatures) {
            return true;
          } else {
            return this.router.createUrlTree(['/console/403'], { queryParamsHandling: 'preserve' });
          }
        } else {
          return this.router.createUrlTree(['/console/403'], { queryParams: route.queryParams });
        }
      }),
    );
  }

}
