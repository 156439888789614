import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AdministratorService, NO_SUCH_ADMIN_ERROR } from './administrator.service';
import { AuthService } from '../auth/auth.service';
import {
  AccountAdministrationSettings,
  CreateAccountAdministrationSettings,
  RawAccountAdministrationSettings,
  UpdateAccountAdministrationSettings,
  RawKeyAdmin,
  RawSiteAdmin
} from '../../models/account-administration';
import { User } from '../../models/user';
import { convertListToMap as convertLargeList } from '../utility/http-large-list';
import { map, switchMap } from 'rxjs/operators';

const convertKeyAdminLargeList = (keyAdmins: RawKeyAdmin[]) => {
  const newKeyAdmins: {
    buildingUUID: string,
    keyUUIDs: Record<string, string>,
    assignAllKeys: boolean,
    manageAllKeys: boolean,
  }[] = [];
  keyAdmins.forEach(value => {
    newKeyAdmins.push({
      buildingUUID: value.buildingUUID,
      keyUUIDs: convertLargeList(value.keyUUIDs),
      assignAllKeys: value.assignAllKeys,
      manageAllKeys: value.manageAllKeys
    });
  });
  return convertLargeList(newKeyAdmins);
};

const convertSiteAdminLargeList = (siteAdmins: RawSiteAdmin[]) => convertLargeList(siteAdmins);

@Injectable()
export class HTTPAdministratorService extends AdministratorService {

  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  getAdministrators(accountUUID: string): Observable<AccountAdministrationSettings[]> {
    return this.authService.request({
      method: 'GET',
      endpoint: `/web/v1/accounts/${accountUUID}/administrators`
    }).pipe(map((response) => {
      const rawSettings: RawAccountAdministrationSettings[] = AuthService.getPayload(response);
      return rawSettings.map((rawSetting) => new AccountAdministrationSettings(rawSetting));
    }));
  }

  getMaintenanceAdministrators(accountUUID: string): Observable<AccountAdministrationSettings[]> {
    return this.authService.request({
      method: 'GET',
      endpoint: `/web/v1/accounts/${accountUUID}/administrators`
    }).pipe(map((response) => {
      const rawSettings: RawAccountAdministrationSettings[] = AuthService.getPayload(response);
      return rawSettings.map((rawSetting) => new AccountAdministrationSettings(rawSetting))
        .filter((adminSettings) => adminSettings.maintenanceAdmins?.length > 0);
    }));
  }

  getMatchingAdministrator(accountUUID: string, userUUID: string): Observable<AccountAdministrationSettings> {
    return this.getAdministrators(accountUUID)
      .pipe(switchMap((adminSettingsArr: AccountAdministrationSettings[]) => {
        // find admin settings matching this userUUID
        const matchedSettings: AccountAdministrationSettings = adminSettingsArr.filter((settings) => settings.userUUID === userUUID)[0];
        if (!matchedSettings) {
          // throw an error if there is no matching administrator
          return throwError(new Error(NO_SUCH_ADMIN_ERROR));
        } else {
          return of(matchedSettings);
        }
      }));
  }

  createAccountOwner(accountUUID: string, userData: User): Observable<AccountAdministrationSettings> {
    /* default settings for new account Owners */
    const newAccountData = {
      accountUUID,
      userUUID: userData.uuid,
      owner: true,
      siteAdmins: {},
      keyAdmins: {},
      maintenanceAdmins: {}
    };

    return this.authService.request({
      method: 'POST',
      endpoint: '/web/v3/administrators',
      data: newAccountData
    })
      .pipe(map((response) => new AccountAdministrationSettings(AuthService.getPayload(response))));
  }

  createAdministrator(
    adminPermissionsObject: CreateAccountAdministrationSettings
  ): Observable<AccountAdministrationSettings> {
    const data = {
      accountUUID: adminPermissionsObject.accountUUID,
      userUUID: adminPermissionsObject.userUUID,
      owner: adminPermissionsObject.owner,
      siteAdmins: convertSiteAdminLargeList(adminPermissionsObject.siteAdmins),
      keyAdmins: convertKeyAdminLargeList(adminPermissionsObject.keyAdmins),
      maintenanceAdmins: convertLargeList(adminPermissionsObject.maintenanceAdmins)
    };
    return this.authService.request({
      method: 'POST',
      endpoint: '/web/v3/administrators',
      data
    })
      .pipe(map((response) => new AccountAdministrationSettings(AuthService.getPayload(response))));
  }

  updateAdministrator(
    adminPermissionsObject: UpdateAccountAdministrationSettings
  ): Observable<AccountAdministrationSettings> {
    const data = {
      uuid: adminPermissionsObject.uuid,
      owner: adminPermissionsObject.owner,
      siteAdminsToUpsert: convertSiteAdminLargeList(adminPermissionsObject.siteAdminsToUpsert),
      keyAdminsToUpsert: convertKeyAdminLargeList(adminPermissionsObject.keyAdminsToUpsert),
      // uuids of buildings admin no longer has device administration privileges for
      siteAdminsToRemove: convertLargeList(adminPermissionsObject.siteAdminsToRemove),
      // uuids of buildings admin no longer has key administration privileges for
      keyAdminsToRemove: convertLargeList(adminPermissionsObject.keyAdminsToRemove)
    };
    return this.authService.request({
      method: 'PATCH',
      endpoint: `/web/v3/administrators/${adminPermissionsObject.uuid}`,
      data
    })
      .pipe(map((response) => new AccountAdministrationSettings(AuthService.getPayload(response))));
  }

  deleteAdministrator(adminUUID: string): Observable<any> {
    return this.authService.request({
      method: 'DELETE',
      endpoint: `/web/v1/administrators/${adminUUID}`,
    })
      .pipe(map((response) => AuthService.getPayload(response)));
  }
}
