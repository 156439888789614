import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { EMPTY, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { logInfo } from '@latch/latch-web';
import { SelectedAccountService } from '../../services/appstate/selected-account.service';
import { map } from 'rxjs/operators';

/**
 * Sole purpose of this component is to figure out which should be the initial landing page of the MC
 * depending on the different feature flags that exist. This logic was fragmented and located in different
 * places, but now this component consolidates the logic and makes it very easy to understand what is happening
 * and make changes easily whenever needed. This functionality could also be implemented as a CanActivate Guard,
 * however we need to guarantee that there is a building selected and a query param appended to the url, which
 * causes the race condition and makes it very complicated to be implemented as a Guard.
 */
@Component({
  selector: 'latch-initialize',
  template: '',
})
export class InitializeComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(
    private featureService: FeatureService,
    private selectedBuildingsService: SelectedBuildingsService,
    private router: Router,
    private selectedAccountService: SelectedAccountService,
  ) {
  }

  public ngOnInit() {
    const noBuildings$ = this.selectedAccountService.getSelectedAccount().pipe(
      map(account => account.buildings?.length === 0),
      tap((noBuildings: boolean) => {
        if (noBuildings) {
          this.router.navigate(['/console/no-building'], { replaceUrl: true });
          logInfo('No buildings in Portfolio');
        }
      }),
    );


    const insightsDashboard$ = this.featureService.hasInsightsDashboardFeature$.pipe(
      tap((hasInsightsDashboard) => {
        if (hasInsightsDashboard) {
          this.router.navigate(['/console/dashboard'], {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
          });
        }
      }),
    );

    const commercial$ = this.featureService.hasCommercialFeature$.pipe(
      tap((hasCommercialFeature) => {
        if (hasCommercialFeature) {
          this.router.navigate(['/console/checkin'], {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
          });
        }
      }),
    );

    const people$ = this.selectedBuildingsService.getSelectedBuildings().pipe(
      tap(() => {
        this.router.navigate(['/console/people'], {
          queryParamsHandling: 'preserve',
          replaceUrl: true,
        });
      })
    );

    noBuildings$.pipe(
      switchMap((noBuildings) => noBuildings ? EMPTY : insightsDashboard$),
      switchMap((hasInsightsDashboard) => hasInsightsDashboard ? EMPTY : commercial$),
      switchMap((hasCommercialFeature) => hasCommercialFeature ? EMPTY : people$),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
