import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryUnitList, notificationTypeNames } from '../../../models/delivery';

enum Tab {
  Notifications = 'Notifications'
}

@Component({
  selector: 'latch-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {
  @Input() public deliveryUnit!: DeliveryUnitList;
  @Output() public closeModal = new EventEmitter<void>();
  @Output() public openSendNotification = new EventEmitter<void>();

  public Tab = Tab;
  public activeTab!: Tab;
  public notificationTypeNames = notificationTypeNames;

  ngOnInit(): void {
    this.setActiveTab(this.Tab.Notifications);
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab as Tab;
  }

}
