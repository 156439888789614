<latch-card (click)="fileUpload.click()">
  <div class="upload-card-content" latchCardContent>
    <frame-avatar
      size="sm"
      variant="icon"
      shape="square"
      icon="icon-plus"/>
    <div class="description">
      <div class="title">Add Files</div>
      <div class="additional-info">Max size 100MB</div>
    </div>
  </div>
  <input
    type="file"
    class="file-input"
    (change)="addFiles($event)"
    #fileUpload>
</latch-card>

<latch-card *ngFor="let file of fileList; let i = index">
  <div class="file-card-content" latchCardContent>
    <div class="icon-avatar-file"></div>
    <div class="description">
      <div class="title">{{file.name}}</div>
      <div class="additional-info">{{file.size | number:"1.2"}} MB</div>
    </div>
    <button class="door-button-link-error door-button-sm" (click)="remove(i)">Remove</button>
  </div>
</latch-card>
