<latch-loading
  class="container"
  [class.has-active-operations]="hasActiveOperations"
  [class.focus-mode]="isFocusMode$ | async"
  [isActive]="isLoading">

  <div class="console" [class.loading]="isLoading">
    <latch-navbar
      [selectedContext]="selectedBuilding ? selectedBuilding.name : (selectedAccount ? ('No Buildings in ' + selectedAccount.name) : 'No Account Selected')"
      [selectedContextNote]="selectedBuilding ? (showCheckin ? 'Commercial' : 'Residential') : ''"
      [showSelectedContextChangeButton]="true"
      [showFailuresIcon]="showNotificationIcon"
      (failuresIconClick)="openNotificationCenter()"
      [showSettingsMenu]="settingsMenuItems.length > 0"
      settingsMenuLabel="Manage Settings"
      [settingsMenuItems]="settingsMenuItems"
      [profileMenu]="profileMenu"
      [links]="navLinks">
      <latch-mega-menu (megaMenuOpen)="onMegaMenuOpen($event)">
        <latch-building-selector
          *ngIf="selectedAccount"
          [buildings]="buildings"
          [accounts]="accounts"
          [selectedBuilding]="selectedBuilding || emptyBuilding"
          [selectedAccount]="selectedAccount"
          [allowAddProperty]="(hasSelfSetup | async)!"
          (addProperty)="openCreatePropertyFlow()"
          (selectedBuildingChange)="handleSelectedBuildingChange($event)"
          (selectedAccountChange)="handleSelectedAccountChange($event)">
        </latch-building-selector>
      </latch-mega-menu>
    </latch-navbar>

    <div class="main" [class.fill-viewport]="shouldFillViewport()">
      <div class="content" [class.show-insights]="insights.showInsights">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</latch-loading>

<latch-insights #insights></latch-insights>

<div class="notification message-type" [@notificationState]="messageState" *ngIf="notificationMessage">
  <!-- placeholder for justify-content: space-between -->
  <div></div>
  <div>{{notificationMessage}}</div>
  <button class="latch-button-text button-dismiss" (click)="clearNotificationMessage()">
    <fa-icon icon="check"></fa-icon>
    Done
  </button>
</div>

<div class="notification error-type" [@notificationState]="errorState" *ngIf="errorBody">
  <div>{{errorHeader}}</div>
  <div class="error-body">
    <span>{{errorBody}}</span>
    <span *ngIf="defaultSupportMessage"> If this problem persists, contact <a href="https://support.latch.com"
                                                                              target="_blank">support</a>.</span>
  </div>
  <div> <!-- We need three divs regardless of whether we show the button because this part of a three-piece layout -->
    <button class="latch-button-text button-dismiss" (click)="clearError()">
      <fa-icon icon="times"></fa-icon>
    </button>
  </div>
</div>
<div class="toast-container">
  <latch-mfa-enrollment-toast *ngIf="!showDoorExperience"></latch-mfa-enrollment-toast>
  <latch-active-operations-toast (failureMessageClick)="openNotificationCenter()"></latch-active-operations-toast>
</div>
<latch-keys-announcement *ngIf="currentUser"></latch-keys-announcement>

<latch-get-help-button class="latch-only-desktop"></latch-get-help-button>
