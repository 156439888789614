import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccountAdministrationSettings,
  CreateAccountAdministrationSettings,
  UpdateAccountAdministrationSettings
} from '../../models/account-administration';
import { User } from '../../models/user';

/**
 * Error constant used to indicate error where the client requested administration details about a specific user, but no such user
 * exists or the user is not an administrator.
 *
 * This is a frontend-side error (this is not a constant returned by the backend, it's for communication between this service and
 * this service's clients).
 */
export const NO_SUCH_ADMIN_ERROR = 'NO_SUCH_ADMIN';

@Injectable()
export abstract class AdministratorService {
  abstract getAdministrators(accountUUID: string): Observable<AccountAdministrationSettings[]>;

  abstract getMaintenanceAdministrators(accountUUID: string): Observable<AccountAdministrationSettings[]>;

  /**
   * take in the account uuid and user uuid and return the matching building admin / account owner.
   * requires that we pass accountUUID and userUUID so that we can display information
   * for accounts besides currently selected
   *
   * If no such administrator exists, returns an error with message NO_SUCH_ADMIN.
   */
  abstract getMatchingAdministrator(accountUUID: string, userUUID: string): Observable<AccountAdministrationSettings>;

  abstract createAccountOwner(accountUUID: string, userData: User): Observable<AccountAdministrationSettings>;

  abstract createAdministrator(
    adminPermissionsObject: CreateAccountAdministrationSettings
  ): Observable<AccountAdministrationSettings>;

  abstract updateAdministrator(
    adminPermissionsObject: UpdateAccountAdministrationSettings
  ): Observable<AccountAdministrationSettings>;

  abstract deleteAdministrator(adminUUID: string): Observable<any>;
}
