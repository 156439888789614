import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[latchAutoSubmitVerification]'
})
/**
 * this will autosubmit forms that interact with the authy api.
 * this version  of the directive targets a hidden element -
 * it's ESSENTIAL that the next sibling of any input with this directive
 * is a HIDDEN SUBMIT BUTTON for the form you must submit
 */
export class AutoSubmitVerificationDirective {
  @Output() submitVerificationCode = new EventEmitter<any>();
  reg: RegExp;

  constructor() {
    this.reg = new RegExp(/\d{7}/);
  }

  @HostListener('keyup', ['$event']) onKey(input: KeyboardEvent) {
    if (input.key !== 'Enter') {
      const target = input.target as HTMLInputElement;
      const submitButton = target.nextElementSibling as HTMLButtonElement;
      if (this.reg.test(target.value)) {
        submitButton.click();
      }
    } else {
      input.preventDefault();
    }
  }

  /**
   * adding this keydown listener to prevent submit on enter
   * for this directive, entering the correct number of chars should be the only way to trigger a submit
   */
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
}
