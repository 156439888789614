<div class="box" *ngIf="dataIsTimeseries(data)">
  <div class="box-content" *ngIf="getTimeseriesValue(data) as itemValue">
    <div class="title">{{ data.title }}</div>
    <div class="main">
      <div class="data">
        <div class="total">{{ itemValue.value }}</div>
        <latch-insight-comparison [data]="itemValue.pastComparison">
        </latch-insight-comparison>
      </div>
      <latch-chart line [view]="[130, 70]"
        [results]="itemValue | insightLineChartData : data.title">
      </latch-chart>
    </div>
  </div>
  <div class="box-footer">
    <a (click)="onHyperlinkClick()">
      {{ data.reportHyperlinkText }}
    </a>
  </div>
</div>

<div class="box" *ngIf="dataIsInstantValue(data)">
  <div class="box-content">
    <div class="title">{{ data.title }}</div>
    <div class="main">
      <div class="data">
        <div class="total">
          {{ data.value }}
          {{ data.unitAbbreviation }}
        </div>
        <div class="numbers">
          {{ data.secondValue }}
          {{ data.secondUnitAbbreviation }}
        </div>
      </div>
      <latch-chart pie [view]="[110, 50]" [doughnut]="true"
        [results]="data | insightPieChartData">
      </latch-chart>
    </div>
  </div>
  <div class="box-footer">
    <a (click)="onHyperlinkClick()">
      {{ data.reportHyperlinkText }}
    </a>
  </div>
</div>
