import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LatchGlobalLogErrorHandler } from '@latch/latch-web';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notification/notification.service';
import { environment } from 'environments/interface';

export enum ErrorStatus {
  Application = 'Application error',
  System = 'System error',
  Permission = 'Permissions error'
}

export class AppError extends Error {

  public static UserHasNoAccount = 'No accounts returned by accounts endpoint';
  public static UserHasNoBuildings = 'No buildings returned by buildings endpoint';

  public status: ErrorStatus;

  constructor(status: ErrorStatus, message: string) {
    super(message);
    // https://stackoverflow.com/a/41429145/712895
    Object.setPrototypeOf(this, AppError.prototype);
    this.status = status;
  }

}

const BackendErrorStatusMap: Record<string, ErrorStatus> = {
  400: ErrorStatus.Application,
  401: ErrorStatus.Permission,
  403: ErrorStatus.Permission,
  404: ErrorStatus.Application,
  500: ErrorStatus.System
};

const BackendErrorMessages = {
  SessionExpired: 'session expired - please re-login'
};

// There are a few code paths where we anticipate potential backend errors and surface them
// appropriately to the user. In most cases, however, it would either be overkill to anticipate
// certain errors, or quite difficult to identify all errors that could occur. For these
// situations, we present the user with the most useful information possible based on the content
// of the error and some hard-coded copy for known backend messages.
export const BackendErrorMessageMap = {
  'user not allowed to access logs': 'This portfolio may not have been configured with the correct permissions.',
  USER_FORBIDDEN: 'You do not have permission to perform this operation. Only portfolio managers can create, edit or delete keys. ' +
    'Only Latch may make changes to Deliveries keys.',
  LOCK_DOES_NOT_EXIST: 'The door you are looking for could not be found.',
  USER_DOES_NOT_EXIST: 'The user you are looking for could not be found.',
  ACCOUNT_DOES_NOT_EXIST: 'The portfolio you are looking for could not be found.',
  ACCESS_DOES_NOT_EXIST: 'The access you are looking for could not be found.',
  INVALID_PHONE_NUMBER: 'The phone number provided is not valid.',
  ACCESS_ALREADY_EXISTS: 'User already has access to this door at this time.',
  CAN_NOT_REVOKE_NON_PERMANENT_ACCESS: 'Daypasses cannot be revoked.',
  BUILDING_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that property in this portfolio.',
  LOCK_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that door in this property.',
  USER_NOT_IN_ACCOUNT: 'Hmm, we couldn\'t find that user in this property.',
  MFA_INVALID_TOKEN: 'The code you entered is invalid, please try again.',
  MFA_NOT_REQUIRED: 'You cannot verify because Two-Factor Authentication is not enabled on your account',
  MFA_ALREADY_VERIFIED: 'You have already enabled Two-Factor Authentication',
  INVALID_EMAIL_OR_PHONE_NUMBER: 'The email or phone number you provided is invalid.',
  MFA_CREDENTIALS_IN_USE: 'The phone number you provided is already in use.',
  MFA_REQUIRED_FOR_ACCOUNT: 'MFA is required for your portfolio.',
  USER_IS_AN_ACCOUNT_ADMINISTRATOR_ALREADY: 'This user could not be added because they are already a' +
    ' portfolio manager or property manager on this account.',
  UNDELETABLE_LOCK_EXISTS_ON_KEY: 'This door cannot be deleted because it is currently added to one or more keys.',
  INVALID_TOKEN: 'Invalid Token.',
  EXPIRED_TOKEN: 'Your session has expired. Please log in again.',
  INVALID_EMAIL_OR_PASSWORD: 'There was an issue with your username or password. Please try again.',
  USER_ACCOUNT_NOT_ACTIVE: 'User account is not active.',
  USER_ACCOUNT_TEMPORARILY_BLOCKED: 'User account is temporarily blocked. Please try again later.',
  INTERNAL_SERVER_ERROR: 'Internal server error.',
  BAD_REQUEST: 'Bad Request.',
  OBJECT_NOT_FOUND: 'Object not found.',
  LEASE_TRANSLATION_ERROR: 'Lease translation error.',
  PLAID_REQUEST_ERROR: 'Plaid request error.',
  DWOLLA_REQUEST_ERROR: 'Dwolla request error.',
  PLAID_SERVER_ERROR: 'Plaid server error.',
  PLAID_TOKEN_NOT_FOUND: 'Plaid token not found.',
  DWOLLA_SERVER_ERROR: 'Something went wrong. Please try again.',
  LEASE_CONTAINS_INVALID_BUILDING: 'Lease contains invalid building.',
  LEASE_CONTAINS_INVALID_UNIT: 'We are not able to confirm your lease details.',
  INVALID_MODULE: 'An unexpected error occured.',
  LEASE_NOT_FOUND: 'There was an issue loading your information.',
  MODULE_HAS_PENDING_TRANSACTION: 'Your payment method cannot be updated while there is a pending transaction.',
  MODULE_ALREADY_COMPLETED: 'Module already completed.',
  PAYMENT_METHOD_HAS_PENDING_TRANSACTIONS: 'Your payment method cannot be deleted while there is a pending transaction.',
  DWOLLA_DUPLICATE_RESOURCE: 'Dwolla duplicate resource.',
  PAYMENT_METHOD_ALREADY_EXISTS: 'Payment method already added.',
  CUSTOMER_ALREADY_EXISTS: 'We were unable to verify your information.',
  NO_DWOLLA_CUSTOMER_FOR_RESIDENT: 'An unexpected error occured. Please try again.',
  PAYMENT_METHOD_HAS_INSUFFICIENT_BALANCE: 'You have insufficient funds in your account to proceed with this payment.',
  PAYMENT_METHOD_NOT_FOUND: 'We are unable to process your payment.'
};

const ClientErrorMessageMap = {
  [AppError.UserHasNoAccount]: 'You do not have permission to manage any portfolios.',
  [AppError.UserHasNoBuildings]: 'You do not have permission to manage any portfolios or properties.'
};

interface ErrorUserMessage {
  header: string;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {

  public currentError: ErrorUserMessage | undefined;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
  }

  public handleException(error: any): void {
    if (environment.enableLogging) {
      const errorHandler: LatchGlobalLogErrorHandler = new LatchGlobalLogErrorHandler();
      errorHandler.handleError(error);
    }

    if (environment.name === 'mock' || environment.name === 'dev-no-logs' || environment.name === 'local') {
      // eslint-disable-next-line no-restricted-syntax, no-console
      console.info('Unhandled exception', error);
    }

    if (!navigator.onLine) {
      this.currentError = {
        header: 'Network error',
        message: 'Unable to connect to the internet. Please check your connection.'
      };
    } else if (error instanceof HttpErrorResponse) {

      const backendMessage = AuthService.getPayload(error) as keyof typeof BackendErrorMessageMap;

      const status = error.status.toString();
      this.currentError = {
        header: BackendErrorStatusMap[status],
        message: BackendErrorMessageMap[backendMessage]
      };

      if (backendMessage === BackendErrorMessages.SessionExpired) {
        this.notificationService.clearError();
        this.authService.logout().subscribe();
        return;
      }

    } else if (error instanceof AppError) {

      this.currentError = {
        header: error.status,
        message: ClientErrorMessageMap[error.message]
      };

      // It should not be possible to reach either the UserHasNoAccount or UserHasNoBuildings cases - if the
      // user does not have any account or building, the backend should refuse to give them a MANAGER_WEB token.
      // However, when possible we shouldn't assume that the backend has successfully maintained all their
      // invariants, so we do our best to handle here by navigating the user to a special error page.

      if (error.message === AppError.UserHasNoAccount) {
        this.router.navigate(['/403'], {
          replaceUrl: true
        });
        return;
      }

      if (error.message === AppError.UserHasNoBuildings) {
        this.router.navigate(['/403'], {
          replaceUrl: true
        });
        return;
      }

    } else if (error.message in BackendErrorMessageMap) {
      const errorMessage = error.message as keyof typeof BackendErrorMessageMap;
      // Some services (HTTPKeyService and HTTPMfaService) strip their HttpErrorResponse errors out of the HttpErrorResponse object
      // before handing them off to us, so would not be caught by the instanceOf HttpErrorResponse which was meant to handle them above.
      this.currentError = {
        header: error.message === 'USER_FORBIDDEN' ? 'Restricted Permissions' : 'Error',
        message: BackendErrorMessageMap[errorMessage]
      };
    }

    let header; let message;
    if (this.currentError) {
      header = this.currentError.header;
      message = this.currentError.message;
    }
    this.notificationService.showError(
      header || 'Unexpected error',
      message || 'An unexpected error occurred.'
    );
  }

  public clear(): void {
    this.currentError = undefined;
    this.notificationService.clearError();
  }

}
