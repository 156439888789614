import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgressStatus, ProgressStatusItem } from 'manager/services/progress/progress.service';

@Component({
  selector: 'latch-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
  @Input()
  public progressStatus!: ProgressStatus;
  @Input()
  public current: ProgressStatusItem | undefined;
  @Input()
  public footer = 'Upload is in progress. Please do not close this window.';

  @Output()
  public handleClose = new EventEmitter<void>();

  get titles() {
    return Object.keys(this.progressStatus);
  }

  get totalPercentage(): number {
    const total = this.progressStatus.items.length;
    const totalDone = this.current ? this.progressStatus.items.findIndex(item => item === this.current) * 100 : 0;

    return totalDone / total + this.currentPercentage / total;
  }

  get currentPercentage(): number {
    return this.current && this.current.total > 0 ?
      Math.round((this.current.done / this.current.total) * 100) : 0;
  }

  get doneTexts(): string[] {
    return this.progressStatus.items.reduce<string[]>((filtered, item) => {
      if (item.doneText) {
        filtered.push(item.doneText(item.done));
      }
      return filtered;
    }, []);
  }

  getProgressText(item: ProgressStatusItem) {
    const count = item.total > 0 ? `(${item.done}/${item.total})` : '';
    return `${item.progressText} ${count}`;
  }

  isInProgress(key: string): boolean {
    return this.current ? this.current.key === key : false;
  }
}
