import { Component, Inject, OnInit, Optional } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { ImageSource } from '../../../models/access-log';
import { DeliveryList } from '../../../models/delivery';

@Component({
  selector: 'latch-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit {
  public delivery?: DeliveryList;
  public ImageSource = ImageSource;

  constructor(
    @Inject(LATCH_DIALOG_DATA) @Optional() private dialogData: DeliveryList | null,
    @Inject(LatchDialogRef) @Optional() private dialogRef: LatchDialogRef<void> | null,
  ) {
  }

  ngOnInit(): void {
    if (this.dialogData) {
      this.delivery = this.dialogData;
    }
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

}
