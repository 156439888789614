import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryUnitInfo, DeliveryUnitList, getPackagesDisplay, statusNames } from '../models/delivery';

@Pipe({
  name: 'deliveryUnitInfo'
})
export class DeliveryUnitInfoPipe implements PipeTransform {

  transform(deliveryUnit: DeliveryUnitList, info: DeliveryUnitInfo): string | number {
    switch (info) {
      case 'packages': return getPackagesDisplay(deliveryUnit.packages);
      case 'status': return statusNames[deliveryUnit.deliveryRequest.status];
      case 'timeNotified': return deliveryUnit.notifications[0] && deliveryUnit.notifications[0].notifiedOn || '';
    }
  }

}
