<latch-loading [isActive]="isLoading">
  <latch-subnav headerTitle="Duplicate Accounts" [subtitle]="subTitle" [actions]="actions"></latch-subnav>
  <latch-tab-group class="latch-tab-with-viewport" [selected]="activeTab" (tabClicked)="onTabClick($event)">
    <latch-tab [id]="Tab.NameMismatch">Name Mismatch</latch-tab>
    <latch-tab [id]="Tab.EmailMismatch">Email Mismatch</latch-tab>
  </latch-tab-group>

  <ng-container *ngIf="activeTab === Tab.NameMismatch">
    <div class="latch-viewport">
      <div class="description">
        <ng-container *ngIf="duplicates.length > 0; else zeroDuplicatesDescription">
          We found <b>{{ filteredDuplicates.length }}</b> potential duplicate account{{ filteredDuplicates.length === 1 ? '' : 's'}} imported
          from {{ getPmsSourceDisplay() }}. If you would like to override the name in Latch, please click on <b>Merge</b>. If you would like
          to keep the name from the Latch account, please update the resident's entry in {{ getPmsSourceDisplay() }} accordingly.
          <br><br>
          Please note that the integration will run every hour, or you can trigger it by clicking on Sync Now in the <a routerLink="/console/people" queryParamsHandling="merge">People Tab</a>.
        </ng-container>
        <ng-template #zeroDuplicatesDescription>
          There are currently no duplicates found from {{ getPmsSourceDisplay() }}. We will notify you in Mission Control when we detect more.
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activeTab === Tab.EmailMismatch">
    <div class="latch-viewport">
      <div class="description">
        <ng-container *ngIf="duplicates.length > 0; else zeroDuplicatesDescription">
          We found <b>{{ filteredDuplicates.length }}</b> potential duplicate account{{ filteredDuplicates.length === 1 ? '' : 's'}} imported
          from {{ getPmsSourceDisplay() }}. Please note Latch users must have a unique email address. If the Latch email is correct, please
          update the resident's entry in {{ getPmsSourceDisplay() }} to the correct email account. If the {{ getPmsSourceDisplay() }} email
          is correct, click <b>Create Separate</b> and ensure you delete the old Latch account.
          <br><br>
          Please note that the integration will run every hour, or you can trigger it by clicking on Sync Now in the <a routerLink="/console/people" queryParamsHandling="merge">People Tab</a>.
        </ng-container>
        <ng-template #zeroDuplicatesDescription>
          There are currently no duplicates found from {{ getPmsSourceDisplay() }}. We will notify you in Mission Control when we detect more.
        </ng-template>
      </div>
    </div>
  </ng-container>
  <latch-table *ngIf="duplicates.length > 0" class="latch-table-with-viewport" [data]="datasource"
    [selection]="true"
    (selectedItemsChange)="onSelectDuplicates($event)"
    [selectedItems]="selectedDuplicates"
    [class.maxSelection]="selectedDuplicates.length >= maxSelectedItems"
    emptyMessage="No duplicates found">
    <latch-table-column field="name" [columnTitle]="getPmsSourceDisplay()" width="50%">
      <ng-template latchTableCellTemplate let-duplicate>
        <div class="account-card pms">
          <div class="card-item" [class.different]="duplicate.difference.fullName">
            <div class="eyebrow">Name</div>
            {{ duplicate.pmsDuplicate.fullName }}
          </div>
          <div class="card-item">
            <div class="eyebrow right">{{ getLastUpdated(duplicate) }}</div>
          </div>
          <div class="card-item" [class.different]="duplicate.difference.email">
            <div class="eyebrow">Email</div>
            {{ duplicate.pmsDuplicate.email }}
          </div>
          <div class="card-item" [class.different]="duplicate.difference.phoneNumber">
            <div class="eyebrow">Phone</div>
            {{ duplicate.pmsDuplicate.phoneNumber | phoneNumber }}
          </div>
        </div>
      </ng-template>
    </latch-table-column>

    <latch-table-column field="timeSlots" columnTitle="Latch Manager Data">
      <ng-template latchTableCellTemplate let-duplicate>
        <div class="account-card latch" *ngIf="!duplicate.result">
          <div class="card-item" [class.different]="duplicate.difference.fullName">
            <div class="eyebrow">Name</div>
            {{ duplicate.latchDuplicate.fullName }}
          </div>
          <div class="card-item">
            <div class="eyebrow right"></div>
          </div>
          <div class="card-item" [class.different]="duplicate.difference.email">
            <div class="eyebrow">Email</div>
            {{ duplicate.latchDuplicate.email }}
          </div>
          <div class="card-item" [class.different]="duplicate.difference.phoneNumber">
            <div class="eyebrow">Phone</div>
            {{ duplicate.latchDuplicate.phoneNumber | phoneNumber }}
          </div>
        </div>
        <div class="account-card latch" [class]="duplicate.result.success ? 'success' : 'failed'" *ngIf="duplicate.result">
          <div class="result">{{ duplicate.result.message }}</div>
        </div>
      </ng-template>
    </latch-table-column>
  </latch-table>
</latch-loading>
