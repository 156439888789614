export interface GeminiPartner {
  name: string;
  value: string;
}

export enum GeminiPartnerName {
  Latch = 'Latch',
  June = 'June',
  HelloLanding = 'Hello Landing',
  LandingDev = 'Landing Dev',
  Cybersuite = 'Cybersuite',
  Behring = 'Behring',
  BLine = 'B-Line',
  OrionHaus = 'OHAI',
  Jurny = 'Jurny',
  ElevatedLiving = 'Elevated Living',
  AirClutter = 'Air Clutter',
  Tour24 = 'Tour24',
  Pynwheel = 'Pynwheel',
  Livly = 'Livly',
  CommunityBoss = 'Community Boss',
  Primo = 'Primo',
  Entratamation = 'Entratamation',
  AvalonBaySelfGuidedTour = 'Avalon Bay Self Guided Tour',
  Churchill = 'Churchill',
  Vacasa = 'Vacasa',
  Seam = 'Seam',
  Sentral = 'Sentral',
  Propkey = 'Propkey',
  Swift = 'Swift',
  RiseIO = 'RiseIO'
}
