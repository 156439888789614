import { Pipe, PipeTransform } from '@angular/core';
import { ChartDataPoint } from '../chart/chart';

export interface InsightPieChartData {
  title: string;
  value: number;
}

@Pipe({
  name: 'insightPieChartData',
})
export class InsightPieChartDataPipe implements PipeTransform {

  transform(item: InsightPieChartData, args?: any): ChartDataPoint[] {
    return [
      { name: item.title, value: item.value.toString() },
      { name: 'Remaining', value: (100 - item.value).toString() },
    ];
  }

}
