import { environment } from 'environments/interface';

/**
 * Information about a particular time that someone attempted to unlock a lock, whether successful or not.
 *
 * Includes information about when, how the door was unlocked, whether it was successful, and which lock.
 *
 * Might also include additional information about who attempted the unlock and a photo, depending on
 * the unlock method type.
 */
export interface AccessLog {
  // A unique identifier for this access attempt.
  uuid: string;
  // The lock that was accessed.
  lockUUID: string;

  // UUID of the user that attempted the unlock. Only present in certain situations - for exmaple, an invalid
  // passcode entry would not have this information.
  userUUID?: string;
  // Name of user identified by userUUID.
  userFullName?: string;

  // Time of the unlock attempt.
  time: Date;
  // How the user attempted to access the lock.
  method: UnlockMethod;
  // Info about the success or failure of the attempt to unlock.
  result: UnlockResult;

  // Photo captured by the lock at time of unlock, if available.
  imageURL?: string;

  // User UUID of the user that hosted this guest, if this is a guest access log,
  // or null if this is not the access log of a guest.
  hostUUID: string | null;

  // Photos captured by the lock or camera
  photos?: Photo[];
  visualAccessLogMetadata?: VisualAccessLogMetadata;

  partnerUUIDs?: string[];
}

export interface VisualAccessLogMetadata {
  helpfulVote: HelpfulVote;
}

export enum HelpfulVote {
  None = 'NONE',
  Helpful = 'HELPFUL',
  NotHelpful = 'NOT_HELPFUL',
}

export enum ImageSource {
  LOCK = 'LOCK',
  CAMERA = 'CAMERA'
}

export interface Photo {
  url: string,
  imageSource: ImageSource,
  imageFileName?: string,
  imageToken?: string,
}

export function accessLogFromJSON(jsonObject: any): AccessLog {
  const accessLog: AccessLog = {
    uuid: jsonObject.uuid,
    lockUUID: jsonObject.lockUUID,
    time: new Date(jsonObject.epochTimeForEntryAttempt * 1000),
    method: jsonObject.method,
    result: jsonObject.result,
    hostUUID: jsonObject.hostUUID,
    imageURL: undefined,
    photos: []
  };

  if (jsonObject.imageFileName) {
    accessLog.imageURL = getImageUrl(jsonObject.lockUUID, jsonObject.imageFileName, jsonObject.imageToken);
  }
  if (jsonObject.photos) {
    accessLog.photos = jsonObject.photos;
  }
  if (jsonObject.guestUUID) {
    accessLog.userUUID = jsonObject.guestUUID;
  }
  if (jsonObject.fullName) {
    accessLog.userFullName = jsonObject.fullName;
  }

  if (jsonObject.visualAccessLogMetadata) {
    accessLog.visualAccessLogMetadata = jsonObject.visualAccessLogMetadata;
  }

  return accessLog;
}

export enum UnlockMethod {
  Passcode = 'PASSCODE',
  BLE = 'BLE',
  NFC = 'NFC',
  MKO = 'MKO'
}

export enum UnlockResult {
  Success = 'SUCCESS',
  GuestSuccess = 'GUEST_SUCCESS',
  NfcFailure = 'NFC_FAILURE',
  Incorrect = 'INCORRECT',
  DeadboltApplied = 'DEADBOLT_APPLIED',
  OutsideQualifiedAccess = 'OUTSIDE_QUALIFIED_ACCESS'
}

function getImageUrl(lockUUID: string, imageFileName: string, imageToken: string): string {
  return [
    environment.apiBaseUrl,
    '/v2/locks/',
    lockUUID,
    '/accessLogs/images/',
    imageFileName,
    '?image_token=',
    imageToken
  ].join('');
}
