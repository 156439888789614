<latch-checkbox class="door-handle" [label]="selectableLockName"
  labelPosition="after" [checked]="selected" (checkedChange)="handleToggle()"
  [disabled]="!isEditing || isViewingKeyMembership">
</latch-checkbox>

<div class="door-details" *ngIf="!onlyDoors && keyDoor && hasDoorcodeSuppression">
  <div class="divider"></div>
  <latch-checkbox
    class="checkbox"
    label="Provide Doorcode"
    [checked]="keyDoor.doorcodeEnabled === true"
    (checkedChange)="toggleDoorcodeEnabled($event)"
    [disabled]="!isEditing || isViewingKeyMembership">
  </latch-checkbox>
</div>

<div class="door-details" *ngIf="!onlyDoors && keyDoor">
  <div class="divider"></div>
  <button *ngIf="isElevator; else doorCase" class="floor-group" (click)="handleViewDoorGroupForLock()">
    <span class="selected-text none-selected" *ngIf="!defaultFloorGroup && !selectedFloorGroup">
      No default Floor Group selected
    </span>
    <span class="selected-text" *ngIf="defaultFloorGroup">{{defaultFloorGroup.name}} (Default)</span>
    <span class="selected-text" *ngIf="selectedFloorGroup">{{selectedFloorGroup.name}}</span>
    <span class="latch-icon-chevron-right"></span>
  </button>
  <ng-template #doorCase>
    <latch-schedule-picker *ngIf="lock.doesSupportQualifiedAccess"
      class="door-schedule" [(ngModel)]="keyDoor.days" [canEdit]="isEditing" (valueChange)="handleScheduleChange($event)">
    </latch-schedule-picker>
    <div class="door-schedule" *ngIf="!lock.doesSupportQualifiedAccess">
      {{ lock.canSupportQualifiedAccess ? 'Schedule: requires firmware upgrade.' : 'This device does not support Schedules.' }}
    </div>
  </ng-template>
</div>
