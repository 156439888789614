import moment from 'moment';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges }
  from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors, Validator } from '@angular/forms';
import { DayOfWeek, DaysOfWeek, QualifiedDay, TimeInterval } from '../../models/key';
import { LatchSelectionItem } from '@latch/latch-web';
import { SlotDuration } from 'manager/modules/bookings/models/booking';
import { cloneDeep, isEqual, keyBy } from 'manager/services/utility/utility';

const CONTIGUOUS_ERROR = 'You must set contiguous time ranges.';
const INCREASING_ERROR = 'End time must be greater than start time.';

const MAX_INTERVALS_PER_DAY = 48;

const START_TIMES = [...Array(24).keys()].reduce(
  (hourTimes: number[], hours) => hourTimes.concat(
    [0, 30].reduce((minuteTimes: number[], minutes) => minuteTimes.concat((hours * 60 + minutes) * 60), [])
  ), []
);
const END_TIMES = [...Array(24).keys()].reduce(
  (hourTimes: number[], hours) => hourTimes.concat(
    [30, 60].reduce((minuteTimes: number[], minutes) => minuteTimes.concat((hours * 60 + minutes) * 60), [])
  ), []
);

const timeToTimeSelectionItem = (minutes: number) => ({
  name: moment.utc(minutes * 1000).format('hh:mma'),
  value: minutes,
} as LatchSelectionItem);

const START_TIME_ITEMS = START_TIMES.map(timeToTimeSelectionItem);
const END_TIME_ITEMS = END_TIMES.map(timeToTimeSelectionItem);

const getStartTimeItemsFrom = (endTime: number) => START_TIME_ITEMS.filter((item) => item.value as number >= endTime);
const getEndTimeItemsFrom = (startTime: number) => END_TIME_ITEMS.filter((item) => item.value as number > startTime);

interface SelectableDayAndTimes {
  dayLetter: string;
  dayOfWeek: DayOfWeek;
  selected?: boolean;
  intervals: DayTimeInterval[];
}

class DayTimeInterval {
  get formKeys(): { start: string, end: string; } {
    return ({
      start: `${this.props.id}-start`,
      end: `${this.props.id}-end`
    });
  }

  get startTimes(): LatchSelectionItem[] {
    return this.props.startTimes;
  }

  get endTimes(): LatchSelectionItem[] {
    return this.props.endTimes;
  }

  constructor(readonly props: {
    readonly id: string;
    readonly dayOfWeek: DayOfWeek;
    timeInterval: TimeInterval;
    startTimes: LatchSelectionItem[];
    endTimes: LatchSelectionItem[];
  }) { }

  static clone(
    interval: DayTimeInterval,
    props: {
      id?: string;
      dayOfWeek?: DayOfWeek;
      timeInterval?: TimeInterval;
      startTimes?: LatchSelectionItem[];
      endTimes?: LatchSelectionItem[];
    }) {
    return new DayTimeInterval({
      id: props.id ?? interval.props.id,
      dayOfWeek: props.dayOfWeek ?? interval.props.dayOfWeek,
      timeInterval: props.timeInterval ?? interval.props.timeInterval,
      startTimes: props.startTimes ?? interval.props.startTimes,
      endTimes: props.endTimes ?? interval.props.endTimes
    });
  }
}

const capitalize = (item: DayOfWeek): string =>
  item[0].toUpperCase() + item.substring(1).toLowerCase();

function ensure<T>(argument: T | undefined | null, message = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }
  return argument;
}

const uniqueId = (): string => Math.random().toString(36).substring(2, 11);

const DAYS_SELECTION: SelectableDayAndTimes[] = ['M', 'T', 'W', 'R', 'F', 'S', 'S'].map((letter, ind) => ({
  dayLetter: letter,
  dayOfWeek: DaysOfWeek[ind],
  selected: false,
  intervals: [new DayTimeInterval({
    id: uniqueId(),
    dayOfWeek: DaysOfWeek[ind],
    timeInterval: {
      start: START_TIMES[0],
      end: END_TIMES[END_TIMES.length - 1]
    },
    startTimes: START_TIME_ITEMS,
    endTimes: END_TIME_ITEMS
  })]
}));

@Component({
  selector: 'latch-complex-schedule-picker',
  templateUrl: './complex-schedule-picker.component.html',
  styleUrls: ['./complex-schedule-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComplexSchedulePickerComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => ComplexSchedulePickerComponent)
  }],
})
export class ComplexSchedulePickerComponent implements ControlValueAccessor, OnInit, Validator, OnChanges {
  @Input() mode: SlotDuration = SlotDuration.THIRTY;
  @Input() disableMultipleTimeSlotsPerDay = false;
  @Output() valueChange = new EventEmitter<QualifiedDay[]>();

  disabled = false;
  SlotDuration = SlotDuration;

  readonly days: SelectableDayAndTimes[] = cloneDeep(DAYS_SELECTION);
  readonly overnight: {
    startTimes: LatchSelectionItem[];
    endTimes: LatchSelectionItem[];
  } = {
    startTimes: START_TIME_ITEMS.filter(s => s.value as number >= 12 * 3600),
    endTimes: END_TIME_ITEMS.filter(s => s.value as number <= 12 * 3600),
  };

  readonly timeIntervalForm = new FormGroup<any>({});
  readonly overnightForm = new FormGroup({
    start: new FormControl(this.overnight.startTimes[0].value as number),
    end: new FormControl(this.overnight.endTimes[this.overnight.endTimes.length - 1].value as number),
  });

  private lastEmittedValue: QualifiedDay[] | null = null;

  get daysSelected(): SelectableDayAndTimes[] {
    return this.days.filter((d) => d.selected === true);
  }

  get daysSelectedTimeIntervals(): DayTimeInterval[] {
    return this.daysSelected.reduce((prev, curr) => prev.concat(curr.intervals),
      [] as DayTimeInterval[]
    );
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.timeIntervalForm.valueChanges.subscribe(() => this.emitChange());
    this.overnightForm.valueChanges.subscribe(() => this.emitChange());
    this.syncModelFromQualifiedDays([]);
    this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode && this.lastEmittedValue) {
      this.syncModelFromQualifiedDays(this.lastEmittedValue);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.timeIntervalForm.disable();
      this.overnightForm.disable();
    } else {
      this.timeIntervalForm.enable();
      this.overnightForm.enable();
    }
    this.changeDetectorRef.markForCheck();
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};
    if (this.mode === SlotDuration.OVERNIGHT) {
      if ((this.overnightForm.controls.end.value ?? 0) > (this.overnightForm.controls.start.value ?? 0)) {
        errors.increasing = INCREASING_ERROR;
      }
      return errors;
    }

    const validateIncreasing = (): { [key: string]: string; } | null =>
      this.daysSelected.some((d) => d.intervals.some((i) => {
        const curTimes = i.props.timeInterval;
        return (curTimes.end <= curTimes.start);
      })) ? { increasing: INCREASING_ERROR } : null;

    const validateContiguous = (): { [key: string]: string; } | null =>
      this.daysSelected.some((d) => {
        let lastTimes: { start: number, end: number; } | null = null;
        return d.intervals.some((i) => {
          const curTimes = i.props.timeInterval;
          const result = (lastTimes != null && curTimes.start < lastTimes.end);
          lastTimes = curTimes;
          return result;
        });
      }) ? { contiguous: CONTIGUOUS_ERROR } : null;

    return {
      ...validateIncreasing(),
      ...validateContiguous()
    };
  }

  writeValue(value: any): void {
    let qualifiedDays = value as QualifiedDay[];
    if (!qualifiedDays || qualifiedDays.length === 0) {
      qualifiedDays = [];
    }

    this.syncModelFromQualifiedDays(qualifiedDays);
    this.changeDetectorRef.markForCheck();
  }

  getDayTitle(time: DayTimeInterval): string {
    return capitalize(time.props.dayOfWeek).substring(0, 3);
  }

  handleToggleDay(day: SelectableDayAndTimes) {
    day.selected = !day.selected;
    this.emitChange();
  }

  showDay(time: DayTimeInterval): boolean {
    const day = ensure(this.daysSelected.find((d) => d.dayOfWeek === time.props.dayOfWeek));
    const timeIdx = day.intervals.findIndex((i) => i.props.id === time.props.id);
    return timeIdx === 0;
  }

  showAddTime(time: DayTimeInterval): boolean {
    const day = ensure(this.daysSelected.find((d) => d.dayOfWeek === time.props.dayOfWeek));
    const intervalIdx = day.intervals.findIndex((i) => i.props.id === time.props.id);
    const isSmallerThanLastStartTime = time.props.timeInterval.end < START_TIMES[START_TIMES.length - 1];
    return day.intervals.length < MAX_INTERVALS_PER_DAY && intervalIdx === day.intervals.length - 1 && isSmallerThanLastStartTime;
  }

  showRemoveTime(time: DayTimeInterval): boolean {
    const day = ensure(this.daysSelected.find((d) => d.dayOfWeek === time.props.dayOfWeek));
    return day.intervals.length > 1;
  }

  showCopyToAll(time: DayTimeInterval): boolean {
    const dayIdx = this.daysSelected.findIndex((d) => d.dayOfWeek === time.props.dayOfWeek);
    const day = this.daysSelected[dayIdx];
    const timeIdx = day.intervals.findIndex((i) => i.props.id === time.props.id);
    return timeIdx === 0 && dayIdx === 0;
  }

  handleAddTime(time: DayTimeInterval) {
    this.addTimeIntervalFromPrev(time);
    this.emitChange();
  }

  handleRemoveTime(time: DayTimeInterval) {
    this.removeTime(time);
    this.emitChange();
  }

  handleCopyToAll(time: DayTimeInterval) {
    const targetDay = ensure(this.daysSelected.find((d) => d.dayOfWeek === time.props.dayOfWeek));

    this.daysSelected
      .filter((d) => d.dayOfWeek !== targetDay.dayOfWeek)
      .forEach((d) => {
        d.intervals = [];
        targetDay.intervals.forEach((i) => {
          const values = i.props.timeInterval;
          d.intervals.push(new DayTimeInterval({
            id: uniqueId(),
            dayOfWeek: d.dayOfWeek,
            timeInterval: {
              start: values.start,
              end: values.end
            },
            startTimes: i.props.startTimes,
            endTimes: i.props.endTimes
          }));
        });
      });

    this.syncModelToFormControls();

    this.emitChange();
    this.onTouched();
  }

  emitChange() {
    // this is critical to ensure the model is up-to-date as the form controls change.
    this.syncFormControlsToModel();

    const qualifiedDays = this.mode === SlotDuration.OVERNIGHT ?
      this.daysSelected.map((day) => ({
        day: day.dayOfWeek,
        timeIntervals: [{
          start: this.overnightForm.controls.start.value ?? 0,
          end: this.overnightForm.controls.end.value ?? 0,
        }]
      })) :
      this.daysSelected.map((day) => ({
        day: day.dayOfWeek,
        timeIntervals: day.intervals.map((i) => ({
          start: i.props.timeInterval.start,
          end: i.props.timeInterval.end
        }))
      }));

    if (isEqual(this.lastEmittedValue, qualifiedDays)) {
      return;
    }
    this.lastEmittedValue = qualifiedDays;

    this.valueChange.emit(qualifiedDays);
    this.changeDetectorRef.markForCheck();
    this.onTouched();
  }

  registerOnChange(fn: (val: QualifiedDay[]) => void) {
    this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private syncModelFromQualifiedDays(qualifiedDays: QualifiedDay[]) {
    if (this.mode === SlotDuration.OVERNIGHT) {
      this.overnightForm.patchValue({
        // loading first time interval available as for overnight all intervals are the same
        start: qualifiedDays[0].timeIntervals[0].start,
        end: qualifiedDays[0].timeIntervals[0].end,
      });
      this.syncModelToFormControls();
      return;
    }
    const qualifiedDaysByDay = keyBy(qualifiedDays, 'day');
    this.days.forEach(day => {
      const inputDayValue = qualifiedDaysByDay[day.dayOfWeek];
      day.selected = !!inputDayValue;
      day.intervals = inputDayValue?.timeIntervals.map((i, index, intervals) =>
        new DayTimeInterval({
          id: uniqueId(),
          dayOfWeek: day.dayOfWeek,
          timeInterval: {
            start: i.start,
            end: i.end
          },
          startTimes: index > 0 ? getStartTimeItemsFrom(intervals[index - 1].end) : START_TIME_ITEMS,
          endTimes: getEndTimeItemsFrom(i.start)
        })) ?? [
          new DayTimeInterval({
            id: uniqueId(),
            dayOfWeek: day.dayOfWeek,
            timeInterval: {
              start: START_TIMES[0],
              end: END_TIMES[END_TIMES.length - 1]
            },
            startTimes: START_TIME_ITEMS,
            endTimes: END_TIME_ITEMS
          })
        ];
    });
    this.syncModelToFormControls();
  }

  private syncModelToFormControls() {
    // create/update form controls for each time interval
    const intervals = this.days.map((d) => d.intervals)
      .reduce((accumulator, value) => accumulator.concat(value), []);
    this.addOrUpdateTimeControls(intervals);

    // delete any form controls that aren't in the model
    const times =
      this.days.map((d) => d.intervals.map((i) => i.formKeys.start))
        .reduce((p, c) => p.concat(c), [] as string[])
        .concat(
          this.days.map((d) => d.intervals.map((i) => i.formKeys.end))
            .reduce((p, c) => p.concat(c), [] as string[]));
    Object.keys(this.timeIntervalForm.controls)
      .filter((d) => !times.some((t) => t === d))
      .forEach((k) => {
        this.timeIntervalForm.removeControl(k);
      });
  }

  private removeTime(time: DayTimeInterval) {
    const day = ensure(this.daysSelected.find((d) => d.dayOfWeek === time.props.dayOfWeek));
    const timeIdx = day.intervals.findIndex((i) => i.props.id === time.props.id);
    day.intervals.splice(timeIdx, 1);
    this.syncModelToFormControls();
  }

  private addTimeIntervalFromPrev(prev: DayTimeInterval) {
    const day = ensure(this.daysSelected.find((d) => d.dayOfWeek === prev.props.dayOfWeek));
    const timeIdx = day.intervals.findIndex((i) => i.props.id === prev.props.id);
    const values = prev.props.timeInterval;
    const startTimes = getStartTimeItemsFrom(values.end);
    const startTime = startTimes[0].value as number;
    const newTimeInterval = DayTimeInterval.clone(prev, {
      id: uniqueId(),
      timeInterval: {
        start: startTime,
        end: END_TIMES[END_TIMES.length - 1]
      },
      startTimes,
      endTimes: getEndTimeItemsFrom(startTime)
    });
    day.intervals.splice(timeIdx + 1, 0, newTimeInterval);

    this.syncModelToFormControls();
  }

  private addOrUpdateTimeControls(times: DayTimeInterval[]) {
    // we use registerControl rather than addControl
    // because we want to avoid change notifications since
    // we're also using patchValue.
    times.forEach((t) => {
      let control = this.timeIntervalForm.controls[t.formKeys.start];
      if (!control) {
        this.timeIntervalForm.registerControl(t.formKeys.start, new FormControl(t.props.timeInterval.start));
      }
      control = this.timeIntervalForm.controls[t.formKeys.end];
      if (!control) {
        this.timeIntervalForm.registerControl(t.formKeys.end, new FormControl(t.props.timeInterval.end));
      }
    });
    const patchValues = times.reduce((p, c) => {
      p[c.formKeys.start] = c.props.timeInterval.start;
      p[c.formKeys.end] = c.props.timeInterval.end;
      return p;
    }, {} as { [key: string]: any; });
    this.timeIntervalForm.patchValue(patchValues);
  }

  private getTimeInputsFromInterval(time: DayTimeInterval): { start: number, end: number; } {
    const start = this.timeIntervalForm.controls[time.formKeys.start]?.value;
    const end = this.timeIntervalForm.controls[time.formKeys.end]?.value;
    return ({
      start: start,
      end: end
    });
  }

  private syncFormControlsToModel() {
    this.daysSelected.forEach((d) => {
      d.intervals.forEach((i, index, intervals) => {
        const times = this.getTimeInputsFromInterval(i);
        i.props.timeInterval.start = times.start;
        i.props.timeInterval.end = times.end;
        i.props.startTimes = index > 0 ? getStartTimeItemsFrom(intervals[index - 1].props.timeInterval.end) : START_TIME_ITEMS;
        i.props.endTimes = getEndTimeItemsFrom(i.props.timeInterval.start);
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => { };
}
