import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeaturedEntryCandidate } from '../../../models/featured-entry';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { ContactCardService, EditContactCardKey, EditContactCardOperation } from '../../../services/contact-card/contact-card.service';
import { INVALID_PHONE_ERROR } from '../../../services/user/user.service';
import { parsePhoneNumber } from '../../../services/utility/utility';

@Component({
  selector: 'latch-add-featured-entry-phone-number',
  templateUrl: './add-featured-entry-phone-number.component.html',
  styleUrls: ['./add-featured-entry-phone-number.component.scss']
})
export class AddFeaturedEntryPhoneNumberComponent implements OnDestroy {
  @Input() public candidate!: FeaturedEntryCandidate;
  @Output() public cancel = new EventEmitter<void>();
  @Output() private phoneNumberAdded = new EventEmitter<FeaturedEntryCandidate>();

  public isLoading = false;
  public phoneNumber = '';
  public showPhoneError = false;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private contactCardService: ContactCardService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleSubmitPhoneNumber() {
    this.isLoading = true;
    this.contactCardService.editContactCard(this.candidate.contactCardUUID, [{
      operation: EditContactCardOperation.ADD,
      key: EditContactCardKey.PHONE_NUMBER,
      value: this.phoneNumber
    }]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.candidate) {
        this.candidate.phoneNumber = parsePhoneNumber(this.phoneNumber);
        this.phoneNumberAdded.emit(this.candidate);
      }
    }, error => {
      this.isLoading = false;
      if (error.message === INVALID_PHONE_ERROR) {
        this.showPhoneError = true;
      } else {
        this.errorHandlerService.handleException(error);
      }
    });
  }

}
