import { SyncStatusJSON } from './sync-status';

export enum KeyOwnershipType {
  Admin = 'ADMIN',
  User = 'USER',
  Latch = 'LATCH',
  Delivery = 'LATCH_INTEGRATIONS',
}

export enum KeyType {
  Door = 'DOOR',
  Elevator = 'ELEVATOR'
}

export interface Key {
  uuid: string;
  accountUUID: string;
  buildingUUID: string;
  partnerUuid?: string;
  ownershipType: KeyOwnershipType;
  name: string;
  doors: KeyDoor[];

  keyType: KeyType;

  // Backend will provide these, but we don't actually use them
  createTime?: Date;
  startTime?: Date;
  creatorUUID?: string;

  // Optional because MockKeyService shouldn't store this, but it will exist in service responses
  userCount?: number;
}

export function deserializeKey(input: any): Key {
  const result: Key = {
    keyType: KeyType.Door,
    ...input
  };
  return result;
}

export interface KeyListView {
  uuid: string;
  keyType: KeyType;
  ownershipType: KeyOwnershipType;
  name: string;
  userCount: number;
  doorCount: number;
  intercomCount: number;
}

export interface KeyDoor {
  uuid: string;
  lockUUID: string;

  // Optional if the DOORCODE_SUPPRESSION feature flag is off,
  // required if the DOORCODE_SUPPRESSION feature flag is on
  doorcodeEnabled?: boolean;

  // Elevator doors never have schedules
  days?: QualifiedDay[];

  // only present for elevator key doors
  defaultFloorGroup?: string;

  // Backend will provide these, but we don't actually use them
  createTime: Date;
  startTime: Date;
}

export interface QualifiedDay {
  day: DayOfWeek;
  timeIntervals: TimeInterval[];
}

export enum DayOfWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export const DaysOfWeek = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday
];

// Seconds from midnight in the lock's local timezone
export interface TimeInterval {
  start: number;
  end: number;
}

export interface CreateKeyOperationResult {
  keyId: string;
}

export interface UpdateKeyOperationResult {
  keyId: string;
  name: string;
}

export interface DeleteKeyOperationResult {
  keyId: string;
}

export interface CreateKeyDoorOperationResult {
  keyId: string;
  lockId: string;
  keyDoorId: string;
  syncStatuses: SyncStatusJSON[];
}

export interface UpdateKeyDoorOperationResult {
  keyId: string;
  keyDoorId: string;
  syncStatuses: SyncStatusJSON[];
}

export interface DeleteKeyDoorOperationResult {
  keyId: string;
  lockId: string;
  keyDoorId: string;
  syncStatuses: SyncStatusJSON[];
}
