import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DedupingResponse, DuplicateEmail, DuplicateResponse } from 'manager/models/user';

@Injectable()
export abstract class DuplicatesService {
  abstract getDuplicates(buildingUUID: string): Observable<DuplicateResponse>;

  abstract createSeparate(buildingUUID: string, duplicateIds: string[]): Observable<DedupingResponse>;

  abstract mergeAccounts(buildingUUID: string, duplicateIds: string[]): Observable<DedupingResponse>;

  abstract getDuplicateEmails(buildingUUID: string): Observable<DuplicateEmail[]>;

}
