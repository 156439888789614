import { Injectable } from '@angular/core';
import {
  IntercomService,
  CreateUserIntercomSettingsInput,
  NO_INTERCOM_SETTINGS_FOR_CONTACT_CARD_RESIDENT,
  FullIntercomUnitInfo,
  IntercomShortInfo,
  UpdateIntercomUnitVisibilityInput,
  NO_DEVICE_PAIRED,
  GetIntercomManagementCandidatesInput,
  GetIntercomManagementCandidatesResponse,
  AddIntercomManagerInput,
  IntercomManagerResponse,
  ChangeIntercomNetworkSettingsInput,
  ChangeIntercomNetworkSettingsResponse,
  UpdateIntercomConnectionStatusResponse,
  UpdateIntercomManagerInput,
  UpdateBuildingIntercomSettingsRequest
} from './intercom.service';
import { Observable, throwError } from 'rxjs';
import { UserIntercomSettings, IntercomConnectionStatus } from 'manager/models/intercom';
import { AuthService } from '../auth/auth.service';
import { map, catchError } from 'rxjs/operators';
import { ServiceResponse } from '../interfaces';
import { convertListToMap as convertLargeList } from '../utility/http-large-list';
import { HttpParams } from '@angular/common/http';
import { CustomQueryEncoderHelper } from 'shared/services/query-encoder';
import { BuildingIntercomSettings, VirtualIntercom } from '../../models/intercom';

@Injectable()
export class HttpIntercomService extends IntercomService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getUserIntercomSettings(personUUID: string): Observable<UserIntercomSettings> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/people/${personUUID}/intercomSettings`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  createUserIntercomSettings(personUUID: string, input: CreateUserIntercomSettingsInput) {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/people/${personUUID}/intercomSettings`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  getBuildingIntercoms(buildingUUID: string): Observable<IntercomShortInfo[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/intercoms`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getBuildingVirtualIntercoms(buildingUUID: string): Observable<VirtualIntercom[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/virtualIntercoms`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getUnitsForIntercom(intercomId: number): Observable<FullIntercomUnitInfo[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v2/intercoms/${intercomId}/units`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateUnitVisibility(intercomId: number, input: UpdateIntercomUnitVisibilityInput[]): Observable<ServiceResponse> {
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v1/intercoms/${intercomId}/units`,
      data: {
        unitVisibilityData: convertLargeList(input)
      }
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getIntercomConnectionStatus(lockUUID: string): Observable<IntercomConnectionStatus> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/locks/${lockUUID}/intercoms/internet`,
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getUnitIntercomSettings(unitId: number): Observable<UserIntercomSettings[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/units/${unitId}/residents`,
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getIntercomManagementCandidates(
    intercomId: number,
    input: GetIntercomManagementCandidatesInput): Observable<GetIntercomManagementCandidatesResponse> {
    const search = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: input as any
    });

    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/intercoms/${intercomId}/managers/candidates`,
      search
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getIntercomManagement(intercomId: number): Observable<IntercomManagerResponse[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/intercoms/${intercomId}/managers`,
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  addIntercomManagement(intercomId: number, input: AddIntercomManagerInput): Observable<IntercomManagerResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/intercoms/${intercomId}/managers`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  changeIntercomConnectionSettings(intercomId: number,
    input: ChangeIntercomNetworkSettingsInput): Observable<ChangeIntercomNetworkSettingsResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/intercoms/${intercomId}/configuration/internet`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getIntercomManager(intercomId: number, managerId: number): Observable<IntercomManagerResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/intercoms/${intercomId}/managers/${managerId}`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  checkIntercomConnectionStatus(intercomId: number, connectionEventId: string): Observable<UpdateIntercomConnectionStatusResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/intercoms/${intercomId}/configuration/internet/${connectionEventId}`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  deleteIntercomManager(intercomId: number, managerId: number): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/intercoms/${intercomId}/managers/${managerId}`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateIntercomManager(intercomId: number, managerId: number, input: UpdateIntercomManagerInput): Observable<IntercomManagerResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/intercoms/${intercomId}/managers/${managerId}`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getBuildingIntercomSettings(buildingUUID: string): Observable<BuildingIntercomSettings> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/intercoms/settings`,
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateBuildingIntercomSettings(
    buildingUUID: string,
    input: UpdateBuildingIntercomSettingsRequest
  ): Observable<BuildingIntercomSettings> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/buildings/${buildingUUID}/intercoms/settings`,
      data: input
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  rotateVirtualIntercom(buildingUUID: string, virtualIntercomUUID: string): Observable<VirtualIntercom> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/buildings/${buildingUUID}/virtualIntercoms/${virtualIntercomUUID}/rotate`,
      data: {}
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: any) {
    const payload = AuthService.getPayload(error);
    if (payload === NO_INTERCOM_SETTINGS_FOR_CONTACT_CARD_RESIDENT || payload === NO_DEVICE_PAIRED) {
      return throwError(new Error(payload));
    } else {
      return throwError(error);
    }
  }
}
