<latch-card *ngIf="delivery" [headerTitle]="delivery | deliveryInfo : 'title'">
  <button class="latch-button-icon" latchCardActions type="button" (click)="closeDialog()">
    <span class="latch-icon-close"></span>
  </button>
  <ng-container latchCardContent>
    <latch-list>
      <ng-container *ngIf="delivery.accessLog?.image?.url">
        <latch-labeled-list-item class="no-label">
            <div class="delivery-image-container">
                <div class="delivery-image"
                    [class.rotate]="delivery.accessLog?.image?.imageSource === ImageSource.LOCK">
                    <img [src]="delivery.accessLog?.image?.url">
                </div>
            </div>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
    </ng-container>
      <latch-labeled-list-item [class.half]="delivery.accessLog?.method" label="Time">
        <div class="delivery-date">
          {{delivery.lastUpdatedOn | date: 'MM/dd/yyyy h:mm a'}}
        </div>
      </latch-labeled-list-item>
      <latch-labeled-list-item class="half" label="Method" *ngIf="delivery.accessLog?.method">
        {{delivery | deliveryInfo : 'unlockMethod'}}
      </latch-labeled-list-item>
    </latch-list>
  </ng-container>
</latch-card>
