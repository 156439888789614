<ngx-charts-line-chart
  [results]="results"
  [view]="view"
  [scheme]="scheme"
  [legend]="false"
  [xAxis]="false"
  [yAxis]="false"
  [showXAxisLabel]="false"
  [showYAxisLabel]="false"
>
</ngx-charts-line-chart>
