import { SpaceId } from 'manager/modules/spaces/models/spaces';
import moment from 'moment';
import { QualifiedDay } from '../../../models/key';

export enum SlotDuration {
  THIRTY = 'THIRTY',
  SIXTY = 'SIXTY',
  NINETY = 'NINETY',
  ONE_TWENTY = 'ONE_TWENTY',
  OVERNIGHT = 'OVERNIGHT',
  CUSTOM = 'CUSTOM',
}

export enum BookingStatus {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
  CANCELED = 'CANCELED',
}

export enum BookingActivityStatus {
  Current = 'Current',
  Upcoming = 'Upcoming',
  Revoked = 'Revoked',
  Canceled = 'Canceled',
  Past = 'Past',
}

export enum BookingAppointmentType {
  USER_APPOINTMENT = 'USER_APPOINTMENT', // used for normal user appointments
  RESERVATION = 'RESERVATION', // used for blockable appointment slots
}

export const BookingActivityStatusDisplay: { [key in BookingActivityStatus]: string } = {
  [BookingActivityStatus.Current]: 'Current',
  [BookingActivityStatus.Upcoming]: 'Upcoming',
  [BookingActivityStatus.Revoked]: 'Revoked',
  [BookingActivityStatus.Canceled]: 'Canceled by Resident',
  [BookingActivityStatus.Past]: 'Past',
};

export enum BookableResourceStatus {
  ACTIVE = 'ACTIVE',
  PAUSED_ON_NEW_BOOKINGS = 'PAUSED_ON_NEW_BOOKINGS'
}

export interface BookableResource {
  uuid: string;
  building?: Building;
  buildingUUID: string;
  name: string;
  linkedResourceUUID?: string;
  policy: BookingPolicy;
  additionalInformation: string;

  bookableResourceStats: BookableResourceStats;
  bookingAmount?: BookingAmount;
  notificationEnabled?: boolean;
  status: BookableResourceStatus;
  space?: SpaceId;
  termsAndConditions?: TermsAndConditions;
}

export interface BookingAmount {
  paymentType: PaymentType;
  timeSlotAmount: number;
}

export interface BookableResourceStats {
  availableSlots: number;
  bookedSlots: number;
  totalAppointments: number;
  slotAvailableAppointments: number;
  slotAppointments: number;
}

export interface BookingPolicy {
  maxSlotsPerPersonPerDay: number;
  maxAppointmentsPerSlot: number;

  minLeadDays: number;
  maxAdvanceDays: number;

  bookablePeriods: QualifiedDay[];

  slotDuration: SlotDuration;
}

export enum PaymentType {
  None = 'NONE',
  OneTime = 'ONE_TIME',
}

export interface TermsAndConditions {
  id?: string,
  displayName?: string,
  value?: string,
}

export interface BookingAppointment {
  uuid: string;
  bookableResource?: BookableResource;
  user: User;
  status: BookingStatus;
  startTimeEpoch: number;
  endTimeEpoch: number;
  appointmentType: BookingAppointmentType;
  reservationNotes: string;
  multiplicity: number;
}

export interface User {
  uuid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface Building {
  id: string;
  name?: string;
}

export interface AppointmentSlot {
  start: string,
  end: string,
  capacity: number,
  appointments: BookingAppointment[];
}

export function getBookingActivityStatus(booking: BookingAppointment): string {
  switch (booking.status) {
    case BookingStatus.ACTIVE:
      if (moment.unix(booking.startTimeEpoch / 1000).isAfter(moment())) {
        return BookingActivityStatusDisplay[BookingActivityStatus.Upcoming];
      } else if (moment.unix(booking.endTimeEpoch / 1000).isBefore(moment())) {
        return BookingActivityStatusDisplay[BookingActivityStatus.Past];
      } else {
        return BookingActivityStatusDisplay[BookingActivityStatus.Current];
      }
    case BookingStatus.REVOKED:
      return BookingActivityStatusDisplay[BookingActivityStatus.Revoked];
    case BookingStatus.CANCELED:
      return BookingActivityStatusDisplay[BookingActivityStatus.Canceled];
  }
}
