<div class="well">
  <div class="well-header">
    <div class="latch-icon-confirm"></div>
    <div>
      {{valid.length}} {{importName}} imported
    </div>
  </div>
</div>
<div class="well" *ngIf="invalid.length > 0">
  <div class="well-header">
    <div class="icon-warning-circle"></div>
    <div>
      {{errorCount}} {{importName}} skipped
    </div>
  </div>
  <div class="well-body">
    <div class="row" *ngFor="let group of invalidGroups">
      {{group.length}} {{group[0].error}}
    </div>
  </div>
</div>
