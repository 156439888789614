import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  getPayload,
  hasValidPayload,
  LATCH_AUTH_CONFIG,
  LATCH_HTTP_ERRORS,
  LatchAuthConfig,
  LatchAuthHTTPService,
  LatchHttpErrors,
  LogMetadata,
  User
} from '@latch/latch-web';
import { Observable } from 'rxjs';
import { APIService } from '../../../shared/services/api.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

@Injectable()
export class AuthService extends LatchAuthHTTPService {
  public isLoggedIn = false;
  public currentUser: User | null = null;

  static getPayload(response: HttpResponse<any> | HttpErrorResponse): any {
    return getPayload(response);
  }

  static hasValidPayload(response: HttpResponse<any> | HttpErrorResponse): boolean {
    return hasValidPayload(response);
  }

  constructor(
    private api: APIService,
    http: HttpClient,
    router: Router,
    @Inject(LATCH_AUTH_CONFIG) config: LatchAuthConfig,
    @Inject(LATCH_HTTP_ERRORS) httpErrors: LatchHttpErrors,
    auth0Service: Auth0Service
  ) {
    super(http, router, config, httpErrors, auth0Service);
  }

  initialize() {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.user$.subscribe(user => {
      this.currentUser = user;
      LogMetadata.user_id = user?.uuid;
    });
  }

  request(params: RequestParams): Observable<HttpResponse<any>> {
    const { method, endpoint, data, search, headers, responseType } = params;
    return this.api.request(method, endpoint, data, headers, search, responseType);
  }

  /**
   * Asks API to reset user's password and email them a new one.
   * TODO: Remove this function when the LW's forgotPassword will support captcha
   */
  forgotPasswordOld(email: string, captcha: string, clearBrowserHistory: boolean): Observable<HttpResponse<any>> {
    const data = {
      email,
      captcha,
      clearBrowserHistory
    };
    return this.api.request('post', '/web/v2/user-accounts/password/reset', data);
  }
}

export interface RequestParams {
  method: string;
  endpoint: string;
  data?: Record<string, any>;
  headers?: HttpHeaders | { [name: string]: string | string[] };
  search?: HttpParams | { [param: string]: string | string[] };
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

/** Error codes returned from backend when attempting to reset a password ("forgot password"). */
export enum ResetPasswordError {
  /** User has been marked as inactive in our backend (this user should not be logging in). */
  UserAccountNotActive = 'USER_ACCOUNT_NOT_ACTIVE',
  InvalidCaptcha = 'INVALID_CAPTCHA',
  SSOUsersForbidden = 'SSO_USER_FORBIDDEN'
}
