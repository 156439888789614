import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { of, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { MessageModalType } from '../../../models/delivery';

const autoCloseModalTimer = 5000;

@Component({
  selector: 'latch-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit, OnDestroy {
  @Input() public messageModalType!: MessageModalType;
  @Input() public message!: string;
  @Output() public closeModal = new EventEmitter<void>();

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.setAutoCloseModal(autoCloseModalTimer);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setAutoCloseModal(timer: number): void {
    of(null).pipe(
      takeUntil(this.unsubscribe$),
      delay(timer),
    ).subscribe(() => this.closeModal.emit());
  }

}
