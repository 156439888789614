<ng-container *ngIf="scrollContainer">
  <div class="latch-scroll-arrows-left" *ngIf="showLeftScrollArrow"
    (click)="onLeftScrollClick()">
    <fa-icon icon="chevron-left"></fa-icon>
  </div>
  <br>
  <div class="latch-scroll-arrows-right" *ngIf="showRightScrollArrow"
    (click)="onRightScrollClick()">
    <fa-icon icon="chevron-right"></fa-icon>
  </div>
</ng-container>
