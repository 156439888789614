import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AppError, ErrorHandlerService } from '../appstate/error-handler.service';
import { Account } from 'manager/models/account';

@Injectable({ providedIn: 'root' })
export class LoadAccountGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private selectedAccountService: SelectedAccountService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.selectedAccountService.getSelectedAccount().pipe(
            map((selectedAccount: Account) => !!selectedAccount),
            catchError((error: AppError) => {
              this.errorHandlerService.handleException(error);
              return of(false);
            })
          );
        } else {
          return this.authService.logout().pipe(
            map(() => false)
          );
        }
      })
    );
  }
}
