import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'frame-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() variant: 'image' | 'initials' | 'icon' = 'initials';
  @Input() shape: 'circle' | 'square' = 'square';
  @Input() imageUrl?: string;
  @Input() initials?: string;
  @Input() icon?: string;

  @HostBinding('class') get classes() {
    return [
      this.size,
      this.variant,
      this.shape,
      this.variant === 'initials' ? 'frame-label-md-default' : ''
    ].join(' ');
  }

  @HostBinding('style.background-image') get backgroundImage() {
    return this.imageUrl ? `url(${this.imageUrl})` : '';
  }
}
