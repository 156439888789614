<div class="prompt dispute-message" *ngIf="showDisputePrompt && unitHasDispute">
  <div class="content">
    <div class="message">
      <h1 class="title">Open Dispute Found</h1>
      <p class="message-text">
        You have an open dispute on this Nest thermostat. You cannot add new residents to this unit until you resolve the dispute.
      </p>
    </div>
    <div class="actions">
      <a class="latch-button-text" (click)="handleToggleDisputeBanners()">View Details</a>
      <div></div>
    </div>
  </div>
</div>

<latch-loading [isActive]="isLoading">
  <ng-container *ngIf="!isLoading && unit">
    <latch-subnav [pageContext]="pageContext" headerTitle="Unit {{unit.name}}"
      [actions]="actions">
    </latch-subnav>
    <latch-tab-group class="latch-tab-with-viewport" [selected]="activeTab"
      (tabClicked)="handleTabClicked($event)">
      <latch-tab [id]="UnitDetailsTab.Details">Details</latch-tab>
      <latch-tab [id]="UnitDetailsTab.Devices" *ngIf="showSmartHome">Devices</latch-tab>
    </latch-tab-group>

    <ng-container *ngIf="activeTab === UnitDetailsTab.Details">
      <div class="field-list latch-viewport">
        <div class="cell" [class.has-error]="errors.name || (editMode && name.errors?.required)">
          <div class="cell-heading">Unit Name</div>
          <div class="cell-body">
            <ng-container *ngIf="!editMode">{{ unit.name }}</ng-container>
            <ng-container *ngIf="editMode">
              <input
                type="text"
                placeholder=""
                class="latch-form-control"
                [formControl]="name"
                minlength="1"
                maxlength="20"
                latchAutofocus
              />
            </ng-container>
          </div>
          <div *ngIf="errors.name" class="help-block">{{ errors.name }}</div>
          <div *ngIf="editMode && name.errors?.required" class="help-block">Name is required.</div>
        </div>
        <div class="cell" *ngIf="buildingHasIntercom">
          <div class="cell-heading">
            Indoor Intercom Number
          </div>
          <div class="cell-body">
            <ng-container *ngIf="!editMode">{{!!unit.localTelecom ? unit.localTelecom : 'Not Assigned'}}</ng-container>
            <ng-container *ngIf="editMode">
              <input
                type="text"
                placeholder="Indoor Intercom Number"
                class="latch-form-control"
                [(ngModel)]="localTelecom"
              />
            </ng-container>
          </div>
        </div>
        <div class="cell" *ngIf="unit.space">
          <div class="cell-heading">
            Occupancy Status
          </div>
          <div class="cell-body" *ngIf="!unitHasDispute">
            {{unit.space.metadata.occupied ? 'Occupied' : 'Unoccupied'}}
          </div>
          <div class="cell-body danger" *ngIf="unitHasDispute">
            Disputed
          </div>
        </div>
        <div class="cell">
          <div class="cell-heading" *ngIf="!editMode">Delete Unit</div>
          <div class="cell-body">
            <button class="latch-button latch-danger" (click)="onDeleteUnitClick()"
              *ngIf="!editMode && currentUserCanEditUnits">
              Delete
            </button>
            <button class="latch-button latch-danger" *ngIf="!editMode && !currentUserCanEditUnits" latchTooltip
              tooltipText="Only Portfolio Managers and Property Managers with ‘Full Key Management’ permission may delete units.">
              Delete
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="activeTab === UnitDetailsTab.Devices">
      <latch-table class="latch-table-with-viewport" [data]="datasource" [selection]="false" (rowClick)="onRowClick($event)" [emptyMessage]="emptyListMessage">
        <latch-table-column field="name" columnTitle="Name" width="400px">
          <ng-template latchTableCellTemplate let-device>
            <div class="cell-name">
              <span class="name-primary">
                {{device.name}}
              </span>
              <span class="name-secondary">
                <strong [class.danger]="device.connection !== SmartDeviceConnectionType.Connected">
                  {{device.connection === SmartDeviceConnectionType.Connected ? 'Connected' : 'Disconnected'}}
                </strong>
              </span>
            </div>
          </ng-template>
        </latch-table-column>
        <latch-table-column field="spacename" columnTitle="Location" width="400px">
          <ng-template latchTableCellTemplate let-device>
            {{device.spaceName}}
          </ng-template>
        </latch-table-column>

      </latch-table>
    </ng-container>
  </ng-container>
</latch-loading>
