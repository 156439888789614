<latch-loading [isActive]="isLoading">
  <latch-subnav headerTitle="Duplicate Emails" [subtitle]="subTitle" [actions]="actions"></latch-subnav>
  <div class="latch-viewport description">
    Please note that each user must have their unique email address in Latch. Below is a list of users in {{ getPmsSourceDisplay() }} with
    a same email address. <b>Users with duplicate emails are not synced from {{ getPmsSourceDisplay() }} to Latch.</b> Please adjust the email
    address of those users in {{ getPmsSourceDisplay() }}. Once adjusted, users will be imported to Latch in the next sync.
  </div>

  <latch-table class="latch-table-with-viewport" [data]="datasource" emptyMessage="No duplicate emails found">
    <latch-table-column field="name" columnTitle="Email" width="45%">
      <ng-template latchTableCellTemplate let-duplicate>
        <div class="cell-name">
          <div class="name-primary">
            {{ duplicate.email }}
          </div>
        </div>
      </ng-template>
    </latch-table-column>

    <latch-table-column field="fullname" columnTitle="Name">
      <ng-template latchTableCellTemplate let-groupedDuplicate>
        <div class="duplicate-cell" *ngFor="let duplicate of groupedDuplicate.duplicates">
          {{ duplicate.firstName }} {{ duplicate.lastName }}
        </div>
      </ng-template>
    </latch-table-column>

    <latch-table-column field="lease" columnTitle="Lease Status">
      <ng-template latchTableCellTemplate let-groupedDuplicate>
        <div class="duplicate-cell" *ngFor="let duplicate of groupedDuplicate.duplicates">
          {{ duplicate.leaseStatus }}
        </div>
      </ng-template>
    </latch-table-column>
  </latch-table>
</latch-loading>
