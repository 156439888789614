import { Injectable } from '@angular/core';
import {
  Floor,
  DocumentId,
  Tenant,
  Guest,
  Host,
  Invite,
  TenantLease,
  RestrictedPerson,
  PaginatedResponse
} from 'manager/models/checkin';
import { Paging } from 'manager/modules/checkin/components/latch-pager/latch-pager.component';
import { GuestInviteStatus } from 'manager/modules/checkin/utility/visitor-utility';
import { Observable } from 'rxjs';

export const VisitorQRNotFound = 'Could not find Visitor QR';

@Injectable()
export abstract class CheckinService {
  abstract getGuests(
    buildingUUID: string,
    searchTerm: string,
    date: Date,
    includeCheckedin: boolean,
    sortNameAscending: boolean,
    statusFilters?: GuestInviteStatus[],
  ): Observable<Guest[]>;
  abstract getInvites(
    buildingUUID: string,
    searchTerm: string,
    date: Date,
    includeCheckedin: boolean,
    sortNameAscending: boolean,
    statusFilters?: GuestInviteStatus[],
  ): Observable<Invite[]>;
  abstract getVisitor(
    buildingUUID: string,
    uuid: string,
    inviteUUID: string,
    startDateEpoch: number,
    endDateEpoch: number
  ): Observable<Invite>;
  abstract getVisitorQrUrl(buildingUUID: string, invite: Invite, guest: Guest): Observable<string>;
  abstract findVisitorByQr(buildingUUID: string, qr: string): Observable<Guest>;
  abstract inviteVisitor(buildingUUID: string, invite: Partial<Invite>): Observable<Invite>;
  abstract updateInvite(buildingUUID: string, invite: Partial<Invite>): Observable<Invite>;
  abstract deleteInvite(buildingUUID: string, inviteUUID: string): Observable<null>;
  abstract replacePass(buildingUUID: string, invite: Invite, guest: Guest): Observable<null>;
  /**
   * Updates an existing visitor with an Image
   * @param buildingUUID Building from where the Visitor is
   * @param uuid Visitor uuid
   * @param image Image blob that is the image to be saved to the visitor information (blob mimeType should be image/*)
   */
  abstract saveImage(buildingUUID: string, uuid: string, image: Blob): Observable<Guest>;
  /**
   * Updates an existing visitor with an DocumentId
   * Side effect: The moment of check in is determined by the presence or absence of required information. The backend will
   * accept the update of the id and run the checkin logic and adjust anything necessary which is communicated by
   * sending the updated visitor along with the 2xx reply.
   * @param buildingUUID Building from where the Visitor is
   * @param uuid Visitor uuid
   * @param id Id to be saved to the Visitor
   */
  abstract saveDocumentId(buildingUUID: string, uuid: string, documentId: DocumentId): Observable<Guest>;
  /**
   * Sends email to Visitor with QR code/access information
   * @param buildingUUID Building from where the Visitor is
   * @param uuid Visitor uuid
   */
  abstract sendEmail(buildingUUID: string, invite: Invite, guest: Guest): Observable<null>;
  abstract getFloors(buildingUUID: string, tenant: Tenant): Observable<Floor[]>;
  abstract getTenants(buildingUUID: string): Observable<Tenant[]>;
  abstract getTenant(buildingUUID: string, tenantUUID: string): Observable<Tenant>;
  abstract getHosts(
    buildingUUID: string,
    tenant: Tenant,
    searchTerm: string,
    paging?: Paging,
    sortAsc?: boolean
  ): Observable<PaginatedResponse<Host>>;
  abstract addNewHost(buildingUUID: string, tenantUUID: string, host: Host): Observable<Host>;
  abstract updateHost(buildingUUID: string, tenantUUID: string, host: Host): Observable<Host>;
  abstract deleteHost(buildingUUID: string, tenantUUID: string, hostUUID: string): Observable<null>;


  abstract addNewTenant(buildingUUID: string, tenant: Tenant): Observable<Tenant>;
  abstract updateTenant(buildingUUID: string, tenant: Tenant): Observable<Tenant>;
  abstract addNewLease(buildingUUID: string, lease: TenantLease): Observable<TenantLease>;
  abstract updateLease(buildingUUID: string, lease: TenantLease): Observable<TenantLease>;
  abstract getLease(buildingUUID: string, leaseUUID: string): Observable<TenantLease>;

  abstract addNewPersonToWatchlist(buildingUUID: string, person: RestrictedPerson): Observable<RestrictedPerson>;
  abstract updateWatchlistedPerson(buildingUUID: string, person: RestrictedPerson): Observable<RestrictedPerson>;
  abstract deleteWatchlistedPerson(buildingUUID: string, personUUID: string): Observable<boolean>;
  abstract getWatchlistedPersons(
    buildingUUID: string,
    tenantUUID?: string,
    paging?: Paging,
    sortAsc?: boolean
  ): Observable<PaginatedResponse<RestrictedPerson>>;
  abstract getWatchlistedPerson(buildingUUID: string, personUUID: string): Observable<RestrictedPerson>;
}
