import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ContactPreference, ContactPreferenceSelect, FeaturedEntryCandidate, FeaturedEntryStep } from '../../../models/featured-entry';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { FeaturedEntryService } from '../../../services/featured-entry/featured-entry.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'latch-add-featured-entry',
  templateUrl: './add-featured-entry.component.html',
  styleUrls: ['./add-featured-entry.component.scss']
})
export class AddFeaturedEntryComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public buildingUUID!: string;
  public currentStep = FeaturedEntryStep.FeaturedEntryDetails;
  public FeaturedEntryStep = FeaturedEntryStep;
  public contactPreferenceSelect: ContactPreferenceSelect[] = [
    {
      name: 'Latch App',
      value: ContactPreference.APP
    },
    {
      name: 'Phone',
      value: ContactPreference.PHONE
    }
  ];
  public formGroup = new FormGroup({
    displayName: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    selectedCandidate: new FormControl<FeaturedEntryCandidate>(null!, { nonNullable: true, validators: [Validators.required] }),
    contactPreference: new FormControl<ContactPreference>(ContactPreference.APP, { nonNullable: true, validators: [Validators.required] }),
  });

  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private featuredEntryService: FeaturedEntryService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildings => this.buildingUUID = buildings[0].uuid);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setSelectedCandidate(selectedCandidate: FeaturedEntryCandidate): void {
    this.formGroup.controls.selectedCandidate?.setValue(selectedCandidate);
    this.setCurrentStep(FeaturedEntryStep.FeaturedEntryDetails);
  }

  clearSelectedCandidate(): void {
    this.formGroup.controls.selectedCandidate?.reset();
  }

  setCurrentStep(currentStep: FeaturedEntryStep): void {
    this.currentStep = currentStep;
  }

  handleSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.isLoading = true;
    const { displayName, selectedCandidate, contactPreference } = this.formGroup.getRawValue();
    const contactCardUUID = selectedCandidate.contactCardUUID;

    this.featuredEntryService.createBuildingFeaturedEntry(this.buildingUUID, {
      displayName, contactCardUUID, contactPreference
    }).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.router.navigate(['', { outlets: { popup: null } }], { queryParamsHandling: 'preserve' });
    });
  }

}
