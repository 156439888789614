import { Component, Input, Output, EventEmitter } from '@angular/core';
import { pluralize } from '../../../services/utility/utility';
import { SelectedKey } from './selected-keys.component';
import { KeyType, Key } from 'manager/models/key';
import { KeyIconType, getKeyIconType } from 'manager/services/utility/presentation';
import { KeyMembershipRole } from 'manager/services/key-membership/key-membership.service';

@Component({
  selector: 'latch-selected-door-key',
  templateUrl: './selected-door-key.component.html',
  styleUrls: ['./selected-door-key.component.scss']
})
export class SelectedDoorKeyComponent {

  @Input() selectedKey!: SelectedKey;
  @Input() countSelectedKeyIntercoms: number | undefined;

  @Output() remove = new EventEmitter<void>();
  @Output() toggleShareable = new EventEmitter<boolean>();
  @Output() toggleRole = new EventEmitter<KeyMembershipRole>();

  KeyType = KeyType;
  KeyIconType = KeyIconType;
  pluralize = pluralize;

  KeyMembershipRole = KeyMembershipRole;

  handleRemove() {
    this.remove.emit();
  }

  handleToggleGuests() {
    this.toggleShareable.emit(!this.selectedKey.shareable);
  }

  handleToggleRole() {
    this.toggleRole.emit(this.selectedKey.role === KeyMembershipRole.RESIDENT ? KeyMembershipRole.GUEST : KeyMembershipRole.RESIDENT);
  }

  keyIconType(key: Key): KeyIconType {
    return getKeyIconType(key);
  }
}
