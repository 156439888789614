import { Injectable, OnDestroy } from '@angular/core';
import { timer, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, distinctUntilChanged, concatMap, takeUntil } from 'rxjs/operators';
import { Lock } from '../../models/lock';
import { SyncState, SyncTask } from '../../models/sync-status';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';
import { BuildingService } from '../building/building.service';
import { LockService } from '../lock/lock.service';
import { differenceBy, groupBy } from '../utility/utility';

const compareLockSyncTaskValues = (previous: Lock[], current: Lock[]) => {
  const allDifferences = [...differenceBy(previous, current, item => item.uuid), ...differenceBy(current, previous, item => item.uuid)];
  return allDifferences.length === 0; // return true if the values have NOT been changed
};

// the backend will try to sync a lock for 5 mins before marking a sync operation as failed, so we poll infrequently
const POLLING_INTERVAL = 300000;

@Injectable({ providedIn: 'root' })
export class LockSyncErrorsService implements OnDestroy {
  public onlineSyncErrors$ = new BehaviorSubject<Lock[]>([]);

  private unsubscribe$ = new Subject<void>();

  constructor(
    private buildingService: BuildingService,
    private selectedBuildingService: SelectedBuildingsService,
    private lockService: LockService
  ) {
    const getSelectedBuildingUUID$ = this.selectedBuildingService.getSelectedBuildings().pipe(map(buildings => buildings[0].uuid));
    timer(0, POLLING_INTERVAL)
      .pipe(
        switchMap(() => getSelectedBuildingUUID$),
        concatMap((buildingUUID) => this.buildingService.getBuildingTasks(buildingUUID)),
        switchMap((syncTasksForBuilding) => this.getLocksWithSyncErrors(syncTasksForBuilding)),
        distinctUntilChanged(compareLockSyncTaskValues),
        takeUntil(this.unsubscribe$),
      ).subscribe((lockSyncErrors) => {
        this.onlineSyncErrors$.next(lockSyncErrors);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getLocksWithSyncErrors(syncTasks: SyncTask[]): Observable<Lock[]> {
    const syncTasksByLockUUID = groupBy(syncTasks, item => item.lockUUID);
    return this.lockService.getLocksBulk(Object.keys(syncTasksByLockUUID)).pipe(
      map((locks) => {
        const internetConnectedLocks = locks.filter((lock) => !!lock.isCurrentlyInternetConnected);
        const locksWithSyncErrors = internetConnectedLocks.filter(
          (lock) => !!syncTasksByLockUUID[lock.uuid] &&
            syncTasksByLockUUID[lock.uuid].some(syncTask => syncTask.state === SyncState.PendingAdminSync)
        );
        return locksWithSyncErrors.map(lock => Object.assign({}, lock, { syncTasks: syncTasksByLockUUID[lock.uuid] }));
      }));
  }
}
