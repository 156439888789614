import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  LatchConfirmationDialogConfig,
  LatchDatasource,
  LatchDialogService,
  LatchNavAction,
  LatchNavbarStateService,
  LatchSelectionItem
} from '@latch/latch-web';
import { Building, PmsSourceDisplay } from 'manager/models/building';
import { LeaseStatus, LeaseStatusDisplay, PmsAccessReviewData, PmsPendingAccessChange, ConflictedKey } from 'manager/models/pms-access';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { PmsAccessService } from 'manager/services/pms-access/pms-access.service';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, finalize, map, mergeMap, startWith, take, takeUntil, tap } from 'rxjs/operators';

const DEBOUNCE_TIME = 300;

@Component({
  selector: 'latch-pms-access-review-page',
  templateUrl: './pms-access-review-page.component.html',
  styleUrls: ['./pms-access-review-page.component.scss'],
})
export class PmsAccessReviewPageComponent implements OnInit, OnDestroy {
  public selectedPendingAccesses: PmsPendingAccessChange[] = [];
  // limit of maximum selectable PmsPendingAccessChange so that we don't go over the backend limit
  // once the maxSelectedItems is achieved the selection of new rows gets disabled
  public maxSelectedItems = 50;
  public isLoading = false;
  public actions: LatchNavAction[] = [];
  public datasource = new LatchDatasource<PmsPendingAccessChange>({
    data: [],
  });
  public lastUpdated = '';

  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  private accessReviewData?: PmsAccessReviewData;
  private updateAccessAction: LatchNavAction = {
    id: 'latch-automate-access',
    name: 'Update Access',
    disabled: true,
    clickHandler: () => this.showEnableAccessAutomation(),
  };
  private refreshAccessSuggestions: LatchNavAction = {
    id: 'refresh-access-suggestions',
    name: 'Refresh Access Suggestions',
    primary: true,
    customClasses: ['latch-button-outline'],
    clickHandler: () => {
      this.isLoading = true;
      this.pmsAccessService.refreshPendingAccess(this.selectedBuilding.uuid).subscribe({
        next: () => {
          this.isLoading = false;
          this.handleLoad();
        },
        error: error => {
          this.isLoading = false;
          this.errorHandlerService.handleException(error);
        }
      });
    },
  };

  public get pmsDisplay(): string {
    const pmsSource = this.selectedBuilding.pmsSource;
    return pmsSource && PmsSourceDisplay[pmsSource] || '';
  }

  public get conflictNum(): number {
    return this.accessReviewData?.accessChanges.length || 0;
  }

  public get pmsUpdateTimeDisplay(): string {
    return moment(this.selectedBuilding.pmsUpdateTime).format('MM/DD/YY, h:mma');
  }

  public get headerTitle(): string {
    return 'Access Review';
  }

  public get pmsTableRowDisplay(): string {
    return 'Access Suggestions';
  }

  private get pendingReviewAccessIds(): string[] {
    return this.selectedPendingAccesses.map(selectedPendingAccess => [
      ...selectedPendingAccess.keysAdded.map(key => key.pendingReviewAccessId),
      ...selectedPendingAccess.keysRemoved.map(key => key.pendingReviewAccessId),
      ...selectedPendingAccess.keysModified.map(key => key.pendingReviewAccessId),
    ]).reduce((acc, curr) => acc.concat(curr), []);
  }

  constructor(
    private selectedBuildingService: SelectedBuildingsService,
    private pmsAccessService: PmsAccessService,
    private dialog: LatchDialogService,
    private navbarStateService: LatchNavbarStateService,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  public ngOnInit(): void {
    this.navbarStateService.initializeSearch({
      placeholder: 'Search Access Review',
    });
    this.initializeFilters();

    this.actions = [this.updateAccessAction, this.refreshAccessSuggestions];

    this.handleLoad();

    combineLatest([
      this.navbarStateService.getFilterValueChange(),
      this.navbarStateService.searchControl.valueChanges.pipe(
        startWith('')
      ),
    ]).pipe(
      debounceTime(DEBOUNCE_TIME),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      ([filters, searchTerm]) => {
        if (this.accessReviewData && this.accessReviewData.accessChanges) {
          this.applyFilters(this.accessReviewData?.accessChanges, filters, searchTerm);
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  handleLoad() {
    this.datasource.startLoading();
    this.isLoading = true;
    this.selectedBuildingService.getSelectedBuildings().pipe(
      take(1),
      map(buildings => buildings[0]),
      tap(selectedBuilding => {
        this.selectedBuilding = selectedBuilding;
      }),
      mergeMap(selectedBuilding => this.pmsAccessService.getPmsAccessReviewData(selectedBuilding.uuid)),
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: accessReviewData => {
        this.accessReviewData = accessReviewData;
        this.selectedPendingAccesses = [];
        this.lastUpdated = accessReviewData ? moment.unix(accessReviewData.changesRetrievedTime / 1000).format('MM/DD/YY, h:mma') : '';
        this.isLoading = false;
        this.datasource.stopLoading();
        this.initializeFilters();
      },
      error: error => {
        this.isLoading = false;
        this.accessReviewData = undefined;
        this.selectedPendingAccesses = [];
        this.lastUpdated = '';
        this.datasource.set([]);
        this.datasource.stopLoading();
        this.errorHandlerService.handleException(error);
      }
    });
  }

  public enableAccessAutomation() {
    this.datasource.startLoading();
    this.pmsAccessService.enableAccessAutomation(this.selectedBuilding.uuid, this.pendingReviewAccessIds).pipe(
      finalize(() => this.datasource.stopLoading())
    ).subscribe({
      next: () => {
        this.handleLoad();
      },
      error: error => {
        this.isLoading = false;
        this.accessReviewData = undefined;
        this.selectedPendingAccesses = [];
        this.lastUpdated = '';
        this.datasource.set([]);
        this.datasource.stopLoading();
        this.errorHandlerService.handleException(error);
      }
    });
  }

  public handleSelectItems(selectedItems: PmsPendingAccessChange[]) {
    if (selectedItems.length > this.maxSelectedItems) {
      selectedItems = selectedItems.splice(0, this.maxSelectedItems);
    }
    this.selectedPendingAccesses = selectedItems;
    this.updateAccessAction.disabled = this.selectedPendingAccesses.length === 0;
    if (this.selectedPendingAccesses.length > 0) {
      this.actions = [this.updateAccessAction];
    } else {
      this.actions = [this.updateAccessAction, this.refreshAccessSuggestions];
    }
  }

  public getLeaseStatusDisplay(status: LeaseStatus): string {
    return LeaseStatusDisplay[status];
  }

  public discardPendingAccess(key: ConflictedKey) {
    this.dialog.openConfirmation({
      data: {
        title: 'Confirmation',
        messages: [
          `Are you sure you want to remove the ${key.keyName} from this access suggestion?`
        ],
        primaryButtonText: 'Apply Changes',
        primaryButtonClasses: ['latch-danger'],
      },
    }).afterClosed().subscribe(
      confirm => {
        if (confirm) {
          this.isLoading = true;
          this.pmsAccessService.discardPendingAccess(this.selectedBuilding.uuid, [key.pendingReviewAccessId]).pipe(
            takeUntil(this.unsubscribe$)
          ).subscribe({
            next: () => {
              this.handleLoad();
            },
            error: error => {
              this.isLoading = false;
              this.errorHandlerService.handleException(error);
            }
          });
        }
      }
    );
  }

  private initializeFilters() {
    this.navbarStateService.initializeFilter([
      {
        type: 'autocomplete',
        data: this.accessReviewData?.accessChanges.reduce((prev: LatchSelectionItem[], curr: PmsPendingAccessChange) => {
          if (!prev.find(a => a.value === curr.unitName)) {
            prev.push({ name: curr.unitName ?? '', value: curr.unitName });
          }
          return prev;
        }, []) ?? [],
        field: 'selectedUnits',
        label: 'Units',
        initialValue: [],
        placeholder: 'Search Unit',
      },
      {
        type: 'radio-group',
        data: [
          { name: this.getLeaseStatusDisplay(LeaseStatus.Current), value: LeaseStatus.Current },
          { name: this.getLeaseStatusDisplay(LeaseStatus.Pending), value: LeaseStatus.Pending },
          { name: this.getLeaseStatusDisplay(LeaseStatus.Former), value: LeaseStatus.Former },
        ],
        field: 'leaseStatus',
        label: 'Lease Status',
      },
    ]);
  }

  private applyFilters(accessChanges: PmsPendingAccessChange[], filters?: Record<string, any>, searchTerm?: string) {
    accessChanges = accessChanges
      .filter(accessChange => filters?.selectedUnits && (filters.selectedUnits as string[]).length > 0 ?
        (filters.selectedUnits as string[]).some(selectedUnit => selectedUnit === accessChange.unitName) : true
      )
      .filter(accessChange => filters?.leaseStatus ? (filters?.leaseStatus as LeaseStatus) === accessChange.leaseStatus : true)
      .filter(accessChange => searchTerm ?
        `${accessChange.firstName} ${accessChange.lastName}`.toLowerCase().includes(searchTerm.toLowerCase() ?? '') : true
      );
    this.datasource.set(accessChanges);
  }

  private showEnableAccessAutomation() {
    const messages: string[] = [`
      Your ${this.pmsDisplay} data will overwrite the Latch Manager account data.
      Access changes will be applied to <b>${this.selectedPendingAccesses.length}</b> users.
      Please note that only ${this.maxSelectedItems} access updates can be processed at a time.
    `];

    const dialogConfig: LatchConfirmationDialogConfig = {
      data: {
        title: 'Confirmation',
        messages,
        primaryButtonText: 'Update Access',
      }
    };

    this.dialog.openConfirmation(dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.enableAccessAutomation();
      }
    });
  }
}
