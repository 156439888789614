export enum SmartDeviceType {
  Thermostat = 'THERMOSTAT',
  Light = 'LIGHT',
  LeakDetector = 'LEAK_DETECTOR',
  Hub = 'HUB',
  MotionSensor = 'MOTION_SENSOR',
  DoorSensor = 'DOOR_SENSOR',
  TempHumidityMonitor = 'TEMPERATURE_HUMIDITY_SENSOR'
}

export const SmartDeviceTypeForDisplayAndSearch: Record<SmartDeviceType, string> = {
  [SmartDeviceType.Thermostat]: 'Thermostat',
  [SmartDeviceType.Light]: 'Light Switch',
  [SmartDeviceType.LeakDetector]: 'Leak Detector',
  [SmartDeviceType.Hub]: 'Hub',
  [SmartDeviceType.MotionSensor]: 'Motion Sensor',
  [SmartDeviceType.DoorSensor]: 'Door Sensor',
  [SmartDeviceType.TempHumidityMonitor]: 'Temp humidity monitor'
};

export const SMART_DEVICE_TYPES: { name: string, value: SmartDeviceType; }[] = [
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.Thermostat], value: SmartDeviceType.Thermostat },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.Light], value: SmartDeviceType.Light },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.LeakDetector], value: SmartDeviceType.LeakDetector },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.Hub], value: SmartDeviceType.Hub },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.MotionSensor], value: SmartDeviceType.MotionSensor },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.DoorSensor], value: SmartDeviceType.DoorSensor },
  { name: SmartDeviceTypeForDisplayAndSearch[SmartDeviceType.TempHumidityMonitor], value: SmartDeviceType.TempHumidityMonitor }
];

export enum SmartDeviceConnectionType {
  Connected = 'CONNECTED',
  NotConnected = 'NOT_CONNECTED',
  NotConnectedRestricted = 'NOT_CONNECTED_RESTRICTED'
}

export enum SmartDeviceManufacturer {
  Ecobee = 'ECOBEE',
  Google = 'GOOGLE',
  Latch = 'LATCH',
  Honeywell = 'HONEYWELL',
  Yolink = 'YOLINK',
}

export const DimmerLightModel = 'JASCO_DIMMER_SWITCH';

export enum SmartDeviceTrait {
  // Thermostat traits
  TemperatureSetpoint = 'TEMPERATURE_SETPOINT',
  HVACMode = 'HVAC_MODE',
  Temperature = 'TEMPERATURE',
  // Hub traits
  HubConnection = 'HUB_CONNECTION',
  HubWifiInfo = 'HUB_WIFI_INFO',
  HubAccessPointInfo = 'HUB_ACCESS_POINT_INFO',
  HubPower = 'HUB_POWER',
  HubMetadata = 'HUB_METADATA',
  HubSpaces = 'HUB_SPACES',
  // Light traits
  LightOnOffState = 'LIGHT_ON_OFF_STATE',
  LightDimmerLevel = 'LIGHT_DIMMER_LEVEL',
  // Leak traits
  LeakLastDetection = 'LEAK_LAST_DETECTION',
  LeakDetectionState = 'LEAK_DETECTION_STATE',
  // Zigbee shared traits
  HubPair = 'HUB_PAIR',
  // Non-hub shared traits
  DevicePower = 'DEVICE_POWER',
  Humidity = 'HUMIDITY',
  // Motion traits
  MotionDetectionState = 'MOTION_DETECTION_STATE',
  DoorSensorState = 'DOOR_SENSOR_STATE',
  // Metadata
  DeviceMetadata = 'DEVICE_METADATA'
}

export enum SmartThermostatMode {
  Heat = 'HEAT',
  Cool = 'COOL',
  Auto = 'AUTO',
  Off = 'OFF'
}

export enum TempHumidityAlarmState {
  AboveMax = 'ABOVE_MAX',
  BelowMin = 'BELOW_MIN',
  None = 'NONE'
}

// Thermostat traits
export interface TemperatureTraitDetails {
  trait: SmartDeviceTrait.Temperature;
  parameters: {
    ambientCelsius: number;
    alarmState?: TempHumidityAlarmState;
  };
}

export function isTemperatureTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is TemperatureTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.Temperature;
}

export interface HumidityTraitDetails {
  trait: SmartDeviceTrait.Humidity;
  parameters: {
    humidityPercentage: number;
    alarmState?: TempHumidityAlarmState;
  };
}

export function isHumidityTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HumidityTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.Humidity;
}

export interface TemperatureSetpointTraitDetails {
  trait: SmartDeviceTrait.TemperatureSetpoint;
  parameters: {
    heatCelsius: number;
    coolCelsius: number;
    heatRangeCelsius: {
      min: number;
      max: number;
    };
    coolRangeCelsius: {
      min: number;
      max: number;
    };
    coolHeatMinDeltaCelsius: number;
  };
  commands: ['TEMPERATURE_SETPOINT'];
}

export function isTemperatureSetpointTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is TemperatureSetpointTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.TemperatureSetpoint;
}

export interface HVACModeTraitDetails {
  trait: SmartDeviceTrait.HVACMode;
  parameters: {
    mode: SmartThermostatMode;
    allowedModes: SmartThermostatMode[];
  };
  commands: ['HVAC_MODE'];
}

export function isHVACModeTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HVACModeTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HVACMode;
}

// Hub traits
export interface HubConnectionTraitDetails {
  trait: SmartDeviceTrait.HubConnection;
  parameters: {
    activeConnection: string;
  };
  commands: [
    'HUB_SETUP_ACCESS_POINT',
    'HUB_JOIN_WIFI'
  ];
}

export function isHubConnectionTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubConnectionTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubConnection;
}

export interface HubWifiInfoTraitDetails {
  trait: SmartDeviceTrait.HubWifiInfo;
  parameters: {
    ssid: string;
  };
}

export function isHubWifiInfoTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubWifiInfoTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubWifiInfo;
}

export interface HubAccessPointInfoTraitDetails {
  trait: SmartDeviceTrait.HubAccessPointInfo;
  parameters: {
    apSsid: string;
    hidden: boolean;
  };
}

export function isHubAccessPointInfoTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubAccessPointInfoTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubAccessPointInfo;
}

export interface HubMetadataTraitDetails {
  trait: SmartDeviceTrait.HubMetadata;
  parameters: {
    serialNumber: string;
    macAddress: string;
    fwVersion: string;
  };
}

export function isHubMetadataTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubMetadataTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubMetadata;
}

export interface HubSpacesTraitDetails {
  trait: SmartDeviceTrait.HubSpaces;
  parameters: {
    spaces: string[];
  };
}

export function isHubSpacesTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubSpacesTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubSpaces;
}

// Light traits
export interface LightOnOffTraitDetails {
  trait: SmartDeviceTrait.LightOnOffState;
  parameters: {
    isOn: boolean;
  };
  commands: [
    'LIGHT_SET_ONOFFSTATE'
  ];
}

export function isLightOnOffTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is LightOnOffTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.LightOnOffState;
}

export interface LightDimmerLevelTraitDetails {
  trait: SmartDeviceTrait.LightDimmerLevel;
  parameters: {
    level: number;
  };
  commands: [
    'Light_SetDimmerLevel'
  ];
}

export function isLightDimmerLevelTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is LightDimmerLevelTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.LightDimmerLevel;
}

// Leak traits
export interface LeakLastDetectionTraitDetails {
  trait: SmartDeviceTrait.LeakLastDetection;
  parameters: {
    receivedTime?: number; // epoch seconds
  };
}

export function isLeakLastDetectionTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is LeakLastDetectionTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.LeakLastDetection;
}

export interface LeakDetectionStateTraitDetails {
  trait: SmartDeviceTrait.LeakDetectionState;
  parameters: {
    state: string; // 'DRY' & ? Look for enum
    lastUpdated: number;
  };
}

export function isLeakDetectionStateTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is LeakDetectionStateTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.LeakDetectionState;
}

// Zigbee shared traits
export interface HubPairTraitDetails {
  trait: SmartDeviceTrait.HubPair;
  parameters: {
    hubDeviceId: string;
    hubUUID: string;
    isPaired: boolean;
  };
  commands: ['HUB_PAIR'];
}

export function isHubPairTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is HubPairTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubPair;
}

// Power trait
// HubPower is only used for hubs; DevicePower used for all other devices
export interface DevicePowerTraitDetails {
  trait: SmartDeviceTrait.HubPower | SmartDeviceTrait.DevicePower;
  parameters: {
    powerType: string;
    hasBattery: boolean;
    level: number;
    isBatteryLow: boolean;
    lastLowBatteryTime: number; // in epoch seconds
  };
}

export function isDevicePowerTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is DevicePowerTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.DevicePower;
}

export function isHubPowerTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is DevicePowerTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.HubPower;
}

export interface DoorSensorStateTraitDetails {
  trait: SmartDeviceTrait.DoorSensorState;
  parameters: {
    state: 'OPEN' | 'CLOSED';
    lastUpdated: number;
  };
}

export function isDoorSensorStateTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is DoorSensorStateTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.DoorSensorState;
}

export interface MotionDetectionStateTraitDetails {
  trait: SmartDeviceTrait.MotionDetectionState;
  parameters: {
    state: 'DETECTED' | 'NOT_DETECTED';
    lastUpdated: number;
  };
}

export function isMotionDetectionStateTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is MotionDetectionStateTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.MotionDetectionState;
}

export interface DeviceMetadataTraitDetails {
  trait: SmartDeviceTrait.DeviceMetadata;
  parameters: {
    firmwareVersion: string;
  };
}

export function isDeviceMetadataTrait(traitDetails: SmartDeviceTraitDetails): traitDetails is DeviceMetadataTraitDetails {
  return traitDetails.trait === SmartDeviceTrait.DeviceMetadata;
}


export type SmartDeviceTraitDetails =
  TemperatureTraitDetails |
  TemperatureSetpointTraitDetails |
  HVACModeTraitDetails |
  HubConnectionTraitDetails |
  HubWifiInfoTraitDetails |
  HubAccessPointInfoTraitDetails |
  HubMetadataTraitDetails |
  HubSpacesTraitDetails |
  LightOnOffTraitDetails |
  LightDimmerLevelTraitDetails |
  HubPairTraitDetails |
  LeakLastDetectionTraitDetails |
  LeakDetectionStateTraitDetails |
  DevicePowerTraitDetails |
  MotionDetectionStateTraitDetails |
  DeviceMetadataTraitDetails |
  HumidityTraitDetails |
  DoorSensorStateTraitDetails;

export interface SmartDevice {
  uuid: string;
  spaceUUID: string;
  name: string;
  deviceType: SmartDeviceType;
  deviceManufacturer: SmartDeviceManufacturer;
  deviceModel: string;
  connection: SmartDeviceConnectionType;
  manufacturerDeviceId: string;
  traits: SmartDeviceTraitDetails[];
}

export interface DeviceActivityLog {
  uuid: string;
  deviceUUID: string;
  deviceName: string;
  spaceUUID: string;
  spaceName: string;
  deviceType: SmartDeviceType;
  logType: string; // type of event detected, currently only supports LEAK_DETECTION
  timestamp: number; // time of event in epoch seconds
}

export interface NestDeviceDispute {
  userUUID: string;
  unitId: number;
  openDate: number; // time in epoch milliseconds that dispute was created
}
