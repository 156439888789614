<latch-card class="page fixed" *ngIf="!showMakeKey" headerTitle="Add keys"
  [showSearch]="true" (searchChange)="search = $event">
  <button class="latch-button-icon" latchCardActions (click)="cancel.emit()">
    <span class="latch-icon-chevron-left"></span>
  </button>
  <button class="latch-button-link"
    latchCardActions
    (click)="currentUserMayEditKey && selectMakeNewKey()"
    latchTooltip
    [tooltipEnabled]="!currentUserMayEditKey"
    tooltipText="Only Portfolio Managers and Property Managers with
      ‘Full Key Management’ permission may create keys.">
    Make Key
  </button>

  <ng-container latchCardContent>
    <div class="cell-list" (latchScrolledToEnd)="scrolledToEnd()">
      <div class="group" *ngFor="let keyGroup of filteredGroups">
        <div class="group-heading" *ngIf="isElevatorBuilding">
          <div>{{ getKeyTypeGroupText(keyGroup) }} ({{filteredByGroup[keyGroup].length}})</div>
        </div>
        <div
          class="cell cell-row toggle-list latch-checkbox-wrapper"
          *ngFor="let key of filteredByGroup[keyGroup]; trackBy: trackByUUID"
          (click)="isDisabled(key) ? null : handleToggle(key)"
          latchTooltip
          [tooltipEnabled]="isDisabled(key)"
          [tooltipText]="disabledTooltip(key)">
          <div class="cell-label">
            <latch-checkbox [checked]="isSelected(key)">
            </latch-checkbox>
          </div>
          <div class="cell-body">
            <div
              class="cell-thumbnail"
              [ngClass]="{
                'icon-door-key': keyIconType(key) === KeyIconType.Door,
                'icon-delivery-key': keyIconType(key) === KeyIconType.Delivery,
                'icon-elevator-key': keyIconType(key) === KeyIconType.Elevator }"
            ></div>
            <div class="cell-text">
              <div class="text-1">{{key.name}}</div>
              <div class="text-2" *ngIf="!countKeyIntercoms(key)">
                {{thisMany(key.doors, (key.keyType === KeyType.Door) ? 'door' : 'elevator')}}
              </div>
              <div class="text-2" *ngIf="countKeyIntercoms(key)">
                {{thisMany(key.doors, (key.keyType === KeyType.Door) ? 'door' : 'elevator')}}, {{thisMany(countKeyIntercoms(key), 'intercom')}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="filteredGroups.length === 0">
        <div class="cell">
          <div class="cell-body">
            <div class="cell-text">
              There are no results for the search term.
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container latchCardFooter>
    <button class="latch-button" (click)="handleDone()" [disabled]="selected.size === 0">
      Add Keys
      <ng-container *ngIf="selected.size > 0">({{ selected.size }})</ng-container>
    </button>
  </ng-container>
</latch-card>

<latch-make-key
  (back)="showMakeKey = false"
  (done)="handleNewKey($event)"
  *ngIf="showMakeKey">
</latch-make-key>
