import { Component, Inject } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { Key } from 'manager/models/key';

@Component({
  selector: 'latch-make-key-modal',
  templateUrl: './make-key-modal.component.html',
  styleUrls: ['./make-key-modal.component.scss']
})
export class MakeKeyModalComponent {

  constructor(
    @Inject(LATCH_DIALOG_DATA) public data: { isElevatorBuilding: boolean; },
    @Inject(LatchDialogRef) private dialogRef: LatchDialogRef<Key>,
  ) { }

  public onBackClick(): void {
    this.dialogRef.close();
  }

  public onDoneClick(key: Key): void {
    this.dialogRef.close(key);
  }

}
