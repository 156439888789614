<latch-loading [isActive]="isLoading">
  <latch-card class="page">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <a [routerLink]="['/console/access/units']" queryParamsHandling="preserve"
          class="latch-button-link">
          Cancel
        </a>
      </div>

      <div class="latch-card-title">Units</div>
      <p class="page-description">
        Assign Units to residents.
        If you use a Latch intercom, residents must be assigned a Unit for their name to display on the intercom.
      </p>
    </ng-container>

    <ng-container latchCardContent>
        <div cdkDropList class="cell-list"
        (cdkDropListDropped)="handleDrop($event)">
          <div *ngFor="let unit of unitsToDisplay"
            cdkDrag
            [cdkDragData]="unit"
            cdkDragLockAxis="y"
            class="cell cell-row"
            >
            <div class="cell-text">
              <div class="icon-hamburger"></div>
              <div class="text-1">
                {{unit.name}}
              </div>
            </div>
          </div>
        </div>
    </ng-container>

    <ng-container latchCardFooter>
      <button class="latch-button-text" (click)="handleSave()">
        Save
      </button>
    </ng-container>
  </latch-card>
</latch-loading>
