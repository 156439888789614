<fa-icon [icon]="data.differencePercent === 0 ? 'minus' :
  data.differencePercent > 0 ? 'caret-up' : 'caret-down'"
  [transform]="data.differencePercent === 0 ? 'grow-2' : 'grow-10'">
</fa-icon>
<div class="numbers">
  <ng-container *ngIf="data.differencePercent !== 0">
    {{ text }}
  </ng-container>

  <ng-container *ngIf="data.differencePercent === 0">
    No change
  </ng-container>
</div>
