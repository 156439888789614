import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  NgZone,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { EMPTY } from 'rxjs';
import { delay } from 'rxjs/operators';

/** The possible states that an operation (server request, etc) might be in. */
export enum OperationState {
  /** The operation has not started yet. */
  Waiting,
  /** The operation has started but not yet finished. */
  InProgress,
  /** The operation has finished successfully. */
  Complete,
  /** The operation has finished, but failed. */
  Error
}

/**
 * After the ->complete or ->error animations complete, we want to leave the progress there for a split second longer
 * because it's jarring for it to disappear immediately. Length of time to leave it up, in milliseconds.
 */
const persistAfterAnimationForMs = 300;

/**
 * OperationProgressPageComponent shows a full-page progress indicator for an operation that takes time (a server request).
 *
 * We use it for operations that we want to capture the user's attention and to be acknowledged by the user - for example, it should
 * be used for operations where we are saving changes, but should NOT be used when data is loading (we want data loading to be seamless
 * and immediately disappear, but when a user saves changes we want them to feel confident their changes have been saved).
 *
 * It allows the client to specify status text to display.
 *
 * It also forces the user to acknowledge the operation has completed by pressing an acknowledgement button after it is completed.
 *
 * Clients must do at least two things to use this component - they must update the current operationState, and they must respond
 * to the done event (to close, dismiss, etc the component).
 *
 * Even though the component (currently) does not have any sense of actual progress (percentage complete), it will animate smoothly
 * between the various states.
 *
 * Potential future improvements:
 * - multi-step operations, showing which step is currently in progress and which are complete
 * - actual progress indicator, when the client is able to provide this detail
 *
 * Example usage:
 * in the template
 * @example
 * <latch-operation-progress-page
 *   *ngIf="isCreatingUser"
 *   [operationState]="creatingUserState" // This is the important part - tell the progress page what state to display
 *   (done)="isCreatingUser = false"      // We use (done) to dismiss the progress page after the user hits "Okay"
 *   inProgressHeader="Creating user"     // Everything else is just customizing copy
 *   inProgressBody="A new user is being created..."
 *   completeHeader="User created"
 *   [errorBody]="errorMessage"
 * ></latch-operation-progress-page>
 *
 * in the component
 * @example
 * createUser() {
 *   this.isCreatingUser = true;
 *   this.creatingUserState = OperationState.InProgress;
 *   this.userService.create({ some data probably goes here })
 *     .subscribe((newUser) => {
 *       this.creatingUserState = OperationState.Complete;
 *     }, (error) => {
 *       this.creatingUserState = OperationState.Error;
 *       // Could set errorMessage here to give the user a description of what went wrong based on the error code returned
 *     });
 * }
 */
@Component({
  selector: 'latch-operation-progress-page',
  templateUrl: './operation-progress-page.component.html',
  styleUrls: ['./operation-progress-page.component.scss']
})
export class OperationProgressPageComponent implements OnChanges {
  /** Status text to display in the header when operationState is InProgress. */
  @Input() inProgressHeader = 'Saving changes';
  /** Status text to display in the header when operationState is Complete. */
  @Input() completeHeader = 'Changes saved';
  /** Status text to display in the header when operationState is Error. */
  @Input() errorHeader = 'Error';

  /** More detailed text to display when operationState is InProgress. */
  @Input() inProgressBody = 'Your changes are being saved...';
  /** A more detailed error description to display when operationState is Error. */
  @Input() errorBody = '';

  /** Clients should update the current state of the operation by setting this state. */
  @Input() operationState: OperationState = OperationState.Waiting;

  /** Event emitted when user acknowledges that the operation has completed. */
  @Output() done = new EventEmitter<void>();

  @ViewChild('progress') progressRef: ElementRef | undefined;

  /**
   * This is true while an animation is in progress.
   *
   * After an operation completes, we hide the progress bar - but we don't want to hide it until the progress bar animation
   * has finished, so we can't hide the progress bar based on operationState (which would happen instantaneously). Instead, the
   * animate function provides this property that the template can use to find out when an animation has finished.
   *
   * It actually extends slightly beyond the life of the animation to give the user a slightly less jumpy experience.
   */
  animationOngoing = false;

  OperationState = OperationState;

  constructor(
    private ngZone: NgZone
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('operationState' in changes) {
      switch (this.operationState) {
        case OperationState.InProgress: {
          // When the operation is in progress - when a request has been sent to the server, for example - we show the user a
          // partially filled in progress bar so that they know the operation has started.
          this.animateProgress(0.2);
          break;
        }
        case OperationState.Complete: {
          this.animateProgress(1.0);
          break;
        }
        case OperationState.Error: {
          // When an error is encountered, have the progress bar "empty out".
          this.animateProgress(0.0);
          break;
        }
        default: {
          // When the operation hasn't started yet (or when we encounter some unexpected case), show no progress.
          this.animateProgress(0.0, 0);
          break;
        }
      }
    }
  }

  get header(): string {
    switch (this.operationState) {
      case OperationState.InProgress: {
        return this.inProgressHeader;
      }
      case OperationState.Complete: {
        return this.completeHeader;
      }
      case OperationState.Error: {
        return this.errorHeader;
      }
      default: {
        return '';
      }
    }
  }

  get body(): string {
    switch (this.operationState) {
      case OperationState.InProgress: {
        return this.inProgressBody;
      }
      case OperationState.Error: {
        return this.errorBody;
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Animate the width property of the progress bar.
   *
   * This method handles creating a smooth animation, and manages the animationOngoing state.
   *
   * @param progress Progress that progress bar should animate to, as a number between 0 and 1.
   * @param duration Length of time, in milliseconds, the animation should take to reach progress.
   */
  public animateProgress(progress: number, duration = 300): void {
    const progressRef = this.progressRef;
    if (!progressRef) {
      return;
    }

    this.animationOngoing = true;

    const widthString = `${Math.floor(progress * 100)}%`;
    this.ngZone.runOutsideAngular(() => progressRef.nativeElement.animate([{
      width: window.getComputedStyle(progressRef.nativeElement).width
    }, {
      width: widthString
    }], {
      fill: 'forwards',
      direction: 'normal',
      easing: 'ease-in-out',
      duration
    }));

    if (duration === 0) {
      // If the client requested an "animation" with no duration, we assume they intended an instantaneous change, so we
      // also ignore the persistAfterAnimationForMs delay.
      this.animationOngoing = false;
    } else {
      EMPTY.pipe(
        delay(duration), // Wait for the animation itself to complete
        delay(persistAfterAnimationForMs), // Wait for a split second after the animation completes
      ).subscribe({
        complete: () => this.animationOngoing = false
      });
    }
  }

}
