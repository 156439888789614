import moment from 'moment';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DATE_DISPLAY_FORMAT,
  DATE_PARSE_FORMATS,
  TIME_DISPLAY_FORMAT,
  TIME_PARSE_FORMATS,
  Meridiem,
  DateTime,
  getParsedTime
} from '../../services/utility/date-time';

@Component({
  selector: 'latch-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimeInputComponent),
    multi: true
  }]
})
export class DateTimeInputComponent implements ControlValueAccessor {
  /** Will disable all inputs that are visible */
  @Input() isReadOnly = false;
  /** The time input elements (spacer, time and meridiem) are not rendered only allowing to change the date input  */
  @Input() onlyDate = false;
  /** The date input element becomes disabled to disallow manual input of dates, still allows to change time and meridiem */
  @Input() disableDate = false;
  @Input() disableTime = false;

  @Input() datePickerMax: Date | undefined = undefined;
  @Input() datePickerMin: Date | undefined = undefined;

  @Output() blurEvent = new EventEmitter<void>();

  _date: string | undefined;
  _dateForPicker: Date | undefined;
  _time: string | undefined;
  _meridiem: Meridiem | undefined;

  public isCalendarPickerOpen = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public propagateChange = (data: any): void => { };

  get date(): string | undefined {
    return this._date;
  }

  set date(date: string | undefined) {
    this._date = date;
    this._dateForPicker = getParsedTime({
      date: this._date ? this._date : moment().format(DATE_DISPLAY_FORMAT),
      time: this._time,
      meridiem: this._meridiem,
    }).toDate();
    this.emitChange();
  }

  get time(): string | undefined {
    return this._time;
  }

  set time(time: string | undefined) {
    this._time = time;
    this.emitChange();
  }

  get meridiem(): Meridiem | undefined {
    return this._meridiem;
  }

  set meridiem(meridiem: Meridiem | undefined) {
    this._meridiem = meridiem;
    this.emitChange();
  }

  handleBlurDate() {
    const date = moment(this.date, DATE_PARSE_FORMATS, true);
    this.date = date.isValid() ? date.format(DATE_DISPLAY_FORMAT) : this.date;
    this.isCalendarPickerOpen = false;
    this.blurEvent.emit();
  }

  public handleBlurTime(): void {
    const time = moment(`${this.time ?? ''} ${this.meridiem ?? ''}`, TIME_PARSE_FORMATS, true);
    this.time = time.isValid() ? time.format(TIME_DISPLAY_FORMAT) : this.time;
    this.blurEvent.emit();
  }

  emitChange() {
    this.propagateChange({
      date: this.date,
      time: this.time,
      meridiem: this.meridiem
    });
  }

  /** Allows clients to start with placeholder values as initial state. Will default the calendar picker to the current date. */
  openCalendarPicker(): void {
    if (!this.isValidDate(this._dateForPicker)) {
      this._dateForPicker = new Date();
    }
    this.isCalendarPickerOpen = true;
  }

  isValidDate(date: Date | undefined): boolean {
    return date !== undefined && !isNaN(date.getTime());
  }

  writeValue(dateTime: DateTime) {
    dateTime = dateTime || {};
    this._date = dateTime.date;
    this._time = dateTime.time;
    this._meridiem = dateTime.meridiem;
    this._dateForPicker = getParsedTime({
      date: this._date ? this._date : moment().format(DATE_DISPLAY_FORMAT),
      time: this._time,
      meridiem: this._meridiem,
    }).toDate();
  }

  registerOnChange(fn: (data: any) => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(): void { }

  public setDateFromPicker(date: Date) {
    const tempDate = moment(date);
    this.date = tempDate.isValid() ? tempDate.format(DATE_DISPLAY_FORMAT) : this.date;
  }
}
