import { Injectable } from '@angular/core';
import {
  Location,
  Media,
  ServiceOrder,
  ServiceOrderStatus,
  TimeSlot
} from 'manager/models/service-order';
import { Observable } from 'rxjs';

export interface GetServiceOrdersInput {
  buildingUUID: string;
  statuses?: ServiceOrderStatus[];
  isUrgent?: boolean;
  isAcknowledged?: boolean;
  spaceUUIDs?: string[];
  assignedToUserUUIDs?: string[];
  startDate?: Date;
  endDate?: Date;
  search?: string;
  pageToken?: string;
  pageSize?: number;
  orderBy?: string;
}

export interface GetServiceOrdersResponseBase {
  nextPageToken: string;
}

export type GetServiceOrdersResponse = GetServiceOrdersResponseBase & {
  serviceOrders: ServiceOrder[];
};

export interface ServiceOrderInput {
  buildingUUID: string;
  serviceOrderUUID: string;
}

export interface AssignServiceOrderInputAssignment {
  assignedToUserUuid: string;
  date: Date;
  timeSlotName: string;
}

export type AssignServiceOrderInput = ServiceOrderInput & {
  assignment: AssignServiceOrderInputAssignment;
};

export type UpdateServiceOrderInput = ServiceOrderInput & {
  serviceOrder: Pick<ServiceOrder, 'isUrgent' | 'propertyManagerNotes' | 'locationName' | 'applianceName'>;
};

export type ActionServiceOrderInput = ServiceOrderInput & {
  note?: string;
};

export type CreateServiceOrderMediaInput = ServiceOrderInput & {
  file: File;
};

@Injectable()
export abstract class ServiceOrderService {
  public abstract pollUnacknowledgedServiceOrders(buildingUUID: string): Observable<GetServiceOrdersResponse>;
  public abstract getServiceOrders(input: GetServiceOrdersInput): Observable<GetServiceOrdersResponse>;
  public abstract getServiceOrder(input: ServiceOrderInput): Observable<ServiceOrder>;
  public abstract acknowledgeServiceOrder(input: ServiceOrderInput): Observable<ServiceOrder>;
  public abstract assignServiceOrder(input: AssignServiceOrderInput): Observable<ServiceOrder>;
  public abstract updateServiceOrder(input: UpdateServiceOrderInput): Observable<ServiceOrder>;
  public abstract cancelServiceOrder(input: ActionServiceOrderInput): Observable<ServiceOrder>;
  public abstract completeServiceOrder(input: ActionServiceOrderInput): Observable<ServiceOrder>;
  public abstract getServiceOrderMedia(input: ServiceOrderInput): Observable<Media[]>;
  public abstract createServiceOrderMedia(input: CreateServiceOrderMediaInput): Observable<Media>;
  public abstract getLocations(): Observable<Location[]>;
  public abstract getTimeSlots(): Observable<TimeSlot[]>;
}
