import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManagerSharedModule } from 'manager/components/manager-shared.module';
import { FloorGroupFloorListComponent } from './floor-group-floor-list/floor-group-floor-list.component';
import { FloorGroupListEditModalComponent } from './floor-group-list/floor-group-list-edit-modal/floor-group-list-edit-modal.component';
import { FloorGroupListEditComponent } from './floor-group-list/floor-group-list-edit/floor-group-list-edit.component';
import { FloorGroupListModalComponent } from './floor-group-list/floor-group-list-modal/floor-group-list-modal.component';
import { FloorGroupListComponent } from './floor-group-list/floor-group-list.component';
import { KeyDoorsListComponent } from './key-doors-list/key-doors-list.component';
import { SelectableLockItemComponent } from './key-doors-list/selectable-lock-item/selectable-lock-item.component';
import { MakeKeyModalComponent } from './make-key/make-key-modal/make-key-modal.component';
import { MakeKeyComponent } from './make-key/make-key.component';
import { SchedulePickerComponent } from './schedule-picker/schedule-picker.component';
import { SelectKeysComponent } from './select-keys/select-keys.component';
import { SelectedDoorKeyComponent } from './select-keys/selected-door-key.component';
import { SelectedElevatorKeyComponent } from './select-keys/selected-elevator-key.component';
import { SelectedKeysComponent } from './select-keys/selected-keys.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ManagerSharedModule,
  ],
  declarations: [
    KeyDoorsListComponent,
    SelectableLockItemComponent,
    SchedulePickerComponent,
    SelectedKeysComponent,
    SelectedDoorKeyComponent,
    SelectedElevatorKeyComponent,
    SelectKeysComponent,
    MakeKeyComponent,
    MakeKeyModalComponent,
    FloorGroupFloorListComponent,
    FloorGroupListComponent,
    FloorGroupListModalComponent,
    FloorGroupListEditComponent,
    FloorGroupListEditModalComponent,
  ],
  exports: [
    KeyDoorsListComponent,
    SelectableLockItemComponent,
    SchedulePickerComponent,
    SelectedKeysComponent,
    SelectedDoorKeyComponent,
    SelectedElevatorKeyComponent,
    SelectKeysComponent,
    MakeKeyComponent,
    MakeKeyModalComponent,
    FloorGroupFloorListComponent,
    FloorGroupListComponent,
    FloorGroupListModalComponent,
    FloorGroupListEditComponent,
    FloorGroupListEditModalComponent,
  ],
})
export class KeysSharedModule { }
