import { Injectable } from '@angular/core';
import { LatchNavAction } from '@latch/latch-web';
import { BehaviorSubject } from 'rxjs';

/*
 * Manages page state accross multiple tabs.
 */
@Injectable()
export class PageWithTabsService {
  private readonly initialValues = {
    subnavSubtitle: '',
    subnavActions: [],
    showPageContent: true
  };
  private subnavSubtitleChange = new BehaviorSubject<string>(this.initialValues.subnavSubtitle);
  private subnavActionsChange = new BehaviorSubject<LatchNavAction[]>(this.initialValues.subnavActions);
  private showPageContentChange = new BehaviorSubject<boolean>(this.initialValues.showPageContent);

  public subnavSubtitleChange$ = this.subnavSubtitleChange.asObservable();
  public subnavActionsChange$ = this.subnavActionsChange.asObservable();
  public showPageContentChange$ = this.showPageContentChange.asObservable();

  public setSubnavSubtitle(subtitle?: string) {
    this.subnavSubtitleChange.next(subtitle ?? '');
  }

  public setSubnavActions(actions?: LatchNavAction[]) {
    this.subnavActionsChange.next(actions ?? []);
  }

  public showPageContent(show: boolean) {
    this.showPageContentChange.next(show);
  }

  public reset() {
    this.setSubnavSubtitle(this.initialValues.subnavSubtitle);
    this.setSubnavActions(this.initialValues.subnavActions);
    this.showPageContent(this.initialValues.showPageContent);
  }
}
