import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'latch-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    this.hideBodyOverflow();
  }

  ngOnDestroy(): void {
    this.clearBodyOverflow();
  }

  private hideBodyOverflow(): void {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = 'hidden';
    }
  }

  private clearBodyOverflow(): void {
    const body = document.querySelector('body');
    if (body && body.style.overflow && body.style.overflow === 'hidden') {
      body.style.removeProperty('overflow');
    }
  }
}
