import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SyncTask } from '../../models/sync-status';

import { AuthService } from '../auth/auth.service';
import { SyncStatusService } from './sync-status.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class HTTPSyncStatusService extends SyncStatusService {

  constructor(private authService: AuthService) {
    super();
  }

  getSyncTasks(syncTaskUUIDs: string[]): Observable<SyncTask[]> {
    let search = new HttpParams();
    syncTaskUUIDs.forEach((uuid) => search = search.append('taskUUID', uuid));
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/tasks',
      search
    }).pipe(map((response: HttpResponse<any>): SyncTask[] => AuthService.getPayload(response)));
  }

}
