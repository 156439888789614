import { Component, HostBinding } from '@angular/core';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { PageWithTabsBaseComponent } from 'manager/components/page-with-tabs-base/page-with-tabs-base.component';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

enum Tab {
  PortfolioManagers = 'PortfolioManagers',
  PropertyManagers = 'PropertyManagers',
  MaintenanceWorkers = 'MaintenanceWorkers'
}

@Component({
  selector: 'latch-staff-page',
  templateUrl: './staff-page.component.html',
  styleUrls: ['./staff-page.component.scss'],
  providers: [PageWithTabsService]
})
export class StaffPageComponent extends PageWithTabsBaseComponent<Tab> {
  baseUrl = '/console/staff';
  defaultTab = Tab.PropertyManagers;
  tabData = [
    { id: Tab.PortfolioManagers, path: 'owners' },
    { id: Tab.PropertyManagers, path: 'admins' },
    { id: Tab.MaintenanceWorkers, path: 'maintenance-admins' }
  ];

  Tab = Tab;
  hasServiceOrdersFeature = false;

  @HostBinding('style.overflow')
  get styleOverflow(): string {
    return this.tabPathMatchFull && this.showPageContent ? 'hidden' : 'initial';
  }

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected pageWithTabsService: PageWithTabsService,
    private featureService: FeatureService
  ) {
    super(route, router, pageWithTabsService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.featureService.hasServiceOrdersFeature$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((hasServiceOrdersFeature) => this.hasServiceOrdersFeature = hasServiceOrdersFeature);
  }
}
