import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { ActiveOperationsStatus } from '../../models/operations';
import { ActiveOperationsService } from '../../services/active-operations/active-operations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LatchToastType } from '@latch/latch-web';

export const enum ActiveOperationsIconClasses {
  IN_PROGRESS = 'spinner-in-progress',
  COMPLETE = 'latch-icon-confirm',
  SOME_FAILED = 'icon-error-circle-black',
  FAILED = 'icon-error-circle-black'
}

export const enum ActiveOperationsToastText {
  IN_PROGRESS = 'In progress...',
  COMPLETE = 'Changes saved.',
  FAILED = 'Changes failed.',
  SOME_FAILED = 'Some changes failed to save.'
}

@Component({
  selector: 'latch-active-operations-toast',
  templateUrl: './active-operations-toast.component.html',
  styleUrls: ['./active-operations-toast.component.scss'],
})
export class ActiveOperationsToastComponent implements OnInit, OnDestroy {
  @Output() failureMessageClick = new EventEmitter();

  status: ActiveOperationsStatus = ActiveOperationsStatus.NO_ACTIVE_OPERATIONS;

  get hasActiveOperations(): boolean {
    return this.status !== ActiveOperationsStatus.NO_ACTIVE_OPERATIONS;
  }

  get operationsInProgress(): boolean {
    return this.status === ActiveOperationsStatus.IN_PROGRESS;
  }

  get activeStatusMessage(): string {
    switch (this.status) {
      case ActiveOperationsStatus.IN_PROGRESS:
        return ActiveOperationsToastText.IN_PROGRESS;
      case ActiveOperationsStatus.COMPLETE:
        return ActiveOperationsToastText.COMPLETE;
      case ActiveOperationsStatus.SOME_FAILED:
        return ActiveOperationsToastText.SOME_FAILED;
      case ActiveOperationsStatus.FAILED:
        return ActiveOperationsToastText.FAILED;
      default:
        return '';
    }
  }

  get activeStatusSubText(): string {
    return (this.status === ActiveOperationsStatus.IN_PROGRESS) ? 'Changes are being made. You may leave the page.' : '';
  }

  get hasFailedOperations() {
    return this.status === ActiveOperationsStatus.FAILED || this.status === ActiveOperationsStatus.SOME_FAILED;
  }

  public get toastType(): LatchToastType {
    switch (this.status) {
      default:
      case ActiveOperationsStatus.IN_PROGRESS:
        return LatchToastType.IN_PROGRESS;
      case ActiveOperationsStatus.COMPLETE:
        return LatchToastType.SUCCESS;
      case ActiveOperationsStatus.SOME_FAILED:
        return LatchToastType.ERROR;
      case ActiveOperationsStatus.FAILED:
        return LatchToastType.ERROR;
    }
  }

  private unsubscribe$ = new Subject<void>();

  constructor(private activeOperationsService: ActiveOperationsService) { }

  ngOnInit() {
    this.activeOperationsService.getOperationStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((latestStatus) => {
        this.status = latestStatus;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onDismiss() {
    this.activeOperationsService.dismissLingeringOperations();
  }

  onMessageClick() {
    if (!this.hasFailedOperations) {
      return;
    }

    this.failureMessageClick.emit();
  }
}
