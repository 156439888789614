import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  assertIsDefined, FilterItem, LatchAnalyticsConstants, LatchAnalyticsService,
  LatchDatasource, LatchNavAction, LatchNavbarStateService
} from '@latch/latch-web';
import { DeviceActivityLog, SmartDeviceType, SMART_DEVICE_TYPES } from 'manager/models/device';
import { SpaceType } from 'manager/models/space';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { NextPage } from 'manager/services/interfaces';
import { SmartHomeService } from 'manager/services/smart-home/smart-home.service';
import { SpaceService, SpaceShortInfo } from 'manager/services/space/space.service';
import { arrayOf, keyBy, omitEmptyNullOrUndefined } from 'manager/services/utility/utility';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NO_FILTER_RESULTS } from 'shared/common/constants';
import { getDefaultStartTime } from '../utility/activity-utility';

interface NavbarFilter {
  selectedSpaces?: SpaceShortInfo[];
  selectedDeviceTypes?: SmartDeviceType[];
  startTime?: Date;
}

const LOGS_START_DEFAULT = 0;
const LOGS_LIMIT_DEFAULT = 20;
const INITIAL_LOGS_NEXT_PAGE: NextPage = {
  start: LOGS_START_DEFAULT,
  limit: LOGS_LIMIT_DEFAULT,
};

@Component({
  selector: 'latch-device-activity-list-page',
  templateUrl: './device-activity-list-page.component.html',
  styleUrls: ['./device-activity-list-page.component.scss']
})
export class DeviceActivityListPageComponent implements OnInit, OnDestroy {
  private allSpaces: SpaceShortInfo[] = [];
  private selectedSpaces: SpaceShortInfo[] = [];
  private selectedDeviceTypes: SmartDeviceType[] = [];
  private startTime: Date | undefined;
  private sortAscending = false;

  public logsNextPage: NextPage | undefined = INITIAL_LOGS_NEXT_PAGE;
  // Keep a reference to any loadNext subscription so we can interrupt it when needed.
  private loadNextSubscription: Subscription | undefined;

  private unsubscribe$ = new Subject<void>();

  isLoadingFilter = true;
  isLoadingLogs = false;

  deviceLogs: DeviceActivityLog[] = [];

  public get emptyListMessage(): string {
    const filterParams = this.navbarFilterValue;
    const emptyFilters: boolean = filterParams.selectedDeviceTypes?.length === 0
      && filterParams.selectedSpaces?.length === 0
      && !filterParams.startTime;

    return emptyFilters ? 'There are no logs to view.' : NO_FILTER_RESULTS;
  }

  public datasource = new LatchDatasource<DeviceActivityLog>({});

  private navbarFilterValue: NavbarFilter = {
    selectedSpaces: [],
    selectedDeviceTypes: [],
  };

  private buildingUUID!: string;

  constructor(
    private spaceService: SpaceService,
    private analyticsService: LatchAnalyticsService,
    private selectedBuildingsService: SelectedBuildingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private smartHomeService: SmartHomeService,
    private errorHandlerService: ErrorHandlerService,
    private navbarStateService: LatchNavbarStateService,
    private pageWithTabsService: PageWithTabsService
  ) { }

  get isEmpty() {
    return !this.isLoading && this.deviceLogs.length === 0;
  }

  get noMoreLogs() {
    return this.deviceLogs.length && !this.logsNextPage;
  }

  get isLoading() {
    return this.isLoadingFilter || this.isLoadingLogs;
  }

  ngOnInit(): void {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Device Activity'
    });

    this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0].uuid),
      tap(buildingUUID => this.buildingUUID = buildingUUID),
      switchMap(buildingUUID => this.spaceService.getSpaces(buildingUUID)),
      tap(spaces => this.allSpaces = spaces),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.loadFilter();
      this.initializeFilters();
      this.initializeFiltersSubscription();
    });

    this.datasource.sortChange().pipe(
      skip(1),
      takeUntil(this.unsubscribe$)
    ).subscribe((sort) => {
      if (sort.active === 'time') {
        const sortAscending = sort.direction === 'asc';
        this.handleSortWhen(sortAscending);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadFilter() {
    this.isLoadingFilter = true;
    this.activatedRoute.queryParams.pipe(
      map((params) => ({
        selectedSpaces: arrayOf(params.space),
        selectedDevices: arrayOf(params.deviceType),
        startTime: params.startTime ? new Date(params.startTime) : undefined
      })),
      map(params => ({
        ...params,
        selectedSpaces: params.selectedSpaces.map(id => this.allSpaces.find(space => space.uuid === id)),
        selectedDevices: params.selectedDevices
      })),
      tap(params => {
        this.selectedSpaces = params.selectedSpaces
          .filter((item): item is SpaceShortInfo => !!item);
        this.selectedDeviceTypes = params.selectedDevices;
        this.clearLogs();
        this.isLoadingFilter = false;
        this.analyticsService.track('Activity Filter Changed', {
          'Num Spaces': this.selectedSpaces.length,
          'Num Device Types': this.selectedDeviceTypes.length,
          'Date selected?': params.startTime ? true : false
        });
      }),
      // Perform sorting business logic if start time filter has changed.
      switchMap(({ startTime }) => {
        const startTimeHasChanged = this.startTime !== startTime;
        const isAscending = !!startTime;
        this.startTime = startTime;
        if (startTimeHasChanged) {
          // If a startTime has been set: show oldest first (ascending).
          // Otherwise we're showing all time: show most recent first (descending).
          this.datasource.setSort({ active: 'time', direction: isAscending ? 'asc' : 'desc' });
          // Close this chain and defer loading next to our sort change handler (handleSortWhen).
          return EMPTY;
        } else {
          return of({ startTime });
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.loadNext();
    });
  }

  loadNext() {
    if (!this.logsNextPage) {
      return;
    }

    // We track each request we send for logs so that we can understand how often and far users
    // typically scroll through logs (rather than refining date filter).
    this.analyticsService.track('Device Activity Load Logs', {
      'Start Index': this.logsNextPage.start
    });

    // Loading the next batch may always cancel any in-flight requests. Ways to trigger loadNext:
    // - Change filters -> always clear state and fetch anew
    // - Sort by time -> always clear state and fetch anew
    // - Infinite scroll -> may only ever issue one request at a time
    this.loadNextSubscription?.unsubscribe();

    // rootSpaceUUID param will return activity for all devices in child spaces
    // spaceUUID param will return activity for devices only in that space
    let spaceUUIDs: string[] = [];
    let rootSpaceUUIDs: string[] = [];
    if (!this.selectedSpaces.length) {
      // If there is no selected space, we will request activity for all devices in the selected building
      rootSpaceUUIDs.push(this.buildingUUID);
    } else {
      // If selected spaces includes a unit, we will request activity for all devices in that unit and any children of the unit space
      // If selected spaces includes the building, we will only request activity for devices assigned to the building
      spaceUUIDs = this.selectedSpaces.filter(space => space.spaceType === SpaceType.Building).map(space => space.uuid);
      rootSpaceUUIDs = this.selectedSpaces.filter(space => space.spaceType === SpaceType.ResidentialUnit).map(space => space.uuid);
    }

    this.isLoadingLogs = true;
    this.datasource.startLoading();
    this.loadNextSubscription = this.smartHomeService.getDeviceActivityLogs({
      spaceUUIDs,
      rootSpaceUUIDs,
      deviceTypes: this.selectedDeviceTypes,
      startTime: this.startTime,
      sortAscending: this.sortAscending,
      start: this.logsNextPage.start,
      limit: this.logsNextPage.limit
    }).pipe(catchError((error) => {
      this.isLoadingLogs = false;
      this.datasource.stopLoading();
      this.clearLogs();
      this.errorHandlerService.handleException(error);
      return EMPTY;
    })).subscribe(response => {
      this.isLoadingLogs = false;
      this.datasource.stopLoading();
      this.updateLogs(response.activityLogs, response.metadata.nextPage);
    });
  }

  private initializeFilters(): void {
    const filterItems: FilterItem[] = [];

    filterItems.push({
      type: 'autocomplete',
      data: this.getSpacesForFilter().map(space => ({ name: space.name, value: space })),
      field: 'selectedSpaces',
      label: 'Units',
      placeholder: 'Search Units',
    });

    filterItems.push({
      type: 'checkbox-list',
      data: SMART_DEVICE_TYPES,
      field: 'selectedDeviceTypes',
      label: 'Devices',
    });

    filterItems.push({
      type: 'date-time',
      field: 'startTime',
      label: 'Since',
      placeholder: 'Since',
    });

    this.navbarStateService.initializeFilter(filterItems);
    this.navbarStateService.patchFormValue({
      selectedSpaces: this.selectedSpaces,
      selectedDeviceTypes: this.selectedDeviceTypes,
      startTime: this.startTime ?? getDefaultStartTime(),
    });
  }

  private initializeFiltersSubscription(): void {
    this.navbarStateService.getFilterValueChange().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((value) => {
      this.navbarFilterValue = value;
      this.filter();
    });
  }

  private getSpacesForFilter(): SpaceShortInfo[] {
    const building = this.allSpaces.filter(space => space.spaceType === SpaceType.Building);
    const units = this.allSpaces.filter(space => space.spaceType === SpaceType.ResidentialUnit)
      .sort((a, b) => a.name.localeCompare(b.name));
    return building.concat(units);
  }

  getFriendlyTimestamp(log: DeviceActivityLog) {
    return new Date(log.timestamp.valueOf() * 1000);
  }

  getSpaceNameForDisplay(spaceUUID: string) {
    const space = this.allSpaces.find(x => x.uuid === spaceUUID);
    assertIsDefined(space);
    if (space.spaceType === SpaceType.ResidentialUnit) {
      return `Unit ${space.name}`;
    }
    if (space.spaceType === SpaceType.Room) {
      const parent = this.spaceService.getUnitForRoom(space, keyBy(this.allSpaces, 'uuid'));
      if (parent) {
        return `Unit ${parent.name}`;
      }
    }
    if (space.spaceType === SpaceType.Building) {
      return 'Common Area';
    }
    return space.name;
  }

  getDeviceNameForDisplay(log: DeviceActivityLog) {
    const space = this.allSpaces.find(x => x.uuid === log.spaceUUID);
    assertIsDefined(space);
    if (space.spaceType === SpaceType.Room) {
      return `${space.name} ${log.deviceName}`;
    }
    return log.deviceName;
  }

  private filter(): void {
    const spaces = this.navbarFilterValue.selectedSpaces ?? [];
    const deviceTypes = this.navbarFilterValue.selectedDeviceTypes ?? [];
    const startTime = this.navbarFilterValue.startTime;
    const queryParams = omitEmptyNullOrUndefined({
      building: this.buildingUUID,
      space: spaces.map(space => space.uuid),
      deviceType: deviceTypes,
      startTime: startTime ? startTime.toISOString() : null
    });

    this.router.navigate(['/console/activity/device'], { queryParams });
  }

  handleSortWhen(sortAscending: boolean) {
    this.clearLogs();
    this.sortAscending = sortAscending;
    this.loadNext();
  }

  private clearLogs() {
    this.deviceLogs = [];
    this.datasource.set([]);
    this.logsNextPage = INITIAL_LOGS_NEXT_PAGE;
    this.updateSubnavSubtitle();
  }

  private updateLogs(deviceLogs: DeviceActivityLog[], logsNextPage?: NextPage) {
    this.deviceLogs = this.deviceLogs.concat(deviceLogs);
    this.datasource.append(deviceLogs);
    this.logsNextPage = logsNextPage;
    this.updateSubnavSubtitle();
  }

  private updateSubnavSubtitle() {
    this.pageWithTabsService.setSubnavSubtitle(`(${this.deviceLogs.length})`);
  }
}
