import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, reduce, tap } from 'rxjs/operators';
import { Operation, OperationStatus, isKeyOperationType, isKeyMembershipOperationType } from '../../models/operations';
import { ChangeNotificationService } from '../appstate/change-notification.service';
import { SelectedAccountService } from '../appstate/selected-account.service';

import { AuthService } from '../auth/auth.service';
import { chunk } from '../utility/utility';
import { OperationsService } from './operations.service';

@Injectable()
export class HTTPOperationsService extends OperationsService {
  private selectedAccountUUID!: string;

  constructor(
    private selectedAccountService: SelectedAccountService,
    private authService: AuthService,
    private changeNotificationService: ChangeNotificationService,
  ) {
    super();
    this.selectedAccountService.getSelectedAccount().subscribe(account => this.selectedAccountUUID = account.uuid);
  }

  getOperationStatuses<T = unknown>(operationUUID: string | string[]): Observable<Operation<T>[]> {
    const MAX_OPERATION_UUIDS_IN_QUERY_PARAMS = 20;
    const uuids = (operationUUID instanceof Array) ? operationUUID : [operationUUID];

    // special case here because `from()` will never emit when passed an empty array
    if (uuids.length === 0) {
      return of([]);
    }

    const chunkedOperationUUIDs = chunk(uuids, MAX_OPERATION_UUIDS_IN_QUERY_PARAMS);
    return from(chunkedOperationUUIDs).pipe(
      mergeMap((operationUUIDChunk: string[]) => this.getChunkedOperationStatuses<T>(operationUUIDChunk)),
      reduce((all, curr) => all.concat(curr)),
      /* invalidate cache here, because this function will be polled in every case where a client consumes the result of an operation
       * in designing this feature, we accept that the entire key / keymembership cache will be blown away
       * NOTE: this logic assumes **clients will not continue to poll operations after the first time they have returned as completed**
       */
      tap((allUpdatedOperations) => {
        const shouldInvalidateCache = allUpdatedOperations.some((o) => (o.status === OperationStatus.SUCCESS &&
          (isKeyOperationType(o.operationType) || isKeyMembershipOperationType(o.operationType))));
        if (shouldInvalidateCache) {
          this.changeNotificationService.publishKeyChange();
          this.changeNotificationService.publishKeyMembershipChange();
        }
      })
    );
  }

  private getChunkedOperationStatuses<T = unknown>(operationUUIDs: string[]): Observable<Operation<T>[]> {
    let search = new HttpParams();
    for (const uuid of operationUUIDs) {
      search = search.append('id', uuid);
    }
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/operations',
      search
    }).pipe(
      map((response) => AuthService.getPayload(response)),
    );
  }

  getInProgressOperations(): Observable<Operation<unknown>[]> {
    return this.getAllOperationsForCurrentUser$().pipe(
      map((allOperations) => allOperations.filter(operation =>
        operation.status === OperationStatus.IN_PROGRESS &&
        operation.accountId === this.selectedAccountUUID
      )),
    );
  }

  acknowledgeFailure(operationUUID: string): Observable<{ operationId: string; }> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/operations/${operationUUID}/acknowledge`,
      data: {}
    }).pipe(
      map((response) => AuthService.getPayload(response))
    );
  }

  getUnacknowledgedFailures(): Observable<Operation<unknown>[]> {
    return this.getAllOperationsForCurrentUser$().pipe(
      map((allOperations) => allOperations.filter(operation =>
        operation.status === OperationStatus.FAILURE_UNACKNOWLEDGED &&
        operation.accountId === this.selectedAccountUUID
      )),
    );
  }

  private getAllOperationsForCurrentUser$(): Observable<Operation<unknown>[]> {
    const search = this.authService.currentUser ?
      new HttpParams().append('user', this.authService.currentUser.uuid) :
      {};
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/operations',
      search
    }).pipe(
      map((response) => AuthService.getPayload(response))
    );
  }
}
