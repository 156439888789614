import { inject, Injectable } from '@angular/core';
import { Person } from 'manager/models/contact-cards';
import { Key } from 'manager/models/key';
import { Lock } from 'manager/models/lock';
import { ContactCardService } from 'manager/services/contact-card/contact-card.service';
import { KeyService } from 'manager/services/key/key.service';
import { LockService } from 'manager/services/lock/lock.service';
import { Observable, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FailureDetailsService {

  private userUUIDPersonMap = new Map<string, Observable<Person>>();
  private keyUUIDKeyMap = new Map<string, Observable<Key>>();
  private lockUUIDLockMap = new Map<string, Observable<Lock>>();

  private contactCardService = inject(ContactCardService);
  private keyService = inject(KeyService);
  private lockService = inject(LockService);

  /**
   * Reset failure details cache when the selected building changes
   */
  public reset(): void {
    this.userUUIDPersonMap.clear();
    this.keyUUIDKeyMap.clear();
    this.lockUUIDLockMap.clear();
  }

  public getPersonInfo(userUUID: string, buildingUUID: string): Observable<Person> {
    let result = this.userUUIDPersonMap.get(userUUID);
    if (!result) {
      result = this.contactCardService.getPersonForUser(buildingUUID, userUUID).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
      this.userUUIDPersonMap.set(userUUID, result);
    }
    return result;
  }

  public getKeyInfo(keyUUID: string, buildingUUID: string): Observable<Key> {
    let result = this.keyUUIDKeyMap.get(keyUUID);
    if (!result) {
      result = this.keyService.getKey(keyUUID, buildingUUID).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
      this.keyUUIDKeyMap.set(keyUUID, result);
    }
    return result;
  }

  public getLockInfo(lockUUID: string, buildingUUID: string): Observable<Lock> {
    let result = this.lockUUIDLockMap.get(lockUUID);
    if (!result) {
      result = this.lockService.getLockDetails(lockUUID, buildingUUID).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
      this.lockUUIDLockMap.set(lockUUID, result);
    }
    return result;
  }

}
