<latch-subnav
  headerTitle="Activity"
  [subtitle]="subnavSubtitle"
  [actions]="actions"/>

<latch-tab-group class="latch-tab-with-viewport" [selected]="activeTab" (tabClicked)="onTabClick($event)">
  <latch-tab [id]="Tab.Doors">Doors</latch-tab>
  <latch-tab *ngIf="hasSmartHomeFeature" [id]="Tab.SmartDevices">Smart Devices</latch-tab>
  <latch-tab *ngIf="showServiceOrdersTab" [id]="Tab.ServiceOrders">Service Orders</latch-tab>
  <latch-tab *ngIf="hasDeliveryAssistantFeature" [id]="Tab.Deliveries">Deliveries</latch-tab>
  <latch-tab *ngIf="hasPackageManagementFeature" [id]="Tab.Packages">Packages</latch-tab>
  <latch-tab *ngIf="hasBookingsFeature" [id]="Tab.Bookings">Bookings</latch-tab>
</latch-tab-group>

<router-outlet></router-outlet>
