import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FloorGroup } from '../../../models/floor-group';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';

@Component({
  selector: 'latch-floor-group-floor-list',
  templateUrl: './floor-group-floor-list.component.html',
  styleUrls: ['./floor-group-floor-list.component.scss']
})
export class FloorGroupFloorListComponent implements OnInit {
  _floorGroup!: FloorGroup;
  floors: string[] = [];
  floorGroupName = 'Floor Group';
  search = '';
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private analyticsService: LatchAnalyticsService
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Floor Group Floors List'
    });
  }

  @Input()
  public set floorGroup(floorGroup: FloorGroup | undefined) {
    if (floorGroup) {
      this._floorGroup = floorGroup;
      if (floorGroup.floorNames) {
        this.floors = floorGroup.floorNames;
      }
      if (floorGroup.name) {
        this.floorGroupName = floorGroup.name;
      }
    }
  }
}
