<button class="get-help-button" #trigger (click)="onClick()">
  ?
</button>
<latch-overlay [origin]="trigger" [open]="isOpen" [blockScroll]="true"
  horizontalAlign="right" [originAllowOverlap]="false" (outsideClick)="close()">
  <div class="get-help-menu">
    <a class="get-help-menu-item" routerLink="/console/resources"
      queryParamsHandling="preserve" (click)="close()">
      Get Help / Resources
    </a>
    <a class="get-help-menu-item" routerLink="/console/support"
      queryParamsHandling="preserve" (click)="close()">
      Contact Support
    </a>
    <span class="get-help-menu-item" (click)="onFeedbackClick(); close()">
      Submit Feedback
    </span>
  </div>
</latch-overlay>
