import { HttpParameterCodec } from '@angular/common/http';

/**
 * Angular has issue when passing `+` sign as a url query param it is encoded to ` ` space
 * This class will correctly encode and decode special signs
 * @link Have a look at the github issue for more description https://github.com/angular/angular/issues/11058
 */
export class CustomQueryEncoderHelper implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}
