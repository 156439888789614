import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { LatchMfaService } from '@latch/latch-web';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';

enum Step {
  Code,
  Success
}

@Component({
  selector: 'latch-de-require-mfa-flow',
  templateUrl: './de-require-mfa-flow.component.html',
  styleUrls: ['./de-require-mfa-flow.component.scss']
})
export class DeRequireMfaFlowComponent implements OnInit {
  @Input() accountId!: string;
  @Output() deRequiredSuccess = new EventEmitter();
  @Output() goToSettings = new EventEmitter();

  currentStep: Step = Step.Code;
  Step = Step;

  constructor(
    private mfaService: LatchMfaService,
    private errorHandlerService: ErrorHandlerService,
    private analyticsService: LatchAnalyticsService,
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, { [LatchAnalyticsConstants.PageName]: 'Two-Factor Auth DeRequire' });
  }

  public confirmChange(e: Event): void {
    e.preventDefault();
    this.mfaService.requireMFAForPortfolio(this.accountId, false)
      .subscribe(
        () => {
          this.currentStep = Step.Success;
          this.analyticsService.track('Two-Factor Auth De-Require', {
            [LatchAnalyticsConstants.Success]: true
          });
        },
        (error: Error) => {
          this.analyticsService.track('Two-Factor Auth De-Require', {
            [LatchAnalyticsConstants.Success]: false,
            [LatchAnalyticsConstants.ErrorMessage]: error
          });
          this.errorHandlerService.handleException(error);
        });
  }

  finished(e: Event) {
    this.deRequiredSuccess.emit();
    e.preventDefault();
  }

  goBack(e: Event) {
    this.goToSettings.emit();
    e.preventDefault();
  }
}
