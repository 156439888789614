<latch-card class="page fixed">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button type="button" class="latch-button-icon" (click)="handleClose()">
        <span class="latch-icon-close"></span>
      </button>
      <a class="latch-button-text" *ngIf="currentTab === NotificationCenterTabs.Errors && numFailures > 0"
        (click)="clearAllFailures()">
        Dismiss all
      </a>
    </div>
    <h2 class="latch-card-title">Alerts</h2>
  </ng-container>
  <ng-container latchCardContent>
    <latch-tab-group [selected]="currentTab" (tabClicked)="handleTabClicked($event)">
      <latch-tab [id]="NotificationCenterTabs.Updates">Device Updates
        <span *ngIf="numUpdates" class="latch-badge red round">{{numUpdates}}</span>
      </latch-tab>
      <latch-tab [id]="NotificationCenterTabs.Errors">System
        <span *ngIf="numFailures" class="latch-badge red round">{{numFailures}}</span>
      </latch-tab>
      <latch-tab *ngIf="showAutomationTab" [id]="NotificationCenterTabs.Conflicts">Automation
        <span *ngIf="numConflicts" class="latch-badge red round">{{numConflicts}}</span>
      </latch-tab>
      <latch-tab *ngIf="showServiceOrdersTab" [id]="NotificationCenterTabs.ServiceOrders">Service Orders
        <span *ngIf="numServiceOrders > 0" class="latch-badge red round">
          {{numServiceOrders}}{{serviceOrdersResponse?.nextPageToken ? '+' : ''}}
        </span>
      </latch-tab>
    </latch-tab-group>

    <!--Updates section-->
    <ng-container *ngIf="currentTab === NotificationCenterTabs.Updates">
      <div class="empty" *ngIf="numUpdates === 0">There are no updates.</div>
      <div class="list">
        <div *ngFor="let update of updates">
          <div class="info">{{getLockTypeText(update.type)}}</div>
          <div class="list-item" *ngFor="let lock of update.locks">
            <div class="list-header">Update required</div>
            <div class="list-body">
              <div><strong>{{lock.name}}</strong> must be updated using one of the Latch apps.</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--Errors section-->
    <ng-container *ngIf="currentTab === NotificationCenterTabs.Errors">
      <latch-loading [isActive]="isFailuresLoading">
        <div class="empty" *ngIf="numFailures === 0 && !isFailuresLoading">There are no errors.</div>
        <div class="info" *ngIf="numFailures > 0 && !isFailuresLoading">Only visible to you</div>
        <div class="list">
          <div class="list-item" *ngFor="let failure of failures" [class.hidden]="isFailuresLoading">
            <div class="list-header">Unexpected error</div>
            <div class="list-body">
              <latch-failure-details [failure]="failure" (load)="handleFailureLoaded()"></latch-failure-details>
              <div class="list-actions">
                <a class="latch-button-text" (click)="removeFailure(failure.operationId)">Dismiss</a>
              </div>
            </div>
          </div>
        </div>
      </latch-loading>
    </ng-container>


    <!--Merge conflicts section-->
    <ng-container *ngIf="currentTab === NotificationCenterTabs.Conflicts">
      <div class="empty" *ngIf="numConflicts === 0">There are no access changes.</div>
      <div class="list">
        <div class="list-item" *ngIf="pmsConflicts">
          <div class="list-header">Access review</div>
          <div class="list-body">
            <div>
              We created <strong>{{pmsConflicts.conflictCount}}</strong> access suggestions.
              <ng-container *ngIf="pmsConflicts.conflictCount > 0">
                Review the changes to access that would result from the automated access integration.
              </ng-container>
              <ng-container *ngIf="pmsConflicts.conflictCount === 0">
                Access automation is ready to be enabled.
              </ng-container>
            </div>
            <div class="list-actions">
              <a class="latch-button-text" routerLink="/console/access-review" queryParamsHandling="preserve">
                Review now
              </a>
            </div>
          </div>
        </div>
        <div class="list-item" *ngIf="pmsUnmappedUnits">
          <div class="list-header">Unmapped Units</div>
          <div class="list-body">
            <div>
              We found <strong>{{pmsUnmappedUnits.conflictCount}}</strong> unmapped units. Review and map the unmapped units to the corresponding Latch units.
            </div>
            <div class="list-actions">
              <a class="latch-button-text" routerLink="/console/integrations/manage-settings" [queryParams]="{ tab: 'UnitMapping', mapped: 'false' }" queryParamsHandling="merge">
                Review now
              </a>
            </div>
          </div>
        </div>
        <div class="list-item" *ngIf="duplicates">
          <div class="list-header">Duplicate accounts</div>
          <div class="list-body">
            <div>We found <strong>{{duplicates.conflictCount}}</strong> potential duplicate accounts. Review these
              duplicates to prevent account issues.</div>
            <div class="list-actions">
              <a class="latch-button-text" routerLink="/console/duplicates" queryParamsHandling="preserve">
                Review now
              </a>
            </div>
          </div>
        </div>
        <div class="list-item" *ngIf="duplicateEmails">
          <div class="list-header">Duplicate emails</div>
          <div class="list-body">
            <div>We found <strong>{{duplicateEmails.conflictCount}}</strong> duplicate emails. Review these duplicates to prevent account issues.</div>
            <div class="list-actions">
              <a class="latch-button-text" routerLink="/console/duplicate-emails" queryParamsHandling="preserve">
                Review now
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--Service orders section-->
    <ng-container *ngIf="currentTab === NotificationCenterTabs.ServiceOrders">
      <div class="empty" *ngIf="numServiceOrders === 0">There are no alerts at the moment.</div>
      <div class="list">
        <div class="list-item" *ngFor="let serviceOrder of serviceOrdersResponse?.serviceOrders">
          <div class="list-header">New service order requested</div>
          <div class="list-body">
            <div>{{serviceOrder.title}}</div>
            <div class="list-actions">
              <a class="latch-button-text" routerLink="/console/activity/service-orders"
                [queryParams]="{acknowledgement: false}" queryParamsHandling="merge">
                Review now
              </a>
            </div>
          </div>
          <div class="list-date">{{serviceOrder.createdAt | relativeDate}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</latch-card>
