import { PmsSource } from 'manager/models/building';

export interface Integration {
  type: IntegrationType;
  title: string;
  description: string[];
  latchSupportArticleUrl: string;
  pmsSource?: PmsSource;
  pmsSourceSupportArticleUrl?: string;
  active?: boolean;
  updateTime?: Date;
  status?: IntegrationStatus;
}

export enum IntegrationStatus {
  Setup = 'SETUP',
  Pending = 'PENDING',
  Configure = 'CONFIGURE',
  Connected = 'CONNECTED',
}

export interface PmsCredential {
  uuid: string;
  pmsSource?: PmsSource,
  client: string;
  username?: string;
  password?: string;
  realPageSetting?: {
    clientId: string
  },
  yardiSetting?: {
    host: string;
    serverName: string;
    databaseName: string;
    apiClientName: string;
  },
  editable?: boolean;
  validated?: boolean;
  validationStatus?: PmsCredentialValidationStatus;
  errorMessage?: string;
}

export enum PmsCredentialValidationStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface PmsProperty {
  pmsSource?: PmsSource;
  id?: string;
  client?: string;
  propertyId?: string;
  buildingId?: string;
  siteId?: string;
  name: string;
}

export enum IntegrationMode {
  Disabled = 'Disabled',
  ResidentsOnly = 'ResidentsOnly',
  ResidentsAndUnitUpdates = 'ResidentsAndUnitUpdates',
  ResidentsAndAccess = 'ResidentsAndAccess',
}

export enum IntegrationType {
  AutomateUsers = 'AutomateUsers',
  AutomateAccess = 'AutomateAccess',
  AutomateAccessForMaintenanceWorkers = 'AutomateAccessForMaintenanceWorkers'
}

export const PmsSources: (PmsSource | string)[] = [
  PmsSource.REALPAGE,
  PmsSource.ENTRATA,
  PmsSource.YARDI_API,
  PmsSource.APPFOLIO,
  'MRI',
  'StarRez',
  'BuildingLink',
  'Other'
];

export interface PmsConfiguration {
  // true if no MW approvals are required
  singleApprovalMode?: boolean;
  excludedLeaseStatuses?: ExcludedLeaseStatus[];
  integrationMode?: IntegrationMode;
  pmsCredential?: PmsCredential;
  pmsProperties?: PmsProperty[];
  status?: IntegrationStatus;
}

export interface ExcludedLeaseStatus {
  id: string;
  status: string;
}

export interface PmsUnit {
  id: string;
  pmsPropertyId: string;
  pmsPropertyName?: string;
  pmsBuildingId: string;
  pmsUnitId: string;
  pmsUnitName: string;
  unitId?: number;
  unitName?: string;
  suggested: boolean;
  remove?: boolean;
  uniqueIdentifier?: string;
}

export interface LatchUnit {
  id: number;
  name: string;
}
