import { forwardRef, NgModule } from '@angular/core';
import { AuthService } from 'manager/services/auth/auth.service';
import {
  getPayload,
  LATCH_APPLICATION_NAME,
  LATCH_LOG_ENDPOINT_BLACKLIST,
  LatchAnalyticsModule,
  LatchAuthModule,
  LatchAuthService,
  LatchLogModule
} from '@latch/latch-web';
import { environment } from 'environments/interface';
import { HttpErrorResponse } from '@angular/common/http';
import { LeaseService } from 'manager/services/lease/lease.service';
import { HTTPLeaseService } from 'manager/services/lease/http-lease.service';
import { BuildingService } from 'manager/services/building/building.service';
import { HTTPBuildingService } from 'manager/services/building/http-building.service';
import { AccountService } from 'manager/services/account/account.service';
import { HTTPAccountService } from 'manager/services/account/http-account.service';
import { ContactCardService } from 'manager/services/contact-card/contact-card.service';
import { HTTPContactCardService } from 'manager/services/contact-card/http-contact-card.service';
import { UserService } from 'manager/services/user/user.service';
import { HTTPUserService } from 'manager/services/user/http-user.service';
import { UnitService } from 'manager/services/units/unit.service';
import { HTTPUnitService } from 'manager/services/units/http-unit.service';
import { HTTPSmartHomeService } from 'manager/services/smart-home/http-smart-home.service';
import { SmartHomeService } from 'manager/services/smart-home/smart-home.service';
import { SpaceService } from 'manager/services/space/space.service';
import { HTTPSpaceService } from 'manager/services/space/http-space.service';
import { IntercomService } from 'manager/services/intercom/intercom.service';
import { HttpIntercomService } from 'manager/services/intercom/http-intercom.service';
import { PaymentService } from 'manager/services/payment/payment.service';
import { HTTPPaymentService } from 'manager/services/payment/http-payment.service';
import { PermissionsService } from 'manager/services/permissions/permissions.service';
import { HTTPPermissionsService } from 'manager/services/permissions/http-permissions.service';
import { httpInterceptorProviders } from './http-interceptors';
import { AdministratorService } from 'manager/services/administrator/administrator.service';
import { HTTPAdministratorService } from 'manager/services/administrator/http-administrator.service';
import { HTTPAccessLogService } from 'manager/services/access-log/http-access-log.service';
import { AccessLogService } from 'manager/services/access-log/access-log.service';
import { ElevatorBuildingService } from 'manager/services/elevator-building/elevator-building.service';
import {
  LockBasedElevatorBuildingService
} from 'manager/services/elevator-building/lock-based-elevator-building.service';
import { HTTPLockService } from 'manager/services/lock/http-lock.service';
import { LockService } from 'manager/services/lock/lock.service';
import { HTTPDoorSchedulesService } from 'manager/services/door-schedules/http-door-schedules.service';
import { DoorSchedulesService } from 'manager/services/door-schedules/door-schedules.service';
import { DuplicatesService } from 'manager/services/duplicates/duplicates.service';
import { HTTPDuplicatesService } from 'manager/services/duplicates/http-duplicates.service';
import { DoorDetailPageService } from 'manager/services/appstate/door-detail-page.service';
import { HTTPKeyMembershipService } from 'manager/services/key-membership/http-key-membership.service';
import { KeyMembershipService } from 'manager/services/key-membership/key-membership.service';
import { HTTPKeyService } from 'manager/services/key/http-key.service';
import { KeyService } from 'manager/services/key/key.service';
import { HTTPOperationsService } from 'manager/services/operations/http-operations.service';
import { OperationsService } from 'manager/services/operations/operations.service';
import { HTTPSyncStatusService } from 'manager/services/sync-status/http-sync-status.service';
import { SyncStatusService } from 'manager/services/sync-status/sync-status.service';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { CheckinService } from 'manager/services/checkin/checkin.service';
import { HTTPCheckinService } from 'manager/services/checkin/http-checkin.service';
import { PmsAccessService } from 'manager/services/pms-access/pms-access.service';
import { HTTPPmsAccessService } from 'manager/services/pms-access/http-pms-access.service';
import { ReportService } from 'manager/services/report/report.service';
import { HTTPReportService } from 'manager/services/report/http-report.service';
import { FeaturedEntryService } from '../services/featured-entry/featured-entry.service';
import { HTTPFeaturedEntryService } from '../services/featured-entry/http-featured-entry.service';
import { BookingService } from 'manager/modules/bookings/services/booking.service';
import { HTTPBookingService } from 'manager/modules/bookings/services/http-booking.service';
import { ServiceOrderService } from 'manager/services/service-order/service-order.service';
import { HTTPServiceOrderService } from 'manager/services/service-order/http-service-order.service';
import { DeliveryService } from '../services/delivery/delivery.service';
import { HTTPDeliveryService } from '../services/delivery/http-delivery.service';
import { InsightsService } from 'manager/services/insights/insights.service';
import { HTTPInsightsService } from 'manager/services/insights/http-insights.service';
import { SpacesService } from 'manager/modules/spaces/services/spaces.service';
import { HTTPSpacesService } from 'manager/modules/spaces/services/http-spaces.service';
import { KeyDetailPageService } from '../services/appstate/key-detail-page.service';
import { PartnerService } from 'manager/services/partner/partner.service';
import { HTTPPartnerService } from 'manager/services/partner/http-partner.service';

const DEFAULT_LOG_ENDPOINT_BLACKLIST = ['/web/v1/operations'];
const APPLICATION_NAME = 'Latch-ManagerWeb';

export function getErrorCode<T>(response: HttpErrorResponse) {
  return getPayload(response) as T;
}

@NgModule({
  imports: [
    LatchAuthModule.forRoot({
      loginApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token`,
      logoutApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token/revoke`,
      identityApiUrl: `${environment.apiBaseUrl}/web/v1/me`,
      refreshTokenApiUrl: `${environment.apiBaseUrl}/web/v1/auth/token/refresh`,
      forgotPasswordApiUrl: `${environment.apiBaseUrl}/web/v2/user-accounts/password/reset`,
      activeUserSessionsApiUrl: `${environment.apiBaseUrl}/web/v1/me/sessions`,
      revokeSessionsApiUrl: `${environment.apiBaseUrl}/web/v1/me/sessions`,
      loginPath: '/login',
      forgotPasswordPath: '/forgot-password',
      defaultPagePath: '/',
      loginScope: 'DOOR_APP',
      errorCodeGetter: getErrorCode,
      updateMFASettingsApiUrl: `${environment.apiBaseUrl}/web/v2/me/mfa`,
      enableMFAOrCancelEnrollmentApiUrl: `${environment.apiBaseUrl}/web/v2/me/mfa`,
      verifyMFACodeApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/verify`,
      validatePasswordResetTokenApiUrl: `${environment.apiBaseUrl}/web/v1/user-accounts/password/status`,
      sendPushNotificationApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/push`,
      checkPushNotificationStatusApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/push/:id/status`,
      requestMFACodeApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/codes`,
      requireMFAForPortfolioApiUrl: `${environment.apiBaseUrl}/web/v1/accounts/:id/mfa`,
      deleteAllSessionsUrl: `${environment.apiBaseUrl}/web/v2/me/sessions`,
      optoutFromMfaApiUrl: `${environment.apiBaseUrl}/web/v1/me/mfa/optout`,
      useMocks: environment.useMocks,
      auth0Config: environment.auth0Config,
      nativeAppAuthConfig: environment.nativeAppAuthConfig,
    }),
    LatchLogModule.forRoot({
      enableLogging: environment.enableLogging,
      datadog: environment.datadogToken ? {
        datadogToken: environment.datadogToken,
        environmentName: environment.name,
        service: 'manager-frontend'
      } : undefined,
    }),
    LatchAnalyticsModule.forRoot({
      enableAnalytics: environment.enableAnalytics ?? false,
      environmentName: environment.name,
      heap: environment.heapApplicationId ? {
        applicationId: environment.heapApplicationId,
        client: APPLICATION_NAME,
      } : undefined,
    }),
  ],
  exports: [
    LatchAuthModule,
  ],
  declarations: [],
  providers: [
    { provide: LATCH_LOG_ENDPOINT_BLACKLIST, useValue: DEFAULT_LOG_ENDPOINT_BLACKLIST },
    { provide: LATCH_APPLICATION_NAME, useValue: APPLICATION_NAME },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.reCaptchaToken } as RecaptchaSettings },
    AuthService,
    { provide: LatchAuthService, useExisting: forwardRef(() => AuthService) },
    httpInterceptorProviders,
    { provide: ElevatorBuildingService, useClass: LockBasedElevatorBuildingService },
    DoorDetailPageService,
    KeyDetailPageService,

    // Extended providers
    { provide: LeaseService, useClass: HTTPLeaseService },
    { provide: ContactCardService, useClass: HTTPContactCardService },
    { provide: UserService, useClass: HTTPUserService },
    { provide: BuildingService, useClass: HTTPBuildingService },
    { provide: AccountService, useClass: HTTPAccountService },
    { provide: UnitService, useClass: HTTPUnitService },
    { provide: SmartHomeService, useClass: HTTPSmartHomeService },
    { provide: SpaceService, useClass: HTTPSpaceService },
    { provide: IntercomService, useClass: HttpIntercomService },
    { provide: PaymentService, useClass: HTTPPaymentService },
    { provide: PermissionsService, useClass: HTTPPermissionsService },
    { provide: AdministratorService, useClass: HTTPAdministratorService },
    { provide: AccessLogService, useClass: HTTPAccessLogService },
    { provide: LockService, useClass: HTTPLockService },
    { provide: DoorSchedulesService, useClass: HTTPDoorSchedulesService },
    { provide: DuplicatesService, useClass: HTTPDuplicatesService },
    { provide: KeyService, useClass: HTTPKeyService },
    { provide: KeyMembershipService, useClass: HTTPKeyMembershipService },
    { provide: OperationsService, useClass: HTTPOperationsService },
    { provide: SyncStatusService, useClass: HTTPSyncStatusService },
    { provide: CheckinService, useClass: HTTPCheckinService },
    { provide: PmsAccessService, useClass: HTTPPmsAccessService },
    { provide: ReportService, useClass: HTTPReportService },
    { provide: InsightsService, useClass: HTTPInsightsService },
    { provide: FeaturedEntryService, useClass: HTTPFeaturedEntryService },
    { provide: BookingService, useClass: HTTPBookingService },
    { provide: SpacesService, useClass: HTTPSpacesService },
    { provide: ServiceOrderService, useClass: HTTPServiceOrderService },
    { provide: DeliveryService, useClass: HTTPDeliveryService },
    { provide: PartnerService, useClass: HTTPPartnerService },
  ],
})
export class CoreModule {}
