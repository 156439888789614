import { Injectable } from '@angular/core';
import {
  SmartHomeService,
  GetDeviceActivityInput,
  GetDevicesResponse,
  PagedGetDeviceActivityResponse,
  GetDisputesResponse,
  CreateOrUpdateDeviceVacancySettingsResponse
} from './smart-home.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map, catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SmartDevice, SmartDeviceTrait } from 'manager/models/device';
import { ServiceResponse } from '../interfaces';
import { DeviceVacancySettings } from 'manager/models/enterprise-settings';

@Injectable()
export class HTTPSmartHomeService extends SmartHomeService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getDevices(spaceUUID: string, recursive = true, includeTraits = false): Observable<SmartDevice[]> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/me/smartDevices',
      search: new HttpParams({
        fromObject: {
          spaceUUID,
          recursive: recursive.toString(),
          includeTraits: includeTraits.toString()
        }
      })
    }).pipe(
      map((res) => {
        const response: GetDevicesResponse = AuthService.getPayload(res);
        return response.devices;
      }),
      catchError((error: Error) => throwError(error))
    );
  }

  getAllDevices(recursive = true, includeTraits = false): Observable<SmartDevice[]> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/me/smartDevices',
      search: new HttpParams({
        fromObject: {
          recursive: recursive.toString(),
          includeTraits: includeTraits.toString()
        }
      })
    }).pipe(
      map((res) => {
        const response: GetDevicesResponse = AuthService.getPayload(res);
        return response.devices;
      }),
      catchError((error: Error) => throwError(error))
    );
  }

  getDeviceDetails(deviceUUID: string): Observable<SmartDevice> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/me/smartDevices/${deviceUUID}`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      map((device: SmartDevice) => {
        // trait.parameters.spaces property is array of uuids, but it won't get picked up
        // by transformIdsToLowercase, so we need to manually make them lowercase
        device.traits.forEach(trait => {
          if (trait.trait === SmartDeviceTrait.HubSpaces) {
            trait.parameters.spaces =
              trait.parameters.spaces.map(space => space.toLowerCase());
          }
        });
        return device;
      }),
      catchError((error: Error) => throwError(error))
    );
  }

  getDevicesForHub(hubUUID: string): Observable<SmartDevice[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/me/smartDevices/${hubUUID}/controlledDevices`
    }).pipe(
      map(res => {
        const response: GetDevicesResponse = AuthService.getPayload(res);
        return response.devices;
      }),
      catchError((error: Error) => throwError(error))
    );
  }

  getHubHotspotPassword(hubUUID: string): Observable<string> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/me/smartDevices/${hubUUID}/hotspotPassword`
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError((error: Error) => throwError(error))
    );
  }

  getDeviceActivityLogs(input: GetDeviceActivityInput): Observable<PagedGetDeviceActivityResponse> {
    let search = new HttpParams();

    if (input.spaceUUIDs?.length) {
      input.spaceUUIDs.forEach(uuid => search = search.append('spaceUUIDs', uuid));
    }

    if (input.rootSpaceUUIDs?.length) {
      input.rootSpaceUUIDs.forEach(uuid => search = search.append('rootSpaceUUIDs', uuid));
    }

    if (input.deviceTypes?.length) {
      input.deviceTypes.forEach(type => search = search.append('deviceTypes', type));
    }

    if (input.startTime) {
      const startDateEpoch = Math.floor(+input.startTime / 1000);
      search = search.append('startDateEpoch', `${startDateEpoch}`);
    }

    if (input.endTime) {
      const endDateEpoch = Math.floor(+input.endTime / 1000);
      search = search.append('endDateEpoch', `${endDateEpoch}`);
    }

    if (input.sortAscending) {
      search = search.append('sortAscending', 'true');
    }

    if (input.start !== undefined) {
      search = search.append('start', `${input.start}`);
    }

    if (input.limit !== undefined) {
      search = search.append('limit', `${input.limit}`);
    }

    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/me/smartDeviceLogs/smartDeviceActivityLogs',
      search
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError((error: Error) => throwError(error))
    );
  }

  getDisputesForUnit(unitId: number): Observable<GetDisputesResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/me/smartDevices/dispute/${unitId}`,
    }).pipe(
      map((res) => AuthService.getPayload(res)),
      catchError((error: Error) => throwError(error))
    );
  }

  closeDisputeForUnit(unitId: number): Observable<ServiceResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/me/smartDevices/dispute/${unitId}/resolveDispute`,
      data: {
        reason: 'Property manager closed dispute.'
      }
    }).pipe(
      map((res) => AuthService.getPayload(res)),
      catchError((error: Error) => throwError(error))
    );
  }

  renameDevice(deviceUUID: string, name: string): Observable<SmartDevice> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/smartDevices/${deviceUUID}/updateName`,
      data: {
        name
      }
    }).pipe(
      map((res) => AuthService.getPayload(res)),
      catchError((res) => {
        const result = AuthService.getPayload(res);
        return throwError(new Error(result));
      })
    );
  }

  getSmartHomeSettingsForSpace(spaceUUID: string): Observable<DeviceVacancySettings[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/me/spaces/${spaceUUID}/smartHome/enterpriseSettings`,
    }).pipe(
      map((res) => {
        const response: CreateOrUpdateDeviceVacancySettingsResponse = AuthService.getPayload(res);
        return response.settings?.smartDeviceVacancySettings ?? [];
      }),
      catchError((error: Error) => throwError(error))
    );
  }

  createOrUpdateDeviceVacancySettingsForSpace(spaceUUID: string, settings: DeviceVacancySettings[]):
    Observable<DeviceVacancySettings[]> {
      return this.authService.request({
        method: 'PUT',
        endpoint: `/web/v1/me/spaces/${spaceUUID}/smartHome/smartDeviceVacancySettings`,
        data: {
          smartDeviceVacancySettings: settings
        }
      }).pipe(
        map((res) => {
          const response: CreateOrUpdateDeviceVacancySettingsResponse = AuthService.getPayload(res);
          return response.settings.smartDeviceVacancySettings;
        }),
        catchError((error: Error) => throwError(error))
      );
    }
}
