import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'environments/interface';
import { Building } from '../../models/building';
import { FeaturedEntry } from '../../models/featured-entry';
import {
  BuildingIntercomSettings,
  DefaultNameFormattingType,DirectoryListSelect,
  DirectoryListType,
  DirectorySortSelect,
  DirectorySortType,
  NameFormattingSelect } from '../../models/intercom';
import { UserNameInfo } from '../../models/user';
import { IntercomDisplayNamePipe } from '../../pipes/intercom-display-name.pipe';
import { ErrorHandlerService } from '../../services/appstate/error-handler.service';
import { SelectedBuildingsService } from '../../services/appstate/selected-buildings.service';
import { FeaturedEntryService } from '../../services/featured-entry/featured-entry.service';
import {
  IntercomService,
  IntercomShortInfo,
  BUILDING_INTERCOM_SETTINGS_CHANGES_SAVED_MESSAGE
} from '../../services/intercom/intercom.service';
import { NotificationService } from '../../services/notification/notification.service';

const sampleName: UserNameInfo = {
  firstName: 'Tom',
  lastName: 'Smith'
};

const minSupportedIntercomVersion = '1.18.2';
const minIntercomVersionForDirectorySettings = '1.19.0';

const maxFeaturedEntries = 3;

@Component({
  selector: 'latch-intercom-settings',
  templateUrl: './intercom-settings.component.html',
  styleUrls: ['./intercom-settings.component.scss']
})
export class IntercomSettingsComponent implements OnInit, OnDestroy {
  public isPageLoading = false;
  public isUpdateLoading = false;
  public nameFormattingSelect: NameFormattingSelect[] = [
    {
      name: this.intercomDisplayNamePipe.transform(sampleName, DefaultNameFormattingType.FIRST_NAME_LAST_NAME),
      value: DefaultNameFormattingType.FIRST_NAME_LAST_NAME
    },
    {
      name: this.intercomDisplayNamePipe.transform(sampleName, DefaultNameFormattingType.FIRST_NAME_LAST_INITIAL),
      value: DefaultNameFormattingType.FIRST_NAME_LAST_INITIAL
    },
    {
      name: this.intercomDisplayNamePipe.transform(sampleName, DefaultNameFormattingType.FIRST_INITIAL_LAST_NAME),
      value: DefaultNameFormattingType.FIRST_INITIAL_LAST_NAME
    },

  ];
  public customNamesDisabled = false;
  public defaultNameFormattingType: DefaultNameFormattingType = this.nameFormattingSelect[0].value;
  public supportedIntercomVersions = false;
  public supportedIntercomVersionForDirectorySettings = false;
  public virtualIntercomExists = false;
  public featuredEntries?: FeaturedEntry[];
  public maxFeaturedEntries: number = maxFeaturedEntries;
  public directoryListSelect: DirectoryListSelect[] = [
    {
      name: 'Unit List',
      value: DirectoryListType.UNIT_LIST
    },
    {
      name: 'Resident List with Unit',
      value: DirectoryListType.RESIDENT_LIST_WITH_UNIT
    },
    {
      name: 'Resident List without Unit',
      value: DirectoryListType.RESIDENT_LIST_WITHOUT_UNIT
    },
  ];
  public directoryListType: DirectoryListType = this.directoryListSelect[0].value;
  public directorySortSelect: DirectorySortSelect[] = [
    {
      name: 'Alphanumeric',
      value: DirectorySortType.ALPHANUMERIC
    },
    {
      name: 'Custom',
      value: DirectorySortType.CUSTOM
    },
  ];
  public directorySortType: DirectorySortType = this.directorySortSelect[0].value;

  private buildingIntercomSettings!: BuildingIntercomSettings;
  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();


  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private intercomService: IntercomService,
    private errorHandlerService: ErrorHandlerService,
    private intercomDisplayNamePipe: IntercomDisplayNamePipe,
    private notificationService: NotificationService,
    private featuredEntryService: FeaturedEntryService
  ) { }

  ngOnInit(): void {
    this.isPageLoading = true;
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      tap(buildings => this.selectedBuilding = buildings[0]),
      switchMap(() => {
        if (environment.enableFeaturedEntries) {
          return this.featuredEntryService.updateFeaturedEntries.pipe(
            switchMap(() => this.featuredEntryService.getBuildingFeaturedEntries(this.selectedBuilding.uuid))
          );
        }
        return of(undefined);
      }),
      tap(featuredEntries => this.featuredEntries = featuredEntries),
      switchMap(() => this.intercomService.getBuildingIntercoms(this.selectedBuilding.uuid)),
      tap(intercoms => {
        this.supportedIntercomVersions = this.atLeastOneIntercomGreaterThanMin(intercoms, minSupportedIntercomVersion);
        this.supportedIntercomVersionForDirectorySettings = this.atLeastOneIntercomGreaterThanMin(
          intercoms,
          minIntercomVersionForDirectorySettings
        );
      }),
      switchMap(() => this.intercomService.getBuildingVirtualIntercoms(this.selectedBuilding.uuid)),
      tap(virtualIntercoms => this.virtualIntercomExists = virtualIntercoms.length > 0),
      switchMap(() => this.intercomService.getBuildingIntercomSettings(this.selectedBuilding.uuid)),
      takeUntil(this.unsubscribe$)
    ).subscribe(buildingIntercomSettings => {
      this.buildingIntercomSettings = buildingIntercomSettings;
      this.setBuildingIntercomSettings();
      this.isPageLoading = false;
    }, error => {
      this.isPageLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleCustomNamesDisabled(): void {
    this.customNamesDisabled = !this.customNamesDisabled;
  }

  handleSubmit(): void {
    if (!this.canSubmit()) {
      return;
    }
    this.isUpdateLoading = true;
    this.intercomService.updateBuildingIntercomSettings(
      this.selectedBuilding.uuid,
      {
        customNamesDisabled: this.customNamesDisabled,
        defaultNameFormattingType: this.defaultNameFormattingType,
        directoryListType: this.directoryListType,
        directorySortType: this.directorySortType
      }
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(buildingIntercomSettings => {
      this.buildingIntercomSettings = buildingIntercomSettings;
      this.notificationService.showMessage(BUILDING_INTERCOM_SETTINGS_CHANGES_SAVED_MESSAGE);
      this.isUpdateLoading = false;
    }, error => {
      this.isUpdateLoading = false;
      this.errorHandlerService.handleException(error);
      this.setBuildingIntercomSettings();
    });
  }

  canSubmit(): this is { defaultNameFormattingType: string; } {
    return !!this.defaultNameFormattingType;
  }

  handleDisplayViewChange(directoryListType: DirectoryListType): void {
    if (directoryListType === DirectoryListType.RESIDENT_LIST_WITH_UNIT
      || directoryListType === DirectoryListType.RESIDENT_LIST_WITHOUT_UNIT) {
        this.directorySortType = DirectorySortType.ALPHANUMERIC;
      }
  }

  handleRadioChange(directorySortType: DirectorySortType): void {
    this.directorySortType = directorySortType;
  }

  disableDirectorySettings(): boolean {
    return !this.supportedIntercomVersionForDirectorySettings && !this.virtualIntercomExists;
  }

  disableCustomDisplayOrderSetting(): boolean {
    return this.directoryListType === DirectoryListType.RESIDENT_LIST_WITH_UNIT
    || this.directoryListType === DirectoryListType.RESIDENT_LIST_WITHOUT_UNIT;
  }

  private atLeastOneIntercomGreaterThanMin(intercoms: IntercomShortInfo[], minSupportedVersion: string): boolean {
    for (let i = 0; i < intercoms.length; i++) {
      if (this.isIntercomVersionHigherThanMinOrNull(minSupportedVersion, intercoms[i].releaseVersion)) {
        return true;
      }
    }
    return false;
  }

  private isIntercomVersionHigherThanMinOrNull(minSupportedVersion: string, releaseVersion?: string | null): boolean {
    if (releaseVersion) {
      return releaseVersion === minSupportedVersion ||
        this.getHigherReleaseVersion(releaseVersion, minSupportedVersion) === releaseVersion;
    }
    return true;
  }

  private getHigherReleaseVersion(v1: string, v2: string): string | null {
    const v1Parts = v1.split('.');
    const v2Parts = v2.split('.');
    for (let i = 0; i < v1Parts.length; i++) {
      if (!v2Parts[i]) {
        return v1;
      } else {
        const v1PartValue = parseInt(v1Parts[i], 10);
        const v2PartValue = parseInt(v2Parts[i], 10);
        if (v1PartValue > v2PartValue) {
          return v1;
        }
        if (v2PartValue > v1PartValue) {
          return v2;
        }
        if (i === (v1Parts.length - 1) && v2Parts[i + 1]) {
          return v2;
        }
      }
    }
    return null;
  }

  private setBuildingIntercomSettings(): void {
    this.customNamesDisabled = this.intercomService.getIntercomCustomNamesDisabled(this.buildingIntercomSettings);
    this.defaultNameFormattingType = this.buildingIntercomSettings.defaultNameFormattingType;
    this.directoryListType = this.buildingIntercomSettings.directoryListType;
    this.directorySortType = this.buildingIntercomSettings.directorySortType;
  }

}
