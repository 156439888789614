import { Component, EventEmitter, Output } from '@angular/core';

/**
 * Similar to ModalComponent, a full-screen blocking window.
 *
 * Different in that it has a slightly different style (rounded corners), comes with a built-in close button,
 * lays out its content a bit differently (ModalComponent defines the width of its content).
 */
@Component({
  selector: 'latch-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {
  // Emitted when the "X" close button is clicked by the user, or when the user clicks the background
  // (not the modal itself).
  @Output() public closeClicked = new EventEmitter<void>();

  public pageClicked(event: MouseEvent): void {
    // Only emit if the div the event is registered on (currentTarget, the info-modal div) was clicked on directly,
    // not if the user clicked on a child of that div.
    if (event.target === event.currentTarget) {
      this.closeClicked.emit();
    }
  }
}
