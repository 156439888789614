import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  clientSideSortReducer, FilterItem, LatchAnalyticsConstants, LatchAnalyticsService,
  LatchDatasource, LatchNavAction, LatchNavbarStateService
} from '@latch/latch-web';
import { SmartDeviceType } from 'manager/models/device';
import { DeviceVacancySettings } from 'manager/models/enterprise-settings';
import { SpaceType } from 'manager/models/space';
import { UserNotificationCategories, UserNotificationInfo } from 'manager/models/user';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { PermissionsService } from 'manager/services/permissions/permissions.service';
import { SmartHomeService } from 'manager/services/smart-home/smart-home.service';
import { SpaceService, SpaceShortInfo } from 'manager/services/space/space.service';
import { UnitService } from 'manager/services/units/unit.service';
import { UserService } from 'manager/services/user/user.service';
import { BehaviorSubject, EMPTY, of, Subject, zip } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConstantsService } from '../../../../shared/services/constants.service';
import { Unit } from '../../../models/unit';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { cloneDeep, pluralize } from '../../../services/utility/utility';

import { NO_SEARCH_RESULTS } from 'shared/common/constants';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';

enum Tab {
  Units = 'Units',
  VacantUnitSettings = 'Vacant Unit Settings',
  NotificationSettings = 'Notification Settings'
}

enum OccupancyStatus {
  All,
  Occupied,
  Unoccupied
}

interface NavbarFilter {
  occupancyStatus?: OccupancyStatus;
}

@Component({
  selector: 'latch-units-list',
  templateUrl: './units-list-page.component.html',
  styleUrls: ['./units-list-page.component.scss']
})
export class UnitsListComponent implements OnInit, OnDestroy {
  pluralize = pluralize;
  units: Unit[] = [];
  unitSpaces: SpaceShortInfo[] = [];
  filteredUnits: Unit[] = [];

  isLoading = false;

  currentUserCanEditUnits = false;
  currentUserCanViewUnits = false;

  buildingSupportsSmartHome = false;
  buildingSupportsSmartHomeEnterpriseManagement = false;
  buildingUUID!: string;

  Tab = Tab;

  private currentTab$ = new BehaviorSubject(Tab.Units);

  public set currentTab(value: Tab) {
    this.currentTab$.next(value);
  }

  public get currentTab(): Tab {
    return this.currentTab$.value;
  }

  public get emptyListMessage(): string {
    if (this.searchControl.value) {
      return NO_SEARCH_RESULTS;
    }

    switch (this.currentTab) {
      case Tab.NotificationSettings:
        return 'There are no settings to view.';
      case Tab.Units:
        return 'There are no units to view.';
      default:
        return '';
    }
  }

  vacancySettingsDataLoaded = false;
  currentUserHasDeviceManagementPermissions = false;
  editSettingsMode = false;
  buildingHasLights = false;
  buildingHasThermostats = false;
  vacancySettings: DeviceVacancySettings[] = [];
  lightsOff = false;
  hvacOff = false;

  notificationSettingsDataLoaded = false;
  notificationSettings: UserNotificationInfo[] = [];
  notificationSettingsForEdit: UserNotificationInfo[] = [];
  editNotificationSettingsMode = false;

  private unitActions: LatchNavAction[] = [
    {
      id: 'add-unit',
      name: 'Add Unit',
      path: '/console/access/units/add'
    }, {
      id: 'reorder-unit',
      name: 'Reorder',
      path: '/console/access/units/reorder',
    }
  ];

  public actions: LatchNavAction[] = this.unitActions;

  public datasource = new LatchDatasource<Unit>({
    sortReducer: clientSideSortReducer,
  });

  public notificationsDataSource = new LatchDatasource<UserNotificationInfo>({
    sortReducer: clientSideSortReducer,
  });

  private get searchControl(): FormControl {
    return this.navbarStateService.searchControl;
  }

  private occupancyStatusForDisplay = [
    { value: OccupancyStatus.All, name: 'All Units' },
    { value: OccupancyStatus.Occupied, name: 'Occupied' },
    { value: OccupancyStatus.Unoccupied, name: 'Unoccupied' }
  ];

  private navbarFilterValue: NavbarFilter = {
    occupancyStatus: OccupancyStatus.All,
  };

  private unsubscribe$ = new Subject<void>();

  get buildingHasDevices(): boolean {
    return this.buildingHasLights || this.buildingHasThermostats;
  }

  public get countText(): string {
    return this.currentTab === Tab.Units ?
      `(${this.filteredUnits.length})` : '';
  }

  constructor(
    private constants: ConstantsService,
    private unitService: UnitService,
    private permissionService: PermissionsService,
    private selectedBuildingsService: SelectedBuildingsService,
    private errorHandlerService: ErrorHandlerService,
    private analyticsService: LatchAnalyticsService,
    private featureService: FeatureService,
    private smartHomeService: SmartHomeService,
    private router: Router,
    private navbarStateService: LatchNavbarStateService,
    private spaceService: SpaceService,
    private userService: UserService,
    @Optional() public pageWithTabsService?: PageWithTabsService,
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Units List'
    });

    this.isLoading = true;
    this.datasource.startLoading();

    const userMayEditKeys$ = this.permissionService.currentUserMayEditKeys();
    const userMayEditDoors$ = this.permissionService.currentUserMayEditDoors();
    const userMaySeeSomeKeys$ = this.permissionService.currentUserMaySeeSomeKeys();
    zip(userMayEditKeys$, userMayEditDoors$, userMaySeeSomeKeys$).pipe(
      tap(([mayEditKeys, mayEditDoors, maySeeSomeKeys]) => {
        this.currentUserCanEditUnits = mayEditKeys;
        this.currentUserHasDeviceManagementPermissions = mayEditDoors;
        this.currentUserCanViewUnits = maySeeSomeKeys;
      }),
      switchMap(() => this.selectedBuildingsService.getSelectedBuildings()),
      map(buildings => buildings[0].uuid),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: buildingUUID => {
        this.buildingUUID = buildingUUID;
        this.handleInitialPageLoad(buildingUUID);
        if (!this.pageWithTabsService) {
          this.handleToggleTabs(this.buildingSupportsSmartHomeEnterpriseManagement ? Tab.VacantUnitSettings : Tab.NotificationSettings);
        }
      },
      error: error => {
        this.isLoading = false;
        this.datasource.stopLoading();
        this.errorHandlerService.handleException(error);
      }
    });

    this.navbarStateService.initializeSearch({ placeholder: 'Search Units' });
    this.searchControl.valueChanges.pipe(
      debounceTime(this.constants.DebounceTime),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.filter();
    });

    this.initializeFilters();
    this.initializeFiltersSubscription();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFilters(): void {
    this.currentTab$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((currentTab) => {
      const filterItems: FilterItem[] = [];

      if (currentTab === Tab.Units) {
        filterItems.push({
          type: 'radio-group',
          data: this.occupancyStatusForDisplay,
          field: 'occupancyStatus',
          label: 'Occupancy',
          initialValue: OccupancyStatus.All,
        });
      }

      this.navbarStateService.initializeFilter(filterItems);
    });
  }

  private initializeFiltersSubscription(): void {
    this.currentTab$.pipe(
      switchMap((currentTab) =>
        currentTab === Tab.Units ? this.navbarStateService.getFilterValueChange() : EMPTY
      ),
      takeUntil(this.unsubscribe$),
    ).subscribe((value) => {
      this.navbarFilterValue = value;
      this.filter();
    });
  }

  private filter() {
    const search: string = this.searchControl.value;
    const occupancyStatus = this.navbarFilterValue.occupancyStatus ?? OccupancyStatus.All;
    this.filteredUnits = this.units.filter(
      (unit) => unit.name.toLowerCase().includes(search.toLowerCase())
    ).filter(unit => {
      if (occupancyStatus === OccupancyStatus.All) {
        return true;
      }
      const unitSpace = this.unitSpaces.find(space => space.name === unit.name);
      if (!unitSpace) {
        return false;
      }
      switch (occupancyStatus) {
        case (OccupancyStatus.Unoccupied):
          return unitSpace.metadata.occupied === false;
        case (OccupancyStatus.Occupied):
          return unitSpace.metadata.occupied === true;
      }
    });
    this.datasource.set(this.filteredUnits);
    if (this.pageWithTabsService) {
      this.pageWithTabsService.setSubnavSubtitle(`(${this.filteredUnits.length})`);
      this.pageWithTabsService.setSubnavActions(this.actions);
    }
  }

  private handleInitialPageLoad(buildingUUID: string) {
    this.isLoading = true;
    this.datasource.startLoading();

    const units$ = this.currentUserCanViewUnits ? this.unitService.getUnits(buildingUUID) : of([]);
    const unitSpaces$ = this.currentUserCanViewUnits ? this.spaceService.getSpaces(buildingUUID, [SpaceType.ResidentialUnit]).pipe(
      catchError(() => of([]))) : of([]);
    const buildingSupportsEnterpriseManagement$ = this.featureService.hasSmartHomeEnterpriseManagementFeature$.pipe(take(1));
    const buildingSupportsSmartHome$ = this.featureService.hasSmartHomeFeature$.pipe(take(1));

    zip(units$, unitSpaces$, buildingSupportsEnterpriseManagement$, buildingSupportsSmartHome$).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: ([units, unitSpaces, buildingSupportsEnterpriseManagement, buildingSupportsSmartHome]) => {
        this.isLoading = false;
        this.datasource.stopLoading();
        this.units = units;
        this.unitSpaces = unitSpaces;
        this.buildingSupportsSmartHomeEnterpriseManagement = buildingSupportsEnterpriseManagement;
        this.buildingSupportsSmartHome = buildingSupportsSmartHome;
        this.filteredUnits = this.units;
        this.filter();
      },
      error: error => {
        this.isLoading = false;
        this.datasource.stopLoading();
        this.errorHandlerService.handleException(error);
      }
    });
  }

  resetSettings() {
    this.lightsOff = this.vacancySettings.find(settings => settings.smartDeviceType === SmartDeviceType.Light)?.isOff ?? false;

    this.hvacOff = this.vacancySettings.find(settings => settings.smartDeviceType === SmartDeviceType.Thermostat)?.isOff ?? false;
  }

  resetNotificationSettings() {
    this.notificationSettingsForEdit = cloneDeep(this.notificationSettings);
    this.notificationsDataSource.set(this.notificationSettingsForEdit);
  }

  handleCancelEditSettings() {
    this.resetSettings();
    this.cancelEdit();
  }

  handleCancelEditNotificationSettings() {
    this.resetNotificationSettings();
    this.cancelEditNotificationSettings();
  }

  handleLoadVacancySettings() {
    if (this.vacancySettingsDataLoaded) {
      return;
    }
    this.isLoading = true;
    this.datasource.startLoading();

    const shouldFetchDeviceAndVacancySettingsInfo =
      this.buildingSupportsSmartHomeEnterpriseManagement && this.currentUserHasDeviceManagementPermissions;

    const devices$ = shouldFetchDeviceAndVacancySettingsInfo ?
      this.smartHomeService.getDevices(this.buildingUUID).pipe(catchError(() => of([]))) : of([]);
    const vacancySettings$ = shouldFetchDeviceAndVacancySettingsInfo ?
      this.smartHomeService.getSmartHomeSettingsForSpace(this.buildingUUID).pipe(catchError(() => of([]))) : of([]);

    zip(devices$, vacancySettings$).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([devices, vacancySettings]) => {
      this.isLoading = false;
      this.datasource.stopLoading();
      this.vacancySettings = vacancySettings;
      this.resetSettings();
      this.buildingHasLights = devices.filter(device => device.deviceType === SmartDeviceType.Light).length > 0;
      this.buildingHasThermostats = devices.filter(device => device.deviceType === SmartDeviceType.Thermostat).length > 0;
      this.vacancySettingsDataLoaded = true;
    }, error => {
      this.isLoading = false;
      this.datasource.stopLoading();
      this.errorHandlerService.handleException(error);
    });
  }

  handleLoadNotificationSettings() {
    if (this.notificationSettingsDataLoaded) {
      return;
    }
    this.isLoading = true;
    this.notificationsDataSource.startLoading();

    this.featureService.hasSmartHomeFeature$.pipe(
      take(1),
      switchMap(buildingSupportsSmartHome => {
        if (buildingSupportsSmartHome && this.currentUserHasDeviceManagementPermissions) {
          return this.userService.getNotificationSettings();
        } else {
          return of([]);
        }
      }),
      catchError(() => of([])),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: notificationSettings => {
        this.notificationSettings = cloneDeep(notificationSettings.filter(setting => this.notificationCategoryIsSupported(setting)));
        this.notificationSettingsForEdit = cloneDeep(this.notificationSettings);
        this.notificationSettingsDataLoaded = true;
        this.isLoading = false;
        this.notificationsDataSource.set(this.notificationSettingsForEdit);
        this.notificationsDataSource.stopLoading();
      },
      error: error => {
        this.isLoading = false;
        this.notificationsDataSource.stopLoading();
        this.errorHandlerService.handleException(error);
      }
    });
  }

  handleToggleTabs(tab: string) {
    if (tab === Tab.Units) {
      this.searchControl.enable();
    } else {
      this.searchControl.disable();
    }
    if (this.currentTab === Tab.VacantUnitSettings && tab !== Tab.VacantUnitSettings) {
      this.handleCancelEditSettings();
    }
    if (tab === Tab.VacantUnitSettings) {
      this.handleLoadVacancySettings();
    }
    if (this.currentTab === Tab.NotificationSettings && tab !== Tab.NotificationSettings) {
      this.handleCancelEditNotificationSettings();
    }
    if (tab === Tab.NotificationSettings) {
      this.handleLoadNotificationSettings();
    }
    this.currentTab = tab as Tab;
    this.updateActions();
  }

  updateActions(): void {
    if (this.currentTab === Tab.Units) {
      this.actions = this.unitActions;
    } else if (this.currentTab === Tab.VacantUnitSettings && !this.editSettingsMode) {
      this.actions = [
        {
          id: 'edit-unit-settings',
          name: 'Edit',
          clickHandler: () => {
            if (this.currentUserHasDeviceManagementPermissions && this.buildingHasDevices) {
              this.enableEdit();
            }
          },
          tooltip: {
            enabled: !this.currentUserHasDeviceManagementPermissions,
            text: 'Only Portfolio Managers and Property Managers with ‘Device Management’ permission may edit vacant unit settings.',
            fixed: false,
            hover: false,
            inverted: false,
          }
        },
      ];
    } else if (this.currentTab === Tab.VacantUnitSettings && this.editSettingsMode) {
      this.actions = [
        {
          id: 'cancel-unit-settings',
          name: 'Cancel',
          customClasses: ['latch-button-link'],
          primary: true,
          clickHandler: () => this.handleCancelEditSettings(),
        },
        {
          id: 'save-unit-settings',
          name: 'Save',
          primary: true,
          clickHandler: () => this.handleSave(),
        },
      ];
    } else if (this.currentTab === Tab.NotificationSettings) {
      if (!this.editNotificationSettingsMode) {
        this.actions = [
          {
            id: 'edit-notification-settings',
            name: 'Edit',
            clickHandler: () => {
              this.enableEditNotifications();
            }
          },
        ];
      } else {
        this.actions = [
          {
            id: 'cancel-notification-settings',
            name: 'Cancel',
            customClasses: ['latch-button-link'],
            primary: true,
            clickHandler: () => this.handleCancelEditNotificationSettings(),
          },
          {
            id: 'save-notification-settings',
            name: 'Save',
            primary: true,
            clickHandler: () => this.handleSaveNotifications(),
          },
        ];
      }
    }
    if (this.pageWithTabsService) {
      this.pageWithTabsService.setSubnavSubtitle(`(${this.filteredUnits.length})`);
      this.pageWithTabsService.setSubnavActions(this.actions);
    }
  }

  handleToggleNotificationSmsCheck(notification: UserNotificationInfo) {
    const info = this.notificationSettingsForEdit.find(n => n.name === notification.name);
    if (info) {
      info.values.SMS = !info.values.SMS;
    }
  }

  handleToggleNotificationPushNotificationsCheck(notification: UserNotificationInfo) {
    const info = this.notificationSettingsForEdit.find(n => n.name === notification.name);
    if (info) {
      info.values.PUSH_NOTIFICATION = !info.values.PUSH_NOTIFICATION;
    }
  }

  public onRowClick(unit: Unit): void {
    this.router.navigate(['/console/access/units', unit.id], { queryParamsHandling: 'preserve' });
  }

  handleSave() {
    this.isLoading = true;
    this.datasource.startLoading();
    const newSettings: DeviceVacancySettings[] = [
      {
        smartDeviceType: SmartDeviceType.Light,
        isOff: this.buildingHasLights ? this.lightsOff : false
      },
      {
        smartDeviceType: SmartDeviceType.Thermostat,
        isOff: this.buildingHasThermostats ? this.hvacOff : false
      }
    ];

    this.smartHomeService.createOrUpdateDeviceVacancySettingsForSpace(this.buildingUUID, newSettings)
      .subscribe(settings => {
        this.vacancySettings = settings;
        this.resetSettings();
        this.isLoading = false;
        this.datasource.stopLoading();
        this.cancelEdit();
      }, error => {
        this.isLoading = false;
        this.datasource.stopLoading();
        this.errorHandlerService.handleException(error);
      });
  }

  handleSaveNotifications() {
    this.isLoading = true;
    this.notificationsDataSource.startLoading();
    this.userService.updateNotificationSettings(this.notificationSettingsForEdit).subscribe(settings => {
      this.notificationSettings = cloneDeep(settings);
      this.handleCancelEditNotificationSettings();
      this.notificationsDataSource.stopLoading();
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.notificationsDataSource.stopLoading();
      this.errorHandlerService.handleException(error);
    });

  }

  private enableEdit(): void {
    this.editSettingsMode = true;
    this.updateActions();
  }

  private enableEditNotifications(): void {
    this.editNotificationSettingsMode = true;
    this.updateActions();
  }

  private cancelEdit(): void {
    this.editSettingsMode = false;
    this.updateActions();
  }

  private cancelEditNotificationSettings(): void {
    this.editNotificationSettingsMode = false;
    this.updateActions();
  }

  public getOccupancyStatusValue(item: { value: OccupancyStatus; }): OccupancyStatus {
    return item.value;
  }

  public getOccupancyStatusDisplay(item: { display: string; }): string {
    return item.display;
  }

  public notificationCategoryIsSupported(info: UserNotificationInfo): boolean {
    return Object.values(UserNotificationCategories).includes(info.name);
  }

  public getNotificationPrimaryTitle(info: UserNotificationInfo): string | null {
    switch (info.name) {
      case UserNotificationCategories.UserLeakNotifications:
        return 'Leak Notifications';
      default:
        return null;
    }
  }

  public getNotificationSecondaryTitle(category: UserNotificationInfo): string | null {
    switch (category.name) {
      case UserNotificationCategories.UserLeakNotifications:
        return 'Leak detected in units';
      default:
        return null;
    }
  }

  public getNotificationSmsCheck(info: UserNotificationInfo): boolean {
    // response represents if user is opted out or not, but UI represents whether user is opted in or not
    // e.g., SMS = true means user is opted out of SMS
    return !info.values.SMS;
  }

  public getNotificationPushNotificationsCheck(info: UserNotificationInfo): boolean {
    // response represents if user is opted out or not, but UI represents whether user is opted in or not
    // e.g., SMS = true means user is opted out of SMS
    return !info.values.PUSH_NOTIFICATION;
  }
}
