export enum PersonDetailPageTab {
  Profile = 'PROFILE',
  Keys = 'KEYS',
  Doors = 'DOORS',
  Guests = 'GUESTS'
}

export enum KeyDetailPageTab {
  Info = 'Info',
  Owners = 'Owners',
  Admins = 'Admins',
  Doors = 'Doors',
  People = 'People'
}

export enum DoorDetailPageTab {
  Door = 'DOOR',
  Intercom = 'INTERCOM',
  VirtualIntercomTab = 'VIRTUAL-INTERCOM',
  Keys = 'KEYS',
  People = 'PEOPLE'
}
