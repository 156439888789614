<latch-card class="page">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="cancel.emit()">
        <span class="latch-icon-chevron-left"></span>
      </button>
    </div>
    <div class="latch-card-title">{{ floorGroupName }}</div>
    <input type="text" placeholder="Search" class="latch-card-search"
      [(ngModel)]="search" />
  </ng-container>

  <ng-container latchCardContent>
    <div class="cell-list">
      <div class="cell cell-row"
        *ngFor="let floor of floors | filterStringList: search">
        <div class="cell-text">
          <div class="text-1">
            {{floor}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</latch-card>
