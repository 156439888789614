<latch-subnav headerTitle="Resources"></latch-subnav>
<div class="latch-viewport">
  <div class="resources-page-title">
    Find resources below
  </div>
  <div class="resources-page-description">
    Find resources to help you get the most out of the Latch experience, and
    to
    assist your residents.
  </div>
</div>
<div class="resource-cards latch-viewport" #cardRow>
  <a class="resource-card" *ngFor="let resource of resources"
    [href]="resource.link" target="_blank">
    <img [src]=resource.image alt="image" class="resource-card-image" />
    <div class="resource-card-text-area">
      <div class="resource-card-title">
        {{resource.title}}
        <a class="latch-button-link external">
          <div class="icon-external-link-blue"></div>
        </a>
      </div>
      <div class="resource-card-subtext">
        {{resource.subtext}}
      </div>
      <button class="latch-button-outline latch-dark">
        Learn More
      </button>
    </div>
  </a>

  <latch-scroll-arrows class="latch-viewport" [scrollContainer]="cardRow">
  </latch-scroll-arrows>
</div>

<div class="video-guide-container latch-viewport">
  <div class="video-guide-title">
    Video Guides
  </div>
  <div class="video-guide-description">
    Find resources to help you get the most out of the Latch experience, and to
    assist your residents.
  </div>

  <div class="video-grid">
    <div class="video-item"
      *ngFor="let videoGuide of videoGuides; let index = index"
      (mouseenter)="onVideoGuideHoverStart(videoGuide, index)"
      (mouseleave)="onVideoGuideHoverStop(index)">
      <video #video class="video-item-preview" [src]="videoGuide.preview">
      </video>
      <div class="video-item-content"
        [style.visibility]="videoGuide === currentlyPlaying ? 'hidden' : ''">
        <div class="video-item-content-text">
          <span class="video-item-title">
            {{ videoGuide.title }}
          </span>
          <span class="video-item-description">
            {{ videoGuide.description }}
          </span>
        </div>
        <img class="video-item-image" *ngIf="videoGuide.image"
          [src]="videoGuide.image" />
      </div>
      <button class="latch-button latch-button-primary video-item-play-button"
        (click)="onPlayClick(videoGuide)">
        {{ videoGuide === hoveringVideoGuide ?
        'Play Full Video' : 'Play Video' }}
      </button>
      <youtube-player *ngIf="videoGuide === currentlyPlaying"
        [playerVars]="playerVars" [videoId]="videoGuide.videoId">
      </youtube-player>
    </div>
  </div>
</div>
