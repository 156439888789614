import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'latch-recent-logins-page',
  templateUrl: './recent-logins-page.component.html',
  styleUrls: ['./recent-logins-page.component.scss']
})
export class RecentLoginsPageComponent {

  constructor(
    private router: Router
  ) { }

  onCancelClick() {
    this.router.navigate(['/console/account'], { queryParamsHandling: 'preserve' });
  }
}
