import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';

import { Unit, UpdateUnitOperationResult } from '../../models/unit';
import { ServiceResponse } from '../interfaces';
import { concatMap, toArray, switchMap, map } from 'rxjs/operators';
import { assertIsDefined } from '@latch/latch-web';
import { Space } from 'manager/models/space';
import { Person } from '../../models/contact-cards';

export const DUPLICATE_UNIT_NAME = 'DUPLICATE_UNIT_NAME';
/**
 * Max unit name length that clients should allow.
 *
 * Note that this is NOT enforced by the service or backend; it's up to clients to
 * enforce this max length.
 */
export const MAX_UNIT_NAME_LENGTH = 20;

export interface CreateUnitInput {
  name: string;
  localTelecom?: string;
  buildingUUID: string;
}

export interface UpdateUnitInput {
  id: number;
  name: string;
  localTelecom?: string;
}

export interface ReorderUnitInput {
  id: number;
  position: number;
}

export interface UnitDetailsResponse {
  id: number;
  name: string;
  localTelecom?: string;
  space?: Space
}

@Injectable()
export abstract class UnitService {
  abstract getUnits(buildingUUID: string): Observable<Unit[]>;

  abstract getUnitDetails(unitUUID: string, buildingUUID: string): Observable<UnitDetailsResponse>;

  abstract createUnit(input: CreateUnitInput): Observable<Unit>;


  /**
   * Method to bulk create units (used for CSV import). This will skip
   * creating units with names that are already in use in the building.
   * Every CreateUnitInput in the input param must have the same buildingUUID,
   * which must match the buildingUUID param.
   * All units in the input param will be at the beginning of the units list,
   * in the order specified, and any existing units not in the input param will
   * be after them, with the current order preserved.
   * This method will also edit unit PBX information for existing units in the input param if the editPbx flag is on.
   * Method returns only the newly created units, excludes units attempted to
   * be created with names which already existed in the building.
   */
  createUnits(input: CreateUnitInput[], buildingUUID: string, editPbx: boolean): Observable<Unit[]> {
    if (input.length === 0) {
      return of([]);
    }// guard against empty array

    let existingUnits: Unit[];
    let newUnits: Unit[];
    let existingUnitNames: string[];

    return this.getUnits(buildingUUID).pipe(
      switchMap(units => {
        // Filters out units in the input with a name that already exists in the building.
        existingUnits = units;
        existingUnitNames = existingUnits.map(unit => unit.name.toLowerCase());
        return input.filter(unit => existingUnitNames.indexOf(unit.name.toLowerCase()) < 0);
      }),
      concatMap(unit => this.createUnit(unit)),
      toArray(),
      switchMap(createdUnits => {
        // Reorders all units in the initial input array.
        newUnits = createdUnits;
        const allUnits = [...existingUnits, ...createdUnits];
        const reorderUnitInput: ReorderUnitInput[] = input.map((inputUnit, index) => {
          const id = allUnits.find(unit => unit.name.toLowerCase() === inputUnit.name.toLowerCase())?.id;
          assertIsDefined(id);
          return {
            id,
            position: index
          };
        });
        return this.reorderUnits(reorderUnitInput);
      }),
      switchMap(() => editPbx ? input.filter(unit => existingUnitNames.indexOf(unit.name.toLowerCase()) >= 0) : from([])),
      concatMap(unit => {
        const foundUnit = existingUnits.find(existingUnit => existingUnit.name.toLowerCase() === unit.name.toLowerCase());
        assertIsDefined(foundUnit);
        if (foundUnit.localTelecom === unit.localTelecom) {
          return of(foundUnit);
        }
        return this.updateUnit({
          id: foundUnit.id,
          name: foundUnit.name,
          localTelecom: unit.localTelecom
        });
      }),
      toArray(),
      map(() => newUnits)
    );
  }

  abstract updateUnit(input: UpdateUnitInput): Observable<UpdateUnitOperationResult>;

  abstract reorderUnit(input: ReorderUnitInput): Observable<Unit>;

  /**
   * ReorderUnitInputs are executed in the order specified.
   * Failure on a single request will interrupt the stream of requests; the caller
   * shouldn't make any assumptions as to what the state of the final order will be.
   * A successful request will return all of the units that were reordered.
   */
  reorderUnits(input: ReorderUnitInput[]): Observable<Unit[]> {
    return from(input).pipe(
      concatMap(request => this.reorderUnit(request)),
      toArray()
    );
  }

  abstract deleteUnit(unitUUID: number): Observable<ServiceResponse>;

  abstract getUnitResidents(buildingUUID: string, unitId: number): Observable<Person[]>;
}
