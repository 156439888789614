<ng-container *ngIf="!isLoading" [ngSwitch]="failure.operationType">
  <ng-container *ngSwitchCase="OPERATION_TYPES.CREATE_KEY">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.MAX_DOORS_PER_KEY">
        Key <b>{{ failureKeyName }}</b> could not be created because you have specified too many Doors.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.LOCK_DOES_NOT_EXIST">
        Key <b>{{ failureKeyName }}</b> could not be created because one of the specified Doors no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.FLOOR_GROUP_NOT_FOUND">
        Key <b>{{ failureKeyName }}</b> could not be created because the selected floor group for elevator
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a> no longer exists.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to create a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.UPDATE_KEY">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        A Key could not be updated because it no longer exists.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to update a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.DELETE_KEY">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        A Key could not be deleted because it no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_HAS_EXISTING_MEMBERSHIPS">
        Key <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a> could not be deleted
        because some users still have access.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to delete a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.CREATE_KEY_DOOR">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        A Door could not be added to a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_EXPIRED">
        A Door could not be added to a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.MAX_DOORS_PER_KEY">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because the Key already has the maximum number of Doors.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.DUPLICATE_LOCK_FOR_KEYS">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        already exists on Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.MAX_ACCESSES">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        has too many users.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_KEY_DOOR_CREDENTIALS">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>.
        Please edit their existing access.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_SCHEDULES">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different schedule.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_DOORCODE_SETTINGS">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different Doorcode setting.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_FLOOR_GROUPS">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different floor group.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.FLOOR_GROUP_NOT_FOUND">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because the selected floor group for
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        no longer exists.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to add a Door to a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.UPDATE_KEY_DOOR">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        A Door could not be updated on a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_EXPIRED">
        A Door could not be updated on a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_DOORCODE_SETTINGS">
        Key
        <a [routerLink]="['/console/keys', key.uuid]"
          [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
          [routerLink]="['/console/people', person.userUUID]" [queryParams]="queryParams" (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to the Door
        <a [routerLink]="['/console/doors', lock.uuid]"
          [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different Doorcode setting.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_SCHEDULES">
        Key
        <a [routerLink]="['/console/keys', key.uuid]"
          [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        already has access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different schedule.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to update a Door on a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.DELETE_KEY_DOOR">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        A Door could not be deleted from a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_EXPIRED">
        A Door could not be deleted from a Key because the Key no longer exists.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to delete a Door from a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.CREATE_KEY_MEMBERSHIP">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_DOES_NOT_EXIST">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to a Key because it no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_EXPIRED">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to a Key because it no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.INVALID_ACCESS_START_TIME">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>.
        Please try again.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.KEY_MEMBERSHIP_ALREADY_EXISTS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because they already have access.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.LOCK_NOT_ASSIGNED_DEVICE">
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        must be activated before
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        can be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.FLOOR_GROUP_NOT_FOUND">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because the selected floor group for elevator
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.NO_DOORCODE_FLOOR_GROUP">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because elevator
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        no longer supports daily Doorcodes.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.MAX_ACCESSES">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because the Door
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        has too many users.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_KEY_DOOR_CREDENTIALS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because they already have access to the Door
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>.
        Please edit their existing access.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_SCHEDULES">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because they already have access to the Door
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different schedule.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_DOORCODE_SETTINGS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because they already have access to the Door
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different Doorcode setting.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_FLOOR_GROUPS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>
        could not be added to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        because they already have access to elevator
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        with a different floor group.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to add a person to a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.UPDATE_KEY_MEMBERSHIP">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchCase="ERRORS.KEY_MEMBERSHIP_DOES_NOT_EXIST">
        A Key membership could not be updated because it no longer exists.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.INVALID_ACCESS_START_TIME">
        The start of
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>’s
        access to Key <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because it has already started.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.INVALID_ACCESS_END_TIME">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>’s
        access to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because the new time is in the past.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_KEY_DOOR_CREDENTIALS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>’s
        access to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because they already have access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        on a different Key. Please edit their existing access.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_SCHEDULES">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>’s
        access to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because they already have access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        on a different Key with a different schedule.
      </ng-container>

      <ng-container *ngSwitchCase="ERRORS.OVERLAPPING_FLOOR_GROUPS">
        <a *ngIf="canLinkToPersonDetail; else canNotLinkToPerson"
           [routerLink]="['/console/people', person.userUUID]"
           [queryParams]="queryParams"
           (click)="triggerClose($event)">
          {{person.userFirstName}} {{person.userLastName}}
        </a>’s
        access to Key
        <a [routerLink]="['/console/keys', key.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{key.name}}</a>
        could not be updated because they already have access to
        <a [routerLink]="['/console/doors', lock.uuid]" [queryParams]="queryParams" (click)="triggerClose($event)">{{lock.name}}</a>
        on a different Key with a different floor group.
      </ng-container>

      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to update a person’s access to a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OPERATION_TYPES.REMOVE_KEY_MEMBERSHIP">
    <ng-container [ngSwitch]="errorMessage">
      <ng-container *ngSwitchDefault>
        Something unexpected happened when trying to revoke a person’s access to a Key. If this problem persists, please contact support.
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #canNotLinkToPerson>
  <b>{{person.userFirstName}} {{person.userLastName}}</b>
</ng-template>
