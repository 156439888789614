import { OnInit, Input, EventEmitter, Output, Directive } from '@angular/core';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { ElevatorDetails } from 'manager/models/elevator-details';
import { FloorGroup } from 'manager/models/floor-group';
import { cloneDeep } from 'manager/services/utility/utility';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FloorGroupListBaseComponent implements OnInit {
  floorGroups: FloorGroup[] = [];
  search = '';
  filter: any;
  _elevatorDetails!: ElevatorDetails;
  viewingFloorGroup: FloorGroup | undefined;
  @Input() elevatorName = '';
  @Output() cancel = new EventEmitter<void>();

  constructor(protected analyticsService: LatchAnalyticsService) { }

  trackPageView(): void {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, this.getPageTrackingInfo());
  }

  abstract getPageTrackingInfo(): Record<string, unknown>;

  ngOnInit() {
    this.trackPageView();
    this.filter = FloorGroup.filter();
  }

  @Input()
  set elevatorDetails(elevatorDetails: ElevatorDetails | undefined) {
    if (elevatorDetails) {
      this._elevatorDetails = elevatorDetails;
      this.floorGroups = cloneDeep(elevatorDetails.floorGroups);
    }
  }

  viewFloorGroup(floorGroup: FloorGroup): void {
    this.viewingFloorGroup = floorGroup;
  }

  stopViewingFloorGroup(): void {
    this.viewingFloorGroup = undefined;
  }
}
