import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AnnouncementService, Announcement } from '../../services/appstate/announcement.service';

@Component({
  selector: 'latch-keys-announcement',
  templateUrl: './keys-announcement.component.html',
  styleUrls: ['./keys-announcement.component.scss']
})
export class KeysAnnouncementComponent implements OnInit {

  shouldShow = false;

  constructor(
    private router: Router,
    private announcementService: AnnouncementService
  ) { }

  ngOnInit() {
    this.shouldShow = this.announcementService.shouldShow(Announcement.Keys);
  }

  handleClose() {
    this.shouldShow = false;
    this.announcementService.markAsSeen(Announcement.Keys);
  }

  handleOpenKeys() {
    this.handleClose();
    this.router.navigate(['/console/keys'], {
      queryParamsHandling: 'preserve'
    });
  }

}
