import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, zip } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  ContactPreference,
  ContactPreferenceSelect,
  FeaturedEntry,
  FeaturedEntryCandidate,
  FeaturedEntryStep
} from '../../../models/featured-entry';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { FeaturedEntryService } from '../../../services/featured-entry/featured-entry.service';

@Component({
  selector: 'latch-view-featured-entry',
  templateUrl: './view-featured-entry.component.html',
  styleUrls: ['./view-featured-entry.component.scss']
})
export class ViewFeaturedEntryComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public buildingUUID!: string;
  public currentStep = FeaturedEntryStep.FeaturedEntryDetails;
  public FeaturedEntryStep = FeaturedEntryStep;
  public displayName = '';
  public selectedCandidate: FeaturedEntryCandidate | null = null;
  public contactPreference: ContactPreference = ContactPreference.APP;
  public contactPreferenceSelect: ContactPreferenceSelect[] = [
    {
      name: 'Latch App',
      value: ContactPreference.APP
    },
    {
      name: 'Phone',
      value: ContactPreference.PHONE
    }
  ];
  public featuredEntry!: FeaturedEntry;
  public isEditing = false;

  private featuredEntryId!: number;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private featuredEntryService: FeaturedEntryService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    const buildingUUID$ = this.selectedBuildingsService.getSelectedBuildings().pipe(map(buildings => buildings[0].uuid));
    const featuredEntryId$ = this.activatedRoute.params.pipe(map(params => params.featuredEntryId));
    zip(buildingUUID$, featuredEntryId$).pipe(
      switchMap(([buildingUUID, featuredEntryId]) => {
        this.buildingUUID = buildingUUID;
        this.featuredEntryId = parseInt(featuredEntryId, 10);
        return this.featuredEntryService.getBuildingFeaturedEntry(this.buildingUUID, this.featuredEntryId);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(featuredEntry => {
      this.featuredEntry = featuredEntry;
      this.displayName = this.featuredEntry.displayName;
      this.contactPreference = this.featuredEntry.contactPreference;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCurrentStep(currentStep: FeaturedEntryStep): void {
    this.currentStep = currentStep;
  }

  setSelectedCandidate(selectedCandidate: FeaturedEntryCandidate): void {
    this.selectedCandidate = selectedCandidate;
    this.setCurrentStep(FeaturedEntryStep.FeaturedEntryDetails);
  }

  clearSelectedCandidate(): void {
    this.selectedCandidate = null;
  }

  handleEdit(): void {
    this.isEditing = true;
    this.displayName = this.featuredEntry.displayName;
    this.contactPreference = this.featuredEntry.contactPreference;
    this.setSelectedCandidate({
      contactCardUUID: this.featuredEntry.contactCardUUID,
      hasAccess: this.featuredEntry.hasAccess,
      isContactCardActive: this.featuredEntry.isContactCardActive,
      firstName: this.featuredEntry.firstName,
      lastName: this.featuredEntry.lastName,
      phoneNumber: this.featuredEntry.phoneNumber
    });
  }

  handleCancel(): void {
    this.isEditing = false;
    this.displayName = this.featuredEntry.displayName;
    this.contactPreference = this.featuredEntry.contactPreference;
  }

  deleteFeaturedEntry(): void {
    this.isLoading = true;
    this.featuredEntryService.deleteFeaturedEntry(this.featuredEntry, this.buildingUUID).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['/console/intercom-settings'], { queryParamsHandling: 'preserve' });
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  handleSubmit(): void {
    if (!this.canSubmit()) {
      return;
    }
    this.isLoading = true;
    this.featuredEntryService.updateFeaturedEntry(
      {
        displayName: this.displayName,
        contactCardUUID: this.selectedCandidate.contactCardUUID,
        contactPreference: this.contactPreference,
        order: this.featuredEntry.order
      },
      this.buildingUUID,
      this.featuredEntryId
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(featuredEntry => {
      this.isLoading = false;
      this.featuredEntry = featuredEntry;
      this.handleCancel();
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  canSubmit(): this is { displayName: string; selectedCandidate: FeaturedEntryCandidate; contactPreference: ContactPreference } {
    return !!this.displayName && !!this.selectedCandidate && !!this.contactPreference;
  }

}
