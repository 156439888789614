import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ListPartnersResponse, PartnerService } from './partner.service';
import { AuthService } from '../auth/auth.service';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable()
export class HTTPPartnerService extends PartnerService {

  constructor(private authService: AuthService) {
    super();
  }

  public getPartners(buildingUUID: string): Observable<ListPartnersResponse> {

      return this.authService.request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/partners`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }
}
