import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';

import {
  SyncTask,
  SyncState,
  LockOperationSyncStatus,
  LockOperationSyncOutcome
} from '../../models/sync-status';
import { reduce, mergeMap, map, delay, switchMap } from 'rxjs/operators';
import { chunk } from '../utility/utility';

// Backend times out after 5 minutes
export const SYNC_STATUS_POLL_BACKEND_TIMEOUT = 5 * 60 * 1000;
export const SYNC_STATUS_POLL_INTERVAL = 1000;
// Backend should always timeout before we do
export const SYNC_STATUS_POLL_DURATION = SYNC_STATUS_POLL_BACKEND_TIMEOUT + 60 * 1000;
export const SYNC_STATUS_POLL_MAX_TRIES = SYNC_STATUS_POLL_DURATION / SYNC_STATUS_POLL_INTERVAL;

export const ESTIMATED_ACCESSES_SYNCED_PER_SECOND = 6;

// There are cases where we may need to poll many sync tasks at once (e.g. CSV import).
// Currently backend rejects GET requests longer than 2048 characters. A constant of 30 will
// ensure that the query param portion of the URL is no longer than 46 * 30 = 1380.
export const MAX_TASK_UUIDS_IN_QUERY_PARAMS = 30;

// Given a collection of sync tasks with different states (and potential race-conditions between them),
// which would make most sense to show as the lock's ultimate sync status?
const SYNC_STATE_SIGNIFICANCE = [
  // If any in the set are pending install, it's probably safe to assume all should be pending install
  SyncState.PendingInstall,
  // If we see synced by device, assume it took care of all others in the collection
  SyncState.SyncedByDevice,
  // A scheduled sync can probably be depended on to take of any others
  SyncState.SyncScheduled,
  // If there's nothing that will automatically sync, a user sync is still better than an admin task
  SyncState.PendingUserSync,
  // Only conclude we need an admin task as a last resort
  SyncState.PendingAdminSync,
  // This would mean a sync completed, but very ambiguous as to whether it took care of the rest in the collection
  SyncState.SyncedByMobile
];
export const syncStateSort = (state: SyncState) =>
  SYNC_STATE_SIGNIFICANCE.indexOf(state);

@Injectable()
export abstract class SyncStatusService {
  abstract getSyncTasks(syncTaskUUIDs: string[]): Observable<SyncTask[]>;

  pollLockOperationSyncStatus(lockSyncStatus: LockOperationSyncStatus, tryCount = 0): Observable<LockOperationSyncOutcome> {
    const toPoll = lockSyncStatus.syncTasks.filter(syncTask =>
      syncTask.state === SyncState.SyncingOnline
    );

    // If backend is not trying to communicate with a lock, we're done polling
    if (toPoll.length === 0) {
      return of({
        lockUUID: lockSyncStatus.lockUUID,
        lockName: lockSyncStatus.lockName,
        syncState: lockSyncStatus.syncTasks
          .map(({ state }) => state)
          .sort(syncStateSort)[0]
      });
    }

    // Backend should always time out before we hit this, but just in case, assume admin task
    if (tryCount >= SYNC_STATUS_POLL_MAX_TRIES) {
      return of({
        lockUUID: lockSyncStatus.lockUUID,
        lockName: lockSyncStatus.lockName,
        syncState: SyncState.PendingAdminSync
      });
    }

    const chunks = chunk(toPoll.map(item => item.uuid), MAX_TASK_UUIDS_IN_QUERY_PARAMS);

    return from(chunks).pipe(
      mergeMap((syncTaskUUIDs) => this.getSyncTasks(syncTaskUUIDs)),
      reduce((all, chunkItem) => all.concat(chunkItem)),
      map((syncTasks) => ({
        ...lockSyncStatus,
        syncTasks: syncTasks.map((syncTask) => ({ uuid: syncTask.uuid, state: syncTask.state }))
      })),
      delay(SYNC_STATUS_POLL_INTERVAL),
      switchMap((nextOperationStatus) => this.pollLockOperationSyncStatus(nextOperationStatus, tryCount + 1))
    );
  }

}
