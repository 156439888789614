interface JsonObject {
  [key: string]: Json
}

type Json =
  | string
  | number
  | boolean
  | null
  | JsonObject
  | Json[];

const idPropertyNameRegex = /\b(id|uuid)|(.*(Id|UUID))$/;
const idArrayPropertyNameRegex = /\b(ids|uuids)|(.*(Ids|UUIDs))$/;
const uuidValueRegex = /\b[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

/**
 * Transform all of the id | uuid related properties to lowercase looking
 * recursively throughout the entire object.
 * Creates shallow copy of the object
 * @param obj Json object containing id | uuid properties to become lowercased
 * @returns shallow copy of the same object with lowercase id | uuid properties
 */
export function transformIdsToLowercase(obj: Json): Json {
  if (Array.isArray(obj)) {
    return obj.map(item => transformIdsToLowercase(item));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc: JsonObject, [key, val]) => {
      if (typeof val === 'string' && idPropertyNameRegex.test(key) && uuidValueRegex.test(val)) {
        acc[key] = val.toLowerCase();
      } else if (Array.isArray(val) && idArrayPropertyNameRegex.test(key)) {
        acc[key] = val.map(item => {
          if (typeof item === 'string' && uuidValueRegex.test(item)) {
            return item.toLowerCase();
          }
          return item;
        });
      } else {
        acc[key] = transformIdsToLowercase(val);
      }
      return acc;
    }, {});
  }
  return obj;
}
