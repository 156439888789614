<div class="upgrade-header">
  <button class="latch-button-icon" (click)="onCloseClick()">
    <span class="latch-icon-close"></span>
  </button>
  <h2 class="upgrade-title">
    {{ data.title }}
  </h2>
</div>

<div class="upgrade-content">
  <span class="upgrade-content-title" *ngIf="data.learnMoreTitle">
    {{ data.learnMoreTitle }}
  </span>
  <p class="upgrade-content-text">
    {{ data.content }}
  </p>
</div>

<div class="upgrade-footer">
  <button class="latch-button" (click)="onSubmitClick()">
    Submit a Request
  </button>
</div>

<ng-template #requestSubmittedDialog>
  <latch-icon-message-dialog message="Request submitted"
    text="We will get back to you soon." iconClass="latch-icon-confirm">
  </latch-icon-message-dialog>
</ng-template>
