<ng-container *ngIf="tabPathMatchFull && showPageContent">
  <latch-subnav headerTitle="Staff" [subtitle]="subnavSubtitle" [actions]="subnavActions"></latch-subnav>

  <latch-tab-group class="latch-tab-with-viewport" [selected]="activeTab" (tabClicked)="onTabClick($event)">
    <latch-tab [id]="Tab.PortfolioManagers">Portfolio Managers</latch-tab>
    <latch-tab [id]="Tab.PropertyManagers">Property Managers</latch-tab>
    <latch-tab *ngIf="hasServiceOrdersFeature" [id]="Tab.MaintenanceWorkers">Maintenance Workers</latch-tab>
  </latch-tab-group>
</ng-container>

<router-outlet></router-outlet>
