import { Component } from '@angular/core';
import { LatchMenuItem } from '@latch/latch-web';
import { SprigService } from 'manager/services/sprig/sprig.service';

@Component({
  selector: 'latch-get-help-button',
  templateUrl: './get-help-button.component.html',
  styleUrls: ['./get-help-button.component.scss']
})
export class GetHelpButtonComponent {

  public menuItems: LatchMenuItem[] = [
    { name: 'Get Help / Resources' },
    { name: 'Contact Support' },
    { name: 'Submit Feedback' },
  ];

  public isOpen = false;

  constructor(
    private sprigService: SprigService,
  ) { }

  public onClick(): void {
    this.isOpen = true;
  }

  public close(): void {
    this.isOpen = false;
  }

  public onFeedbackClick(): void {
    this.sprigService.track('MW-feedback');
  }

}
