import { Component, OnInit, OnDestroy } from '@angular/core';
import { Unit } from 'manager/models/unit';
import { UnitService, ReorderUnitInput } from 'manager/services/units/unit.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { LatchAnalyticsService, LatchAnalyticsConstants } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';

@Component({
  selector: 'latch-units-reorder-page',
  templateUrl: './units-reorder-page.component.html',
  styleUrls: ['./units-reorder-page.component.scss']
})
export class UnitsReorderPageComponent implements OnInit, OnDestroy {
  units: Unit[] = [];
  unitsToDisplay: Unit[] = [];
  unitsToReorder: ReorderUnitInput[] = [];

  isLoading = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private unitService: UnitService,
    private selectedBuildingsService: SelectedBuildingsService,
    private errorHandlerService: ErrorHandlerService,
    private analyticsService: LatchAnalyticsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Units Reorder'
    });

    this.isLoading = true;
    this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0].uuid), takeUntil(this.unsubscribe$)).subscribe(buildingUUID => {
        this.handlePageLoad(buildingUUID);
      }, error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      }
      );

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handlePageLoad(buildingUUID: string) {
    this.isLoading = true;

    this.unitService.getUnits(buildingUUID)
      .subscribe((units) => {
        this.isLoading = false;
        this.units = units;
        this.unitsToDisplay = [...units];
      }, (error) => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      });
  }

  handleSave() {
    this.isLoading = true;
    this.unitService.reorderUnits(this.unitsToReorder).subscribe(() => {
      this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
        [LatchAnalyticsConstants.PageName]: 'Units Reorder Async',
        [LatchAnalyticsConstants.Success]: true
      });
      this.isLoading = false;
      this.router.navigate(['/console/access/units'], { queryParamsHandling: 'preserve' });
    }, error => {
      this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
        [LatchAnalyticsConstants.PageName]: 'Units Reorder Failure',
        [LatchAnalyticsConstants.ErrorMessage]: error.message
      });
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  handleDrop(event: CdkDragDrop<string[]>) {
    const newPosition = event.currentIndex;
    const unit = event.item.data;
    this.unitsToReorder.push({ id: unit.id, position: newPosition });
    moveItemInArray(this.unitsToDisplay, event.previousIndex, newPosition);
  }

}
