import { Key } from './key';
import { Building } from './building';

export interface RawSiteAdmin {
  buildingUUID: string;
  installer: boolean;
}

export interface RawKeyAdmin {
  buildingUUID: string;
  keyUUIDs: string[];
  assignAllKeys: boolean;
  manageAllKeys: boolean;
}

export interface MaintenanceAdmin {
  buildingUUID: string;
}

export interface DeliveryAdmin {
  buildingUUID: string;
}

export interface RawAccountAdministrationSettings {
  uuid: string;
  accountUUID: string;
  userUUID: string;
  owner: boolean;
  siteAdmins: RawSiteAdmin[];
  keyAdmins: RawKeyAdmin[];
  maintenanceAdmins: MaintenanceAdmin[];
}

export interface CreateAccountAdministrationSettings {
  accountUUID: string;
  userUUID: string;
  owner: boolean;
  siteAdmins: RawSiteAdmin[];
  keyAdmins: RawKeyAdmin[];
  maintenanceAdmins: MaintenanceAdmin[];
}

export interface UpdateAccountAdministrationSettings {
  uuid: string;
  owner: boolean;
  siteAdminsToUpsert: RawSiteAdmin[];
  keyAdminsToUpsert: RawKeyAdmin[];
  siteAdminsToRemove: string[]; // uuids of buildings admin no longer has  device administration privileges for
  keyAdminsToRemove: string[]; // uuids of buildings admin no longer has  key administration privileges for
}

export enum KeyAdminPermissions {
  Full = 'Full',
  Limited = 'Limited',
  Specific = 'Specific'
}

export class SiteAdmin {
  readonly buildingUUID: string;
  readonly installer: boolean;
  readonly rawData: RawSiteAdmin;

  constructor(rawSiteAdminData: RawSiteAdmin) {
    this.rawData = rawSiteAdminData;
    this.buildingUUID = rawSiteAdminData.buildingUUID;
    this.installer = rawSiteAdminData.installer;
  }

  get raw(): RawSiteAdmin {
    return this.rawData;
  }
}

export class KeyAdmin {
  readonly buildingUUID: string;
  readonly keyUUIDs: string[];
  readonly assignAllKeys: boolean;
  readonly manageAllKeys: boolean;
  readonly rawData: RawKeyAdmin;

  constructor(rawKeyAdminData: RawKeyAdmin) {
    this.rawData = rawKeyAdminData;
    this.buildingUUID = rawKeyAdminData.buildingUUID;
    this.keyUUIDs = rawKeyAdminData.keyUUIDs;
    this.assignAllKeys = rawKeyAdminData.assignAllKeys;
    this.manageAllKeys = rawKeyAdminData.manageAllKeys;
  }

  get permissions(): KeyAdminPermissions {
    if (this.assignAllKeys && this.manageAllKeys) {
      return KeyAdminPermissions.Full;
    } else if (this.assignAllKeys) {
      return KeyAdminPermissions.Limited;
    } else {
      return KeyAdminPermissions.Specific;
    }
  }

  get raw(): RawKeyAdmin {
    return this.rawData;
  }
}

export class AccountAdministrationSettings {
  readonly uuid: string;
  readonly accountUUID: string;
  readonly userUUID: string;
  owner: boolean;
  siteAdmins: SiteAdmin[];
  keyAdmins: KeyAdmin[];
  maintenanceAdmins: MaintenanceAdmin[];
  deliveryAdmins: DeliveryAdmin[];

  constructor(rawAccountAdministrationData: any) {
    this.uuid = rawAccountAdministrationData.uuid;
    this.accountUUID = rawAccountAdministrationData.accountUUID;
    this.userUUID = rawAccountAdministrationData.userUUID;
    this.owner = rawAccountAdministrationData.owner;
    this.siteAdmins = rawAccountAdministrationData.siteAdmins.map(
      (rawSiteAdminData: RawSiteAdmin) => new SiteAdmin(rawSiteAdminData)
    );
    this.keyAdmins = rawAccountAdministrationData.keyAdmins.map(
      (rawKeyAdminData: RawKeyAdmin) => new KeyAdmin(rawKeyAdminData)
    );
    this.maintenanceAdmins = rawAccountAdministrationData.maintenanceAdmins;
    this.deliveryAdmins = rawAccountAdministrationData.deliveryAdmins;
  }

  /**
   * returns true if the target admin has installerPermissions for the building with this uuid
   * @param buildingUUID
   * @return
   */
  isInstaller(buildingUUID: string): boolean {
    if (this.isSiteAdmin(buildingUUID)) {
      return !!this.getSiteAdmin(buildingUUID)?.installer;
    } else {
      throw new ReferenceError('Admin does not Administer this Site');
    }
  }

  getSiteAdmin(buildingUUID: string): SiteAdmin | undefined {
    // we should always check that we have this site admin privileges before calling this function
    return this.siteAdmins.find((siteAdmin) => siteAdmin.buildingUUID === buildingUUID);
  }

  isSiteAdmin(buildingUUID: string): boolean {
    return this.siteAdmins.some((siteAdmin) => siteAdmin.buildingUUID === buildingUUID);
  }


  getKeyAdmin(buildingUUID: string): KeyAdmin | undefined {
    return this.keyAdmins.find((keyAdmin) => keyAdmin.buildingUUID === buildingUUID);
  }

  isKeyAdmin(buildingUUID: string): boolean {
    return this.keyAdmins.some((keyAdmin) => keyAdmin.buildingUUID === buildingUUID);
  }

  isAdminForKey(keyUUID: string): boolean {
    return (
      this.keyAdmins.some((keyAdmin) => (keyAdmin.permissions === KeyAdminPermissions.Full ||
        keyAdmin.permissions === KeyAdminPermissions.Limited)) ||
      this.keyAdmins.some((keyAdmin) => keyAdmin.keyUUIDs.includes(keyUUID))
    );
  }
}

/**
 * Returns the buildingUUID either from one of the site admins or key admins building
 * Note: works only for the key or site admins, not for the owners
 */
export function getOneAdministratingBuildingUUID(
  adminSettings: AccountAdministrationSettings
): string {
  let buildingUUID = adminSettings.siteAdmins && adminSettings.siteAdmins.length > 0 ?
    adminSettings.siteAdmins[0].buildingUUID : '';
  if (!buildingUUID) {
    buildingUUID = adminSettings.keyAdmins && adminSettings.keyAdmins.length > 0 ?
      adminSettings.keyAdmins[0].buildingUUID : '';
  }
  return buildingUUID;
}

export interface BuildingUUIDMap {
  [buildingUUID: string]: number;
}

export interface BuildingUUIDtoKeysMap {
  [buildingUUID: string]: Key[];
}

export class DeviceManagementSiteSettings {
  readonly buildingUUID: string;
  readonly name: string;
  doorCount: number;
  installer: boolean;
  selected: boolean;

  constructor(building: Building, doorCount: BuildingUUIDMap) {
    this.buildingUUID = building.uuid;
    this.name = building.name;
    this.doorCount = doorCount[building.uuid];
    this.installer = false;
    this.selected = false;
  }
}

export class KeyManagementSiteSettings {
  readonly buildingUUID: string;
  readonly name: string;
  keyCount: number;
  selected: boolean;
  permission: KeyAdminPermissions;
  keyUUIDs: string[];

  constructor(building: Building, keysCount: BuildingUUIDMap) {
    this.buildingUUID = building.uuid;
    this.name = building.name;
    this.keyCount = keysCount[building.uuid];
    this.selected = false;
    this.permission = KeyAdminPermissions.Full;
    this.keyUUIDs = [];
  }
}
