// membership-codes.pipe.ts
import { Pipe, PipeTransform } from '@angular/core' ;
/*
 * pipe to format the display of keycodes in the same format as it appears in the email
 * Usage:
 *	value | keycodeFormat
 * Example:
 * {{ 1234567 | keycodeFormat }}
 * formats to : `123 45 67`
 */
 @Pipe({ name: 'keycodeFormat' })
 export class KeycodeFormatPipe implements PipeTransform {
    transform(kc: string): string {
    return kc.substr(0, 3) + ' ' + kc.substr(3, 2) + ' ' + kc.substr(5, 2);
    }
 }
