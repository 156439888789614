<latch-loading [isActive]="isLoading">
  <latch-card class="page">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button
          class="latch-button-icon close-button"
          latchCardActions
          [routerLink]="['', { outlets: { popup: null } }]"
          queryParamsHandling="preserve">
          <span class="latch-icon-close"></span>
        </button>
      </div>
      <div class="latch-card-title">Featured Entries</div>
      <p class="page-description">
        Arrange your featured entries in the order you would like them to be displayed on your intercoms.
      </p>
    </ng-container>
    <ng-container latchCardContent>
      <div class="cell-list" *ngIf="featuredEntries.length > 0" cdkDropList (cdkDropListDropped)="handleDrop($event)">
        <div *ngFor="let featuredEntry of featuredEntries"
          class="cell cell-row"
          cdkDrag
          [cdkDragData]="featuredEntry"
          cdkDragLockAxis="y">
          <div class="cell-text">
            <div class="icon-hamburger"></div>
            {{featuredEntry.displayName}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container latchCardFooter>
      <button class="latch-button" (click)="handleSubmit()">
        Save order
      </button>
    </ng-container>
  </latch-card>
</latch-loading>
