<latch-loading [isActive]="isLoading">
  <latch-subnav
    [headerTitle]="headerTitle"
    [subtitle]="pmsUpdateTimeDisplay"
    [actions]="actions"
  ></latch-subnav>

  <div class="latch-viewport description">
    We created <b>{{conflictNum}}</b> access suggestions based on the data imported from {{pmsDisplay}}. Please review and choose whether
    you’d like to override the access for these residents or reconcile these conflicts.
    <a href="https://support.latch.com/hc/en-us/articles/14148903546263-Automated-Access-Post-Activation-Instructions" target="_blank">
      Click here to learn more.
    </a>
    <br><br>
    If you choose to turn the automated access integration on, your {{pmsDisplay}} data will overwrite the Latch Manager account data.
  </div>

  <latch-table class="latch-table-with-viewport" [data]="datasource" *ngIf="conflictNum > 0" [selection]="true" (selectedItemsChange)="handleSelectItems($event)"
    [selectedItems]="selectedPendingAccesses" [class.maxSelection]="selectedPendingAccesses.length >= maxSelectedItems">
    <latch-table-column [columnTitle]="pmsTableRowDisplay" field="pmsKeyState">
      <ng-template latchTableCellTemplate let-accessReviewData>
        <div class="access-review-card">
          <div class="column">
            <div class="card-item">
              <div class="eyebrow">Name</div>
              <a [routerLink]="['/console/people', accessReviewData.userUUID]" queryParamsHandling="preserve">
                <b>{{ accessReviewData.firstName }} {{ accessReviewData.lastName }}</b>
              </a>
            </div>
            <div class="card-item" *ngIf="accessReviewData.unitName">
              <div class="eyebrow">Unit</div>
              {{ accessReviewData.unitName }}
            </div>
            <div class="card-item" *ngIf="accessReviewData.leaseStatus">
              <div class="eyebrow">Status</div>
              {{ getLeaseStatusDisplay(accessReviewData.leaseStatus) }}
            </div>
          </div>
          <div class="column">
            <div class="card-item right">
              <div class="eyebrow right">{{ lastUpdated }}</div>
            </div>
            <div class="card-item">
              <div class="eyebrow">Keys</div>
              <div class="keys">
                <div class="key added" *ngFor="let key of accessReviewData.keysAdded">
                  <b latchTooltip [tooltipHover]="true" [tooltipText]="key.keyName">{{ key.keyName }}</b>
                  <button class="latch-button-icon" (click)="$event.preventDefault(); discardPendingAccess(key)">
                    <span class="latch-icon-close"></span>
                  </button>
                </div>
                <div class="key removed" *ngFor="let key of accessReviewData.keysRemoved">
                  <b latchTooltip [tooltipHover]="true" [tooltipText]="key.keyName">{{ key.keyName }}</b>
                  <button class="latch-button-icon" (click)="$event.preventDefault(); discardPendingAccess(key)">
                    <span class="latch-icon-close"></span>
                  </button>
                </div>
                <div class="key" *ngFor="let key of accessReviewData.keysModified">
                  <b latchTooltip [tooltipHover]="true" [tooltipText]="key.keyName">{{ key.keyName }}</b>
                </div>
                <div class="key" *ngFor="let key of accessReviewData.keysUnchanged">
                  <b latchTooltip [tooltipHover]="true" [tooltipText]="key.keyName">{{ key.keyName }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </latch-table-column>
  </latch-table>
</latch-loading>
