import { environment } from 'environments/interface';
import { GeminiPartnerName } from 'manager/models/gemini-partner';

// These partner ids are not secret values that can cause harm by being exposed in the codebase
const sandboxGeminiPartnerNameToGeminiPartnerUUIDMap = {
  Latch: 'b5f292c5-5d3b-4edc-8d63-031949fdbb27',
  June: 'e50be266-c77c-4c95-a9da-169c32bcefbb',
  'Hello Landing': '5becb6ee-b356-416f-b0c6-d926a0a33024',
  'Landing Dev': 'f7877f5b-20db-4b07-a69c-8a1fb900abf4',
  Cybersuite: '',
  Behring: '552e02f3-61de-4e04-8546-dc3f37d907b8',
  'B-Line': 'c4d35d5a-7d7d-4833-bf73-188e24ae78f0',
  OHAI: '4ae048cb-3163-4f73-b53d-abb9b3f67194',
  Jurny: '710a0e4a-6cc5-4615-bfe3-c707bce517f2',
  'Elevated Living': 'ef55986e-9681-4d02-9b3c-c443e8b48001',
  'Air Clutter': '3edcd6cc-52d8-4b91-bc4f-4cdb4483c069',
  Tour24: 'd2444ef1-19d7-41b1-91af-19b9563a8cc7',
  Pynwheel: '5089cbb4-dba5-424c-9c64-8ed22a9ddeb9',
  Livly: 'cc794930-76af-465e-b3f6-14465fc5505d',
  'Community Boss': 'ef61253c-214c-47a0-9772-66c7b01b9669',
  Primo: '197da281-09eb-4a89-a352-db3359d58496',
  Entratamation: 'bcfcd5b3-234d-4192-a103-53319ddd0aae',
  'Avalon Bay Self Guided Tour': 'e554f13d-3acd-4f90-9487-c74c574da813',
  Churchill: '2f400889-b45b-4f58-96cd-e7e662d7d11d',
  Vacasa: 'ab0cdce4-ec4a-407a-84b0-90bac959e484',
  Seam: '',
  Sentral: '',
  Propkey: '',
  Swift: '',
  RiseIO: ''
};

const devGeminiPartnerNameToGeminiPartnerUUIDMap = {
  Latch: '78ad2e78-edc2-4c6c-b1d2-e330ec3124e7',
  June: 'fe812ed9-cf0b-4fd3-a166-9c0df1e66064',
  'Hello Landing': '24aa2f8e-d09f-4382-8841-a237fe2579c7',
  'Landing Dev': 'd3b80f49-539b-466d-928e-220e68914187',
  Cybersuite: '0a490e86-018c-4047-81e4-78275e391055',
  Behring: 'df510a84-6c44-419b-a61d-efb7934ec2c3',
  'B-Line': 'd85b6d9a-fe80-4c56-ae0e-84ae84618b9a',
  OHAI: 'cf469e4d-f01e-49ef-bbee-3b8e195afeea',
  Jurny: '4805766c-942c-4153-a47d-3a59f16f8668',
  'Elevated Living': '52161626-a66b-474e-bfbe-9781f002cde1',
  'Air Clutter': '90bb2983-f52d-4162-a67e-3d5977b403c8',
  Tour24: '5c81b1ed-b1b1-4bf8-8fce-62a1d25572b0',
  Pynwheel: '84f16a35-ed2c-42ce-a8f9-75c44a02bdea',
  Livly: '438d0485-77e9-4792-b7fd-993bfb9b4a34',
  'Community Boss': '3d527dee-1021-4eb6-8d67-e5f81e32adc7',
  Primo: '48146483-c3d1-430a-a58c-0ed6dc2be84e',
  Entratamation: 'f657ac7b-cd22-40a4-ab71-cdcef4f0c41c',
  'Avalon Bay Self Guided Tour': 'e964bdc0-8b33-4924-894d-03e3cbaa2b39',
  Churchill: '5cebda3f-a1d5-4c87-afd8-820e5acdb09c',
  Vacasa: '8f425112-680a-4ce2-b66f-24697c98003f',
  Seam: 'eaf91efe-e225-4877-bfcf-e9cef199b8d6',
  Sentral: '6f76cb45-3489-4f18-bae1-a9e058f8f015',
  Propkey: 'd76699fe-0c70-449b-852d-0767c92d0fe7',
  Swift: '6e79a44e-a45d-4217-beb2-a64811502b7f',
  RiseIO: '268ebacd-1baa-4a75-9120-0fad681de9bb'
};

const qaGeminiPartnerNameToGeminiPartnerUUIDMap = {
  Latch: '1891c19b-a3f7-4fc7-8576-20c48d5b70b6',
  June: '8772f53a-34dc-4f9b-8c2a-378481279d5a',
  'Hello Landing': 'cb1e1b74-1bb3-4c11-9d5c-0920c499a752',
  'Landing Dev': 'f377b1f9-604b-4532-91aa-b84fed5265c2',
  Cybersuite: 'cd22b9cd-3d57-42b3-8209-f18dcc09aff6',
  Behring: 'b034a25a-1686-403f-9085-630369af77c6',
  'B-Line': '1da607bd-3512-49fa-966e-201c87db4a71',
  OHAI: '618f2db2-b161-48f9-9031-c9970e6d387d',
  Jurny: '371f62d8-5e4d-4f76-a309-5afbb6cb5e07',
  'Elevated Living': 'b934964e-78a2-4f8c-92f0-3b1fe640e497',
  'Air Clutter': 'd3e9d116-b287-417b-ab50-b9a7163655ef',
  Tour24: '622d0891-0018-4be7-a161-06d2d5f58177',
  Pynwheel: 'abeae40a-81ff-4c18-bbe4-d083227a8cf3',
  Livly: '1d5a80af-ee2b-4231-b932-388528fe0326',
  'Community Boss': 'c1594de9-8942-416e-a800-e13af0ed83e9',
  Primo: '43562464-dc77-45ce-b123-fa5603e3eaec',
  Entratamation: 'dc1c2431-d7ff-4cb1-bdf6-5ec530adb8b1',
  'Avalon Bay Self Guided Tour': '94c25d2f-1a0e-4d35-9eb1-01d8fa4a48bf',
  Churchill: '79fa5c7e-a133-43ac-bfc1-49f1ff701831',
  Vacasa: 'f8e8f349-5fe0-4936-aeb8-bbf5f95f1e7e',
  Seam: '27dbd777-9b2f-4b6f-8e7e-36b97c456b55',
  Sentral: '86be2898-a98a-4c72-9e13-576c594de11d',
  Propkey: 'c3ef3d17-5639-496e-9ea1-3665082d4846',
  Swift: 'e616ec58-4f25-4ddd-a9ee-d2982784adb7',
  RiseIO: '257de58b-aaf4-4fed-86a9-72e2b5edcfc1'
};

const stagingGeminiPartnerNameToGeminiPartnerUUIDMap = {
  Latch: '6efc2e37-a053-43dd-8810-b6c2bcc1b0dd',
  June: 'bec05c46-c12b-417a-8c10-e718ba2b7f51',
  'Hello Landing': '6901a976-8b56-4c27-80e3-3feed0fb1e0f',
  'Landing Dev': 'b749a2db-dbf4-4b58-8810-be9f61156eee',
  Cybersuite: '3d53688a-c69c-4325-9fd8-5b1be6c31790',
  Behring: 'be6f2307-9919-4ad9-b821-7c479e42b1f9',
  'B-Line': '4179bd72-7ac4-44aa-b2f0-2c1e170a2ad9',
  OHAI: 'b0d1bb22-e8e6-46a0-b122-b13348d3df69',
  Jurny: '1ab366f9-4b0c-46ad-9d10-3269873bf704',
  'Elevated Living': '0631636e-e9de-4c25-a90c-f29f79d22f27',
  'Air Clutter': '4e669d17-0750-49a2-bcd8-261d2c6a6967',
  Tour24: 'ccb8cd30-fe44-47e7-a713-64c2b7456801',
  Pynwheel: '9915fd57-d8ee-4650-bcd5-831650b7fb79',
  Livly: '17e2aaf0-9bf1-44e9-9e4a-8d95408a0823',
  'Community Boss': '183ec624-0dec-485a-8b3d-beba21d217a7',
  Primo: 'c550fa1d-1493-4516-a134-5a18f5d72fad',
  Entratamation: '64d20f37-0c0b-42b5-b134-fa3044392c9d',
  'Avalon Bay Self Guided Tour': '9b1e0312-c52f-4dd3-95b6-d0994a9fed3e',
  Churchill: '0c858cb8-f206-4e10-84e6-56e7fb59038f',
  Vacasa: '1c9b8320-e1c1-4105-865b-abf7481dbf1a',
  Seam: '1f4ad0d4-b921-44ec-a59b-3bb132b6675a',
  Sentral: '2ed9858f-6c61-41cf-bbb1-c721bfcb22fe',
  Propkey: '172e1587-6909-49cd-9c97-a39691067a24',
  Swift: '8d2fea2a-18bb-44ba-9d3f-5b88e045aa30',
  RiseIO: '40ef7091-8f84-43a3-ab2a-018c1b99d3ac'
};

const productionGeminiPartnerNameToGeminiPartnerUUIDMap = {
  Latch: '54633d29-b6ea-4793-9866-3cce981e66f8',
  June: 'b52712d1-9e74-4cdb-b531-6aa98eb67e42',
  'Hello Landing': '6484b110-a7dd-4772-85a9-3ef61db1035c',
  'Landing Dev': '8db8e2bf-f01b-4557-b8fb-979cc4e63643',
  Cybersuite: '1f4a0efa-3402-4df4-9800-359c667e15fe',
  Behring: '8373da3a-0fee-400a-a6c4-61eae0e69485',
  'B-Line': '46605764-e51f-4640-ae03-bd0e9b933649',
  OHAI: '2caa99e7-5a47-4bf9-8c89-c31c3804abdc',
  Jurny: 'cead9736-d6b2-4194-9697-6e3acb1b5068',
  'Elevated Living': 'a9053879-7d95-47fa-abcb-f2f5f3d991c5',
  'Air Clutter': 'a517dfa8-5edb-43bf-9add-189274dba3f6',
  Tour24: '3f07e1a0-5f45-41b1-91ea-1b906c289603',
  Pynwheel: '64c6f39b-c597-426a-85b2-e6750bda460b',
  Livly: 'fdb2e056-83c8-4301-b111-66bf73031f40',
  'Community Boss': '254bd351-0b2d-4438-b84f-3e8cfdcfc1db',
  Primo: '2b9d6275-bc04-4d55-886d-5c79e5849620',
  Entratamation: '5973e525-2359-447e-b7c7-6b4a00ff0d54',
  'Avalon Bay Self Guided Tour': '7ce8236c-6b71-4c36-a15e-28ee069d4c13',
  Churchill: '180a88f6-64ac-4630-a7e2-9abbdb30d6d0',
  Vacasa: 'e2b3fc84-77a7-4a92-a13e-d48ff76c8dba',
  Seam: '02a5033f-0d57-4075-b8ef-8b551807c934',
  Sentral: 'a7c4529e-8349-479a-85f0-6e275240f31c',
  Propkey: '8ad93c68-c665-4a2f-b8a5-e06f22bcc169',
  Swift: 'efdda122-d518-429f-b5e7-83d2692021df',
  RiseIO: '1c8a2d10-697c-4047-a847-4ceb718f7603'
};

export function getGeminiPartnerId(partnerName: GeminiPartnerName): string {
  if (environment.name === 'sandbox' || environment.name === 'mock') {
    return sandboxGeminiPartnerNameToGeminiPartnerUUIDMap[partnerName];
  } else if (environment.name === 'dev' || environment.name === 'dev-no-logs') {
    return devGeminiPartnerNameToGeminiPartnerUUIDMap[partnerName];
  } else if (environment.name === 'qa') {
    return qaGeminiPartnerNameToGeminiPartnerUUIDMap[partnerName];
  } else if (environment.name === 'staging') {
    return stagingGeminiPartnerNameToGeminiPartnerUUIDMap[partnerName];
  } else if (environment.name === 'production') {
    return productionGeminiPartnerNameToGeminiPartnerUUIDMap[partnerName];
  }
  return '';
}
