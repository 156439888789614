import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClosePackagesModal, CloseSendNotificationModal, DeliveryUnitList, PackageModalType } from '../../../models/delivery';

@Component({
  selector: 'latch-package-modal',
  templateUrl: './package-modal.component.html',
  styleUrls: ['./package-modal.component.scss']
})
export class PackageModalComponent implements OnInit {
  @Input() public packageModalType!: PackageModalType;
  @Input() public deliveryUnit!: DeliveryUnitList;
  @Output() public closeModal = new EventEmitter<ClosePackagesModal>();

  private initialPackageModalType!: PackageModalType;
  ngOnInit(): void {
    this.initialPackageModalType = this.packageModalType;
  }

  handleCloseDetailsModal(): void {
    this.closeModal.emit('close');
  }

  handleCloseSendNotificationsModal(closeSendNotificationModal: CloseSendNotificationModal): void {
    switch (closeSendNotificationModal) {
      case 'close': {
        if (this.initialPackageModalType === 'packageDetails') {
          this.setPackageModalType('packageDetails');
        } else {
          this.closeModal.emit('close');
        }
        break;
      }
      case 'success': {
        this.closeModal.emit('sendNotificationsSuccess');
        break;
      }
      case 'error': {
        this.closeModal.emit('sendNotificationsError');
        break;
      }
    }
  }

  setPackageModalType(packageModalType: PackageModalType): void {
    this.packageModalType = packageModalType;
  }

}
