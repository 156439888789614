import { Injectable } from '@angular/core';
import { DuplicatesService } from './duplicates.service';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DedupingResponse, DuplicateEmail, DuplicateResponse } from 'manager/models/user';

@Injectable()
export class HTTPDuplicatesService extends DuplicatesService {
  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getDuplicates(buildingUUID: string): Observable<DuplicateResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v3/buildings/${buildingUUID}/pmsDuplicates`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  mergeAccounts(buildingUUID: string, duplicateIds: string[]): Observable<DedupingResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v3/buildings/${buildingUUID}/mergePmsDuplicates`,
      data: { dupUUIDs: duplicateIds }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  createSeparate(buildingUUID: string, duplicateIds: string[]): Observable<DedupingResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v3/buildings/${buildingUUID}/separatePmsDuplicates`,
      data: { dupUUIDs: duplicateIds }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  getDuplicateEmails(buildingUUID: string): Observable<DuplicateEmail[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/residentDuplicateEmail`
    }).pipe(
      map((response) => AuthService.getPayload(response) as DuplicateEmail[]),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }
}
