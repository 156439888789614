import { Injectable } from '@angular/core';
import { Observable, of, from, throwError, concat } from 'rxjs';
import { catchError, concatMap, map, reduce, shareReplay, toArray } from 'rxjs/operators';

import { fromJSON, KeyMembership } from '../../models/key-membership';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { AuthService } from '../auth/auth.service';
import {
  KeyMembershipService,
  GetKeyMembershipsInput,
  GetKeyMembershipInput,
  CreateKeyMembershipInput,
  UpdateKeyMembershipInput,
  DeleteKeyMembershipInput,
  CreateKeyMembershipsInput
} from './key-membership.service';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { convertListToMap as convertLargeList } from '../utility/http-large-list';
import { ChangeNotificationService } from '../appstate/change-notification.service';
import { OperationResultResponse } from '../../models/operations';
import { chunk } from '../utility/utility';

@Injectable()
export class HTTPKeyMembershipService extends KeyMembershipService {

  constructor(
    private authService: AuthService,
    private selectedAccountService: SelectedAccountService,
    private changeNotificationService: ChangeNotificationService
  ) {
    super();
  }

  getKeyMemberships(input: GetKeyMembershipsInput): Observable<KeyMembership[]> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const MAX_KEY_UUIDS_IN_QUERY_PARAMS = 12;
    const endpoint = `/web/v1/accounts/${accountUUID}/buildings/${input.buildingUUID}/memberships`;
    let response;
    if (input.keyUUID) {
      const keyUUIDsInput = this.getKeyUUIDInput(input);

      // If the array is empty, we would eventually get to from(chunks = []) below, which would never fire.
      // Need to special case the empty array case or this will return an observable that never fires.
      if (!keyUUIDsInput || keyUUIDsInput.length === 0) {
        return of([]);
      }

      const chunkedKeyUUIDs = chunk(keyUUIDsInput, MAX_KEY_UUIDS_IN_QUERY_PARAMS);
      response = from(chunkedKeyUUIDs).pipe(
        map(keyUUIDChunk => Object.assign({}, input, { keyUUID: keyUUIDChunk })),
        concatMap((chunkedMembershipInput: GetKeyMembershipsInput) => this.getChunkedKeyMemberships(endpoint, chunkedMembershipInput)),
        reduce((all, curr) => all.concat(curr))
      );
    } else {
      response = this.getChunkedKeyMemberships(endpoint, input);
    }

    return response;
  }

  /**
   * Sends requests for key memberships, assuming that keyUUID is either undefined or a non-empty array with a length
   * no greater than MAX_KEY_UUIDS_IN_QUERY_PARAMS.
   */
  private getChunkedKeyMemberships(endpoint: string, chunkedMembershipInput: GetKeyMembershipsInput) {
    let search = new HttpParams();
    if (chunkedMembershipInput.userUUID) {
      search = search.append('userUUID', chunkedMembershipInput.userUUID);
    }
    if (chunkedMembershipInput.hostUUID) {
      search = search.append('hostUUID', chunkedMembershipInput.hostUUID);
    }
    if (chunkedMembershipInput.type) {
      search = search.append('type', String(chunkedMembershipInput.type));
    }
    if (chunkedMembershipInput.keyOwnershipType) {
      search = search.append('keyOwnershipType', String(chunkedMembershipInput.keyOwnershipType));
    }
    if (chunkedMembershipInput.keyUUID) {
      const keyUUIDs = Array.isArray(chunkedMembershipInput.keyUUID) ? chunkedMembershipInput.keyUUID : [chunkedMembershipInput.keyUUID];
      keyUUIDs.forEach((keyUUID) => search = search.append('keyUUID', keyUUID));
    }

    return this.authService.request({
      method: 'get',
      endpoint,
      search
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((memberships) => memberships.map(fromJSON)),
      catchError((error: Error) => this.handleError(error)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  getKeyMembership(input: GetKeyMembershipInput): Observable<KeyMembership> {
    const endpoint = `/web/v1/keys/${input.keyUUID}/memberships/${input.membershipUUID}`;
    return this.authService.request({
      method: 'get',
      endpoint
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((json) => fromJSON(json)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  createKeyMembership(input: CreateKeyMembershipInput): Observable<OperationResultResponse> {
    const data = {
      type: input.type,
      startTime: input.startTime,
      endTime: input.endTime,
      shareable: input.shareable,
      role: input.role,
      userUUID: input.userUUID,
      nickname: input.nickname,
      floorGroups: (input.floorGroups) ? convertLargeList(input.floorGroups) : null
    };
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v2/keys/${input.keyUUID}/memberships`,
      data
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  createKeyMemberships(input: CreateKeyMembershipsInput): Observable<OperationResultResponse> {
    const MAX_USERS_PER_REQUEST = 200;
    const results = chunk(input.users, MAX_USERS_PER_REQUEST)
      .map(chunkItem => this.authService.request({
        method: 'post',
        endpoint: `/web/v2/bulk/keys/${input.keyUUID}/memberships`,
        data: {
          passcodeType: input.passcodeType,
          startTime: input.startTime,
          endTime: input.endTime,
          shareable: input.shareable,
          role: input.role,
          users: convertLargeList(chunkItem),
          floorGroups: (input.floorGroups) ? convertLargeList(input.floorGroups) : null
        }
      }));

    return this.concatResults(results);
  }

  updateKeyMembership(input: UpdateKeyMembershipInput): Observable<OperationResultResponse> {
    this.changeNotificationService.publishKeyMembershipChange();
    const data = {
      startTime: input.startTime,
      endTime: input.endTime,
      shareable: input.shareable,
      role: input.role,
      floorGroups: (input.floorGroups) ? convertLargeList(input.floorGroups) : null
    };
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v2/keys/${input.keyUUID}/memberships/${input.uuid}`,
      data
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  deleteKeyMembership(input: DeleteKeyMembershipInput): Observable<OperationResultResponse> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v2/keys/${input.keyUUID}/memberships/${input.uuid}`,
      data: {}
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  private getKeyUUIDInput(input: any): string[] | undefined {
    if (!input) {
      return undefined;
    }
    return Array.isArray(input.keyUUID) ? input.keyUUID : [input.keyUUID];
  }

  /**
   * Accepts an array of requests and concats the results into a single response
   * @param requests an array of requests
   * @returns the concated result
   */
  private concatResults(requests: Observable<HttpResponse<OperationResultResponse>>[]): Observable<OperationResultResponse> {
    return concat(...requests).pipe(
      toArray(),
      map(response => {
        const operations = response.map(AuthService.getPayload) as OperationResultResponse[];
        const operationIds = operations.map(r => r.operationIds).reduce((acc, curr) => acc.concat(curr), []);
        return { operationIds };
      }),
      catchError((error: Error) => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }
}
