import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[latchRequired]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RequiredDirective, multi: true }]
})
export class RequiredDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    const hasError = !control.value || !control.value.trim();
    return hasError ? { required: true } : null;
  }

}
