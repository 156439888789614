import { NextPage } from '../services/interfaces';

export enum ContactPreference {
  APP = 'APP',
  PHONE = 'PHONE'
}

export interface FeaturedEntry {
  id: number;
  displayName: string;
  buildingUUID: string;
  contactCardUUID: string;
  contactPreference: ContactPreference;
  hasAccess: boolean;
  isContactCardActive: boolean;
  order: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export enum FeaturedEntryStep {
  FeaturedEntryDetails,
  FeaturedEntryCandidate
}


export interface FeaturedEntryCandidate {
  contactCardUUID: string;
  userUUID?: string;
  hasAccess: boolean;
  isContactCardActive: boolean;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface ContactPreferenceSelect {
  name: string;
  value: ContactPreference;
}

export interface CreateFeaturedEntry {
  displayName: string;
  contactCardUUID: string;
  contactPreference: ContactPreference
}

export const ContactCardNotFoundError = 'CONTACT_CARD_NOT_FOUND_ERROR';
export const FeaturedEntryNotFoundError = 'FEATURED_ENTRY_NOT_FOUND_ERROR';

export enum SelectCandidateStep {
  SelectCandidate,
  AddPhoneNumber
}

export interface FeaturedEntryCandidatesInput {
  start: number;
  limit: number;
  search?: string;
}

export interface FeaturedEntryCandidatesResponse {
  metadata: {
    nextPage: NextPage | null;
  }
  people: FeaturedEntryCandidate[];
}

export interface FeaturedEntryUpdate {
  displayName: string;
  contactCardUUID: string;
  contactPreference: ContactPreference;
  order: number;
  phoneNumber?: string;
  decommissionTime?: number;
}
