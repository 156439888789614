import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'manager/services/auth/auth.service';
import { BuildingService } from 'manager/services/building/building.service';
import moment from 'moment';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppointmentSlot, BookableResource, BookingAppointment, BookingAppointmentType, TermsAndConditions } from '../models/booking';
import {
  GetBookableResourcesInput,
  GetBookingAppointmentsInput,
  BookingService, UpdateBookableResource,
  CreateBookableResource,
  DeleteBookingAppointmentInput,
  BookableResourcesResult,
  DeleteBookingAppointmentsInput,
  GetBookingActivityInput,
  CreateBookingAppointmentsInput,
} from './booking.service';

const PAGE_SIZE = 50;

interface BackendError {
  errors?: [
    {
      source: string,
      code: string,
      message: string,
    }
  ];
}

@Injectable()
export class HTTPBookingService extends BookingService {
  constructor(
    private authService: AuthService,
    private buildingService: BuildingService,
  ) {
    super();
  }

  public revokeBookingAppointment(input: DeleteBookingAppointmentInput): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/bookings/booking-appointments/${input.bookingAppointmentUUID}`,
    }).pipe(
      map(() => null),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public revokeBookingAppointments(input: DeleteBookingAppointmentsInput): Observable<null> {
    const year = moment.unix(input.start).year();
    const month = moment.unix(input.start).month() + 1;
    const day = moment.unix(input.start).date();
    const start = moment.unix(input.start).diff(moment.unix(input.start).startOf('day'), 'seconds');
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/bookings/buildings/${input.buildingUUID}/bookable-resources/${input.bookableResource.uuid}/` +
        `slots/${year}/${month}/${day}/${start}`,
    }).pipe(
      map(() => null),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public deleteBookableResource(buildingUUID: string, uuid: string): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/bookings/buildings/${buildingUUID}/bookable-resources/${uuid}`,
    }).pipe(
      map(() => null),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public createBookableResource(input: CreateBookableResource): Observable<BookableResource> {
    const file = input.termsAndConditions?.file;
    const termsAndConditions = input.termsAndConditions;
    if (file) {
      delete input.termsAndConditions?.file;
    }
    const createRequest = (terms?: TermsAndConditions) => this.authService.request({
      method: 'post',
      endpoint: `/web/v1/bookings/buildings/${input.buildingUUID}/bookable-resources`,
      data: {
        name: input.name,
        policy: {
          maxSlotsPerPersonPerDay: input.maxSlots,
          maxAppointmentsPerSlot: input.maxConcurrent,
          minLeadDays: 0,
          maxAdvanceDays: input.maxAdvanceDays,
          slotDuration: input.slotDuration,
          bookablePeriods: input.bookablePeriods,
        },
        additionalInformation: input.additionalInformation,
        bookingAmount: {
          paymentType: input.paymentType,
          timeSlotAmount: input.timeSlotAmount,
        },
        notificationEnabled: input.notificationEnabled,
        space: input.spaces?.map(space => ({ path: space.path }))[0],
        termsAndConditions: terms ?? termsAndConditions
      }
    }).pipe(
      map((response: HttpResponse<BookableResource>) => AuthService.getPayload(response) as BookableResource),
      catchError((error: Error) => this.handleBookingsError(error)),
    );

    if (file) {
      return this.createTermsAndConditions(file).pipe(
        switchMap(result => createRequest(result))
      );
    } else {
      return createRequest();
    }
  }

  public updateBookableResource(input: UpdateBookableResource): Observable<BookableResource> {
    const file = input.termsAndConditions?.file;
    const termsAndConditions = input.termsAndConditions;
    if (file) {
      delete input.termsAndConditions?.file;
    }
    const updateRequest = (terms?: TermsAndConditions) => this.authService.request({
      method: 'put',
      endpoint: `/web/v1/bookings/buildings/${input.buildingUUID}/bookable-resources/${input.uuid}`,
      data: {
        bookableResource: {
          name: input.name,
          uuid: input.uuid,
          building: {
            id: input.buildingUUID
          },
          policy: {
            maxSlotsPerPersonPerDay: input.maxSlots,
            maxAppointmentsPerSlot: input.maxConcurrent,
            minLeadDays: 0,
            maxAdvanceDays: input.maxAdvanceDays,
            slotDuration: input.slotDuration,
            bookablePeriods: input.bookablePeriods,
          },
          additionalInformation: input.additionalInformation,
          bookingAmount: {
            paymentType: input.paymentType,
            timeSlotAmount: input.timeSlotAmount,
          },
          notificationEnabled: input.notificationEnabled,
          status: input.status,
          space: input.spaces?.map(space => ({ path: space.path }))[0],
          termsAndConditions: terms ?? termsAndConditions
        }
      }
    }).pipe(
      map((response: HttpResponse<BookableResource>) => AuthService.getPayload(response) as BookableResource),
      catchError((error: Error) => this.handleBookingsError(error)),
    );

    if (file) {
      return this.createTermsAndConditions(file).pipe(
        switchMap(result => updateRequest(result))
      );
    } else {
      return updateRequest();
    }
  }

  public getBookableResource(buildingUUID: string, uuid: string): Observable<BookableResource> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/bookings/buildings/${buildingUUID}/bookable-resources/${uuid}`,
    }).pipe(
      map((response: HttpResponse<BookableResource>) => AuthService.getPayload(response) as BookableResource),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getBookableResources(input: GetBookableResourcesInput): Observable<BookableResourcesResult> {
    let search = new HttpParams()
      .append('pageSize', PAGE_SIZE.toString())
      .append('order', `resource.name:${input.sortNameAscending ? 'ASC' : 'DESC'}`);
    if (input.search) {
      search = search.append('search', input.search);
    }
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/bookings/buildings/${input.buildingUUID}/bookable-resources`,
      search
    }).pipe(
      map((response: HttpResponse<BookableResourcesResult>) => AuthService.getPayload(response) as BookableResourcesResult),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getBookingAppointments(input: GetBookingAppointmentsInput): Observable<AppointmentSlot[]> {
    let search = new HttpParams();
    if (input.search) {
      search = search.append('searchTerm', input.search);
    }
    const year = moment.unix(input.start).year();
    const month = moment.unix(input.start).month() + 1;
    const day = moment.unix(input.start).date();
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/bookings/buildings/${input.buildingUUID}/bookable-resources/${input.bookableResourceUUID}/` +
        `slots/${year}/${month}/${day}`,
      search
    }).pipe(
      map((response: HttpResponse<AppointmentSlot[]>) => AuthService.getPayload(response) as AppointmentSlot[]),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getBookingsActivity(input: GetBookingActivityInput): Observable<BookingAppointment[]> {
    let search = new HttpParams({
      fromObject: {
        buildingUUID: input.buildingUUID,
        pageSize: PAGE_SIZE,
      }
    });
    if (input.searchTerm) {
      search = search.append('searchTerm', input.searchTerm);
    }
    input.resourceFilter?.forEach(resource => {
      search = search.append('resourceUUID', resource.uuid);
    });
    input.peopleFilter?.forEach(person => {
      search = search.append('userUUID', person.userUUID);
    });
    input.statusFilter?.forEach(status => {
      search = search.append('selector', status);
    });
    if (input.startTimeEpoch) {
      search = search.append('startTimeEpoch', input.startTimeEpoch);
    }
    if (input.endTimeEpoch) {
      search = search.append('endTimeEpoch', input.endTimeEpoch);
    }
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/bookings/booking-appointments',
      search
    }).pipe(
      map((response: HttpResponse<AppointmentSlot[]>) => AuthService.getPayload(response).elements as BookingAppointment[]),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public changeViewBookings(buildingUUID: string, enabled: boolean): Observable<null> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/bookings/buildings/${buildingUUID}`,
      data: {
        viewBookingsEnabled: enabled
      }
    }).pipe(
      map((response: HttpResponse<null>) => {
        this.buildingService.refreshBuildings();
        return AuthService.getPayload(response) as null;
      }),
      catchError((error: Error) => this.handleBookingsError(error)),
    );
  }

  public createBookingAppointments(buildingUUID: string, input: CreateBookingAppointmentsInput): Observable<null> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/bookings/buildings/${buildingUUID}/bookable-resources/${input.bookableResourceUUID}/booking-appointments`,
      data: input,
    }).pipe(
      map(() => null),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public blockBookingAppointmentTimeSlots(buildingUUID: string, input: CreateBookingAppointmentsInput): Observable<null> {
    input.appointmentType = BookingAppointmentType.RESERVATION; // meant for blocked appointments
    return this.createBookingAppointments(buildingUUID, input);
  }

  public createTermsAndConditions(file: File): Observable<TermsAndConditions> {
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v1/bookings/store/terms-and-conditions',
      data: {
        title : file.name,
        contentType : 'application/pdf'
      },
    }).pipe(
      switchMap(response => {
        const { termsAndConditions, uploadUrl } = AuthService.getPayload(response) as {
          termsAndConditions: TermsAndConditions,
          uploadUrl: string,
        };
        return from(fetch(uploadUrl, { body: file, method: 'PUT' })).pipe(
          map(() => termsAndConditions),
        );
      }),
      catchError((error: Error) => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      const message = AuthService.getPayload(error) as string;
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }

  private handleBookingsError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status === 400 && error.error) {
      const backendError: BackendError = error.error?.payload ?? error.error;
      return throwError(() => backendError.errors?.reduce(
        (prev: { [index: string]: string; }, curr) => {
          prev[curr.source] = curr.message;
          return prev;
        },
        {}
      ) ?? backendError ?? error);
    }
    return this.handleError(error);
  }

}
