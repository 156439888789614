import { Component, Output, EventEmitter, Input } from '@angular/core';
import { LatchAnalyticsService } from '@latch/latch-web';
import { MAX_UNIT_NAME_LENGTH } from 'manager/services/units/unit.service';

const UPLOAD_TEMPLATE = `Unit
1A
1B
1C
1D
2A
2B
2C
2D
3A
3B
3C
3D`;

const UPLOAD_TEMPLATE_PBX = `Unit,PBX in-unit calling number
1A,555-555-5555
1B,555-555-5555
1C,555-555-5555
1D,555-555-5555
2A,555-555-5555
2B,555-555-5555
2C,555-555-5555
2D,555-555-5555
3A,555-555-5555
3B,555-555-5555
3C,555-555-5555
3D,555-555-5555`;

export interface UnitImportInput {
  name: string;
  localTelecom?: string;
}

interface InvalidUnitImportInput {
  /** The fields (name, [optional] pbx) of the invalid unit. */
  unitFields: string[];
  /** Error describing why the fields do not make up a valid unit. */
  error: string;
}

enum Step {
  Upload,
  Summary
}

@Component({
  selector: 'latch-import-units-page',
  templateUrl: './import-units-page.component.html',
  styleUrls: []
})
export class ImportUnitsPageComponent {

  @Input() showPbx = false;
  @Output() cancel = new EventEmitter<void>();
  @Output() import = new EventEmitter<UnitImportInput[]>();

  importName = 'Units';
  maxRows = 1500;

  currentStep: Step = Step.Upload;
  Step = Step;

  valid: UnitImportInput[] = [];
  invalid: InvalidUnitImportInput[] = [];

  constructor(
    private analyticsService: LatchAnalyticsService
  ) { }

  get pageDescription(): string {
    return `When creating your .CSV file, include only the unit number or name. For example, use "2A" instead of "Unit 2A."
    ${this.showPbx ? 'The intercom in-unit calling number is optional.' : ''}`;
  }

  get uploadTemplate(): string {
    return this.showPbx ? UPLOAD_TEMPLATE_PBX : UPLOAD_TEMPLATE;
  }

  get columnNames(): string[] {
    return this.showPbx ? ['unit', 'PBX in-unit calling number'] : ['unit'];
  }

  handleImport(input: string[][]) {
    if (!input) {
      return;
    }

    this.valid = [];
    this.invalid = [];
    const unitNames: Record<string, boolean> = {};

    input.forEach((unitFields) => {
      const unitName = unitFields[0].trim();
      const unitPbx = unitFields[1];

      if (!unitName) {
        return this.invalid.push({
          unitFields,
          error: 'Unit name is required'
        });
      }

      if (unitName.length > MAX_UNIT_NAME_LENGTH) {
        return this.invalid.push({
          unitFields,
          error: `Unit name exceeds ${MAX_UNIT_NAME_LENGTH} characters`
        });
      }

      if (unitNames[unitName]) {
        return this.invalid.push({
          unitFields,
          error: 'Unit name is a duplicate'
        });
      } else {
        unitNames[unitName] = true;
      }

      this.valid.push({
        name: unitName,
        localTelecom: unitPbx ? unitPbx : undefined
      });
    });

    this.analyticsService.track(`Import ${this.importName} Success`, {
      'Valid Count': this.valid.length,
      'Invalid Count': this.invalid.length
    });

    this.currentStep = Step.Summary;
  }

}
