import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Key, KeyOwnershipType, KeyType } from '../../../models/key';

import { groupBy, thisMany } from '../../../services/utility/utility';
import { PermissionsService } from '../../../services/permissions/permissions.service';
import { KeyTypeGroupText, KeyIconType, getKeyIconType } from '../../../services/utility/presentation';
import { ElevatorBuildingService } from '../../../services/elevator-building/elevator-building.service';
import { Subject, zip } from 'rxjs';
import { debounceTime, first, startWith, takeUntil } from 'rxjs/operators';
import { IntercomShortInfo } from 'manager/services/intercom/intercom.service';

const reverseAlphabetical = (s1: string, s2: string) => -1 * s1.localeCompare(s2);
const DEBOUNCE_TIME = 300;

/**
 * A key, identified by UUID, that should not be selectable, along with a human-readable message explaining why it is not selectable.
 */
export interface DisabledKey {
  keyUUID: string;
  tooltipText: string;
}

@Component({
  selector: 'latch-select-keys',
  templateUrl: './select-keys.component.html',
  styleUrls: ['./select-keys.component.scss']
})
export class SelectKeysComponent implements OnInit, OnDestroy {

  KeyIconType = KeyIconType;
  KeyType = KeyType;

  @Input() initialSelected: Key[] = [];
  @Input() intercoms: IntercomShortInfo[] = [];
  @Output() cancel = new EventEmitter<void>();
  @Output() created = new EventEmitter<Key>();
  @Output() done = new EventEmitter<Key[]>();

  currentUserMayEditKey = false;

  _keys: Key[] = [];
  _disabledKeys: DisabledKey[] = [];

  filtered: Key[] = [];
  filteredGroups: string[] = [];
  filteredByGroup: { [keyGroup: string]: Key[]; } = {};
  isElevatorBuilding = true;

  _search = '';
  search$ = new Subject<string>();

  selected: Set<Key> = new Set();

  showMakeKey = false;
  thisMany = thisMany;

  private unsubscribe$ = new Subject<void>();

  @Input()
  set keys(keys: Key[]) {
    this._keys = keys
      .map(k => ({ ...k, name: k.name.trim() }))
      .sort((a, b) => a.keyType.localeCompare(b.keyType) || a.name.localeCompare(b.name));
    this.filter();
  }

  get keys(): Key[] {
    return this._keys;
  }

  /**
   * List of keys that should not be selectable along with the reason why that should be displayed to the user if they attempt to select.
   *
   * Currently this component also assumes that delivery keys should not be selectable (in addition to the ones provided via this input).
   * Eventually this could be unified, and it could up to clients to decide whether delivery keys should be selectable. For now in the
   * interest of minimizing changes, this component will still disable deliveries keys automatically.
   */
  @Input()
  set disabledKeys(disabledKeys: DisabledKey[]) {
    this._disabledKeys = disabledKeys;
    this.removeDisabledFromSelected();
  }

  get disabledKeys(): DisabledKey[] {
    return this._disabledKeys;
  }

  set search(search: string) {
    this._search = search;
    this.search$.next(search);
  }

  get search() {
    return this._search;
  }

  constructor(
    protected permissionsService: PermissionsService,
    protected elevatorBuildingService: ElevatorBuildingService
  ) { }

  // intended to be overridden in sub-classes if using a non-standard siteUUID
  protected alternateSiteUUID(): string | null {
    return null;
  }

  ngOnInit() {
    const currentUserMayEditKeys$ = this.permissionsService.currentUserMayEditKeys();

    const isElevatorBuilding$ = this.elevatorBuildingService
      .isElevatorBuilding(this.alternateSiteUUID()).pipe(first());

    zip(currentUserMayEditKeys$, isElevatorBuilding$).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([currentUserCanEditKeys, isElevatorBuilding]) => {
      this.currentUserMayEditKey = currentUserCanEditKeys;
      this.isElevatorBuilding = isElevatorBuilding;
      this.selected = new Set(this.initialSelected);
      this.removeDisabledFromSelected();
      this.filter();
    });

    this.search$.pipe(
      startWith(''),
      debounceTime(DEBOUNCE_TIME),
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => this.filter()
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  filter(amountOfRows = 100) {
    this.filtered = this.keys.filter((key) => key.name.toLowerCase().includes(this.search.toLowerCase())).slice(0, amountOfRows);
    if (this.isElevatorBuilding) {
      this.filteredByGroup = groupBy(this.filtered, key => key.keyType);
      this.filteredGroups = Object.keys(this.filteredByGroup).sort(reverseAlphabetical);
    } else {
      this.filteredByGroup = groupBy(this.filtered, key => KeyType.Door);
      this.filteredGroups = Object.keys(this.filteredByGroup);
    }
  }

  scrolledToEnd() {
    this.filter(this.filtered.length + 100);
  }

  public getKeyTypeGroupText(keyGroup: string): string {
    return KeyTypeGroupText[keyGroup as keyof typeof KeyTypeGroupText];
  }

  keyIconType(key: Key): KeyIconType {
    return getKeyIconType(key);
  }

  isSelected(key: Key) {
    return this.selected.has(key);
  }

  isDisabled(key: Key): boolean {
    return this.disabledKeys.some(disabledKey => disabledKey.keyUUID === key.uuid) || this.isDeliveriesKey(key);
  }

  disabledTooltip(key: Key): string {
    const disabledKey = this.disabledKeys.find(keyItem => keyItem.keyUUID === key.uuid) as DisabledKey;
    if (disabledKey) {
      return disabledKey.tooltipText;
    } else {
      // If this key is not in the disabled list but isDisabled, it must be a deliveries key.
      return 'Your Portfolio Manager opted-in for automated deliveries. This Deliveries Key was created by Latch and is securely shared ' +
        'with trusted delivery carriers such as UPS and other national carriers to enable deliveries when recipients are away.';
    }
  }

  isDeliveriesKey(key: Key): boolean {
    return key.ownershipType === KeyOwnershipType.Delivery;
  }

  handleToggle(key: Key) {
    if (this.isSelected(key)) {
      this.selected.delete(key);
    } else {
      this.selected.add(key);
    }
  }

  handleNewKey(key: Key) {
    this.showMakeKey = false;
    this.keys = this.keys.concat(key);
    this.selected.add(key);
    this.created.emit(key);
    this.removeDisabledFromSelected();
  }

  handleDone() {
    this.done.emit(Array.from(this.selected));
  }

  public selectMakeNewKey(): void {
    this.showMakeKey = true;
  }

  trackByUUID(index: number, item: Key) {
    if (!item) {
      return null;
    }
    return item.uuid;
  }

  countKeyIntercoms(key: Key): number {
    const keyLockUUIDs: string[] = key.doors.map(door => door.lockUUID);
    const intercomLockUUIDs: string[] = this.intercoms.map(intercom => intercom.lockUUID);
    return keyLockUUIDs.filter(keyLockUUID => intercomLockUUIDs.indexOf(keyLockUUID) >= 0).length;
  }

  private removeDisabledFromSelected() {
    this.disabledKeys.forEach((disabledKey) => {
      this.selected.delete(this.keys.find(key => key.uuid === disabledKey.keyUUID) as Key);
    });
  }
}
