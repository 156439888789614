import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { getFullName } from '../services/utility/presentation';

@Injectable()
@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(user: { firstName?: string; lastName?: string; }, args?: any): string {
    return getFullName(user.firstName ?? '', user.lastName);
  }
}
