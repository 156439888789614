<latch-table class="latch-table-with-viewport" [data]="datasource" [selection]="false"
  (rowClick)="handleSelectLog($event)" (scrolledToEnd)="loadNext()" [emptyMessage]="emptyListMessage"
  [showLoadMore]="!!logsNextPage"
  (loadMore)="loadNext()">

  <latch-table-column field="name" columnTitle="Name" width="500px">
    <ng-template latchTableCellTemplate let-accessLog>
      <div class="cell-thumbnail" *ngIf="accessLog.photos && accessLog.photos[0]"
        [class.rotate]="accessLog.photos[0].imageSource === ImageSource.LOCK">
        <span class="icon-burst" *ngIf="hasVisualAccessLogsFeature"></span>
        <img [src]="accessLog.photos[0].url">
      </div>
      <div class="cell-thumbnail rotate"
        *ngIf="(!accessLog.photos || accessLog.photos.length === 0) && accessLog.imageURL">
        <img [src]="accessLog.imageURL">
      </div>
      <div class="cell-name">
        <span class="name-primary" [class.unsuccessful]="!isSuccessful(accessLog)">
          {{ getAccessLogName(accessLog) }}
        </span>
      </div>
    </ng-template>
  </latch-table-column>

  <latch-table-column field="connected" columnTitle="Connected To" width="200px" *ngIf="hasOpenkitMultiPartnerFeature">
    <ng-template latchTableCellTemplate let-accessLog>
      <ng-container *ngIf="accessLog.partnerUUIDs">Partner / {{ getPartnerName(accessLog.partnerUUIDs[0]) }}</ng-container>
      <ng-template #partner>
        Partners:<br><br>
        <ng-container *ngFor="let partnerUUID of accessLog.partnerUUIDs">
          {{ getPartnerName(partnerUUID) }}<br>
        </ng-container>
      </ng-template>
      <button class="latch-button-icon" *ngIf="(accessLog.partnerUUIDs?.length ?? 0) > 1">
        <span class="icon-more-partners"
          latchTooltip
          [tooltipHover]="true"
          [tooltipTemplate]="partner">
        </span>
      </button>
    </ng-template>
  </latch-table-column>

  <latch-table-column field="door" columnTitle="Door" width="200px">
    <ng-template latchTableCellTemplate let-accessLog>
      {{ getLockName(accessLog.lockUUID) }}
    </ng-template>
  </latch-table-column>

  <latch-table-column field="time" columnTitle="Time" [sortable]="true" width="200px">
    <ng-template latchTableCellTemplate let-accessLog>
      <div title="{{ accessLog.time | date: 'medium' }}">
        {{ accessLog.time | friendlyDate }}
      </div>
    </ng-template>
  </latch-table-column>

</latch-table>

<!--<div class="list">
  <latch-infinite-scroll [isLoading]="isLoadingLogs" (load)="loadNext()">
    <table class="data-table">
      <thead>
        <th width="82" class="thumbnail"></th>
        <th width="25%">
          <span class="text-line">Name</span>
        </th>
        <th width="25%">
          <span class="text-line">Door</span>
        </th>
        <th width="45%">
          <latch-sorter-ui (toggle)="handleSortWhen($event)" #sortWhen>
            <span class="text-line">When</span>
          </latch-sorter-ui>
        </th>
      </thead>
      <tbody>
        <tr [class.unsuccessful]="!isSuccessful(accessLog)"
          *ngFor="let accessLog of accessLogs" (click)="handleSelectLog(accessLog)">
          <td class="thumbnail">
            <div *ngIf="accessLog.photos && accessLog.photos[0]"
              class="frame"
              [class.rotate]="accessLog.photos[0].imageSource === ImageSource.LOCK">
              <div class="icon-burst" *ngIf="hasVisualAccessLogsFeature"></div>
              <img [src]="accessLog.photos[0].url">
            </div>
            <div *ngIf="(!accessLog.photos || accessLog.photos.length === 0) && accessLog.imageURL"
              class="frame rotate">
              <img [src]="accessLog.imageURL">
            </div>
          </td>
          <td class="name">{{getAccessLogName(accessLog)}}</td>
          <td>{{getLockName(accessLog.lockUUID)}}</td>
          <td class="capitalize" title="{{accessLog.time | date: 'medium'}}">
            {{accessLog.time | friendlyDate}}
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="isEmpty" class="footer-message">
      There are not any logs that you have permission to view.
    </div>
    <div *ngIf="isLoading" class="footer-message">
      <latch-spinner></latch-spinner>
    </div>
    <div *ngIf="noMoreLogs" class="footer-message">
      No more activity to show
    </div>
  </latch-infinite-scroll>
</div> -->

<latch-info-modal [style.visibility]="selectedLog ? 'visible' : 'hidden'" (closeClicked)="selectedLog = undefined">
  <latch-loading [isActive]="isLoadingLogDetail">
    <div class="access-log-info" *ngIf="selectedLog">
      <div class="access-log-container">
        <div class="image-wrapper">
          <div *ngIf="showingPhoto" class="image-container"
            [class.rotate]="showingPhoto.imageSource === ImageSource.LOCK"
            [style.--url]="'url(' + showingPhoto.url + ')'">
          </div>
          <div *ngIf="!showingPhoto && selectedLog.imageURL" class="image-container rotate"
            [style.--url]="'url(' + selectedLog.imageURL + ')'">
          </div>
          <div class="image-container no-image" *ngIf="!showingPhoto && !selectedLog.imageURL">
            <p>{{getMissingPhotoText(selectedLog)}}</p>
          </div>
        </div>
        <div class="burst-image-wrapper" *ngIf="photosData && hasVisualAccessLogsFeature">
          <div class="burst-image-container">
            <div *ngFor="let image of photosData.items; let i = index" class="burst-image"
              [class.rotate]="image.imageSource === ImageSource.LOCK" (click)="setShowingImage(i)"
              [style.background-image]="'url(' + image.url + ')'"
              [class.active]="showingPhoto && showingPhoto.page ===  photosData.currentPage && showingPhoto.index === i">
            </div>
          </div>
          <div class="burst-image-navigation">
            <div class="burst-image-navigation-element total">
              {{ photosData.totalElements }} {{ photosData.totalElements === 1 ? 'image' : 'images' }}
            </div>
            <div class="burst-image-navigation-element pages">
              <a class="latch-button-icon" (click)="photosData.previousPage && setImagesData(photosData.previousPage)"
                [class.disabled]="!photosData.previousPage">
                <span class="icon-blue-arrow-left"></span>
              </a>
              <span>View more</span>
              <a class="latch-button-icon" (click)="photosData.nextPage && setImagesData(photosData.nextPage)"
                [class.disabled]="!photosData.nextPage">
                <span class="icon-blue-arrow-right"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="photo-useful" *ngIf="photosData && selectedLog.visualAccessLogMetadata">
          <span>Was this Visual Access Log helpful?</span>
          <a class="latch-button-icon" (click)="toggleVote(HelpfulVote.Helpful)">
            <span *ngIf="selectedLog.visualAccessLogMetadata.helpfulVote !== HelpfulVote.Helpful"
              class="icon-thumbs-up-empty"></span>
            <span *ngIf="selectedLog.visualAccessLogMetadata.helpfulVote === HelpfulVote.Helpful"
              class="icon-thumbs-up-selected"></span>
          </a>
          <a class="latch-button-icon" (click)="toggleVote(HelpfulVote.NotHelpful)">
            <span *ngIf="selectedLog.visualAccessLogMetadata.helpfulVote !== HelpfulVote.NotHelpful"
              class="icon-thumbs-down-empty"></span>
            <span *ngIf="selectedLog.visualAccessLogMetadata.helpfulVote === HelpfulVote.NotHelpful"
              class="icon-thumbs-down-selected"></span>
          </a>
        </div>
        <div class="access-log-metadata">
          <div class="name" [class.unsuccessful]="!isSuccessful(selectedLog)">
            <a *ngIf="canLinkToPersonDetail(selectedLog.userUUID); else personElseTemplate"
              [routerLink]="['/console/people', selectedLog.userUUID]" queryParamsHandling="preserve">
              {{ getAccessLogName(selectedLog) }}
            </a>
            <ng-template #personElseTemplate>
              {{ getAccessLogName(selectedLog) }}
            </ng-template>
            at
            <a *ngIf="canLinkToLockDetail(selectedLog.lockUUID); else lockElseTemplate"
              [routerLink]="['/console/doors', selectedLog.lockUUID]" queryParamsHandling="preserve">
              {{ getLockName(selectedLog.lockUUID) }}
            </a>
            <ng-template #lockElseTemplate>
              {{ getLockName(selectedLog.lockUUID) }}
            </ng-template>
          </div>
          <div class="details">
            <div>
              <h5>Time of Access</h5>
              {{ selectedLog.time | friendlyDate }}
            </div>
            <div>
              <h5>Access Method</h5>
              {{ getMethodText(selectedLog) }}
            </div>
            <div *ngIf="selectedLog.hostUUID && canLinkToPersonDetail(selectedLog.hostUUID)">
              <h5>Invited By</h5>
              <a [routerLink]="['/console/people', selectedLog.hostUUID]" queryParamsHandling="preserve">
                {{ getPersonName(selectedLog.hostUUID) }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </latch-loading>
</latch-info-modal>
