import { Injectable } from '@angular/core';

/**
 * EnvironmentService is a utility module for modifying the application's host environment.
 * It was introduced for the purpose of adding class names to the top-level `<html>` element
 * in order to define browser-specific or feature-based class names.
 */
@Injectable({ providedIn: 'root' })
export class BrowserEnvironmentService {

  /**
   * Utility helper for adding class names to the root <html> element. Ideally CSS overrides
   * are based on feature-detection vs user agent sniffing, but this method should give us
   * the flexibility to quickly resolve cross-environment problems without too much overhead.
   */
  setGlobalClassNames() {
    const classNames: string[] = [];

    // `ie` and `ie<version>` CSS classes for resolving flexbox issues that don't seem to correspond
    // to categories of flexbox support defined by Modernizr:
    // https://modernizr.com/download?flexbox-flexboxlegacy-flexboxtweener-setclasses
    const ieVersion = getIEVersion();
    if (ieVersion) {
      classNames.push('ie', `ie${ieVersion}`);
    }

    if (classNames.length) {
      // IE does not yet support Element#classList
      document.documentElement.className += ' ' + classNames.join(' ');
    }
  }

}

/**
 * From https://codepen.io/gapcode/pen/vEJNZN - based on the following user agent strings:
 *
 *   IE 10
 *   ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
 *
 *   IE 11
 *   ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
 *
 *   Edge 12 (Spartan)
 *   ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
 *
 *   Edge 13
 *   ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
 *
 * Use this one-off user-agent sniffer for IE (vs a generic library) because we don't really want
 * to be doing anything based on user-agent - we just do it if it becomes necessary.
 */
function getIEVersion() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
}
