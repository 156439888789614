import { Component, EventEmitter, Input, Output } from '@angular/core';
import { hasProperty } from '@latch/latch-web';
import { InsightInstantValue, InsightItem, InsightTimeseries, InsightTimeseriesValue } from 'manager/models/insights';
import { ReportPageService } from 'manager/modules/report/report-page.service';

@Component({
  selector: 'latch-insight-card',
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss']
})
export class InsightCardComponent {

  @Input() public data!: InsightItem;

  @Input() public selectedTimeRange!: string;

  @Output() public hyperlinkClick = new EventEmitter();

  constructor(
    private reportPageService: ReportPageService,
  ) { }

  public getTimeseriesValue(timeseries: InsightTimeseries): InsightTimeseriesValue | undefined {
    return timeseries.timeseriesCardValuesByTimeRange[this.selectedTimeRange];
  }

  public dataIsInstantValue(data: InsightItem): data is InsightInstantValue {
    return hasProperty(data, 'value');
  }

  public dataIsTimeseries(data: InsightItem): data is InsightTimeseries {
    return hasProperty(data, 'timeseriesCardValuesByTimeRange');
  }

  public onHyperlinkClick(): void {
    this.reportPageService.navigateToReport(this.data.reportHyperlink);
    this.hyperlinkClick.emit();
  }

}
