import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ensure, LatchDialogRef, LatchDialogService, LatchNavbarStateService, logInfo } from '@latch/latch-web';
import { asapScheduler, EMPTY, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, observeOn, switchMap, tap } from 'rxjs/operators';
import { getLeafRoute, NavigatedPopup } from './router-utility';
import { environment } from 'environments/interface';

@Injectable({ providedIn: 'root' })
export class RouterEventsService {

  private popup$ = new ReplaySubject(1);

  private navigationEnd$ = new ReplaySubject<string>(1);

  private subscription: Subscription | undefined;

  private popupSubscription: Subscription | undefined;

  private activatedLeafRoute: ActivatedRoute | undefined;

  private popups: NavigatedPopup[] = [];

  private currentlyOpenPopup: LatchDialogRef | undefined;

  constructor(
    private router: Router,
    private navbarStateService: LatchNavbarStateService,
    private dialog: LatchDialogService,
  ) {
  }

  public initialize(): void {
    if (!this.subscription) {
      this.subscription = this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => {
          const url = event.urlAfterRedirects;
          const questionMarkIndex = url.indexOf('?');
          const popupIndex = url.indexOf(';popup');
          let baseUrl = questionMarkIndex === -1 ? url : url.substring(0, questionMarkIndex);
          baseUrl = popupIndex === -1 ? baseUrl : url.substring(0, popupIndex);
          this.popup$.next(event);
          return baseUrl;
        }),
        distinctUntilChanged(),
        tap((baseUrl) => this.navigationEnd$.next(baseUrl)),
        tap((baseUrl) => this.logRouteChange(baseUrl)),
        filter(() => {
          // route changes but the activated component stays the same, so don't reset filters
          const newLeafRoute = getLeafRoute(this.router);
          const isDifferentLeafRoute = this.activatedLeafRoute !== newLeafRoute;
          this.activatedLeafRoute = newLeafRoute;
          return isDifferentLeafRoute;
        }),
      ).subscribe(() => {
        // reset filters only when the new page is visited, ignore events when only query params are changing
        this.navbarStateService.initializeFilter([]);
        this.navbarStateService.hideSearch();
      });
    }

    this.initializePopupSubscription();
  }

  public getNavigationEnd(): Observable<string> {
    return this.navigationEnd$.asObservable();
  }

  public registerPopups(popups: NavigatedPopup[]): void {
    this.popups.push(...popups);
  }

  private initializePopupSubscription(): void {
    if (!this.popupSubscription) {
      this.popupSubscription = this.popup$.pipe(
        switchMap(() => {
          if (this.currentlyOpenPopup) {
            this.currentlyOpenPopup.close();
            this.currentlyOpenPopup = undefined;
          }

          const leafRoute = getLeafRoute(this.router);
          const type: string | undefined = leafRoute.snapshot.params.popup;
          if (type) {
            this.currentlyOpenPopup = this.openPopup(type);
            return this.currentlyOpenPopup.afterClosed().pipe(
              // if dialog closes in the middle of navigation make sure that the navigation
              // has ended before resetting the popup params, thats what asapScheduler takes care of
              observeOn(asapScheduler),
              tap(() => this.resetPopupParams()),
            );
          }
          return EMPTY;
        }),
      ).subscribe();
    }
  }

  private openPopup(type: string): LatchDialogRef {
    const navigatedPopup = ensure(this.popups.find(popup => popup.type === type));
    const leafRoute = getLeafRoute(this.router);
    return this.dialog.open(navigatedPopup.popup, {
      data: leafRoute.snapshot.params,
      width: '810px',
      maxWidth: '100vw',
    });
  }

  private resetPopupParams(): void {
    const leafRoute = getLeafRoute(this.router);
    this.router.navigate(['./', {}], {
      queryParamsHandling: 'preserve',
      relativeTo: leafRoute,
    });
  }

  public navigateToPopup(
    type: string,
    params: Params,
    path = './',
  ): void {
    const popupParams = {
      popup: type,
      ...params,
    };
    const leafRoute = getLeafRoute(this.router);
    this.router.navigate(
      [path, popupParams],
      {
        queryParamsHandling: 'preserve',
        relativeTo: leafRoute,
      }
    );
  }

  private logRouteChange(baseUrl: any) {
    if (!environment.enableLogging) {
      return;
    }

    const message = `Route Change: ${ baseUrl }`;
    const data: Record<string, any> = { url: baseUrl };
    logInfo(message, { data });
  }

}

export function getPersonInvitePopupRouterLink(uuid: string): unknown[] {
  return ['/console/people', uuid, 'profile', { popup: 'invite', uuid }];
}
