import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReportService, BaseReportInput, GetReportInput, ExportReportInput, GetFieldOptionsInput } from './report.service';
import { ReportsTokenPagedResponse, Report, ReportKpi, FieldOption } from 'manager/models/report';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class HTTPReportService extends ReportService {
  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  public getReports(data: BaseReportInput): Observable<Report[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${data.accountUUID}/buildings/${data.buildingUUID}/reports`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map((response: { reports: Report[] }) => response.reports),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public getReportData(data: GetReportInput): Observable<ReportsTokenPagedResponse<unknown>> {
    const search: { [param: string]: string | string[] } = {};
    if (data.pageToken) {
      search.pageToken = data.pageToken;
    }
    if (data.sort) {
      search.orderBy = data.sort;
    }
    if (data.filter) {
      search.filter = data.filter;
    }
    return this.authService
      .request({
        method: 'get',
        search,
        endpoint: `/web/v1/accounts/${data.accountUUID}/buildings/${data.buildingUUID}/reports/${data.type}`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public exportReportData(data: ExportReportInput): Observable<unknown> {
    const search: { [param: string]: string | string[]; } = {};
    if (data.filter) {
      search.filter = data.filter;
    }

    return this.authService
      .request({
        method: 'get',
        search,
        responseType: 'blob',
        endpoint: `/web/v1/accounts/${data.accountUUID}/buildings/${data.buildingUUID}/reports/${data.type}/csv`
      }).pipe(
        map((response: { body: Blob }) => response.body),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public getKpis(data: BaseReportInput): Observable<ReportKpi[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${data.accountUUID}/buildings/${data.buildingUUID}/kpis`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map((response: { kpis: ReportKpi[] }) => response.kpis),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public getFieldOptions(data: GetFieldOptionsInput): Observable<FieldOption[]> {
    const search: { [param: string]: string | string[]; } = {};
    if (data.term) {
      search.contains = data.term;
    }
    return this.authService
      .request({
        method: 'get',
        search,
        endpoint:
          `/web/v1/accounts/${data.accountUUID}/buildings/${data.buildingUUID}/reports/${data.reportName}/fields/${data.fieldName}/values`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map((response: { options: FieldOption[] }) => response.options),
        catchError((error: Error) => of([])),
      );
  }

  private handleError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}
