import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, fromEvent, merge, Observable, Subject } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { Building } from '../../models/building';
import { ErrorHandlerService } from '../../services/appstate/error-handler.service';
import { NotificationService } from '../../services/notification/notification.service';
import { ConstantsService } from '../../../shared/services/constants.service';
import { SelectedBuildingsService } from '../../services/appstate/selected-buildings.service';
import { UIStateService } from '../../services/appstate/ui-state.service';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActiveOperationsService } from '../../services/active-operations/active-operations.service';
import { ActiveOperationsStatus } from '../../models/operations';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { naturalSortBy } from 'manager/services/utility/utility';
import {
  LatchAnalyticsService,
  LatchDialogService,
  LatchMenuItem,
  LatchMfaService,
  LatchNavbarProfileMenu, LatchNavbarStateService,
  LatchNavLink,
  logInfo,
  NativeAppService,
  User
} from '@latch/latch-web';
import { FullNamePipe } from 'manager/pipes/full-name.pipe';
import { SelectedAccountService } from 'manager/services/appstate/selected-account.service';
import { Account } from 'manager/models/account';
import { NotificationCenterService } from 'manager/services/notification-center/notification-center.service';
import { SprigService } from 'manager/services/sprig/sprig.service';
import { IntegrationType } from 'manager/modules/integrations/models/integrations';
import { FailureDetailsService } from './failure-details/failure-details.service';
import { AccountService } from '../../services/account/account.service';
import { environment } from 'environments/interface';
import { DoorAppPopupComponent } from './door-app-popup/door-app-popup.component';

/**
 * Primary management console page after login.
 *
 * Contains the actual contents of the management console. Distinct from {@link AppComponent} because AppComponent contains the
 * entire page (including, for example, the login page), whereas ConsoleComponent contains the page visible after login.
 */
@Component({
  selector: 'latch-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss'],
  animations: [
    trigger('notificationState', [
      state('active', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate(300)
      ]),
      transition(':leave', [
        animate(300, style({ transform: 'translateY(100%)' }))
      ])
    ])
  ],
  providers: [NotificationCenterService],
})
export class ConsoleComponent implements OnInit, OnDestroy {
  isLoading = false;

  get currentUser(): User | null {
    return this.authService.currentUser;
  }

  get showNotificationIcon(): boolean {
    return this.notificationCenterService.showAlert;
  }

  get notificationMessage(): string {
    return this.notificationService.message;
  }

  get messageState(): string {
    return this.notificationMessage ? 'active' : 'inactive';
  }

  get errorHeader(): string {
    return this.notificationService.errorHeader;
  }

  get errorBody(): string {
    return this.notificationService.errorBody;
  }

  get defaultSupportMessage(): boolean {
    return !this.notificationService.disableDefaultMessage;
  }

  get errorState(): string {
    return this.errorBody ? 'active' : 'inactive';
  }

  buildings: Building[] = [];
  selectedBuilding!: Building;
  emptyBuilding: Building = {
    uuid: '',
    name: '',
  };
  public accounts: Account[] = [];
  public selectedAccount!: Account;
  public globalSelectedBuilding!: Building;
  public globalSelectedAccount!: Account;
  public hasSelfSetup = this.featureService.hasSelfSetupFeature$;

  showDevices$: Observable<boolean> = this.featureService.hasSmartHomeFeature$;
  public showCheckin = false;

  public profileMenu: LatchNavbarProfileMenu = {
    label: this.currentUser ? new FullNamePipe().transform(this.currentUser) : '',
    logout: () => this.logout(),
    profile: '/console/account',
    support: '/console/support',
    resources: '/console/resources',
    menuItems: [
      {
        name: 'Feedback',
        clickHandler: () => this.sprigService.track('MW-feedback'),
      }
    ],
  };

  public settingsMenuItems: LatchMenuItem[] = [];

  public navLinks: LatchNavLink[] = [];

  hasActiveOperations = false;

  public isFocusMode$: Observable<boolean> = this.uiStateService.isFocusMode();

  showDoorExperience = environment.showDoorExperience;

  hasBuildingChanged = true;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private selectedBuildingsService: SelectedBuildingsService,
    private errorHandlerService: ErrorHandlerService,
    private notificationService: NotificationService,
    private constants: ConstantsService,
    private uiStateService: UIStateService,
    private permissionsService: PermissionsService,
    private activeOperationsService: ActiveOperationsService,
    private featureService: FeatureService,
    private notificationCenterService: NotificationCenterService,
    private accountService: AccountService,
    private selectedAccountService: SelectedAccountService,
    private mfaService: LatchMfaService,
    private analyticsService: LatchAnalyticsService,
    private sprigService: SprigService,
    private failureDetailsService: FailureDetailsService,
    private navbarStateService: LatchNavbarStateService,
    private nativeAppService: NativeAppService,
    private dialog: LatchDialogService,
  ) { }

  ngOnInit(): void {
    if (this.nativeAppService.isRunningInApp()) {
      this.uiStateService.enableFocusMode();
    }

    // If the user initiated an MFA reset we log them out in order to force them to reset it.
    this.mfaService.getUserMFASettings().subscribe(({ mfaEnabled }) => {
      const mfaReset = localStorage.getItem(this.constants.MfaReset);
      localStorage.removeItem(this.constants.MfaReset);
      if (mfaReset && !mfaEnabled) {
        this.logout();
      }
    });

    combineLatest([
      this.accountService.getAccounts().pipe(
        tap(accounts => this.accounts = accounts),
      ),
      this.selectedAccountService.getSelectedAccount()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([accounts, selectedAccount]) => {
        // find the same account object in the accounts array to have the same consistent reference
        this.selectedAccount = selectedAccount;
        this.globalSelectedAccount = this.selectedAccount;

        if (this.selectedAccount.buildings?.length === 0) {
          this.router.navigate(['/console/no-building']);
          logInfo('No buildings in Portfolio');
          return;
        }

        this.updateBuildings();
      });

    this.navbarStateService.user.next(this.currentUser as User);
    this.selectedBuildingsService.getSelectedBuilding().pipe(
      filter(building => {
        if (!building) {
          // Redirect to the first available building, triggering the observable chain to emit again
          this.selectedAccountService.getSelectedAccount().subscribe(selectedAccount => {
            const selectedBuilding = selectedAccount.buildings && selectedAccount.buildings[0];
            if (selectedBuilding) {
              this.handleSelectedBuildingChange(selectedBuilding);
            }
          });
        } else {
          this.selectedBuilding = building;
          this.globalSelectedBuilding = building;
        }
        return !!building;
      }),
      distinctUntilChanged(),
      switchMap(() => combineLatest([
        this.featureService.hasDeliveryAssistantFeature$,
        this.featureService.hasPackageManagementFeature$,
        this.featureService.hasPmsManagedFeature$,
        this.featureService.hasAccessAutomationFeature$,
        this.featureService.hasBundlesFeature$,
        this.permissionsService.currentUserIsOwner(),
        this.permissionsService.currentUserKeyAdminPermissions(),
        this.permissionsService.currentUserIsInstaller(),
        this.featureService.hasCommercialFeature$,
        this.featureService.hasLeasePayOrRentPayFeature$,
        this.featureService.hasDoorSchedulesFeature$,
        this.featureService.hasUnitFeature$,
        this.featureService.hasSpacesFeature$,
        this.featureService.hasIntercomFeature$,
        this.featureService.hasVirtualIntercomFeature$,
        this.featureService.hasBookingsFeature$,
        this.featureService.hasStripePaymentFeature$,
        this.featureService.hasConciergeFeature$,
        this.featureService.hasInsightsDashboardFeature$,
        this.featureService.hasActivityFeature$,
        this.featureService.hasReportingFeature$,
        this.permissionsService.currentUserIsOwner(),
        this.permissionsService.currentUserMaySeeSomeKeys(),
      ])),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: ([
        hasDeliveryAssistantFeature,
        hasPackageManagementFeature,
        hasPmsManagedFeature,
        hasAccessAutomationFeature,
        hasBundlingFeature,
        isOwner,
        keyAdminPermissions,
        isInstaller,
        showCheckin,
        hasRentPayments,
        showDoorSchedules,
        showUnits,
        showSpaces,
        hasHardwareIntercom,
        hasVirtualIntercom,
        showBookings,
        showStripePayments,
        hasConcierge,
        hasInsightsDashboard,
        hasActivityFeature,
        hasReportingFeature,
        currentUserIsOwner,
        currentUserMaySeeSomeKeys,
      ]) => {
        this.setBuildingAnalyticsInfo(hasDeliveryAssistantFeature, hasPackageManagementFeature);
        const integrationType = hasPmsManagedFeature ?
          (hasAccessAutomationFeature ? IntegrationType.AutomateAccess : IntegrationType.AutomateUsers) : undefined;
        this.analyticsService.addEventProperties({
          'Building Name': this.selectedBuilding.name,
          'Building ePMS Source': this.selectedBuilding.pmsSource ?? undefined,
          'Building ePMS Integration Type': integrationType,
          'Is Admin': isOwner,
          'Key Manager Type': keyAdminPermissions,
          'Device Manager Type': isOwner ? 'Full' : (isInstaller ? 'Installer' : undefined),
        });
        this.sprigService.setAttributes({
          'Building Name': this.selectedBuilding.name,
          'Building ePMS Source': this.selectedBuilding.pmsSource ?? undefined,
          'Building ePMS Integration Type': integrationType,
          'Is Admin': isOwner,
          'Key Manager Type': keyAdminPermissions,
          'Device Manager Type': isOwner ? 'Full' : (isInstaller ? 'Installer' : undefined),
        });
        this.failureDetailsService.reset();

        // Add link to the bundles page
        if (hasBundlingFeature) {
          this.profileMenu.menuItems.unshift({
            name: 'My Account',
            clickHandler: () => {
              this.router.navigate(['console/bundles'], { queryParamsHandling: 'preserve' });
            },
          });
        }

        this.showCheckin = showCheckin;
        this.navLinks = [];
        if (showCheckin) {
          this.navLinks = [
            ...this.navLinks,
            { id: 'header-checkin-link', name: 'Visitors', path: '/console/checkin/visitors' },
            { id: 'header-property-link', name: 'Property', path: '/console/checkin/tenants' },
          ];
        } else {
          this.navLinks.push({ id: 'header-people-link', name: 'People', path: '/console/people' });
          if (environment.showDoorExperience) {
            this.navLinks.push({ id: 'header-maintenance', name: 'Maintenance', path: '/console/maintenance' });
            this.navLinks.push({ id: 'header-controls', name: 'Controls', path: '/console/building-health' });
            this.navLinks.push({ id: 'header-inventory', name: 'Inventory', path: '/console/inventory' });
          }
          this.navLinks.push({ id: 'header-access-link', name: 'Access', path: '/console/access' });

          if (showBookings) {
            this.navLinks.push({ id: 'header-bookings-link', name: 'Bookings', path: '/console/bookings' });
          }
          if (hasActivityFeature) {
            this.navLinks.push({ id: 'header-activity-link', name: 'Activity', path: '/console/activity' });
          }
          if (hasInsightsDashboard) {
            this.navLinks.unshift({ id: 'header-dashboard-link', name: 'Dashboard', path: '/console/dashboard' });
          }
          if (hasRentPayments) {
            this.navLinks.push({ id: 'header-lease-link', name: 'Rent', path: '/console/leases' });
          }
          if (hasReportingFeature) {
            this.navLinks.push({ id: 'header-reports-link', name: 'Reports', path: '/console/reports' });
          }
        }
        if (hasConcierge) {
          this.navLinks.unshift({ id: 'header-concierge-link', name: 'Concierge', path: '/console/concierge' });
        }

        // Set nav links in navbar service
        this.navbarStateService.navLinks.next(this.navLinks);

        this.settingsMenuItems = [];

        if (showUnits) {
          this.settingsMenuItems.push({ name: 'Manage Unit Settings', path: '/console/units' });
        }

        if (showSpaces) {
          this.settingsMenuItems.push({ name: 'Manage Spaces', path: '/console/spaces' });
        }

        if ((hasHardwareIntercom || hasVirtualIntercom) && currentUserMaySeeSomeKeys) {
          this.settingsMenuItems.push({ name: 'Intercom Settings', path: '/console/intercom-settings' });
        }

        this.settingsMenuItems.push({ name: 'Integrations', path: '/console/integrations' });

        if (currentUserIsOwner) {
          // Traditionally we have shown menu items even to people who don't have the ability to use them, but disabled
          // them and put up a tooltip. Currently the nav bar doesn't support tooltips here, and we think this is a
          // better experience than giving them a menu item they can't use.
          this.settingsMenuItems.push({ name: 'Staff', path: '/console/staff' });

          if (hasRentPayments) {
            this.settingsMenuItems.push({ name: 'Payments', path: '/console/payments/business-accounts' });
          } else if (showStripePayments) {
            this.settingsMenuItems.push({ name: 'Payments', path: '/console/payments/bookings-accounts' });
          }
        }

        if (showDoorSchedules) {
          this.settingsMenuItems.push({ name: 'Schedules', path: '/console/door-schedules' });
        }

        this.featureService.hasShowDoorPopup$.subscribe(hasFeature => {
          if (hasFeature && this.hasBuildingChanged) {
            this.dialog.close();
            this.dialog.open(DoorAppPopupComponent, { width: '675px' });
          }
        });
      },
      error: () => {
        // Encountered an error in retrieving the list of buildings.
        // Empty error handler here to avoid allowing this error to be rethrown as a global error.
        // Note that the case where current UUID is invalid
        // will not reach here - instead, getSelectedBuilding will
        // return the first valid building as the selected building, but also redirect.
      }
    });

    // Auto-logout if nothing happens for some period of time (InactivityAutoLogoutTimeout). Use
    // runOutsideAngular to prevent change-detection from running on every event.
    this.ngZone.runOutsideAngular(() => {
      merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'touchstart')
      ).pipe(
        debounceTime(this.constants.InactivityAutoLogoutTimeout),
        takeUntil(this.unsubscribe$),
      ).subscribe(() => this.ngZone.run(() => this.logout()));
    });

    this.activeOperationsService.getOperationStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.hasActiveOperations = status !== ActiveOperationsStatus.NO_ACTIVE_OPERATIONS;
      });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  shouldFillViewport(): boolean {
    // Some pages (activity) want the page to fill the window rather than have the entire page scroll. This allows
    // those pages to implement "internal scrolling", where they fill the remaining window space and have internal
    // divs that allow the user to scroll through longer content.
    // We mark these pages in the router by giving them a data param of fillViewport: true.
    if (this.activatedRoute.snapshot.children.length > 0) {
      // In actual use, the route should always have children. This covers us during unit tests.
      const data = this.activatedRoute.snapshot.children[0].data;
      return 'fillViewport' in data && data.fillViewport;
    }

    return false;
  }

  clearNotificationMessage() {
    this.notificationService.clearMessage();
  }

  clearError() {
    this.errorHandlerService.clear();
  }

  logout() {
    this.errorHandlerService.clear();
    this.isLoading = true;
    this.authService.logout().subscribe();
  }

  openNotificationCenter() {
    this.notificationCenterService.refreshNotifications();
    this.router.navigate(['', { outlets: { popup: ['notifications'] } }], { queryParamsHandling: 'preserve' });
  }

  public handleSelectedAccountChange(account: Account) {
    this.selectedAccount = account;
    this.updateBuildings();
  }

  private updateBuildings(): void {
    this.buildings = naturalSortBy(this.selectedAccount.buildings ?? [], 'name');
  }

  public handleSelectedBuildingChange(building: Building) {
    this.hasBuildingChanged = !this.selectedBuilding || this.selectedBuilding.uuid !== building.uuid;
    const urlTree = this.router.createUrlTree([''], { queryParams: { building: building.uuid } });
    const url = this.router.serializeUrl(urlTree);

    if (this.globalSelectedAccount !== this.selectedAccount) {
      this.selectedAccountService.selectAccount(this.selectedAccount.uuid);
      location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
    this.globalSelectedAccount = this.selectedAccount;
    this.globalSelectedBuilding = this.selectedBuilding;
  }

  public onMegaMenuOpen(isOpen: boolean): void {
    if (isOpen) {
      // reset to default values when the mega menu opens up, so we don't show old state
      this.selectedAccount = this.globalSelectedAccount;
      this.selectedBuilding = this.globalSelectedBuilding;
      this.updateBuildings();
    }
  }

  openCreatePropertyFlow() {
    const route = ['', { outlets: { popup: ['new-property'] } }];
    const queryParams = { account: this.selectedAccount.uuid };
    this.router.navigate(route, { queryParamsHandling: 'merge', queryParams });
  }

  private setBuildingAnalyticsInfo(
    hasDeliveryAssistantFeature: boolean,
    hasPackageManagementFeature: boolean
  ): void {
    this.analyticsService.track('Manager - Change - Building', {
      'Building UUID': this.selectedBuilding.uuid,
      'Building Timezone': this.selectedBuilding.timezone,
      'LDA Building': hasDeliveryAssistantFeature,
      'LDA Active Building': hasPackageManagementFeature
    });
  }
}
