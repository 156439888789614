import { Injectable } from '@angular/core';
import { Person } from 'manager/models/contact-cards';
import { QualifiedDay } from 'manager/models/key';
import { SpaceId } from 'manager/modules/spaces/models/spaces';
import { Observable } from 'rxjs';
import {
  AppointmentSlot,
  BookableResource,
  BookableResourceStatus,
  BookingActivityStatus,
  BookingAppointment,
  BookingAppointmentType,
  PaymentType,
  SlotDuration,
  TermsAndConditions
} from '../models/booking';

export interface GetBookableResourcesInput {
  buildingUUID: string;
  search?: string | null;
  page: number;
  limit?: number;
  sortNameAscending: boolean;
}

// todo: paging
export interface GetBookingAppointmentsInput {
  buildingUUID: string;
  bookableResourceUUID: string;
  start: number;
  end: number;
  page?: number;
  limit?: number;
  search?: string;
}

export interface UpdateBookableResource {
  buildingUUID: string;
  uuid: string;
  name: string;
  maxSlots: number;
  maxConcurrent: number;
  slotDuration: SlotDuration;
  bookablePeriods: QualifiedDay[];
  maxAdvanceDays: number;
  additionalInformation: string;
  paymentType: PaymentType;
  timeSlotAmount: number;
  notificationEnabled: boolean;
  status: BookableResourceStatus;
  spaces?: SpaceId[];
  termsAndConditions?: TermsAndConditions & {
    file?: File,
  }
}

export interface CreateBookableResource {
  buildingUUID: string;
  name: string;
  maxSlots: number;
  maxConcurrent: number;
  slotDuration: SlotDuration;
  bookablePeriods: QualifiedDay[];
  maxAdvanceDays: number;
  additionalInformation: string;
  paymentType: PaymentType;
  timeSlotAmount: number;
  notificationEnabled: boolean;
  spaces?: SpaceId[];
  termsAndConditions?: TermsAndConditions & {
    file?: File,
  }
}

export interface CreateBookingAppointmentTimeSlot {
  startTimeEpoch: number;
  endTimeEpoch: number;
  multiplicity: number;
}

export interface CreateBookingAppointmentsInput {
  bookableResourceUUID: string;
  timeSlots: CreateBookingAppointmentTimeSlot[];
  appointmentType: BookingAppointmentType;
  reservationNotes: string;
}

export interface DeleteBookingAppointmentInput {
  bookingAppointmentUUID: string;
}

export interface DeleteBookingAppointmentsInput {
  bookableResource: BookableResource;
  buildingUUID: string;
  start: number;
}

export interface BookableResourcesResult {
  totalSize: number;
  elements: BookableResource[];
}

export interface GetBookingActivityInput {
  buildingUUID: string;
  searchTerm?: string;
  resourceFilter?: BookableResource[];
  peopleFilter?: Person[];
  statusFilter?: BookingActivityStatus[];
  startTimeEpoch?: number;
  endTimeEpoch?: number;
}


@Injectable()
export abstract class BookingService {

  public abstract getBookableResources(input: GetBookableResourcesInput): Observable<BookableResourcesResult>;

  public abstract getBookableResource(buildingUUID: string, uuid: string): Observable<BookableResource>;

  public abstract updateBookableResource(input: UpdateBookableResource): Observable<BookableResource>;

  public abstract createBookableResource(input: CreateBookableResource): Observable<BookableResource>;

  public abstract deleteBookableResource(buildingUUID: string, uuid: string): Observable<null>;

  public abstract getBookingAppointments(input: GetBookingAppointmentsInput): Observable<AppointmentSlot[]>;

  public abstract getBookingsActivity(input: GetBookingActivityInput): Observable<BookingAppointment[]>;

  public abstract revokeBookingAppointment(input: DeleteBookingAppointmentInput): Observable<null>;

  public abstract revokeBookingAppointments(input: DeleteBookingAppointmentsInput): Observable<null>;

  public abstract changeViewBookings(buildingUUID: string, enabled: boolean): Observable<null>;

  public abstract createBookingAppointments(buildingUUID: string,
    input: CreateBookingAppointmentsInput): Observable<null>;

  public abstract blockBookingAppointmentTimeSlots(buildingUUID: string,
    input: CreateBookingAppointmentsInput): Observable<null>;

  public abstract createTermsAndConditions(file: File): Observable<TermsAndConditions>;
}
