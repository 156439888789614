import { Injectable } from '@angular/core';

const ANDROID_RE = /android/i;
const IOS_RE = /iPad|iPhone|iPod/i;

// taken from vars.scss
const BREAKPOINT_LG = '1200px';
const BREAKPOINT_MD = '992px';
const BREAKPOINT_SM = '809px'; // tablet starts from 810px, so -1px is the max-width for the mobile

@Injectable({ providedIn: 'root' })
export class PlatformService {
  get isMediaSmall() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_SM})`).matches;
  }

  get isMediaMedium() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_MD})`).matches;
  }

  get isMediaLarge() {
    return window.matchMedia(`(max-width: ${BREAKPOINT_LG})`).matches;
  }

  get isAndroid() {
    return ANDROID_RE.test(navigator.userAgent);
  }

  get isIOS() {
    return IOS_RE.test(navigator.userAgent);
  }

  get isMobile() {
    return this.isMediaSmall;
  }

  get isTablet() {
    return !this.isMediaSmall && this.isMediaMedium;
  }

  get isDesktop() {
    return !this.isMediaSmall && !this.isMediaMedium;
  }
}
