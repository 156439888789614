import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CreateDoorScheduleResponse, DoorSchedule } from './../../models/door-schedules';


/**
 * Service for interfacting with the door schedules.
 */
@Injectable()
export abstract class DoorSchedulesService {

  abstract createDoorSchedule(
    doorSchedule: DoorSchedule, buildingUUID: string): Observable<CreateDoorScheduleResponse>;

  abstract editDoorSchedule(
    doorSchedule: DoorSchedule, buildingUUID: string, doorScheduleUUID: string
  ): Observable<DoorSchedule>;

  // Returns a list of door schedules for the selected account and building.
  abstract getDoorSchedules(buildingUUID: string): Observable<DoorSchedule[]>;

  // Returns a specific door schedule by UUID for the selected account and building.
  abstract getDoorScheduleByUUID(buildingUUID: string, doorScheduleUUID: string): Observable<DoorSchedule>;

  // Deletes a specific door schedule by UUID for the selected account and building.
  abstract deleteDoorScheduleByUUID(buildingUUID: string, doorScheduleUUID: string): Observable<null>;
}
