import { SelectedKeyDoor } from 'manager/modules/keys/key-doors-list/selectable-lock-item/selectable-lock-item.component';
import { DaysOfWeek, KeyDoor, KeyType, QualifiedDay } from 'manager/models/key';
import { Lock } from 'manager/models/lock';
import { isDefined } from '@latch/latch-web';
import { groupBy, isEqual, keyBy } from './utility';

export interface DoorSettingsUpdated {
  schedulesModified: number;
  schedulesRemoved: number;
  schedulesAdded: number;
  doorcodesEnabled: number;
  doorcodesDisabled: number;
  elevatorDefaultGroups: number;
}

export const getKeyDoorsToDelete = (previousKeyDoors: KeyDoor[], currentKeyDoors: SelectedKeyDoor[]) => {
  const currentKeyDoorsByLockUUID = keyBy(currentKeyDoors, 'lockUUID');
  return previousKeyDoors
    .filter((previousKeyDoor) => !currentKeyDoorsByLockUUID[previousKeyDoor.lockUUID]);
};

export const getKeyDoorsToCreate = (keyType: KeyType, previousKeyDoors: KeyDoor[], currentKeyDoors: SelectedKeyDoor[]) => {
  const previousKeyDoorsByLockUUID = keyBy(previousKeyDoors, 'lockUUID');
  let toCreate = currentKeyDoors.filter((keyDoor) => !previousKeyDoorsByLockUUID[keyDoor.lockUUID]);
  if (keyType === KeyType.Elevator) {
    toCreate = toCreate.map(({ days, ...rest }) => rest);
  }
  return toCreate;
};

const isScheduleRestrictive = (days: QualifiedDay[]) => {
  let isRestrictive = false;
  if (days?.length === 7) {
    const isTwentyFourSeven = days[0].timeIntervals[0].start === 0 && days[0].timeIntervals[0].end === 24 * 60 * 60;
    isRestrictive = !isTwentyFourSeven;
  } else {
    isRestrictive = true;
  }
  return isRestrictive;
};

/** Returns keyDoor's days schedule in standardized order, with days ordered according to their order in DaysOfWeek.
 * This standard format makes it easier to compare schedules. */
const convertDoorSchedule = (keyDoor: SelectedKeyDoor) => {
  const convertedSchedule: QualifiedDay[] = [];
  DaysOfWeek.forEach((day) => {
    if (keyDoor.days?.filter((initialDay) => initialDay.day === day).length) {
      const interval = keyDoor.days?.filter((initialDay) => initialDay.day === day);
      convertedSchedule.push(interval[0]);
    }
  });
  return convertedSchedule;
};

const getKeyDoorSettingsUpdates = (previousKeyDoor: SelectedKeyDoor, currentKeyDoor: SelectedKeyDoor) => {
  const doorSettingsUpdated: DoorSettingsUpdated = {
    schedulesModified: 0,
    schedulesRemoved: 0,
    schedulesAdded: 0,
    doorcodesEnabled: 0,
    doorcodesDisabled: 0,
    elevatorDefaultGroups: 0
  };
  if (isDefined(currentKeyDoor) && isDefined(previousKeyDoor) &&
    (!isEqual(currentKeyDoor.days, previousKeyDoor.days) ||
      (currentKeyDoor.defaultFloorGroup !== previousKeyDoor.defaultFloorGroup) ||
      (currentKeyDoor.selectedFloorGroup !== previousKeyDoor.selectedFloorGroup) ||
      (currentKeyDoor.doorcodeEnabled !== previousKeyDoor.doorcodeEnabled))) {

    /** These schedules might have days listed in different orders. Standardize the order so that they can be compared using isEqual */
    const convertedPreviousDoorSchedule: QualifiedDay[] = convertDoorSchedule(previousKeyDoor);
    const convertedCurrentDoorSchedule: QualifiedDay[] = convertDoorSchedule(currentKeyDoor);
    if (!isEqual(convertedPreviousDoorSchedule, convertedCurrentDoorSchedule)) {
      // check for modified doors schedules
      const isInitialKeyDoorScheduleRestrictive = isScheduleRestrictive(convertedPreviousDoorSchedule || []);
      const isNewKeyDoorScheduleRestrictive = isScheduleRestrictive(convertedCurrentDoorSchedule || []);
      if (isInitialKeyDoorScheduleRestrictive && !isNewKeyDoorScheduleRestrictive) {
        doorSettingsUpdated.schedulesRemoved++;
      }
      if (!isInitialKeyDoorScheduleRestrictive && isNewKeyDoorScheduleRestrictive) {
        doorSettingsUpdated.schedulesAdded++;
      }
      if (isInitialKeyDoorScheduleRestrictive && isNewKeyDoorScheduleRestrictive) {
        doorSettingsUpdated.schedulesModified++;
      }
    }
    if (previousKeyDoor.doorcodeEnabled !== currentKeyDoor.doorcodeEnabled) {
      // checking for doorcodes enabled status changes
      if (currentKeyDoor.doorcodeEnabled) {
        doorSettingsUpdated.doorcodesEnabled++;
      } else {
        doorSettingsUpdated.doorcodesDisabled++;
      }
    }
    if (previousKeyDoor.defaultFloorGroup && previousKeyDoor.defaultFloorGroup &&
      !isEqual(previousKeyDoor.defaultFloorGroup, currentKeyDoor.defaultFloorGroup)) {
      // checking for floor group changes
      doorSettingsUpdated.elevatorDefaultGroups++;
    }
  }
  return doorSettingsUpdated;
};

export const hasKeyDoorChanged = (before: SelectedKeyDoor, after: SelectedKeyDoor) => {
  const doorSettingsUpdated: DoorSettingsUpdated = getKeyDoorSettingsUpdates(before, after);
  const changesCount = doorSettingsUpdated.doorcodesDisabled + doorSettingsUpdated.doorcodesEnabled +
    doorSettingsUpdated.elevatorDefaultGroups + doorSettingsUpdated.schedulesAdded +
    doorSettingsUpdated.schedulesModified + doorSettingsUpdated.schedulesRemoved;
  return changesCount > 0;
};

export const groupDoorsByAccessibility = (selectableLocks: SelectedKeyDoor[], locks: Lock[]) => {
  const groupedLocks: { [doorGroup: string]: SelectedKeyDoor[]; } = groupBy(selectableLocks, (selectableLock) =>
    String(locks.find(item => item.uuid === selectableLock.lockUUID)?.accessibility));
  return groupedLocks;
};


export const getAllKeyDoorsSettingsUpdates = (previousKeyDoors: SelectedKeyDoor[], currentKeyDoors: SelectedKeyDoor[]) => {
  const doorSettingsUpdated: DoorSettingsUpdated = {
    schedulesModified: 0,
    schedulesRemoved: 0,
    schedulesAdded: 0,
    doorcodesEnabled: 0,
    doorcodesDisabled: 0,
    elevatorDefaultGroups: 0
  };
  const previousKeyDoorsByLockUUID = keyBy(previousKeyDoors, 'lockUUID');
  currentKeyDoors
    .forEach((currentKeyDoor) => {
      if (!!previousKeyDoorsByLockUUID[currentKeyDoor.lockUUID] &&
        !hasKeyDoorChanged(previousKeyDoorsByLockUUID[currentKeyDoor.lockUUID], currentKeyDoor)) {
        return;
      }
      const keyDoorSettingsUpdates = getKeyDoorSettingsUpdates(previousKeyDoorsByLockUUID[currentKeyDoor.lockUUID], currentKeyDoor);
      doorSettingsUpdated.doorcodesDisabled += keyDoorSettingsUpdates.doorcodesDisabled;
      doorSettingsUpdated.doorcodesEnabled += keyDoorSettingsUpdates.doorcodesEnabled;
      doorSettingsUpdated.elevatorDefaultGroups += keyDoorSettingsUpdates.elevatorDefaultGroups;
      doorSettingsUpdated.schedulesModified += keyDoorSettingsUpdates.schedulesModified;
      doorSettingsUpdated.schedulesRemoved += keyDoorSettingsUpdates.schedulesRemoved;
      doorSettingsUpdated.schedulesAdded += keyDoorSettingsUpdates.schedulesAdded;
    });
  return doorSettingsUpdated;
};


export const getKeyDoorsToUpdate = (keyType: KeyType, previousKeyDoors: KeyDoor[], currentKeyDoors: SelectedKeyDoor[]) => {
  const previousKeyDoorsByLockUUID = keyBy(previousKeyDoors, 'lockUUID');
  return currentKeyDoors
    .filter((currentkeyDoor) => (!!previousKeyDoorsByLockUUID[currentkeyDoor.lockUUID] &&
      hasKeyDoorChanged(previousKeyDoorsByLockUUID[currentkeyDoor.lockUUID], currentkeyDoor)))
    .map((currentKeyDoor) => {
      if (keyType === KeyType.Door) {
        return {
          uuid: previousKeyDoorsByLockUUID[currentKeyDoor.lockUUID].uuid,
          doorcodeEnabled: currentKeyDoor.doorcodeEnabled,
          days: currentKeyDoor.days
        };
      } else {
        return {
          uuid: previousKeyDoorsByLockUUID[currentKeyDoor.lockUUID].uuid,
          doorcodeEnabled: currentKeyDoor.doorcodeEnabled,
          defaultFloorGroup: currentKeyDoor.defaultFloorGroup
        };
      }
    });
};

