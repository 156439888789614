import moment from 'moment';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  TIME_DISPLAY_FORMAT,
  TIME_PARSE_FORMATS,
  Meridiem,
  DateTimeRange
} from '../../../services/utility/date-time';

@Component({
  selector: 'latch-time-range-input',
  templateUrl: './time-range-input.component.html',
  styleUrls: ['./time-range-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeRangeInputComponent),
    multi: true
  }]
})
export class TimeRangeInputComponent implements ControlValueAccessor, OnInit {
  /** The time input elements (spacer, time and meridiem) are not rendered only allowing to change the date input  */
  @Input() isReadOnly = false;
  @Input() initialValue?: DateTimeRange;

  @Output() blurEvent = new EventEmitter<void>();

  _startTime: string | undefined;
  _startMeridiem: Meridiem | undefined;
  _endTime: string | undefined;
  _endMeridiem: Meridiem | undefined;

  ngOnInit(): void {
    if (this.initialValue) {
      this.startTime = this.initialValue.start.time;
      this.endTime = this.initialValue.end.time;
      this.startMeridiem = this.initialValue.start.meridiem;
      this.endMeridiem = this.initialValue.end.meridiem;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public propagateChange = (data: any): void => { };


  get startTime(): string | undefined {
    return this._startTime;
  }

  set startTime(time: string | undefined) {
    this._startTime = time;
    this.emitChange();
  }

  get startMeridiem(): Meridiem | undefined {
    return this._startMeridiem;
  }

  set startMeridiem(meridiem: Meridiem | undefined) {
    this._startMeridiem = meridiem;
    this.emitChange();
  }

  get endTime(): string | undefined {
    return this._endTime;
  }

  set endTime(time: string | undefined) {
    this._endTime = time;
    this.emitChange();
  }

  get endMeridiem(): Meridiem | undefined {
    return this._endMeridiem;
  }

  set endMeridiem(meridiem: Meridiem | undefined) {
    this._endMeridiem = meridiem;
    this.emitChange();
  }

  public handleBlurStartTime(): void {
    const startTime = moment(`${this.startTime ?? ''} ${this.startMeridiem ?? ''}`, TIME_PARSE_FORMATS, true);
    this.startTime = startTime.isValid() ? startTime.format(TIME_DISPLAY_FORMAT) : this.startTime;
    this.blurEvent.emit();
  }

  public handleBlurEndTime(): void {
    const endTime = moment(`${this.endTime ?? ''} ${this.endMeridiem ?? ''}`, TIME_PARSE_FORMATS, true);
    this.endTime = endTime.isValid() ? endTime.format(TIME_DISPLAY_FORMAT) : this.endTime;
    this.blurEvent.emit();
  }

  public handleMeridiemChange(): void {
    this.blurEvent.emit();
  }

  emitChange() {
    this.propagateChange({
      start: {
        time: this.startTime,
        meridiem: this.startMeridiem
      },
      end: {
        time: this.endTime,
        meridiem: this.endMeridiem
      }
    });
  }

  writeValue(range: DateTimeRange) {
    range = range || {};
    this._startTime = range.start?.time;
    this._endTime = range.end?.time;
    this._startMeridiem = range.start?.meridiem;
    this._endMeridiem = range.end?.meridiem;
  }

  registerOnChange(fn: (data: any) => void) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(): void { }
}
