import { Component, HostBinding, OnInit } from '@angular/core';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { PageWithTabsBaseComponent } from 'manager/components/page-with-tabs-base/page-with-tabs-base.component';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

enum Tab {
  Units = 'Units',
  Doors = 'Doors',
  Devices = 'Devices',
  Keys = 'Keys'
}

@Component({
  selector: 'latch-access-page',
  templateUrl: './access-page.component.html',
  styleUrls: ['./access-page.component.scss'],
  providers: [PageWithTabsService]
})
export class AccessPageComponent extends PageWithTabsBaseComponent<Tab> implements OnInit {
  baseUrl = '/console/access';
  defaultTab = Tab.Units;
  tabData = [
    { id: Tab.Units, path: 'units' },
    { id: Tab.Doors, path: 'doors' },
    { id: Tab.Devices, path: 'devices' },
    { id: Tab.Keys, path: 'keys' }
  ];

  Tab = Tab;
  showDevices$: Observable<boolean> = this.featureService.hasSmartHomeFeature$;

  @HostBinding('style.overflow')
  get styleOverflow(): string {
    return this.tabPathMatchFull && this.showPageContent ? 'hidden' : 'initial';
  }

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected pageWithTabsService: PageWithTabsService,
    private featureService: FeatureService
  ) {
    super(route, router, pageWithTabsService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
