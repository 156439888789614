import { Injectable } from '@angular/core';
import { Lock, LockType } from 'manager/models/lock';
import { MergeConflict, Update } from 'manager/models/notification';
import { Operation } from 'manager/models/operations';
import { PmsPendingAccessChange } from 'manager/models/pms-access';
import { Duplicate, DuplicateEmail } from 'manager/models/user';
import { Subject, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { FeatureService } from '../appstate/feature.service';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';
import { DuplicatesService } from '../duplicates/duplicates.service';
import { LockSyncErrorsService } from '../lock-sync-errors/lock-sync-errors.service';
import { FailedOperationsService } from '../operations/failed-operations.service';
import { PermissionsService } from '../permissions/permissions.service';
import { PmsAccessService } from '../pms-access/pms-access.service';
import { GetServiceOrdersResponse, ServiceOrderService } from '../service-order/service-order.service';

@Injectable({ providedIn: 'root' })
export class NotificationCenterService {
  private _updates: Update[] = [];
  private _failures: Operation<unknown>[] = [];
  private _pmsConflicts?: MergeConflict;
  private _pmsUnmappedUnits?: MergeConflict;
  private _duplicates?: MergeConflict;
  private _duplicateEmails?: MergeConflict;
  private _serviceOrdersResponse?: GetServiceOrdersResponse;
  private refresh$ = new Subject<void>();

  get updates(): Update[] {
    return this._updates;
  }

  get failures(): Operation<unknown>[] {
    return this._failures;
  }

  get pmsConflicts(): MergeConflict | undefined {
    return this._pmsConflicts;
  }

  get pmsUnmappedUnits(): MergeConflict | undefined {
    return this._pmsUnmappedUnits;
  }

  get duplicates(): MergeConflict | undefined {
    return this._duplicates;
  }

  get duplicateEmails(): MergeConflict | undefined {
    return this._duplicateEmails;
  }

  get serviceOrdersResponse(): GetServiceOrdersResponse | undefined {
    return this._serviceOrdersResponse;
  }

  get showAlert(): boolean {
    return this._updates.length > 0 ||
      this._failures.length > 0 ||
      !!this._pmsConflicts ||
      !!this._duplicates ||
      (!!this._serviceOrdersResponse && this._serviceOrdersResponse.serviceOrders.length > 0);
  }

  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private pmsAccessService: PmsAccessService,
    private duplicatesService: DuplicatesService,
    private failedOperationsService: FailedOperationsService,
    private lockSyncErrorsService: LockSyncErrorsService,
    private permissionsService: PermissionsService,
    private featureService: FeatureService,
    private serviceOrderService: ServiceOrderService
  ) {
    // Updates
    this.lockSyncErrorsService.onlineSyncErrors$.pipe(
      catchError(() => of([])),
    ).subscribe(locksNeedingSync => this._updates = this.mapUpdates(locksNeedingSync));

    // Failures
    this.failedOperationsService.failures$.pipe(
      catchError(() => of([])),
    ).subscribe(failures => this._failures = failures);

    const building$ = this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0]),
    );

    // Only try to request duplicates and pending accesses if the user is at least limited key admin
    const userHasPermission$ = this.permissionsService.currentUserMaySeeAllKeys();

    // duplicate accounts
    combineLatest([userHasPermission$, building$, this.refresh$.pipe(startWith(void 0))]).pipe(
      switchMap(([userHasPermission, building]) => userHasPermission && !!building?.pmsSource ?
        this.duplicatesService.getDuplicates(building.uuid) : of(undefined)
      ),
      map(duplicates => duplicates ? duplicates.rows : []),
      catchError(() => of([])),
    ).subscribe(duplicates => this._duplicates = duplicates.length > 0 ? this.mapMergeConflict(duplicates) : undefined);

    // duplicate emails
    combineLatest([userHasPermission$, building$]).pipe(
      switchMap(([userHasPermission, building]) => userHasPermission && !!building?.pmsSource ?
        this.duplicatesService.getDuplicateEmails(building.uuid) : of(undefined)
      ),
      map(duplicates => duplicates ? duplicates : []),
      catchError(() => of([])),
    ).subscribe(duplicates => this._duplicateEmails = duplicates.length > 0 ? this.mapMergeConflict(duplicates) : undefined);

    const hasAccessAutomationFeature$ = this.featureService.hasAccessAutomationFeature$;

    // pending access review
    combineLatest([userHasPermission$, building$]).pipe(
      withLatestFrom(hasAccessAutomationFeature$),
      switchMap(([[userHasPermission, building], hasAccessAutomationFeature]) =>
        userHasPermission && !!building?.pmsSource && hasAccessAutomationFeature ?
          this.pmsAccessService.getPmsAccessReviewData(building.uuid) : of(undefined)
      ),
      catchError(() => of(undefined)),
    ).subscribe(accessReviewData => this._pmsConflicts = accessReviewData &&
      !accessReviewData.isManagerConfirmed &&
      accessReviewData.accessChanges.length > 0 ?
      this.mapMergeConflict(accessReviewData.accessChanges) : undefined
    );

    // unmapped pmsUnits
    combineLatest([userHasPermission$, building$]).pipe(
      switchMap(([userHasPermission, building]) => userHasPermission && !!building?.pmsSource ?
        this.pmsAccessService.getPmsUnits(building.uuid, { mapped: false, limit: 1 }).pipe(
          map(pmsUnits => pmsUnits.count)
        ) : of(0)
      ),
      catchError(() => of(0)),
    ).subscribe(countOfPmsUnits => this._pmsUnmappedUnits = countOfPmsUnits > 0 ? { conflictCount: countOfPmsUnits } : undefined);

    // Service Orders
    const hasServiceOrdersFeature$ = this.featureService.hasServiceOrdersFeature$;
    const currentUserIsOwner$ = this.permissionsService.currentUserIsOwner();

    combineLatest([currentUserIsOwner$, building$]).pipe(
      withLatestFrom(hasServiceOrdersFeature$),
      switchMap(([[currentUserIsOwner, building], hasServiceOrdersFeature]) =>
        currentUserIsOwner && building && hasServiceOrdersFeature ?
          this.serviceOrderService.pollUnacknowledgedServiceOrders(building.uuid) : of(undefined)
      ),
      catchError(() => of(undefined)),
    ).subscribe(serviceOrdersResponse => this._serviceOrdersResponse = serviceOrdersResponse);
  }

  public acknowledgeFailure(operationId: string) {
    return this.failedOperationsService.acknowledgeFailure(operationId);
  }

  public acknowledgeFailures() {
    return this.failedOperationsService.acknowledgeFailures(this._failures.map(failure => failure.operationId));
  }

  public refreshNotifications() {
    this.refresh$.next();
  }

  private mapMergeConflict(data: Duplicate[] | DuplicateEmail[] | PmsPendingAccessChange[]): MergeConflict {
    return {
      conflictCount: data.length,
    };
  }

  private mapUpdates(locksNeedingSync: Lock[]): Update[] {
    return Object.values(LockType).map(type => ({
      type,
      locks: locksNeedingSync
        .filter(lock => lock.lockType === type)
        .map(lock => ({
          name: lock.name,
        })),
    })).filter(updates => updates.locks.length > 0);
  }
}
