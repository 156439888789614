<latch-table class="latch-table-with-viewport" [data]="datasource" [selection]="false" (scrolledToEnd)="loadNext()" [emptyMessage]="emptyListMessage"
  [showLoadMore]="!!logsNextPage"
  (loadMore)="loadNext()">

  <latch-table-column field="name" columnTitle="Name" width="500px">
    <ng-template latchTableCellTemplate let-deviceLog>
      <div class="cell-name">
        <span class="name-primary">
          {{ getSpaceNameForDisplay(deviceLog.spaceUUID) | titlecase }}
        </span>
      </div>
    </ng-template>
  </latch-table-column>

  <latch-table-column field="door" columnTitle="Device" width="200px">
    <ng-template latchTableCellTemplate let-deviceLog>
      {{getDeviceNameForDisplay(deviceLog)}}
    </ng-template>
  </latch-table-column>

  <latch-table-column field="door" columnTitle="Type" width="200px">
    <ng-template latchTableCellTemplate let-deviceLog>
      {{ deviceLog.logType === 'LEAK_DETECTION' ? 'Leak Detected' : deviceLog.logType ?? 'N/A' }}
    </ng-template>
  </latch-table-column>

  <latch-table-column field="time" columnTitle="Time" [sortable]="true" width="200px">
    <ng-template latchTableCellTemplate let-deviceLog>
      <div title="{{ getFriendlyTimestamp(deviceLog) | date: 'medium' }}">
        {{ getFriendlyTimestamp(deviceLog) | friendlyDate }}
      </div>
    </ng-template>
  </latch-table-column>

</latch-table>
