<latch-loading [isActive]="isLoading">
  <ng-container *ngIf="currentStep === SelectCandidateStep.SelectCandidate">
    <latch-card class="page">
      <ng-container latchCardHeader>
        <div class="latch-card-actions">
          <button class="latch-button-icon" latchCardActions (click)="cancel.emit()">
            <span class="latch-icon-chevron-left"></span>
          </button>
        </div>
        <h2 class="latch-card-title">Add Person</h2>
        <p class="page-description">
          With featured entries, you can add a unique item to your intercom’s directory, such as Delivery and Leasing Office. Feature entries will be displayed at the top of your directory.
        </p>
        <input type="text" [formControl]="search" placeholder="Search" class="latch-card-search">

      </ng-container>

      <ng-container latchCardContent>
        <div class="infinite-scroll-content">
          <latch-infinite-scroll class="cell-list"
            [isLoading]="isLoadingNextPage"
            (load)="nextPage && getNextPage(nextPage)">
            <div class="cell cell-row toggle-list" *ngFor="let person of candidates">
              <div class="cell-body" [class.no-access]="!person.hasAccess">
                <div class="cell-text" (click)="person.hasAccess && setCandidate(person)">
                  <input type="radio" [checked]="!!(candidate && candidate.userUUID === person.userUUID)">
                  <div class="text-1">
                    {{person.firstName}} {{person.lastName}}
                  </div>
                </div>
                <div
                  class="icon-warning-circle-gray"
                  latchTooltip
                  tooltipText="This user cannot be selected because they do not have access to the door connected to the intercom.">
                </div>
              </div>
            </div>
            <div *ngIf="isLoadingNextPage" class="footer-message">
              <latch-spinner></latch-spinner>
            </div>
          </latch-infinite-scroll>
        </div>
      </ng-container>
      <ng-container latchCardFooter>
        <button class="latch-button" [disabled]="!candidate" (click)="candidate && selectCandidate(candidate)">
          Add Person
        </button>
      </ng-container>
    </latch-card>
  </ng-container>
  <latch-add-featured-entry-phone-number
    *ngIf="currentStep === SelectCandidateStep.AddPhoneNumber && candidate"
    [candidate]="candidate"
    (phoneNumberAdded)="selectCandidate($event)"
    (cancel)="setCurrentStep(SelectCandidateStep.SelectCandidate)">
  </latch-add-featured-entry-phone-number>
</latch-loading>
