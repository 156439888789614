import { HttpResponse } from '@angular/common/http';
import { hasProperty } from '@latch/latch-web';
import moment from 'moment';

/**
 * Determine whether value should be described as singular or plural. `value` may be an array of items
 * (to check the length of) or just an explicit number representing the count.
 */
export const isPlural = (value: number | any[]) => {
  const count = Array.isArray(value) ? value.length : value;
  return count !== 1;
};

/**
 * Make some label plural (by adding -s or -es) based on count of items to describe. `value` may be an array of items
 * (to check the length of) or just an explicit number representing the count.
 */
export const pluralize = (value: number | any[], label: string): string =>
  isPlural(value) ? label.endsWith('s') ? `${label}es` : `${label}s` : label;

/**
 * Show a count with a properly pluralized label.
 */
export const thisMany = (value: number | any[], label: string) => {
  const count = Array.isArray(value) ? value.length : value;
  return `${count} ${pluralize(count, label)}`;
};

const US_PREFIX_REGEX = /^\+1/;
const NON_NUMERIC_REGEX = /[^\d]+/g;

/**
 * Basic US phone number parser. Removes +1 prefix and non-numbers, then returns first 10 digits.
 */
export const parsePhoneNumber = (phoneNumber: string) => {
  phoneNumber = String(phoneNumber);
  return phoneNumber
    .replace(US_PREFIX_REGEX, '')
    .replace(NON_NUMERIC_REGEX, '')
    .substr(0, 10);
};

/**
 * Turn any form of phone number into one that's just digits and has "+1" at the start. Passing in
 * `null` or `undefined` will return the same.
 */
export const serializePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber === null || phoneNumber === undefined) {
    return phoneNumber;
  }
  phoneNumber = String(phoneNumber);
  return `+1${parsePhoneNumber(phoneNumber)}`;
};


/**
 * Coerce some value to an array (or an empty one for if it's null/undefined).
 */
export const arrayOf = <T>(value: T | T[]): T[] => {
  if (value === null || value === undefined) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
};


/**
 * Alphabetize a collection by some property. Uses localeCompare and takes care of trimming.
 */
export function alphabetizeBy<T, K extends keyof T, F extends (item: T) => string>(
  collection: T[],
  iteratee: K | F
): T[] {
  return collection.sort((el1, el2) => {
    let value1 = '';
    let value2 = '';
    if (typeof iteratee === 'function') {
      value1 = iteratee(el1) || '';
      value2 = iteratee(el2) || '';
    } else {
      value1 = String(el1[iteratee]) || '';
      value2 = String(el2[iteratee]) || '';
    }
    return value1.trim().localeCompare(value2.trim());
  });
}

/**
 * Convert seconds in a day (0 - 86,400) to hh:mma.
 */
export const secondsToTime = (seconds: number) => {
  const totalMinutes = Math.floor(seconds / 60);
  // Use % 24 in case we're dealing with 12:00am at the end of the current day
  const hours24 = Math.floor(totalMinutes / 60) % 24;
  const meridiem = hours24 < 12 ? 'am' : 'pm';
  const hours = (hours24 + 11) % 12 + 1;
  const minutes = totalMinutes % 60;

  const hh = hours.toString().padStart(2, '0');
  const mm = minutes.toString().padStart(2, '0');
  return `${hh}:${mm}${meridiem}`;
};


// https://stackoverflow.com/a/32686261/712895
export const EMAIL_RE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validateEmail = (email: string) => EMAIL_RE.test(email);

/**
 * Wraps data in an HTTP response, in a similar way to what the server would respond with.
 */
export const wrapResponse = <T>(responseData: T): HttpResponse<any> => {
  const body = { payload: { message: responseData } };
  return new HttpResponse({ body });
};

/**
 * cleans and formats 12-digit keycard serial numbers for display
 */
export const formatKeycardSerial = (rawSerial: string | undefined): string => {
  if (!rawSerial) {
    return '';
  }

  const cleanedSerial = rawSerial.replace(/\D/g, '');
  const matched = new RegExp(/(\d{4})(\d{4})(\d{4})/).exec(cleanedSerial);

  if (!matched) {
    return rawSerial;
  }

  return matched.slice(1).join(' ');
};

/**
 * @param epoch epoch time in seconds to turn into date
 */
export function epochSecondsToDate(epoch: number): Date {
  return new Date(epoch * 1000);
}

/**
 * @param date date to turn into epoch seconds
 */
export function dateToEpochSeconds(date: Date): number {
  return date && Math.round(date.getTime() / 1000);
}

/**
 * Produces a short, human-readable summary of the provided items.
 *
 * For example:
 * ['a', 'b', c'], 'keys', 2 -> 'a and 2 other keys'
 * ['a', 'b'], 'keys', 2 -> 'a and b'
 *
 * @param items Item list to be summarized.
 * @param pluralNoun Plural form of noun describing what kind of things are in items.
 *    For example, if each entry in items is the name of a key, pluralNoun might be "keys".
 * @param maxItems Controls how short the summary is. The maximum number of items (or counts)
 *    that should be included. Min is 1.
 */
export function listSummary(items: string[], pluralNoun: string, maxItems: number): string {
  if (items.length <= maxItems) {
    // If we have less than the max number of items, we can include all of them in our summary.
    if (items.length > 1) {
      // If there are multiple, we need to add an ' and ' phrase to join the last two items.
      // All others should be joined with commas.
      const lastItem = items[items.length - 1];
      const allOtherItems = items.slice(0, -1).join(', ');
      return `${allOtherItems} and ${lastItem}`;
    } else if (items.length === 1) {
      // If there's only one item, we just return it.
      return items[0];
    } else {
      return '';
    }
  } else if (maxItems === 1) {
    if (items.length > 1) {
      // If there are more than maxItems and maxItems is 1, we just return the number of items.
      return `${items.length} ${pluralNoun}`;
    } else {
      return '';
    }
  } else {
    // More than 1 item is allowed, and we have more than the max number of items.
    // The last phrase should be a summary count.
    const includedItems = items.slice(0, maxItems - 1).join(', ');
    const numRemainingItems = items.length - maxItems + 1;
    return `${includedItems} and ${numRemainingItems} other ${pluralNoun}`;
  }
}

/**
 * Sort an array by Natural Sort Order
 *
 * For example:
 * ['100A', '1A', '1B', '2A'] =>
 * ['1000', '100A', '1A', '1B', '2A'] (normal sort)
 * ['1A', '1B', '2A', '100A', '1000'] (natural sort)
 *
 * @param collection Array<T>
 * @param iteratee Parameter to sort by.
 */
export function naturalSortBy<T, K extends keyof T, F extends (item: T) => string>(
  collection: T[],
  iteratee: K | F
): T[] {
  return collection.sort((el1, el2) => {
    let value1 = '';
    let value2 = '';
    if (typeof iteratee === 'function') {
      value1 = iteratee(el1) || '';
      value2 = iteratee(el2) || '';
    } else {
      value1 = String(el1[iteratee]) || '';
      value2 = String(el2[iteratee]) || '';
    }
    return value1.localeCompare(value2, navigator.languages[0] ||
      navigator.language,
      { numeric: true }) > 0
      ? 1 : -1;
  });
}

/**
 * omit `''`, `[]`, `null` or `undefined` properties from the object and make shallow copy. it doesn't
 * do a deep check to omit all these properties from the nested objects
 * @param obj object argument to omit properties that are either `''`, `[]`, `null` or `undefined`
 * @returns shallow copy of an object, without those properties
 */
export function omitEmptyNullOrUndefined<T extends object>(obj: T): Partial<T> {
  const result = { ...obj };
  const isEmptyArray = (value: unknown) => Array.isArray(value) && value.length === 0;
  Object.keys(result).forEach((key) => {
    if (hasProperty(result, key) &&
      (result[key] === undefined || result[key] === null || result[key] === '' || isEmptyArray(result[key]))
    ) {
      delete result[key];
    }
  });
  return result;
}

/**
 * Creates the mapping object from the value accessed via the property name or the projection function
 * to the value item itself.
 * @param arr the array to iterate over
 * @param key property name or projection function that will become the map key
 * @returns the composed aggregate map object that maps from the key to the property
 */
export function keyBy<T, K extends keyof T>(arr: T[], key: K | ((item: T) => string)): Record<string, T> {
  return arr.reduce((acc: Record<string, T>, curr) => {
    const tmpKey = typeof key === 'function' ? key(curr) : String(curr[key]);
    acc[tmpKey] = curr;
    return acc;
  }, {});
}

// source: https://stackoverflow.com/questions/40291987/javascript-deep-clone-object-with-circular-references/40293777#40293777
// todo: cleanup cloneDeep when we upgrade to Angular15+, Node17+ and native `structuredClone` becomes available
function cloneDeepRecursion(target: unknown, hash = new WeakMap()): unknown {
  if (target === undefined || target === null) {
    return target;
  }

  if (target instanceof Date) {
    return new Date(target.getTime());
  } else if (moment.isMoment(target)) {
    return target.clone();
  } else if (typeof target === 'object') {
    if (hash.has(target)) {
      return hash.get(target);
    }
    let obj: object;
    try {
      obj = new (target as any).constructor();
    } catch (e) {
      obj = Object.create(Object.getPrototypeOf(target));
    }
    hash.set(target, obj);

    const targetCopyObj = Object.keys(target).reduce((acc: Record<string, unknown>, key) => {
      const value = (target as Record<string, unknown>)[key];
      acc[key] = cloneDeepRecursion(value, hash);
      return acc;
    }, {});
    return Object.assign(obj, targetCopyObj);
  }
  return target;
}

/**
 * Creates a deep clone of the target object
 * @param target target object
 * @returns deep clone of the target object
 */
export function cloneDeep<T extends object | undefined>(target: T): T {
  return cloneDeepRecursion(target) as T;
}

/**
 * make first letter of the string capital letter and maintain others as they are
 * @param str string to capitalize
 * @returns same string where the first letter will be capital letter
 */
export function capitalize(str: string): string {
  return str.length > 0 ? str[0].toUpperCase() + str.substring(1) : str;
}

/**
 * deep equality check of the objects by going the entire structure of
 * the objects and make sure they are equal using recursion
 * @param first object to check equality for
 * @param second object to check equality for
 * @returns wether or not the objects are equal
 */
export function isEqual(first: unknown, second: unknown): boolean {
  /* Checking if the two arguments are strictly equal. */
  if (first === second) {
    return true;
  }

  /* Checking if any arguments are null */
  if (first === null || second === null) {
    return false;
  }

  /* Checking if any argument is non-object */
  if (typeof first !== 'object' || typeof second !== 'object') {
    return false;
  }

  if (first instanceof Date && second instanceof Date) {
    return first.getTime() === second.getTime();
  } else if (moment.isMoment(first) && moment.isMoment(second)) {
    return first.unix() === second.unix();
  }

  /* Using Object.getOwnPropertyNames() method to return the list of the objects’ properties */
  const firstKeys = Object.getOwnPropertyNames(first);
  const secondKeys = Object.getOwnPropertyNames(second);

  /* Checking if the objects' length are same*/
  if (firstKeys.length !== secondKeys.length) {
    return false;
  }

  /* Iterating through all the properties of the first object */
  for (const key of firstKeys) {
    /* Making sure that every property in the first object also exists in second object. */
    if (!Object.prototype.hasOwnProperty.call(second, key)) {
      return false;
    }

    /* Using the function recursively  and passing
          the values of each property into it to check if they are equal. */
    if (!isEqual((first as Record<string, object>)[key], (second as Record<string, object>)[key])) {
      return false;
    }
  }
  /* if no case matches, returning true */
  return true;
}

/**
 * Creates an object composed of keys generated from the results of running each element of collection through
 * projection. The corresponding value of each key is an array of the elements responsible for generating the
 * key. The projection is invoked with one argument: (value).
 * @param arr The collection to iterate over.
 * @param projection The function invoked per iteration.
 * @return Returns the composed aggregate object.
 */
export function groupBy<T>(arr: T[], projection: (item: T) => string): Record<string, T[]> {
  return arr.reduce((acc: Record<string, T[]>, curr: T) => {
    const key = projection(curr);
    acc[key] = acc[key] ?? [];
    acc[key].push(curr);
    return acc;
  }, {});
}

/**
 * Creates an array of elements split into groups the length of size. If collection can’t be split evenly, the
 * final chunk will be the remaining elements.
 * @param array The array to process.
 * @param size The length of each chunk.
 * @return Returns the new array containing chunks.
 */
export function chunk<T>(array: T[], size: number): T[][] {
  return array.reduce((arr: T[][], item: T, index: number) =>
    index % size === 0 ?
      [...arr, [item]] :
      [...arr.slice(0, -1), [...arr.slice(-1)[0], item]], []
  );
}

/**
 * Creates an array of unique array values not included in the other provided arrays using projection for
 * equality comparisons.
 * @param array The array to inspect.
 * @param values The values to exclude.
 * @param projection The projection invoked per element.
 * @returns Returns the new array of filtered values.
 */
export function differenceBy<T, K>(array: T[], values: K[], projection: (item: T | K) => unknown): T[] {
  const projectedValues = values.map(projection);
  return array.filter(item => {
    const value = projection(item);
    return !projectedValues.includes(value);
  });
}


/**
 * Checks if an object is empty.
 * @param obj - The object to check.
 * @returns True if the object is empty, false otherwise.
 */
export function isEmptyObject(obj: object | null): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return Object.keys(obj).length === 0;
}
