import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { FloorGroupListBaseComponent } from '../floor-group-list-base/floor-group-list.base.component';
import { FloorGroup } from 'manager/models/floor-group';
import { isDefined } from '@latch/latch-web';

@Component({
  selector: 'latch-floor-group-list-edit',
  templateUrl: './floor-group-list-edit.html',
  styleUrls: ['./floor-group-list-edit.scss']
})
export class FloorGroupListEditComponent extends FloorGroupListBaseComponent implements OnInit {
  static readonly DEFAULT_SELECT_MODE_HEADER = 'Select default Floor Group';
  static readonly FLOOR_GROUP_SELECT_SUBTEXT = 'Select a default Floor Group for this Elevator for this particular Key. ' +
    'When adding a Key to an individual user, you will be able to select a different ' +
    'Floor Group other than the default.';
  // Whether or not the confirmation prompt with warningMessage is currently displayed.
  showConfirmPrompt = false;
  @Input() defaultFloorGroupSelectMode = false;
  // Whether or not we should show warning message in a confirmation dialog before saving floor group selection.
  @Input() showWarningMessage = false;
  @Input() warningMessage = 'Changing the default Floor Group for this Elevator does not modify any Floor Groups that ' +
    'were previously set. From now on this new Floor Group will be selected by default when giving this Key.';
  @Input() selectedFloorGroupUUID: string | undefined;
  @Output() save = new EventEmitter<FloorGroup>();

  constructor(analyticsService: LatchAnalyticsService) {
    super(analyticsService);
  }

  getPageHeader(): string {
    if (this.defaultFloorGroupSelectMode) {
      return FloorGroupListEditComponent.DEFAULT_SELECT_MODE_HEADER;
    }
    return this.elevatorName + ' floor group';
  }

  getPageHeaderSubtext(): string {
    return FloorGroupListEditComponent.FLOOR_GROUP_SELECT_SUBTEXT;
  }

  getPageTrackingInfo(): Record<string, unknown> {
    return { [LatchAnalyticsConstants.PageName]: 'Elevator Floor Group Edit List' };
  }

  selectFloorGroup(floorGroup: FloorGroup): void {
    if (this.selectedFloorGroupUUID && this.selectedFloorGroupUUID === floorGroup.uuid) {
      this.selectedFloorGroupUUID = undefined;
    } else {
      this.selectedFloorGroupUUID = floorGroup.uuid;
    }
  }

  isItemSelected(floorGroup: FloorGroup): boolean {
    return isDefined(this.selectedFloorGroupUUID) && this.selectedFloorGroupUUID === floorGroup.uuid;
  }

  confirmSave(): void {
    if (this.showWarningMessage) {
      this.showConfirmPrompt = true;
    } else {
      const fg = this.floorGroups.find(floorGroup => floorGroup.uuid === this.selectedFloorGroupUUID);
      this.save.emit(fg);
    }
  }

  handleSave(): void {
    const fg = this.floorGroups.find(floorGroup => floorGroup.uuid === this.selectedFloorGroupUUID);
    this.save.emit(fg);
  }
}
