<latch-card class="page fixed">
  <ng-container latchCardHeader>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="cancel.emit()">
        <span class="latch-icon-close"></span>
      </button>
    </div>
    <div class="latch-card-title">Assign a unit</div>
    <p class="page-description">Assign units to residents only. If you use a Latch intercom, residents must be assigned
      a unit in order to show up on the intercom.</p>
    <input type="text" placeholder="Search" class="latch-card-search" [(ngModel)]="search"/>
    <div class="cell" [class.has-error]="error">
      <div class="help-block">
        {{error}}
      </div>
    </div>
  </ng-container>

  <ng-container latchCardContent>
    <div class="cell-list">
      <div class="cell cell-row toggle-list latch-checkbox-wrapper" *ngIf="!search"
        (click)="handleClearUnitSelection()">
        <div class="cell-label">
          <latch-checkbox [checked]="isNull()">
          </latch-checkbox>
        </div>
        <div class="cell-body">
          <div class="cell-text">
            Not assigned (Not a resident)
          </div>
        </div>
      </div>
      <ng-container *ngFor="let unit of filtered">
        <div class="cell cell-row toggle-list latch-checkbox-wrapper"
          (click)="handleToggle(unit)">
          <div class="cell-label">
            <latch-checkbox [checked]="isSelected(unit)">
            </latch-checkbox>
          </div>
          <div class="cell-body">
            <div class="cell-text">
              <div class="text-1">
                {{unit.name}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="filtered.length === 0">
        <div class="cell">
          <div class="cell-body">
            <div class="cell-text">
              There are no results for the search term.
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container latchCardFooter>
    <button class="latch-button" (click)="handleSave()">
      Save
    </button>
  </ng-container>
</latch-card>
