export interface PaymentAccount {
  accountId: string,
  ownerType: OwnerType,
  ownerId: string,
  accountStatus: PaymentAccountStatus,
}

export enum PaymentAccountStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum OwnerType {
  Portfolio = 'PORTFOLIO',
  Building = 'BUILDING',
}
