import { Injectable } from '@angular/core';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';
import { Observable } from 'rxjs';
import { filter, map, tap, share, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AccountAdministrationSettings, KeyAdminPermissions } from '../../models/account-administration';
import { PermissionsService } from './permissions.service';
import { combineLatest } from 'rxjs';

@Injectable()
export class HTTPPermissionsService extends PermissionsService {
  private adminSettings$: Observable<AccountAdministrationSettings> | undefined;
  private selectedAccountUUID!: string;
  private selectedBuildingUUID!: string;

  constructor(
    private authService: AuthService,
    private selectedAccountService: SelectedAccountService,
    private selectedBuildingService: SelectedBuildingsService
  ) {
    super();

    this.authService.isLoggedIn$.subscribe(session => {
      if (!session) {
        this.adminSettings$ = undefined;
      }
    });
  }

  public currentUserIsOwner(): Observable<boolean> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map(settings => settings.owner)
    );
  }

  public currentUserMaySeeAllKeys(): Observable<boolean> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map((adminSettings) => {
        if (adminSettings.owner) {
          return true;
        } else {
          const keyAdmin = adminSettings.getKeyAdmin(this.selectedBuildingUUID);
          return (keyAdmin) ?
            (keyAdmin.permissions === KeyAdminPermissions.Full || keyAdmin.permissions === KeyAdminPermissions.Limited) :
            false;
        }
      })
    );
  }

  public currentUserMayEditDoors(): Observable<boolean> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map((adminSettings) => {
        if (adminSettings.owner) {
          return true;
        } else {
          const siteAdmin = adminSettings.getSiteAdmin(this.selectedBuildingUUID);
          return (siteAdmin) ? siteAdmin.installer : false;
        }
      })
    );
  }

  public currentUserMayEditKeys(): Observable<boolean> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map((adminSettings) => {
        if (adminSettings.owner) {
          return true;
        }
        const keyAdminForCurrentBuilding = adminSettings.getKeyAdmin(this.selectedBuildingUUID);
        return (keyAdminForCurrentBuilding) ? (keyAdminForCurrentBuilding.permissions === KeyAdminPermissions.Full) : false;
      })
    );
  }

  public currentUserMaySeeSomeKeys(): Observable<boolean> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map((adminSettings) => {
        if (adminSettings.owner) {
          return true;
        } else {
          const keyAdminForCurrentBuilding = adminSettings.getKeyAdmin(this.selectedBuildingUUID);
          return (keyAdminForCurrentBuilding) ? (
            keyAdminForCurrentBuilding.permissions === KeyAdminPermissions.Full ||
            keyAdminForCurrentBuilding.permissions === KeyAdminPermissions.Limited ||
            keyAdminForCurrentBuilding.permissions === KeyAdminPermissions.Specific) : false;
        }
      })
    );
  }

  public currentUserKeyAdminPermissions(): Observable<KeyAdminPermissions | undefined> {
    return this.getAdminSettings().pipe(
      filter(adminSettings => !!adminSettings),
      map(adminSettings => {
        if (adminSettings.owner) {
          return KeyAdminPermissions.Full;
        }
        const keyAdminForCurrentBuilding = adminSettings.getKeyAdmin(this.selectedBuildingUUID);
        return (keyAdminForCurrentBuilding) ? keyAdminForCurrentBuilding.permissions : undefined;
      })
    );
  }

  public currentUserIsInstaller(): Observable<boolean | undefined> {
    return this.getAdminSettings().pipe(
      filter((adminSettings) => !!adminSettings),
      map((adminSettings) => {
        const siteAdmin = adminSettings.getSiteAdmin(this.selectedBuildingUUID);
        return siteAdmin?.installer;
      })
    );
  }

  private getAdminSettings(): Observable<AccountAdministrationSettings> {
    if (!this.adminSettings$) {
      /**
       * create 'hot' observables from selected building service and selected account service
       * so that the internal variables will continually  update when either value changes
       */
      const buildingSource$ = this.selectedBuildingService.getSelectedBuildings()
        .pipe(
          map((buildings) => buildings[0].uuid),
          share()
        );
      const accountSource$ = this.selectedAccountService.getSelectedAccount()
        .pipe(
          map((account) => account.uuid),
          share()
        );

      this.adminSettings$ = combineLatest([accountSource$, buildingSource$]).pipe(
        // don't emit anything if we're not logged in (because the observable is still hot)
        // otherwise, authService.request will continue to error and redirect us back to login indefinitely
        filter(() => this.authService.isLoggedIn),
        tap(([accountUUID, buildingUUID]) => {
          this.selectedAccountUUID = accountUUID;
          this.selectedBuildingUUID = buildingUUID;
        }),
        map(() => {
          const rawPermissions = this.authService.currentUser?.accountAdministrationPrivileges;
          const rawPermissionsForSelectedAccount = rawPermissions?.find(item => item.accountUUID === this.selectedAccountUUID);
          return new AccountAdministrationSettings(rawPermissionsForSelectedAccount);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
    return this.adminSettings$;
  }
}
