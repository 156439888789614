import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Unit } from 'manager/models/unit';

@Component({
  selector: 'latch-select-unit',
  templateUrl: './select-unit.component.html',
  styleUrls: ['./select-unit.component.scss']
})
export class SelectUnitComponent {

  @Output() cancel = new EventEmitter<void>();
  @Output() done = new EventEmitter<Unit>();
  @Input() selectedUnit: Unit | undefined;
  @Input() error: string | undefined;

  _units: Unit[] = [];
  filtered: Unit[] = [];
  _search = '';

  @Input()
  set units(units: Unit[]) {
    this._units = units;
    this.filter();
  }

  get units(): Unit[] {
    return this._units;
  }

  set search(search: string) {
    this._search = search;
    this.filter();
  }

  get search() {
    return this._search;
  }

  filter() {
    this.filtered = this.units.filter(unit => unit.name.toLowerCase().includes(this.search.toLowerCase()));
  }

  isSelected(unit: Unit) {
    if (!this.selectedUnit) {
      return false;
    }
    return this.selectedUnit.name === unit.name;
  }

  isNull() {
    return !this.selectedUnit;
  }

  handleToggle(unit: Unit) {
    if (this.isSelected(unit)) {
      this.selectedUnit = undefined;
    } else {
      this.selectedUnit = unit;
    }
  }

  handleClearUnitSelection() {
    this.selectedUnit = undefined;
  }

  handleSave() {
    this.done.emit(this.selectedUnit);
  }

}
