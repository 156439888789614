import { Injectable } from '@angular/core';
import { OperationsService } from './operations.service';
import { from, interval, merge, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, shareReplay, startWith, tap, toArray } from 'rxjs/operators';
import { Operation } from '../../models/operations';
import { AuthService } from '../auth/auth.service';

const compareFailureLists = (first: Operation<unknown>[], second: Operation<unknown>[]) => {
  if (first.length !== second.length) {
    return false;
  }

  const operationIds = first.map(operation => operation.operationId);
  return second.every((operation) => operationIds.includes(operation.operationId));
};

@Injectable({ providedIn: 'root' })
export class FailedOperationsService {
  private refreshFailures$ = new Subject<void>();
  private hasActionInProcess = false;

  // periodically gets failures data from the server
  failures$ = merge(this.refreshFailures$, interval(5000)).pipe(
    startWith(0),
    filter(() => !this.hasActionInProcess && this.authService.isLoggedIn),
    concatMap(() => this.operationsService.getUnacknowledgedFailures()),
    distinctUntilChanged(compareFailureLists),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  constructor(private operationsService: OperationsService, private authService: AuthService) { }

  updateFailures() {
    this.refreshFailures$.next();
  }

  acknowledgeFailure(operationId: string) {
    this.hasActionInProcess = true;
    return this.operationsService.acknowledgeFailure(operationId).pipe(
      tap(() => {
        this.hasActionInProcess = false;
        this.updateFailures();
      })
    );
  }

  acknowledgeFailures(operationIds: string[]) {
    this.hasActionInProcess = true;
    return from(operationIds).pipe(
      concatMap(operationId => this.operationsService.acknowledgeFailure(operationId)),
      toArray(),
      tap(() => {
        this.hasActionInProcess = false;
        this.updateFailures();
      })
    );
  }
}
