import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from './../../models/account';
import { AuthService } from './../auth/auth.service';

/**
 * Service which provides a list of accounts accessible to the current user.
 */
@Injectable()
export abstract class AccountService {

  protected accounts$: Observable<Account[]> | undefined;
  protected refreshAccounts$ = new BehaviorSubject<void>(undefined);

  constructor(protected authService: AuthService) { }

  public initialize(): void {
    this.authService.isLoggedIn$.subscribe((session) => {
      if (!session) {
        this.accounts$ = undefined;
      }
    });
  }

  /**
   * this function forces a refresh of the accounts by emptying the cache of accounts
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public clearCache(): void {
    this.accounts$ = undefined;
  }

  public refreshAccounts(): void {
    this.refreshAccounts$.next();
  }

  /**
   * Returns a list of accounts.
   *
   * Uses the current user's (via AuthService) credentials.
   */
  abstract getAccounts(): Observable<Account[]>;

}
