<input
  type="text"
  class="latch-form-control date"
  #trigger
  [(ngModel)]="date"
  placeholder="mm/dd/yy"
  (focus)="openCalendarPicker()"
  [class.latch-datepicker-open]="isCalendarPickerOpen"
  [disabled]="disableDate || isReadOnly"
/>
<ng-container *ngIf="!onlyDate">
  <div class="spacer"></div>
  <input
    type="text"
    class="latch-form-control time"
    [(ngModel)]="time"
    placeholder="hh:mm"
    (blur)="handleBlurTime()"
    [disabled]="disableTime || isReadOnly"
  />
  <select class="meridiem" [(ngModel)]="meridiem" [disabled]="disableTime || isReadOnly">
    <option value="AM">am</option>
    <option value="PM">pm</option>
  </select>
</ng-container>
<latch-overlay *ngIf="isCalendarPickerOpen" [origin]="trigger" [originAllowOverlap]="false"
  [open]="isCalendarPickerOpen" [blockScroll]="false" horizontalAlign="left"
  (outsideClick)="handleBlurDate()">
    <latch-datepicker
      [date]="_dateForPicker"
      [min]="datePickerMin"
      [max]="datePickerMax"
      (dateChange)="setDateFromPicker($event)"
      (mousedown)="$event.preventDefault();"
    ></latch-datepicker>
</latch-overlay>
