import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChangeNotificationService {
  private keyChange$: Subject<void>;
  private keyMembershipChange$: Subject<void>;
  private lockChange$: Subject<void>;
  private authStateChange$: Subject<void>;

  constructor() {
    this.keyChange$ = new Subject<void>();
    this.keyMembershipChange$ = new Subject<void>();
    this.lockChange$ = new Subject<void>();
    this.authStateChange$ = new Subject<void>();
  }

  keyChange() {
    return this.keyChange$.asObservable();
  }

  keyMembershipChange() {
    return this.keyMembershipChange$.asObservable();
  }

  lockChange() {
    return this.lockChange$.asObservable();
  }

  authStateChange() {
    return this.authStateChange$.asObservable();
  }

  publishKeyChange(): void {
    this.keyChange$.next();
  }

  publishKeyMembershipChange(): void {
    this.keyMembershipChange$.next();
  }

  publishLockChange(): void {
    this.lockChange$.next();
  }

  publishAuthStateChange(): void {
    this.authStateChange$.next();
  }
}
