import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhoneNumberPipe } from 'manager/pipes/phone-number.pipe';

@Component({
  selector: 'latch-phone-input',
  templateUrl: './phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent implements ControlValueAccessor {

  private _value = '';
  public get value() {
    return this._value;
  }

  @Input() placeholder = 'Phone';
  @ViewChild('input') input: ElementRef | undefined;

  disabled = false;

  constructor(private phoneNumberPipe: PhoneNumberPipe) { }

  onChange = (value: string) => { }; // eslint-disable-line @typescript-eslint/no-empty-function
  onTouch = () => { }; // eslint-disable-line @typescript-eslint/no-empty-function

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    // Because phone number gets formatted the cursor is always moved to the end, so we update it manually
    // Save cursor position before formatting phone number
    const cursorPosition: number | undefined = this.input?.nativeElement.selectionStart;
    const isAtEnd = cursorPosition != null && cursorPosition === this.input?.nativeElement.value.length;

    const formatted = this.phoneNumberPipe.transform(value);
    this._value = formatted || '';

    if (this.input) {
      // If user enters too many numbers we override the value
      // Allow to enter + as first character
      this.input.nativeElement.value = value === '+' ? value : this.value;

      // If the cursor is at the end, just ignore it
      // Otherwise update cursor position to what it was before
      if (cursorPosition != null && !isAtEnd && this.input.nativeElement.setSelectionRange) {
        this.input.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
      }
    }

    this.onChange(this.value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
