import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isEqual } from 'manager/services/utility/utility';
import { Observable } from 'rxjs';

/**
 * ResetQuaryParamsCanDeactivate is really useful guard that helps to reset the query params added to the url
 * by the component. This guard will cleanup the query params when the component gets deactivated before the new
 * one gets created. It is super handy to get rid of query params because we always use the preserve query params
 * for navigation which is the main cause of the query param polution.
 * @example
 * ```typescript
 * // Add the following to the Routing Module
 * { path: '', component: PeopleListPageComponent, data: { resetQueryParams: true }, canDeactivate: [ResetQuaryParamsCanDeactivate] },
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ResetQuaryParamsCanDeactivate implements CanDeactivate<unknown> {

  constructor(
    private router: Router,
  ) { }

  private applyResetQueryParams(queryParamsToReset: Params, queryParams: Params): Params {
    const newQueryParams: Params = { ...queryParams };
    Object.keys(queryParamsToReset).forEach(key => {
      if (!queryParams[key] || isEqual(queryParams[key], queryParamsToReset[key])) {
        // if query param doesn't exist any more, that was meant to be reset
        // or if query param stayed the same, that was also meant to be reset
        delete newQueryParams[key];
      } else {
        // if query param changed to a new value, then it was not meant to be reset, so we keep it
        newQueryParams[key] = queryParams[key];
      }
    });
    return newQueryParams;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const baseUrl = currentState.url.split(';popup')[0].split('?')[0];
    const nextBaseUrl = nextState.url.split(';popup')[0].split('?')[0];
    if (baseUrl === nextBaseUrl) {
      // do nothing if the base url stays the same, only when the navigation to different url happens,
      // so that we avoid redundant query params cleanup when the popup gets opened
      return true;
    }

    if (currentRoute.data.resetQueryParams) {
      // do not reset the building query param
      const { building, ...queryParamsToReset } = currentRoute.queryParams;
      const queryParams = nextState.root.queryParams;
      const newQueryParams = this.applyResetQueryParams(queryParamsToReset, queryParams);
      if (isEqual(queryParams, newQueryParams)) {
        // we did correctly reset the query params, it is time to deactivate the component
        return true;
      }
      const tree = this.router.parseUrl(nextState.url);
      tree.queryParams = newQueryParams;
      return tree;
    }

    return true;
  }

}
