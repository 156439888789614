import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccessLog, VisualAccessLogMetadata } from '../../models/access-log';
import { PagedResponse } from '../interfaces';

/**
 * Filter specifying which access logs should be fetched.
 */
export interface SearchAccessLogParams {
  buildingUUIDs?: string[];
  guestUUIDs?: string[];
  lockUUIDs?: string[];
  sortAscending?: boolean;
  startTime?: Date;
  start?: number;
  limit?: number;
}

/**
 * Service for fetching records of access attempts.
 */
@Injectable()
export abstract class AccessLogService {
  /**
   * Get all access logs matching the given filter criteria.
   *
   * @param searchParams Filter to determine which logs should be returned.
   */
  public abstract getAccessLogs(searchParams: SearchAccessLogParams): Observable<PagedResponse<AccessLog>>;
  public abstract updateVisualAccessLogMetadata(accessLog: AccessLog, visualAccessLogMetadata: VisualAccessLogMetadata): Observable<void>;
}
