import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  CreateFeaturedEntry,
  FeaturedEntry,
  FeaturedEntryCandidatesInput,
  FeaturedEntryCandidatesResponse,
  FeaturedEntryUpdate
} from '../../models/featured-entry';

@Injectable()
export abstract class FeaturedEntryService {
  updateFeaturedEntries = new BehaviorSubject(true);

  deleteFeaturedEntry(featuredEntry: FeaturedEntry, buildingUUID: string): Observable<FeaturedEntry> {
    return this.updateFeaturedEntry({
      displayName: featuredEntry.displayName,
      contactCardUUID: featuredEntry.contactCardUUID,
      contactPreference: featuredEntry.contactPreference,
      order: featuredEntry.order,
      decommissionTime: new Date().getTime() / 1000
    }, buildingUUID, featuredEntry.id);
  }

  abstract getBuildingFeaturedEntries(buildingUUID: string): Observable<FeaturedEntry[]>;

  abstract createBuildingFeaturedEntry(buildingUUID: string, featuredEntryInput: CreateFeaturedEntry): Observable<FeaturedEntry>;

  abstract getBuildingFeaturedEntryCandidates(
    buildingUUID: string,
    featuredEntryCandidatesInput: FeaturedEntryCandidatesInput
  ): Observable<FeaturedEntryCandidatesResponse>;

  abstract getBuildingFeaturedEntry(buildingUUID: string, featuredEntryId: number): Observable<FeaturedEntry>;

  abstract updateFeaturedEntry(
    featuredEntry: FeaturedEntryUpdate,
    buildingUUID: string,
    featuredEntryId: number
  ): Observable<FeaturedEntry>;

  abstract updateFeaturedEntriesOrder(
    featuredEntries: FeaturedEntry[],
    buildingUUID: string
  ): Observable<FeaturedEntry[]>;
}
