import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';

/**
 * Shows sorting toggle but doesn't actually do any sorting.
 */
@Component({
  selector: 'latch-sorter-ui',
  templateUrl: './sorter-ui.component.html',
  styleUrls: ['./sorter-ui.component.scss']
})
export class SorterUIComponent {

  @Output() toggle = new EventEmitter<boolean>();

  // Table may be sortable by other columns, which should cause our toggle icon to hide. We don't
  // have that use-case yet, and when we do we'll need an API for passing that down. For now we just
  // stick with this logic to maintain consistency with our SorterComponent (which extends from
  // DataTable's DefaultSorter) since we share component HTML.
  isSortedByMe = true;

  isAscending = false;

  get isSortedByMeAsc() {
    return this.isSortedByMe && this.isAscending;
  }

  get isSortedByMeDesc() {
    return this.isSortedByMe && !this.isAscending;
  }

  @HostListener('click')
  handleToggle() {
    if (this.isSortedByMe) {
      this.isAscending = !this.isAscending;
    } else {
      this.isSortedByMe = true;
    }

    this.toggle.emit(this.isSortedByMeAsc);
  }

  public setSortAscending(isAscending: boolean) {
    this.isSortedByMe = true;
    this.isAscending = isAscending;
    this.toggle.emit(this.isSortedByMeAsc);
  }

}
