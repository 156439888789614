import { Component, Input } from '@angular/core';

@Component({
  selector: 'latch-icon-message-dialog',
  templateUrl: './icon-message-dialog.component.html',
  styleUrls: ['./icon-message-dialog.component.scss']
})
export class LatchIconMessageDialogComponent {
  @Input() iconClass?: string;
  @Input() message?: string;
  @Input() text?: string;
}
