/**
 * For compatibility with the API, a utility to convert a list to a map
 *
 * The keys of the Map are the indexes of the List. Therefore ['a','b','c'] -> {0:'a', 1:'b', 2:'c'}
 */

export function convertListToMap<T>(list: T[]): Record<string, T> {
  const map: Record<string, T> = {};
  list.forEach((item, index) => {
    map[String(index)] = item;
  });
  return map;
}
