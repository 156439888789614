import { Component, ContentChild, ElementRef, HostBinding, Input } from '@angular/core';

export type FeedbackVariant = 'success' | 'error' | 'default' | 'empty' | 'loading';

@Component({
  selector: 'frame-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() variant: FeedbackVariant = 'default';
  @ContentChild('feedbackImage', { read: ElementRef }) feedbackImage: ElementRef | undefined;

  get showDefaultFeedbackImage() {
    return !this.feedbackImage && this.variant !== 'loading';
  }

  get feedbackImageClass() {
    if (this.variant === 'loading') {
      return '';
    }

    if (this.variant === 'default' || this.variant === 'empty') {
      return 'feedback-default';
    }

    return `feedback-${this.variant}`;
  }

  get feedbackRole() {
    return this.variant === 'success' || this.variant === 'error' ? 'alert' : 'status';
  }
}
