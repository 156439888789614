import { Component, Input } from '@angular/core';
import { BaseChartComponent } from './base-chart.component';
import { ChartDataPoint } from './chart';

@Component({
  selector: 'latch-chart[pie]',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends BaseChartComponent {
  @Input()
  public arcWidth = 0.35;

  @Input()
  public doughnut = false;

  @Input()
  public results: ChartDataPoint[] = [];

  constructor() {
    super();
  }
}
