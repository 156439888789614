import { NgModule } from '@angular/core';
import {
  LatchCardModule,
  LatchCheckboxModule,
  LatchToggleModule,
  LatchRadioItemModule,
  LatchListModule,
  LatchLoadingModule,
  LatchSpinnerModule,
  LatchRadioGroupModule,
  LatchIconModule,
  LatchNavbarModule,
  LatchTabsModule,
  LatchTableModule,
  LatchTooltipModule,
  LatchAutocompleteModule,
  LatchDropdownModule,
  LatchToastModule,
  LatchOverlayModule,
  LatchMenuModule,
  LatchDatepickerModule,
  LatchDatepickerTriggerModule,
  LatchTimepickerModule
} from '@latch/latch-web';

@NgModule({
  exports: [
    LatchCardModule,
    LatchLoadingModule,
    LatchSpinnerModule,
    LatchListModule,
    LatchCheckboxModule,
    LatchToggleModule,
    LatchRadioItemModule,
    LatchRadioGroupModule,
    LatchIconModule,
    LatchNavbarModule,
    LatchTabsModule,
    LatchTableModule,
    LatchTooltipModule,
    LatchAutocompleteModule,
    LatchDropdownModule,
    LatchToastModule,
    LatchOverlayModule,
    LatchMenuModule,
    LatchDatepickerModule,
    LatchDatepickerTriggerModule,
    LatchTimepickerModule
  ],
  declarations: [],
  providers: [],
})
export class LatchWebModule { }
