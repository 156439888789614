import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, EMPTY, Subject, zip, combineLatest } from 'rxjs';
import { concatMap, map, mergeMap, takeUntil } from 'rxjs/operators';

import { Lock, LockType } from '../../../models/lock';
import { CreateKeyOperationResult, Key, KeyType } from '../../../models/key';

import { LockService } from '../../../services/lock/lock.service';
import { SelectedAccountService } from '../../../services/appstate/selected-account.service';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { CreateKeyDoorInput, KeyService } from '../../../services/key/key.service';
import { pluralize } from '../../../services/utility/utility';
import { UnexpectedErrorText } from '../../../services/utility/presentation';
import { OperationErrorMessage } from '../../../models/operations';
import { emitOperationResultsWhenComplete } from '../../../services/utility/async-operations-helpers';
import { OperationsService } from '../../../services/operations/operations.service';
import { FeatureService } from '../../../services/appstate/feature.service';
import { isDefined } from '@latch/latch-web';
import { GeminiPartner } from 'manager/models/gemini-partner';
import { ElevatorBuildingService } from 'manager/services/elevator-building/elevator-building.service';
import { Partner } from 'manager/models/partner';
import { PartnerService } from 'manager/services/partner/partner.service';

interface FormErrors {
  name?: string;
  keyDoors?: string;
  global?: string;
}

interface KeyTypeDataItem {
  name: string;
  value: KeyType;
}

@Component({
  selector: 'latch-make-key',
  templateUrl: './make-key.component.html',
  styleUrls: ['./make-key.component.scss']
})
export class MakeKeyComponent implements OnInit, OnDestroy {

  public isElevatorBuilding = false;

  @Output() back = new EventEmitter<void>();
  @Output() done = new EventEmitter<Key>();

  public keyTypeData: KeyTypeDataItem[] = [
    { name: 'Door', value: KeyType.Door },
    { name: 'Elevator', value: KeyType.Elevator },
  ];

  private _keyType: KeyType = KeyType.Door;

  public set keyType(value: KeyType) {
    this._keyType = value;
    this.resetDoorsSelected();
    this.filter();
  }

  public get keyType(): KeyType {
    return this._keyType;
  }

  KeyType = KeyType;
  isLoading = false;

  private allLocks: Lock[] = [];

  public filteredLocks: Lock[] = [];

  name = '';
  keyDoors: CreateKeyDoorInput[] = [];

  errors: FormErrors = {};

  showSelectDoors = false;

  geminiPartners: GeminiPartner[] = [];
  selectedGeminiPartnerUUID!: string;
  partners: Partner[] = [];
  selectedPartner!: Partner;
  hasOpenkitOnboardingV2Feature = false;

  pluralize = pluralize;

  public hasDoorcodeSuppressionFeature$: Observable<boolean> = this.featureService.hasDoorcodeSuppression$;
  private unsubscribe$ = new Subject<void>();

  get canSubmit(): boolean {
    return !!this.name && this.keyDoors.length > 0;
  }

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private lockService: LockService,
    private selectedBuildingsService: SelectedBuildingsService,
    private selectedAccountService: SelectedAccountService,
    private keyService: KeyService,
    private operationsService: OperationsService,
    private featureService: FeatureService,
    private elevatorBuildingService: ElevatorBuildingService,
    private partnerService: PartnerService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    combineLatest([
      this.selectedBuildingsService.getSelectedBuildings().pipe(
        map((buildings) => buildings.map((building) => building.uuid))
      ),
      this.elevatorBuildingService.isCurrentElevatorBuilding(),
      this.featureService.hasOpenkitOnboardingV2Feature,
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: ([buildingUUIDs, isElevatorBuilding, hasOpenkitOnboardingV2Feature]) => {
        this.isElevatorBuilding = isElevatorBuilding;
        this.hasOpenkitOnboardingV2Feature = hasOpenkitOnboardingV2Feature;
        this.handlePageLoad(buildingUUIDs);
      },
      error: error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  private handlePageLoad(buildingUUIDs: string[]): void {
    this.isLoading = true;
    const buildingUuid = this.selectedBuildingsService.selectedBuildings[0].uuid;
    zip(
      this.lockService.getAllLocks(buildingUUIDs),
      this.featureService.getGeminiPartners$,
      this.partnerService.getPartners(buildingUuid),
    ).subscribe(([locks, geminiPartners, partnersResponse]) => {
      this.isLoading = false;
      this.allLocks = locks;
      this.filter();
      const partners = partnersResponse.partners;

      if (geminiPartners && geminiPartners.length) {
        const noGeminiPartnerSelected: GeminiPartner = {
          name: '-',
          value: '',
        };
        this.geminiPartners.push(noGeminiPartnerSelected);
        this.geminiPartners.push(...geminiPartners);
        this.selectedGeminiPartnerUUID = noGeminiPartnerSelected.value;
      }

      if (partners && partners.length) {
        const noPartnerSelected: Partner = {
          uuid: '',
          name: '-'
        };
        this.partners.push(noPartnerSelected);
        this.partners.push(...partners);
        this.selectedPartner = noPartnerSelected;
      }
    }, (error) => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  private filter(): void {
    this.filteredLocks = this.allLocks.filter((lock) =>
      (this.keyType === KeyType.Door) ? lock.lockType === LockType.DOOR : lock.lockType === LockType.ELEVATOR
    );
  }

  handleSelectDoors() {
    this.showSelectDoors = true;
  }

  handleDoorsSelected(keyDoors: CreateKeyDoorInput[]) {
    this.keyDoors = keyDoors;
    this.showSelectDoors = false;
  }

  private resetDoorsSelected(): void {
    this.keyDoors = [];
  }

  handleSubmit() {
    this.errors = {};
    this.isLoading = true;

    const buildingId = this.selectedBuildingsService.selectedBuildings[0].uuid;

    this.keyService.createKey({
      accountUUID: this.selectedAccountService.selectedAccount.uuid,
      buildingUUID: buildingId,
      name: this.name,
      doors: this.getDoorsForKeyType(this.keyDoors, this.keyType),
      type: this.keyType,
      partnerUUID: this.getPartnerUUID()
    }).pipe(
      concatMap((result) => emitOperationResultsWhenComplete<CreateKeyOperationResult>(this.operationsService, result.operationIds)),
      map(([operation]) => operation.result?.keyId),
      mergeMap((keyId) => isDefined(keyId) ? this.keyService.getKey(keyId, buildingId) : EMPTY)
    ).subscribe((key) => {
      this.isLoading = false;
      this.done.emit(key);
    }, (error) => {
      this.isLoading = false;
      if (error.message === OperationErrorMessage.INVALID_KEY_NAME) {
        this.errors.name = 'Name is already in use';
      } else {
        this.errors.global = UnexpectedErrorText;
      }
    });
  }

  private getDoorsForKeyType(keyDoors: CreateKeyDoorInput[], keyType: KeyType): CreateKeyDoorInput[] {
    if (keyType === KeyType.Door) {
      return keyDoors.map(({ defaultFloorGroup, ...rest }) => rest as CreateKeyDoorInput);
    } else {
      return keyDoors.map(({ days, ...rest }) => rest as CreateKeyDoorInput);
    }
  }

  private getPartnerUUID() {
    if (this.hasOpenkitOnboardingV2Feature) {
      return this.selectedPartner ? this.selectedPartner.uuid : undefined;
    } else {
      return this.selectedGeminiPartnerUUID;
    }
  }
}
