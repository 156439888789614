import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { parsePhoneNumber } from '../services/utility/utility';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  // `+1xxxxxxxxxx` -> `+1 (xxx) xxx-xxxx` with support for partial numbers (e.g. for incomplete user input)
  transform(phoneNumber: string | undefined): string | undefined {
    if (!phoneNumber) {
      // If the input is undefined, pass undefined on through. We prefer this to formatting as, say, an
      // empty string so that we could specify other pipes giving default values.
      return undefined;
    }

    phoneNumber = phoneNumber.replace(/(?!^\+)\D/g, '');
    const result = phoneNumber.replace(/^(\+?1?)(\d{0,3})(\d{0,3})(\d{0,4})\d*$/, '$1 ($2) $3-$4');

    return result.trim().replace(/\D+$/, '');
  }
}
