import { Injectable } from '@angular/core';
import { PmsSource } from 'manager/models/building';
import { PmsAccessReviewData } from 'manager/models/pms-access';
import { PmsCredential, PmsConfiguration, PmsProperty, PmsUnit, LatchUnit } from 'manager/modules/integrations/models/integrations';
import { Observable } from 'rxjs';

export interface PmsUnitsFilter {
  name?: string;
  mapped?: boolean;
  start?: number;
  limit?: number;
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

@Injectable()
export abstract class PmsAccessService {
  abstract getPmsAccessReviewData(buildingUUID: string): Observable<PmsAccessReviewData | undefined>;

  abstract discardPendingAccess(buildingUUID: string, discardPendingAccessReviews: string[]): Observable<void>;

  abstract enableAccessAutomation(buildingUUID: string, confirmedPendingAccessReviews: string[]): Observable<void>;

  abstract triggerPmsSync(buildingUUID: string): Observable<string>;

  abstract requestIntegration(buildingUUID: string, pmsSource: PmsSource): Observable<void>;

  abstract getPmsConfiguration(buildingUUID: string): Observable<PmsConfiguration>;

  abstract updatePmsConfiguration(buildingUUID: string, pmsConfiguration: PmsConfiguration): Observable<void>;

  abstract getProperties(buildingUUID: string, credentialUUID: string): Observable<PmsProperty[]>;

  abstract getCredentials(buildingUUID: string): Observable<PmsCredential[]>;

  abstract createOrUpdateCredential(buildingUUID: string, pmsCredential: PartialBy<PmsCredential, 'uuid'>): Observable<PmsCredential>;

  abstract getPmsUnits(buildingUUID: string, filters: PmsUnitsFilter): Observable<{ units: PmsUnit[], count: number }>;

  abstract updatePmsUnits(buildingUUID: string, pmsUnit: PmsUnit): Observable<void>;

  abstract getUnmappedUnits(buildingUUID: string, searchTerm: string): Observable<LatchUnit[]>;

  abstract refreshPendingAccess(buildingUUID: string): Observable<void>;
}
