import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Accessibility, Lock, LockType } from '../../../../models/lock';
import { TWENTY_FOUR_SEVEN } from '../../schedule-picker/schedule-picker.component';
import { KeyDoor, QualifiedDay } from '../../../../models/key';
import { FloorGroup } from '../../../../models/floor-group';
import { getFloorGroupFromLock } from '../../../../services/utility/model-helpers';
import { Subject } from 'rxjs';
import { cloneDeep } from 'manager/services/utility/utility';

export const SELECTED_DOORS_GROUP = 'SELECTED';
export const ELEVATOR_DOORS_GROUP = 'ELEVATORS';

export interface SelectedKeyDoor extends Partial<KeyDoor> {
  lockUUID: string;
  days?: QualifiedDay[];

  // Optional if the DOORCODE_SUPPRESSION feature flag is off,
  // required if the DOORCODE_SUPPRESSION feature flag is on
  doorcodeEnabled?: boolean;

  // The floor group selected as the default for an elevator key.
  defaultFloorGroup?: string;
  // The floor group selected for this particular elevator key membership.
  selectedFloorGroup?: string;
}

export class SelectableLock {

  lockId: string;
  lock: Lock;
  lockName: string;
  groupName: string;

  initialKeyDoor: SelectedKeyDoor | undefined;
  keyDoor: SelectedKeyDoor | undefined;

  defaultFloorGroup: FloorGroup | undefined;
  selectedFloorGroup: FloorGroup | undefined;

  // The subject is intended to notify change to a component to trigger change detection
  private lockStateChangedSubject = new Subject<void>();
  public lockStateChanged = this.lockStateChangedSubject.asObservable();

  get selected(): boolean {
    return !!this.keyDoor;
  }

  get selectedInitially(): boolean {
    return !!this.initialKeyDoor;
  }

  get isElevator(): boolean {
    return this.lock.lockType === LockType.ELEVATOR;
  }

  constructor(lock: Lock, keyDoor?: SelectedKeyDoor) {
    this.lockId = lock.uuid;
    this.lock = lock;
    this.lockName = lock.name;

    this.initialKeyDoor = keyDoor;
    if (keyDoor) {
      this.keyDoor = cloneDeep(keyDoor);
    }

    this.groupName = this.getGroupName();

    if (this.isElevator && keyDoor) {
      this.updateFloorGroupInfo();
    }
  }

  select() {
    this.keyDoor = {
      lockUUID: this.lockId,
      days: cloneDeep(TWENTY_FOUR_SEVEN),
      doorcodeEnabled: true,
    };
    this.lockStateChangedSubject.next();
  }

  unselect() {
    this.keyDoor = undefined;
    this.defaultFloorGroup = undefined;
    this.selectedFloorGroup = undefined;
    this.lockStateChangedSubject.next();
  }

  toggleDoorcodeEnabled(enabled: boolean | null = null) {
    if (this.keyDoor) {
      this.keyDoor.doorcodeEnabled = enabled === null ? !this.keyDoor.doorcodeEnabled : enabled;
      this.lockStateChangedSubject.next();
    }
  }

  handleScheduleChange(qualifiedDays: QualifiedDay[]) {
    if (this.keyDoor) {
      this.keyDoor.days = qualifiedDays;
      this.lockStateChangedSubject.next();
    }
  }

  updateFloorGroupInfo(): void {
    const defaultFloorGroup = this.keyDoor?.defaultFloorGroup;
    if (defaultFloorGroup) {
      this.defaultFloorGroup = getFloorGroupFromLock(this.lock, defaultFloorGroup);
    }

    const selectedFloorGroup = this.keyDoor?.selectedFloorGroup;
    if (selectedFloorGroup) {
      this.selectedFloorGroup = getFloorGroupFromLock(this.lock, selectedFloorGroup);
    }

    this.lockStateChangedSubject.next();
  }

  private getGroupName(): string {
    if (this.selectedInitially) {
      return SELECTED_DOORS_GROUP;
    }

    const lock = this.lock;
    return lock.lockType === LockType.ELEVATOR ? ELEVATOR_DOORS_GROUP :
      lock.accessibility ? Accessibility[lock.accessibility] : '';
  }
}

@Component({
  selector: 'latch-selectable-lock-item',
  templateUrl: './selectable-lock-item.component.html',
  styleUrls: ['./selectable-lock-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableLockItemComponent implements OnInit {
  @Input() selectableLock!: SelectableLock;

  @Input() isViewingKeyMembership = false;
  @Input() hasDoorcodeSuppression = false;
  @Input() @HostBinding('class.editable') isEditing = false;
  /**
   * Only show doors without any scheduling details
   */
  @Input() onlyDoors = false;

  @Output() selectedChange = new EventEmitter<SelectableLock>();
  @Output() viewDoorGroupForLock = new EventEmitter<SelectableLock>();
  @Output() update = new EventEmitter<void>();

  @HostBinding('class.selected') get selected(): boolean {
    return this.selectableLock.selected;
  }

  get lock(): Lock {
    return this.selectableLock.lock;
  }

  get keyDoor(): SelectedKeyDoor | undefined {
    return this.selectableLock.keyDoor;
  }

  get isElevator(): boolean {
    return this.lock.lockType === LockType.ELEVATOR;
  }

  get defaultFloorGroup(): FloorGroup | undefined {
    return this.selectableLock.defaultFloorGroup;
  }

  get selectedFloorGroup(): FloorGroup | undefined {
    return this.selectableLock.selectedFloorGroup;
  }

  get selectableLockName(): string {
    return this.selectableLock.lockName + (!this.lock.isInstalled ? ' (Not activated)' : '');
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.selectableLock.lockStateChanged.subscribe(() => this.changeDetectorRef.markForCheck());
  }

  handleToggle(): void {
    if (this.selected) {
      this.selectableLock.unselect();
    } else {
      this.selectableLock.select();
    }
    this.selectedChange.emit(this.selectableLock);
  }

  toggleDoorcodeEnabled(value: boolean): void {
    this.selectableLock.toggleDoorcodeEnabled(value);
    this.update.emit();
  }

  handleScheduleChange(qualifiedDays: QualifiedDay[]) {
    this.selectableLock.handleScheduleChange(qualifiedDays);
    this.update.emit();
  }

  handleViewDoorGroupForLock(): void {
    this.viewDoorGroupForLock.emit(this.selectableLock);
  }
}
