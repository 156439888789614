import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyDate'
})
export class FriendlyDatePipe implements PipeTransform {

  transform(then: Date, args?: any): string {
    if (!then) {
      return '';
    }

    const date = moment(then);

    if (date.isSameOrAfter(moment().subtract(1, 'hour'))) {
      return date.from(moment());
    }

    if (date.isSameOrAfter(moment().startOf('day'))) {
      return `Today at ${date.format('h:mma')}`;
    }

    if (date.isSameOrAfter(moment().subtract(1, 'day').startOf('day'))) {
      return `Yesterday at ${date.format('h:mma')}`;
    }

    if (date.isSameOrAfter(moment().subtract(6, 'days').startOf('day'))) {
      return date.format('dddd [at] h:mma');
    }

    if (date.isSameOrAfter(moment().startOf('year'))) {
      return date.format('ddd, MMM D [at] h:mma');
    }

    return date.format('MMM D, YYYY [at] h:mma');
  }
}
