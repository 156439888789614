import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LatchAnalyticsService } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Building } from '../../../models/building';
import { Person } from '../../../models/contact-cards';
import {
  CloseSendNotificationModal,
  DeliveryUnitList,
  DeliveryUnitNotificationType,
  notificationTypeNames,
  NotifyResident
} from '../../../models/delivery';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { DeliveryService } from '../../../services/delivery/delivery.service';
import { UnitService } from '../../../services/units/unit.service';
import { getFullName } from '../../../services/utility/presentation';

@Component({
  selector: 'latch-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  @Input() public deliveryUnit!: DeliveryUnitList;
  @Output() public closeModal = new EventEmitter<CloseSendNotificationModal>();

  public isLoading = false;
  public notifyAllResidents = false;
  public notifyResidentsCount = 0;
  public notifyResidents: NotifyResident[] = [];
  public notificationTypeNames = notificationTypeNames;

  private selectedBuilding!: Building;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private selectedBuidlingService: SelectedBuildingsService,
    private unitService: UnitService,
    private deliveryService: DeliveryService,
    private analyticsService: LatchAnalyticsService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedBuidlingService.getSelectedBuildings().pipe(
      tap(buildings => this.selectedBuilding = buildings[0]),
      switchMap(() => this.unitService.getUnitResidents(this.selectedBuilding.uuid, this.deliveryUnit.unit.id)),
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(unitResidents => {
      this.setNotifyResidents(unitResidents);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setNotifyAllResidents(): void {
    this.notifyResidentsCount = this.notifyResidents.filter(notifyResident => notifyResident.notify).length;
    this.notifyAllResidents = this.notifyResidentsCount === this.notifyResidents.length;
  }

  setResidentNotifyValue(value: boolean, notifyResident: NotifyResident): void {
    notifyResident.notify = value;
    this.setNotifyAllResidents();
  }

  handleNotifyAllResidentsChange(value: boolean): void {
    this.notifyResidents.forEach(notifyResident => notifyResident.notify = value);
    this.setNotifyAllResidents();
  }

  setResidentNotificationType(notificationType: DeliveryUnitNotificationType, resident: NotifyResident): void {
    resident.notificationType = notificationType;
  }

  sendNotifications(): void {
    this.isLoading = true;
    this.trackOneOffNotifications(this.notifyResidents);
    const notifyResidentsRequest = {
      notifications: this.notifyResidents
        .filter(notifyResident => notifyResident.notify && notifyResident.notificationType)
        .map(notifyResident => ({
          unitId: this.deliveryUnit.unit.id,
          notificationType: notifyResident.notificationType,
          contactCardId: notifyResident.contactCardId
        }))
    };
    this.deliveryService.notifyResidents(this.selectedBuilding.uuid, this.deliveryUnit.deliveryRequest.id, notifyResidentsRequest).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(notifyResidentsResponse =>
      notifyResidentsResponse.notifications.every(notification => notification.success) ?
        this.closeModal.emit('success') : this.closeModal.emit('error')
    );
  }

  private trackOneOffNotifications(notifyResidents: NotifyResident[]): void {
    const totalNotifications = notifyResidents.filter(notifyResident => notifyResident.notify);
    const emailNotifications = totalNotifications.filter(notification =>
      notification.notificationType === DeliveryUnitNotificationType.EMAIL
    );
    const smsNotifications = totalNotifications.filter(notification =>
      notification.notificationType === DeliveryUnitNotificationType.SMS
    );
    this.analyticsService.track('One Off Notifications Sent', {
      'Building Name': this.selectedBuilding.name,
      'Building Timezone': this.selectedBuilding.timezone,
      'Total Notifications': totalNotifications.length,
      'Email Notifications': emailNotifications.length,
      'Sms Notifications': smsNotifications.length,
      'Email Notifications %': (emailNotifications.length / totalNotifications.length * 100).toFixed(1),
      'Sms Notifications %': (smsNotifications.length / totalNotifications.length * 100).toFixed(1)
    });
  }

  private setNotifyResidents(residents: Person[]): void {
    this.notifyResidents = residents.map(resident => {
      const notificationTypes: DeliveryUnitNotificationType[] = [];
      if (resident.userEmail) {
        notificationTypes.push(DeliveryUnitNotificationType.EMAIL);
      }
      if (resident.userPhoneNumber) {
        notificationTypes.push(DeliveryUnitNotificationType.SMS);
      }
      const notifyResident: NotifyResident = {
        contactCardFullName: getFullName(resident.userFirstName, resident.userLastName),
        contactCardId: resident.personUUID,
        notify: false,
        notificationTypes,
        notificationType: notificationTypes.length > 0 ? notificationTypes[0] : undefined
      };
      return notifyResident;
    });
  }
}
