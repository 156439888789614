import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  // Timeout for clearing notification message.
  private messageTimeout: number | undefined;

  private _message = '';

  private _errorHeader = '';
  private _errorBody = '';
  private _disableDefaultMessage = false;

  get message(): string {
    return this._message;
  }

  clearMessage() {
    this._message = '';
  }

  showMessage(newMessage: string) {
    window.clearTimeout(this.messageTimeout);
    this._message = newMessage;

    if (this._message !== '') {
      this.messageTimeout = window.setTimeout(() => {
        this._message = '';
      }, 5000);
    }
  }

  get errorHeader(): string {
    return this._errorHeader;
  }

  get errorBody(): string {
    return this._errorBody;
  }

  get disableDefaultMessage(): boolean {
    return this._disableDefaultMessage;
  }

  clearError() {
    this._errorHeader = '';
    this._errorBody = '';
  }

  showError(newHeader: string, newBody: string, disableDefaultMessage = false) {
    this._errorHeader = newHeader;
    this._errorBody = newBody;
    this._disableDefaultMessage = disableDefaultMessage;
  }
}
