import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LockType } from '../../models/lock';

import { LockService } from '../lock/lock.service';
import { ElevatorBuildingService } from './elevator-building.service';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';
import { shareReplay, switchMap, map } from 'rxjs/operators';

/**
 * A LockBasedElevatorBuildingService is an ElevatorBuildingService which determines whether a building is an
 * "elevator building" based on whether that building has any locks of type elevator.
 */
@Injectable()
export class LockBasedElevatorBuildingService extends ElevatorBuildingService {

  private buildingObsMap: Record<string, Observable<boolean>> = {};

  constructor(
    private selectedBuildingsService: SelectedBuildingsService,
    private lockService: LockService
  ) {
    super();
  }

  // Cache the result, since there are a few places scattered throughout the app where we will want to find out
  // whether it's an elevator building and we don't want to incur the cost of a GET all locks call every time.
  // If an elevator is added while the user is already on MW they won't see it until they refresh.
  // This particular cache mechanism also has the side effect that when the user switches buildings we'll
  // immediately GET all locks (ideal behavior would instead be that it waits until a new subscriber is
  // registered).

  isCurrentElevatorBuilding(): Observable<boolean> {
    return this.selectedBuildingsService.getSelectedBuildings().pipe(
      map(buildings => buildings[0]),
      switchMap(building => this.isElevatorBuildingInternal(building.uuid))
    );
  }

  isElevatorBuilding(buildingUUID: string | null): Observable<boolean> {
    if (!buildingUUID) {
      return this.isCurrentElevatorBuilding();
    }

    return this.isElevatorBuildingInternal(buildingUUID);
  }

  private isElevatorBuildingInternal(buildingUUID: string): Observable<boolean> {
    let buildingObs = this.buildingObsMap[buildingUUID];
    if (buildingObs) {
      return buildingObs;
    }

    buildingObs = this.lockService.getAllLocks([buildingUUID]).pipe(
      map((locks) => locks.some((lock) => lock.lockType === LockType.ELEVATOR)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.buildingObsMap[buildingUUID] = buildingObs;
    return buildingObs;
  }
}
