<div class="modal" *ngIf="shouldShow">
  <div class="content">
    <div class="visual">
      <div class="key-card">
        <div class="key-header">
          <div class="icon-door-key"></div>
          <div>
            <div class="text-1">Amenities</div>
            <div class="text-2"><strong>12</strong> doors</div>
          </div>
        </div>
        <div class="key-settings">
          <latch-checkbox label="May share key" [checked]="true" [disabled]="true"></latch-checkbox>
        </div>
      </div>
      <button class="latch-icon-close" (click)="handleClose()"></button>
    </div>
    <div class="text">
      <h1>Manage access with Keys</h1>
      <p>
        With Keys, you can group doors and permissions that work for your residents, staff, and guests.
        Keys are powerful because you can give them to multiple people and manage everyone at the same time.
      </p>
      <button class="latch-button-text" (click)="handleOpenKeys()">Open your keys</button>
    </div>
  </div>
</div>
