import { Component, OnInit, OnDestroy } from '@angular/core';
import { UnitService, DUPLICATE_UNIT_NAME, UnitDetailsResponse } from 'manager/services/units/unit.service';
import { PermissionsService } from 'manager/services/permissions/permissions.service';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { zip, Subject, of } from 'rxjs';
import { map, distinctUntilChanged, takeUntil, take, tap, switchMap, catchError, filter } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IntercomService, IntercomShortInfo } from 'manager/services/intercom/intercom.service';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { SmartHomeService } from 'manager/services/smart-home/smart-home.service';
import { LatchAnalyticsService, LatchAnalyticsConstants, LatchNavAction, LatchConfirmationDialogConfig,
  LatchDialogService,
  LatchDatasource } from '@latch/latch-web';
import { SmartDevice, SmartDeviceConnectionType, SmartDeviceType } from 'manager/models/device';
import { SpaceService, SpaceShortInfo } from 'manager/services/space/space.service';
import { SelectedBuildingsService } from '@managerweb/services/appstate/selected-buildings.service';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

interface FormErrors {
  name?: string;
}

enum UnitDetailsTab {
  Details = 'DETAILS',
  Devices = 'DEVICES'
}

interface DeviceAndSpaceInfo {
  uuid: string;
  name: string;
  devices: SmartDevice[];
}

interface SmartDeviceWithSpaceName extends SmartDevice{
  spaceName?: string;
}

export function emptyAfterTrimValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => control.value.trim() === '' ? { required: true } : null;
}

@Component({
  selector: 'latch-unit-detail-page',
  templateUrl: './unit-detail-page.component.html',
  styleUrls: ['./unit-detail-page.component.scss']
})
export class UnitDetailPageComponent implements OnInit, OnDestroy {

  isLoading = false;
  currentUserCanEditUnits = false;
  unit!: UnitDetailsResponse;
  editMode = false;
  name = new FormControl('', {
    validators: [
      Validators.required,
      emptyAfterTrimValidator(),
    ],
    nonNullable: true,
  });
  localTelecom: string | undefined;
  errors: FormErrors = {};
  intercoms: IntercomShortInfo[] = [];
  private showParrot = false;
  private showSmartHomeNest = false;
  public showSmartHome = false;
  unitHasDispute = false;
  showDisputePrompt = false;
  showConfirmCloseDisputePrompt = false;

  unitDevices: SmartDevice[] = [];
  unitSpaces: SpaceShortInfo[] = [];
  SmartDeviceConnectionType = SmartDeviceConnectionType;
  unitDevicesBySpace: DeviceAndSpaceInfo[] = [];
  errorLoadingDevicesOrSpaces = false;

  UnitDetailsTab = UnitDetailsTab;
  activeTab = UnitDetailsTab.Details;

  public pageContext: LatchNavAction = {
    id: 'unit-detail-back',
    name: 'Units',
    path: '/console/access/units',
  };
  public edit: LatchNavAction = {
    id: 'edit-unit-details',
    name: 'Edit',
    clickHandler: this.handleEditUnit.bind(this),
  };
  public cancel: LatchNavAction = {
    id: 'edit-unit-details-cancel',
    name: 'Cancel',
    customClasses: ['latch-button-link'],
    primary: true,
    clickHandler: this.handleCancelEditUnit.bind(this),
  };
  public save: LatchNavAction = {
    id: 'save-unit',
    name: 'Save',
    primary: true,
    clickHandler: this.handleSaveEdit.bind(this)
  };
  public actions: LatchNavAction[] = [this.edit];

  public get emptyListMessage(): string {
    return 'There are no devices assigned to this unit.';
  }

  private unsubscribe$ = new Subject<void>();

  public datasource = new LatchDatasource<SmartDeviceWithSpaceName>({});

  get buildingHasIntercom(): boolean {
    return this.intercoms.length > 0;
  }

  constructor(
    private unitService: UnitService,
    private permissionsService: PermissionsService,
    private errorHandlerService: ErrorHandlerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private analyticsService: LatchAnalyticsService,
    private intercomService: IntercomService,
    private featureService: FeatureService,
    private smartHomeService: SmartHomeService,
    private dialog: LatchDialogService,
    private spaceService: SpaceService,
    private selectedBuildingsService: SelectedBuildingsService,
  ) { }

  ngOnInit() {
    this.checkCurrentUserPermissions();
    this.datasource.startLoading();

    const unitUUID$ = this.activatedRoute.params.pipe(
      map((params: Params) => params.unitUUID as string ?? ''),
      distinctUntilChanged()
    );

    const buildingUUID$ = this.selectedBuildingsService.getSelectedBuilding().pipe(
      filter(building => !!building),
      map(building => building?.uuid as string),
      distinctUntilChanged()
    );

    const buildingHasIntercomFeature$ = this.featureService.hasIntercomFeature$.pipe(take(1));
    const buildingHasSmartHomeNestFeature$ = this.featureService.hasSmartHomeNestFeature$.pipe(take(1));
    const buildingHasSmartHomeFeature$ = this.featureService.hasSmartHomeFeature$.pipe(take(1));

    zip(
      unitUUID$,
      buildingUUID$,
      buildingHasIntercomFeature$,
      buildingHasSmartHomeFeature$, buildingHasSmartHomeNestFeature$
    ).pipe(takeUntil(this.unsubscribe$)).
      subscribe(([
        unitUUID,
        buildingUUID,
        buildingHasIntercomFeature,
        buildingHasSmartHomeFeature,
        buildingHasSmartHomeNestFeature]) => {
        this.showParrot = buildingHasIntercomFeature;
        this.showSmartHome = buildingHasSmartHomeFeature;
        this.showSmartHomeNest = buildingHasSmartHomeNestFeature;
        this.handlePageLoad(unitUUID, buildingUUID);
      });

    this.name.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.save.disabled = !this.name.valid;
    });
  }

  handleEditUnit() {
    this.name.setValue(this.unit.name);
    this.localTelecom = this.unit.localTelecom;
    this.editMode = true;
    this.actions = [
      this.cancel,
      this.save,
    ];
  }

  handleCancelEditUnit() {
    this.name.setValue('');
    this.localTelecom = '';
    this.editMode = false;
    this.actions = [this.edit];
  }

  checkCurrentUserPermissions() {
    this.permissionsService.currentUserMayEditKeys().pipe(takeUntil(this.unsubscribe$)).subscribe((canEditKeys) => {
      this.currentUserCanEditUnits = canEditKeys;
    }, error => {
      this.errorHandlerService.handleException(error);
    });
  }

  handlePageLoad(unitUUID: string, buildingUUID: string) {
    this.isLoading = true;
    const unit$ = this.unitService.getUnitDetails(unitUUID, buildingUUID);
    const intercoms$ = this.showParrot ? this.intercomService.getBuildingIntercoms(buildingUUID) : of([]);
    const dispute$ = this.showSmartHomeNest ? this.smartHomeService.getDisputesForUnit(parseInt(unitUUID, 10)) : of(null);

    zip(unit$, intercoms$, dispute$)
    .pipe(
      tap(([unit, intercoms, dispute]) => {
        this.unit = unit;
        this.intercoms = intercoms;
        this.unitHasDispute = dispute?.hasActiveDispute ? true : false;
        this.showDisputePrompt = this.unitHasDispute ? true : false;
      }),
      switchMap(([unit, _]) => {
        if (this.showSmartHome && !unit.space?.uuid) {
          this.errorLoadingDevicesOrSpaces = true;
        }
        const devices$ = this.showSmartHome && unit.space?.uuid && !this.errorLoadingDevicesOrSpaces ?
          this.smartHomeService.getDevices(unit.space.uuid, true, false).pipe(catchError(error => {
            this.errorLoadingDevicesOrSpaces = true;
            return of([]);
          })) : of([]);
        const spaces$ = this.showSmartHome && unit.space?.uuid ?
          this.spaceService.getSpaces(unit.space.uuid).pipe(catchError(error => {
            this.errorLoadingDevicesOrSpaces = true;
            return of([]);
          }))
          : of([]);
        return zip(devices$, spaces$);
      }),
      takeUntil(this.unsubscribe$))
    .subscribe(([devices, spaces]) => {
    this.isLoading = false;
    this.unitDevices = devices;
    this.unitSpaces = spaces;
    this.generateDeviceAndSpaceInfo();
    this.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Unit Detail',
      [LatchAnalyticsConstants.UnitUUID]: this.unit.id,
      [LatchAnalyticsConstants.UnitName]: this.unit.name
    });
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  public onDeleteUnitClick(): void {
    const dialogConfig: LatchConfirmationDialogConfig = {
      data: {
        messages:[
          `Please confirm that you wish to delete <strong>Unit ${this.unit.name}</strong>`
        ] ,
        primaryButtonText: 'Delete',
        primaryButtonClasses: ['latch-danger'],
      }
    };
    this.dialog.openConfirmation(dialogConfig).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((result) => {
      if (result) {
        this.handleConfirmDeleteUnit();
      }
    });
  }

  handleSaveEdit() {
    this.track('Edit Unit Submit', {
      'Unit Name': this.name
    });
    this.isLoading = true;
    this.errors = {};
    this.unitService.updateUnit({
      id: this.unit.id,
      name: this.name.getRawValue().trim(),
      localTelecom: this.localTelecom
    }).subscribe(unit => {
      this.isLoading = false;
      this.unit.name = unit.name;
      this.unit.localTelecom = unit.localTelecom;
      this.editMode = false;
      this.track('Edit Unit Success');
      this.actions = [this.edit];
    }, error => {
      this.isLoading = false;
      if (error.message === DUPLICATE_UNIT_NAME) {
        this.errors.name = 'Name is already in use';
      } else {
        this.errorHandlerService.handleException(error);
      }
      this.track('Edit Unit Failure', {
        [LatchAnalyticsConstants.ErrorMessage]: error.message
      });
    });
  }

  handleConfirmDeleteUnit() {
    this.isLoading = true;
    this.unitService.deleteUnit(this.unit.id).subscribe(() => {
      this.track('Delete Unit Async', {
        [LatchAnalyticsConstants.Success]: true
      });
      this.isLoading = false;
      this.router.navigate(['/console/access/units'], { queryParamsHandling: 'preserve' });
    }, error => {
      this.isLoading = false;
      this.track('Delete Unit Failure', {
        [LatchAnalyticsConstants.ErrorMessage]: error.message
      });
      this.errorHandlerService.handleException(error);
    });

  }

  handleToggleDisputeBanners() {
    this.showDisputePrompt = !this.showDisputePrompt;
    const dialogConfig: LatchConfirmationDialogConfig = {
      data: {
        messages:[
          'Nest ensures residents can dispute their move-out date for 24 hours.',
          'Close this dispute and ensure access end dates are accurate for each resident in the unit.'
        ] ,
        primaryButtonText: 'Close Dispute',
        primaryButtonClasses: ['latch-danger'],
      }
    };
    this.dialog.openConfirmation(dialogConfig).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((result) => {
      if (result) {
        this.handleConfirmCloseDispute();
      } else {
        this.showDisputePrompt = !this.showDisputePrompt;
      }
    });
  }

  handleConfirmCloseDispute() {
    this.isLoading = true;
    this.smartHomeService.closeDisputeForUnit(this.unit.id).subscribe(() => {
      this.track('Closed Dispute on Unit', {
        [LatchAnalyticsConstants.Success]: true
      });
      this.isLoading = false;
      this.showConfirmCloseDisputePrompt = false;
      this.showDisputePrompt = false;
    }, error => {
      this.isLoading = false;
      this.track('Close Dispute on Unit Failure', {
        [LatchAnalyticsConstants.ErrorMessage]: error.message
      });
      this.errorHandlerService.handleException(error);
    });
  }

  handleTabClicked(tab: string) {
    this.activeTab = tab as UnitDetailsTab;
    if (tab === UnitDetailsTab.Details && this.currentUserCanEditUnits) {
      this.actions = [this.edit];
    } else {
      this.actions = [];
    }

  }

  generateDeviceAndSpaceInfo() {
    this.unitDevicesBySpace = [];
    this.unitDevices.forEach(device => {
      const existingInfo = this.unitDevicesBySpace.find(deviceBySpace => deviceBySpace.uuid === device.spaceUUID);

      if (!existingInfo) {
        const space = this.unitSpaces.find(s => s.uuid === device.spaceUUID);
        if (!space) {
          this.errorLoadingDevicesOrSpaces = true;
          return ;
        }
        const newInfo: DeviceAndSpaceInfo = {
          name: space.name,
          uuid: device.spaceUUID,
          devices: [device]
        };
        this.unitDevicesBySpace.push(newInfo);
      } else {
        existingInfo.devices.push(device);
      }
    });

    // sort by space name for display purposes
    this.unitDevicesBySpace.sort((a, b) => {
      if (a.uuid === this.unit.space?.uuid) {
        return -1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });

    // within each space, sort devices by thermostats first (alphabetically by name) and then other devices by name
    const devicesDatasource: SmartDeviceWithSpaceName[] = [];
    this.unitDevicesBySpace.forEach(info => {
      const thermostats = info.devices.filter(device => device.deviceType === SmartDeviceType.Thermostat);
      const otherDevices = info.devices.filter(device => device.deviceType !== SmartDeviceType.Thermostat);

      thermostats.sort((a, b) => a.name.localeCompare(b.name));
      otherDevices.sort((a,b) => a.name.localeCompare(b.name));

      info.devices = [...thermostats, ...otherDevices];
      info.devices.forEach(device => {
        let spaceName;
        if (info.uuid !== this.unit.space?.uuid) {
          spaceName = info.name;
        }
        devicesDatasource.push({ ...device, spaceName });
      });
    });

    this.datasource.set(devicesDatasource);
    this.datasource.stopLoading();
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onRowClick(device: SmartDevice) {
    this.router.navigate(['/console/devices', device.uuid], { queryParamsHandling: 'preserve' });
  }

  track(eventName: string, properties = {}) {
    this.analyticsService.track(eventName, Object.assign({
      [LatchAnalyticsConstants.UnitUUID]: this.unit.id,
      [LatchAnalyticsConstants.UnitName]: this.unit.name
    }, properties));
  }

}
