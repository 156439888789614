import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Key, KeyListView, KeyType, QualifiedDay } from '../../models/key';
import { OperationResultResponse } from '../../models/operations';

export interface CreateKeyInput {
  name: string;
  accountUUID: string;
  buildingUUID: string;
  doors: CreateKeyDoorInput[];
  type?: KeyType;
  partnerUUID?: string;
}

export interface UpdateKeyInput {
  uuid: string;
  name: string;
}

export interface CreateKeyDoorInput {
  lockUUID: string;
  doorcodeEnabled?: boolean;
  // backend will throw errors when we try to create an elevator with a schedule
  days?: QualifiedDay[];
  defaultFloorGroup?: string;
}

export interface UpdateKeyDoorInput {
  uuid: string;
  doorcodeEnabled?: boolean;
  days?: QualifiedDay[];
  defaultFloorGroup?: string;
}

export interface UpdateKeyDoorsInput {
  uuid: string;
  toCreate: CreateKeyDoorInput[];
  toUpdate: UpdateKeyDoorInput[];
  toDelete: string[];
}

export const Errors = {
  InvalidKeyName: 'INVALID_KEY_NAME'
};

@Injectable()
export abstract class KeyService {
  abstract getKeys(buildingUUID: string): Observable<Key[]>;

  abstract getKey(keyUUID: string, buildingUUID?: string): Observable<Key>;

  abstract getKeysList(buildingUUID: string): Observable<KeyListView[]>;

  abstract createKey(input: CreateKeyInput): Observable<OperationResultResponse>;

  abstract updateKey(input: UpdateKeyInput): Observable<OperationResultResponse>;

  abstract deleteKey(keyUUID: string): Observable<OperationResultResponse>;

  abstract updateKeyDoors(input: UpdateKeyDoorsInput): Observable<OperationResultResponse>;

}
