<latch-card class="page" *ngIf="!viewingFloorGroup"
  headerTitle="{{ elevatorName }} floor group" [showSearch]="true"
  (searchChange)="search = $event">
  <button class="latch-button-icon" latchCardActions (click)="cancel.emit()">
    <span class="latch-icon-chevron-left"></span>
  </button>

  <ng-container latchCardContent>
    <div class="cell-list" *ngIf="floorGroups.length !== 0">
      <a
        class="cell cell-row"
        href="javascript:void(0)"
        (click)="viewFloorGroup(floorGroup)"
        *ngFor="let floorGroup of floorGroups | listFilter: search : filter"
      >
        <div class="cell-text">
          <div class="text-1">
            {{floorGroup.name}}
            <span class="floor-group-detail">
              {{isFloorGroupSelected(floorGroup) ? '(Selected)' : ''}}
              {{isFloorGroupDefault(floorGroup) ? '(Default)' : ''}}
            </span>
          </div>
        </div>
        <div class="latch-icon-chevron-right"></div>
      </a>
    </div>
  </ng-container>
</latch-card>

<latch-floor-group-floor-list
    *ngIf="!!viewingFloorGroup"
    [floorGroup]="viewingFloorGroup"
    (cancel)="stopViewingFloorGroup()">
</latch-floor-group-floor-list>
