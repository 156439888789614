import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LatchStorageService {
  public setLocalStorageItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getLocalStorageItem<T>(key: string): T | undefined {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  }

  public setTimestamp(key: string, timestamp: number): void {
    this.setLocalStorageItem(key, timestamp);
  }

  public getTimestamp(key: string): boolean {
    const timestamp = this.getLocalStorageItem<number>(key);
    return moment().diff(timestamp) < 0;
  }
}
