<ng-container *ngIf="showInsights">
  <div class="container latch-viewport" [class.opened]="expanded">
    <div class="header">
      <div class="header-content">
        <div class="title">
          Your daily insights
        </div>
        <span *ngIf="lastRefreshText && expanded" class="last-refresh">
          Last refresh:
          {{ lastRefreshText | date: 'MM/dd/yy, hh:mm a' }}
        </span>
        <span *ngIf="selectedTimeRange && expanded" class="time-range">
          <span>Time range:</span>
          <latch-dropdown [pill]="false" [data]="timeRangeData"
            [(ngModel)]="selectedTimeRange"
            (ngModelChange)="onSelectedTimeRangeChange($event)">
          </latch-dropdown>
        </span>
      </div>
      <button class="insights-toggle-button" (click)="toggle()">
        <img *ngIf="!expanded" src="manager/assets/images/latch-chevron-up.svg"
          alt="expand icon" class="btn" />
        <img *ngIf="expanded" src="manager/assets/images/latch-chevron-down.svg"
          alt="collapse icon" class="btn" />
      </button>
    </div>
    <div *ngIf="expanded" class="content">
      <latch-insight-card *ngFor="let item of insightItems" [data]="item"
        [selectedTimeRange]="selectedTimeRange"
        (hyperlinkClick)="onHyperlinkClick(item)">
      </latch-insight-card>
    </div>
  </div>
</ng-container>
