import { Injectable } from '@angular/core';
import { KeyAdminPermissions } from 'manager/models/account-administration';
import { Observable } from 'rxjs';

@Injectable()
export abstract class PermissionsService {
  abstract currentUserIsOwner(): Observable<boolean>;

  /**
   * Whether the currently logged in user has the ability to see (but not necessarily edit)
   * all keys in the currently selected building.
   *
   * False if the user can see only some keys or no keys, or if the user's permissions don't
   * give them enough permission to know whether the keys they have are all the keys (if they
   * have specific key permissions, they don't even know about the existence of other keys).
   */
  abstract currentUserMaySeeAllKeys(): Observable<boolean>;

  abstract currentUserMayEditDoors(): Observable<boolean>;

  abstract currentUserMayEditKeys(): Observable<boolean>;

  abstract currentUserMaySeeSomeKeys(): Observable<boolean>;

  abstract currentUserKeyAdminPermissions(): Observable<KeyAdminPermissions | undefined>;

  abstract currentUserIsInstaller(): Observable<boolean | undefined>;
}
