import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Resource, ResourceVideoGuide } from '../../models/resource';

let apiLoaded = false;

@Component({
  selector: 'latch-resources-page',
  templateUrl: './resources-page.component.html',
  styleUrls: ['./resources-page.component.scss']
})
export class ResourcesPageComponent implements OnInit {

  public currentlyPlaying: ResourceVideoGuide | undefined;

  public hoveringVideoGuide: ResourceVideoGuide | undefined;

  @ViewChildren('video')
  public videos: QueryList<ElementRef<HTMLVideoElement>> | undefined;

  public playerVars = {
    autoplay: 1,
  };

  resources: Resource[] = [
    {
      title: 'Latch Support',
      subtext: 'Find answers to any questions you have',
      image: 'manager/assets/images/living-room.png',
      link: 'https://support.latch.com/hc/en-us'
    },
    {
      title: 'Managing with Latch',
      subtext: 'Managing your property is easier than ever before',
      image: 'manager/assets/images/building-manager.png',
      link: 'https://www.latch.com/managing-with-latch'
    },
    {
      title: 'Resident Experience',
      subtext: 'Experience the Latch way of living',
      image: 'manager/assets/images/phone-unlock-lock.png',
      link: 'https://www.latch.com/welcome-home'
    }
  ];

  public videoGuides: ResourceVideoGuide[] = [
    {
      title: 'Mission Control Basics',
      description: 'Get started with the basics of Latch mission control',
      image: '/manager/assets/images/manage-portfolio-image.png',
      preview: '/manager/assets/images/manage-portfolio-preview.mp4',
      videoId: 'lU5Y06Lzbns',
    },
    {
      title: 'Device Management',
      description: 'Learn how to Add/remove properties, upgrade software, view battery life and perform door updates',
      preview: '/manager/assets/images/device-management-preview.mp4',
      videoId: '1csbNnTcoiU',
    },
    {
      title: 'Key Management Overview',
      description: 'An overview of the three levels of key management permissions',
      preview: '/manager/assets/images/key-management-preview.mp4',
      videoId: 'qGKHrZVb-1w',
    },
    {
      title: 'Latch Intercom Tutorial',
      description: 'Set up and display people and units on the intercom, add featured entries, and hide people from the display',
      image: '/manager/assets/images/intercom-settings-image.png',
      preview: '/manager/assets/images/intercom-settings-preview.mp4',
      videoId: 'tXrtNpr6RJs',
    },
  ];

  ngOnInit() {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  public onPlayClick(videoGuide: ResourceVideoGuide): void {
    this.currentlyPlaying = videoGuide;
  }

  public onVideoGuideHoverStart(videoGuide: ResourceVideoGuide, index: number): void {
    this.hoveringVideoGuide = videoGuide;
    if (this.videos) {
      const video = this.videos.toArray()[index].nativeElement;
      video.currentTime = 0;
      video.play();
    }
  }

  public onVideoGuideHoverStop(index: number): void {
    this.hoveringVideoGuide = undefined;
    if (this.videos) {
      const video = this.videos.toArray()[index].nativeElement;
      video.pause();
    }
  }

}
