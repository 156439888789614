<latch-loading [isActive]="isLoading">
  <ng-container *ngIf="!showImport">
    <latch-card class="page" *ngIf="!createdUnits && !isLoading"
      headerTitle="Add Unit">
      <a class="latch-button-icon" latchCardActions
        [routerLink]="['/console/access/units']" queryParamsHandling="preserve">
        <span class="latch-icon-close"></span>
      </a>

      <ng-container latchCardContent>
        <div class="field-list">
          <div class="section">
            <latch-tab-group class="latch-card-header-offset latch-tabs-wrapper" [selected]="currentTab" (tabClicked)="handleTabClicked($event)">
              <latch-tab [id]="Tab.CreateUnit">New Unit</latch-tab>
              <latch-tab [id]="Tab.CSV">Import CSV</latch-tab>
            </latch-tab-group>

            <ng-container *ngIf="!isImportMode">
              <div class="cell" [class.has-error]="errors.name">
                <div class="cell-heading">Unit Name</div>
                <div class="cell-body">
                  <input
                    type="text"
                    placeholder=""
                    class="latch-form-control"
                    [(ngModel)]="name"
                    [maxlength]="MAX_UNIT_NAME_LENGTH"
                    latchAutofocus
                  />
                </div>
                <div *ngIf="errors.name" class="help-block">{{errors.name}}</div>
              </div>

              <div class="cell" *ngIf="buildingHasIntercom">
                <div class="cell-heading">Indoor Intercom Number (Optional)</div>
                <div class="cell-body">
                  <input
                    type="text"
                    placeholder="Indoor Intercom Number"
                    class="latch-form-control"
                    [(ngModel)]="localTelecom"
                  />
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isImportMode">
              <div class="cell">
                <div class="csv-import-box">
                  <button class="latch-button-link" (click)="showImport = true" *ngIf="units.length === 0">
                    Import CSV
                  </button>
                  <div class="import-selection" *ngIf="units.length > 0">
                    <div class="import-summary">
                      <div class="latch-icon-confirm"></div>
                      <div>
                        <strong>{{units.length}}</strong> imported
                      </div>
                    </div>
                    <button class="latch-icon-close" (click)="units = []"></button>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </ng-container>

      <ng-container latchCardFooter>
        <button
          class="latch-button"
          [disabled]="!canSubmit"
          (click)="handleSubmit()"
        >
          Save
        </button>
      </ng-container>
    </latch-card>

    <latch-card class="page confirmation" *ngIf="!!createdUnits"
      headerTitle="{{ isImportMode ? 'Units' : 'Unit' }} added.">
      <button class="latch-button-link" latchCardActions
        (click)="handleCreateAnother()">
        Add another unit
      </button>
      <ng-container latchCardFooter>
        <a
          class="latch-button-text"
          [routerLink]="['/console/access/units']"
          queryParamsHandling="preserve"
        >
          Done
        </a>
      </ng-container>
    </latch-card>
  </ng-container>

  <latch-import-units-page *ngIf="showImport"
    (cancel)="showImport = false"
    (import)="handleImport($event)"
    [showPbx]="buildingHasIntercom"
  >
  </latch-import-units-page>
</latch-loading>
