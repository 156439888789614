import moment from 'moment';
import { Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';

export enum Announcement {
  Keys = 'keys'
}

export const AnnouncementConfig = {
  [Announcement.Keys]: {
    namespace: 'keys',
    expiration: moment('2018-04-15')
  }
};

const STORAGE_KEY = 'announcements';

interface AnnouncementsState {
  [userUUID: string]: {
    [namespace: string]: boolean;
  };
}

@Injectable({ providedIn: 'root' })
export class AnnouncementService {

  constructor(private authService: AuthService) { }

  get userKey(): string {
    return this.authService.currentUser ?
      this.authService.currentUser.uuid :
      'anonymous';
  }

  shouldShow(announcement: Announcement) {
    const config = AnnouncementConfig[announcement];
    if (!config) {
      return false;
    }
    const expired = moment().isSameOrAfter(config.expiration);
    if (expired) {
      return false;
    }
    const hasSeen = this.read()?.[this.userKey]?.[config.namespace];
    return !hasSeen;
  }

  markAsSeen(announcement: Announcement) {
    const config = AnnouncementConfig[announcement];
    if (!config) {
      return;
    }
    const state = this.read();
    state[this.userKey][config.namespace] = true;
    this.write(state);
  }

  private read(): AnnouncementsState {
    const json = localStorage.getItem(STORAGE_KEY);
    let state: any;
    try {
      if (json) {
        state = JSON.parse(json);
      }
    } catch (e) {
      localStorage.removeItem(STORAGE_KEY);
    }
    return state || {};
  }

  private write(state: AnnouncementsState) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }

}
