import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { parsePhoneNumber } from '../services/utility/utility';

@Directive({
  selector: '[latchPhoneNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidatePhoneNumberDirective, multi: true }]
})
export class ValidatePhoneNumberDirective implements Validator {

  @Input() phoneNumber: string | undefined;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const phoneNumber = parsePhoneNumber(control.value);
    const hasError = phoneNumber.length !== 0 && phoneNumber.length !== 10;
    return hasError ? { phoneNumber } : null;
  }

}
