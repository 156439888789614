import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Person } from 'manager/models/contact-cards';
import {
  CreateLeaseInputV2,
  Lease,
  LeaseBase,
  LeaseFee,
  LeaseResidents,
  LeaseShort,
  RentDetails,
  RentDetailsResponse,
  RentDetailsStatus,
  UpdateLeaseInput,
  UpdateLeasesBatchInput
} from 'manager/models/lease';
import { TransactionBase, Transaction, BankAccount } from 'manager/models/payment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isDefined } from '@latch/latch-web';
import { AuthService } from '../auth/auth.service';
import { epochSecondsToDate } from '../utility/utility';
import { GetLeasesInput, LeaseService } from './lease.service';

type LeaseShortRaw = LeaseBase & {
  /** epoch seconds */
  startDate: number;
  /** epoch seconds */
  endDate: number;
};

type LeaseRaw = LeaseShortRaw & {
  otherResidents?: Person[];
  propertyManager: Person;
  /** amount in US cents */
  firstMonthRent: number;
  fees?: LeaseFee[];
  /** amount in US cents */
  securityDeposit: number;
  /** epoch seconds */
  firstMonthRentAndFeesDueDate: number;
  firstMonthRentAndFeesPayment?: TransactionRaw;
  /** epoch seconds */
  securityDepositDueDate: number;
  securityDepositPayment?: TransactionRaw;
  rentDestinationPaymentMethodId: string;
  depositDestinationPaymentMethodId: string;
  isActive: boolean;
};

type TransactionRaw = TransactionBase & {
  /** epoch seconds */
  timestamp: number;
  updateTimestamp: number;
};

export interface RentDetailsResponseRaw {
  depositDetails: RentDetailsRaw;
  firstMonthRentDetails: RentDetailsRaw;
  monthlyRentDetails: RentDetailsRaw;
}

export interface RentDetailsRaw {
  amount: number;
  dueDate?: number;
  status: RentDetailsStatus;
  transaction?: TransactionRaw;
  destinationAccount: BankAccount;
  fees: LeaseFee[];
}

@Injectable()
export class HTTPLeaseService extends LeaseService {
  constructor(
    private authService: AuthService
  ) {
    super();
  }

  public getLeasesV2(input: GetLeasesInput): Observable<Lease[]> {
    let httpParams = new HttpParams();
    if (isDefined(input.destinationId)) {
      httpParams = httpParams.append('destinationId', input.destinationId);
    }
    if (isDefined(input.buildingUUID)) {
      httpParams = httpParams.append('buildingUUID', input.buildingUUID);
    }
    if (isDefined(input.primaryResidentUserUUID)) {
      httpParams = httpParams.append('primaryResidentUserUUID', input.primaryResidentUserUUID);
    }
    return this.authService
      .request({
        method: 'get',
        endpoint: '/web/v2/leases',
        search: httpParams
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map((response: LeaseRaw[]): Lease[] =>
          response.map(item => leaseRawToJSON(item))
        ),
        catchError((error: Error) => this.handleGetLeasesError(error)),
      );
  }

  public getLeaseV2(leaseUUID: string): Observable<Lease> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v2/leases/${leaseUUID}`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map(leaseRawToJSON),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public getLease(leaseUUID: string): Observable<Lease> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/leases/${leaseUUID}`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map(leaseRawToJSON),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public createLeaseV2(input: CreateLeaseInputV2): Observable<LeaseShort> {
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v2/leases',
      data: input
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(leaseShortRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public updateLease(leaseUUID: string, input: UpdateLeaseInput):
    Observable<LeaseShort> {
    return this.authService
      .request({
        method: 'put',
        endpoint: `/web/v1/leases/${leaseUUID}`,
        data: input
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        map(leaseShortRawToJSON),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public updateLeasesBatch(input: UpdateLeasesBatchInput): Observable<void> {
    return this.authService
      .request({
        method: 'patch',
        endpoint: '/web/v1/leases',
        data: input
      }).pipe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map(() => { }),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public deleteLease(leaseUUID: string): Observable<void> {
    return this.authService
      .request({
        method: 'delete',
        endpoint: `/web/v1/leases/${leaseUUID}`
      }).pipe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map(() => { }),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public resendInviteEmail(leaseUUID: string): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/leases/${leaseUUID}/resendInviteEmail`,
        data: {}
      }).pipe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        map(() => { }),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public getPaymentHistory(leaseUUID: string): Observable<Transaction[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/leases/${leaseUUID}/transactions`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((response: TransactionRaw[]): Transaction[] =>
        response.map(item => transactionRawToJSON(item))),
      map((response) => response.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getPaymentDetails(leaseUUID: string, paymentUUID: string): Observable<Transaction> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/leases/${leaseUUID}/transactions/${paymentUUID}`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(transactionRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getRentDetails(leaseUUID: string): Observable<RentDetailsResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/leases/${leaseUUID}/rentDetails`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map(rentDetailsResponseRawToJSON),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  public getLeaseResidents(leaseUUID: string): Observable<LeaseResidents> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/leases/${leaseUUID}/residents`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  private handleError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }

  private handleGetLeasesError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse && error.status === 500) {
      return throwError({ message: 'GET_LEASE_LIST_ERROR' });
    } else {
      return this.handleError(error);
    }
  }
}

function leaseShortRawToJSON(leaseRaw: LeaseShortRaw): LeaseShort {
  return {
    ...leaseRaw,
    startDate: epochSecondsToDate(leaseRaw.startDate),
    endDate: epochSecondsToDate(leaseRaw.endDate),
  };
}

function transactionRawToJSON(transaction: TransactionRaw): Transaction {
  return {
    ...transaction,
    timestamp: epochSecondsToDate(transaction.timestamp),
    updateTimestamp: epochSecondsToDate(transaction.updateTimestamp)
  };
}

function leaseRawToJSON(lease: LeaseRaw): Lease {
  return {
    ...lease,
    startDate: epochSecondsToDate(lease.startDate),
    endDate: epochSecondsToDate(lease.endDate),
    firstMonthRentAndFeesDueDate: epochSecondsToDate(lease.firstMonthRentAndFeesDueDate),
    securityDepositDueDate: epochSecondsToDate(lease.securityDepositDueDate),
    firstMonthRentAndFeesPayment: isDefined(lease.firstMonthRentAndFeesPayment) ?
      transactionRawToJSON(lease.firstMonthRentAndFeesPayment) : undefined,
    securityDepositPayment: isDefined(lease.securityDepositPayment) ?
      transactionRawToJSON(lease.securityDepositPayment) : undefined,
  };
}

function rentDetailsResponseRawToJSON(rentDetailsResponseRaw: RentDetailsResponseRaw): RentDetailsResponse {
  return {
    depositDetails: isDefined(rentDetailsResponseRaw.depositDetails) ?
      rentDetailsRawToJSON(rentDetailsResponseRaw.depositDetails) : undefined,
    firstMonthRentDetails: isDefined(rentDetailsResponseRaw.firstMonthRentDetails) ?
      rentDetailsRawToJSON(rentDetailsResponseRaw.firstMonthRentDetails) : undefined,
    monthlyRentDetails: isDefined(rentDetailsResponseRaw.monthlyRentDetails) ?
      rentDetailsRawToJSON(rentDetailsResponseRaw.monthlyRentDetails) : undefined,
  };
}

function rentDetailsRawToJSON(raw: RentDetailsRaw): RentDetails {
  return {
    ...raw,
    dueDate: isDefined(raw.dueDate) ? epochSecondsToDate(raw.dueDate) : undefined,
    transaction: isDefined(raw.transaction) ? transactionRawToJSON(raw.transaction) : undefined
  };
}
