<div class="summary">
  Schedule:
  {{getTimeText(timeInterval.start)}} to {{getTimeText(timeInterval.end)}},
  <div class="summary-days">
    <div *ngFor="let day of days" class="summary-day" [class.bold]="day.selected">
      {{day.dayLetter}}
    </div>
  </div>
</div>

<ng-container *ngIf="canEdit">
  <div class="days">
    <button
      *ngFor="let day of days"
      class="day"
      [class.active]="day.selected"
      (click)="handleToggleDay(day)">
      {{day.dayLetter}}
    </button>
  </div>
  <div class="error-message" *ngIf="noDaysSelected">
    You must select at least one day, or remove this door from the key.
  </div>
  <div class="times" [formGroup]="timeIntervalForm">
    <select formControlName="start" class="latch-form-control">
      <option [ngValue]="time" *ngFor="let time of StartTimes">{{getTimeText(time)}}</option>
    </select>
    <div class="connector">to</div>
    <select formControlName="end" class="latch-form-control">
      <option [ngValue]="time" *ngFor="let time of EndTimes" [disabled]="isEndTimeDisabled(time)">{{getTimeText(time)}}</option>
    </select>
  </div>
</ng-container>
