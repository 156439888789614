import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStringList',
  pure: false
})
export class FilterStringListPipe implements PipeTransform {
  transform(value: string[], query: string): string[] {
    if (query == null || query === '') {
      return value;
    }
    return value.filter((element) => element.toLowerCase().includes(query.toLowerCase()));
  }
}
