import { Injectable } from '@angular/core';
import { ReportsTokenPagedResponse, Report, ReportKpi, FieldOption } from 'manager/models/report';
import { Observable } from 'rxjs';

export interface BaseReportInput {
  buildingUUID: string;
  accountUUID: string;
}

export interface GetReportInput extends BaseReportInput {
  type: string;
  pageToken?: string;
  sort?: string;
  filter?: string;
}

export interface ExportReportInput extends BaseReportInput {
  type: string;
  sort?: string;
  filter?: string;
}

export interface GetFieldOptionsInput extends BaseReportInput {
  reportName: string;
  fieldName: string;
  term?: string;
}

@Injectable()
export abstract class ReportService {
  public abstract getReports(data: BaseReportInput): Observable<Report[]>;
  public abstract getKpis(data: BaseReportInput): Observable<ReportKpi[]>;
  public abstract getReportData(data: GetReportInput): Observable<ReportsTokenPagedResponse<unknown>>;
  public abstract exportReportData(data: ExportReportInput): Observable<unknown>;
  public abstract getFieldOptions(data: GetFieldOptionsInput): Observable<FieldOption[]>;
}
