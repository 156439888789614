import moment from 'moment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { Address, Building, BuildingInput, fromJSON } from './../../models/building';
import { SyncTask } from './../../models/sync-status';
import { BuildingService } from './building.service';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { AuthService } from './../auth/auth.service';
import { HttpResponse } from '@angular/common/http';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Account } from '../../models/account';
import { AccountService } from '../account/account.service';

/**
 * HTTPBuildingService provides a version of BuildingService that gets its data from the Latch servers.
 *
 * This is the version of BuildingService which is used in the real, working app.
 */
@Injectable()
export class HTTPBuildingService extends BuildingService {
  private refreshBuildings$ = new BehaviorSubject<void>(undefined);

  constructor(
    protected authService: AuthService,
    private selectedAccountService: SelectedAccountService,
    private accountService: AccountService
  ) {
    super(authService);
  }

  getBuildings(): Observable<Building[]> {
    if (!this.buildings$) {
      this.buildings$ = this.refreshBuildings$.pipe(
        switchMap(() => this.selectedAccountService.getSelectedAccount()),
        map((account: Account) => account.buildings ?? []),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
    return this.buildings$;
  }

  refreshBuildings() {
    this.accountService.refreshAccounts();
    this.refreshBuildings$.next();
  }

  getBuildingTasks(buildingUUID: string): Observable<SyncTask[]> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/tasks`
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response)),
      map((syncTasks: any[]) => syncTasks.map((syncTask) => ({
        ...syncTask,
        deadline: moment.unix(syncTask.deadlineEpoch).toDate(),
        earliestDownlink: moment.unix(syncTask.earliestDownlinkEpoch).toDate()
      }))),
      map((syncTasks: SyncTask[]) => syncTasks.sort((a, b) => a.deadline.getTime() - b.deadline.getTime())),
    );
  }

  createBuilding(buildingDetails: BuildingInput, accountUUID = this.selectedAccountService.selectedAccount.uuid): Observable<Building> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/setup/accounts/${accountUUID}/buildings`,
      data: buildingDetails
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response)),
      map(response => response.building),
      tap(() => this.accountService.clearCache()),
      tap(() => this.refreshBuildings()),
    );
  }

  formatAddress(address: Address): Observable<{ address: Address, formattedAddress: string }> {
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v1/setup/stdaddress',
      data: address
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response)),
    );
  }

  /**
   * Helper method - checks the format of the getBuildings endpoint's response to make sure it has returned legit data.
   */
  private getBuildingsResponseErrors(response: HttpResponse<any>): Error | null {
    if (!AuthService.hasValidPayload(response)) {
      const message = `Response has no payload: '${response.toString()}'`;
      return new Error(message);
    }
    const payload = AuthService.getPayload(response);

    if (!Array.isArray(payload)) {
      const message = `Received unexpected result from server: '${response.toString()}'`;
      return new Error(message);
    }

    for (const object of payload) {
      const buildingError = this.getBuildingErrors(object);
      if (buildingError) {
        return buildingError;
      }
    }

    return null;
  }

  private getBuildingErrors(building: any): Error | null {
    if (!('name' in building)) {
      let message = 'Received unexpected result from server:';
      message += `; building '${JSON.stringify(building)}' has no 'name' property.`;
      return new Error(message);
    }
    if (!('uuid' in building)) {
      let message = 'Received unexpected result from server:';
      message += `; building '${JSON.stringify(building)}' has no 'uuid' property.`;
      return new Error(message);
    }

    return null;
  }

}
