<ngx-charts-pie-chart
  [view]="view"
  [margins]="margins"
  [results]="results"
  [legend]="false"
  [labels]="false"
  [doughnut]="doughnut"
  [scheme]="scheme"
  [arcWidth]="arcWidth"
>
</ngx-charts-pie-chart>
