<latch-table class="latch-table-with-viewport" [data]="datasource" (scrolledToEnd)="nextPage && setPage(nextPage)"
  (rowClick)="openDelivery($event)" [emptyMessage]="emptyListMessage"
  [showLoadMore]="!!nextPage"
  (loadMore)="nextPage && setPage(nextPage)">
  <latch-table-column field="title" columnTitle="Log ID">
    <ng-template latchTableCellTemplate let-delivery>
      <div class="delivery-details" [class.with-image]="delivery?.accessLog?.image?.url">
        <div *ngIf="delivery?.accessLog?.image?.url" class="delivery-image"
          [class.rotate]="delivery?.accessLog?.image?.imageSource === ImageSource.LOCK">
          <img [src]="delivery?.accessLog?.image?.url">
        </div>
        <div class="delivery-info">
          <div class="delivery-title">{{delivery | deliveryInfo : 'title'}}</div>
        </div>
      </div>
    </ng-template>
  </latch-table-column>
  <latch-table-column field="priority" columnTitle="Priority">
    <ng-template latchTableCellTemplate let-delivery>
      <div [class.priority-high]="delivery.priority === DeliveryPriority.HIGH">{{delivery.priority | titlecase}}</div>
    </ng-template>
  </latch-table-column>
  <latch-table-column field="lastUpdatedOn" columnTitle="Time" [sortable]="true">
    <ng-template latchTableCellTemplate let-delivery>
      {{delivery.lastUpdatedOn | date: 'MM/dd/yyyy h:mm a'}}
    </ng-template>
  </latch-table-column>
  <latch-table-column field="packageType" columnTitle="Package Type">
    <ng-template latchTableCellTemplate let-delivery>
      <div class="packages">
        {{delivery | deliveryInfo : 'packages'}}
      </div>
    </ng-template>
  </latch-table-column>
</latch-table>
