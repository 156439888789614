<latch-base-modal>
  <latch-package-details
    *ngIf="packageModalType === 'packageDetails'"
    [deliveryUnit]="deliveryUnit"
    (closeModal)="handleCloseDetailsModal()"
    (openSendNotification)="setPackageModalType('sendNotification')"></latch-package-details>
  <latch-send-notification
    *ngIf="packageModalType === 'sendNotification'"
    [deliveryUnit]="deliveryUnit"
    (closeModal)="handleCloseSendNotificationsModal($event)"></latch-send-notification>
</latch-base-modal>
