import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetDashboardInsightForGroupInput,
  GetInsightsInput, GetInsightsNotificationInput,
  InsightResponse,
  InsightsDashboardDefinitionResponse,
  InsightsDashboardGroupResponse,
  InsightsNotificationMap,
  InsightsNotificationsResponse
} from 'manager/models/insights';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { InsightsService } from './insights.service';
import { keyBy } from '../utility/utility';

@Injectable()
export class HTTPInsightsService extends InsightsService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  public getInsights(input: GetInsightsInput): Observable<InsightResponse> {
    const { accountUUID, buildingUUID } = input;

    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/insights`,
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public getInsightDashboardDefinition(accountUUID: string): Observable<InsightsDashboardDefinitionResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${accountUUID}/insights-dashboard-definition`,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getDashboardInsightForGroup(input: GetDashboardInsightForGroupInput): Observable<InsightsDashboardGroupResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${input.accountUUID}/insights-dashboard/${input.metricGroupName}`,
      search: {
        buildingUUID: input.buildingUUID
      }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getInsightsNotifications(input: GetInsightsNotificationInput): Observable<InsightsNotificationMap> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${input.accountUUID}/insights-dashboard-notifications`,
      search: {
        buildingUUIDs: input.buildingUUID
      }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((data: InsightsNotificationsResponse) =>
        keyBy(data.notifications, notification => notification.groupName)
      ),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }

}
