import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InvalidItem } from './import-csv-result.component';

/**
 * Displays a complete page summarizing the results of an import operation.
 *
 * Allows the user to either proceed with importing or cancel.
 */
@Component({
  selector: 'latch-import-csv-result-page',
  templateUrl: './import-csv-result-page.component.html',
  styleUrls: ['./import-csv-result-page.component.scss']
})
export class ImportCsvResultPageComponent {
  @Output() import = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Input() importName!: string;
  @Input() valid: unknown[] = [];
  @Input() invalid: InvalidItem[] = [];
}
