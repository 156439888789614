import moment from 'moment';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserConsentAction, UserConsentKey, UserCredential, UserNotificationInfo } from '../../models/user';

import { SelectedAccountService } from '../appstate/selected-account.service';
import { AuthService } from '../auth/auth.service';
import { UserConsentResponse, GetUserNotificationSettingsResponse, UserService } from './user.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

/**
 * Implementation of UserService which hits actual HTTP endpoints.
 */
@Injectable()
export class HTTPUserService extends UserService {

  constructor(
    private selectedAccountService: SelectedAccountService,
    private authService: AuthService,
  ) {
    super();
  }

  getUserCredential(userUUID: string, buildingUUID: string): Observable<UserCredential> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const search = new HttpParams()
      .append('buildingUUID', buildingUUID);
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/users/${userUUID}/credential`,
        search
      }).pipe(
        map((response: HttpResponse<any>) => AuthService.getPayload(response)),
        map((credential): UserCredential => ({
          ...credential,
          accountCreated: credential.accountCreated ?
            moment.unix(credential.accountCreated).toDate() :
            null,
          inviteSent: credential.inviteSent ?
            moment.unix(credential.inviteSent).toDate() :
            null
        }))
      );
  }

  activateKeycard(userUUID: string, keycardSerialNumber: string): Observable<void> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/users/${userUUID}/keycards`,
      data: {
        keycardSerialNumber
      }
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response))
    );
  }

  deactivateKeycard(userUUID: string, keycardSerialNumber: string): Observable<void> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/users/${userUUID}/keycards/${keycardSerialNumber}`
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response))
    );
  }

  getNotificationSettings(): Observable<UserNotificationInfo[]> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/me/notification-optouts'
    }).pipe(
      map((response) => {
        const res: GetUserNotificationSettingsResponse = AuthService.getPayload(response);
        return res.settings;
      })
    );
  }

  updateNotificationSettings(settings: UserNotificationInfo[]): Observable<UserNotificationInfo[]> {
    return this.authService.request({
      method: 'put',
      endpoint: '/web/v1/me/notifications-optouts',
      data: {
        settings
      }
    }).pipe(
      map((response) => {
        const res: GetUserNotificationSettingsResponse = AuthService.getPayload(response);
        return res.settings;
      })
    );
  }

  getUserConsent(key: UserConsentKey): Observable<UserConsentResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/user/consent/${key}`,
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response))
    );
  }

  updateUserConsent(key: UserConsentKey, action: UserConsentAction): Observable<UserConsentResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/user/consent/${key}/status/${action}`,
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response))
    );
  }
}
