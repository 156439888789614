<latch-card class="page loading progress-page" [headerTitle]="header">
  <div class="page-body" latchCardContent>
    <div class="progress-bar" [class.operation-finished]="operationState !== OperationState.InProgress && !animationOngoing">
      <div class="progress-fill" #progress></div>
    </div>
    <div class="step" [class.active]="operationState === OperationState.InProgress">
      <div>{{ body }}</div>
    </div>
  </div>
  <div latchCardFooter *ngIf="operationState === OperationState.Complete || operationState === OperationState.Error">
    <button class="latch-button" (click)="done.emit()">
      Okay
    </button>
  </div>
</latch-card>
