<ng-container *ngIf="tabPathMatchFull && showPageContent">
  <latch-subnav headerTitle="Access" [subtitle]="subnavSubtitle" [actions]="subnavActions"></latch-subnav>

  <latch-tab-group class="latch-tab-with-viewport" [selected]="activeTab" (tabClicked)="onTabClick($event)">
    <latch-tab [id]="Tab.Units">Units</latch-tab>
    <latch-tab [id]="Tab.Doors">Doors</latch-tab>
    <latch-tab [id]="Tab.Devices" *ngIf="(showDevices$ | async) === true">Smart Devices</latch-tab>
    <latch-tab [id]="Tab.Keys">Keys</latch-tab>
  </latch-tab-group>
</ng-container>

<router-outlet></router-outlet>
