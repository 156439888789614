import { Injectable } from '@angular/core';
import { Operation } from '../../models/operations';
import { Observable } from 'rxjs';

@Injectable()
export abstract class OperationsService {
  abstract getOperationStatuses<T = unknown>(operationUUID: string | string[]): Observable<Operation<T>[]>;

  abstract getInProgressOperations(): Observable<Operation<unknown>[]>;

  abstract acknowledgeFailure(operationUUID: string): Observable<{ operationId: string }>;

  abstract getUnacknowledgedFailures(): Observable<Operation<unknown>[]>;
}
