export class FloorGroup {
  public uuid: string;
  public name: string;
  public floorNames: string[];

  public static filter() {
    return (list: FloorGroup[], query: any): FloorGroup[] => list.filter(
      (element) => element.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  public static fromJSON(json: any): FloorGroup {
    return new FloorGroup(json.uuid, json.name, json.floorNames);
  }

  constructor(uuid: string, name: string, floorNames: string[]) {
    this.uuid = uuid;
    this.name = name;
    this.floorNames = floorNames;
  }
}
