<ng-container *ngIf="(hasSelfSetup | async); else NO_SELF_SETUP">
  <latch-subnav headerTitle="Welcome back, {{ currentUser! | fullName }}."></latch-subnav>

  <div class="latch-hero">
    <div class="latch-viewport">
      <div class="hero-content">
        <h2>Add your first Property</h2>
        <p>You will be able to manage construction operations, to unattended leasing, to resident move-ins, to maintenance.</p>
        <button class="latch-button" (click)="openCreatePropertyFlow()">Add a property</button>
      </div>
    </div>
  </div>
</ng-container>


<ng-template #NO_SELF_SETUP>
  <latch-subnav headerTitle="Current portfolio has no Buildings"></latch-subnav>
</ng-template>
