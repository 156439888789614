import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { LatchAnalyticsConstants, LatchAnalyticsService, LatchDialogRef } from '@latch/latch-web';
import { Building, PmsSourceDisplay } from 'manager/models/building';
import { LockType } from 'manager/models/lock';
import { MergeConflict, Update } from 'manager/models/notification';
import { Operation } from 'manager/models/operations';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { NotificationCenterService } from 'manager/services/notification-center/notification-center.service';
import { PermissionsService } from 'manager/services/permissions/permissions.service';
import { GetServiceOrdersResponse } from 'manager/services/service-order/service-order.service';
import { Subject, combineLatest } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FailureDetailsComponent } from '../failure-details/failure-details.component';
import { NavigationEnd, Router } from '@angular/router';

enum NotificationCenterTabs {
  Updates = 'UPDATES',
  Errors = 'ERRORS',
  Conflicts = 'CONFLICTS',
  ServiceOrders = 'SERVICE_ORDERS'
}

@Component({
  selector: 'latch-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  @ViewChildren(FailureDetailsComponent) failureDetails!: QueryList<FailureDetailsComponent>;

  private selectedBuilding!: Building;

  public NotificationCenterTabs = NotificationCenterTabs;
  public currentTab: NotificationCenterTabs = NotificationCenterTabs.Updates;
  public isFailuresLoading = false;
  public showServiceOrdersTab = false;
  unsubscribe$ = new Subject<void>();

  public get numUpdates(): number {
    return this.updates.length;
  }

  public get numFailures(): number {
    return this.failures.length;
  }

  public get numConflicts(): number {
    return (this.pmsConflicts ? 1 : 0) + (this.duplicates ? 1 : 0) + (this.duplicateEmails ? 1 : 0) + (this.pmsUnmappedUnits ? 1 : 0);
  }

  public get numServiceOrders(): number {
    return this.serviceOrdersResponse?.serviceOrders?.length ?? 0;
  }

  public get updates(): Update[] {
    return this.notificationService.updates;
  }

  public get failures(): Operation<unknown>[] {
    return this.notificationService.failures;
  }

  public get pmsConflicts(): MergeConflict | undefined {
    return this.notificationService.pmsConflicts;
  }

  public get pmsUnmappedUnits(): MergeConflict | undefined {
    return this.notificationService.pmsUnmappedUnits;
  }

  public get duplicates(): MergeConflict | undefined {
    return this.notificationService.duplicates;
  }

  public get duplicateEmails(): MergeConflict | undefined {
    return this.notificationService.duplicateEmails;
  }

  public get serviceOrdersResponse(): GetServiceOrdersResponse | undefined {
    return this.notificationService.serviceOrdersResponse;
  }

  public get pmsDisplay(): string {
    return this.selectedBuilding.pmsSource ? PmsSourceDisplay[this.selectedBuilding.pmsSource] : '';
  }

  public get showAutomationTab(): boolean {
    return !!this.selectedBuilding.pmsSource;
  }

  constructor(
    private selectedBuildingService: SelectedBuildingsService,
    private notificationService: NotificationCenterService,
    private analyticsService: LatchAnalyticsService,
    private featureService: FeatureService,
    private permissionsService: PermissionsService,
    private dialogRef: LatchDialogRef,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.selectedBuildingService.getSelectedBuildings().subscribe(([selectedBuilding]) => {
      this.selectedBuilding = selectedBuilding;
    });

    combineLatest([
      this.featureService.hasServiceOrdersFeature$,
      this.permissionsService.currentUserIsOwner()
    ]).subscribe(([hasServiceOrdersFeature, currentUserIsOwner]) => {
      this.showServiceOrdersTab = currentUserIsOwner && hasServiceOrdersFeature;
    });

    this.router.events.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getLockTypeText(lockType: LockType): string {
    switch (lockType) {
      case LockType.DOOR:
      default:
        return 'Doors';
      case LockType.ELEVATOR:
        return 'Elevators';
    }
  }

  public handleClose(): void {
    this.dialogRef.close();
  }

  public handleTabClicked(tab: string): void {
    this.currentTab = tab as NotificationCenterTabs;
    if (tab === NotificationCenterTabs.Errors) {
      this.isFailuresLoading = this.failures.length > 0;
    }
  }

  public removeFailure(operationId: string): void {
    const failuresCount = this.failures.length;
    this.isFailuresLoading = true;
    this.notificationService.acknowledgeFailure(operationId)
      .pipe(finalize(() => this.isFailuresLoading = false))
      .subscribe({
        next: () => this.trackAcknowledgeFailure(true, failuresCount),
        error: () => this.trackAcknowledgeFailure(false, failuresCount)
      });
  }

  public clearAllFailures(): void {
    const failuresCount = this.failures.length;
    this.isFailuresLoading = true;
    this.notificationService.acknowledgeFailures()
      .pipe(finalize(() => this.isFailuresLoading = false))
      .subscribe({
        next: () => this.trackAcknowledgeFailure(true, failuresCount),
        error: () => this.trackAcknowledgeFailure(false, failuresCount)
      });
  }

  public handleFailureLoaded() {
    this.isFailuresLoading = this.failureDetails.some(detail => detail.isLoading);
  }

  private trackAcknowledgeFailure(isSuccess: boolean, failuresCount: number, isClearAll = false) {
    this.analyticsService.track('Acknowledge Failure', {
      [LatchAnalyticsConstants.Success]: isSuccess,
      'Total Unacknowledged Failures': failuresCount,
      'Is Clear All': isClearAll
    });
  }
}
