import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LatchAuthGuardService,
  LatchDialogConfig,
  LatchNonAuthGuardService,
  LatchRoutePopupComponent
} from '@latch/latch-web';
import { ActiveSessionsPageComponent } from './components/account/active-sessions-page/active-sessions-page.component';
import { RecentLoginsPageComponent } from './components/account/recent-logins-page/recent-logins-page.component';
import { ViewAccountPageComponent } from './components/account/view-account-page.component';
import { DoorsActivityListPageComponent } from './components/activity/doors-activity-list-page/doors-activity-list-page.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password.component';
import { LoginComponent } from './components/auth/login.component';
import { ConsoleComponent } from './components/console/console.component';
import { DeviceActivityListPageComponent } from './components/activity/device-activity-list-page/device-activity-list-page.component';
import { DeviceDetailPageComponent } from './components/devices/device-detail-page/device-detail-page.component';
import { DuplicatesComponent } from './components/duplicates/duplicates.component';
import { ErrorPageComponent } from './components/error-page.component';
import { MfaFlowComponent } from './components/mfa/mfa-flow.component';
import { SupportPageComponent } from './components/support-page/support-page.component';
import { CreateUnitPageComponent } from './components/units/create-unit-page/create-unit-page.component';
import { UnitDetailPageComponent } from './components/units/unit-detail-page/unit-detail-page.component';
import { UnitsListComponent } from './components/units/units-list-page/units-list-page.component';
import { UnitsReorderPageComponent } from './components/units/units-reorder-page/units-reorder-page.component';
import { LoadAccountGuard } from './services/account/load-account.guard';
import { IntercomSettingsComponent } from './components/intercom-settings/intercom-settings.component';
import { PmsAccessReviewPageComponent } from './components/pms-access-review/pms-access-review-page.component';
import { AddFeaturedEntryComponent } from './components/intercom-settings/add-featured-entry/add-featured-entry.component';
import { ViewFeaturedEntryComponent } from './components/intercom-settings/view-featured-entry/view-featured-entry.component';
import {
  ReorderFeaturedEntriesComponent
} from './components/intercom-settings/reorder-featured-entries/reorder-featured-entries.component';
import { ActivityListPageComponent } from './components/activity/activity-list-page/activity-list-page.component';
import {
  DeliveryActivityListPageComponent
} from './components/deliveries/delivery-activity-list-page/delivery-activity-list-page.component';
import { PackagesActivityListPageComponent } from './components/packages/packages-activity-list-page/packages-activity-list-page.component';
import { StaffPageComponent } from './components/staff-page/staff-page.component';
import { ResourcesPageComponent } from './components/resources-page/resources-page.component';
import { DuplicateEmailsComponent } from './components/duplicate-emails/duplicate-emails.component';
import { InitializeComponent } from './components/initialize/initialize.component';
import { ResetQuaryParamsCanDeactivate } from './core/services/reset-query-params.guard';
import { NoBuildingComponent } from './components/no-building/no-building.component';
import { FeatureGuard, FeatureGuardMode } from './services/appstate/feature-guard.guard';
import { BuildingFeature } from './models/building';
import { AccessPageComponent } from './components/access-page/access-page.component';
import { NotificationCenterComponent } from './components/console/notification-center/notification-center.component';

export const ROUTE_TREE = Object.freeze({
  ROOT: '',
  AUTH: {
    ROOT: 'auth',
    DOOR_SIGN_IN: 'door-sign-in',
    DOOR_SIGN_UP: 'door-sign-up',
    RESET_PASSWORD: 'door-reset-password'
  },
});

const routes: Routes = [
  { path: '', redirectTo: 'console', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LatchNonAuthGuardService] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: ROUTE_TREE.AUTH.ROOT,
    loadChildren: () => import('./components/auth/auth.module').then(m => m.DoorAuthModule)
  },
  {
    path: '',
    children: [
      {
        path: 'people',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/people/people.module').then(m => m.PeopleModule),
        data: { resetQueryParams: true },
        canDeactivate: [ResetQuaryParamsCanDeactivate],
      },
      {
        path: 'keys',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/keys/keys.module').then(m => m.KeysModule),
      },
      {
        path: 'bookings',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'owners',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/account-owner/account-owner.module').then(m => m.AccountOwnerModule)
      },
      {
        path: 'admins',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/account-admin/account-admin.module').then(m => m.AccountAdminModule)
      },
      {
        path: 'new-property',
        component: LatchRoutePopupComponent,
        loadChildren: () => import('./modules/new-property/new-property.module').then(m => m.NewPropertyModule)
      },
      {
        path: 'featured-entries',
        component: LatchRoutePopupComponent,
        children: [
          {
            path: 'add',
            component: AddFeaturedEntryComponent,
            data: {
              dialogConfig: {
                width: '810px',
                maxWidth: '100vw',
              } as LatchDialogConfig,
            }
          },
          {
            path: 'reorder',
            component: ReorderFeaturedEntriesComponent,
            data: {
              dialogConfig: {
                width: '810px',
                maxWidth: '100vw',
              } as LatchDialogConfig,
            }
          },
          {
            path: ':featuredEntryId',
            component: ViewFeaturedEntryComponent,
            data: {
              dialogConfig: {
                width: '810px',
                maxWidth: '100vw',
              } as LatchDialogConfig,
            }
          },
        ]
      },
      {
        path: 'notifications',
        component: LatchRoutePopupComponent,
        children: [
          {
            path: '',
            component: NotificationCenterComponent,
            data: {
              dialogConfig: {
                width: '810px',
                maxWidth: '100vw',
                height: '90vh',
              } as LatchDialogConfig,
            }
          }
        ]
      }
    ],
    outlet: 'popup',
  },
  {
    path: 'console',
    component: ConsoleComponent,
    canActivate: [LatchAuthGuardService, LoadAccountGuard],
    children: [
      { path: '', component: InitializeComponent, pathMatch: 'full' },
      {
        path: 'people',
        loadChildren: () => import('./modules/people/people.module').then(m => m.PeopleModule),
        data: { resetQueryParams: true },
        canDeactivate: [ResetQuaryParamsCanDeactivate],
      },
      { path: 'duplicates', component: DuplicatesComponent },
      { path: 'duplicate-emails', component: DuplicateEmailsComponent },
      {
        path: 'doors',
        loadChildren: () => import('./modules/doors/doors.module').then(m => m.DoorsModule),
      },
      // these two routes are used to stay backwards compatible since introducing Doors lazy-loaded module
      { path: 'intercom/:intercomId/add-management', redirectTo: 'doors/intercom/:intercomId/add-management' },
      { path: 'intercom/:intercomId/view-management/:managerId', redirectTo: 'doors/intercom/:intercomId/view-management/:managerId' },
      {
        path: 'keys',
        loadChildren: () => import('./modules/keys/keys.module').then(m => m.KeysModule),
      },
      {
        path: 'leases',
        loadChildren: () => import('./modules/lease/lease.module').then(m => m.LeaseModule),
      },
      {
        path: 'payments',
        loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'concierge',
        loadChildren: () => import('./modules/concierge/concierge.module').then(m => m.ConciergeModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'checkin',
        loadChildren: () => import('./modules/checkin/checkin.module').then(m => m.CheckinModule)
      },
      {
        path: 'door-schedules',
        loadChildren: () => import('./modules/door-schedule/door-schedule.module').then(m => m.DoorScheduleModule)
      },
      {
        path: 'bookings',
        loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'integrations',
        loadChildren: () => import('./modules/integrations/integrations.module').then(m => m.IntegrationsModule)
      },
      {
        path: 'activity',
        component: ActivityListPageComponent,
        canActivate: [FeatureGuard],
        data: {
          requiredFeatures: [BuildingFeature.Activity],
          mode: FeatureGuardMode.AllRequired,
        },
        children: [
          { path: '', pathMatch: 'full', component: DoorsActivityListPageComponent },
          { path: 'device', component: DeviceActivityListPageComponent },
          {
            path: 'service-orders',
            loadChildren: () => import('./modules/service-order/service-order.module').then(m => m.ServiceOrderModule)
          },
          { path: 'delivery', component: DeliveryActivityListPageComponent },
          { path: 'packages', component: PackagesActivityListPageComponent },
          {
            path: 'bookings',
            loadChildren: () => import('./modules/bookings/bookings.module').then(m => m.BookingsModule)
          },
        ]
      },
      { path: 'account', component: ViewAccountPageComponent },
      { path: 'account/mfa', component: MfaFlowComponent },
      { path: 'account/active-sessions', component: ActiveSessionsPageComponent },
      { path: 'account/recent-logins', component: RecentLoginsPageComponent },
      // Legacy admins and owners paths redirects
      { path: 'admins', redirectTo: 'staff/admins' },
      { path: 'admins/:userUUID', redirectTo: 'staff/admins/:userUUID' },
      { path: 'owners', redirectTo: 'staff/owners' },
      { path: 'owners/:userUUID', redirectTo: 'staff/owners/:userUUID' },
      {
        path: 'access',
        component: AccessPageComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'units' },
          {
            path: 'doors',
            loadChildren: () => import('./modules/doors/doors.module').then(m => m.DoorsModule),
          },
          {
            path: 'devices',
            loadChildren: () => import('./modules/doors/doors.module').then(m => m.DoorsModule),
          },
          {
            path: 'keys',
            loadChildren: () => import('./modules/keys/keys.module').then(m => m.KeysModule),
          },
          {
            path: 'units',
            children: [
              { path: '', component: UnitsListComponent },
              { path: 'add', component: CreateUnitPageComponent },
              { path: 'reorder', component: UnitsReorderPageComponent },
              { path: ':unitUUID', component: UnitDetailPageComponent },
            ]
          },
        ]
      },
      {
        path: 'staff',
        component: StaffPageComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'owners' },
          {
            path: 'owners',
            loadChildren: () => import('./modules/account-owner/account-owner.module').then(m => m.AccountOwnerModule)
          },
          {
            path: 'admins',
            loadChildren: () => import('./modules/account-admin/account-admin.module').then(m => m.AccountAdminModule)
          },
          {
            path: 'maintenance-admins',
            loadChildren: () => import('./modules/maintenance-admin/maintenance-admin.module').then(m => m.MaintenanceAdminModule)
          }
        ]
      },
      { path: 'support', component: SupportPageComponent },
      {
        path: 'units',
        children: [
          { path: '', component: UnitsListComponent },
          { path: 'add', component: CreateUnitPageComponent },
          { path: 'reorder', component: UnitsReorderPageComponent },
          { path: ':unitUUID', component: UnitDetailPageComponent },
        ]
      },
      {
        path: 'spaces',
        loadChildren: () => import('./modules/spaces/spaces.module').then(m => m.SpacesModule)
      },
      { path: 'devices/:deviceUUID', component: DeviceDetailPageComponent },
      { path: 'intercom-settings', component: IntercomSettingsComponent },
      { path: 'access-review', component: PmsAccessReviewPageComponent },
      { path: 'resources', component: ResourcesPageComponent },
      { path: 'no-building', component: NoBuildingComponent },
      {
        path: 'bundles',
        loadChildren: () => import('./modules/bundles/bundles.module').then(m => m.BundlesModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'building-health',
        loadChildren: () => import('./modules/building-health/building-health.module').then(m => m.BuildingHealthModule)
      },
      { path: '403', component: ErrorPageComponent },
    ]
  },
  { path: '403', component: ErrorPageComponent },
  { path: '404', component: ErrorPageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
