export const enum OperationErrorCode {
  UNKNOWN = 'UNKNOWN',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  SEE_OTHER = 'SEE_OTHER',
  UNPROCESSABLE_MESSAGE = 'UNPROCESSABLE_MESSAGE',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
}

export enum OperationType {
  UPDATE_KEY = 'UPDATE_KEY',
  CREATE_KEY = 'CREATE_KEY',
  DELETE_KEY = 'DELETE_KEY',
  CREATE_KEY_DOOR = 'CREATE_KEY_DOOR',
  UPDATE_KEY_DOOR = 'UPDATE_KEY_DOOR',
  DELETE_KEY_DOOR = 'DELETE_KEY_DOOR',
  CREATE_KEY_MEMBERSHIP = 'CREATE_KEY_MEMBERSHIP',
  UPDATE_KEY_MEMBERSHIP = 'UPDATE_KEY_MEMBERSHIP',
  REMOVE_KEY_MEMBERSHIP = 'REMOVE_KEY_MEMBERSHIP',
  UPDATE_UNIT = 'UPDATE_UNIT'
}

/* union of OperationTypes performed on keys for more succint comparisons */
export const isKeyOperationType = (ot: OperationType): boolean => ot === OperationType.CREATE_KEY ||
  ot === OperationType.DELETE_KEY ||
  ot === OperationType.UPDATE_KEY ||
  ot === OperationType.CREATE_KEY_DOOR ||
  ot === OperationType.UPDATE_KEY_DOOR ||
  ot === OperationType.DELETE_KEY_DOOR;

export const isKeyMembershipOperationType = (ot: OperationType): boolean => ot === OperationType.UPDATE_KEY_MEMBERSHIP ||
  ot === OperationType.REMOVE_KEY_MEMBERSHIP ||
  ot === OperationType.CREATE_KEY_MEMBERSHIP;

export enum OperationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE_UNACKNOWLEDGED = 'FAILURE_UNACKNOWLEDGED',
  FAILURE_ACKNOWLEDGED = 'FAILURE_ACKNOWLEDGED'
}

export enum OperationErrorMessage {
  DUPLICATE_LOCK_FOR_KEYS = 'DUPLICATE_LOCK_FOR_KEYS',
  FLOOR_GROUP_NOT_FOUND = 'FLOOR_GROUP_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_ACCESS_END_TIME = 'INVALID_ACCESS_END_TIME',
  INVALID_ACCESS_START_TIME = 'INVALID_ACCESS_START_TIME',
  INVALID_KEY_NAME = 'INVALID_KEY_NAME',
  KEY_DOES_NOT_EXIST = 'KEY_DOES_NOT_EXIST',
  KEY_EXPIRED = 'KEY_EXPIRED',
  KEY_HAS_EXISTING_MEMBERSHIPS = 'KEY_HAS_EXISTING_MEMBERSHIPS',
  KEY_MEMBERSHIP_ALREADY_EXISTS = 'KEY_MEMBERSHIP_ALREADY_EXISTS',
  KEY_MEMBERSHIP_DOES_NOT_EXIST = 'KEY_MEMBERSHIP_DOES_NOT_EXIST',
  LOCK_DOES_NOT_EXIST = 'LOCK_DOES_NOT_EXIST',
  LOCK_NOT_ASSIGNED_DEVICE = 'LOCK_NOT_ASSIGNED_DEVICE',
  MAX_ACCESSES = 'MAX_ACCESSES',
  MAX_DOORS_PER_KEY = 'MAX_DOORS_PER_KEY',
  MISSING_DEFAULT_FLOOR_GROUP = 'MISSING_DEFAULT_FLOOR_GROUP',
  NO_DOORCODE_FLOOR_GROUP = 'NO_DOORCODE_FLOOR_GROUP',
  OVERLAPPING_FLOOR_GROUPS = 'OVERLAPPING_FLOOR_GROUPS',
  OVERLAPPING_KEY_DOOR_CREDENTIALS = 'OVERLAPPING_KEY_DOOR_CREDENTIALS',
  OVERLAPPING_SCHEDULES = 'OVERLAPPING_SCHEDULES',
  OVERLAPPING_DOORCODE_SETTINGS = 'OVERLAPPING_DOORCODE_SETTINGS',
}

export interface Operation<T> {
  operationId: string;
  accountId: string;
  buildingId: string;
  operationType: OperationType;
  status: OperationStatus;
  error?: OperationErrorResult;
  result?: T;
}

export interface OperationErrorResult {
  code: OperationErrorCode;
  error: OperationErrorMessage;
  parameters: {
    keyId?: string,
    keyName?: string,
    keyMembershipId?: string,
    keyDoorId?: string,
    lockId?: string,
    userId?: string,
    floorGroupId?: string
  };
}

export const enum ActiveOperationsStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  SOME_FAILED = 'SOME_FAILED',
  NO_ACTIVE_OPERATIONS = 'NO_ACTIVE_OPERATIONS'
}

/**
 * When a backend request results in some number of operations, it returns
 * an OperationResultResponse object that identifies the operations that
 * were created as a result of that request.
 */
export interface OperationResultResponse {
  operationIds: string[];
}
