import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { pluralize } from '../../../services/utility/utility';
import { SelectedKey } from './selected-keys.component';
import { Lock } from '../../../models/lock';
import { assertIsDefined } from '@latch/latch-web';
import { KeyMembershipRole } from 'manager/services/key-membership/key-membership.service';

export enum FloorGroupState {
  DEFAULT = '(Default)',
  OTHER = ''
}

export interface ElevatorKeyDoorInfo {
  keyUUID: string;
  lockUUID: string;
  keyDoorName: string;
  floorGroupUUID: string;
  floorGroupName: string;
  floorGroupState: FloorGroupState;
}

/**
 * A lock, identified by UUID, that should not trigger floor group select events, along with a
 * human-readable message explaining why it is not selectable.
 */
export interface DisabledLock {
  lockUUID: string;
  tooltipText: string;
}

@Component({
  selector: 'latch-selected-elevator-key',
  templateUrl: './selected-elevator-key.component.html',
  styleUrls: ['./selected-elevator-key.component.scss']
})
export class SelectedElevatorKeyComponent implements OnInit {

  pluralize = pluralize;

  isTemporary = false;

  @Input() selectedKey!: SelectedKey;
  @Input() countSelectedKeyIntercoms: number | undefined;
  @Input() locks?: Lock[];
  /**
   * The locks which should not allow floor group selection.
   *
   * Note that these are not the only locks that will disable floor group selection - any key marked readOnly
   * will also have floor group selection events disabled for all its locks.
   * Only disabledLocks, however, will display a tooltip explaining why they are disabled.
   */
  @Input() disabledLocks: DisabledLock[] = [];

  @Output() remove = new EventEmitter<void>();
  @Output() toggleShareable = new EventEmitter<boolean>();
  @Output() toggleRole = new EventEmitter<KeyMembershipRole>();
  @Output() floorGroupSelect = new EventEmitter<ElevatorKeyDoorInfo>();

  elevators: ElevatorKeyDoorInfo[] = [];

  KeyMembershipRole = KeyMembershipRole;

  ngOnInit(): void {

    this.elevators = this.selectedKey.key.doors
      .reduce((acc: ElevatorKeyDoorInfo[], door) => {
        const keyDoorLock = this.locks?.find(lock => lock.uuid === door.lockUUID);
        if (!keyDoorLock) {
          return acc;
        }

        const selectedFloorGroup = this.selectedKey.floorGroups?.find(item => item.keyDoorUUID === door.uuid);
        assertIsDefined(selectedFloorGroup);
        const floorGroups = keyDoorLock.elevator?.floorGroups ? [...keyDoorLock.elevator.floorGroups] : [];
        if (keyDoorLock.elevator?.doorcodeFloorGroup) {
          floorGroups.push(keyDoorLock.elevator.doorcodeFloorGroup);
        }
        const floorGroup = floorGroups.find(item => item.uuid === selectedFloorGroup.floorGroupUUID);
        assertIsDefined(floorGroup);
        const floorGroupState = selectedFloorGroup.floorGroupUUID === door.defaultFloorGroup ?
          FloorGroupState.DEFAULT : FloorGroupState.OTHER;

        acc.push({
          keyUUID: this.selectedKey.key.uuid,
          lockUUID: keyDoorLock.uuid,
          keyDoorName: keyDoorLock.name,
          floorGroupUUID: floorGroup.uuid,
          floorGroupName: floorGroup.name,
          floorGroupState
        });
        return acc;
      }, [])
      .sort((e1, e2) => e1.keyDoorName.localeCompare(e2.keyDoorName));
  }

  handleRemove() {
    this.remove.emit();
  }

  handleToggleGuests() {
    this.toggleShareable.emit(!this.selectedKey.shareable);
  }

  handleToggleRole() {
    this.toggleRole.emit(this.selectedKey.role === KeyMembershipRole.RESIDENT ? KeyMembershipRole.GUEST : KeyMembershipRole.RESIDENT);
  }

  handleFloorGroupSelect(elevatorKeyDoorInfo: ElevatorKeyDoorInfo) {
    this.floorGroupSelect.emit(elevatorKeyDoorInfo);
  }

  isDisabled(lockUUID: string): boolean {
    return !!this.disabledLocks.find(lock => lock.lockUUID === lockUUID);
  }

  disabledTooltip(lockUUID: string): string {
    const disabledLock = this.disabledLocks.find(lock => lock.lockUUID === lockUUID);
    return disabledLock ? disabledLock.tooltipText : '';
  }
}
