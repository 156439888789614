import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DwollaCustomer } from 'manager/models/dwolla';
import { BankAccount, BankAccountStatus, DwollaCustomerBuilding, DwollaCustomerBuildingAssociations } from 'manager/models/payment';
import { OwnerType, PaymentAccount } from 'manager/modules/payment/model/payments';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CreateBankAccountInput, PaymentService } from './payment.service';

@Injectable()
export class HTTPPaymentService extends PaymentService {
  constructor(
    private authService: AuthService
  ) {
    super();
  }

  public getDwollaToken(data: Record<string, unknown>): Observable<{ token: string }> {
    return this.authService
    .request({
      method: 'post',
      endpoint: '/web/v1/dwollaTokens',
      data,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((token) => ({ token })),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getDwollaCustomer(dwollaCustomerUUID: string): Observable<DwollaCustomer> {
    return this.authService
    .request({
      method: 'get',
      endpoint: `/web/v2/dwollaCustomers/${dwollaCustomerUUID}`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getDwollaCustomers(accountUUID: string): Observable<DwollaCustomer[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/dwollaCustomers`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public createDwollaCustomer(accountUUID: string, dwollaCustomerId: string): Observable<DwollaCustomer> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/accounts/${accountUUID}/dwollaCustomers`,
        data: { dwollaCustomerId }
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public createPlaidToken(accountUUID: string): Observable<string> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/accounts/${accountUUID}/linkTokens`,
        data: { plaidRedirectUri: location.origin + '/console/payments/oauth/plaid/callback' }
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public createPlaidTokenForVerification(bankAccountUUID: string): Observable<string> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/bankAccounts/${bankAccountUUID}/linkTokens`,
        data: {}
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public createBankAccount(input: CreateBankAccountInput): Observable<BankAccount> {
    return this.authService
      .request({
        method: 'post',
        endpoint: '/web/v1/bankAccounts',
        data: input
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public verifyBankAccount(bankAccountUUID: string, bankAccountStatus: BankAccountStatus): Observable<BankAccount> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/bankAccounts/${bankAccountUUID}/verify`,
        data: { status : bankAccountStatus }
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public getBankAccounts(buildingUUID: string): Observable<BankAccount[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/bankAccounts`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public getDwollaCustomerBuildingsForPortfolio(accountUUID: string): Observable<DwollaCustomerBuildingAssociations> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/accounts/${accountUUID}/dwollaCustomerBuildings`
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }
  public updateDwollaCustomerBuildingMapping(accountUUID: string, dwollaCustomerUUID: string, associatedBuildingIds: Set<string>):
    Observable<DwollaCustomerBuilding> {
    return this.authService
      .request({
        method: 'put',
        endpoint: `/web/v1/accounts/${accountUUID}/dwollaCustomers/${dwollaCustomerUUID}/buildings`,
        data: { associatedBuildingIds: Array.from(associatedBuildingIds) }
      }).pipe(
        map((response) => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error))
      );
  }

  public getPaymentAccounts(accountUUID: string): Observable<PaymentAccount[]> {
    const ownerType = OwnerType.Portfolio;
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v2/payments/${ownerType}/${accountUUID}/accounts`
    }).pipe(
      map((response) => AuthService.getPayload(response) as PaymentAccount[]),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public createPaymentAccount(accountUUID: string): Observable<PaymentAccount> {
    const ownerType = OwnerType.Portfolio;
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v2/payments/${ownerType}/${accountUUID}/accounts`,
      data: {
        returnUrl: window.location.href,
        refreshUrl: window.location.href,
      }
    }).pipe(
      map((response) => AuthService.getPayload(response) as PaymentAccount),
      catchError((error: Error) => this.handleError(error))
    );
  }

  public getPaymentAccountLink(accountId: string): Observable<string> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v2/payments/accounts/${accountId}/dashboard`,
      data: {
        returnUrl: window.location.href,
        refreshUrl: window.location.href,
      }
    }).pipe(
      map((response) => AuthService.getPayload(response).url as string),
      catchError((error: Error) => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error) as string;
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }
}
