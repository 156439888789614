import { Injectable } from '@angular/core';
import {
  GetDashboardInsightForGroupInput, GetInsightsInput, GetInsightsNotificationInput, InsightResponse,
  InsightsDashboardDefinitionResponse, InsightsDashboardGroupResponse, InsightsNotificationMap
} from 'manager/models/insights';
import { Observable } from 'rxjs';

@Injectable()
export abstract class InsightsService {
  public abstract getInsights(input: GetInsightsInput): Observable<InsightResponse>;
  public abstract getInsightDashboardDefinition(accountUUID: string): Observable<InsightsDashboardDefinitionResponse>;
  public abstract getDashboardInsightForGroup(input: GetDashboardInsightForGroupInput): Observable<InsightsDashboardGroupResponse>;
  public abstract getInsightsNotifications(input: GetInsightsNotificationInput): Observable<InsightsNotificationMap>;
}
