import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { assertIsDefined } from '@latch/latch-web';
import { Person } from 'manager/models/contact-cards';
import { EMPTY, merge, Observable } from 'rxjs';
import { filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { Key } from '../../../models/key';
import { Lock } from '../../../models/lock';
import { Operation, OperationErrorMessage, OperationType } from '../../../models/operations';
import { FailureDetailsService } from './failure-details.service';

@Component({
  selector: 'latch-failure-details',
  templateUrl: './failure-details.component.html',
  styleUrls: ['./failure-details.component.scss']
})
export class FailureDetailsComponent implements OnInit {
  @Input() failure!: Operation<unknown>;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public load = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<Event>();

  isLoading = false;

  ERRORS = OperationErrorMessage;
  OPERATION_TYPES = OperationType;

  errorMessage: OperationErrorMessage | undefined;
  public person!: Person;
  lock!: Lock;
  key!: Key;
  queryParams!: Params;

  canLinkToPersonDetail = true;

  public get failureKeyName(): string {
    return this.failure.error?.parameters.keyName ?? '';
  }

  /**
   * FailureDetailsService is meant for wrapping api services for the FailureDetailsComponent and caching data for
   * the failure items so that we don't send the duplicated api requests if we have multiple similar failures.
   * We invalidate the cache when the selected building changes.
   */
  private failureDetailsService = inject(FailureDetailsService);

  ngOnInit() {
    const error = this.failure.error?.error;
    const buildingId = this.failure.buildingId;
    this.errorMessage = error;
    this.queryParams = {
      building: buildingId
    };

    this.getAdditionalData(buildingId);
  }

  triggerClose(event: Event): void {
    this.close.emit(event);
  }

  private getAdditionalData(buildingId: string) {
    const parameters = this.failure.error?.parameters;
    assertIsDefined(parameters);
    const observables: Observable<Person | Lock | Key>[] = [];

    if (parameters.userId) {
      observables.push(this.getPersonInfo(parameters.userId, buildingId));
    }

    if (parameters.lockId) {
      observables.push(this.getLockInfo(parameters.lockId, buildingId));
    }

    if (parameters.keyId && this.isKeyInfoNeeded()) {
      const keyDoorId = parameters.lockId ? undefined : parameters.keyDoorId;
      observables.push(this.getKeyInfo(parameters.keyId, buildingId, keyDoorId));
    }

    if (!observables.length) {
      this.load.emit();
      return;
    }

    this.isLoading = true;
    merge(...observables).pipe(toArray()).subscribe({
      next: () => {
        this.isLoading = false;
        this.load.emit();
      },
      error: () => {
        this.isLoading = false;
        this.errorMessage = undefined;
        this.load.emit();
      }
    });
  }

  private getLockInfo(lockUUID: string, buildingUUID: string) {
    return this.failureDetailsService.getLockInfo(lockUUID, buildingUUID).pipe(
      tap((lock) => this.lock = lock),
    );
  }

  private getKeyInfo(keyUUID: string, buildingUUID: string, keyDoorId?: string) {
    return this.failureDetailsService.getKeyInfo(keyUUID, buildingUUID).pipe(
      tap((key) => this.key = key),
      filter(() => !!keyDoorId),
      map((key) => key.doors.find(door => door.uuid === keyDoorId)),
      mergeMap((keyDoor) => keyDoor ? this.getLockInfo(keyDoor.lockUUID, buildingUUID) : EMPTY)
    );
  }

  private getPersonInfo(userUUID: string, buildingUUID: string): Observable<Person> {
    return this.failureDetailsService.getPersonInfo(userUUID, buildingUUID).pipe(
      tap((person) => {
        this.person = person;
        // if the user's access is not expired, and there is a chance we may link to the user details,
        // we must check to ensure that we would have permission to navigate to the person detail page. See: LMC-1193
        // this.canLinkToPersonDetail = user.accessState !== AccessState.EXPIRED;
      }));
  }

  private isKeyInfoNeeded(): boolean {
    const exceptionCases = [
      OperationErrorMessage.KEY_DOES_NOT_EXIST,
      OperationErrorMessage.INVALID_KEY_NAME
    ];

    return !(this.errorMessage && exceptionCases.includes(this.errorMessage));
  }
}
