interface UnconflictedKey {
  keyUUID: string;
  keyName: string;
}

export interface ConflictedKey extends UnconflictedKey {
  pendingReviewAccessId: string;
}

export interface PmsPendingAccessChange {
  userUUID: string;
  firstName: string;
  lastName: string;
  unitName?: string,
  leaseStatus?: LeaseStatus,
  keysAdded: ConflictedKey[];
  keysRemoved: ConflictedKey[];
  keysModified: ConflictedKey[];
  keysUnchanged: UnconflictedKey[];
}

export interface PmsAccessReviewData {
  accessChanges: PmsPendingAccessChange[],
  isManagerConfirmed: boolean,
  changesRetrievedTime: number,
}

export enum LeaseStatus {
  Current = 'CURRENT',
  Pending = 'PENDING',
  Former = 'FORMER',
}

export const LeaseStatusDisplay: { [key in LeaseStatus]: string } = {
  [LeaseStatus.Current]: 'Current',
  [LeaseStatus.Pending]: 'Pending',
  [LeaseStatus.Former]: 'Former',
};
