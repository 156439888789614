import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap, toArray } from 'rxjs/operators';
import { Lock, LockShortInfo } from '../../models/lock';
import { CreateLockInput, LockService, UpdateLockInput, UpdateLockOutput } from './lock.service';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { AuthService } from '../auth/auth.service';
import { ServiceResponse } from '../interfaces';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';
import { ChangeNotificationService } from '../appstate/change-notification.service';

@Injectable()
export class HTTPLockService extends LockService {
  private cachedLocksByBuildingId = new Map<string, Observable<Lock[]>>();

  private get selectedBuildingId() {
    return this.selectedBuildingsService.selectedBuildings[0].uuid;
  }

  constructor(
    private selectedAccountService: SelectedAccountService,
    private selectedBuildingsService: SelectedBuildingsService,
    private changeNotificationService: ChangeNotificationService,
    private authService: AuthService
  ) {
    super();
    this.subscribeToChanges();
  }

  getAllLocksShortInfo(buildingUUID: string): Observable<LockShortInfo[]> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${ accountUUID }/buildings/${ buildingUUID }/doors`
    }).pipe(
      map((response: HttpResponse<any>): LockShortInfo[] => {
        const jsonObjects = AuthService.getPayload(response);
        return jsonObjects.map((jsonObject: any) => LockShortInfo.fromJSON(jsonObject));
      })
    );
  }

  getAllLocks(buildingUUIDs: string[]): Observable<Lock[]> {
    return from(buildingUUIDs).pipe(
      mergeMap(buildingId => this.getBuildingLocks(buildingId)),
      toArray(),
      map((lockLists: Lock[][]) => lockLists.reduce((acc, curr) => acc.concat(curr), [])),
    );
  }

  getLockDetails(lockUUID: string, buildingUUID = this.selectedBuildingId): Observable<Lock> {
    const allLocks$ = this.getAllLocks([buildingUUID]);

    return allLocks$.pipe(
      switchMap((locks) => {
        const matchingLock = locks.find(lock => lock.uuid === lockUUID);
        return matchingLock ? of(matchingLock) : this.handleLockNotFoundError();
      })
    );
  }

  /**
   * takes a list of strings of lockUUIDs and returns a single Observable.
   * @param lockUUIDs
   * @param buildingId
   * @returns
   * {@link SyncStatusService.pollLockOperationSyncStatus}
   */
  getLocksBulk(lockUUIDs: string[], buildingId = this.selectedBuildingId): Observable<Lock[]> {
    if (lockUUIDs.length === 0) {
      return of([]);
    }

    return this.getAllLocks([buildingId]).pipe(
      map((locks) => locks.filter((lock) => lockUUIDs.includes(lock.uuid)))
    );
  }

  createLock(input: CreateLockInput): Observable<Lock> {
    this.changeNotificationService.publishLockChange();
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const data = {
      buildingUUID: input.buildingUUID,
      name: input.name,
      accessibility: input.accessibility,
      hasIntercom: input.hasIntercom,
      hasVirtualIntercom: input.hasVirtualIntercom,
      virtualIntercomCode: input.virtualIntercomCode,
    };
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/accounts/${ accountUUID }/locks`,
      data,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((json): Lock => Lock.fromJSON(json)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  updateLock(input: UpdateLockInput): Observable<UpdateLockOutput> {
    this.changeNotificationService.publishLockChange();
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const data = {
      name: input.name,
      accessibility: input.accessibility,
      hasIntercom: input.hasIntercom,
      hasVirtualIntercom: input.hasVirtualIntercom,
      virtualIntercomCode: input.virtualIntercomCode,
      scheduleUUID: input.scheduleUUID,
      partnerUUID: input.partnerUUID,
      partnerUUIDs: input.partnerUUIDs,
    };
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v3/accounts/${ accountUUID }/locks/${ input.uuid }`,
      data,
    }).pipe(
      map((response): UpdateLockOutput => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  deleteLock(lockUUID: string): Observable<ServiceResponse> {
    this.changeNotificationService.publishLockChange();
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/accounts/${accountUUID}/locks/${lockUUID}`
    }).pipe(map((): ServiceResponse => ({
      success: true
    })));
  }

  exportAccessLogs(): Observable<string> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${accountUUID}/exportAccessLogs?buildingUUIDs=${this.selectedBuildingId}`
    }).pipe(
      map((response: HttpResponse<any>): string => AuthService.getPayload(response)?.accessLogs)
    );
  }

  private getBuildingLocks(buildingUUID: string): Observable<Lock[]> {
    const locks = this.cachedLocksByBuildingId.get(buildingUUID);
    if (locks) {
      return locks;
    }

    const accountUUID = this.selectedAccountService.selectedAccount.uuid;

    const observable$ = this.authService.request({
      method: 'get',
      endpoint: `/web/v1/accounts/${ accountUUID }/locks`,
      search: new HttpParams({
        fromObject: {
          buildingUUID
        }
      })
    }).pipe(
      map((response: HttpResponse<any>): Lock[] => {
        const jsonObjects = AuthService.getPayload(response);
        return jsonObjects.map((jsonObject: any) => Lock.fromJSON(jsonObject));
      }),
      catchError((error) => {
        if ((error instanceof HttpErrorResponse) && error.status === 403) {
          // In the case where a user does not have permission to see any keys and requests the doors list, the backend returns
          // 403 USER_FORBIDDEN. We really just want this to behave as if the user could not see any locks, so we pretend
          // that's what the backend gave us. We believe this can only be the case when a "specific keys" property manager has
          // 0 keys (which we intend to not allow, but which can happen when keys are deleted).
          return of([]);
        } else {
          throw error;
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.cachedLocksByBuildingId.set(buildingUUID, observable$);
    return observable$;
  }

  private handleError(error: Error) {
    if ((error instanceof HttpErrorResponse) && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }

  private clearCache(): void {
    this.cachedLocksByBuildingId.clear();
  }

  private handleLockNotFoundError() {
    return throwError(new Error('LOCK_DOES_NOT_EXIST'));
  }

  private subscribeToChanges(): void {
    this.changeNotificationService.lockChange().subscribe(() => this.clearCache());
    this.changeNotificationService.authStateChange().subscribe(() => this.clearCache());
  }
}
