import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';


import { deserializeKey, Key, KeyListView } from '../../models/key';
import { CreateKeyInput, KeyService, UpdateKeyDoorsInput, UpdateKeyInput } from './key.service';
import { AuthService } from '../auth/auth.service';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { SelectedBuildingsService } from '../appstate/selected-buildings.service';

import { convertListToMap as convertLargeList } from '../utility/http-large-list';
import { ChangeNotificationService } from '../appstate/change-notification.service';
import { OperationResultResponse } from '../../models/operations';

@Injectable()
export class HTTPKeyService extends KeyService {
  /**
   * Caching
   *
   * We cache the results of getKeys, and we convert all calls to getKey into a call to getKeys.
   *
   * We invalidate the cache any time the user creates, modifies, or deletes a key.
   *
   * The key response also includes a userCount that would be changed by creating / deleting key
   * memberships - however, that count is already treated as an estimate, so we don't rely on it
   * being accurate and are okay with it being cached and outdated.
   *
   * We cache keys for each building for which user requested to get all keys.
   *
   * Other users on different machines could also be making changes, and we would not see them here.
   * We think that is a rare use case (multiple MW users in the same property at the same time) and
   * believe it's worth the benefit we can get from caching. A user can just refresh the page to get
   * fresh data.
   */

  private cachedKeysByBuildingId = new Map<string, Observable<Key[]>>();
  private cachedKeysListByBuildingId = new Map<string, Observable<KeyListView[]>>();

  private get selectedBuildingId() {
    return this.selectedBuildingsService.selectedBuildings[0].uuid;
  }

  constructor(
    private authService: AuthService,
    private selectedAccountService: SelectedAccountService,
    private selectedBuildingsService: SelectedBuildingsService,
    private changeNotificationService: ChangeNotificationService
  ) {
    super();
    this.subscribeToChanges();
  }

  getKeys(buildingUUID: string): Observable<Key[]> {
    const cachedKeys = this.cachedKeysByBuildingId.get(buildingUUID);
    if (cachedKeys) {
      return cachedKeys;
    }

    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const endpoint = `/web/v1/accounts/${accountUUID}/buildings/${buildingUUID}/keys`;

    const observable$: Observable<Key[]> = this.authService.request({
      method: 'get',
      endpoint
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((keys: any[]) => keys.map(key => deserializeKey(key))),
      // keys[i].doors[j].defaultFloorGroup property is uuid, but it won't get picked up
      // by transformIdsToLowercase, so we need to manually make it lowercase
      map((keys: Key[]) => keys.map(key => ({
        ...key,
        doors: key.doors.map((keyDoor) => ({
          ...keyDoor,
          defaultFloorGroup: keyDoor.defaultFloorGroup ?
            keyDoor.defaultFloorGroup.toLowerCase() : keyDoor.defaultFloorGroup
        })),
      }))),
      catchError((error: Error) => this.handleError(error)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.cachedKeysByBuildingId.set(buildingUUID, observable$);

    return observable$;
  }

  getKey(keyUUID: string, buildingUUID = this.selectedBuildingId): Observable<Key> {
    const allKeys = this.getKeys(buildingUUID);
    // Testing has shown that getting a single key is rarely much faster than getting ALL keys in a building.
    // We convert all calls to get a single key into calls to get all keys because (1) it's no worse, and (2)
    // doing this allows us to implement a very simple & effective caching strategy by caching just that one
    // getKeys call.
    return allKeys.pipe(
      switchMap((keys) => {
        const matchingKey = keys.find(key => key.uuid === keyUUID);
        return matchingKey ? of(matchingKey) : this.handleKeyNotFoundError();
      })
    );
  }

  getKeysList(buildingId: string): Observable<KeyListView[]> {
    const cachedKeys = this.cachedKeysListByBuildingId.get(buildingId);
    if (cachedKeys) {
      return cachedKeys;
    }

    const observable$: Observable<KeyListView[]> = this.getKeysListData(buildingId);
    this.cachedKeysListByBuildingId.set(buildingId, observable$);

    return observable$;
  }

  private getKeysListData(buildingId: string): Observable<KeyListView[]> {
    const endpoint = `/web/v1/buildings/${buildingId}/keys`;
    return this.authService.request({
      method: 'get',
      endpoint
    }).pipe(
      map(response => AuthService.getPayload(response) as KeyListView[]),
      catchError((error: Error) => this.handleError(error)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  createKey(input: CreateKeyInput): Observable<OperationResultResponse> {
    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    const data = {
      name: input.name,
      accountUUID,
      // Backend ignores buildingUUID, but we pass it through for the sake of our test service.
      // Grouping keys by building helps our mock service implement its functionality more easily,
      // so our mock key service includes a building UUID with each key.
      buildingUUID: input.buildingUUID,
      doors: convertLargeList(input.doors),
      type: input.type,
      partnerUUID: input.partnerUUID,
    };
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v3/keys',
      data
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  updateKey(input: UpdateKeyInput): Observable<OperationResultResponse> {
    const data = {
      name: input.name
    };
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v3/keys/${input.uuid}`,
      data
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  deleteKey(keyUUID: string): Observable<OperationResultResponse> {
    this.changeNotificationService.publishKeyChange();

    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v2/keys/${keyUUID}`,
      data: {}
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  updateKeyDoors(input: UpdateKeyDoorsInput): Observable<OperationResultResponse> {
    this.changeNotificationService.publishKeyChange();

    const data = {
      toDelete: convertLargeList(input.toDelete),
      toUpdate: convertLargeList(input.toUpdate),
      toCreate: convertLargeList(input.toCreate)
    };
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v3/keys/${input.uuid}`,
      data
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(() => new Error(message));
    } else {
      return throwError(() => error);
    }
  }

  private handleKeyNotFoundError() {
    // KEY_DOES_NOT_EXIST is the same error message that would be returned by the backend, so this should look the
    // same to a client as if we had actually made the call and returned the backend response.
    return throwError(() => new Error('KEY_DOES_NOT_EXIST'));
  }

  private clearCache() {
    this.cachedKeysByBuildingId.clear();
    this.cachedKeysListByBuildingId.clear();
  }

  private subscribeToChanges(): void {
    this.changeNotificationService.keyChange().subscribe(() => this.clearCache());
    this.changeNotificationService.keyMembershipChange().subscribe(() => this.clearCache());
    this.changeNotificationService.authStateChange().subscribe(() => this.clearCache());
  }
}
