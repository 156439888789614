import {
  Component, ContentChild, EventEmitter, Input, OnChanges,
  OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';

import { Accessibility, Lock } from '../../../models/lock';

import { alphabetizeBy, groupBy, isPlural, pluralize } from '../../../services/utility/utility';
import { AccessibilityText } from '../../../services/utility/presentation';

import { FloorGroup } from '../../../models/floor-group';
import { FormControl } from '@angular/forms';
import {
  ELEVATOR_DOORS_GROUP,
  SelectableLock,
  SELECTED_DOORS_GROUP,
  SelectedKeyDoor
} from './selectable-lock-item/selectable-lock-item.component';
import { isDefined, LatchConfirmationDialogConfig, LatchDialogService, LatchNavAction, LatchNavbarStateService } from '@latch/latch-web';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Key } from 'manager/models/key';
import {
  FloorGroupListModalComponent,
  FloorGroupListModalData
} from '../floor-group-list/floor-group-list-modal/floor-group-list-modal.component';
import {
  FloorGroupListEditModalComponent,
  FloorGroupListEditModalData
} from '../floor-group-list/floor-group-list-edit-modal/floor-group-list-edit-modal.component';
import {
  DoorSettingsUpdated, getKeyDoorsToCreate, getKeyDoorsToDelete, getAllKeyDoorsSettingsUpdates,
  groupDoorsByAccessibility
} from 'manager/services/utility/keydoor-changes-utility';
import { KeyDetailPageTab } from '../../../models/tabs';
import { KeyDetailPageService } from '../../../services/appstate/key-detail-page.service';

// 'SELECTED' added manually for selected section ordering
const DOOR_GROUP_ORDER = [
  SELECTED_DOORS_GROUP,
  ELEVATOR_DOORS_GROUP,
  Accessibility.PUBLIC,
  Accessibility.COMMUNAL,
  Accessibility.PRIVATE,
  Accessibility.SERVICE
];
const doorGroupSort = (doorGroup: string) => DOOR_GROUP_ORDER.indexOf(doorGroup);

@Component({
  selector: 'latch-key-doors-list',
  templateUrl: './key-doors-list.component.html',
  styleUrls: ['./key-doors-list.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('open', style({
        height: '*'
      })),
      state('close', style({
        overflow: 'hidden',
        height: '0'
      })),
      transition('open <=> close', [
        style({
          overflow: 'hidden',
        }),
        animate('1s ease-in-out'),
      ])
    ])]
})
export class KeyDoorsListComponent implements OnInit, OnChanges, OnDestroy {
  /** card style layout vs new list style layout */
  @Input() cardLayout = false;
  // boolean to hide the edit button completely (e.g. in the person-member-page component)
  @Input() displayEditButton = true;
  @Input() isCreating = false;
  /**
   * Whether this list is being used to show key membership doors, rather than key doors. On elevators, this controls
   * whether we should be choosing the selected floor group or the default floor group.
   */
  @Input() isViewingKeyMembership = false;
  @Input() key: Key | undefined;
  @Input() initialSelected: SelectedKeyDoor[] = [];
  /**
   * Clients that allow editing (displayEditButton = true) should provide a membershipCount with the number of
   * memberships on this key. This allows this component to provide meaningful warning messages when making changes
   * about the effects of those changes.
   * Clients that don't allow editing need not provide membershipCount.
   */
  @Input() membershipCount = 0;
  /**
   * editing elevator floor groups on the key membership page is a special case where currentUserCanEdit must be set to true
   * all users may edit there because we are editing key membership, not the Key itself
   */
  @Input() currentUserCanEdit = false;
  @Input() isDeliveryKey = false;
  @Input() editMode = false;
  @Input() locks: Lock[] = [];
  @Input() hasDoorcodeSuppression = false;
  /**
   * Only show doors on the list without any scheduling details
   */
  @Input() onlyDoors = false;
  @Output() changeActions = new EventEmitter<LatchNavAction[]>();
  @Output() cancel = new EventEmitter<void>();
  @Output() done = new EventEmitter<SelectedKeyDoor[]>();

  @ContentChild('dialogPrompt') dialogPrompt!: TemplateRef<Element>;
  @ViewChild('confirmDialog') public confirmDialog!: TemplateRef<string[]>;

  initialSelectedById = new Map<string, SelectedKeyDoor>();
  selectableLocks: SelectableLock[] = [];

  filtered: SelectableLock[] = [];
  filteredGroups: string[] = [];
  filteredByGroup: { [doorGroup: string]: SelectableLock[]; } = {};
  groupCollapsed: { [doorGroup: string]: boolean; } = {};

  selected: SelectableLock[] = [];

  viewingDoorGroupForLock: SelectableLock | undefined;

  toCreate: { [doorGroup: string]: SelectedKeyDoor[]; } = {};
  toDelete: { [doorGroup: string]: SelectedKeyDoor[]; } = {};
  createdCount = 0;
  deletedCount = 0;
  updatedCount = 0;
  doorSettingsUpdated: DoorSettingsUpdated = {
    schedulesModified: 0,
    schedulesRemoved: 0,
    schedulesAdded: 0,
    doorcodesEnabled: 0,
    doorcodesDisabled: 0,
    elevatorDefaultGroups: 0
  };
  totalChanges = 0;
  messages: string[] = [];


  private unsubscribe$ = new Subject<void>();

  private searchSubscription: Subscription | undefined;

  public searchControl!: FormControl;

  /**
   * If every lock in the locks list is an elevator, tweaks are made for more user-friendly copy
   * (selected elevators instead of selected doors, etc).
   *
   * This returns true if every lock passed in by the client is an elevator. It's up to the client
   * to filter the provided locks list (this component does not itself filter to show only elevators).
   */
  onlyShowingElevators = false;

  isPlural = isPlural;
  pluralize = pluralize;

  get isEditing(): boolean {
    return this.isCreating || this.editMode;
  }

  get showBack(): boolean {
    return this.isCreating || !this.editMode;
  }

  get title(): string {
    if (this.isEditing) {
      return this.onlyShowingElevators ? 'Select elevators' : 'Select doors';
    } else {
      return this.onlyShowingElevators ? 'Elevators' : 'Doors';
    }
  }

  get showSubmit(): boolean {
    if (this.isCreating) {
      return this.selected.length > 0 && (this.onlyDoors || this.allElevatorsHaveFloorGroup());
    } else {
      // Todo: change-detection
      return this.isEditing && (this.onlyDoors || this.allElevatorsHaveFloorGroup());
    }
  }

  private allElevatorsHaveFloorGroup(): boolean {
    return this.selected.every(({ keyDoor, isElevator }) => {
      if (!isElevator) {
        return true;
      }

      return isDefined(keyDoor) &&
        !!(this.isViewingKeyMembership ? keyDoor.selectedFloorGroup : keyDoor.defaultFloorGroup);
    });
  }

  calculateChangeSummary() {
    this.totalChanges = 0;
    this.toCreate = {};
    this.toDelete = {};

    if (this.key) {
      const currentKeyDoors = this.getSelectedKeyDoors();
      const doorsCreated = getKeyDoorsToCreate(this.key.keyType, this.key.doors, currentKeyDoors);
      this.toCreate = groupDoorsByAccessibility(doorsCreated, this.locks);
      this.createdCount = doorsCreated.length;
      const doorsDeleted = getKeyDoorsToDelete(this.key.doors, currentKeyDoors);
      this.doorSettingsUpdated = getAllKeyDoorsSettingsUpdates(this.key.doors, currentKeyDoors);
      this.toDelete = groupDoorsByAccessibility(doorsDeleted, this.locks);
      this.deletedCount = doorsDeleted.length;
      this.updatedCount = this.doorSettingsUpdated.doorcodesDisabled + this.doorSettingsUpdated.doorcodesEnabled +
        this.doorSettingsUpdated.schedulesModified + this.doorSettingsUpdated.schedulesRemoved +
        this.doorSettingsUpdated.schedulesAdded + this.doorSettingsUpdated.elevatorDefaultGroups;
      this.totalChanges = this.deletedCount + this.createdCount + this.updatedCount;
    }
  }

  get submitText(): string {
    if (this.isCreating) {
      return `Select ${this.onlyShowingElevators ? 'elevators' : 'doors'} (${this.selected.length})`;
    } else {
      this.calculateChangeSummary();
      return `Save (${this.totalChanges} changes)`;
    }
  }

  constructor(
    private navbarStateService: LatchNavbarStateService,
    private dialog: LatchDialogService,
    private keyDetailPageService: KeyDetailPageService
  ) { }

  ngOnInit() {
    this.locks = alphabetizeBy(this.locks, 'name');
    this.initialSelected.forEach((keyDoor) => this.initialSelectedById.set(keyDoor.lockUUID, keyDoor));

    this.initSelectableLocks();

    this.onlyShowingElevators = this.locks.length > 0 && this.selectableLocks.every(({ isElevator }) => isElevator);

    this.initSearch();

    this.setUpNavbarActions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardLayout && !changes.cardLayout.firstChange) {
      this.initSearch();
    }
  }

  ngOnDestroy() {
    this.changeActions.next([]);
    this.unsubscribe$.next();
  }

  private initSearch(): void {
    // cleanup old search subscription
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = undefined;
    }

    if (this.cardLayout) {
      this.searchControl = new FormControl('');
    } else {
      this.navbarStateService.initializeSearch({ placeholder: 'Search Doors' });
      this.searchControl = this.navbarStateService.searchControl;
    }

    this.searchSubscription = this.searchControl.valueChanges.pipe(
      startWith(this.searchControl.value),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.filter());
  }

  getCurrentFloorGroupUUID(lock?: SelectableLock): string | undefined {
    const floorGroup = lock ? this.getCurrentFloorGroup(lock) : undefined;
    return floorGroup?.uuid;
  }

  getCurrentFloorGroup(lock: SelectableLock): FloorGroup | undefined {
    if (this.isViewingKeyMembership) {
      return lock.selectedFloorGroup;
    } else {
      return lock.defaultFloorGroup;
    }
  }

  getDoorGroupHeader(doorGroup: string): string {
    let groupName;
    const count = this.filteredByGroup[doorGroup].length;

    switch (doorGroup) {
      case SELECTED_DOORS_GROUP:
        groupName = this.onlyShowingElevators ? 'Selected Elevators' : 'Selected Doors';
        break;
      case ELEVATOR_DOORS_GROUP:
        groupName = 'Elevators';
        break;
      default:
        groupName = AccessibilityText[doorGroup as keyof typeof AccessibilityText];
    }

    return `${groupName} (${count})`;
  }

  isGroupSelected(doorGroup: string): boolean {
    const groupItems = this.filteredByGroup[doorGroup] || [];
    return groupItems.every((selectableLock) => selectableLock.selected);
  }

  isGroupCollapsed(doorGroup: string): boolean {
    return this.groupCollapsed[doorGroup];
  }

  handleToggleCollapse(doorGroup: string) {
    this.groupCollapsed[doorGroup] = !this.groupCollapsed[doorGroup];
  }

  handleToggleEditMode() {
    if (this.editMode) {
      this.initSelectableLocks();
    }

    this.editMode = !this.editMode;
    this.setUpNavbarActions();
    this.filter();
  }

  handleSelectedChange(selectableLock: SelectableLock): void {
    if (selectableLock.selected) {
      this.selected.push(selectableLock);
    } else {
      this.selected = this.selected.filter(lock => lock !== selectableLock);
    }

    this.setUpNavbarActions();
  }

  handleViewDoorGroupForLock(selectableLock?: SelectableLock) {
    this.viewingDoorGroupForLock = selectableLock;

    this.openFloorGroupListModal();
  }

  areAllDoorcodesDisabled(): boolean {
    return this.selected.reduce((prev: boolean, curr) => prev && !curr.keyDoor?.doorcodeEnabled, true);
  }

  toggleAllDoorcodes() {
    const newValue = this.areAllDoorcodesDisabled();
    this.selected.forEach(item => item.toggleDoorcodeEnabled(newValue));
    this.setUpNavbarActions();
  }

  handleToggleGroup(doorGroup: string, event?: Event) {
    event?.stopPropagation();
    const groupLocks = this.filteredByGroup[doorGroup];
    this.groupCollapsed[doorGroup] = false;

    const isGroupSelected = this.isGroupSelected(doorGroup);
    groupLocks.forEach((selectableLock) => {
      if (isGroupSelected) {
        selectableLock.unselect();
      } else {
        selectableLock.select();
      }
      this.handleSelectedChange(selectableLock);
    });
  }

  handleSave() {
    const doorsWithNoDoorcode = this.selected.filter((selectedDoor) => !selectedDoor.keyDoor?.doorcodeEnabled);
    const doorsWithNoDay = this.selected.filter((selectedDoor) => !selectedDoor.keyDoor?.days?.length);
    let dialogConfig!: LatchConfirmationDialogConfig;

    if (this.isCreating || this.isViewingKeyMembership) {
      this.done.emit(this.getSelectedKeyDoors());
    } else if (this.selected.length === 0) {
      dialogConfig = {
        data: {
          primaryButtonText: 'Okay',
          messages: this.getInvalidPromptMessages(doorsWithNoDay.length),
        }
      };
    } else {
      const deleteDoorCount = this.selectableLocks.filter((lock) => !lock.selected && lock.initialKeyDoor).length;
      this.messages = this.getPromptMessages(doorsWithNoDoorcode.length, deleteDoorCount);
      dialogConfig = {
        data: {
          title: 'Confirmation',
          primaryButtonText: 'Save',
          messages: [this.confirmDialog]
        },
        maxWidth: '500px'
      };
    }

    if (dialogConfig) {
      this.dialog.openConfirmation(dialogConfig).afterClosed().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(result => {
        if (result) {
          this.done.emit(this.getSelectedKeyDoors());
          this.handleToggleEditMode();
        }
      });
    }
  }

  handleSaveFloorGroup(saveFloorGroup: FloorGroup) {
    const viewingKeyDoor = this.viewingDoorGroupForLock?.keyDoor;
    if (viewingKeyDoor) {
      if (this.isViewingKeyMembership) {
        viewingKeyDoor.selectedFloorGroup = saveFloorGroup.uuid;
      } else {
        viewingKeyDoor.defaultFloorGroup = saveFloorGroup.uuid;
      }
      this.viewingDoorGroupForLock?.updateFloorGroupInfo();
      this.setUpNavbarActions();
      this.viewingDoorGroupForLock = undefined;
    }
  }

  filter() {
    this.filtered = this.selectableLocks.filter((lock) => {
      const search: string = this.searchControl.value;
      const includesSearch = lock.lockName.toLowerCase().includes(search.toLowerCase());
      return this.isEditing ? includesSearch : includesSearch && lock.selected;
    });

    this.keyDetailPageService.searchResultCount.next(
      this.searchControl.value ? { [KeyDetailPageTab.Doors]: this.filtered?.length } : {}
    );

    this.filteredByGroup = groupBy(this.filtered, (lock) => lock.groupName);
    this.filteredGroups = Object.keys(this.filteredByGroup).sort((a, b) => doorGroupSort(a) - doorGroupSort(b));
  }

  /**
   * Whether or not we should display the warning message about how changing the default floor group only
   * affects future memberships, not past memberships, in the current floor group selection screen.
   */
  get showDefaultFloorGroupWarning(): boolean {
    if (this.isViewingKeyMembership) {
      // If we are editing a key membership, we are not changing the default, we're changing the actual floor
      // group for the membership, so the warning (which is about defaults) is not relevant.
      return false;
    }
    if (this.isCreating) {
      // We're creating a new key, so we're not "changing" any floor group, they're all brand new.
      return false;
    }

    // We're editing an existing key, and not a key membership.
    // If this is a brand new elevator, we should not show the warning.
    // If it's an elevator already on the key, we SHOULD show the warning (because we're changing the default).
    return !!this.viewingDoorGroupForLock?.initialKeyDoor;
  }

  public setUpNavbarActions() {
    const actions: LatchNavAction[] = [];

    if ((this.isCreating || this.isEditing) && !this.isViewingKeyMembership && this.hasDoorcodeSuppression) {
      actions.push({
        id: 'key-toggle-doorcodes-edit-doors',
        name: `${this.areAllDoorcodesDisabled() ? 'Enable' : 'Disable'} All Doorcodes`,
        clickHandler: this.toggleAllDoorcodes.bind(this),
      });
    }

    const editAction = this.editModeAction;
    if (editAction) {
      actions.push(editAction);
    }

    if (this.showSubmit) {
      actions.push({
        id: 'save-key-edit-doors',
        name: this.submitText,
        disabled: this.totalChanges === 0,
        primary: !this.isCreating,
        clickHandler: this.handleSave.bind(this),
      });
    }

    this.changeActions.emit(actions);
  }

  private initSelectableLocks(): void {
    this.selectableLocks = this.locks.map((lock) => new SelectableLock(lock, this.initialSelectedById.get(lock.uuid)));
    this.selected = this.selectableLocks.filter((lock) => lock.selected);
  }

  private getSelectedKeyDoors(): SelectedKeyDoor[] {
    return this.selected.map(({ keyDoor }) => keyDoor as SelectedKeyDoor);
  }

  private openFloorGroupListModal(): void {
    if (!this.cardLayout) {
      const dialog = this.isEditing ?
        this.dialog.open<FloorGroupListEditModalComponent, FloorGroupListEditModalData, FloorGroup>(
          FloorGroupListEditModalComponent, {
          data: {
            elevatorName: this.viewingDoorGroupForLock?.lock.name ?? '',
            elevatorDetails: this.viewingDoorGroupForLock?.lock.elevator,
            selectedFloorGroupUUID: this.getCurrentFloorGroupUUID(this.viewingDoorGroupForLock),
            showWarningMessage: this.showDefaultFloorGroupWarning,
            defaultFloorGroupSelectMode: !this.isViewingKeyMembership,
          },
          width: '810px',
          maxWidth: '100vw',
        }) :
        this.dialog.open<FloorGroupListModalComponent, FloorGroupListModalData, FloorGroup>(
          FloorGroupListModalComponent, {
          data: {
            elevatorName: this.viewingDoorGroupForLock?.lock.name ?? '',
            elevatorDetails: this.viewingDoorGroupForLock?.lock.elevator,
            selectedFloorGroup: this.viewingDoorGroupForLock?.selectedFloorGroup,
            defaultFloorGroup: this.viewingDoorGroupForLock?.defaultFloorGroup,
          },
          width: '810px',
          maxWidth: '100vw',
        });

      dialog.afterClosed().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe((result: FloorGroup | undefined) => result ?
        this.handleSaveFloorGroup(result) : this.viewingDoorGroupForLock = undefined);
    }
  }

  private getPromptMessages(
    noDoorcodeCount: number,
    deleteDoorCount: number,
  ): string[] {
    const messages = [];
    if (this.membershipCount > 0) {
      if (deleteDoorCount && deleteDoorCount > 0) {
        messages.push(`
          Saving will remove <strong>${this.membershipCount}</strong>
          ${this.isPlural(this.membershipCount) ? 'people' : 'person'}'s access to
          <strong>${deleteDoorCount}</strong>
          ${pluralize(deleteDoorCount, (this.onlyShowingElevators) ? 'elevator' : 'door')},
          including any of their guests. Their doorcodes will be permanently deleted.
        `);
      }
      if (noDoorcodeCount && noDoorcodeCount > 0) {
        messages.push(`
          Permanent Doorcodes will be removed from the corresponding doors within this key.
          If the affected doors aren’t internet connected, they will need to be updated.
        `);
      }
      messages.push('Any daily doorcodes given out for this key will not be affected.');
      messages.push(`
        Some ${this.onlyShowingElevators ? 'elevators' : 'doors'} may require an update.
      `);
    } else {
      messages.push('Please confirm that you wish to save changes for this key.');
    }

    return messages;
  }

  private getInvalidPromptMessages(doorsWithNoDayCount: number): string[] {
    const messages = [];
    if (!doorsWithNoDayCount) {
      messages.push(`
        Keys must have at least one ${this.onlyShowingElevators ? 'elevator' : 'door'}.
        To remove all ${this.onlyShowingElevators ? 'elevator' : 'door'}s, delete the key.
      `);
    } else {
      messages.push(`
        ${doorsWithNoDayCount} of the selected doors ${doorsWithNoDayCount > 1 ? 'have' : 'has'} no days in its schedule.
        Add at least one day or remove ${doorsWithNoDayCount > 1 ? 'those doors' : 'that door'} from the key.
      `);
    }

    return messages;
  }

  private get editModeAction(): LatchNavAction | undefined {
    if (!this.isCreating && this.currentUserCanEdit && this.displayEditButton && !this.isEditing) {
      return {
        id: 'key-edit-doors',
        name: 'Edit',
        clickHandler: this.handleToggleEditMode.bind(this),
      };
    }

    if (!this.isCreating && this.currentUserCanEdit && this.displayEditButton && this.isEditing) {
      return {
        id: 'cancel-key-edit-doors',
        name: 'Cancel',
        primary: true,
        customClasses: ['latch-button-link'],
        clickHandler: this.handleToggleEditMode.bind(this),
      };
    }

    if (!this.isCreating && !this.currentUserCanEdit && !this.isDeliveryKey && this.displayEditButton) {
      return {
        id: 'key-edit-doors',
        name: 'Edit',
        tooltip: {
          enabled: true,
          hover: true,
          inverted: false,
          fixed: false,
          text: 'Only Portfolio Managers and Property Managers with ‘Full Key Management’ permission may edit keys.'
        },
      };
    }

    if (!this.isCreating && !this.currentUserCanEdit && this.isDeliveryKey && this.displayEditButton) {
      return {
        id: 'key-edit-doors',
        name: 'Edit',
        tooltip: {
          enabled: true,
          hover: true,
          inverted: false,
          fixed: false,
          text: `This Deliveries Key was created automatically by Latch and is securely shared with trusted delivery carriers such as UPS
            and other national carriers to enable deliveries when recipients are away.
            Only the Latch Team may edit the doors on this key.`
        },
      };
    }
  }
}
