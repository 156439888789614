import { Injectable } from '@angular/core';
import { DwollaCustomer } from 'manager/models/dwolla';
import {
  BankAccount, BankAccountStatus, BankAccountType, DwollaCustomerBuilding, DwollaCustomerBuildingAssociations
} from 'manager/models/payment';
import { PaymentAccount } from 'manager/modules/payment/model/payments';

import { Observable } from 'rxjs';

export interface CreateBankAccountInput {
  accountUUID: string;
  plaidAccountId: string;
  name: string;
  publicToken: string;
  status: BankAccountStatus;
  internalDwollaCustomerId: string;
  // Last 4 digits of the account number.
  mask: string | null;
  type: BankAccountType;
  bankName?: string;
}

@Injectable()
export abstract class PaymentService {
  public abstract getDwollaToken(data: Record<string, any>): Observable<{ token: string }>;
  public abstract getDwollaCustomer(dwollaCustomerUUID: string): Observable<DwollaCustomer | undefined>;
  public abstract getDwollaCustomers(accountUUID: string): Observable<DwollaCustomer[]>;
  public abstract createDwollaCustomer(accountUUID: string, dwollaCustomerId: string): Observable<DwollaCustomer>;
  // Used to create a new Plaid Link Token.
  public abstract createPlaidToken(accountUUID: string): Observable<string>;
  // Used to create a Plaid Link Token to verify an existing Bank Account.
  public abstract createPlaidTokenForVerification(bankAccountUUID: string): Observable<string>;
  public abstract createBankAccount(input: CreateBankAccountInput): Observable<BankAccount>;
  public abstract verifyBankAccount(bankAccountUUID: string, bankAccountStatus: BankAccountStatus): Observable<BankAccount>;
  public abstract getBankAccounts(buildingUUID: string): Observable<BankAccount[]>;
  public abstract getDwollaCustomerBuildingsForPortfolio(accountUUID: string): Observable<DwollaCustomerBuildingAssociations>;
  public abstract updateDwollaCustomerBuildingMapping(accountUUID: string, dwollaCustomerUUID: string, associatedBuildingIds: Set<string>):
    Observable<DwollaCustomerBuilding>;
  /**
   * Lists all payment accounts for Latch Portfolio
   * @param accountUUID Latch Portfolio Account Id
   */
  public abstract getPaymentAccounts(accountUUID: string): Observable<PaymentAccount[]>;
  /**
   * Create payment account for Latch Portfolio
   * @param accountUUID Latch Portfolio Account Id
   */
  public abstract createPaymentAccount(accountUUID: string): Observable<PaymentAccount>;
  /**
   * Retrieves onboarding url for payment account
   * @param accountId Payments account id
   */
  public abstract getPaymentAccountLink(accountId: string): Observable<string>;
}
