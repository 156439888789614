import { Component, Inject } from '@angular/core';
import { LatchDialogRef, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { ElevatorDetails } from 'manager/models/elevator-details';
import { FloorGroup } from 'manager/models/floor-group';

export interface FloorGroupListEditModalData {
  elevatorName: string;
  elevatorDetails?: ElevatorDetails;
  selectedFloorGroupUUID?: string;
  showWarningMessage: boolean;
  warningMessage?: string;
  defaultFloorGroupSelectMode: boolean;
}

@Component({
  selector: 'latch-floor-group-list-edit-modal',
  templateUrl: './floor-group-list-edit-modal.component.html',
})
export class FloorGroupListEditModalComponent {

  constructor(
    @Inject(LATCH_DIALOG_DATA) public data: FloorGroupListEditModalData,
    @Inject(LatchDialogRef) private dialogRef: LatchDialogRef<FloorGroup>,
  ) { }

  public onBackClick(): void {
    this.dialogRef.close();
  }

  public onDoneClick(floorGroup: FloorGroup): void {
    this.dialogRef.close(floorGroup);
  }
}
