<div class="key-card" [class.add-margin]="selectedKey.hideSettings">
  <div class="icon-elevator-key"></div>
  <div class="vertical">
    <div class="title">{{selectedKey.key.name}}</div>
    <div class="subtitle" *ngIf="!countSelectedKeyIntercoms">
      <strong>{{selectedKey.key.doors.length}}</strong>
      {{pluralize(selectedKey.key.doors, 'elevator')}}
    </div>
    <div class="subtitle" *ngIf="countSelectedKeyIntercoms">
      <strong>{{selectedKey.key.doors.length}}</strong>
      {{pluralize(selectedKey.key.doors, 'elevator')}}, <strong>{{countSelectedKeyIntercoms}}</strong> {{pluralize(countSelectedKeyIntercoms, 'intercom')}}
    </div>
  </div>
  <button *ngIf="!selectedKey.readOnly" type="button" class="latch-button-icon" (click)="handleRemove()">
    <span class="latch-icon-close"></span>
  </button>
</div>
<div class="card-details" *ngIf="!selectedKey.hideSettings">
  <div class="card-detail-row">
    <latch-checkbox [checked]="selectedKey.role === KeyMembershipRole.RESIDENT"
      label="Resident" labelPosition="after" class="text text-dark" data-id="resident"
      (checkedChange)="handleToggleRole()" [disabled]="selectedKey.readOnly === true">
    </latch-checkbox>
  </div>
  <div class="card-detail-row">
    <latch-checkbox [checked]="selectedKey.shareable"
      label="May share key" labelPosition="after" class="text text-dark" data-id="may-share-key"
      (checkedChange)="handleToggleGuests()" [disabled]="selectedKey.readOnly === true">
    </latch-checkbox>
  </div>
</div>
<div class="card-elevators" *ngIf="!!elevators" [class.add-padding]="selectedKey.hideSettings">
  <div class="elevator-row"
    *ngFor="let elevator of elevators"
    [class.disabled]="selectedKey.readOnly"
    latchTooltip
    [tooltipEnabled]="!selectedKey.readOnly && isDisabled(elevator.lockUUID)"
    [tooltipText]="disabledTooltip(elevator.lockUUID)"
    (click)="!selectedKey.readOnly && !isDisabled(elevator.lockUUID) && handleFloorGroupSelect(elevator)">
    <div class="vertical">
      <div class="card-title">{{elevator.keyDoorName}}</div>
      <div class="card-subtitle">{{elevator.floorGroupName}}&nbsp;{{elevator.floorGroupState}}</div>
    </div>
    <div class="latch-icon-chevron-right" *ngIf="!selectedKey.readOnly && !isDisabled(elevator.lockUUID)"></div>
  </div>
</div>
