import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Key, KeyType } from '../../../models/key';

import { LatchAnalyticsService } from '@latch/latch-web';
import { pluralize } from '../../../services/utility/utility';
import { ElevatorKeyDoorInfo, DisabledLock } from './selected-elevator-key.component';
import { getKeyIconType, KeyIconType } from '../../../services/utility/presentation';
import { Lock } from '../../../models/lock';
import { CreateFloorGroupInput, KeyMembershipRole } from '../../../services/key-membership/key-membership.service';
import { IntercomShortInfo } from 'manager/services/intercom/intercom.service';

export interface SelectedKey {
  key: Key;
  shareable: boolean;
  role: KeyMembershipRole;
  readOnly?: boolean;
  hideSettings?: boolean;

  // Only present for elevator keys
  floorGroups?: CreateFloorGroupInput[];
}

/**
 * KeyShareableUpdate is an event that is emitted when the user modifies whether a key membership should be shareable.
 *
 * It indicates for which key the status was changed, and what the new (post-update) status should be.
 */
export interface KeyUpdate {
  key: Key;
  shareable: boolean;
  role: KeyMembershipRole;
}

@Component({
  selector: 'latch-selected-keys',
  templateUrl: './selected-keys.component.html',
  styleUrls: ['./selected-keys.component.scss']
})
export class SelectedKeysComponent {

  KeyType = KeyType;
  KeyIconType = KeyIconType;
  pluralize = pluralize;

  @Input() locks: Lock[] = [];
  @Input() selectedKeys: SelectedKey[] = [];
  @Input() intercoms: IntercomShortInfo[] = [];
  /**
   * Locks for which the user should not be allowed to set a floor group.
   *
   * In additon to the locks listed here, clients can mark an entire key "read-only".
   */
  @Input() disabledLocks: DisabledLock[] = [];

  @Output() floorGroupSelect = new EventEmitter<ElevatorKeyDoorInfo>();
  @Output() toggleShareable = new EventEmitter<KeyUpdate>();
  @Output() remove = new EventEmitter<SelectedKey>();

  constructor(private analyticsService: LatchAnalyticsService) { }

  keyIconType(key: Key): KeyIconType {
    return getKeyIconType(key);
  }

  handleFloorGroupSelect(elevatorKeyDoorInfo: ElevatorKeyDoorInfo) {
    this.floorGroupSelect.emit(elevatorKeyDoorInfo);
  }

  handleRemove(membership: SelectedKey) {
    this.remove.emit(membership);
    this.analyticsService.track('Remove Selected Key', {
      'Key Name': membership.key.name
    });
  }

  handleToggleShareable(membership: SelectedKey, newValue: boolean) {
    this.toggleShareable.emit({ shareable: newValue, key: membership.key, role: membership.role });
    this.analyticsService.track('Toggle Sharing', {
      'Key Name': membership.key.name,
      'May Share': membership.shareable
    });
  }

  handleToggleRole(membership: SelectedKey, newValue: KeyMembershipRole) {
    this.toggleShareable.emit({
      shareable: membership.shareable,
      key: membership.key,
      role: newValue,
    });
    this.analyticsService.track('Toggle Sharing', {
      'Key Name': membership.key.name,
      'Key Membership Role': membership.role
    });
  }

  countSelectedKeyIntercoms(selectedKey: SelectedKey): number {
    const selectedKeyLockUUIDs: string[] = selectedKey.key.doors.map(door => door.lockUUID);
    const intercomLockUUIDs: string[] = this.intercoms.map(intercom => intercom.lockUUID);
    return selectedKeyLockUUIDs.filter(selectedKeyLockUUID => intercomLockUUIDs.indexOf(selectedKeyLockUUID) >= 0).length;
  }

  trackBy(index: number, item: SelectedKey) {
    if (!item) {
      return null;
    }
    return item.key.uuid;
  }
}
