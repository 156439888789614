import { Injectable } from '@angular/core';
import { from, Observable, tap } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import {
  CreateFeaturedEntry,
  FeaturedEntry,
  FeaturedEntryCandidatesInput,
  FeaturedEntryCandidatesResponse,
  FeaturedEntryUpdate
} from '../../models/featured-entry';
import { AuthService } from '../auth/auth.service';
import { FeaturedEntryService } from './featured-entry.service';

@Injectable()
export class HTTPFeaturedEntryService extends FeaturedEntryService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getBuildingFeaturedEntries(buildingUUID: string): Observable<FeaturedEntry[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/featuredEntries`
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  createBuildingFeaturedEntry(buildingUUID: string, featuredEntryInput: CreateFeaturedEntry): Observable<FeaturedEntry> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/buildings/${buildingUUID}/featuredEntries`,
      data: featuredEntryInput
    }).pipe(
      map(response => AuthService.getPayload(response)),
      tap(() => this.updateFeaturedEntries.next(true))
    );
  }

  getBuildingFeaturedEntryCandidates(
    buildingUUID: string,
    featuredEntryCandidatesInput: FeaturedEntryCandidatesInput
  ): Observable<FeaturedEntryCandidatesResponse> {
    const search: Record<string, string> = {
      limit: featuredEntryCandidatesInput.limit.toString(),
      start: featuredEntryCandidatesInput.start.toString(),
    };
    if (featuredEntryCandidatesInput.search) {
      search.search = featuredEntryCandidatesInput.search;
    }
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/featuredEntries/candidates`,
      search
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  getBuildingFeaturedEntry(buildingUUID: string, featuredEntryId: number): Observable<FeaturedEntry> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/featuredEntries/${featuredEntryId}`,
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  updateFeaturedEntry(featuredEntry: FeaturedEntryUpdate, buildingUUID: string, featuredEntryId: number): Observable<FeaturedEntry> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/buildings/${buildingUUID}/featuredEntries/${featuredEntryId}`,
      data: featuredEntry
    }).pipe(
      map(response => AuthService.getPayload(response)),
      tap(() => this.updateFeaturedEntries.next(true))
    );
  }

  updateFeaturedEntriesOrder(featuredEntries: FeaturedEntry[], buildingUUID: string): Observable<FeaturedEntry[]> {
    const requests: Observable<FeaturedEntry>[] = [];
    featuredEntries.forEach(fe => {
      const req = {
        displayName: fe.displayName,
        contactCardUUID: fe.contactCardUUID,
        contactPreference: fe.contactPreference,
        order: fe.order
      };
      requests.push(this.updateFeaturedEntry(req, buildingUUID, fe.id));
    });

    return from(requests).pipe(
      concatMap(req => req),
      toArray(),
      tap(() => this.updateFeaturedEntries.next(true))
    );
  }
}
