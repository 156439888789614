<latch-card>
  <ng-container latchCardHeader>
    <div class="latch-card-title">Latch Mission Control is now Door Web</div>
    <div class="latch-card-actions">
      <button class="latch-button-icon" (click)="dialogRef.close()">
        <span class="latch-icon-close"></span>
      </button>
    </div>
  </ng-container>
  <ng-container latchCardContent>
    You may continue to use the Latch Manager App to service Latch hardware.
  </ng-container>
  <ng-container latchCardFooter>
    <button class="latch-button" (click)="redirectToDoor()">Log in here</button>
  </ng-container>
</latch-card>
