<latch-base-modal>
  <div class="base-modal-card">
    <latch-loading [isActive]="isLoading">
      <div class="base-modal-card-header">
        <div class="base-modal-card-actions">
          <button class="latch-button-icon" (click)="closeModal.emit('close')">
            <span class="latch-icon-chevron-left"></span>
          </button>
        </div>
        <div class="base-modal-card-title">Send Notification</div>
        <div class="base-modal-card-description">If SMS is chosen and there’s an issue with the resident’s phone number, then an email will be sent instead.</div>
      </div>
      <div class="unit-name">
        <latch-checkbox
          labelPosition="after"
          [checked]="notifyAllResidents"
          [label]="'Unit ' + deliveryUnit.unit.displayName"
          (checkedChange)="handleNotifyAllResidentsChange($event)"></latch-checkbox>
      </div>
      <div class="base-modal-card-body">
        <ng-container *ngIf="notifyResidents.length > 0">
          <ng-container *ngFor="let notifyResident of notifyResidents; let i = index">
            <div class="latch-divider" *ngIf="i > 0"></div>
            <latch-labeled-list-item>
              <div class="resident-elements">
                <div class="resident-element">
                  <latch-checkbox
                    *ngIf="notifyResident.notificationTypes.length > 0"
                    labelPosition="after"
                    [checked]="notifyResident.notify"
                    [label]="notifyResident.contactCardFullName"
                    (checkedChange)="setResidentNotifyValue($event, notifyResident)"></latch-checkbox>
                    <div class="resident-name" *ngIf="notifyResident.notificationTypes.length === 0">
                      {{notifyResident.contactCardFullName}}
                    </div>
                </div>
                <div class="resident-element">
                  <div class="radio-items" *ngIf="notifyResident.notificationTypes.length > 0">
                    <ng-container *ngFor="let notificationType of notifyResident.notificationTypes">
                      <latch-radio-item
                        labelPosition="after"
                        [checked]="notifyResident.notificationType === notificationType"
                        [label]="notificationTypeNames[notificationType]"
                        [value]="notificationType"
                        (check)="setResidentNotificationType(notificationType, notifyResident)"></latch-radio-item>
                    </ng-container>
                  </div>
                  <div *ngIf="notifyResident.notificationTypes.length === 0"
                    class="icon-warning-circle-gray"
                    latchTooltip
                    tooltipText="This resident cannot be selected because they do not have contact information."></div>
                </div>
              </div>
            </latch-labeled-list-item>
          </ng-container>
        </ng-container>
      </div>
      <div class="base-modal-card-footer">
        <button class="latch-button" [disabled]="notifyResidentsCount === 0" (click)="sendNotifications()">
          Send notifications ({{notifyResidentsCount}})
        </button>
      </div>
    </latch-loading>
  </div>
</latch-base-modal>
