import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'phoneFormatting' })
export class PhoneFormattingPipe implements PipeTransform {
  public transform(pn: any, args?: any): string | undefined {
    if (!pn) {
      return undefined;
    } else {
      // the digits after the '+' and before the scrubbed digits comprise the country code
      const countryCode = new RegExp(/.*(?=X{6}.*)/).exec(pn);
      const lastNumbers: string = pn.slice(-4);
      return `${String(countryCode)}(***) ***-${lastNumbers}`;
    }
  }
}
