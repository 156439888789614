import { LatchAuthConfig, NativeAppAuthConfig } from '@latch/latch-web';
import { environment as environmentBuiltIn } from './environment';

export interface Environment {
  production: boolean;
  apiBaseUrl: string;
  apiDoorUrl?: string;
  selectAccount: string | null;
  useMocks: boolean;
  name: string;
  enableLogging: boolean;
  datadogToken?: string;
  enableAnalytics?: boolean;
  heapApplicationId?: string;
  reCaptchaToken: string;
  auth0Config: LatchAuthConfig['auth0Config'];
  nativeAppAuthConfig?: NativeAppAuthConfig;
  enableFeaturedEntries: boolean;
  sprigEnvironmentId?: string;
  split?: {
    core: {
      authorizationKey: string;
      key: string;
    }
  };
  showDoorExperience?: boolean;
  mapbox?: {
    accessToken: string;
    geocodingUrl: string;
  },
  doorAppUrl?: string;
}

export const environment: Environment = (window as any).environment ?? environmentBuiltIn;
