import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { PmsAccessReviewData } from 'manager/models/pms-access';
import { Observable, of, Subject, throwError } from 'rxjs';
import { PartialBy, PmsAccessService, PmsUnitsFilter } from './pms-access.service';
import { catchError, finalize, map, mergeMap, startWith } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PmsSource } from 'manager/models/building';
import { PmsCredential, PmsConfiguration, PmsProperty, PmsUnit, LatchUnit } from 'manager/modules/integrations/models/integrations';

const UserForbiddenError = 'USER_FORBIDDEN';

@Injectable()
export class HTTPPmsAccessService implements PmsAccessService {
  private pmsAccessReviewDataSubject$ = new Subject<void>();

  constructor(private authService: AuthService) { }

  public getPmsAccessReviewData(buildingUUID: string): Observable<PmsAccessReviewData | undefined> {
    const pmsAccessReviewData$ = this.authService
      .request({
        method: 'get',
        endpoint: `/web/v2/buildings/${buildingUUID}/getPendingAccess`,
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error) => {
          const errorMessage = AuthService.getPayload(error);
          return errorMessage === UserForbiddenError ? of(undefined) : this.handleError(error);
        }),
      );

    return this.pmsAccessReviewDataSubject$.asObservable().pipe(
      startWith(() => pmsAccessReviewData$),
      mergeMap(() => pmsAccessReviewData$),
    );
  }

  public discardPendingAccess(buildingUUID: string, discardPendingAccessReviews: string[] = []): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/buildings/${buildingUUID}/automatedPmsAccess`,
        data: {
          discardPendingAccessReviews,
        }
      }).pipe(
        finalize(() => this.pmsAccessReviewDataSubject$.next()),
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public enableAccessAutomation(buildingUUID: string, confirmedPendingAccessReviews: string[] = []): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/buildings/${buildingUUID}/automatedPmsAccess`,
        data: {
          confirmedPendingAccessReviews,
        }
      }).pipe(
        finalize(() => this.pmsAccessReviewDataSubject$.next()),
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public triggerPmsSync(buildingUUID: string): Observable<string> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/buildings/${buildingUUID}/triggerPmsSync`,
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  public requestIntegration(buildingUUID: string, pmsSource: PmsSource): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/buildings/${buildingUUID}/configurePmsIntegration`,
        data: { pmsSource }
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  getPmsConfiguration(buildingUUID: string): Observable<PmsConfiguration> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/pmsConfiguration`,
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  updatePmsConfiguration(buildingUUID: string, pmsConfiguration: PmsConfiguration): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/buildings/${buildingUUID}/configurePmsIntegration`,
        data: {
          ...pmsConfiguration,
          excludedLeaseStatuses: pmsConfiguration.excludedLeaseStatuses?.map(s => ({ status: s.status })) ?? []
        },
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  getProperties(buildingUUID: string, credentialUUID: string): Observable<PmsProperty[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/client/${credentialUUID}/pmsProperties`,
      }).pipe(
        map(response => AuthService.getPayload(response).availableProperties),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  getCredentials(buildingUUID: string): Observable<PmsCredential[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/pmsCredentials`,
      }).pipe(
        map(response => AuthService.getPayload(response).pmsCredentialList),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  createOrUpdateCredential(buildingUUID: string, pmsCredential: PartialBy<PmsCredential, 'uuid'>): Observable<PmsCredential> {
    return this.authService
      .request({
        method: 'POST',
        endpoint: `/web/v1/buildings/${buildingUUID}/pmsCredential`,
        data: pmsCredential,
      }).pipe(
        map(response => AuthService.getPayload(response)),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  getPmsUnits(buildingUUID: string, filters: PmsUnitsFilter): Observable<{ units: PmsUnit[], count: number }> {
    let search = new HttpParams()
      .append('start', filters.start ?? 0)
      .append('limit', filters.limit ?? 100);
    if (filters.name) {
      search = search.append('name', filters.name);
    }
    if (filters.mapped !== undefined) {
      search = search.append('mapped', filters.mapped);
    }
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/pmsUnits`,
        search
      }).pipe(
        map(response => {
          const result = AuthService.getPayload(response);
          return {
            units: (result.mappings as PmsUnit[]).map(pmsUnit => ({
              ...pmsUnit,
              unitId: pmsUnit.suggested ? undefined : pmsUnit.unitId,
              unitName: pmsUnit.suggested ? undefined : pmsUnit.unitName,
            })),
            count: result.count,
          };
        }),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  updatePmsUnits(buildingUUID: string, pmsUnit: PmsUnit): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v1/buildings/${buildingUUID}/pmsUnit`,
        data: {
          id: pmsUnit.id,
          pmsPropertyId: pmsUnit.pmsPropertyId,
          pmsBuildingId: pmsUnit.pmsBuildingId,
          pmsUnitId: pmsUnit.pmsUnitId,
          pmsUnitName: pmsUnit.pmsUnitName,
          unitId: pmsUnit.unitId,
          unitName: pmsUnit.unitName,
          suggested: pmsUnit.suggested,
          remove: pmsUnit.remove,
          uniqueIdentifier: pmsUnit.uniqueIdentifier,
        },
      }).pipe(
        map(response => AuthService.getPayload(response) as void),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  getUnmappedUnits(buildingUUID: string, searchTerm: string): Observable<LatchUnit[]> {
    return this.authService
      .request({
        method: 'get',
        endpoint: `/web/v1/buildings/${buildingUUID}/availableUnitsForPms`,
      }).pipe(
        map(response => AuthService.getPayload(response).units as LatchUnit[]),
        map(units => units.filter(u => u.name.toLowerCase().includes(searchTerm.toLowerCase()))),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  refreshPendingAccess(buildingUUID: string): Observable<void> {
    return this.authService
      .request({
        method: 'post',
        endpoint: `/web/v2/buildings/${buildingUUID}/refreshPendingAccess`,
        data: {},
      }).pipe(
        map(response => void 0),
        catchError((error: Error) => this.handleError(error)),
      );
  }

  private handleError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      if (typeof message === 'string') {
        return throwError(() => new Error(message));
      } else {
        return throwError(() => message ?? error);
      }
    } else {
      return throwError(() => error);
    }
  }
}
