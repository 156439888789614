<div *ngIf="selectedKeys && locks">
  <div *ngFor="let selectedKey of selectedKeys; trackBy: trackBy">
    <latch-selected-door-key
      *ngIf="selectedKey.key.keyType === KeyType.Door"
      [selectedKey]="selectedKey"
      [countSelectedKeyIntercoms]="countSelectedKeyIntercoms(selectedKey)"
      (toggleShareable)="handleToggleShareable(selectedKey, $event)"
      (toggleRole)="handleToggleRole(selectedKey, $event)"
      (remove)="handleRemove(selectedKey)" >
    </latch-selected-door-key>
    <latch-selected-elevator-key
      *ngIf="selectedKey.key.keyType === KeyType.Elevator"
      [selectedKey]="selectedKey"
      [countSelectedKeyIntercoms]="countSelectedKeyIntercoms(selectedKey)"
      [locks]="locks"
      [disabledLocks]="disabledLocks"
      (floorGroupSelect)="handleFloorGroupSelect($event)"
      (toggleShareable)="handleToggleShareable(selectedKey, $event)"
      (toggleRole)="handleToggleRole(selectedKey, $event)"
      (remove)="handleRemove(selectedKey)" >
    </latch-selected-elevator-key>
  </div>
</div>
