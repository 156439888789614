import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/interface';
import { BehaviorSubject, combineLatest, fromEvent, Observable, startWith, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

// TODO: Import from the latchweb library once it's exported
enum Theme {
  Latch = 'latch-theme',
  DoorLight = 'door-light-theme',
  DoorDark = 'door-dark-theme'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  sub: Subscription | undefined;
  private darkMode = false;
  private document = inject(DOCUMENT);
  private theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(Theme.Latch);

  init() {
    this.pickTheme();
    this.sub = combineLatest([
      this.evaluateMediaQuery$('(prefers-color-scheme: dark)'),
      this.theme.asObservable()
    ]).subscribe(([matches, theme]) => {
      if (!this.sub) {
        this.document.body.classList.add(theme);
      }
      // TODO: Replace with the "matches" received value once dark mode theme is ready
      this.darkMode = false;
      this.applyTheme(theme);
    });
  }

  private pickTheme() {
    if (environment.showDoorExperience) {
      this.theme.next(this.darkMode ? Theme.DoorDark : Theme.DoorLight);
    } else {
      this.theme.next(Theme.Latch);
    }
  }

  private applyTheme(theme: Theme) {
    Object.values(Theme).forEach(option => {
      if (theme === option) {
        this.document.body.classList.add(option);
      } else {
        this.document.body.classList.remove(option);
      }
    });
  }

  evaluateMediaQuery$(query: string): Observable<boolean> {
    const mediaQuery = window.matchMedia(query);
    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((list: MediaQueryList) => list.matches)
    );
  }
}
