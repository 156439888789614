<latch-loading [isActive]="isLoading" *ngIf="!showSelectDoors">
  <latch-card class="page" headerTitle="Make Key">
    <button class="latch-button-icon" latchCardActions (click)="back.emit()">
      <span class="latch-icon-close"></span>
    </button>

    <ng-container latchCardContent>

      <latch-labeled-list-item label="Type">
        <latch-dropdown [data]="keyTypeData" [(ngModel)]="keyType" [disabled]="!isElevatorBuilding"/>
      </latch-labeled-list-item>
      <div class="latch-divider"></div>

      <latch-labeled-list-item label="Name" [class.has-error]="errors.name">
        <input
          class="latch-form-control"
          name="name"
          type="text"
          autocomplete="off"
          [(ngModel)]="name"
          latchRequired
          latchAutofocus/>
        <div *ngIf="errors.name" class="help-block">{{errors.name}}</div>
      </latch-labeled-list-item>
      <div class="latch-divider"></div>

      <latch-labeled-list-item
        label="{{(keyType === KeyType.Door) ? 'Doors' : 'Elevators'}}"
        [class.has-error]="errors.keyDoors || errors.global">
        <button latch-labeled-list-item-action [class.placeholder]="keyDoors.length === 0" (click)="handleSelectDoors()">
          <ng-container *ngIf="keyDoors.length === 0">
            Select {{(keyType === KeyType.Door) ? 'doors' : 'elevators'}}
          </ng-container>
          <div *ngIf="keyDoors.length > 0">
            <strong>{{keyDoors.length}}</strong>
            {{pluralize(keyDoors, (keyType === KeyType.Door) ? 'door' : 'elevator')}}
          </div>
        </button>
        <div *ngIf="errors.keyDoors" class="help-block">{{errors.keyDoors}}</div>
        <div *ngIf="errors.global" class="help-block">{{errors.global}}</div>
      </latch-labeled-list-item>
      <div class="latch-divider"></div>

      <ng-container *ngIf="!hasOpenkitOnboardingV2Feature && geminiPartners.length">
        <latch-labeled-list-item label="Partner">
          <latch-dropdown
            [data]="geminiPartners"
            [(ngModel)]="selectedGeminiPartnerUUID"/>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
      </ng-container>

      <ng-container *ngIf="hasOpenkitOnboardingV2Feature && partners.length">
        <latch-labeled-list-item label="Partner">
          <latch-dropdown
            [data]="partners"
            [(ngModel)]="selectedPartner"/>
        </latch-labeled-list-item>
        <div class="latch-divider"></div>
      </ng-container>

    </ng-container>

    <ng-container latchCardFooter>
      <button
        class="latch-button"
        [disabled]="!canSubmit"
        (click)="handleSubmit()"
      >
        Make Key
      </button>
    </ng-container>
  </latch-card>
</latch-loading>

<latch-key-doors-list
  [cardLayout]="true"
  [isCreating]="true"
  [locks]="filteredLocks"
  [initialSelected]="keyDoors"
  [hasDoorcodeSuppression]="(hasDoorcodeSuppressionFeature$ | async) === true"
  (cancel)="showSelectDoors = false"
  (done)="handleDoorsSelected($event)"
  *ngIf="showSelectDoors"
></latch-key-doors-list>
