<latch-loading [isActive]="isLoading" [ngSwitch]="currentFlow"
  *ngIf="selectedAccount && userMfaSettings && user">
  <!--DEFAULT TEMPLATE-->
  <ng-container *ngSwitchCase="Flow.None">
    <latch-card class="page" headerTitle="Two-Factor Authentication">
      <button class="latch-button-icon" latchCardActions (click)="exit($event)">
        <span class="latch-icon-close"></span>
      </button>
      <ng-container latchCardContent>
        <div class="field-list">
          <!--DISABLE MFA CELL-->
          <div class="cell cell-row">
            <div class="cell-heading spaced">Two-Factor Authentication is active for your user account</div>
            <div class="cell-value cell-body">
              <div class="icon-mfa-enabled text-line"></div>
              <button *ngIf="!requiredForAnyAccounts"
                      class="latch-button-link latch-danger cell-right text-line"
                      (click)="disableMfa($event)">
                Turn Off
              </button>
              <button *ngIf="requiredForAnyAccounts"
                      class="latch-button-link cell-right text-line"
                      disabled
              >Turn Off
              </button>
              <div class="explanation-text" *ngIf="requiredForAnyAccounts && isAccountOwner">
                <!--for account owner-->
                Two-Factor Authentication has been required for one of the portfolios you manage. If you would like
                to
                disable this setting, you will first need to Turn Off the setting requiring Two-Factor Authentication
                for the portfolio, and then you can disable the Two-Factor Authentication setting for yourself.
              </div>
              <div class="explanation-text" *ngIf="requiredForAnyAccounts && !isAccountOwner">
                <!--for building admin-->
                Your Portfolio Manager has required Two-Factor Authentication. If you wish to change this setting, please
                consult your Portfolio Manager.
              </div>
            </div>
          </div>
          <!--CHANGE PHONE NUMBER-->
          <div class="cell cell-row">
            <button class="cell-value"
                    (click)="changePhone($event)">
              <div class="cell-heading">Change Phone Number</div>
            </button>
          </div>
          <div class="cell cell-row">
            <button class="cell-value"
                    (click)="onLost($event)">
              <div class="cell-heading">I Lost My Phone</div>
            </button>
          </div>
          <!--MFA cell (when MFA not required)-->
          <div class="cell cell-row" *ngIf="!selectedAccount.mfa?.smsRequired && isAccountOwner && !requiredForAnyAccounts">
            <div class="cell-heading spaced">Require Two-Factor Authentication for all portfolio managers and property managers
              for
              {{selectedAccount.name}}
            </div>
            <div class="cell-value  cell-body">
              <div class="icon-mfa-not-required text-line"></div>
              <button
                class="latch-button-link cell-right text-line"
                (click)="requireMfaForAccount($event)">Turn On
              </button>
            </div>
          </div>
          <!--MFA cell (when MFA required)-->
          <div class="cell" *ngIf="selectedAccount.mfa?.smsRequired && isAccountOwner && !requiredForAnyAccounts">
            <div class="cell-heading spaced">Require Two-Factor Authentication for all portfolio managers and property managers
              for
              {{selectedAccount.name}}
            </div>
            <div class="cell-value cell-body">
              <div class="icon-mfa-required text-line"></div>
              <button
                class="latch-button-link latch-danger cell-right text-line"
                (click)="deRequireMfaForAccount($event)">Turn Off
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </latch-card>
  </ng-container>

  <!--Try Again later element -->
  <latch-card class="page" *ngSwitchCase="Flow.TryAgainSoon">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="exitTryAgain($event)">
          <span class="latch-icon-chevron-left"></span>
        </button>
      </div>
      <div class="latch-card-title">Try Again Later</div>
      <div class="page-description">Your account is temporarily locked due to too many failed attempts. Please try again in a
        little while.
      </div>
    </ng-container>
  </latch-card>

  <!--Change phone number-->
  <latch-card class="page" id="change-phone" *ngSwitchCase="Flow.ChangePhone">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="goToSettingsPage($event)">
          <span class="latch-icon-close"></span>
        </button>
      </div>
      <div class="latch-card-title">Change phone number</div>
      <div class="page-description">You'll be asked to confirm your current phone number with a code. Then, you'll be prompted to
        log in again and can select a new phone number for two-factor authentication at that time.</div>
    </ng-container>
    <ng-container latchCardFooter>
      <a class="latch-button" (click)="initiateChangePhone()">Change phone number</a>
    </ng-container>
  </latch-card>

  <!--I lost my Phone-->
  <latch-card class="page" id="lost-phone" *ngSwitchCase="Flow.Support">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="goToSettingsPage($event)">
          <span class="latch-icon-close"></span>
        </button>
      </div>
      <div class="latch-card-title">{{supportFlowTexts.header}}</div>
      <div class="page-description">{{supportFlowTexts.body}}</div>
    </ng-container>
    <ng-container latchCardFooter>
      <a class="latch-button" href="{{mailtoLink}}">Contact</a>
    </ng-container>
  </latch-card>

  <!--REQUIRE MFA FLOW-->
  <latch-require-mfa-flow
    *ngSwitchCase="Flow.Require"
    [accountId]="selectedAccount.uuid"
    [accountName]="selectedAccount.name"
    (requiredSuccess)="onDone($event)"
    (goToSettings)="goToSettingsPage($event)"
  ></latch-require-mfa-flow>

  <!-- DE-REQUIRE MFA FLOW-->
  <latch-de-require-mfa-flow
    *ngSwitchCase="Flow.DeRequire"
    [accountId]="selectedAccount.uuid"
    (deRequiredSuccess)="onDone($event)"
    (goToSettings)="goToSettingsPage($event)"
  ></latch-de-require-mfa-flow>
</latch-loading>
