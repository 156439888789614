import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, switchMap, tap, concatMap } from 'rxjs/operators';

import { Account } from './../../models/account';
import { AccountService } from './account.service';
import { AuthService } from './../auth/auth.service';
import { Building } from './../../models/building';
import { environment } from '../../../environments/interface';

/**
 * Implementation of AccountService which queries real data from the Latch servers.
 */
@Injectable()
export class HTTPAccountService extends AccountService {

  constructor(
    protected authService: AuthService,
    private http: HttpClient
  ) {
    super(authService);
  }

  getAccounts(): Observable<Account[]> {
    if (!this.accounts$) {
      this.accounts$ = this.refreshAccounts$.pipe(
        switchMap(() => this.authService
          .request({
            method: 'get',
            endpoint: '/web/v2/accounts'
          }).pipe(
            concatMap((response: HttpResponse<any>): Observable<Account[]> => {
              // Check that the response is in the format we expect; bail if not.
              const responseError = this.getResponseErrors(response);
              if (responseError) {
                // throwError allows the code to continue running, so ... Why would that exist? Who would want that?
                throw responseError;
              }

              // getResponseErrors checks that the data is in the correct format and has all required fields. We
              // happen to have data structures with fields that exactly match the endpoint's return format, so we can just
              // return the json object.
              const accounts = AuthService.getPayload(response).accounts;
              /*
              Add the data from the new buildings endpoint to the accounts
              */
              if (environment.showDoorExperience) {
                const url = `${environment.apiDoorUrl ?? ''}/door-api/v1/me/buildings`;
                return this.http.get<any>(url).pipe(
                  map(data => {
                    const { buildings } = data;
                    accounts.forEach((account: Account) => {
                      account.buildings = buildings.filter((building: Building) => building.accountUuid === account.uuid);
                    });
                    return accounts;
                  })
                );
              }

              return of(accounts);
            }),
          )
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }

    return this.accounts$;
  }

  private getResponseErrors(response: HttpResponse<any>): Error | null {
    if (!AuthService.hasValidPayload(response)) {
      const message = `Response has no payload: '${response.toString()}'`;
      return new Error(message);
    }
    const payload = AuthService.getPayload(response).accounts;

    if (!Array.isArray(payload)) {
      const message = `Received unexpected result from server: '${response.toString()}'`;
      return new Error(message);
    }

    for (const object of payload) {
      if (!('name' in object)) {
        let message = `Received unexpected result from server: '${response.toString()}'`;
        message += `; object '${object}' has no 'name' property.`;
        return new Error(message);
      }
      if (!('uuid' in object)) {
        let message = `Received unexpected result from server: '${response.toString()}'`;
        message += `; object '${object}' has no 'uuid' property.`;
        return new Error(message);
      }
    }

    return null;
  }

}
