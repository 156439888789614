import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { LatchAnalyticsConstants, LatchAnalyticsService } from '@latch/latch-web';
import { LatchMfaService } from '@latch/latch-web';

enum Step {
  Confirm,
  Success
}

@Component({
  selector: 'latch-require-mfa-flow',
  templateUrl: './require-mfa-flow.component.html',
  styleUrls: ['./require-mfa-flow.component.scss']
})
export class RequireMfaFlowComponent implements OnInit {
  @Input() accountId!: string;
  @Input() accountName!: string;
  @Output() requiredSuccess = new EventEmitter();
  @Output() goToSettings = new EventEmitter();
  Step = Step;
  currentStep: Step = Step.Confirm;

  constructor(
    private mfaService: LatchMfaService,
    private errorHandlerService: ErrorHandlerService,
    private analyticsService: LatchAnalyticsService,
  ) { }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, { [LatchAnalyticsConstants.PageName]: 'Two-Factor Auth Require' });
  }

  public confirmChange(e: Event): void {
    e.preventDefault();
    this.mfaService.requireMFAForPortfolio(this.accountId, true)
      .subscribe(
        () => {
          this.currentStep = Step.Success;
          this.analyticsService.track('Two-Factor Auth Require', {
            [LatchAnalyticsConstants.Success]: true
          });
        },
        (error: Error) => {
          this.analyticsService.track('Two-Factor Auth Require', {
            [LatchAnalyticsConstants.Success]: false,
            [LatchAnalyticsConstants.ErrorMessage]: error
          });
          this.errorHandlerService.handleException(error);
        });
  }

  goBack(e: Event) {
    e.preventDefault();
    this.goToSettings.emit();
  }

  finished(e: Event) {
    e.preventDefault();
    this.requiredSuccess.emit();
  }
}
