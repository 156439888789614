<div class="step-bar">
  <button
    *ngFor="let step of stepsArray; let i = index"
    class="step latch-button-icon"
    type="button"
    [class.active]="i === activeStep"
    [class.done]="i < activeStep">
    {{ i + 1 }}
  </button>
</div>
