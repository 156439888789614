import { LatchSelectionItem } from '@latch/latch-web';
import { BookableResource } from 'manager/modules/bookings/models/booking';

export enum SpaceType {
  Portfolio = 'PORTFOLIO',
  Building = 'BUILDING',
  Floor = 'FLOOR',
  ResidentialUnit = 'RESIDENTIAL_UNIT',
  Room = 'ROOM',
  Communal = 'COMMUNAL',
  Access = 'ACCESS',
  Virtual = 'VIRTUAL',
  Key = 'KEY',
  Unknown = 'UNKNOWN',
}

export const SpaceTypeDisplay: { [key in SpaceType]: string } = {
  [SpaceType.Portfolio]: 'Portfolio',
  [SpaceType.Building]: 'Building',
  [SpaceType.Floor]: 'Floor',
  [SpaceType.ResidentialUnit]: 'Residential Unit',
  [SpaceType.Room]: 'Room',
  [SpaceType.Communal]: 'Communal',
  [SpaceType.Access]: 'Access',
  [SpaceType.Virtual]: 'Virtual',
  [SpaceType.Key]: 'Key',
  [SpaceType.Unknown]: 'Unknown',
};

export interface SpaceMetadata {
  'bookings.resource'?: {
    resource_ids: string[],
  },
  ui?: {
    name: string,
  },
}

export interface Space {
  uuid: string,
  spaceType: SpaceType,
  path?: string,
  parentPath?: string,
  subspaces: {
    [SpaceType.Access]?: Space[],
  },
  metadata?: SpaceMetadata,
}

export interface SpaceId {
  id?: string,
  path?: string,
  name?: string,
}

export const SpaceTypes: LatchSelectionItem[] = [
  { name: SpaceTypeDisplay[SpaceType.Building], value: SpaceType.Building, disabled: true },
  { name: SpaceTypeDisplay[SpaceType.Communal], value: SpaceType.Communal },
];
