import { KeyMembershipRole } from 'manager/services/key-membership/key-membership.service';
import moment from 'moment';
import { KeyOwnershipType, KeyType } from './key';
import { SyncStatusJSON } from './sync-status';

export enum AccessState {
  ASSIGNED = 'ASSIGNED',
  NEW_USER = 'NEW_USER',
  EXPIRED = 'EXPIRED'
}
export const ALL_ACCESS_STATES = [AccessState.ASSIGNED, AccessState.NEW_USER, AccessState.EXPIRED];
export const ACTIVE_ACCESS_STATES = [AccessState.ASSIGNED, AccessState.NEW_USER];

export const AccessStateDisplay: { [key in AccessState]: string } = {
  [AccessState.ASSIGNED]: 'Access assigned',
  [AccessState.NEW_USER]: 'New (no access)',
  [AccessState.EXPIRED]: 'Expired access',
};

export interface KeyMembership {
  uuid: string;
  keyUUID: string;
  startTime: Date;
  endTime?: Date;
  userUUID: string;
  type: KeyMembershipType;
  shareable: boolean;
  settings: {
    role: KeyMembershipRole;
  };
  hostUUID: string;
  userNickname?: string;
  // codes are only provided in certain cases - when creating a Daily or DailySingleUse type membership.
  codes?: Doorcode[];

  // Backend won't provide this, but mocks use it to simplify queries
  // (otherwise would have to go through Key -> Lock -> Building)
  buildingUUID?: string;

  // May be derived from keyUUID
  keyType?: KeyOwnershipType;
  keyName?: string;
  keyKeyType?: KeyType;

  // May be derived from userUUID
  userFirstName?: string;
  userLastName?: string;
  userPhone?: string;
  userEmail?: string;
  userSignupDate?: Date;

  // keyDoorMemberships is provided by the backend for elevator key memberships to describe which floor group is
  // associated with each elevator in the key.
  keyDoorMemberships?: KeyDoorMembership[];
}

export enum KeyMembershipType {
  Permanent = 'PERMANENT',
  Daily = 'DAILY',
  DailySingleUse = 'DAILY_SINGLE_USE'
}

export interface Doorcode {
  lockUUID: string;
  doorcode: string;
}

export interface CreateKeyMembershipOperationResultDoorcode {
  lockId: string;
  doorcode: string;
}

export interface CreateKeyMembershipOperationResult {
  keyId: string;
  userId: string;
  keyMembershipId: string;
  doorcodes: CreateKeyMembershipOperationResultDoorcode[];
  syncStatuses: SyncStatusJSON[];
}

export interface UpdateKeyMembershipOperationResult {
  keyId: string;
  keyMembershipId: string;
  userId: string;
  syncStatuses: SyncStatusJSON[];
}

export interface DeleteKeyMembershipOperationResult {
  keyId: string;
  keyMembershipId: string;
  userId: string;
  syncStatuses: SyncStatusJSON[];
}

export interface KeyDoorMembership {
  keyDoorMembershipUUID: string;
  keyDoorUUID: string;
  floorGroupUUID: string;
}

export const fromJSON = (json: any) => ({
  ...json,
  startTime: moment.unix(json.startTime).toDate(),
  endTime: json.endTime ?
    moment.unix(json.endTime).toDate() :
    null,
  userSignupDate: json.userSignupDate ?
    moment.unix(json.userSignupDate).toDate() :
    null
});
