import {
  AfterViewInit, Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LatchConfirmationDialogConfig, LatchDialogRef, LatchDialogService, LATCH_DIALOG_DATA } from '@latch/latch-web';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface AgreementModalData {
  title: string;
  subtitle?: string;
  disagreeMessage: string;
  agreement: Type<unknown> | string; // Component class | PDF file path
}

@Component({
  selector: 'latch-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('agreementContainer', { read: ViewContainerRef }) agreementContainer!: ViewContainerRef;

  agreementSafeUrl: SafeResourceUrl | undefined;
  isAgreeDisabled = true;

  get isComponentClass(): boolean {
    return this.data.agreement instanceof Type;
  }

  get isFilePath(): boolean {
    return typeof this.data.agreement === 'string';
  }

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(LATCH_DIALOG_DATA) public data: AgreementModalData,
    private dialogRef: LatchDialogRef,
    private dialog: LatchDialogService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.isFilePath) {
      this.agreementSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.data.agreement as string}#toolbar=0`);
      this.isAgreeDisabled = false;
    }
  }

  ngAfterViewInit() {
    if (this.isComponentClass) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.agreement as Type<unknown>);
      this.agreementContainer.createComponent(componentFactory);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  handleCancel() {
    this.dialogRef.close(false);
  }

  handleAgree() {
    this.dialogRef.close(true);
  }

  handleDisagree() {
    const config: LatchConfirmationDialogConfig = {
      maxWidth: '470px',
      data: {
        title: 'Cannot continue',
        messages: [this.data.disagreeMessage],
        primaryButtonText: 'Review Agreement',
        primaryButtonClasses: ['latch-dark'],
        secondaryButtonText: 'Not Now',
        secondaryButtonClasses: ['latch-button-link'],
      }
    };
    this.dialog.openConfirmation(config).afterClosed().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      if (!res) {
        this.dialogRef.close(false);
      }
    });
  }

  public onAgreementContainerScrollToEnd(scrolledToEnd: boolean): void {
    if (scrolledToEnd) {
      this.isAgreeDisabled = false;
    }
  }
}
