import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ManagerSharedModule } from './components/manager-shared.module';

import { ActiveOperationsToastComponent } from './components/active-operations-toast/active-operations-toast.component';
import { SupportPageComponent } from './components/support-page/support-page.component';

import { ModalComponent } from './components/modal/modal.component';
import { AppComponent } from './components/app.component';
import { ErrorPageComponent } from './components/error-page.component';

import { LoginComponent } from './components/auth/login.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password.component';

import { ConsoleComponent } from './components/console/console.component';
import { ActivityListPageComponent } from './components/activity/activity-list-page/activity-list-page.component';
import { DoorsActivityListPageComponent } from './components/activity/doors-activity-list-page/doors-activity-list-page.component';
import { InfoModalComponent } from './components/modal/info-modal.component';
import { KeysAnnouncementComponent } from './components/announcements/keys-announcement.component';
import { ViewAccountPageComponent } from './components/account/view-account-page.component';
import { AccountSwitcherComponent } from './components/account/account-switcher.component';
import { MfaFlowComponent } from './components/mfa/mfa-flow.component';
import { RequireMfaFlowComponent } from './components/mfa/require-mfa-flow/require-mfa-flow.component';
import { DeRequireMfaFlowComponent } from './components/mfa/de-require-mfa-flow/de-require-mfa-flow.component';
import { AccountService } from './services/account/account.service';
import { AuthService } from './services/auth/auth.service';
import { BuildingService } from './services/building/building.service';

import { FriendlyDatePipe } from './pipes/friendly-date.pipe';
import { AutoSubmitVerificationDirective } from './directives/auto-submit-verification.directive';
import { RequiredDirective } from './directives/required.directive';
import { ValidateEmailDirective } from './directives/validate-email.directive';
import { ValidatePhoneNumberDirective } from './directives/validate-phone-number.directive';
import { FailureDetailsComponent } from './components/console/failure-details/failure-details.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { UnitsListComponent } from './components/units/units-list-page/units-list-page.component';
import { CreateUnitPageComponent } from './components/units/create-unit-page/create-unit-page.component';
import { UnitDetailPageComponent } from './components/units/unit-detail-page/unit-detail-page.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DuplicatesComponent } from './components/duplicates/duplicates.component';
import { UnitsReorderPageComponent } from './components/units/units-reorder-page/units-reorder-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImportUnitsPageComponent } from './components/units/import-units-page/import-units-page.component';
import { CoreModule } from './core/core.module';
import { ActiveSessionsPageComponent } from './components/account/active-sessions-page/active-sessions-page.component';
import { RecentLoginsPageComponent } from './components/account/recent-logins-page/recent-logins-page.component';
import { DeviceDetailPageComponent } from './components/devices/device-detail-page/device-detail-page.component';
import { AppcuesService } from './services/appcues/appcues.service';
import { SprigService } from './services/sprig/sprig.service';
import { DeviceActivityListPageComponent } from './components/activity/device-activity-list-page/device-activity-list-page.component';
import { AppRoutingModule } from './app-routing.module';
import { IntercomSettingsComponent } from './components/intercom-settings/intercom-settings.component';
import { PmsAccessReviewPageComponent } from './components/pms-access-review/pms-access-review-page.component';
import { NotificationCenterComponent } from './components/console/notification-center/notification-center.component';
import { AddFeaturedEntryComponent } from './components/intercom-settings/add-featured-entry/add-featured-entry.component';
import {
  SelectFeaturedEntryCandidateComponent
} from './components/intercom-settings/select-featured-entry-candidate/select-featured-entry-candidate.component';
import {
  AddFeaturedEntryPhoneNumberComponent
} from './components/intercom-settings/add-featured-entry-phone-number/add-featured-entry-phone-number.component';
import { ViewFeaturedEntryComponent } from './components/intercom-settings/view-featured-entry/view-featured-entry.component';
import {
  ReorderFeaturedEntriesComponent
} from './components/intercom-settings/reorder-featured-entries/reorder-featured-entries.component';
import { RouterEventsService } from './core/services/router-events.service';
import { LatchDialogModule, LatchDropdownModule } from '@latch/latch-web';
import {
  DeliveryActivityListPageComponent
} from './components/deliveries/delivery-activity-list-page/delivery-activity-list-page.component';
import { DeliveryInfoPipe } from './pipes/delivery-info.pipe';
import { BaseModalComponent } from './components/modal/base-modal/base-modal.component';
import { DeliveryDetailsComponent } from './components/deliveries/delivery-details/delivery-details.component';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { PackagesActivityListPageComponent } from './components/packages/packages-activity-list-page/packages-activity-list-page.component';
import { DeliveryUnitInfoPipe } from './pipes/delivery-unit-info.pipe';
import { StaffPageComponent } from './components/staff-page/staff-page.component';
import { MfaEnrollmentToastComponent } from './components/mfa-enrollment-toast/mfa-enrollment-toast.component';
import { PackageModalComponent } from './components/packages/package-modal/package-modal.component';
import { PackageDetailsComponent } from './components/packages/package-details/package-details.component';
import { SendNotificationComponent } from './components/packages/send-notification/send-notification.component';
import { MessageModalComponent } from './components/packages/message-modal/message-modal.component';
import { LatchInsightsComponent } from './components/insights/latch-insights.component';
import { InsightCardComponent } from './components/insights/insight-card/insight-card.component';
import { ResourcesPageComponent } from './components/resources-page/resources-page.component';
import { KeysSharedModule } from './modules/keys/keys-shared.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { InitializeComponent } from './components/initialize/initialize.component';
import { GetHelpButtonComponent } from './components/get-help-button/get-help-button.component';
import { AccessPageComponent } from './components/access-page/access-page.component';

// Providers
import { ThemeService } from './services/theme/theme.service';

@NgModule({
  declarations: [
    ModalComponent,
    AppComponent,
    ErrorPageComponent,
    LoginComponent,
    ForgotPasswordComponent,
    MfaFlowComponent,
    RequireMfaFlowComponent,
    DeRequireMfaFlowComponent,
    ConsoleComponent,
    FriendlyDatePipe,
    RelativeDatePipe,
    AutoSubmitVerificationDirective,
    RequiredDirective,
    ValidateEmailDirective,
    ValidatePhoneNumberDirective,
    ViewAccountPageComponent,
    AccountSwitcherComponent,
    ActivityListPageComponent,
    DoorsActivityListPageComponent,
    InfoModalComponent,
    KeysAnnouncementComponent,
    ActiveOperationsToastComponent,
    MfaEnrollmentToastComponent,
    FailureDetailsComponent,
    SupportPageComponent,
    UnitsListComponent,
    CreateUnitPageComponent,
    UnitDetailPageComponent,
    UnitsReorderPageComponent,
    DuplicatesComponent,
    UnitsReorderPageComponent,
    ImportUnitsPageComponent,
    ActiveSessionsPageComponent,
    RecentLoginsPageComponent,
    DeviceDetailPageComponent,
    DeviceActivityListPageComponent,
    IntercomSettingsComponent,
    PmsAccessReviewPageComponent,
    NotificationCenterComponent,
    AddFeaturedEntryComponent,
    SelectFeaturedEntryCandidateComponent,
    AddFeaturedEntryPhoneNumberComponent,
    ViewFeaturedEntryComponent,
    ReorderFeaturedEntriesComponent,
    DeliveryActivityListPageComponent,
    DeliveryInfoPipe,
    BaseModalComponent,
    DeliveryDetailsComponent,
    LatchInsightsComponent,
    InsightCardComponent,
    PackagesActivityListPageComponent,
    DeliveryUnitInfoPipe,
    StaffPageComponent,
    AccessPageComponent,
    PackageModalComponent,
    PackageDetailsComponent,
    SendNotificationComponent,
    MessageModalComponent,
    ResourcesPageComponent,
    InitializeComponent,
    GetHelpButtonComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ManagerSharedModule,
    KeysSharedModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ScrollingModule,
    DragDropModule,
    LatchDropdownModule,
    CoreModule,
    YouTubePlayerModule,
    LatchDialogModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (theme: ThemeService) => () => theme.init(),
      deps: [ThemeService]
    },
    ThemeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private buildingService: BuildingService,
    private appcuesService: AppcuesService,
    private sprigService: SprigService,
    private routerEventsService: RouterEventsService,
  ) {
    this.accountService.initialize();
    this.buildingService.initialize();
    this.authService.initialize();
    this.appcuesService.initialize();
    this.sprigService.initialize();
    this.routerEventsService.initialize();
  }
}
