import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface fileUploadItem {
  name: string,
  size: number
}

@Component({
  selector: 'frame-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.scss']
})
export class FileUploadListComponent {
  @Input() fileList: fileUploadItem[] = [];
  @Output() filesSelected = new EventEmitter<File[]>();
  @Output() fileDeleted = new EventEmitter<number>();

  addFiles(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = Array.from(target.files ?? []);
    this.filesSelected.emit(files);
  }

  remove(index: number) {
    this.fileDeleted.emit(index);
  }
}
