import { Component } from '@angular/core';
import { LatchDialogRef } from '@latch/latch-web';
import { environment } from 'environments/interface';
import * as QRCode from 'qrcode';

@Component({
  selector: 'latch-door-app-popup',
  templateUrl: './door-app-popup.component.html',
  styleUrls: ['./door-app-popup.component.scss']
})
export class DoorAppPopupComponent {
  constructor(
    public dialogRef: LatchDialogRef,
  ) { }

  redirectToDoor() {
    window.open(environment.doorAppUrl, '_blank');
    this.dialogRef.close();
  }
}
