import { Injectable } from '@angular/core';
import { Partner } from 'manager/models/partner';
import { Observable, of } from 'rxjs';

export interface ListPartnersResponse {
  partners: Partner[],
  count: number
}

@Injectable()
export abstract class PartnerService {
  abstract getPartners(buildingUUID: string): Observable<ListPartnersResponse>;
}
