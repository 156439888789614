
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { Unit, UpdateUnitOperationResult } from '../../models/unit';
import { UnitService, CreateUnitInput, UpdateUnitInput, ReorderUnitInput, UnitDetailsResponse } from './unit.service';

import { AuthService } from '../auth/auth.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServiceResponse } from '../interfaces';
import { assertIsDefined } from '@latch/latch-web';
import { Person } from '../../models/contact-cards';

@Injectable()
export class HTTPUnitService extends UnitService {

  constructor(
    private authService: AuthService,
  ) {
    super();
  }

  getUnits(buildingUUID: string): Observable<Unit[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/units`
    }).pipe(
      map((response: HttpResponse<any>): Unit[] => {
        const jsonObjects = AuthService.getPayload(response);
        return jsonObjects.map((jsonObject: any) => Unit.fromJson(jsonObject));
      }),
      catchError((error) => this.handleError(error))
    );
  }

  getUnitDetails(unitUUID: string, buildingUUID: string): Observable<UnitDetailsResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/units/${unitUUID}`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error) => this.handleError(error))
    );
  }

  createUnit(input: CreateUnitInput): Observable<Unit> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/buildings/${input.buildingUUID}/units`,
      data: { name: input.name, localTelecom: input.localTelecom }
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      map((json) => Unit.fromJson(json)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  updateUnit(input: UpdateUnitInput): Observable<UpdateUnitOperationResult> {
    const data = {
      name: input.name,
      localTelecom: input.localTelecom
    };
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/units/${input.id}`,
      data
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  reorderUnit(input: ReorderUnitInput): Observable<Unit> {
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v1/units/${input.id}`,
      data: { place: input.position }
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  deleteUnit(unitUUID: number): Observable<ServiceResponse> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/units/${unitUUID}`
    }).pipe(
      map((): ServiceResponse => ({
        success: true
      })),
      catchError(error => this.handleError(error))
    );
  }

  getUnitResidents(buildingUUID: string, unitId: number): Observable<Person[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v3/buildings/${buildingUUID}/people`,
      search: {
        page: '1',
        pageSize: '100',
        unitId: unitId.toString()
      }
    }).pipe(
      map(response => AuthService.getPayload(response).people),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if ((error instanceof HttpErrorResponse) && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}
