import { Injectable } from '@angular/core';
import {
  ContactCardService,
  CreateContactCardResponse,
  GetPeopleInput,
  PaginatedPeopleResponse,
  EditContactCardInput,
  CreateContactCardInput
} from './contact-card.service';
import { Observable, throwError, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { fromJSON } from '../../models/key-membership';
import { CustomQueryEncoderHelper } from '../../../shared/services/query-encoder';
import { ServiceResponse } from '../interfaces';
import { Person, ContactOnlyPerson } from '../../models/contact-cards';

@Injectable()
export class HTTPContactCardService extends ContactCardService {
  constructor(
    private authService: AuthService
  ) {
    super();
  }

  createContactCard(input: CreateContactCardInput):
    Observable<CreateContactCardResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v2/people',
      data: input
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  deleteContactCard(personUUID: string, keepKeyCards?: boolean): Observable<void> {
    let search = new HttpParams();
    if (keepKeyCards) {
      search = search.append('keepKeyCards', keepKeyCards);
    }
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v2/people/${personUUID}`,
      data: {},
      search
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError((error: Error) => this.handleError(error))
    );
  }

  getPersonForUser(buildingUUID: string, userUUID: string): Observable<Person> {
    // Note that this and getContactOnlyPersonForUser are actually hitting the same endpoint with
    // different query params.
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/people/${userUUID}`
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response))
    );
  }

  getContactOnlyPersonForUser(buildingUUID: string, userUUID: string): Observable<ContactOnlyPerson> {
    // Note that this and getPersonForUser are actually hitting the same endpoint with different
    // query params. I chose to structure it this way as a very simple way to get more specific
    // return type information for each version of the endpoint.
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUUID}/people/${userUUID}`,
      search: new HttpParams().set('includeKeyMembershipInfo', 'false')
    }).pipe(
      map((response: HttpResponse<any>) => AuthService.getPayload(response)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const message = AuthService.getPayload(error);
          if (message === 'CONTACT_CARD_NOT_FOUND' && error.status === 400) {
            // LMC-3763 This error occurs when a contact card is manually deactivated from admin tool
            return of({
              personUUID: userUUID,
              userUUID: userUUID,
              userFirstName: 'User Unavailable'
            });
          }
        }
        return throwError(error);
      })
    );
  }

  getPeople(buildingId: string, input: GetPeopleInput): Observable<PaginatedPeopleResponse> {
    const endpoint = `/web/v4/buildings/${buildingId}/people`;

    const search = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: input as any
    });

    return this.authService.request({
      method: 'get',
      endpoint,
      search,
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      tap((paginatedResponse) => paginatedResponse.people = paginatedResponse.people.map(fromJSON)),
      catchError((error: Error) => this.handleError(error)),
    );
  }

  editContactCard(personUUID: string, input: EditContactCardInput[]): Observable<ServiceResponse> {
    return this.authService.request({
      method: 'patch',
      endpoint: `/web/v1/people/${personUUID}`,
      data: {
        patchOperations: input
      }
    }).pipe(
      map(response => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse && error.status < 500) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}
