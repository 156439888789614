import { Component, Input } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  template: ''
})
export class BaseChartComponent {
  @Input()
  public margins: [number, number, number, number] = [0, 0, 0, 0];

  @Input()
  public view: [number, number] = [0, 0];

  @Input()
  public colorScheme = 'black';

  private schemes: Color[] = [
    {
      name: 'green',
      selectable: true,
      domain: [
        '#2ef32e',
        '#f3e9e9',
      ],
      group: ScaleType.Linear,
    },
    {
      name: 'orange',
      selectable: true,
      domain: [
        '#ffcb37',
        '#f3e9e9',
      ],
      group: ScaleType.Linear,
    },
    {
      name: 'black',
      selectable: true,
      domain: [
        '#1c1c1c',
        '#f1f1f1',
      ],
      group: ScaleType.Linear,
    },
    {
      name: 'white',
      selectable: true,
      domain: [
        '#ffffff',
        '#404040',
      ],
      group: ScaleType.Linear,
    }];

  public get scheme() {
    return this.schemes.find(s => s.name === this.colorScheme) || this.schemes[0];
  }
}
