import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private static currencyPipe = new CurrencyPipe('en-US');

  private static getCurrency(currency?: string): string {
    return currency || 'USD';
  }

  private static getFormat(decimal?: boolean): string {
    return decimal ? '1.2-2' : '1.0-2';
  }

  /**
   * Convert amount in cents to formatted string
   * @param amount Amount in cents
   * @param currency Currency string, defaults to USD
   * @param decimal Optional boolean to always show decimal places
   * @returns Formatted amount string
   */
  static getAmountString(amount: number, currency?: string, decimal?: boolean): string {
    return this.currencyPipe.transform(
      this.toDecimal(amount),
      this.getCurrency(currency),
      undefined,
      this.getFormat(decimal)) || '';
  }

  /**
   * Sum multiple amounts
   * @param amounts Array of amounts in cents
   * @returns Amount in cents
   */
  static sumAmounts(...amounts: number[]): number {
    return amounts.reduce((a, b) => a + b, 0);
  }

  /**
   * Convert amount in cents to amount in dollars
   * @param amount Amount in cents
   * @returns Amount in dollars
   */
  static toDecimal(amount: number): number {
    return amount / 100;
  }

  /**
   * Convert amount in dollars to cents
   * @param amount Amount in dollars
   * @returns Amount in cents
   */
  static toFull(amount: number): number {
    return Math.round(amount * 100);
  }
}
