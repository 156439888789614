import { Injectable } from '@angular/core';
import { CheckinService } from './checkin.service';
import { Observable, throwError } from 'rxjs';
import {
  Floor,
  DocumentId,
  Tenant,
  Guest,
  Host,
  Invite,
  TenantLease,
  RestrictedPerson,
  PaginatedResponse,
  TenantSetting
} from 'manager/models/checkin';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { from } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import moment from 'moment';
import { environment } from 'environments/interface';
import { FeatureService } from '../appstate/feature.service';
import { BuildingFeature } from 'manager/models/building';
import { isDefined } from '@latch/latch-web';
import { Paging } from 'manager/modules/checkin/components/latch-pager/latch-pager.component';
import { GuestInviteStatus } from 'manager/modules/checkin/utility/visitor-utility';

interface Media {
  id: string,
  contentType: string,
  putUrl: string,
}

interface BackendError {
  errors: [
    {
      source: string,
      code: string,
      message: string,
    }
  ]
}

@Injectable()
export class HTTPCheckinService extends CheckinService {

  constructor(
    private authService: AuthService,
    private featureService: FeatureService,
    private http: HttpClient,
  ) {
    super();
  }

  getGuests(
    buildingUUID: string,
    searchTerm: string,
    date: Date,
    includeCheckedin: boolean,
    sortNameAscending: boolean,
    statusFilters?: GuestInviteStatus[],
  ): Observable<Guest[]> {
    let search = new HttpParams()
      .append('searchTerm', searchTerm.trim())
      .append('includeCheckedIn', includeCheckedin ? 'true' : 'false')
      .append('start', '0')
      .append('limit', '100')
      .append('startDateEpoch', `${moment(date).startOf('day').unix()}`)
      .append('endDateEpoch', `${moment(date).endOf('day').unix()}`)
      .append('sortOrder', sortNameAscending ? 'asc' : 'desc');

    if (statusFilters && statusFilters.length > 0) {
      search = search.delete('includeCheckedIn');
      statusFilters.forEach(statusFilter => {
        search = search.append('statusFilters', statusFilter);
      });
    }

    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/guests`,
      search
    }).pipe(
      map((response: HttpResponse<Guest[]>): Guest[] => AuthService.getPayload(response).elements),
      catchError(error => this.handleError(error))
    );
  }

  getInvites(
    buildingUUID: string,
    searchTerm: string,
    date: Date,
    includeCheckedin: boolean,
    sortNameAscending: boolean,
    statusFilters?: GuestInviteStatus[],
  ): Observable<Invite[]> {
    let search = new HttpParams()
      .append('searchTerm', searchTerm.trim())
      .append('includeCheckedIn', includeCheckedin ? 'true' : 'false')
      .append('start', '0')
      .append('limit', '100')
      .append('startDateEpoch', `${moment(date).startOf('day').unix()}`)
      .append('endDateEpoch', `${moment(date).endOf('day').unix()}`)
      .append('sortOrder', sortNameAscending ? 'asc' : 'desc');

    if (statusFilters && statusFilters.length > 0) {
      search = search.delete('includeCheckedIn');
      statusFilters.forEach(statusFilter => {
        search = search.append('statusFilters', statusFilter);
      });
    }

    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites`,
      search
    }).pipe(
      map((response: HttpResponse<Invite[]>): Invite[] => AuthService.getPayload(response).elements),
      map(invites => invites.filter(invite => invite.groupName)),
      catchError(error => this.handleError(error))
    );
  }

  getVisitor(
    buildingUUID: string,
    uuid: string,
    inviteUUID: string,
    startDateEpoch: number,
    endDateEpoch: number
  ): Observable<Invite> {
    return this.featureService.hasFeature(BuildingFeature.CommercialInviteGroup).pipe(
      take(1),
      switchMap(showGroups => {
        if (showGroups) {
          const search = new HttpParams()
            .append('startDateEpoch', startDateEpoch.toString())
            .append('endDateEpoch', endDateEpoch.toString());
          return this.authService.request({
            method: 'get',
            endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/${inviteUUID}`,
            search
          }).pipe(
            map((response: HttpResponse<Invite>): Invite => AuthService.getPayload(response)),
            catchError(error => this.handleError(error)),
          );
        } else {
          // for backwards compatibility
          const search = new HttpParams()
            .append('startDateEpoch', `${moment().startOf('day').unix()}`)
            .append('endDateEpoch', `${moment().startOf('day').add(7, 'days').unix()}`);
        return this.authService.request({
          method: 'get',
          endpoint: `/web/v1/commercial/buildings/${buildingUUID}/guests/${uuid}`,
          search
        }).pipe(
          map((response: HttpResponse<Guest>): Invite => {
            const { activeInvites, ...guest }: Guest & { activeInvites: Invite[] } = AuthService.getPayload(response);
            const invite = activeInvites.find(v => v.uuid === inviteUUID);
            if (invite) {
              return {
                ...invite,
                guests: [guest],
              };
            } else {
              throw new Error('Could not find Invite.');
            }
          }),
          catchError(error => this.handleError(error))
        );
        }
      })
    );
  }

  getVisitorQrUrl(buildingUUID: string, invite: Invite, guest: Guest): Observable<string> {
    const url = `${environment.apiBaseUrl}/web/v1/commercial/buildings/${buildingUUID}/invites/${invite.uuid}/guests/${guest.uuid}/qrcode`;
    // had to skip the auth service as there is no way to get a blob
    return this.http.get(url, { responseType: 'blob' }).pipe(
      switchMap(qrcode => new Observable<string>(obs => {
          const reader = new FileReader();
          reader.onerror = err => obs.error(err);
          reader.onabort = err => obs.error(err);
          reader.onload = () => obs.next(reader.result as string);
          reader.onloadend = () => obs.complete();
          reader.readAsDataURL(qrcode);
        })
      ),
    );
  }

  findVisitorByQr(buildingUUID: string, qr: string): Observable<Guest> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/scan`,
      data: {
        qr
      },
    }).pipe(
      map((response: HttpResponse<Guest>): Guest => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  inviteVisitor(buildingUUID: string, invite: Partial<Invite>): Observable<Invite> {
    const newInvite = {
      tenantUUID: invite.tenant?.uuid,
      floorUUID: invite.floor?.uuid,
      invitees: invite.guests?.map(g => ({
        firstName: g.firstName,
        lastName: g.lastName,
        email: g.email?.trim().toLowerCase(),
        phoneNumber: g.phoneNumber?.trim().toLowerCase(),
      })),
      hostEmail: invite.host?.email?.trim().toLowerCase(),
      startDateEpoch: invite.startDateEpoch,
      endDateEpoch: invite.endDateEpoch,
      groupName: invite.groupName,
      notifications: {
        notifications: invite.notifications?.notifications,
        recipients: invite.notifications?.recipients.map(r => r.email),
      },
      guestInstructions: invite.guestInstructions,
      lobbyGreeterInstructions: invite.lobbyGreeterInstructions,
      recurrence: invite.recurrence,
    };
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites`,
      data: newInvite,
    }).pipe(
      map((response: HttpResponse<Guest>): Invite => {
        const { activeInvites, ...guest }: Guest & { activeInvites: Invite[] } = AuthService.getPayload(response);
        // TODO backend should send the active invite just created
        const responseInvite = (activeInvites ?? []).find(v => v.uuid === invite.uuid);
        if (responseInvite) {
          return {
            ...responseInvite,
            guests: [guest],
          };
        } else {
          return {
            guests: [guest],
          } as Invite;
        }
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 400 && error.error) {
          const backendError: BackendError = error.error;
          return throwError(backendError.errors.reduce(
            (prev: { [index: string]: string }, curr) => {
              prev[curr.source] = curr.message;
              return prev;
            },
            {}
          ));
        }
        return this.handleError(error);
      })
    );
  }

  updateInvite(buildingUUID: string, invite: Invite): Observable<Invite> {
    const newInvite = {
      tenantUUID: invite.tenant.uuid,
      floorUUID: invite.floor?.uuid,
      invitees: invite.guests?.map(g => ({
        uuid: g.uuid,
        firstName: g.firstName,
        lastName: g.lastName,
        email: g.email?.trim().toLowerCase(),
        phoneNumber: g.phoneNumber?.trim().toLowerCase(),
      })),
      hostEmail: invite.host?.email?.trim().toLowerCase(),
      startDateEpoch: invite.startDateEpoch,
      endDateEpoch: invite.endDateEpoch,
      notifications: {
        notifications: invite.notifications?.notifications,
        recipients: invite.notifications?.recipients.map(r => r.email),
      },
      guestInstructions: invite.guestInstructions,
      lobbyGreeterInstructions: invite.lobbyGreeterInstructions,
      recurrence: invite.recurrence,
    };
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/${invite.uuid}`,
      data: newInvite,
    }).pipe(
      map((response: HttpResponse<Guest>): Invite => {
        const { activeInvites, ...guest }: Guest & { activeInvites: Invite[] } = AuthService.getPayload(response);
        // TODO backend should send the active invite just created
        const responseInvite = (activeInvites ?? []).find(v => v.uuid === invite.uuid);
        if (responseInvite) {
          return {
            ...responseInvite,
            guests: [guest],
          };
        } else {
          return {
            guests: [guest],
          } as Invite;
        }
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 400 && error.error) {
          const backendError: BackendError = error.error;
          return throwError(backendError.errors.reduce(
            (prev: { [index: string]: string }, curr) => {
              prev[curr.source] = curr.message;
              return prev;
            },
            {}
          ));
        }
        return this.handleError(error);
      })
    );
  }

  deleteInvite(buildingUUID: string, inviteUUID: string): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/${inviteUUID}`,
    }).pipe(
      map(() => null),
      catchError(error => this.handleError(error))
    );
  }

  replacePass(buildingUUID: string, invite: Invite, guest: Guest): Observable<null> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/${invite.uuid}/guests/${guest.uuid}/replacePass`,
      data: {},
    }).pipe(
      map(() => null),
      catchError(error => this.handleError(error))
    );
  }

  /**
   * Uploads an image to the visitor, to achieve that we first call ../media to get a AWS presigned url where the image is
   * uploaded to, after successfuly uploading we update the visitor with the media id and receive as a result the updated
   * visitor
   */
  saveImage(buildingUUID: string, uuid: string, image: Blob): Observable<Guest> {
    return this.authService.request({
      method: 'post',
      endpoint: '/web/v1/commercial/media',
      data: {
        contentType: image.type,
        buildingUUID
      }
    }).pipe(
      switchMap((mediaResponse: HttpResponse<Media>) => {
        const media: Media = AuthService.getPayload(mediaResponse);
        return from(fetch(media.putUrl, { body: image, method: 'PUT' })).pipe(
          switchMap(() => this.authService.request({
            method: 'put',
            endpoint: `/web/v1/commercial/buildings/${buildingUUID}/guests/${uuid}/image`,
            data: {
              mediaEntry: media.id
            }
          }).pipe(
            map((response: HttpResponse<Guest>): Guest => AuthService.getPayload(response))
          ))
        );
      }),
      catchError(error => this.handleError(error))
    );
  }

  saveDocumentId(buildingUUID: string, uuid: string, documentId: DocumentId): Observable<Guest> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/guests/${uuid}/document`,
      data: documentId,
    }).pipe(
      map((response: HttpResponse<Guest>): Guest => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  sendEmail(buildingUUID: string, invite: Invite, guest: Guest): Observable<null> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/invites/send`,
      data: { guestId: guest.uuid, inviteId: invite.uuid }
    }).pipe(
      map(() => null),
      catchError(error => this.handleError(error))
    );
  }

  getFloors(buildingUUID: string, tenant: Tenant): Observable<Floor[]> {
    const search = new HttpParams()
      .append('tenantId', tenant.uuid);
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/floors`,
      search
    }).pipe(
      map((response: HttpResponse<Floor>): Floor[] => AuthService.getPayload(response).floors),
      catchError(error => this.handleError(error))
    );
  }

  getTenants(buildingUUID: string): Observable<Tenant[]> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants`,
    }).pipe(
      map((response: HttpResponse<Tenant>): Tenant[] => AuthService.getPayload(response).tenants),
      catchError(error => this.handleError(error))
    );
  }

  getHosts(
    buildingUUID: string,
    tenant: Tenant,
    searchTerm: string,
    paging?: Paging,
    sortAsc?: boolean
  ): Observable<PaginatedResponse<Host>> {
    const pageSize = paging?.pageSize ?? 100;
    const start = paging?.page ?? 0;
    const search = new HttpParams()
    .append('searchTerm', searchTerm.trim())
    .append('start', start.toString())
    .append('limit', pageSize.toString())
    .append('sortOrder', (sortAsc ?? true) ? 'ASC ': 'DESC');

    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenant.uuid}/hosts`,
      search
    }).pipe(
      map((response: HttpResponse<any>): PaginatedResponse<Host> => {
        const responseBody = AuthService.getPayload(response);
        const hosts = responseBody.elements;
        const page: Paging = {
          totalPages: responseBody.totalPages,
          totalElements: responseBody.totalElements,
          page: responseBody.page,
          pageSize: responseBody.pageSize
        };
        const result = {
          data: hosts,
          pagingDetails: page
        };

        return result;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addNewHost(buildingUUID: string, tenantUUID: string, host: Host): Observable<Host> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenantUUID}/hosts`,
      data: host
    }).pipe(
      map((response: HttpResponse<Host>): Host => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateHost(buildingUUID: string, tenantUUID: string, host: Host): Observable<Host> {
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenantUUID}/hosts`,
      data: host
    }).pipe(
      map((response: HttpResponse<Host>): Host => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  deleteHost(buildingUUID: string, tenantUUID: string, hostUUID: string): Observable<null> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenantUUID}/hosts/${hostUUID}`
    }).pipe(
      map(() => null),
      catchError(error => this.handleError(error))
    );
  }

  addNewTenant(buildingUUID: string, tenant: Tenant): Observable<Tenant> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants`,
      data: tenant
    }).pipe(
      map((response: HttpResponse<Tenant>): Tenant => {
        const tenantData = AuthService.getPayload(response);
        return tenantData;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addNewLease(buildingUUID: string, lease: TenantLease): Observable<TenantLease> {
    const request = {
      tenantUUID: lease.tenantUUID,
      leaseStartDate: moment(lease.leaseStartDate).unix(),
      leaseEndDate: moment(lease.leaseEndDate).unix(),
      floors: lease.floors,
      settings: []
    };
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/leases`,
      data: request
    }).pipe(
      map((response: HttpResponse<TenantLease>): TenantLease => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getTenant(buildingUUID: string, tenantUUID: string): Observable<Tenant> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenantUUID}`
    }).pipe(
      map((response: HttpResponse<Tenant>): Tenant => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateTenant(buildingUUID: string, tenant: Tenant): Observable<Tenant> {
    const req = {
      name: tenant.name
    };

    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/tenants/${tenant.uuid}`,
      data: req
    }).pipe(
      map((response: HttpResponse<Tenant>): Tenant => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateLease(buildingUUID: string, lease: TenantLease): Observable<TenantLease> {
    if (!isDefined(lease) || !isDefined(lease.uuid)) {
      throw new Error('Only existing lease can be updated.');
    }

    const settings: any[] = [];
    if (lease.settings) {
      lease.settings.forEach(s => settings.push({ name: s.name, value: s.value.toString() }));
    }
    const request = {
      leaseStartDate: lease.leaseStartDate,
      leaseEndDate: lease.leaseEndDate,
      floors: lease.floors,
      settings: settings
    };

    return this.authService.request(
      {
        method: 'put',
        endpoint: `/web/v1/commercial/buildings/${buildingUUID}/leases/${lease.uuid}`,
        data: request
      }
    ).pipe(
      map((response: HttpResponse<TenantLease>): TenantLease => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getLease(buildingUUID: string, leaseUUID: string): Observable<TenantLease> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/leases/${leaseUUID}`
    }).pipe(
      map((response: HttpResponse<TenantLease>) => {
        const data = AuthService.getPayload(response);
        const settings: TenantSetting[] = [];
        if (data.settings) {
          for (let i = 0; i < data.settings.length; i++) {
            settings.push({ name: data.settings[i].name, value: data.settings[i].value.toLowerCase() === 'true' });
          }
        }

        const parsedData = Object.assign({}, data, { settings: settings });
        return parsedData;
      }),
      catchError(error => this.handleError(error))
    );
  }

  addNewPersonToWatchlist(buildingUUID: string, person: RestrictedPerson): Observable<RestrictedPerson> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/watchlist`,
      data: person
    }).pipe(
      map((response: HttpResponse<RestrictedPerson>): RestrictedPerson => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  updateWatchlistedPerson(buildingUUID: string, person: RestrictedPerson): Observable<RestrictedPerson> {
    if (!isDefined(person) || !isDefined(person.uuid)) {
      throw new Error('Only existing person can be updated.');
    }

    const req = {
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      reason: person.reason
    };
    return this.authService.request({
      method: 'put',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/watchlist/${person.uuid}`,
      data: req
    }).pipe(
      map((response: HttpResponse<RestrictedPerson>): RestrictedPerson => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  deleteWatchlistedPerson(buildingUUID: string, personUUID: string): Observable<boolean> {
    return this.authService.request({
      method: 'delete',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/watchlist/${personUUID}`
    }).pipe(
      map((response) => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  getWatchlistedPersons(
    buildingUUID: string,
    tenantUUID?: string,
    paging?: Paging,
    sortAsc?: boolean
  ): Observable<PaginatedResponse<RestrictedPerson>> {
    let search = new HttpParams()
      .append('sortAsc', (sortAsc ?? true).toString())
      .append('page', (paging?.page ?? 0).toString())
      .append('pageSize', (paging?.pageSize ?? 100).toString());

    if (tenantUUID !== undefined) {
      search = search.append('tenantUUID', tenantUUID ?? '');
    }
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/watchlist`,
      search
    }).pipe(
      map((response: HttpResponse<any>): PaginatedResponse<RestrictedPerson> => {
        const responseBody = AuthService.getPayload(response);
        const persons = responseBody.elements;
        const page: Paging = {
          totalPages: responseBody.totalPages,
          totalElements: responseBody.totalElements,
          page: responseBody.page,
          pageSize: responseBody.pageSize
        };
        const result = {
          data: persons,
          pagingDetails: page
        };

        return result;
      }),
      catchError(error => this.handleError(error))
    );
  }

  getWatchlistedPerson(buildingUUID: string, personUUID: string): Observable<RestrictedPerson> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/commercial/buildings/${buildingUUID}/watchlist/${personUUID}`
    }).pipe(
      map((response: HttpResponse<RestrictedPerson>): RestrictedPerson => AuthService.getPayload(response)),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      const message = AuthService.getPayload(error);
      return throwError(new Error(message));
    } else {
      return throwError(error);
    }
  }
}
