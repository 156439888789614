import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScrolledToEndDirective } from './directives/scrolled-to-end.directive';
import { LatchWebModule } from './latch-web.module';
import { DateTimeInputComponent } from 'manager/components/date-time/date-time-input.component';
import { ResetTimeDirective } from 'manager/components/date-time/reset-time.directive';
import { SorterUIComponent } from 'manager/components/table/sorter-ui.component';
import { PieChartComponent } from 'manager/components/chart/pie-chart.component';
import { LineChartComponent } from 'manager/components/chart/line-chart.component';
import { ImportCsvComponent } from 'manager/components/import-csv/import-csv.component';
import { ImportCsvResultComponent } from 'manager/components/import-csv/import-csv-result/import-csv-result.component';
import { ImportCsvResultPageComponent } from 'manager/components/import-csv/import-csv-result/import-csv-result-page.component';
import { TimeRangeInputComponent } from 'manager/components/date-time/time-range-input.component/time-range-input.component';
import { OperationProgressPageComponent } from './components/operation-progress-page/operation-progress-page.component';
import { UpgradePopupComponent } from './components/upgrade-popup/upgrade-popup.component';
import { LatchIconMessageDialogComponent } from './components/icon-message-dialog/icon-message-dialog.component';
import { ScrollArrowsComponent } from './components/scroll-arrows/scroll-arrows.component';

@NgModule({
  imports: [
    LatchWebModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxChartsModule,
  ],
  declarations: [
    LoadingBarComponent,
    AutofocusDirective,
    ScrolledToEndDirective,
    DateTimeInputComponent,
    TimeRangeInputComponent,
    ResetTimeDirective,
    SorterUIComponent,
    PieChartComponent,
    LineChartComponent,
    ImportCsvComponent,
    ImportCsvResultComponent,
    ImportCsvResultPageComponent,
    OperationProgressPageComponent,
    UpgradePopupComponent,
    LatchIconMessageDialogComponent,
    ScrollArrowsComponent,
  ],
  exports: [
    LatchWebModule,
    TextMaskModule,
    LoadingBarComponent,
    AutofocusDirective,
    ScrolledToEndDirective,
    DateTimeInputComponent,
    TimeRangeInputComponent,
    ResetTimeDirective,
    SorterUIComponent,
    PieChartComponent,
    LineChartComponent,
    ImportCsvComponent,
    ImportCsvResultComponent,
    ImportCsvResultPageComponent,
    OperationProgressPageComponent,
    UpgradePopupComponent,
    LatchIconMessageDialogComponent,
    ScrollArrowsComponent,
  ],
  providers: []
})
export class SharedModule { }
