import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LatchAnalyticsService, LatchSelectionItem } from '@latch/latch-web';
import { RouterEventsService } from 'manager/core/services/router-events.service';
import { InsightItem, InsightResponse } from 'manager/models/insights';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { SelectedAccountService } from 'manager/services/appstate/selected-account.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { InsightsService } from 'manager/services/insights/insights.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PlatformService } from 'shared/services/platform.service';

@Component({
  selector: 'latch-insights',
  templateUrl: './latch-insights.component.html',
  styleUrls: ['./latch-insights.component.scss']
})
export class LatchInsightsComponent implements OnInit, OnDestroy {

  public expanded = true;

  public lastRefreshText = '';

  public timeRangeData: LatchSelectionItem[] = [];
  public selectedTimeRange = '';

  public hasInsightsFeature = false;

  public showInsights = false;

  public data!: InsightResponse;

  public insightItems: InsightItem[] = [];

  private selectedBuildingUUID!: string;

  private selectedAccountUUID!: string;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private insightsService: InsightsService,
    private selectedBuildingService: SelectedBuildingsService,
    private selectedAccountService: SelectedAccountService,
    private featureService: FeatureService,
    private routerEventsService: RouterEventsService,
    private analyticsService: LatchAnalyticsService,
    private platformService: PlatformService,
  ) {
    this.showInsights = this.shouldShowInsights();
  }

  ngOnInit(): void {
    // start collapsed on mobile
    this.expanded = !this.platformService.isMobile;
    this.routerEventsService.getNavigationEnd().pipe(
      skip(1),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.showInsights = this.shouldShowInsights();
      this.expanded = false;
    });

    this.selectedBuildingService.getSelectedBuildings().pipe(
      tap(buildings => this.selectedBuildingUUID = buildings[0].uuid),
      switchMap(() => this.featureService.hasInsightsFeature$),
      tap((hasInsightsFeature) => {
        this.hasInsightsFeature = hasInsightsFeature;
        this.showInsights = this.shouldShowInsights();
      }),
      switchMap(() => this.selectedAccountService.getSelectedAccount()),
      tap(selectedAccount => this.selectedAccountUUID = selectedAccount.uuid),
      switchMap(() => this.hasInsightsFeature ? this.getInsights() : EMPTY),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggle(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.analyticsService.track('Insights Panel - Expanded');
    } else {
      this.analyticsService.track('Insights Panel - Collapsed');
    }
  }

  public onHyperlinkClick(item: InsightItem): void {
    this.expanded = false;
    this.analyticsService.track('Insights Panel - Hyperlink Click', {
      'Insight Title': item.title,
      'Insight Hyperlink': item.reportHyperlinkText,
    });
  }

  public onSelectedTimeRangeChange(timeRange: string): void {
    this.analyticsService.track('Insights Panel - Update Time Range', {
      'Time Range': timeRange,
    });
  }

  private getInsights(): Observable<InsightResponse> {
    return this.insightsService.getInsights({
      accountUUID: this.selectedAccountUUID,
      buildingUUID: this.selectedBuildingUUID,
    }).pipe(
      tap((response: InsightResponse) => {
        this.data = response;
        this.lastRefreshText = response.dataRefreshedDate;
        this.insightItems = [...response.timeseries, ...response.instantValues];
        this.timeRangeData = response.timeRanges.map(timeRange => ({
          name: timeRange,
          value: timeRange,
        }));
        this.selectedTimeRange = response.timeRanges[0];
      }),
    );
  }

  private shouldShowInsights(): boolean {
    const url = this.router.routerState.snapshot.url;
    if (!this.hasInsightsFeature) {
      return false;
    }
    const baseUrl = url.split('?')[0];
    return baseUrl === '/console/people' ||
      baseUrl === '/console/doors' ||
      baseUrl === '/console/keys' ||
      baseUrl.startsWith('/console/access') ||
      baseUrl.startsWith('/console/activity') ||
      baseUrl.startsWith('/console/reports') ||
      baseUrl === '/console/leases';
  }
}
