import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Lock, Accessibility, LockShortInfo } from '../../models/lock';
import { ServiceResponse } from '../interfaces';
import { switchMap, filter, concatMap, toArray, tap } from 'rxjs/operators';
import { IntercomShortInfo } from '../intercom/intercom.service';
import { VirtualIntercom } from '../../models/intercom';

export const LOCK_EXISTS_IN_BUILDING = 'LOCK_EXISTS_IN_BUILDING';

export interface CreateLockInput {
  buildingUUID: string;
  name: string;
  accessibility: Accessibility;
  /**
   * This property is available for buildings that support the intercom feature
   * If hasIntercom is true, an intercom is created and associated with this lock.
   * If it’s not defined, no intercom will be created.
   */
  hasIntercom?: true;
  /**
   * This property is available for buildings that support the intercom feature
   * If hasVirtualIntercom is true, a Door Link is created and associated with this lock.
   * If it’s not defined, no Door Link will be created.
   */
  hasVirtualIntercom?: true;
  /**
   * This property is available for Door Links that have a physically printed QR code
   */
  virtualIntercomCode?: string;
}

export interface UpdateLockInput {
  uuid: string;
  name: string;
  scheduleUUID: string
  accessibility: Accessibility;
  /**
   * This property is available for buildings that support the intercom feature.
   * If hasIntercom is true, an intercom is created and associated with this lock.
   * If hasIntercom is false the associated intercom with this lock is removed.
   * If it’s not defined, no intercom will be created nor removed.
   */
  hasIntercom?: boolean;
  /**
   * This property is available for buildings that support the intercom feature.
   * If hasVirtualIntercom is true, a Door Link is created and associated with this lock.
   * If hasVirtualIntercom is false the associated Door Link with this lock is removed.
   * If it’s not defined, no intercom will be created nor removed.
   */
  hasVirtualIntercom?: boolean;
  /**
   * This property is available for Door Links that have a physically printed QR code
   */
  virtualIntercomCode?: string;
  /**
   * This property is available for buildings that support one or many Partner features.
   * If the partnerUUID is non-null then the partner association for this door will be created
   * If the partnerUUID is null then the partner association will be removed
   * If it's not defined, then the partner association will be removed
   */
  partnerUUID?: string;
  partnerUUIDs?: string[];
}

// Update lock response does not include a full Lock object. If we used Lock.fromJSON on it, we'd
// end up with a Lock instance that claims to be more of a source of truth than it really should.
export interface UpdateLockOutput {
  uuid: string;
  buildingUUID: string;
  name: string;
  accessibility: Accessibility;
  intercom: IntercomShortInfo | null;
  virtualIntercom: VirtualIntercom | null;
  partnerUUIDs: string[];
}

@Injectable()
export abstract class LockService {
  /**
   * Get all locks' short info in the given building. The short info contains some minimal info along with
   * "earliest deadline SyncTaskStatusView".
   *
   * This API is better for the cases where the earliest deadline SyncTaskStatusView information is important
   * and the other details of the lock which are missing here aren't that much important.
   */
  abstract getAllLocksShortInfo(buildingUUID: string): Observable<LockShortInfo[]>;

  /**
   * Get all locks in the given building.
   *
   * If no buildings are specified (buildingUUIDs is an empty list), all locks accessible by this account will be returned.
   */
  abstract getAllLocks(buildingUUIDs: string[]): Observable<Lock[]>;

  /**
   * Get the details from the lock specified by lockUUID
   *
   * Lock returned by this method will contain the fully populated data structure.
   */
  abstract getLockDetails(lockUUID: string, buildingUUID?: string): Observable<Lock>;

  abstract getLocksBulk(lockUUIDs: string[]): Observable<Lock[]>;

  abstract createLock(input: CreateLockInput): Observable<Lock>;

  /**
   * Creates all the locks specified by input, ignoring any locks in input
   * for which a lock of the same name already exists in the specified building.
   * @param input Array of locks to be created
   * @param buildingUUID Locks' corresponding building uuid
   * @returns Observable that emits newly created locks excluding skipped ones
   */
  public createLocks(input: CreateLockInput[], buildingUUID: string): Observable<Lock[]> {
    if (input.length === 0) {
      return of([]);
    }

    let existingLocks: Lock[];
    return this.getAllLocks([buildingUUID]).pipe(
      tap(allLocks => existingLocks = allLocks),
      switchMap(() => input),
      filter(lock =>
        !!lock.name &&
        existingLocks.every(existingLock =>
          existingLock.name.toLowerCase() !== lock.name.toLowerCase()
        )
      ),
      concatMap(lock => this.createLock(lock)),
      toArray()
    );
  }

  abstract updateLock(input: UpdateLockInput): Observable<UpdateLockOutput>;

  abstract deleteLock(lockUUID: string): Observable<ServiceResponse>;

  abstract exportAccessLogs(): Observable<string>;

}
