import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CourierNamesResponse,
  DeliveriesFilter,
  DeliveryListResponse,
  DeliveryUnitListResponse,
  DeliveryUnitsFilter,
  NotifyResidentRequest,
  NotifyResidentResponse
} from '../../models/delivery';
import { AuthService } from '../auth/auth.service';
import { DeliveryService } from './delivery.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HTTPDeliveryService extends DeliveryService {

  constructor(
    private authService: AuthService
  ) {
    super();
  }

  getDeliveries(buildingUuid: string, filter: DeliveriesFilter): Observable<DeliveryListResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-requests`,
      search: {
        page: filter.page.toString(),
        pageSize: filter.pageSize.toString(),
        sort: filter.sort,
        q: filter.search
      }
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  getDeliveryUnits(buildingUuid: string, filter: DeliveryUnitsFilter): Observable<DeliveryUnitListResponse> {
    const search: Record<string, string | string[]> = {
      page: filter.page.toString(),
      pageSize: filter.pageSize.toString(),
      sort: filter.sort,
      q: filter.search,
      unitIds: filter.units.map(element => element.toString()),
      packageTypes: filter.packageTypes,
      couriers: filter.couriers
    };
    if (filter.deliveryDateTimeFrom) {
      search.deliveryDateTimeStart = filter.deliveryDateTimeFrom.toString();
    }
    if (filter.deliveryDateTimeTo) {
      search.deliveryDateTimeEnd = filter.deliveryDateTimeTo.toString();
    }
    return this.authService.request({
      method: 'get',
      endpoint: `/web/v1/buildings/${buildingUuid}/delivery-request-units`,
      search
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }

  getCourierNames(): Observable<CourierNamesResponse> {
    return this.authService.request({
      method: 'get',
      endpoint: '/web/v1/courierNames'
    }).pipe(
      map((response: HttpResponse<any>): CourierNamesResponse => AuthService.getPayload(response))
    );
  }

  notifyResidents(
    buildingUUID: string,
    deliveryRequestId: string,
    notifyResidents: NotifyResidentRequest
  ): Observable<NotifyResidentResponse> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/buildings/${buildingUUID}/delivery-requests/${deliveryRequestId}/notify`,
      data: notifyResidents
    }).pipe(
      map(response => AuthService.getPayload(response))
    );
  }
}
