import { Injectable } from '@angular/core';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';

import { Account, AccountPermissions } from '../../models/account';
import { AccountService } from '../account/account.service';
import { ErrorStatus, AppError } from '../appstate/error-handler.service';
import { environment } from 'environments/interface';
import { map, switchMap, take } from 'rxjs/operators';
import { logInfo } from '@latch/latch-web';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Tells which account is currently selected by the user.
 *
 * Right now we don't really handle multiple accounts - this service will return the first from the user's list of accounts.
 */
@Injectable({ providedIn: 'root' })
export class SelectedAccountService {

  selectedAccount!: Account;

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  /**
   * Returns the account currently selected by the user.
   */
  getSelectedAccount(): Observable<Account> {
    return combineLatest([
      this.accountService.getAccounts().pipe(),
      this.activatedRoute.queryParams.pipe(
        take(1),
        map(({ building }) => building as string | undefined),
      )
    ]).pipe(
      switchMap(([accounts, selectedBuildingUUID]) => {
        const buildingAccount = accounts.find(account => account.buildings?.find(b => b.uuid === selectedBuildingUUID));
        if (buildingAccount) {
          this.selectedAccount = buildingAccount;
          return of(this.selectedAccount);
        } else if (selectedBuildingUUID) {
          this.router.navigate(['/console/403'], { queryParamsHandling: 'preserve' });
          return EMPTY;
        }

        // First, check to see if the user has set a cookie to specify which account to choose - if the user wants to do
        // this, they should:
        // localStorage.setItem('selected_account', 'some-unique-part-of-uuid');
        const selectedUUIDString = localStorage.getItem('selected_account');
        if (selectedUUIDString) {
          const selectedAccount = accounts.find(account => account.uuid.includes(selectedUUIDString));
          if (selectedAccount) {
            this.selectedAccount = selectedAccount;
            return of(this.selectedAccount);
          }
        }

        // If the user has not specified an account via a cookie, check to see if this environment specifies a default
        // account, and return it if that account is available for this user.
        const selectAccount = environment.selectAccount;
        if (selectAccount) {
          const selectedAccount = accounts.find(account => account.uuid.includes(selectAccount));
          if (selectedAccount) {
            this.selectedAccount = selectedAccount;
            return of(this.selectedAccount);
          }
        }

        // If no cookie is set and no account is set for this environment, or if either of the above are true but the
        // selected account is not available for this user, fall back to the first account in the list.
        if (accounts.length > 0) {
          this.selectedAccount = accounts[0];
          return of(this.selectedAccount);
        }

        logInfo('No accounts returned by accounts endpoint');
        throw new AppError(ErrorStatus.Permission, AppError.UserHasNoAccount);
      }));
  }

  selectAccount(accountUUID: string) {
    localStorage.setItem('selected_account', accountUUID);
  }

}
