import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AutocompleteFilterItem,
  CheckboxListFilterItem,
  DateTimeFilterItem,
  FilterItem,
  LatchDatasource,
  LatchNavbarStateService,
  LatchSelectionItem,
  LatchSortDescriptor
} from '@latch/latch-web';
import { isEqual } from 'manager/services/utility/utility';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImageSource } from '../../../models/access-log';
import { Building } from '../../../models/building';
import {
  ClosePackagesModal,
  DeliveryUnitList,
  DeliveryUnitsFilter,
  MessageModalType,
  PackageModalType,
  PackageType
} from '../../../models/delivery';
import { Unit } from '../../../models/unit';
import { PageWithTabsService } from '../../../services/appstate/page-with-tabs.service';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { DeliveryService } from '../../../services/delivery/delivery.service';
import { UnitService } from '../../../services/units/unit.service';

const PAGE_START_DEFAULT = 1;
const PAGE_SIZE_DEFAULT = 20;

const sendNotificationsSuccessMessage = 'Notifications sent';

const packageTypeSelect: LatchSelectionItem[] = [
  { value: PackageType.PARCEL, name: 'Parcel' },
  { value: PackageType.PERISHABLE_ITEM, name: 'Perishable item' },
  { value: PackageType.MEDICAL_ITEM, name: 'Medical item' },
  { value: PackageType.LARGE_PACKAGE, name: 'Large package' },
  { value: PackageType.OTHER, name: 'Other' }
];
@Component({
  selector: 'latch-packages-activity-list-page',
  templateUrl: './packages-activity-list-page.component.html',
  styleUrls: ['./packages-activity-list-page.component.scss']
})
export class PackagesActivityListPageComponent implements OnInit, OnDestroy {
  public deliveryUnits: DeliveryUnitList[] = [];
  public filter: DeliveryUnitsFilter = {
    page: PAGE_START_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
    search: '',
    sort: '',
    units: [],
    couriers: [],
    packageTypes: []
  };
  public nextPage: number | null = null;
  public datasource = new LatchDatasource<DeliveryUnitList>({});
  public ImageSource = ImageSource;
  public units!: Unit[];
  public couriers: string[] = [];
  public packageModalType?: PackageModalType;
  public viewDeliveryUnit?: DeliveryUnitList;
  public messageModalType?: MessageModalType;
  public message?: string;

  private selectedBuilding!: Building;
  private deliveryUnitsSubscription?: Subscription;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private latchNavbarStateService: LatchNavbarStateService,
    private selectedBuildingsService: SelectedBuildingsService,
    private deliveryService: DeliveryService,
    private pageWithTabsService: PageWithTabsService,
    private unitService: UnitService
  ) { }

  ngOnInit(): void {
    this.latchNavbarStateService.initializeSearch({ placeholder: 'Search Packages' });

    combineLatest([
      this.selectedBuildingsService.getSelectedBuildings(),
      this.deliveryService.getCourierNames()
    ]).pipe(
      tap(([buildings, couriersResponse]) => {
        this.selectedBuilding = buildings[0];
        this.couriers = couriersResponse.courierNames;
      }),
      switchMap(() => this.unitService.getUnits(this.selectedBuilding.uuid)),
      takeUntil(this.unsubscribe$),
    ).subscribe(units => {
      this.units = units;
      this.initializeFilters();
      this.setPage(PAGE_START_DEFAULT);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openPackageModal(packageModalType: PackageModalType, deliveryUnit: DeliveryUnitList): void {
    this.packageModalType = packageModalType;
    this.viewDeliveryUnit = deliveryUnit;
  }

  closePackageModal(closePackageModal: ClosePackagesModal): void {
    this.packageModalType = undefined;
    this.viewDeliveryUnit = undefined;
    if (closePackageModal === 'sendNotificationsSuccess') {
      this.messageModalType = 'success';
      this.message = sendNotificationsSuccessMessage;
    }
  }

  closeMessageModal(): void {
    this.messageModalType = undefined;
    this.message = undefined;
  }

  setPage(page: number) {
    this.filter.page = page;
    this.getDeliveryUnits(this.selectedBuilding.uuid, this.filter);
  }

  notifyResidents(event: Event, deliveryUnit: DeliveryUnitList): void {
    event.stopPropagation();
    this.openPackageModal('sendNotification', deliveryUnit);
  }

  private initializeFilters(): void {
    const filterItems: FilterItem[] = [];
    const deliveryDateTimeFrom: DateTimeFilterItem = {
      type: 'date-time',
      label: 'Delivery date from',
      field: 'deliveryDateTimeFrom'
    };
    const deliveryDateTimeTo: DateTimeFilterItem = {
      type: 'date-time',
      label: 'Delivery date to',
      field: 'deliveryDateTimeTo'
    };
    const unitsAutocomplete: AutocompleteFilterItem = {
      type: 'autocomplete',
      label: 'Units',
      field: 'units',
      data: this.units.map(unit => ({
        name: unit.name,
        value: unit.id
      })),
      placeholder: 'Unit name'
    };
    const couriersAutocomplete: AutocompleteFilterItem = {
      type: 'autocomplete',
      label: 'Couriers',
      field: 'couriers',
      data: this.couriers.map((courier: string) => ({
        name: courier,
        value: courier
      })),
      placeholder: 'Courier name'
    };
    const packageTypes: CheckboxListFilterItem = {
      type: 'checkbox-list',
      label: 'Package Type',
      field: 'packageTypes',
      data: packageTypeSelect
    };
    filterItems.push(
      deliveryDateTimeFrom,
      deliveryDateTimeTo,
      unitsAutocomplete,
      couriersAutocomplete,
      packageTypes
    );
    this.latchNavbarStateService.initializeFilter(filterItems);
    this.handleFiltersChange();
    this.handleSearchChange();
    this.handleSortChange();
  }

  private handleFiltersChange(): void {
    this.latchNavbarStateService.getFilterValueChange().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      skip(1),
      takeUntil(this.unsubscribe$)
    ).subscribe(stateFilter => {
      this.filter.couriers = stateFilter.couriers as string[] || [];
      this.filter.units = stateFilter.units as number[] || [];
      this.filter.packageTypes = stateFilter.packageTypes as PackageType[] || [];
      this.filter.deliveryDateTimeFrom = stateFilter.deliveryDateTimeFrom ?
        (stateFilter.deliveryDateTimeFrom as Date).getTime() : undefined;
      this.filter.deliveryDateTimeTo = stateFilter.deliveryDateTimeTo ? (stateFilter.deliveryDateTimeTo as Date).getTime() : undefined;
      this.setPage(PAGE_START_DEFAULT);
    });
  }

  private handleSearchChange(): void {
    this.latchNavbarStateService.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(search => {
      this.filter.search = search;
      this.setPage(PAGE_START_DEFAULT);
    });
  }

  private handleSortChange(): void {
    this.datasource.sortChange().pipe(
      distinctUntilChanged(),
      filter(sort => !!sort.active),
      takeUntil(this.unsubscribe$)
    ).subscribe(sort => {
      if (sort.direction) {
        this.filter.sort = this.getSortValue(sort);
      } else {
        this.filter.sort = '';
      }
      this.setPage(PAGE_START_DEFAULT);
    });
  }

  private getSortValue(sort: LatchSortDescriptor): string {
    return `${sort.active}:${sort.direction.toUpperCase()}`;
  }

  private getDeliveryUnits(buildingUuid: string, deliveryUnitsFilter: DeliveryUnitsFilter): void {
    this.datasource.startLoading();
    if (this.deliveryUnitsSubscription) {
      this.deliveryUnitsSubscription.unsubscribe();
    }
    this.deliveryUnitsSubscription = this.deliveryService.getDeliveryUnits(buildingUuid, deliveryUnitsFilter).pipe(
      finalize(() => this.datasource.stopLoading()),
      takeUntil(this.unsubscribe$)
    ).subscribe(deliveriesListReponse => {
      this.nextPage = deliveriesListReponse.metadata.page < deliveriesListReponse.metadata.totalPages ?
        deliveriesListReponse.metadata.page + 1 : null;
      if (deliveryUnitsFilter.page === 1) {
        this.deliveryUnits = [];
      }
      if (deliveriesListReponse.elements && deliveriesListReponse.elements.length > 0) {
        deliveriesListReponse.elements.forEach(a => this.deliveryUnits.push(a));
      }
      this.datasource.set(this.deliveryUnits);
      this.updateSubnavSubtitle(deliveriesListReponse.metadata.totalElements);
    });
  }

  private updateSubnavSubtitle(totalDeliveryUnits: number) {
    this.pageWithTabsService.setSubnavSubtitle(totalDeliveryUnits ? `(${totalDeliveryUnits})` : '');
  }

}
