import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'frame-avatar-presentation',
  templateUrl: './avatar-presentation.component.html',
  styleUrls: ['./avatar-presentation.component.scss']
})
export class AvatarPresentationComponent implements OnInit {
  @Input() photos: any[] = [];
  @Input() urlProperty!: string;

  selectedPhoto: any;

  ngOnInit(): void {
    if (!this.urlProperty) {
      throw new Error('urlProperty input is missing');
    }

    if (this.photos.length) {
      this.selectedPhoto = this.photos[0];
    }
  }
}
