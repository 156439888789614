import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { AccountService } from '../../services/account/account.service';
import { SelectedAccountService } from '../../services/appstate/selected-account.service';
import { Account, AccountPermissions } from '../../models/account';
import { ErrorHandlerService } from '../../services/appstate/error-handler.service';
import { Router } from '@angular/router';
import { LatchAnalyticsConstants, LatchAnalyticsService, UserMfaSettings, LatchMfaService, User } from '@latch/latch-web';
import { Observable, zip } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ConstantsService } from 'shared/services/constants.service';

enum Flow {
  None,
  Enable,
  Require,
  DeRequire,
  TryAgainSoon,
  Support,
  ChangePhone,
}

enum SupportFlow {
  AccountRecovery,
  ChangePhoneNumber,
}

interface SupportFlowTexts {
  header: string;
  body: string;
}

@Component({
  selector: 'latch-mfa-flow',
  templateUrl: './mfa-flow.component.html',
  styleUrls: ['./mfa-flow.component.scss']
})

export class MfaFlowComponent implements OnInit {
  currentFlow: Flow = Flow.None;
  supportFlow: SupportFlow = SupportFlow.AccountRecovery;
  previousFlow: Flow = Flow.None;
  Flow = Flow;
  user: User | null = null;
  isLoading = false;
  userMfaSettings!: UserMfaSettings;
  selectedAccount!: Account;
  requiredForAnyAccounts = false;
  private isOwner = false;

  get supportFlowTexts(): SupportFlowTexts {
    return {
      header: this.supportFlow === SupportFlow.AccountRecovery ? 'Request account recovery' :
        'Request Phone Number Change for Authentication Purposes',
      body: this.supportFlow === SupportFlow.AccountRecovery ?
        'Contact Latch Customer Support at support@latch.com to request account recovery.' :
        `To prevent unauthorized changes to your account, you must first un-enroll and re-enroll in Multi-Factor Authentication (MFA).
        Property managers can only unenroll if your portfolio manager has not enforced MFA. If your property manager has enforced MFA and
        you cannot authenticate your identity, contact support@latch.com for guidance.`,
    };
  }

  constructor(
    private mfaService: LatchMfaService,
    private authService: AuthService,
    private accountService: AccountService,
    private analyticsService: LatchAnalyticsService,
    private selectedAccountService: SelectedAccountService,
    private errorHandlerService: ErrorHandlerService,
    private router: Router,
    private constants: ConstantsService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.user = this.authService.currentUser;

    zip(
      this.mfaService.getUserMFASettings(),
      this.selectedAccountService.getSelectedAccount(),
      this.accountService.getAccounts(),
    ).subscribe(
      ([settings, acct, accts]: [UserMfaSettings, Account, Account[]]) => {
        this.userMfaSettings = settings;
        this.selectedAccount = acct;
        // check if there are ANY accounts for this user that have mfa required
        this.requiredForAnyAccounts = accts.some(account => account.mfa?.smsRequired === true);

        this.isOwner = this.checkIfOwner(this.selectedAccount.permissions);
        if (this.userMfaSettings.mfaEnabled) {
          this.isLoading = false;
          this.goToSettingsPage();
        } else {
          this.currentFlow = Flow.Enable;
          this.toggleMfa(true);
        }
      },
      (err) => {
        this.errorHandlerService.handleException(err);
      }
    );
  }

  get isAccountOwner() {
    return this.isOwner;
  }

  private checkIfOwner(accts: AccountPermissions[]): boolean {
    return accts.includes(AccountPermissions.CreateAdministrator);
  }

  public get mailtoLink(): string {
    // Any update to the copy here should probably also update the similar copy found in LoginComponent.
    // TODO: Unify these to be defined in one single place.
    if (this.supportFlow === SupportFlow.AccountRecovery) {
      return 'mailto:support@latch.com?subject=Account%20Recovery%20Request&body=Hello%20Latch%20Support%2C%0A%0A' +
        'I%20am%20unable%20to%20gain%20access%20to%20my%20Latch%20Manager%20account' +
        '%20with%20two-factor%20authentication%20account%20recovery.%0A%0A' +
        'Please%20contact%20me%20at%20your%20earliest%20convenience.%0A%0AThank%20you%2C%0A%0A' + (this.user?.firstName ?? '');
    }
    return 'mailto:support@latch.com?subject=Phone%20Number%20Change%20Request&body=Hello%20Latch%20Support%2C%0A%0A' +
      'I%20would%20like%20to%20change%20my%20phone%20number%20for%20two-factor%20authentication%20.%0A%0A' +
      'Please%20contact%20me%20at%20your%20earliest%20convenience.%0A%0AThank%20you%2C%0A%0A' + (this.user?.firstName ?? '');
  }

  disableMfa(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.toggleMfa(false);
  }

  changePhone(e?: Event) {
    if (e) {
      e.preventDefault();
    }

    // If the user has MFA enforced they are taken to a different page where they can initiate phone number change.
    // Users who are not enforced by the portfolio can either contact support or disable then re-enable MFA to reset it.
    if (this.requiredForAnyAccounts) {
      this.currentFlow = Flow.ChangePhone;
    } else {
      this.currentFlow = Flow.Support;
      this.supportFlow = SupportFlow.ChangePhoneNumber;
    }
  }

  requireMfaForAccount(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.currentFlow = Flow.Require;
  }

  deRequireMfaForAccount(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.currentFlow = Flow.DeRequire;
  }

  exit(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.router.navigate(['/console/account'], { queryParamsHandling: 'preserve' });
  }

  onTryAgain(e: Event) {
    e.preventDefault();
    this.previousFlow = this.currentFlow;
    this.currentFlow = Flow.TryAgainSoon;
  }

  exitTryAgain(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.currentFlow = this.previousFlow;
  }

  onDone(e?: Event) {
    this.accountService.clearCache();
    zip(
      this.mfaService.getUserMFASettings(),
      this.selectedAccountService.getSelectedAccount(),
      this.accountService.getAccounts()
    )
      .subscribe(
        ([settings, acct, accts]: [UserMfaSettings, Account, Account[]]) => {
          this.userMfaSettings = settings;
          this.selectedAccount = acct;
          // check if there are ANY accounts for this user that have mfa required
          this.requiredForAnyAccounts = accts.some(account => account.mfa?.smsRequired === true);
          this.goToSettingsPage();
        },
        (err) => {
          this.errorHandlerService.handleException(err);
        });

    if (e) {
      e.preventDefault();
    }
  }

  goToSettingsPage(e?: Event) {
    this.currentFlow = Flow.None;
    if (e) {
      e.preventDefault();
    }
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, { [LatchAnalyticsConstants.PageName]: 'Two-Factor Auth Settings' });
  }

  onLost(e?: Event) {
    if (e) {
      e.preventDefault();
    }
    this.currentFlow = Flow.Support;
    this.supportFlow = SupportFlow.AccountRecovery;
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Two-Factor Auth Request Account Recovery'
    });
  }

  public initiateChangePhone(): void {
    this.isLoading = true;
    this.authService.loginWithRedirect({ set_mfa: false }).pipe(
      finalize(() => this.isLoading = false),
    ).subscribe(() => {
      localStorage.setItem(this.constants.MfaReset, 'true');
    });
  }

  private toggleMfa(mfaValue: boolean): Observable<unknown> {
    return this.authService.loginWithRedirect({ set_mfa: mfaValue }).pipe(
      catchError(err => {
        this.errorHandlerService.handleException(err);
        return err;
      }),
    );
  }
}
