import { Component, OnInit, OnDestroy } from '@angular/core';
import { DedupingResponse, DedupingResponseErrorMessages, Duplicate } from 'manager/models/user';
import { finalize, map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { DuplicatesService } from 'manager/services/duplicates/duplicates.service';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { Subject, timer } from 'rxjs';
import { Building, PmsSourceDisplay } from 'manager/models/building';
import { isDefined, LatchDatasource, LatchDialogService, LatchNavAction, LatchNavbarStateService } from '@latch/latch-web';
import moment from 'moment';

enum Tab {
  NameMismatch = 'Name Mismatch',
  EmailMismatch = 'Email Mismatch',
}

interface PropertyDifference {
  fullName: boolean;
  email: boolean;
  phoneNumber: boolean;
}

interface DuplicateWithDifferences extends Duplicate {
  difference: PropertyDifference;
  result?: {
    success: boolean;
    message: string;
  }
}

@Component({
  selector: 'latch-duplicates',
  templateUrl: './duplicates.component.html',
  styleUrls: ['./duplicates.component.scss']
})
export class DuplicatesComponent implements OnInit, OnDestroy {
  selectedDuplicates: DuplicateWithDifferences[] = [];
  activeTab = Tab.NameMismatch;
  Tab = Tab;
  public maxSelectedItems = 50;

  actions: LatchNavAction[] = [];
  public datasource = new LatchDatasource<DuplicateWithDifferences>({});

  public isLoading = false;
  public duplicates: DuplicateWithDifferences[] = [];
  public filteredDuplicates: DuplicateWithDifferences[] = [];
  private building!: Building;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private duplicatesService: DuplicatesService,
    private selectedBuildingService: SelectedBuildingsService,
    private navbarStateService: LatchNavbarStateService,
    private dialogService: LatchDialogService,
  ) { }

  get subTitle() {
    return this.building.pmsUpdateTime ? `Last updated ${moment(this.building.pmsUpdateTime).format('M/D/YY, h:mma')}` : '';
  }

  getLastUpdated(duplicate: DuplicateWithDifferences): string {
    return duplicate.lastUpdated ? moment.unix(duplicate.lastUpdated).format('MM/DD/YY, h:mma') : '';
  }

  updateActions(): void {
    if (this.activeTab === Tab.NameMismatch) {
      this.actions = [{
        id: 'merge-accounts',
        name: 'Merge Accounts',
        clickHandler: () => this.mergeAccounts(),
        disabled: !this.selectedDuplicates?.length,
      }];
    } else {
      this.actions = [{
        id: 'create-separate',
        name: 'Create Separate',
        clickHandler: () => this.createSeparate(),
        disabled: !this.selectedDuplicates?.length,
      }];
    }
  }

  onTabClick(tabId: string) {
    this.activeTab = tabId as Tab;
    this.updateDataSource(this.duplicates);
  }

  updateDataSource(rows: DuplicateWithDifferences[]) {
    switch (this.activeTab) {
      case Tab.NameMismatch:
        this.filteredDuplicates = rows.filter(r => r.pmsDuplicate.email.toLowerCase() === r.latchDuplicate.email.toLowerCase());
        break;
      case Tab.EmailMismatch:
        this.filteredDuplicates = rows.filter(r => r.pmsDuplicate.email.toLowerCase() !== r.latchDuplicate.email.toLowerCase());
        break;
    }
    const searchTerm = this.navbarStateService.searchControl.value;
    this.filteredDuplicates = this.filteredDuplicates.filter(d =>
      `
      ${d.pmsDuplicate.fullName}
      ${d.pmsDuplicate.email}
      ${d.pmsDuplicate.phoneNumber}
      ${d.latchDuplicate.fullName}
      ${d.latchDuplicate.email}
      ${d.latchDuplicate.phoneNumber}
      `.toLowerCase().includes(searchTerm.toLowerCase() ?? '')
    );
    this.datasource.set(this.filteredDuplicates);
    this.onSelectDuplicates([]);
  }

  ngOnInit() {
    this.navbarStateService.initializeSearch({
      placeholder: 'Search Duplicates',
    });

    this.navbarStateService.searchControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      () => this.updateDataSource(this.duplicates)
    );

    const getDuplicates = (buildingUUID: string) => this.duplicatesService.getDuplicates(buildingUUID).pipe(
      tap(({ rows }) => {
        this.duplicates = rows.map(duplicate => ({
          ...duplicate,
          difference: {
            fullName: duplicate.latchDuplicate.fullName !== duplicate.pmsDuplicate.fullName,
            email: duplicate.latchDuplicate.email.toLowerCase() !== duplicate.pmsDuplicate.email.toLowerCase(),
            phoneNumber: duplicate.latchDuplicate.phoneNumber !== duplicate.pmsDuplicate.phoneNumber,
          }
        }));
        // change to Email Mismatch tab if there are no Name Mismatch duplicates
        if (rows.length > 0 && rows.filter(r => r.pmsDuplicate.email.toLowerCase() === r.latchDuplicate.email.toLowerCase()).length === 0) {
          this.activeTab = Tab.EmailMismatch;
        }
        this.updateDataSource(this.duplicates);
        this.isLoading = false;
      }),
      finalize(() => this.isLoading = false),
    );

    this.selectedBuildingService.getSelectedBuildings().pipe(
      tap(() => this.isLoading = true),
      map(selectedBuldings => selectedBuldings[0]),
      tap(building => {
        this.building = building;
      }),
      switchMap(building => getDuplicates(building.uuid)),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  public getPmsSourceDisplay(): string {
    const pmsSource = this.building.pmsSource;
    return isDefined(pmsSource) ? PmsSourceDisplay[pmsSource] : '';
  }

  public onSelectDuplicates(duplicates: DuplicateWithDifferences[]) {
    if (duplicates.length > this.maxSelectedItems) {
      duplicates = duplicates.splice(0, this.maxSelectedItems);
    }
    this.selectedDuplicates = duplicates;
    this.updateActions();
  }

  mergeAccounts(): void {
    this.dialogService.openConfirmation({
      data: {
        title: 'Confirmation',
        messages: [
          `Your ${this.getPmsSourceDisplay()} account data will overwrite your Latch Manager account data. ` +
          `${this.selectedDuplicates.length} account${ this.selectedDuplicates.length === 1 ? '' : 's' } will be merged.`
        ],
        primaryButtonText: 'Merge Accounts'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        const duplicateIds = this.selectedDuplicates.map(duplicate => duplicate.duplicateUUID);
        this.duplicatesService.mergeAccounts(this.building.uuid, duplicateIds).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(
          (res) => this.markDedupedItemsDone(res, true)
        );
      }
    });

  }

  createSeparate(): void {
    this.dialogService.openConfirmation({
      data: {
        title: 'Confirmation',
        messages: [
          `Separate accounts will be created using your ${this.getPmsSourceDisplay()} account data. ` +
          `${this.selectedDuplicates.length} account${ this.selectedDuplicates.length === 1 ? '' : 's' } will be created.`
        ],
        primaryButtonText: 'Create Separate Accounts'
      },
    }).afterClosed().subscribe(result => {
      if (result) {
        this.isLoading = true;
        const duplicateIds = this.selectedDuplicates.map(duplicate => duplicate.duplicateUUID);
        this.duplicatesService.createSeparate(this.building.uuid, duplicateIds).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(
          (res) => this.markDedupedItemsDone(res, false)
        );
      }
    });
  }

  private markDedupedItemsDone(dedupingResponse: DedupingResponse, isMerge: boolean): void {
    const { successfulUUIDs, failedUUIDs, failureDetails } = dedupingResponse;
    this.duplicates.forEach((duplicate) => {
      if (successfulUUIDs.includes(duplicate.duplicateUUID)) {
        duplicate.result = {
          success: true,
          message: isMerge ? 'Successfully merged' : 'Successfully created separate account(s)',
        };
        timer(5000).subscribe(() => {
          this.duplicates.splice(this.duplicates.findIndex(d => d === duplicate), 1);
          this.updateDataSource(this.duplicates);
        });
      }

      if (failedUUIDs.includes(duplicate.duplicateUUID)) {
        duplicate.result = {
          success: false,
          message: isMerge ? 'Merge failed' : 'Create separate failed',
        };
        if (failureDetails?.[duplicate.duplicateUUID] && duplicate.result.message) {
          duplicate.result.message =
            DedupingResponseErrorMessages.get(failureDetails?.[duplicate.duplicateUUID]) ?? duplicate.result.message;
        }
      }
    });
  }
}
