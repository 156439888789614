<latch-card [headerTitle]="data.title" class="page fixed" (latchScrolledToEnd)="onAgreementContainerScrollToEnd($event)">
  <ng-container latchCardActions>
    <button class="latch-button-icon" (click)="handleCancel()">
      <span class="latch-icon-close"></span>
    </button>
  </ng-container>

  <ng-container latchCardContent>
    <div class="modal-body">
      <p *ngIf="data.subtitle">{{data.subtitle}}</p>
      <div class="agreement-container" [class.component-container]="isComponentClass"
        (latchScrolledToEnd)="onAgreementContainerScrollToEnd($event)">
        <ng-template #agreementContainer></ng-template>
        <object *ngIf="agreementSafeUrl" [data]="agreementSafeUrl" type="application/pdf">
          <div class="unsupported-object">
            <p>Your browser doesn't support PDFs.<br />
              Click <a [href]="data.agreement" download>here</a> to download the agreement.</p>
          </div>
        </object>
      </div>
      <p>By clicking Agree below, you agree that this constitutes your electronic signature and that your electronic
        signature will have the same legal effect as a physical signature.</p>
    </div>
  </ng-container>

  <ng-container latchCardFooter>
    <div class="modal-footer">
      <button class="latch-button-link" (click)="handleDisagree()">Disagree</button>
      <button class="latch-button" [disabled]="isAgreeDisabled" (click)="handleAgree()">Agree</button>
    </div>
  </ng-container>
</latch-card>
