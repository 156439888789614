import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserConsentAction, UserConsentKey, UserConsentStatus, UserCredential, UserNotificationInfo } from '../../models/user';

export const IE_303_ERROR = 'IE_303_ERROR';
export const INVALID_EMAIL_ERROR = 'INVALID_EMAIL';
export const INVALID_PHONE_ERROR = 'Phone number is invalid';

export interface GetUserNotificationSettingsResponse {
  settings: UserNotificationInfo[];
}

export interface UserConsentResponse {
  consentKey: UserConsentKey;
  status: UserConsentStatus;
  userId: string;
}

/**
 * Provides metadata about specific users given their UUID.
 */
@Injectable()
export abstract class UserService {
  abstract getUserCredential(userUUID: string, buildingUUID: string): Observable<UserCredential>;

  /**
   * Activate keycard with the serial number for the given user.
   */
  abstract activateKeycard(userUUID: string, keycardSerialNumber: string): Observable<void>;

  /**
   * Deactivate keycard for the given user.
   */
  abstract deactivateKeycard(userUUID: string, keycardSerialNumber: string): Observable<void>;

  /**
   * Gets information about the current user's notification preferences
   * Boolean value for given notification type represents if user is opted OUT of that notification
   */
  abstract getNotificationSettings(): Observable<UserNotificationInfo[]>;

  /**
   * Updates user's notification settings with new @param settings
   * Note that boolean values in settings represent whether user is opted OUT of the notification or not
   */
  abstract updateNotificationSettings(settings: UserNotificationInfo[]): Observable<UserNotificationInfo[]>;

  /**
   * Gets information about the current user's consent state for consent key @param key
   */
  abstract getUserConsent(key: UserConsentKey): Observable<UserConsentResponse>;

  /**
   * Updates current user's consent state for consent key @param key using consent action @param action
   */
  abstract updateUserConsent(key: UserConsentKey, action: UserConsentAction): Observable<UserConsentResponse>;
}
