import { Pipe, PipeTransform } from '@angular/core';
import { InsightTimeseriesValue } from 'manager/models/insights';
import { LineChartData } from '../chart/chart';

@Pipe({
  name: 'insightLineChartData',
})
export class InsightLineChartDataPipe implements PipeTransform {

  transform(value: InsightTimeseriesValue, name?: string): LineChartData[] {
    return [{
      name: name ?? '',
      series: value.buckets.map(item => ({
        name: item.formattedDatetime,
        value: item.value.toString(),
      })),
    }];
  }

}
