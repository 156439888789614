import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccessLogService, SearchAccessLogParams } from './access-log.service';
import { SelectedAccountService } from '../appstate/selected-account.service';
import { AuthService } from '../auth/auth.service';
import { PagedResponse } from '../interfaces';

import { AccessLog, accessLogFromJSON, VisualAccessLogMetadata } from '../../models/access-log';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map, switchMap, take } from 'rxjs/operators';
import { FeatureService } from '../appstate/feature.service';

@Injectable()
export class HTTPAccessLogService extends AccessLogService {

  constructor(
    private selectedAccountService: SelectedAccountService,
    private authService: AuthService,
    private featureService: FeatureService,
  ) {
    super();
  }

  getAccessLogs(searchParams: SearchAccessLogParams): Observable<PagedResponse<AccessLog>> {
    let search = new HttpParams();

    if (searchParams.buildingUUIDs && searchParams.buildingUUIDs.length) {
      searchParams.buildingUUIDs.forEach(buildingUUID => search = search.append('buildingUUIDs', buildingUUID));
    }

    if (searchParams.guestUUIDs && searchParams.guestUUIDs.length) {
      searchParams.guestUUIDs.forEach(userUUID => search = search.append('guestUUIDs', userUUID));
    }

    if (searchParams.lockUUIDs && searchParams.lockUUIDs.length) {
      searchParams.lockUUIDs.forEach(lockUUID => search = search.append('lockUUIDs', lockUUID));
    }

    if (searchParams.startTime) {
      const startDateEpoch = Math.floor(+searchParams.startTime / 1000);
      search = search.append('startDateEpoch', `${startDateEpoch}`);
    }

    if (searchParams.sortAscending) {
      search = search.append('sortAscending', 'true');
    }

    if (searchParams.start !== undefined) {
      search = search.append('start', `${searchParams.start}`);
    }

    if (searchParams.limit !== undefined) {
      search = search.append('limit', `${searchParams.limit}`);
    }

    const accountUUID = this.selectedAccountService.selectedAccount.uuid;
    return this.featureService.hasVisualAccessLogsFeature$.pipe(
      take(1),
      switchMap(hasFeature => {
        if (hasFeature) {
          search = search.append('includeVal', 'true');
        }
        return this.authService
          .request({
            method: 'get',
            endpoint: `/web/v1/accounts/${accountUUID}/accessLogs`,
            search
          }).pipe(map((response: HttpResponse<any>): PagedResponse<AccessLog> => {
            const payload = AuthService.getPayload(response);
            return {
              data: payload.accessLogs.map(accessLogFromJSON),
              nextPage: payload.metadata.nextPage
            };
          }));
      }),
    );
  }

  public updateVisualAccessLogMetadata(accessLog: AccessLog, visualAccessLogMetadata: VisualAccessLogMetadata): Observable<void> {
    return this.authService.request({
      method: 'post',
      endpoint: `/web/v1/accounts/${this.selectedAccountService.selectedAccount.uuid}/accessLogs/${accessLog.uuid}/visualAccessLogMetadata`,
      data: visualAccessLogMetadata
    }).pipe(
      map((response) => AuthService.getPayload(response)),
    );
  }
}
