<ng-container *ngIf="currentStep === FeaturedEntryStep.FeaturedEntryDetails">
  <latch-loading [isActive]="isLoading">
    <latch-card class="page">
      <ng-container latchCardHeader>
        <div class="latch-card-actions">
          <button
            class="latch-button-icon close-button"
            latchCardActions
            [routerLink]="['', { outlets: { popup: null } }]"
            queryParamsHandling="preserve">
            <span class="latch-icon-close"></span>
          </button>
        </div>
        <h2 class="latch-card-title">Add Featured Entry</h2>

        <p class="page-description">
          With featured entries, you can add a unique item to your intercom’s directory, such as Delivery and Leasing Office. Feature entries will be displayed at the top of your directory.
        </p>

      </ng-container>
      <ng-container latchCardContent>
        <form [formGroup]="formGroup">
          <latch-list>
            <latch-labeled-list-item label="Display Name" *ngIf="formGroup.controls.displayName as displayName">
              <div class="list-sub-title">
                Maximum 17 characters
              </div>
              <input class="latch-form-control" type="text" placeholder="Display Name" maxlength="17" formControlName="displayName" required/>
              <div class="help-block text-danger" *ngIf="displayName.errors && !displayName.pristine">
                <div *ngIf="displayName.errors?.required">Field is required.</div>
              </div>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Person" *ngIf="formGroup.controls.selectedCandidate as selectedCandidate">
              <div *ngIf="!selectedCandidate.value">
                <button class="latch-button-dashed" (click)="setCurrentStep(FeaturedEntryStep.FeaturedEntryCandidate)">
                  Add Person
                </button>
              </div>
              <div class="card" *ngIf="selectedCandidate.value">
                <div class="card-handle space-bt">
                  <div class="card-title">{{selectedCandidate.value.firstName}} {{selectedCandidate.value.lastName}}</div>
                  <div class="latch-icon-close"(click)="clearSelectedCandidate()"></div>
                </div>
              </div>
            </latch-labeled-list-item>
            <div class="latch-divider"></div>
            <latch-labeled-list-item label="Contact Preference" *ngIf="formGroup.controls.contactPreference as contactPreference">
              <div>
                <latch-dropdown
                  [data]="contactPreferenceSelect"
                  formControlName="contactPreference">
                </latch-dropdown>
              </div>
            </latch-labeled-list-item>
          </latch-list>
        </form>
      </ng-container>
      <ng-container latchCardFooter>
        <button class="latch-button" [disabled]="formGroup.invalid" (click)="handleSubmit()">
          Save Featured Entry
        </button>
      </ng-container>
    </latch-card>
  </latch-loading>
</ng-container>
<ng-container *ngIf="currentStep===FeaturedEntryStep.FeaturedEntryCandidate">
  <latch-select-featured-entry-candidate
    [buildingUUID]="buildingUUID"
    (selectedCandidate)="setSelectedCandidate($event)"
    (cancel)="setCurrentStep(FeaturedEntryStep.FeaturedEntryDetails)"
  ></latch-select-featured-entry-candidate>
</ng-container>
