import { ImageSource, UnlockMethod } from './access-log';

export enum DeliveryStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD'
}

export enum DeliveryPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export enum PackageType {
  PARCEL = 'PARCEL',
  PERISHABLE_ITEM = 'PERISHABLE_ITEM',
  MEDICAL_ITEM = 'MEDICAL_ITEM',
  LARGE_PACKAGE = 'LARGE_PACKAGE',
  OTHER = 'OTHER'
}

export interface DeliveryPackage {
  numberOfPackages: number;
  packageType: PackageType;
}

export interface DeliveryUnit extends DeliveryPackage {
  id: number;
  displayName: string;
}

export interface DeliveriesFilter {
  page: number;
  pageSize: number;
  sort: string;
  search: string;
}

export interface AccessLogImage {
  url?: string;
  imageSource?: ImageSource;
  imageFileName?: string;
  imageToken?: string;
}

export interface DeliveryAccessLog {
  uuid: string;
  method: UnlockMethod;
  image?: AccessLogImage;
}

export enum DeliveryType {
  PACKAGE = 'PACKAGE',
  OTHER = 'OTHER'
}

export interface Delivery {
  id: string;
  deliveryType: DeliveryType;
  lock: {
    id: string;
    displayName: string;
  };
  courier: string;
  comment: string;
  status: DeliveryStatus;
  priority: DeliveryPriority;
  deliveryDateTime: number;
  createdOn: number;
  lastUpdatedOn: number;
  createdBy: {
    id: string;
    displayName: string;
  };
  lastUpdatedBy: {
    id: string;
    displayName: string;
  };
  units: DeliveryUnit[];
  accessLog?: DeliveryAccessLog;
}

export interface DeliveryList {
  id: string;
  lock: {
    id: string;
    displayName: string;
  };
  status: DeliveryStatus;
  lastUpdatedOn: number;
  units: DeliveryUnit[];
  courier: string;
  priority: DeliveryPriority;
  accessLog?: DeliveryAccessLog;
}

export interface DeliveryListResponse {
  metadata: {
    page: number,
    pageSize: number,
    totalElements: number,
    totalPages: number
  };
  elements: DeliveryList[];
}

export type DeliveryInfo = 'title' | 'packages' | 'status' | 'unlockMethod';

export type DeliveryUnitInfo = 'packages' | 'status' | 'timeNotified';

export interface DeliveryUnitsFilter {
  page: number;
  pageSize: number;
  sort: string;
  search: string;
  units: number[];
  packageTypes: PackageType[];
  couriers: string[];
  deliveryDateTimeFrom?: number;
  deliveryDateTimeTo?: number;
}

export interface DeliveryUnitRequest {
  id: string;
  courier: string;
  deliveryDateTime: number;
  priority: DeliveryPriority;
  status: DeliveryStatus;
}

export enum DeliveryUnitNotificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

export interface DeliveryUnitNotification {
  contactCardId: string;
  contactCardFullName: string;
  notifiedOn: number;
  notificationType: DeliveryUnitNotificationType
}

export interface DeliveryUnitList {
  unit: {
    id: number;
    displayName: string;
  };
  deliveryRequest: DeliveryUnitRequest;
  notifications: DeliveryUnitNotification[];
  packages: DeliveryPackage[];
  createdOn: number;
  lastUpdatedOn: number;
  createdBy: {
    id: string;
    displayName: string;
  }
  lastUpdatedBy: {
    id: string;
    displayName: string;
  }
  accessLog?: DeliveryAccessLog;
}

export interface DeliveryUnitListResponse {
  metadata: {
    page: number,
    pageSize: number,
    totalElements: number,
    totalPages: number
  };
  elements: DeliveryUnitList[];
}

export interface NotifyResident {
  contactCardFullName: string;
  contactCardId: string;
  notify: boolean;
  notificationTypes: DeliveryUnitNotificationType[];
  notificationType?: DeliveryUnitNotificationType;
}

export interface NotifyResidentNotification {
  unitId: number;
  contactCardId: string;
  notificationType?: DeliveryUnitNotificationType;
}

export interface NotifyResidentRequest {
  notifications: NotifyResidentNotification[];
}

export interface NotifyResidentBaseResponse {
  unitId: number;
  contactCardId: string;
  success: boolean;
}

export interface NotifyResidentSuccessResponse extends NotifyResidentBaseResponse {
  success: true;
  notificationType: DeliveryUnitNotificationType;
  notifiedAt: number;
  packages: DeliveryPackage[];
}

export enum NotifyResidentErrorReason {
  CONTACT_HAS_NO_PHONE_NUMBER = 'CONTACT_HAS_NO_PHONE_NUMBER',
  CONTACT_HAS_NO_EMAIL = 'CONTACT_HAS_NO_EMAIL',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
}

export interface NotifyResidentErrorResponse extends NotifyResidentBaseResponse {
  success: false;
  reason: NotifyResidentErrorReason;
}

export type NotifyResidentNotificationResponse = NotifyResidentSuccessResponse | NotifyResidentErrorResponse;

export interface NotifyResidentResponse {
  notifications: NotifyResidentNotificationResponse[];
}

export const notificationTypeNames: Record<DeliveryUnitNotificationType, string> = {
  [DeliveryUnitNotificationType.EMAIL]: 'Email',
  [DeliveryUnitNotificationType.SMS]: 'SMS'
};

export const packageTypeNames: Record<PackageType, string> = {
  [PackageType.LARGE_PACKAGE]: 'Large Package',
  [PackageType.MEDICAL_ITEM]: 'Medical Item',
  [PackageType.OTHER]: 'Other',
  [PackageType.PARCEL]: 'Parcel',
  [PackageType.PERISHABLE_ITEM]: 'Perishable Item'
};

export const statusNames: Record<DeliveryStatus, string> = {
  [DeliveryStatus.CLOSED]: 'Closed',
  [DeliveryStatus.ON_HOLD]: 'On Hold',
  [DeliveryStatus.OPEN]: 'Open',
  [DeliveryStatus.PENDING]: 'Pending',
};

export const unlockMethodDisplayValues: Record<UnlockMethod, string> = {
  [UnlockMethod.Passcode]: 'Passcode',
  [UnlockMethod.BLE]: 'Bluetooth',
  [UnlockMethod.NFC]: 'Mobile App / Keycards',
  [UnlockMethod.MKO]: 'Mechanical Key',
};

export type PackageModalType = 'packageDetails' | 'sendNotification';

export type CloseSendNotificationModal = 'close' | 'success' | 'error';

export type ClosePackagesModal = 'close' | 'sendNotificationsSuccess' | 'sendNotificationsError';

export type MessageModalType = 'success' | 'error';

export const getUnlockMethodDisplayValue = (accessLog?: DeliveryAccessLog): string => {
  if (accessLog) {
    return unlockMethodDisplayValues[accessLog.method] || accessLog.method;
  }
  return '';
};

export const getPackagesDisplay = (packages: DeliveryPackage[]): string => {
  const packagesRecord: Record<PackageType, number> = {
    [PackageType.LARGE_PACKAGE]: 0,
    [PackageType.MEDICAL_ITEM]: 0,
    [PackageType.OTHER]: 0,
    [PackageType.PARCEL]: 0,
    [PackageType.PERISHABLE_ITEM]: 0
  };
  packages.forEach(activityUnit => {
    packagesRecord[activityUnit.packageType] += activityUnit.numberOfPackages;
  });
  const packagesArray: string[] = [];
  Object.keys(packagesRecord).forEach(key => {
    if (packagesRecord[key as PackageType] > 0) {
      packagesArray.push(`${packageTypeNames[key as PackageType]} (${packagesRecord[key as PackageType].toString()})`);
    }
  });
  return packagesArray.join(', ');
};

export interface CourierNamesResponse {
  courierNames: string[];
}
