import moment from 'moment';
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, takeUntil, filter, startWith } from 'rxjs/operators';
import { DateTime, TIME_DISPLAY_FORMAT, Meridiem } from 'manager/services/utility/date-time';
import { ensure } from '@latch/latch-web';

const DEBOUNCE_TIME = 300;

@Directive({ selector: 'latch-date-time-input[latchResetTime]' })
export class ResetTimeDirective implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  private formControl: AbstractControl;

  constructor(
    private control: NgControl
  ) {
    this.formControl = ensure(this.control.control);
  }

  ngOnInit() {
    this.initSubscription();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  private initSubscription() {
    const isDateChanged = (x: DateTime, y: DateTime) => x.date === y.date;

    this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      filter(x => !!x),
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged(isDateChanged),
      skip(1),
      takeUntil(this.unsubscribe$),
    ).subscribe(startDate => {
      const time = moment().startOf('day');
      this.formControl.patchValue({
        ...startDate,
        time: time.format(TIME_DISPLAY_FORMAT),
        meridiem: time.format('A') === 'AM' ? Meridiem.AM : Meridiem.PM,
      }, { emitEvent: false });
    });
  }
}
