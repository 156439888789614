<div class="feedback-main" [ngClass]="variant" [attr.role]="feedbackRole" aria-live="assertive">
  <div class="feedback-image" aria-hidden="true">
    <ng-content select="[feedback-image]" *ngIf="variant !== 'loading'"></ng-content>
    <div *ngIf="showDefaultFeedbackImage" [class]="feedbackImageClass"></div>
    <latch-spinner *ngIf="variant === 'loading'"></latch-spinner>
  </div>
  <div class="feedback-content">
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
</div>
