import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Space, SpaceMetadata, SpaceType } from '../models/spaces';

export interface GetSpacesInput {
  searchTerm?: string;
  spaceTypes?: SpaceType[];
  sort?: string;
  sortDescending?: boolean;
}

export interface CreateSpaceInput {
  spaceType: SpaceType;
  parentPath: string
  subspaces: {
    [SpaceType.Access]: Space[];
  };
  metadata: SpaceMetadata;
}

@Injectable({
  providedIn: 'root'
})
export abstract class SpacesService {
  public abstract getSpaces(buildingUUID: string, input: GetSpacesInput): Observable<Space[]>;
  public abstract getSpace(buildingUUID: string, uuid: string): Observable<Space>;
  public abstract createSpace(buildingUUID: string, space: CreateSpaceInput): Observable<Space>;
  public abstract updateSpace(buildingUUID: string, space: Space): Observable<Space>;
  public abstract deleteSpace(buildingUUID: string, space: Space): Observable<null>;
}
