import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'manager/services/currency/currency.service';

@Pipe({
  name: 'latchCurrency'
})
export class CurrencyPipe implements PipeTransform {

  transform(amount: number | undefined, currency?: string, decimal?: boolean): string {
    if (amount == null) {
      return '';
    }

    return CurrencyService.getAmountString(amount, currency, decimal);
  }

}
