import { Component, OnInit, OnDestroy } from '@angular/core';

import { UnitService, DUPLICATE_UNIT_NAME, MAX_UNIT_NAME_LENGTH } from '../../../services/units/unit.service';
import { UIStateService } from '../../../services/appstate/ui-state.service';
import { Unit } from 'manager/models/unit';
import { UnitImportInput } from '../import-units-page/import-units-page.component';
import { SelectedBuildingsService } from 'manager/services/appstate/selected-buildings.service';
import { Subject, of, zip } from 'rxjs';
import { ErrorHandlerService } from 'manager/services/appstate/error-handler.service';
import { IntercomService, IntercomShortInfo } from 'manager/services/intercom/intercom.service';
import { switchMap, takeUntil, take } from 'rxjs/operators';
import { FeatureService } from 'manager/services/appstate/feature.service';

interface FormErrors {
  name?: string;
}

enum Tab {
  CreateUnit = 'CreateUnit',
  CSV = 'CSV',
}

@Component({
  selector: 'latch-create-unit-page',
  templateUrl: './create-unit-page.component.html',
  styleUrls: ['./create-unit-page.component.scss']
})
export class CreateUnitPageComponent implements OnInit, OnDestroy {
  isLoading = false;

  createdUnits: Unit[] | undefined;
  units: UnitImportInput[] = [];
  buildingUUID!: string;
  intercoms: IntercomShortInfo[] = [];

  name = '';
  localTelecom: string | undefined;

  errors: FormErrors = {};

  currentTab: Tab = Tab.CreateUnit;
  Tab = Tab;
  MAX_UNIT_NAME_LENGTH = MAX_UNIT_NAME_LENGTH;

  showImport = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private unitService: UnitService,
    private uiStateService: UIStateService,
    private selectedBuildingsService: SelectedBuildingsService,
    private errorHandlerService: ErrorHandlerService,
    private intercomService: IntercomService,
    private featureService: FeatureService
  ) { }

  get isImportMode(): boolean {
    return this.currentTab === Tab.CSV;
  }

  get canSubmit(): boolean {
    return this.isImportMode ? !!this.units.length : !!this.name;
  }

  get buildingHasIntercom(): boolean {
    return this.intercoms.length > 0;
  }

  ngOnInit() {
    this.isLoading = true;
    this.uiStateService.enableFocusMode();

    const getSelectedBuildings$ = this.selectedBuildingsService.getSelectedBuildings();
    const buildingHasIntercomFeature$ = this.featureService.hasIntercomFeature$.pipe(take(1));

    zip(getSelectedBuildings$, buildingHasIntercomFeature$).pipe(
      switchMap(([buildings, buildingHasIntercomFeature]) => {
        this.buildingUUID = buildings[0].uuid;
        const intercoms$ = buildingHasIntercomFeature ? this.intercomService.getBuildingIntercoms(this.buildingUUID) : of([]);
        return intercoms$;
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(intercoms => {
      this.intercoms = intercoms;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.errorHandlerService.handleException(error);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.uiStateService.disableFocusMode();
  }

  handleSubmit() {
    this.validate();
    if (Object.keys(this.errors).length === 0) {
      this.submit();
    }
  }

  handleCreateAnother() {
    this.errors = {};
    this.name = '';
    this.localTelecom = undefined;
    this.units = [];
    this.createdUnits = undefined;
  }

  handleImport(units: UnitImportInput[]) {
    this.showImport = false;
    this.units = units;
  }

  handleTabClicked(tab: string) {
    this.currentTab = tab as Tab;
  }

  submit() {
    this.isLoading = true;

    if (this.isImportMode) {
      const units = this.units.map(unit => ({
        name: unit.name,
        buildingUUID: this.buildingUUID,
        localTelecom: unit.localTelecom
      }));
      this.unitService.createUnits(units, this.buildingUUID, this.buildingHasIntercom).subscribe(createdUnits => {
        this.isLoading = false;
        this.createdUnits = createdUnits;
      }, error => {
        this.isLoading = false;
        this.errorHandlerService.handleException(error);
      });
    } else {
      this.unitService.createUnit({
        name: this.name.trim(),
        buildingUUID: this.buildingUUID,
        localTelecom: this.localTelecom
      }).subscribe((unit: Unit) => {
        this.isLoading = false;
        this.createdUnits = [unit];
      }, error => {
        this.isLoading = false;
        if (error.message === DUPLICATE_UNIT_NAME) {
          this.errors.name = 'Name is already in use';
        } else {
          this.errorHandlerService.handleException(error);
        }
      });
    }
  }

  validate() {
    this.errors = {};
    if (!this.isImportMode) {
      if (!this.name || !this.name.trim()) {
        this.errors.name = 'Name is required';
      }
    }
  }
}
