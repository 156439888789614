import { Component, HostBinding, inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserEnvironmentService } from '../services/utility/browser-environment.service';
import { Platform } from '@angular/cdk/platform';
import { Event, GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationError, Router } from '@angular/router';

// Services
import { ThemeService } from '../services/theme/theme.service';
import { environment } from 'environments/interface';

/**
 * Shell component which contains the entire Management Console app.
 */
@Component({
  selector: 'latch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-ie')
  get isIE() {
    return this.platform.TRIDENT;
  }

  isGuardsCheckActive = false;

  constructor(
    private titleService: Title,
    private browserEnvironmentService: BrowserEnvironmentService,
    private platform: Platform,
    private router: Router,
    private themeService: ThemeService
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle(environment.showDoorExperience ? 'Door' : 'Latch | Mission Control');
    this.browserEnvironmentService.setGlobalClassNames();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof GuardsCheckStart) {
        this.isGuardsCheckActive = true;
      } else if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.isGuardsCheckActive = false;
      }
    });
  }

  ngOnDestroy() {
    this.themeService.sub?.unsubscribe();
  }
}
