import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { FeaturedEntryCandidate, SelectCandidateStep } from '../../../models/featured-entry';
import { FeaturedEntryService } from '../../../services/featured-entry/featured-entry.service';
import { NextPage } from '../../../services/interfaces';

const PAGE_START_DEFAULT = 0;
const PAGE_LIMIT_DEFAULT = 20;
const DEBOUNCE_TIME = 250;

@Component({
  selector: 'latch-select-featured-entry-candidate',
  templateUrl: './select-featured-entry-candidate.component.html',
  styleUrls: ['./select-featured-entry-candidate.component.scss']
})
export class SelectFeaturedEntryCandidateComponent implements OnInit, OnDestroy {
  @Input() private buildingUUID!: string;
  @Output() public cancel = new EventEmitter<void>();
  @Output() private selectedCandidate = new EventEmitter<FeaturedEntryCandidate>();
  public isLoading = false;
  public isLoadingNextPage = false;
  public SelectCandidateStep = SelectCandidateStep;
  public currentStep = SelectCandidateStep.SelectCandidate;
  public candidates!: FeaturedEntryCandidate[];
  public candidate: FeaturedEntryCandidate | null = null;
  public search = new FormControl('', { nonNullable: true });
  public nextPage: NextPage | null = null;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private featuredEntryService: FeaturedEntryService
  ) { }

  ngOnInit(): void {
    this.loadCandidates(PAGE_START_DEFAULT, PAGE_LIMIT_DEFAULT, this.search.value);
    this.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(DEBOUNCE_TIME),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.loadCandidates(PAGE_START_DEFAULT, PAGE_LIMIT_DEFAULT, this.search.value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCurrentStep(currentStep: SelectCandidateStep): void {
    this.currentStep = currentStep;
  }

  getNextPage(nextPage: NextPage): void {
    this.setNextPageCandidates(nextPage.start, nextPage.limit, this.search.value);
  }

  setCandidate(candidate: FeaturedEntryCandidate): void {
    this.candidate = candidate;
  }

  selectCandidate(candidate: FeaturedEntryCandidate): void {
    if (candidate.phoneNumber) {
      this.selectedCandidate.emit(candidate);
    } else {
      this.setCurrentStep(this.SelectCandidateStep.AddPhoneNumber);
    }
  }

  private loadCandidates(start: number, limit: number, search: string): void {
    this.isLoading = true;
    this.featuredEntryService.getBuildingFeaturedEntryCandidates(this.buildingUUID, {
      start,
      limit,
      search
    }).pipe(
      finalize(() => this.isLoading = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(buildingFeaturedEntryResponse => {
      this.nextPage = buildingFeaturedEntryResponse.metadata.nextPage;
      this.candidates = buildingFeaturedEntryResponse.people;
    });
  }

  private setNextPageCandidates(start: number, limit: number, search: string): void {
    this.isLoadingNextPage = true;
    this.featuredEntryService.getBuildingFeaturedEntryCandidates(this.buildingUUID, {
      start,
      limit,
      search
    }).pipe(
      finalize(() => this.isLoadingNextPage = false),
      takeUntil(this.unsubscribe$)
    ).subscribe(buildingFeaturedEntryResponse => {
      this.nextPage = buildingFeaturedEntryResponse.metadata.nextPage;
      buildingFeaturedEntryResponse.people.forEach(candidate => this.candidates.push(candidate));
    });
  }

}
