import { SyncTask } from './sync-status';
import { ElevatorDetails } from './elevator-details';
import { FloorGroup } from './floor-group';
import { cloneDeep } from 'manager/services/utility/utility';

export enum Accessibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  COMMUNAL = 'COMMUNAL',
  SERVICE = 'SERVICE',
  ELEVATOR = 'ELEVATOR'
}

export enum LockAssignmentStatus {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  NEVER_ASSIGNED = 'NEVER_ASSIGNED'
}

export class Battery {
  percentage: number;
  voltage: number;
  date: Date;

  constructor(battery: IBattery) {
    this.percentage = battery.percentage;
    this.voltage = battery.voltage;
    this.date = battery.date;
  }

  static fromJSON(json: any): Battery {
    const { percentage, voltage, date } = json;
    return new Battery({
      percentage: percentage ?? 0,
      voltage: voltage ?? 0,
      // Server timestamps are in seconds, new Date expects milliseconds
      date: new Date(date * 1000)
    });
  }

  isLow(): boolean {
    return this.percentage <= 35;
  }
}

/**
 * LockType determines whether this lock is controlling a door, which can be only open or shut, or an elevator,
 * which has a more complicated state model (with different floors accessible to different users).
 *
 * It is different from LockDeviceType, which tells the physical type of Latch device - LockType is about what
 * that physical device is connected to.
 */
export enum LockType {
  ELEVATOR = 'ELEVATOR',
  DOOR = 'DOOR'
}

export enum LockDeviceType {
  M = 'M',
  R = 'R',
  C = 'C',
  G = 'G',
  R2 = 'R2'
}

export enum NetworkConfigurationType {
  NONE = 'NONE',
  ETHERNET_ONLY = 'ETHERNET_ONLY',
  WIFI_ONLY = 'WIFI_ONLY',
  ETHERNET_AND_WIFI = 'ETHERNET_AND_WIFI',
  // NOT_CONFIGURED is not a value that exists in the backend -> it's added here to provide consistency in manager web
  // there is logic in `Lock#fromJSON` to parse and set this value for the appropriate locks
  NOT_CONFIGURED = 'NOT_CONFIGURED'
}

export interface LockConfiguration {
  productConfiguration?: any,
  ethernetConfiguration?: any,
  wifiConfiguration?: any,
}

/**
 * Basic data structure of a lock based of the expected API response
 */
export class Lock {
  uuid: string;
  name: string;
  buildingUUID: string;
  scheduleUUID?: string;
  accessibility?: Accessibility;
  timezone?: string;
  assignment?: LockAssignmentStatus;
  device?: ILockDevice;
  firmwareVersion?: ILockFirmwareVersion;
  accessConfiguration?: ILockAccessConfiguration;
  /** Whether this lock is being used to control a door or an elevator. */
  lockType: LockType;
  elevator?: ElevatorDetails;
  networkConfiguration: NetworkConfigurationType;
  // Specifies whether the Lock is associated with a Partner
  partnerUUIDs?: string[];
  // Not part of backend's Lock model view, but client needs to add it
  syncTasks?: SyncTask[];
  configuration?: LockConfiguration;

  get isInstalled(): boolean {
    return this.assignment === LockAssignmentStatus.ASSIGNED;
  }

  get neverInstalled(): boolean {
    return this.assignment === LockAssignmentStatus.NEVER_ASSIGNED;
  }

  get currentBattery(): Battery | undefined {
    if (!this.device) {
      return undefined;
    }
    return this.device.currentBattery;
  }

  get isCurrentlyInternetConnected(): boolean | undefined {
    if (!this.device) {
      return false;
    }
    const connections = this.device.internetConnections;
    return connections?.ethernet || connections?.wifi;
  }

  get isGuestSharingAllowed(): boolean | undefined {
    if (!this.accessConfiguration) {
      return false;
    }
    return this.accessConfiguration.isGuestSharingAllowed;
  }

  get canSupportQualifiedAccess(): boolean | undefined {
    if (!this.accessConfiguration) {
      return false;
    }
    return this.accessConfiguration.canSupportQualifiedAccess;
  }

  get doesSupportQualifiedAccess(): boolean | undefined {
    if (!this.accessConfiguration) {
      return false;
    }
    return this.accessConfiguration.doesSupportQualifiedAccess;
  }

  get isPartnerEnabled(): boolean {
    if (this.partnerUUIDs !== undefined && this.partnerUUIDs.length) {
      return true;
    }
    return false;
  }

  constructor(lock: ILock) {
    this.uuid = lock.uuid;
    this.name = lock.name;
    this.buildingUUID = lock.buildingUUID;
    this.scheduleUUID = lock.scheduleUUID;
    this.accessibility = lock.accessibility;
    this.timezone = lock.timezone;
    this.assignment = lock.assignment;
    this.device = lock.device;
    this.firmwareVersion = lock.firmwareVersion;
    this.accessConfiguration = lock.accessConfiguration;
    // For backwards compatibility with versions of the GET /locks endpoint that don't return
    // a type for each lock, default to DOOR (which was the only type before the new versions
    // of the endpoint).
    this.lockType = lock.type || LockType.DOOR;
    this.elevator = lock.elevator;
    this.syncTasks = lock.syncTasks;
    this.partnerUUIDs = lock.partnerUUIDs;

    this.networkConfiguration = lock.configuration?.productConfiguration?.networkSelection ?? NetworkConfigurationType.NOT_CONFIGURED;

    this.configuration = lock.configuration;
  }

  static fromJSON(json: any): Lock {
    // Avoid mutation raw json data
    const lock = cloneDeep(json);

    if (lock.device && lock.device.currentBattery) {
      lock.device.currentBattery = Battery.fromJSON(lock.device.currentBattery);
    }

    if (lock.elevator && lock.elevator.floorGroups) {
      lock.elevator.floorGroups = lock.elevator.floorGroups.map(FloorGroup.fromJSON);
    }
    if (lock.elevator && lock.elevator.doorcodeFloorGroup) {
      lock.elevator.doorcodeFloorGroup = FloorGroup.fromJSON(lock.elevator.doorcodeFloorGroup);
    }

    return new Lock(lock);
  }

  isPrivate() {
    return this.accessibility === Accessibility.PRIVATE;
  }

  isPublic() {
    return this.accessibility === Accessibility.PUBLIC;
  }

  isCommunal() {
    return this.accessibility === Accessibility.COMMUNAL;
  }

  isService() {
    return this.accessibility === Accessibility.SERVICE;
  }

  // Sloth is not battery powered but still may specify a battery percentage.
  isBatteryPowered(): boolean {
    if (!this.device) {
      return true;
    }
    return this.device.type !== LockDeviceType.R && this.device.type !== LockDeviceType.R2;
  }

  isBatteryLow(): boolean | undefined {
    if (!this.isBatteryPowered()) {
      return false;
    }
    return this.currentBattery ? this.currentBattery.isLow() : undefined;
  }
}

export interface ILock {
  uuid: string;
  name: string;
  buildingUUID: string;
  accessibility?: Accessibility;
  scheduleUUID?: string;
  timezone?: string;
  assignment?: LockAssignmentStatus;
  device?: ILockDevice;
  firmwareVersion?: ILockFirmwareVersion;
  accessConfiguration?: ILockAccessConfiguration;
  type?: LockType;
  elevator?: ElevatorDetails;
  configuration?: any;
  partnerUUIDs?: string[];
  syncTasks?: SyncTask[];
}

export interface ILockDevice {
  serialNumber?: string;
  currentBattery?: Battery;
  type?: LockDeviceType;
  internetConnections?: {
    ethernet: boolean;
    wifi: boolean;
  };
  hsUUID?: string;
  lsUUID?: string;
  updateUUID?: string;
  unlockUUID?: string;
  productDetails?: ProductDetails;
}

export interface ProductDetails {
  name: string;
}

export interface ILockFirmwareVersion {
  // FirmwareVersionV1 fields
  upgradeTime?: number;
  releaseVersion?: string;
  homeSystemVersion?: string;
  stmMainVersion?: string;
  stmRamVersion?: string;
  stmRomVersion?: string;
  tiAppVersion?: string;
  tiStackVersion?: string;
  hsBleVersion?: string;
  lsBleVersion?: string;
  releasePatchVersion?: string;

  // FirmwareVersionV2 fields
  apiVersion?: string;
  androidNfVersion?: string;
  deltaSyncVersion?: number;
  systemCertificateTimestamp?: Date;
  communicationVersion?: string;
  bleVersion?: number;
  userListTimestamp?: Date;
  deviceCertificateVersion?: number;
  lockProtocolVersion?: string;
  doorScheduleVersion?: string;
}

export interface ILockAccessConfiguration {
  isGuestAccessAllowed?: boolean;
  isGuestSharingAllowed?: boolean;
  canSupportQualifiedAccess?: boolean;
  doesSupportQualifiedAccess?: boolean;
}

interface IBattery {
  percentage: number;
  voltage: number;
  date: Date;
}

export class LockShortInfo {
  lockUUID: string;
  /** Whether this lock is being used to control a door or an elevator. */
  type: LockType;
  name: string;
  lockState: LockAssignmentStatus;
  currentBattery: Battery;
  deviceType: LockDeviceType;
  seriesName: string;
  earliestDeadlineSyncTaskStatusViews: SyncTask;

  get isInstalled(): boolean {
    return this.lockState === LockAssignmentStatus.ASSIGNED;
  }

  get neverInstalled(): boolean {
    return this.lockState === LockAssignmentStatus.NEVER_ASSIGNED;
  }

  constructor(data: LockShortInfo) {
    this.lockUUID = data.lockUUID;
    this.type = data.type;
    this.name = data.name;
    this.lockState = data.lockState;
    this.currentBattery = data.currentBattery;
    this.deviceType = data.deviceType;
    this.seriesName = data.seriesName;
    this.earliestDeadlineSyncTaskStatusViews = data.earliestDeadlineSyncTaskStatusViews;
  }

  static fromJSON(json: any): LockShortInfo {
    // Avoid mutation raw json data
    const lock: LockShortInfo = cloneDeep(json);

    if (lock.currentBattery) {
      lock.currentBattery = Battery.fromJSON(lock.currentBattery);
    }

    return new LockShortInfo(lock);
  }

  // Sloth is not battery powered but still may specify a battery percentage.
  isBatteryPowered(): boolean {
    return this.deviceType !== LockDeviceType.R && this.deviceType !== LockDeviceType.R2;
  }

  isBatteryLow(): boolean | undefined {
    if (!this.isBatteryPowered()) {
      return false;
    }
    return this.currentBattery ? this.currentBattery.isLow() : undefined;
  }
}
