<ng-container [ngTemplateOutlet]="cardLayout ? cardTemplate : listTemplate">
</ng-container>

<ng-template #cardTemplate>
  <latch-card class="page" *ngIf="!viewingDoorGroupForLock; else viewingFloorGroupTemplate">
    <ng-container latchCardHeader>
      <div class="latch-card-actions">
        <button class="latch-button-icon" (click)="cancel.emit()" *ngIf="showBack">
          <span class="latch-icon-chevron-left"></span>
        </button>
        <button *ngIf="!isCreating && currentUserCanEdit && displayEditButton" class="latch-button-link" (click)="handleToggleEditMode()">
          {{isEditing ? 'Cancel' : 'Edit'}}
        </button>
        <button *ngIf="!isCreating && !currentUserCanEdit && !isDeliveryKey && displayEditButton"
                class="latch-button-link"
                latchTooltip
                tooltipText="Only Portfolio Managers and Property Managers with ‘Full Key Management’ permission may edit keys.">
          Edit
        </button>
        <button
          *ngIf="!isCreating && !currentUserCanEdit && isDeliveryKey && displayEditButton"
          class="latch-button-link"
          latchTooltip
          tooltipText="This Deliveries Key was created automatically by Latch and is securely shared with trusted delivery carriers such as UPS and other national carriers to enable deliveries when recipients are away. Only the Latch Team may edit the doors on this key."
        >Edit
        </button>
      </div>

      <div class="latch-card-title">{{ title }}</div>

      <input type="text" placeholder="Search Doors" class="latch-card-search" [formControl]="searchControl"/>

      <button
        class="latch-button-link disable-doorcodes"
        *ngIf="(isCreating || isEditing) && !isViewingKeyMembership && hasDoorcodeSuppression"
        (click)="toggleAllDoorcodes()">
        {{ areAllDoorcodesDisabled() ? 'Enable' : 'Disable' }} All Doorcodes
      </button>
    </ng-container>

    <ng-container latchCardContent>
      <div class="cell-list">
        <div class="group" *ngFor="let doorGroup of filteredGroups">
          <div class="group-heading"
            (click)="handleToggleCollapse(doorGroup)"
            [ngClass]="{'expanded': !isGroupCollapsed(doorGroup)}">
            <div>
              <span class="icon-chevron-down"></span>
              {{getDoorGroupHeader(doorGroup)}}
            </div>
            <button
              *ngIf="isEditing && !isViewingKeyMembership"
              class="latch-button-link"
              (click)="handleToggleGroup(doorGroup, $event)">
              {{isGroupSelected(doorGroup) ? 'Deselect all' : 'Select all'}}
            </button>
          </div>
          <div [@expandCollapse]="isGroupCollapsed(doorGroup) ? 'close' : 'open' ">
            <latch-selectable-lock-item
              *ngFor="let selectableLock of filteredByGroup[doorGroup]"
              [selectableLock]="selectableLock"
              [isEditing]="isEditing"
              [hasDoorcodeSuppression]="hasDoorcodeSuppression"
              [isViewingKeyMembership]="isViewingKeyMembership"
              [onlyDoors]="onlyDoors"
              (selectedChange)="handleSelectedChange(selectableLock)"
              (viewDoorGroupForLock)="handleViewDoorGroupForLock(selectableLock)">
            </latch-selectable-lock-item>
          </div>
        </div>
        <ng-container *ngIf="filteredGroups.length === 0">
          <div class="cell">
            <div class="cell-body">
              <div class="cell-text">
                There are no results for the search term.
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container latchCardFooter *ngIf="showSubmit">
      <button class="latch-button" (click)="handleSave()">{{submitText}}</button>
    </ng-container>
  </latch-card>
</ng-template>

<ng-template #listTemplate>
  <div class="latch-viewport">
    <div class="cell-list">
      <div class="group" *ngFor="let doorGroup of filteredGroups">
        <div class="group-heading"
          (click)="handleToggleCollapse(doorGroup)"
          [ngClass]="{'expanded': !isGroupCollapsed(doorGroup)}">
          <div>
            <span class="icon-chevron-down"></span>
            {{getDoorGroupHeader(doorGroup)}}
          </div>
          <button
            *ngIf="isEditing && !isViewingKeyMembership"
            class="latch-button-link"
            (click)="handleToggleGroup(doorGroup, $event)">
            {{isGroupSelected(doorGroup) ? 'Deselect all' : 'Select all'}}
          </button>
        </div>
        <div [@expandCollapse]="isGroupCollapsed(doorGroup) ? 'close' : 'open' ">
          <latch-selectable-lock-item
            *ngFor="let selectableLock of filteredByGroup[doorGroup]"
            [selectableLock]="selectableLock"
            [isEditing]="isEditing"
            [hasDoorcodeSuppression]="hasDoorcodeSuppression"
            [isViewingKeyMembership]="isViewingKeyMembership"
            (selectedChange)="handleSelectedChange(selectableLock)"
            (update)="setUpNavbarActions()"
            (viewDoorGroupForLock)="handleViewDoorGroupForLock(selectableLock)">
          </latch-selectable-lock-item>
        </div>
      </div>
      <ng-container *ngIf="filteredGroups.length === 0">
        <div class="cell">
          <div class="cell-body">
            <div class="cell-text">
              There are no results for the search term.
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #viewingFloorGroupTemplate>
  <ng-container *ngIf="viewingDoorGroupForLock && cardLayout">
    <latch-floor-group-list-edit
      *ngIf="isEditing; else readonlyFloorGroupListTemplate"
      [elevatorName]="viewingDoorGroupForLock.lock.name"
      [elevatorDetails]="viewingDoorGroupForLock.lock.elevator"
      [selectedFloorGroupUUID]="getCurrentFloorGroupUUID(viewingDoorGroupForLock)"
      [showWarningMessage]="showDefaultFloorGroupWarning"
      [defaultFloorGroupSelectMode]="!isViewingKeyMembership"
      (cancel)="handleViewDoorGroupForLock()"
      (save)="handleSaveFloorGroup($event)">
    </latch-floor-group-list-edit>

    <ng-template #readonlyFloorGroupListTemplate>
      <latch-floor-group-list
        [elevatorName]="viewingDoorGroupForLock.lock.name"
        [elevatorDetails]="viewingDoorGroupForLock.lock.elevator"
        [selectedFloorGroup]="viewingDoorGroupForLock.selectedFloorGroup"
        [defaultFloorGroup]="viewingDoorGroupForLock.defaultFloorGroup"
        (cancel)="handleViewDoorGroupForLock()">
      </latch-floor-group-list>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #confirmDialog>
  Are you sure you want to make <strong>{{totalChanges}}</strong> changes to <strong>{{key?.name}} Key</strong>?
  <div class="divider"></div>
  <div class="changes-container">
    <div class="change" *ngIf="createdCount">
      <div><strong>{{createdCount}}</strong> doors added</div>
      <div class="changes-summary">
        <div *ngIf="toCreate['PRIVATE']">{{toCreate['PRIVATE'].length}} Residence</div>
        <div *ngIf="toCreate['PUBLIC']">{{toCreate['PUBLIC'].length}}  Building Entrances</div>
        <div *ngIf="toCreate['COMMUNAL']">{{toCreate['COMMUNAL'].length}} Communal</div>
        <div *ngIf="toCreate['SERVICE']">{{toCreate['SERVICE'].length}} Service</div>
      </div>
    </div>
    <div class="change" *ngIf="updatedCount">
      <div>
        <strong>{{updatedCount}}</strong>
        door settings changes</div>
      <div class="changes-summary">
        <div *ngIf="doorSettingsUpdated.schedulesModified">{{doorSettingsUpdated.schedulesModified}} door schedules modified</div>
        <div *ngIf="doorSettingsUpdated.schedulesRemoved">{{doorSettingsUpdated.schedulesRemoved}} door schedules removed</div>
        <div *ngIf="doorSettingsUpdated.schedulesAdded">{{doorSettingsUpdated.schedulesAdded}} door schedules added</div>
        <div *ngIf="doorSettingsUpdated.doorcodesEnabled">{{doorSettingsUpdated.doorcodesEnabled}} doorcode enabled</div>
        <div *ngIf="doorSettingsUpdated.doorcodesDisabled">{{doorSettingsUpdated.doorcodesDisabled}}  doorcode disabled</div>
        <div *ngIf="doorSettingsUpdated.elevatorDefaultGroups">{{doorSettingsUpdated.elevatorDefaultGroups}} elevator default floor groups</div>
      </div>
    </div>
    <div class="change" *ngIf="deletedCount">
      <div><strong>{{deletedCount}}</strong> doors removed</div>
      <div class="changes-summary">
        <div *ngIf="toDelete['PRIVATE']">{{toDelete['PRIVATE'].length}} Residence</div>
        <div *ngIf="toDelete['PUBLIC']">{{toDelete['PUBLIC'].length}}  Building Entrances</div>
        <div *ngIf="toDelete['COMMUNAL']">{{toDelete['COMMUNAL'].length}} Communal</div>
        <div *ngIf="toDelete['SERVICE']">{{toDelete['SERVICE'].length}} Service</div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <ng-container *ngFor="let message of messages">
    <div class="latch-confirmation-dialog-message messages-subtext"
      [innerHTML]="message">
    </div>
  </ng-container>
</ng-template>
