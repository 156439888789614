import { Directive, OnInit, Output, EventEmitter, OnDestroy, ElementRef, NgZone, Optional } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subject, asyncScheduler } from 'rxjs';
import { takeUntil, filter, throttleTime } from 'rxjs/operators';
import { Directionality } from '@angular/cdk/bidi';

const THROTTLE_TIME = 250;

@Directive({ selector: '[latchScrolledToEnd]' })
export class ScrolledToEndDirective extends CdkScrollable implements OnInit, OnDestroy {

  @Output('latchScrolledToEnd') scrolledToEnd = new EventEmitter<boolean>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    protected elementRef: ElementRef<HTMLElement>,
    protected scrollDispatcher: ScrollDispatcher,
    protected ngZone: NgZone,
    @Optional() protected dir?: Directionality
  ) {
    super(elementRef, scrollDispatcher, ngZone, dir);
  }

  ngOnInit() {
    super.ngOnInit();
    const element = this.elementRef.nativeElement;
    this.elementScrolled().pipe(
      filter(() => {
        const scrollOffset = this.measureScrollOffset('top');
        const maxScrollOffset = element.scrollHeight - element.clientHeight;
        return scrollOffset >= maxScrollOffset * 0.99; // 1% wiggle room
      }),
      throttleTime(THROTTLE_TIME, asyncScheduler, { leading: true, trailing: false }),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.scrolledToEnd.emit(true);
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribe$.next();
  }

}
