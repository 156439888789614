import { Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface NavigatedPopup {
  type: string;
  popup: Type<unknown>;
}

/**
 * get the leaf ActivatedRoute based on the Router
 * @param router Router
 * @returns Leaf route's corresponding ActivatedRoute
 */
export function getLeafRoute(router: Router): ActivatedRoute {
  const rootRoute = router.routerState.root;
  let leafRoute = rootRoute;
  while (leafRoute.firstChild) {
    leafRoute = leafRoute.firstChild;
  }
  return leafRoute;
}
