import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '../services/utility/utility';

const oneMinute = 60;
const oneHour = 60 * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;
const oneYear = oneDay * 365;
// Since months vary in length, just use the average
const oneMonth = oneYear / 12;

const thresholds = [oneMinute, oneHour, oneDay, oneWeek, oneMonth, oneYear, Infinity];
const units = ['minute', 'hour', 'day', 'week', 'month', 'year'];

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  public transform(from: Date, to?: Date): string {
    if (!from) {
      return '';
    }

    // The difference between 'from' and 'to' date to transform - in seconds
    const diff = ((to ?? new Date()).valueOf() - from.valueOf()) / 1000;

    for (let i = 0; i < units.length; i++) {
      const nextThreshold = thresholds[i + 1];
      if (diff < nextThreshold) {
        const threshold = thresholds[i];
        const unit = units[i];
        // Round up when between 0 and 1
        const count = Math.max(Math.round(diff / threshold), 1);
        // In the case where the count rounds up to threshold, like 60 minutes, we skip this loop and go to the next one
        // to get 1 hour
        if (count * threshold === nextThreshold) {
          continue;
        }
        return `${count} ${pluralize(count, unit)}${to ? '' : ' ago'}`;
      }
    }

    throw new Error('Date value is not less than or equal to Infinity');
  }
}
